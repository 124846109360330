import React, { Component } from 'react';
//Core
import {
Typography,
Dialog,
DialogActions,
DialogContent,
Divider,
ButtonGroup,
IconButton,
Button,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done'
//Components
import { RoundedButtonGroup, MuiOutlinedInput } from 'components';
import { UserStore } from 'stores';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';


const DialogTitle = withStyles(style.styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class SkipDialog extends Component  {
  

    constructor(props) {
        super(props);

        this.state = {
            item : this.props.data
        }

    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.data != this.props.data) {
            this.state = {
                item: this.props.data
            }
        }
    }


    handleChange = (event) => {
        const value = event.target.value;
        var newItem = { ...this.state.item };
        newItem.reasonOfSkip = value;
        this.setState({
            item: newItem
        });
    }

    validate = () => {
       
        const { item } = this.state;
        if (item.reasonOfSkip !== null && item.reasonOfSkip !== undefined && item.reasonOfSkip.length>25) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { item } = this.state;
        const { open,data, handleClose, snackbar, classes } = this.props;
        const loggedBy =  UserStore.getLoggedByAdminValue();
    return (
        <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={handleClose}><Typography className={classes.title}>Kihagyás indoklása</Typography></DialogTitle>
            <Divider />
            <DialogContent>
                <Typography>-Mikor derült ki, hogy a projekt nem teljesíthető?</Typography> 
                <Typography>-Milyen külső és/vagy belső tényezők befolyásolták, hogy nem teljesült a projekt?</Typography> 
                <Typography>-Történt-e a projekt ütemezéséről, a gátló tényezők megszüntetésével kapcsolatos kommunikáció a vezető és a munkavállaló között?</Typography> 
                <Typography>-Területen belül más sem tudta volna megvalósítani a projektet?</Typography> 
                <Typography>-Pótolható a megvalósítás később?</Typography>
                <Typography>(minimum karakterszám 25)</Typography>
                <Divider style={{ marginBottom: theme.spacing(2) }} />
               <MuiOutlinedInput
                    margin="dense"
                    label="Indoklás"
                    readOnly={loggedBy!=null && loggedBy.period.disabled}
                    value={item.reasonOfSkip}
                    handleChange={this.handleChange}
                    multiline
                    rows={8} />
            </DialogContent>
            <Divider />
            <DialogActions>
            {(loggedBy===null || !loggedBy.period.disabled) && <ButtonGroup disableElevation={true}>
                    <Button
                        onClick={() => this.props.onDone(item)}
                        className={classes.doneButton}
                        disabled={!this.validate()} 
                        size="small"
                        endIcon={<DoneIcon/>}
                    >
                      Mentés
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}  
                        size="small"
                        endIcon={<CloseIcon/>}
                    >
                        Vissza
                    </Button>
                </ButtonGroup>}
            </DialogActions>
        </Dialog>
    );

}
    
}
export default withStyles(style.useStyles)(SkipDialog);
