//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Typography
 } from '@material-ui/core';
//Components

import { 
    Pagination, 
    LeadersTable, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class Leaders extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            leaders: [],
            lastValidFromForAdd: null,
            lastValidFromForEdit: null,
            loading: {
                leaders: true,
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit:null,
        }

    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateLeaders(this, this.state.page);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (organizations) => {
        this.setState({
            selectedOrganizations: organizations
        });
    }

    handleChange = (date,index,props) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        const leaders = [...this.state.leaders];
        leaders[index][props] = newDate;
        this.setState({ leaders });
    }

    handleEdit = (role) => {
        this.setState({
            edit: { ...role }
        });
    }

    handleSave = (index) => {
        const { leaders } = this.state;
        apiRequest.saveEdit(this, leaders[index]);
    }

    handleDelete = (index) => {
        const { leaders } = this.state;
        apiRequest.deleteRole(this, leaders[index]);
    }

    handleCancel = (index) => {
        const leaders = [...this.state.leaders];
        leaders[index].validFrom = this.state.edit.validFrom;
        leaders[index].validTo = this.state.edit.validTo;
        this.setState({
            leaders,
            edit:null
        });
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                leaders: true
            }
        }));
        apiRequest.populateUserRoles(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () =>{
        const {page} = this.state;
        apiRequest.populateLeaders(this, page);
    }

    render() {
        const {open, leaders, currentPage, step, total, pages, lastValidFromForEdit, lastValidFromForAdd, edit, loading} = this.state;
        const {classes, snackbar, onClose, organization} = this.props;

        return (
            <Drawer anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <Typography className={classes.appBarTitle}>{organization.name}</Typography>
                        <div style={{flexGrow:1}}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.root}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                        <div className={classes.content}>
                            <LeadersTable
                                loading={loading.leaders} 
                                onOpen={this.handleOpen}
                                lastValidFrom={lastValidFromForEdit} 
                                onDelete={this.handleDelete}  
                                onCancel={this.handleCancel} 
                                edit={edit} 
                                onEdit={this.handleEdit} 
                                onSave={this.handleSave} 
                                onChange={this.handleChange} 
                                onSelect={this.handleSelectCompanies} 
                                leaders={leaders}
                            />
                        </div>
                        {(loading.leaders === false && edit==null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                </Grid>
                {open == true && 
                    <AddNewDialog
                        lastValidFrom={lastValidFromForAdd} 
                        organization={organization}
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </Drawer>
        );
    }

};

export default withStyles(style)(Leaders);
