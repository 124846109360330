//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.getFilters(this);

        this.state = {
            selected: [],
            fromQuantities:'',
            data: [],
            valid:false,
            loading:{
                data:true
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selected,fromQuantities } = this.state;
        if (prevState.selected != selected || prevState.fromQuantities !=fromQuantities) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onSelectChange = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    onChange = (event,isNumber) =>{
        const name = event.target.name;
        let value = event.target.value;
        if(isNumber){
            value = value *1;
        }
        this.setState({
            [name]: value
        });
    }

    handleSave = () => {
        const {selected,fromQuantities } = this.state;
        const {variation,period} = this.props;
        const model = { filters:selected.map(item=>{return item.id}),variation:variation.id,period:period.id,fromQuantities:fromQuantities};
        apiRequest.saveChanges(this,model);
    }

    validation = () => {
        var doc = document.getElementById("add-new-form");     
        if (doc !== null && doc !== undefined) {
            if (!(doc.querySelectorAll(".Mui-error").length>1)) {
                return true;
            }
        }
        return false;
    }


    render() {
        const {onClose, open, classes} = this.props;
        const {selected, data, loading, valid, fromQuantities} = this.state;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új szűrő hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                    <Grid spacing={2} container>                      
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                                className={classes.autocomplete}
                                clearText="Alaphelyzet"
                                noOptionsText="Nincs találat"
                                closeText="Bezárás"
                                id="select-selected"
                                multiple
                                name="filter"
                                value={selected}
                                onChange={(event, value) => this.onSelectChange("selected", value)}
                                options={data}
                                getOptionLabel={(option) => option.label}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        error={(selected === null || selected==='' || selected.length<1) ? true : false}
                                        helperText={(selected === null || selected==='' || selected.length<1) ? "Kötelező választani" : ''}
                                        label="Szűrők"
                                        InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading.data ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                required
                                label="Darabszámtól"
                                type="number"
                                fullWidth
                                error={(fromQuantities==='' || fromQuantities === null || fromQuantities===undefined) ? true : false}
                                helperText={(fromQuantities==='' || fromQuantities === null || fromQuantities===undefined) ? 'Kötelező kitölteni' : ''}
                                name="fromQuantities"
                                value={fromQuantities}
                                onChange={(event)=>this.onChange(event,true)}
                            />
                        </Grid>                                                
                    </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!valid} 
                                    onClick={this.handleSave} 
                                    className={classes.doneButton} 
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
