
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar } from 'components';
//Helpers

import { UserStore } from 'stores';

const Actions = {
    GetReviewers: 'matrix/reviewers',
    Save:'matrix/save',
    SaveAdmin: 'matrix/admin/save',
    CheckIsValid: 'matrix/check-is-valid',
    RemoveUser: 'matrix/remove',
    CheckIsAddNew: 'matrix/check-could-add-new',
}

export const apiRequest = {
    populateEvaluators,
    checkIsValid,
    checkIsAddNew,
    saveAction,
    removeUser
};

async function populateEvaluators(parent) {
    const {setParentSate,userId} = parent.props;

    var model = {userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetReviewers,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            evaluators: res.reviewers,
            baseEvaluators: JSON.parse(JSON.stringify(res.reviewers)),
            loading: false,
            selectedMatrix: res.variationMatrixId

        });
        setParentSate("evaluators", JSON.parse(JSON.stringify(res.reviewers)));
        setParentSate("selectedMatrix", res.variationMatrixId);
    }).catch(handleError);;
}

async function checkIsValid(parent) {
    const { evaluators } = parent.state;
    const {setParentSate} = parent.props;

    setParentSate("evaluators", JSON.parse(JSON.stringify(evaluators)));

    var model = {evaluators};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(`${Actions.CheckIsValid}`, model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            validMatrix: res
        });
        setParentSate("selectedMatrix", res);
        if (res !== null && res !== undefined && res !== '') {
            setParentSate("isValid", true)
        } else {
            setParentSate("isValid", false)
        }
    }).catch(handleError);
}

async function checkIsAddNew(parent) {
    const { evaluators } = parent.state;
    const {userId} = parent.props;

    var model = {selectedUser:userId,quantity:evaluators.length};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.CheckIsAddNew,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            couldAddNew: res
        });
    }).catch(handleError);
}

async function removeUser(parent,model) {
     var _model = {...model};

     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
        _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

    await new Api().call.post(Actions.RemoveUser,_model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            evaluators: res.reviewers,
            selectedMatrix: res.selectedMatrixId
        });
    }).catch(handleError);
}

async function saveAction(parent,userId) {
    const { evaluators, validMatrix } = parent.state;
    const {setParentSate} = parent.props;

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

     var model = {  evaluators, selectedMatrixId: validMatrix, userId};
     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

    await  new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            isModified: false,
            baseEvaluators: JSON.parse(JSON.stringify(evaluators))
        });
        setParentSate("isModified", false);
    }).catch(handleError);
}
