//React

import React from 'react';
//Api

import { 
    Api, 
    handleResponse, 
    handleError 
} from 'api';
//Components

import { 
    NotificationBar, 
    Progress 
} from 'components';
//Actions

const Actions = {
    GetUsers: 'probaido/user-list-hr',
    GetUsersForFilter: 'probaido/filter/users',
    downloadFirst: 'probaido/leader/forms/first/download',
    downloadSecond: 'probaido/hr/forms/second/download',
    downloadThird: 'probaido/leader/forms/third/download',
    downloadAll: 'probaido/hr/forms/download-all'
}

export const apiRequest = {
    populateUsers,
    populateUsersForFilter,
    downloadFirst,
    downloadThird,
    downloadAll
};

async function populateUsers(parent, page) {
    const { filter } = parent.state;

    const model = {
        filteredUsers: filter.users,
        page

    };

    await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res.userList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateUsersForFilter(parent) {
    await new Api().call.get(Actions.GetUsersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                },
                filterUsers: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                }
            }));
        });
}

async function downloadFirst(parent, selected) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.downloadFirst, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

async function downloadThird(parent, selected) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.downloadThird, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

async function downloadAll(parent, selected) {
    NotificationBar('File-ok letöltése...', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.downloadFirst, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });

    await new Api().call.post(Actions.downloadSecond, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
        }).catch(handleError);

    await new Api().call.post(Actions.downloadThird, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
