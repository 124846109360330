//React

import React from 'react';
//Api

import { Api, handleResponse, handleError } from 'api';
//Components

import { Progress, NotificationBar } from 'components';
//Helpers

import { UserStore } from 'stores';
//Actions

const Actions = {
    GetGoalTypes: 'probaido/goal/types',
    GetUsers: 'probaido/goals/user',
    GetGoals: 'probaido/goals',
    GetPoints: 'probaido/leader/forms/third',
    SavePoints: 'probaido/leader/forms/third/save',
    Download: 'probaido/leader/forms/third/download',
    DownloadGoals: 'probaido/leader/forms/third/goals-download'
}

export const apiRequest = {
    populateUsers,
    populateGoalTypes,
    populateGoals,
    populatePoints,
    save,
    download,
    downloadGoals
};

async function save(parent) {
    const { points } = parent.state;
    const { selected } = parent.props;
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { data: points, userId: selected.id };
    await new Api().call.post(Actions.SavePoints, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                tabValue: 1
            });
        }).catch(handleError);

}

async function populatePoints(parent, user) {
    await new Api().call.get(`${Actions.GetPoints}/${user}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                points: res,
                loading: {
                    ...prevState.loading,
                    points: false
                }
            }));
        }).catch(handleError);
}

async function populateUsers(parent) {
    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false,
                },
                users: res.filter(item => parent.props.selected.id === item.id)
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        lazyData: false,
                    },
                }));
            }
        }).catch(handleError);

}

async function populateGoalTypes(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetGoalTypes, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    goalTypes: false,
                },
                goalTypes: res,
                selectedType: res !== null && res.length > 0 ? res[0] : null,
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        lazyData: false,
                    },
                }));
            }
        }).catch(handleError);

}


async function populateGoals(parent, currentPage, _typeId) {
    const { users } = parent.state;
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;


    var model = { currentPage: pageNumber, typeId, userId: users[0].id };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }


    await new Api().call.post(Actions.GetGoals, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    goals: res,
                    baseGoals: JSON.parse(JSON.stringify(res))
                },
                alert: {
                    ...prevState.alert,
                    weightSum: res.relevanceSum,
                    total: res.total,
                    isWeighted: res.goalType.isWeighted
                },
                loading: {
                    ...prevState.loading,
                    lazyData: false
                }
            }));
        }).catch(handleError);

}

async function download(parent, selected) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Download, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false
                }
            }));
        }).catch(handleError);
}

async function downloadGoals(parent, _typeId, userId) {
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = { typeId, userId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.DownloadGoals, model)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false
                }
            }));
        }).catch(handleError);

}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

