import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import theme from 'theme';

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '1.0rem',
            fontFamily: "Nunito",
        },
    },
    head: {
        '& > *': {
            fontSize: '1.1rem',
            fontFamily: "Nunito",
            fontWeight: 'bold',
        },
    },
})((props) => <TableRow  {...props} />)


const useStyles = ({
    table: {
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    }
});

export default {
    useStyles: useStyles,
    StyledTableRow: StyledTableRow
}
