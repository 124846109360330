import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetLeader: 'user/leader'
}

export const apiRequest = {
    populateLeader
};

//#Api calls
async function populateLeader(parent) {

    const loggedBy = UserStore.getLoggedByAdminValue();
    var request = null;
    if(loggedBy!=null){
       let model = {period:loggedBy.period.id,user:loggedBy.user.userId};
       request =  new Api().call.post(Actions.GetLeader,model);
    }else{
        request = new Api().call.get(Actions.GetLeader);
    }
        await request
        .then(handleResponse)
        .then(res => {
                parent.setState(prevState => ({
                    leader: res,
                    loading: {
                        ...prevState.loading,
                        leaderBox: false
                    }
                }));
            }).catch(handleError);
}
