import theme from 'theme';

const useStyles = () => ({
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    paginationText: {
        fontFamily: 'Nunito',
        fontSize: '1.0rem',
        paddingRight:theme.spacing(2)
    },
    previousButton: {
        border: '1px solid',
        backgroundColor: theme.palette.primary.main, 
        color: theme.palette.white,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.white, 
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    },
    nextButton: {

        border: '1px solid',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.white, 
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    }
});

export default useStyles;
