import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
    },
    label: {
        fontFamily: 'Nunito',
        fontSize: '1.0rem',
        padding: theme.spacing(1)
    },
    center: {
        margin: 'auto'
    },
    divider: {
        backgroundColor: theme.palette.appMain.main
    },
}));

export default {
    useStyles: useStyles
}
