import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components
import {UserStore} from '../../../../../../stores';
//Core
import { Avatar, Typography,Badge, IconButton, Tooltip, Hidden } from '@material-ui/core';
//Icons
import InputIcon from '@material-ui/icons/Input';
//Style
import { withStyles } from '@material-ui/styles';
import style from './style';

const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 30,
      height: 30,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);
  

class Profile extends Component {

    state = {
        loginBy:UserStore.getLoggedByAdminValue()
    }

    componentDidMount() {
        UserStore.getLoggedByAdmin().subscribe(res => this.setState({
            loginBy: res,
        }));
    }

    handleClose = () =>{
        UserStore.clearLoggedByAdmin();
    }


    render() {
        const { className, onLogoutDialogOpen, classes,profile, ...rest } = this.props;
        const {loginBy} = this.state;
        const user = UserStore.getUserValue();
        return (user !== null && user.id ?
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <Tooltip title={user.fullName}>              
                 {loginBy!==null && loginBy.user!=null ? 
                 <IconButton onClick={this.handleClose}>
                    <Badge
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    badgeContent={<SmallAvatar alt={loginBy.user.fullName} src={loginBy.user.avatar}/>}
                >
                    <Avatar alt={user.fullName} src={user.avatar} className={classes.avatar} />
                </Badge>
                </IconButton>  :                  
                  profile!=='admin' ?  
                    <IconButton> 
                      <Avatar
                         className={classes.avatar}
                         component={RouterLink}
                         src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                         to={"/account"}
                     />  
                    </IconButton> :
                     <Avatar
                         className={classes.avatar}
                         src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                     />
                }               
                </Tooltip>
                <Typography
                    className={classes.name}
                    variant="h4"
                >
                    {user.fullName}
                </Typography>
                {loginBy!==null && loginBy.user!=null && 
                <Typography className={classes.subName}>
                    {loginBy.user.fullName}
                </Typography>
                }
                {loginBy!==null && loginBy.period!=null && 
                <Typography className={classes.subName}>
                    {loginBy.period.label}
                </Typography>
                }
                <Typography className={classes.bio} variant="body2">{user.bio}</Typography>
                <Hidden lgUp>
                    <Tooltip title="Kijelentkezés">
                        <IconButton
                            className={classes.signOutButton}
                            color="inherit"
                            onClick={onLogoutDialogOpen}
                        >
                            <InputIcon />
                        </IconButton>
                    </Tooltip>
                </Hidden>
            </div> : <div></div>
        );
    }
}

Profile.propTypes = {
  className: PropTypes.string
};

export default withStyles(style)(Profile);
