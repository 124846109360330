//React

import React from 'react';
//Core

import {
    AppBar,
    Tabs,
    Tab,
} from '@material-ui/core';
//Icons

import ThumbUp from '@material-ui/icons/ThumbUp';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import RateReviewIcon from '@material-ui/icons/RateReview';
//Style

import style from './style';


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function Selector(props) {
    const {data, onChange, value, children, disabled} = props;
    const classes = style();
    const icons = [
        {key:'Competences',icon:<AssignmentTurnedInIcon />}, 
        {key:'RatingsAsLeader',icon:<RateReviewIcon/>}, 
        {key:'PrevGoals',icon:<EqualizerIcon />}, 
        {key:'NewGoals',icon:<EqualizerIcon />}, 
        {key:'Motivations',icon:<ThumbUp />}
    ]

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs               
                    value={value}
                    onChange={onChange}
                    scrollButtons="on"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >             
                    {data.map((item, index) => (
                        <Tab 
                            key={index} 
                            disabled={disabled} 
                            icon={icons?.find(item2=>item2.key === item.key)?.icon} 
                            className={classes.tabs} 
                            label={item.title} 
                            {...a11yProps(index)} 
                        />
                    ))} 
                </Tabs>               
            </AppBar>
            {children}       
        </div>
    );
}
export default Selector;
