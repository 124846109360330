import React, { Component } from 'react';
//Core
import {
    Typography,
    Grid,
    CircularProgress,
    Divider,
} from '@material-ui/core';
//Components
import { Error, Pagination, MuiOutlinedSelect } from 'components';
import { GoalsGrid } from './components';
import { authenticationService } from 'services';
import $ from "jquery";
import { apiRequest } from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';



class Goals extends Component {

    state = {
        goals: [],
        goalTypes: [],
        selectedType: null,
        periods:[],
        loading: {
            goals:true,
            goalTypes: true,
            periods:true,
        },
        error: false,
        selected: {
            period:null
        }
    }

    constructor(props) {
        super(props);

        this.handleSelectChangeGoalTypes = this.handleSelectChangeGoalTypes.bind(this);
        this.initState = this.initState.bind(this);
        this.handlePageChanges = this.handlePageChanges.bind(this);
    }

    componentDidMount() {
        apiRequest.populatePeriods(this);           
    }

    async componentDidUpdate(prevProps, prevState) {
        const { selected, selectedType } = this.state;
        if (prevState.selected.period != selected.period) {
            if (selected.period !== null && selected.period !== undefined) {
                apiRequest.populateGoalTypes(this,selected.period);
            }
        }
    }


    handleError = () => {
        this.setState({
            error: true
        });
    }

    handleSelectChange = (event) => {
        const { selected } = this.state;
        this.setState(prevState => ({
            selected: {
                ...prevState.select,
                period: event.target.value
            },
            loading: {
                ...prevState.loading,
                goalTypes:true
            },
            goalTypes:[]
        }));
   
    }

    async handleSelectChangeGoalTypes(event){
        //Change selected state
        const { goalTypes, selected } = this.state;
        const selectedType = goalTypes.find(type => type.id === event.target.value);
        this.setState({
            selectedType
        });
        //Refresh table
        this.initState();
        apiRequest.populateGoals(this,1, selectedType.id, selected.period);
    }

    async initState() {
        await this.setState(prevState => ({
            goals: null,
            loading: {
                ...prevState.loading,
                goals: true
            },
        }));
    }

    async handlePageChanges(currentPage, goalType) {
        const { selected } = this.state;
        this.initState();
        apiRequest.populateGoals(this,currentPage, goalType.id, selected.period);

        $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
    }

    
    render() {
        const { loading, goalTypes, periods, selected, error, goals, selectedType } = this.state;
        const { snackbar, classes } = this.props;
        return (error === true ? <Error /> : <div className={classes.root}>
            <Grid
                container
                spacing={0}
            >
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
                {(periods !== null && periods !== undefined && periods.length > 0) &&
                    <Grid
                        item
                        md={10}
                        xs={12}
                    >

                        <Typography className={classes.title}>Célkitűzések</Typography>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                            >
                            <MuiOutlinedSelect margin="dense" disabledItemLabel="Még nem elérhető" disabled={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} error={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} helperText={(periods !== null && periods !== undefined && periods.length > 0) ? '' : "Nem található előző periódus"} className={classes.select} menuList={periods} handleChange={this.handleSelectChange} value={selected.period !== null ? selected.period : 0} label="Előző periódusok" />
                            </Grid>
                        </Grid>
                        <div style={{ display: 'flex' }}>

                        </div>
                        {selected.period != null && (loading.goalTypes ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                            <React.Fragment> <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xl={3}
                                    lg={3}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <MuiOutlinedSelect margin="dense" className={classes.select} menuList={goalTypes} handleChange={this.handleSelectChangeGoalTypes} value={selectedType !== null ? selectedType.id : 0} label="Célkitűzések" />
                                </Grid>
                            </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        {loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                                            <React.Fragment>{selectedType !== null && <Typography className={classes.subTitle}>{selectedType.label}</Typography>}
                                                <Divider className={classes.divider} />
                                                {(selectedType !== null) && (loading.goals === true ? < div className={classes.circularProgress}><CircularProgress color="primary" /></div> : < GoalsGrid
                                                checkIsModified={this.checkIsModified}
                                                data={goals.goalsForTable}
                                                currentPage={goals.currentPage}
                                                selectedType={selectedType}
                                                />)}
                                                {loading.goals === false && selectedType !== null && (goals.step < goals.total) && <Pagination goalType={selectedType} onPageChanges={this.handlePageChanges} step={goals.step} currentPage={goals.currentPage} pages={goals.pages} total={goals.total} />}
                                            </React.Fragment>} </Grid>
                                </Grid></React.Fragment>
                        )}
                    </Grid>}
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
            </Grid>
        </div>);
    }
}

export default withStyles(style)(Goals)
