
//React

import React, { Component, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
//Core

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Divider,
    Grid,
    Typography,
    Avatar,
    TableBody,
    TableContainer,
    Paper,
    TableHead,
    Table,
    TableCell,
    TableRow,
    LinearProgress
} from '@material-ui/core';
//Icons

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
//Api

import { apiRequest } from './apiRequest';
//Components

import { Constants } from 'stores';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ResultDialog } from './components';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
    >
        <RouterLink {...props} />
    </div>
));

class Home extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);
        this.state = {
            period: null,
            self: null,
            motivation: null,
            matrix: null,
            goals: null,
            results: null,
            ratings: null,
            leaderRatings: null,
            speak: null,
            loading: {
                period: true,
                self: true,
                motivation: true,
                matrix: true,
                goals: true,
                results: true,
                ratings: true,
                leaderRatings: true,
                speak: true
            },
            users: null,
            title: null
        }
    }

    componentDidMount() {
        Constants.setcontentLoading(false);
        apiRequest.getActivePeriod(this);
        apiRequest.getSelfRatingResult(this);
        apiRequest.getMotivationsResult(this);
        apiRequest.getMatrixResult(this);
        apiRequest.getGoalResult(this);
        apiRequest.getResultResult(this);
        apiRequest.getRatingResult(this);
        apiRequest.getLeaderRatingResult(this);
        apiRequest.getSpeakResult(this)
    }

    handleClose = () => {
        this.setState({
            users: null
        });
    }

    handleOpen = (users, title) => {
        this.setState({
            users,
            title
        });
    }

    render() {
        const { classes, className, ...rest } = this.props;
        const { loading, period, self, title, users, motivation, matrix, goals, results, ratings, leaderRatings, speak } = this.state;

        return (
            <div className={classes.root} >
                <Typography className={classes.title}>Kezdőlap</Typography>
                <Grid container spacing={4}>
                    <Grid item lg={6} md={8} sm={10} xl={6} xs={12}>
                        <Card>
                            <CardHeader
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Értékelési időszak információk</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                <TableContainer component={Paper}>
                                    {loading.period && <LinearProgress />}
                                    <PerfectScrollbar>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow className={classes.head}>
                                                    <TableCell padding="none" align="center">Periódus</TableCell>
                                                    <TableCell padding="none" align="center">Állapot</TableCell>
                                                    <TableCell padding="none" align="center">Kezdet dátum</TableCell>
                                                    <TableCell padding="none" align="center">Vég dátum</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(period == null || period == undefined || period == '') &&
                                                    <TableRow>
                                                        <TableCell align="center" colSpan="4">
                                                            Jelenleg nem aktív az értékelési időszak
                                                        </TableCell>
                                                    </TableRow>}
                                                {(period != null && period != undefined && period != '') &&
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            {period.label}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(period.isPaused === null || period.isPaused === undefined || period.isPaused === false) ?
                                                                <PlayArrowIcon className={classes.playIcon} />
                                                                :
                                                                <PauseIcon className={classes.pauseIcon} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {period.openedTime}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {period.endTime}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </PerfectScrollbar>
                                </TableContainer>
                            </CardContent>
                            <CardActions className={classes.actions}>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="text"
                                    component={CustomRouterLink}
                                    to="/admin/master-datas/periods"
                                >
                                    Kezelés <ArrowRightIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Divider />
                <Typography className={classes.title}>Kitöltési állapotok</Typography>
                <Grid container spacing={4}>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <AssignmentTurnedInIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Önértékelés</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.self && <LinearProgress />}
                                {loading.self === false && (self === null || self === undefined || self == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.self === false && (self !== null && self !== undefined && self != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{self.all}</span > / <span className={classes.card__result__text}>{self.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.self === false && (self !== null && self !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(self === null || self === undefined || self == '')}
                                        onClick={() => this.handleOpen(self.users, 'Önértékelés')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <ThumbUpIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Motiváció</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.motivation && <LinearProgress />}
                                {loading.motivation === false && (motivation === null || motivation === undefined || motivation == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.motivation === false && (motivation !== null && motivation !== undefined && motivation != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{motivation.all}</span > / <span className={classes.card__result__text}>{motivation.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.motivation === false && (motivation !== null && motivation !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(motivation === null || motivation === undefined || motivation == '')}
                                        onClick={() => this.handleOpen(motivation.users, 'Motiváció')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <AccountTreeIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Mátrixok kitöltése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.matrix && <LinearProgress />}
                                {loading.matrix === false && (matrix === null || matrix === undefined || matrix == '') && <Typography> Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.matrix === false && (matrix !== null && matrix !== undefined && matrix != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{matrix.all}</span > / <span className={classes.card__result__text}>{matrix.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.matrix === false && (matrix !== null && matrix !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(matrix === null || matrix === undefined || matrix == '')}
                                        onClick={() => this.handleOpen(matrix.users, 'Mátrixok')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FormatListBulletedIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Célkitűzések meghatározása</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.goals && <LinearProgress />}
                                {loading.goals === false && (goals === null || goals === undefined || goals == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.goals === false && (goals !== null && goals !== undefined && goals != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{goals.all}</span > / <span className={classes.card__result__text}>{goals.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.goals === false && (goals !== null && goals !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(goals === null || goals === undefined || goals == '')}
                                        onClick={() => this.handleOpen(goals.users, 'Célkitűzések')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <PlaylistAddCheckIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Eredmények kitöltése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.results && <LinearProgress />}
                                {loading.results === false && (results === null || results === undefined || results == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.results === false && (results !== null && results !== undefined && results != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{results.all}</span > / <span className={classes.card__result__text}>{results.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.results === false && (results !== null && results !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(results === null || results === undefined || results == '')}
                                        onClick={() => this.handleOpen(results.users, 'Eredmények')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <StarHalfIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Értékelések elkészítése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.ratings && <LinearProgress />}
                                {loading.ratings === false && (ratings === null || ratings === undefined || ratings == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.ratings === false && (ratings !== null && ratings !== undefined && ratings != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{ratings.all}</span > / <span className={classes.card__result__text}>{ratings.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.ratings === false && (ratings !== null && ratings !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(ratings === null || ratings === undefined || ratings == '')}
                                        onClick={() => this.handleOpen(ratings.users, 'Értékelések')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <PermContactCalendarIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Vezetői értékelések elkészítése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.leaderRatings && <LinearProgress />}
                                {loading.leaderRatings === false && (leaderRatings === null || leaderRatings === undefined || leaderRatings == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.leaderRatings === false && (leaderRatings !== null && leaderRatings !== undefined && leaderRatings != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{leaderRatings.all}</span > / <span className={classes.card__result__text}>{leaderRatings.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.leaderRatings === false && (leaderRatings !== null && leaderRatings !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(leaderRatings === null || leaderRatings === undefined || leaderRatings == '')}
                                        onClick={() => this.handleOpen(leaderRatings.users, 'Vezetői értékelések')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <RecordVoiceOverIcon className={classes.avatar__icon} />
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{ color: 'white', fontFamily: 'Nunito', fontWeight: 'bold' }}>Beszélgetések visszajelzése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardContent className={classes.card__content}>
                                {loading.speak && <LinearProgress />}
                                {loading.speak === false && (speak === null || speak === undefined || speak == '') && <Typography > Jelenleg nem aktív az értékelési időszak</Typography>}
                                {loading.speak === false && (speak !== null && speak !== undefined && speak != '') &&
                                    <div className={classes.card__result}>
                                        <span className={classes.card__result__text}>{speak.all}</span > / <span className={classes.card__result__text}>{speak.done}</span>
                                    </div>
                                }
                            </CardContent>
                            <CardActions className={classes.actions}>
                                {loading.speak === false && (speak !== null && speak !== undefined) &&
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        endIcon={<ArrowRightIcon />}
                                        disabled={(speak === null || speak === undefined || speak == '')}
                                        onClick={() => this.handleOpen(speak.users, 'Beszélgetések')}
                                    >
                                        Megtekintés
                                    </Button>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                {users !== null && <ResultDialog users={users} onClose={this.handleClose} title={title} />}
            </div>
        );
    }
};

export default withStyles(style)(Home);