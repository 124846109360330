import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700,
        fontFamily: 'Nunito',
        fontWeight:'bold'
    },
    avatar: {
        backgroundColor: theme.palette.appMain.main,
        color: theme.palette.primary.contrastText,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32,
    },
    progress: {
        marginTop: theme.spacing(3),
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: theme.palette.appMain.main
        }
    }
}));
