//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components
import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.getCompanies(this);

        this.state = {
            organization: '',
            company: null,
            companies:[],
            valid: false,
            loading:true,
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { organization, company } = this.state;
        if (prevState.organization != organization || prevState.company != company) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });       
    }

    onSelectChange = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    handleSave = () => {
        const { company, organization } = this.state;
        const model = { organization, company };
        apiRequest.saveChanges(this,model);
    }

    validation = () => {
        var doc = document.getElementById("add-new-form");     
        if (doc !== null && doc !== undefined) {
            if (!(doc.querySelectorAll(".Mui-error").length>1)) {
                return true;
            }
        }
        return false;
    }
    
    render() {
        const {onClose, open, classes} = this.props;
        const {organization, loading, valid, companies, company} = this.state;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új szervezeti egység hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                        <Grid spacing={2} container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div id="add-new-form">
                                    <TextField
                                        required
                                        style={{width:'100%'}}
                                        label="Megnevezés"
                                        name="organization"
                                        error={(organization == null || organization == undefined || organization == '') ? true : false}
                                        helperText={(organization == null || organization == undefined || organization == '') ? 'Kötelező megadni' : ''}
                                        onChange={this.onChange}
                                        value={organization} 
                                    />
                                </div>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-company"
                                    name="company"
                                    value={company}
                                    onChange={(event, value) => this.onSelectChange("company", value)}
                                    options={companies}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            error={company === null ? true : false}
                                            helperText={company === null ? "Kötelező megadni" : ''}
                                            label="Cégcsoportok"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>                   
                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
            </Dialog>
        );
    } 
}
export default withStyles(style.useStyles)(AddNewDialog);
