import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        verticalAlign:'center'
    },
    progress: {
        color: theme.palette.white
    }
}));
