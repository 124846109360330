//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    NotificationBar,
    Progress
} from 'components';
//Actions

const Actions = {
    SaveSettings: 'probaido/admin/user/hr/update',
    GetHrs: 'probaido/auto-complete/hrs',
    GetData: 'probaido/admin/user/hr'
}

export const apiRequest = {
    saveSettings,
    populateHrs,
    populateData
};

//#Api calls
async function saveSettings(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const { hr } = parent.state;
    const { userId, refreshProfile, refreshTable } = parent.props;
    const model = { userId, person: hr.person, dateOfEntry: hr.dateOfEntry }
    await new Api().call.post(Actions.SaveSettings, model).then(handleResponse).then(res => {
        refreshProfile();
        refreshTable();
    }).catch(handleError);
}


async function populateHrs(parent) {
    await new Api().call.get(Actions.GetHrs)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                },
                hrs: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                }
            }));
        });
}

async function populateData(parent) {
    const { userId } = parent.props;
    await new Api().call.get(`${Actions.GetData}/${userId}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hr: false
                },
                hr: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hr: false
                }
            }));
        });
}


