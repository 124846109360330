
//React

import React, { Component } from 'react';
//Helpers

import { Constants } from 'stores';
//Core

import {
    Grid,
    Typography
} from '@material-ui/core';
//Components

import { Welcome } from './components'
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


class Home extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Constants.setcontentLoading(false);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={9} xs={12}>
                        <Typography className={classes.title}>
                            Köszöntünk a próbaidő értékelő beszélgetések nyomon követésére kialakított felületen!
                        </Typography>
                        <Welcome />
                    </Grid>
                    <Grid item md={3} xs={12} />
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(Home);