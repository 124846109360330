import React from 'react';
//Api
import {Api,handleResponse,handleError} from 'api';
import {authenticationService} from 'services';
//Components
import { NotificationBar,Progress  } from 'components';
import { Constants } from 'stores';

const Actions = {
    GetUsersForSelect: 'admin/loginBy/users',
    GetPeriod: 'admin/loginBy/periods'
}

export const apiRequest = {
    populateUsers,
    populatePeriods
};

 async function populateUsers(parent) {

        await new Api().call.get(Actions.GetUsersForSelect)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users:false
                },
                users: res
            }));
        }).catch(handleError);
}

async function populatePeriods(parent) {

    await new Api().call.get(Actions.GetPeriod)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                periods:false
            },
            periods: res
        }));
    }).catch(handleError);
}


