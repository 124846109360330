import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.appMain.main,        
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: theme.palette.white,
        padding: theme.spacing(2)
    },
}));

export default {
    useStyles: useStyles
}
