//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    GetRoles: 'admin/masterData/roles',
    GetClaims:'admin/masterData/claims'
}

export const apiRequest = {
    populateRoles,
    populateClaims
};

async function populateRoles(parent) {

        await new Api().call.get(Actions.GetRoles)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    roles:false
                },
                roles:res
            }));
        }).catch(handleError);
}

async function populateClaims(parent) {

    await new Api().call.get(Actions.GetClaims)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                claims:false
            },
            claims:res
        }));
    }).catch(handleError);
}
