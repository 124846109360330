//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    Save: 'admin/matrix/variations/add-new/filters/save',
    GetFilters:'admin/matrix/variations/filters/matrix-filters'
}

export const apiRequest = {
    saveChanges,
    getFilters
};

//#Api calls

async function getFilters(parent) {

    await new Api().call.get(Actions.GetFilters)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            data: res,
            loading:{
                ...prevState.loading,
                data:false
            }
        }));
    }).catch(error => {
        handleError(error);
    });
}

async function saveChanges(parent, model) {
    const { onClose,refresh } = parent.props;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save, model)
    .then(handleResponse)
    .then(res => {
        refresh();
        onClose();
    }).catch(handleError);
}

