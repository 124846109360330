//React
import React,{ Component } from "react";
//Core
import { 
    Typography, 
    Grid, Divider, 
    TextField, 
    IconButton,
    Button,
    Backdrop,
    CircularProgress 
} from "@material-ui/core";
//Style
import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/styles';

class UserGuide extends Component{

    render(){
        const { classes } = this.props;

        return(<div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                >
                </Grid>
                <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                >
                    <Typography className={classes.title}>Használati segédlet</Typography>
                    <Divider />
                    <div className={classes.divider} />
                    <div className={classes.help_container}>
                        <video className={classes.help_video} controls>
                            <source src="/videos/Használati útmutató.mp4" type="video/mp4"/>
                        </video> 
                    </div>
                                   
                </Grid>
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                >
                </Grid>
            </Grid>
        </div>)    
    }
}

export default withStyles(style)(UserGuide);