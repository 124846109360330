//React

import React from 'react';
import { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import {
    RolesTable,
    ClaimsTable
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Style

import { withStyles } from '@material-ui/styles';
import style from './style';

class Roles extends Component{

    constructor(props){
        super(props)

        this.state={
            roles:[],
            claims:[],
        };
    }

    componentDidMount(){
        apiRequest.populateRoles(this);
        apiRequest.populateClaims(this);
    }

    render(){
        const {classes} = this.props;
        const  {roles ,claims} = this.state;

        return(
            <div className={classes.root}>
                <Typography className={classes.title}>Jogosultságok</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Typography className={classes.subTitle}>Jogosultságok</Typography>
                        <RolesTable data={roles}/>
                    </Grid>                 
                </Grid>
                <Grid container spacing={2}> 
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <Typography className={classes.subTitle}>Megszorítások</Typography>
                        <ClaimsTable data={claims}/>
                    </Grid>                
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(Roles);