//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    CircularProgress,
    Button,
    ButtonGroup
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
//Components

import { MuiOutlinedInput } from 'components';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';


const DialogTitle = withStyles(style.styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class NotesDialog extends Component  {
  
    constructor(props) {
        super(props);

        this.state = {
            notes: [],
            note:'',
            loading: true
        }
        
        this.handleClickSend = this.handleClickSend.bind(this);
    }

    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateNotes(this);
    }
    //#EndLifecycle methods

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({
            note: value
        });
    }

    handleClickSend() {
        const { data } = this.props;
        const { note } = this.state;
        apiRequest.handleSaveChanges(this,data.id, note)
    }


    render(){
        const {open, handleClose, period, data, classes} = this.props;
        const {note, loading, notes} = this.state;

        return (
            <Dialog fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle onClose={handleClose} id="customized-dialog-title">
                    <Typography className={classes.title}>
                        Megjegyzések - {data.fullName}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                        <List>
                            {notes.length < 1 ? <div style={{ textAlign: 'center', padding: theme.spacing(4) }}><b>Nincs megjegyzés hozzáfűzve</b></div> :
                                notes.map(item =>
                                <ListItem key={item.id} >
                                    <ListItemIcon>
                                        <FiberManualRecordIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={item.note} secondary={item.createdTime} />
                                </ListItem>)}
                        </List>}
                {period.isActive === true && <MuiOutlinedInput
                        margin="dense"
                        label="Megjegyzés"
                        value={note}
                        handleChange={this.handleChange}
                        multiline
                        rows={8} />}
                </DialogContent>
                <Divider />
                {period.isActive === true && <DialogActions>
                    <ButtonGroup>
                        <Button
                            onClick={this.handleClickSend}
                            className={classes.doneButton}
                            size="small"
                            endIcon={<DoneIcon/>}
                        >
                            Mentés
                        </Button>
                        <Button
                            onClick={handleClose}
                            className={classes.cancelButton}  
                            size="small"
                            endIcon={<CloseIcon/>}
                        >
                            Mégsem
                        </Button>
                    </ButtonGroup>
                </DialogActions>}
            </Dialog>
        );
    }
   
}
export default withStyles(style.useStyles)(NotesDialog);
