//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    GetTypes:'ratingForm/rating-types'
}

export const apiRequest = {
    populateTypes
};

async function populateTypes(parent) {


    await new Api().call.get(Actions.GetTypes)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                types: false,
            },
            types: res,

        }));
    }).catch(handleError);

}

