//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetCompetences:'admin/rating/competences',
    GetCompetencesFilters:'admin/rating/competences/filter/competences',
    SaveEdit:'admin/rating/competences/edit/save',
}

export const apiRequest = {
    populateCompetences,
    populateCompetencesFilters,
    saveEdit,
};

async function populateCompetences(parent,page) {

    const { selectedFilter } = parent.state;
    const model = {
        filteredCompetences: selectedFilter.competences,
        active: (selectedFilter.active!==null && selectedFilter.active!==undefined) ? selectedFilter.active.id : null,
        page
    };
    await new Api().call.post(Actions.GetCompetences,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                competences:false
            },
            competences: res.competencesList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function populateCompetencesFilters(parent) {

    await new Api().call.get(Actions.GetCompetencesFilters)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                f_competences:false
            },
            filter: {
                ...prevState.filter,
                competences: res
            }
        }));
    }).catch(handleError);
}

async function saveEdit(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.SaveEdit,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        parent.refresh();
    }).catch(handleError);
}

