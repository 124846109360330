//React

import React from 'react';
import clsx from 'clsx';
//Styles

import style from './style';
//Core

import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TextField
} from '@material-ui/core';
//Icons

import FilterListIcon from '@material-ui/icons/FilterList';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';

const validOptions = [{ id: 1, label: 'Igen' }, { id: 2, label: 'Nem' }];

const Toolbar = props => {
    const {selected, onDelete, onDone, edit, companies, onBlockSelected, onUnBlockSelected, selectedFilter, filter, loading, onChange, className, ...rest} = props;

    const classes = style();

    const [width, setWidth] = React.useState(classes.root);

    const handleExit = () => {
        setWidth(classes.root);
    }
    const handleEnter = () => {
        setWidth(classes.rootFull);
    }

    return (
        <div {...rest} className={clsx(className)}>         
          <div className={width}>
              <ExpansionPanel 
                TransitionProps={{ onExited: handleExit, onEnter: handleEnter }}
              >
                  <ExpansionPanelSummary
                      expandIcon={<FilterListIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                      <Typography className={classes.heading}>
                          Szűrők
                       </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                      <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <Autocomplete
                                        multiple
                                        clearText="Alaphelyzet"
                                        noOptionsText="Nincs találat"
                                        closeText="Bezárás"
                                        id="filter-competences"
                                        name="competences"
                                        disabled={edit == null ? false : true}
                                        value={selectedFilter.competences}
                                        onChange={(event, value) => onChange("competences", value)}
                                        options={filter.competences}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                margin='dense'
                                                {...params}
                                                variant="outlined"
                                                label="Kompetenciák"
                                            />
                                        )}
                                    />
                                  </Grid>
                                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <Autocomplete
                                        clearText="Alaphelyzet"
                                        noOptionsText="Nincs találat"
                                        closeText="Bezárás"
                                        id="filter-active"
                                        name="active"
                                        value={filter.active}
                                        onChange={(event, value) => onChange("active", value)}
                                        options={validOptions}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                margin='dense'
                                                {...params}
                                                variant="outlined"
                                                label="Aktív"
                                            />
                                        )}
                                    />
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>                   
        </div>
    );
};

export default Toolbar;
