//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';


const StyledTableRow = style.StyledTableRow;

const RolesTable = props => {
  const {className, data, ...rest} = props;
  const classes = style.useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Azonosító</TableCell>
                    <TableCell align="center"  padding="none">Megnevezés</TableCell>
                    <TableCell align="center"  padding="none">Aktív</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((row,index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={row.id}
                  >
                    <TableCell padding="none" align="center" >
                      {row.id}
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      {row.label}
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      {row.isValid==true ? 'Igen' : 'Nem'}
                    </TableCell>          
                  </StyledTableRow>))
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default RolesTable;
