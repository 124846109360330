import React from 'react';
//Components
import { MuiOutlinedInput } from 'components'; 

export default function SetRelevance(props) {
    const { handleChange, value, disabled } = props;
    const maxValue = 100;

    const handleNumberFieldChange = (event) => {
        const value =  event.target.value;
        if(value!==null && value!==undefined && value!==''){
            handleChange('addNew', (value*1));
        }else{
            handleChange('addNew', value);
        }
     
    }

    return (
        <MuiOutlinedInput
            className={(value === null || value < 1 || value > maxValue) && "goal-new-add-notvalid"}
            id="input-relevance"
            type="number"
            margin="dense"
            helperText={(value === null || value < 1 || value > maxValue) && 'Nem megengedett érték'}
            error={(value === null || value < 1 || value > maxValue) ? true : false}
            endInputAdornment={"%"}
            minValue={0}
            maxValue={maxValue}
            value={value != null ? value : 0}
            label="Feladat súlya"
            handleChange={handleNumberFieldChange} />
    );
}
