import React from 'react';
//Core
import {
    Dialog,
    DialogActions,
    DialogContent,
    Avatar,
    Button,
    ButtonGroup,
    DialogTitle,
    Divider,
    Backdrop,
    CircularProgress,
    Typography,
    Grid,
    IconButton
} from '@material-ui/core';
import {apiRequest} from './apiRequest';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style
import theme from 'theme';
import style from './style';

export default function AddPicture(props) {
    const { open, onClose,onSave,user } = props;

    const [picture,setPicture] = React.useState(null);
    const [request,setRequest] =  React.useState(false);
    const [avatarPreview,setAvatarPreview] =  React.useState(null);

    function changeHandler(event){
        const files = event.target.files;
        if(files.length>0){
            setPicture(files[0])
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            setAvatarPreview(e.target.result);
        }
        reader.readAsDataURL(files[0]);
    }

    function emptyFile(){
        setPicture(null);
    }

    function handleUpload(){     
        setRequest(true);

        var data = new FormData();
        data.append('files',picture);

        let lastDot = picture.name.lastIndexOf('.');
        let length = picture.name.length;
        if(lastDot!==null && lastDot!==undefined){
            var fileType= picture.name.slice(lastDot+1,length);   
            data.append("fileType",fileType);
            apiRequest.upload(setPicture,setRequest,data);
        }
      }

    const classes = style();
    return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="update-dialog-title"
                aria-describedby="update-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: theme.palette.primary.main }} id="update-dialog-title">{<div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>Profilkép módosítása</Typography>
                </div>}
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid spacing={2} container>
                    <Grid item>
                    {request==false &&  
                        <div style={{padding:'2rem'}}>
                            <input accept="image/png, image/jpeg" id="file-input" type="file" name="file-input" onChange={changeHandler} multiple={false} />  
                        </div>
                    }
                    </Grid>
                    <Grid item>
                    {request==false && <Avatar
                        className={classes.avatar}
                        //src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        src={avatarPreview!=null ? avatarPreview : (user.avatar !== null && user.avatar !== undefined) ? user.avatar : null}
                    />}
                    {request==true &&
                        <div style={{padding:'1.5rem'}}>
                            <Backdrop
                                className={classes.loading}
                                open={true}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    }
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>

            <ButtonGroup variant="contained" color="primary">
                <Button
                    className={classes.saveButton} 
                    size="small"
                    onClick={handleUpload}
                    disabled={request===true || (picture===null || picture ===undefined)}
                    endIcon={<DoneIcon />}
                    
                >
                    Mentés
                </Button>
                <Button
                    disabled={request} 
                    size="small"
                    endIcon={<ArrowBackIcon />}
                    onClick={onClose}
                >
                    Vissza
                </Button>
            </ButtonGroup>
                </DialogActions>
            </Dialog>
    );
}
