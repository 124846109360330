import { makeStyles } from '@material-ui/styles';

const useStyles =  makeStyles(theme => ({
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize:'1.1rem'
    },
    description: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
        paddingBottom: theme.spacing(2)
    },
    doneButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        }
    }
}));


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export default {
styles:styles,
useStyles:useStyles
}
