//React

import React from 'react';
import clsx from 'clsx';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TextField,
  IconButton,
  CardActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';


const StyledTableRow = style.StyledTableRow;

const DataTable = props => {
  const {className, loading, competences, edit, onEdit, onDone, onOpen, onCancel, onValidChange, onChange, onSave, ...rest} = props;

  const classes = style.useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Azonosító</TableCell>
                    <TableCell align="center"  padding="none">Megnevezés</TableCell>
                    <TableCell align="center" padding="checkbox">Szín</TableCell>
                    <TableCell align="center" padding="none">Leírás</TableCell>               
                    <TableCell align="center" padding="none">Aktív</TableCell>
                    <TableCell align="center" padding="none">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {competences.map((comp,index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={comp.id}
                  >
                    <TableCell padding="none" align="center" >
                      {comp.id}
                    </TableCell>                                 
                    <TableCell padding="none" align="center" >
                        <TextField
                        disabled={(edit == null || (edit != null && edit.id != comp.id))}
                        value={comp.label}
                        onChange={(event)=>onChange(event,index,"label")}
                        fullWidth
                        />
                    </TableCell>
                    <TableCell padding="checkbox" align="center" >
                        <input 
                          disabled={(edit == null || (edit != null && edit.id != comp.id))} 
                          onChange={(event)=>onChange(event,index,"color")} 
                          style={{margin:'0.5rem'}} 
                          type="color" 
                          id="color" 
                          name="color"
                          value={comp.color}></input>
                    </TableCell>   
                    <TableCell padding="none" align="center" >
                      <TextField
                        disabled={(edit == null || (edit != null && edit.id != comp.id))}
                        value={comp.description}
                        onChange={(event)=>onChange(event,index,"description")}
                        multiline
                        fullWidth
                        />
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      
                      {comp.isValid===true ?
                      <Tooltip title="Tiltás">
                      <IconButton  disabled={(edit != null ? true : false)}  onClick={()=>onValidChange(index,false)} className={classes.doneIcon}>
                        <DoneIcon/>
                      </IconButton></Tooltip>  : 
                        <Tooltip title="Feloldás">
                      <IconButton  disabled={(edit != null ? true : false)}  onClick={()=>onValidChange(index,true)} className={classes.blockIcon}>
                        <BlockIcon/>
                      </IconButton></Tooltip>
                      }
                        {comp.isValid ? 'Igen' : 'Nem'}
                    </TableCell>
                    <TableCell padding="none" align="center">
                        {(edit == null || (edit != null && edit.id != comp.id)) &&
                              <Tooltip title="Módosítás">
                                <span>
                                  <IconButton 
                                    disabled={(edit != null ? true : false)} 
                                    onClick={() => onEdit(comp)} 
                                    className={classes.editButton} 
                                    aria-label="edit"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>  
                        }
                        {(edit != null && edit.id == comp.id) &&
                          <React.Fragment> 
                            <Tooltip title="Mentés">
                              <span>
                                <IconButton 
                                  disabled={(comp.label!==null && comp.label!==undefined && comp.label!=='' &&  comp.description!==null  && comp.description!==undefined && comp.description!=='') ? false : true}  
                                  onClick={()=>onSave(index)} 
                                  className={classes.saveButton} 
                                  aria-label="save"
                                >
                                  <DoneIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Mégsem">
                            <IconButton 
                              onClick={()=>onCancel(index)} 
                              className={classes.cancelButton} 
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip> 
                        </React.Fragment> 
                      }
                    </TableCell>
                  </StyledTableRow>))
                }
                {competences.length < 1 &&
                  <StyledTableRow>
                      <TableCell align="center" colSpan="6">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                  </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
            <Typography style={{ fontWeight: 'bold' }}>Új kompetencia létrehozása: </Typography>
            <Tooltip title="Új hozzáadás">
              <span>
                  <IconButton disabled={edit != null ? true : false} onClick={onOpen} className={classes.addButton} aria-label="add">
                    <AddIcon />
                  </IconButton>
              </span>
            </Tooltip>
        </div>
      </CardActions>
    </Card>
  );
};

export default DataTable;
