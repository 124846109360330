import theme from 'theme';

const useStyles = () => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    }
});

export default useStyles;
