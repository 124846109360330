import theme from 'theme';

const useStyles = () => ({
    root: {
        padding: theme.spacing(4)
    },
    appBar: {
        position: 'relative',
      },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color:'white',
        fontWeight:'bold',
        fontSize:'1.2rem',
        fontFamily:'Nunito'
    },
    footer:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        padding:'0.5rem',
      },
      subTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color:theme.palette.appMain.main,
        fontSize: '0.9rem',
        paddingBottom:'0.5rem',
      },
      point:{
        fontFamily: 'Nunito',
        fontSize: '0.8rem',
        padding:'0.5rem'
      },
      actions:{
          display:'flex',
          marginTop:'0.5rem',
          alignItems:'center',
          justifyContent:'flex-end',
          gap:'0.5rem'
      },
      mainButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.light}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        },
      },
      greenButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        },
      }
});

export default useStyles;
