import React,{Component} from 'react';
import { Route,Redirect  } from 'react-router-dom';
import PropTypes from 'prop-types';
//Components
import {UserStore,Constants} from '../../stores';
import {authenticationService} from '../../services';
//MaterialUI 
import {withStyles} from '@material-ui/core';
//Themes
import style from './style';



class RouteWithLayout extends Component{

  constructor(props){
    super(props);
    this.state={
      currentUser:UserStore.getUserValue(),
      request:UserStore.getRequestValue(),
      contentRequest:Constants.getcontentLoadingValue(),
      loggedInStatus:UserStore.getLoggedInStatusValue(),

    }
  }

  componentDidMount(){

     //Subscribe request value ('true','false')
    UserStore.getRequest().subscribe(res => this.setState({
      request: res,
    }));
    //Subscribe data request value ('true','false')
    Constants.getcontentLoading().subscribe(res => this.setState({
      contentRequest: res,
    }));
     //Subscribe logged in status value ('LOGGED_IN, NOT_LOGGED_IN')
    UserStore.getLoggedInStatus().subscribe(res => this.setState({
      loggedInStatus: res,
    }));
     //Subscribe user value 
    UserStore.getUser().subscribe(res => this.setState({
      currentUser: res,
    }));
    //Refresh token
    authenticationService.refreshToken();
  }

  componentWillUnmount () {
    //Clear the subscribes
    this.state={
      currentUser:null,
      request:null,
      loggedInStatus:null,
    }
  }

  render(){
    const { layout: Layout,path,classes,roles,authorize,location, component: Component, ...rest } = this.props;
    const {loggedInStatus,request,currentUser} = this.state;
    return (
      //if request is finished give back the component
      request===false && 
      //if component need authorize  
      (authorize ?
      <Route
        {...rest}
        render={matchProps => {
          if (loggedInStatus === 'NOT_LOGGED_IN' || loggedInStatus==='SESSION_IS_OVER') {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />
          }
           //check if route is restricted by role
          if (roles !== null && roles !== undefined && roles!=='' && roles!=='null' && roles.length>0) {
            var permission = false;
            if(currentUser.roles!==null && currentUser.roles!=='' && currentUser.roles!==undefined){
              permission = roles.some(r => currentUser.roles.indexOf(r) >= 0);
            }
            
            if (permission === false) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/permission-denied' }} />
            }

          }
        //if logged give back the component
            return <Layout>
                    <Component {...matchProps} />             
                  </Layout> 
      }}
      /> : 
      //if component not need authorize
      <Route
          {...rest}
          render={matchProps => {
            //if user is logged in but she wants to go in to the sing-in page then redirect to home page
            if (loggedInStatus==='LOGGED_IN' && path === "/sign-in") {
              return <Redirect to={{ pathname: currentUser.redirectTo, state: { from: location } }} />
            }
            //give back the component 
            return <Layout>           
              <Component {...matchProps} />
            </Layout>
          }}
      />)
    ); 
  }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default withStyles(style)(RouteWithLayout);