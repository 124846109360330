//React

import React, { Component } from 'react';
//Core

import {
    Button,
    Drawer,
    AppBar,
    IconButton,
    Toolbar,
    TextField
} from '@material-ui/core';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Components

import {
    AccountProfile,
    AccountDetails,
    HrDetails
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';


class EditUser extends Component {

    constructor(props) {
        super(props);

        apiRequest.getUser(this);
        apiRequest.getSettings(this);

        this.state = {
            deleted: '',
            user: null,
            loading: {
                user: true,
                settings: true
            },
            time: new Date(),
            settings: {
                autoSave: false
            }
        };
    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        
        if (prevState.loading != loading) {
            if (loading.user === false && loading.settings === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods

    handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: checked
            }
        }));
    }

    handleTextChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));
    }

    handleDeleteTextChange = (value) => {
        this.setState({
            deleted: value
        });
    }

    render() {
        const { user, time, loading, settings, deleted } = this.state;
        const { classes, snackbar, onClose, refreshTable } = this.props;

        return (loading.user === false && loading.settings === false &&
            <Drawer anchor={'right'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.drawer}>
                    <AccountProfile
                        userId={this.props.user.id}
                        user={user}
                        time={time}
                        refreshProfile={() => apiRequest.getUser(this)}
                        refreshTable={refreshTable}
                    />
                    <HrDetails
                        refreshTable={refreshTable}
                        userId={this.props.user.id}
                        refreshProfile={() => apiRequest.getUser(this)}
                    />
                    <AccountDetails
                        userId={this.props.user.id}
                        refreshProfile={() => apiRequest.getUser(this)}
                        refreshTable={refreshTable}
                        snackbar={snackbar}
                        onSave={this.handleSave}
                        onTextChange={this.handleTextChange}
                        onChange={this.handleChange}
                        settings={settings}
                        user={user}
                    />
                    <div className={classes.delete}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            label="A törléshez írd be a felhasználó nevét"
                            onChange={(event) => this.handleDeleteTextChange(event.target.value)}
                        />
                        <Button
                            className={classes.deleteButton}
                            variant="contained"
                            disabled={!(deleted === this.props.user.fullName)}
                            size="small"
                            onClick={this.handleSave}
                            endIcon={<DeleteIcon />}
                        >
                            Törlés
                        </Button>
                    </div>
                </div>
            </Drawer>
        );
    }
};

export default withStyles(style)(EditUser);
