import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    stepper: {
        '& .MuiStepLabel-label': {
            fontFamily: 'Nunito',
            fontSize: '0.8rem'
        },
        '& .MuiStepLabel-active': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '0.8rem'
        },
        '& .MuiStepIcon-active': {
            color: theme.palette.appMain.main
        },
         '& .MuiStepIcon-completed': {
            color: theme.palette.appMain.main
        }
    },
    stepLabel: {
        fontFamily:'Nunito'
    },
    gridRoot: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    doneButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        }
    },
    nextButton: {
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            backgroundColor: theme.palette.appMain.dark,
        },
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        color: theme.palette.white
    },
    bgWhite: {
        backgroundColor: theme.palette.white
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
    dividerBottom: {
        marginBottom: theme.spacing(2)
    }
}));
