import React from 'react';
//Components
import { MuiOutlinedSelect } from 'components';


export default function SelectList(props) {
    const { goal, goalId,disabled, data,readOnly, defValue, handleGoalsChange } = props;
    const [value, setValue] = React.useState((defValue !== null && defValue != undefined) ? defValue : 0);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleGoalsChange(goal, event.target.value);
    };

    return (
        <MuiOutlinedSelect
            disabled={disabled}
            margin="dense"
            className={(value === "0" || value === 0) && "goal-result-notvalid"}
            error={(value === "0" || value === 0) ? true : false}
            helperText="Kötelező választani"
            value={value}
            readOnly={readOnly}
            handleChange={handleChange}
            menuList={data}
            selectId="select-mot-value"
            label="Teljesítve" />
    );
}
