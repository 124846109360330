import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '& .MuiTab-textColorPrimary.Mui-selected': {
                color: theme.palette.appMain.main
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.appMain.main,
        },
        '& .MuiTab-root': {
            textTransform: 'none',
        },
      
    },
    tabs: {
        '& .MuiTab- textColorPrimary': {
            color: theme.palette.white
        },
        margin: 'auto',
    }
}));
