//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    Typography,
    Card,
    CardHeader,
    Divider,
    Select,
    MenuItem,
    Button,
    CardActions,
    Backdrop,
    CircularProgress,
    Avatar
} from '@material-ui/core';
//Icons
import WidgetsIcon from '@material-ui/icons/Widgets';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class ExportDatas extends Component  {

    constructor(props) {
        super(props);
        this.state={
            periods:[],
            loading:{
                periods:true,
                download:false
            },
            period:0
        };
        Constants.setcontentLoading(true);
    }

    componentDidMount() {
        Constants.setcontentLoading(false);
        apiRequest.populatePeriods(this);
    }

    handleClick = (name) =>{
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: true,
            },
        }));

        if(name=="selfRatings"){
            apiRequest.exportSelfRatings(this);
        }else if(name=="leaderRatings"){
            apiRequest.exportLeaderRatings(this);
        }else if(name=="motivations"){
            apiRequest.exportMotivationDatas(this);
        }else if(name=="gencomp"){
            apiRequest.exportGeneralCompetences(this);
        }else if(name=="ledcomp"){
            apiRequest.exportLeaderCompetences(this);
        }else if(name=="sumcomp"){
            apiRequest.exportSumCompetences(this);
        }else if(name=="newgoals"){
            apiRequest.exportNewGoalResult(this);
        }else if(name=="resgoals"){
            apiRequest.exportGoalResult(this);
        }
    }

    handleChange = (event)=>{
        const value = event.target.value;
        this.setState({
            period:value
        });
    }
    
    render() {
        const {loading, period, periods} = this.state;
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={loading.download}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography className={classes.title}>Adatok exportálása</Typography>
                <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Select
                            labelId="period-select-label"
                            id="period-select"
                            value={period}
                            className={classes.select} 
                            onChange={this.handleChange}
                        >
                            <MenuItem  value={0}>Válaszd ki</MenuItem>)
                            {periods.map(period=>
                            <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                        </Select>
                    </Grid>
                </Grid>     
                <Grid container spacing={4}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <AssignmentTurnedInIcon className={classes.avatar__icon}/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Önértékelés</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                onClick={()=>this.handleClick("selfRatings")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>   
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <PermContactCalendarIcon className={classes.avatar__icon}/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Vezetői értékelés</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                onClick={()=>this.handleClick("leaderRatings")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>   
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <ThumbUpIcon className={classes.avatar__icon}/>
                                        </Avatar>
                                    }
                                    title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Motivációk</Typography>}
                                    style={{ backgroundColor: theme.palette.warning.light }}
                                />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                    onClick={()=>this.handleClick("motivations")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <WidgetsIcon className={classes.avatar__icon}/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Általános kompetenciák</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                    onClick={()=>this.handleClick("gencomp")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <WidgetsIcon className={classes.avatar__icon}/>
                                        </Avatar>
                                    }
                                    title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Vezetői kompetenciák</Typography>}
                                    style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                onClick={()=>this.handleClick("ledcomp")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <WidgetsIcon className={classes.avatar__icon}/>
                                        </Avatar>
                                    }
                                    title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Összegzett kompetencia eredmények</Typography>}
                                    style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                onClick={()=>this.handleClick("sumcomp")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>   
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FormatListBulletedIcon className={classes.avatar__icon}/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Új célkitűzések</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}
                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                    onClick={()=>this.handleClick("newgoals")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>    
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <PlaylistAddCheckIcon className={classes.avatar__icon}/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6" style={{color:'white',fontFamily:'Nunito',fontWeight:'bold'}}>Célkitűzések kiértékelése</Typography>}
                                style={{ backgroundColor: theme.palette.warning.light }}

                            />
                            <Divider />
                            <CardActions className={classes.actions}>
                                <Button
                                    disabled={(loading.periods==true || period==''|| period==undefined || period ===null)}
                                    onClick={()=>this.handleClick("resgoals")}
                                    color="primary"
                                    size="small"
                                    variant="text"
                                >
                                    Letöltés <GetAppIcon />
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>                                                
                </Grid>
            </div>
        )
    }
   
}
export default withStyles(style)(ExportDatas);
