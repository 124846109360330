import { BehaviorSubject } from 'rxjs';
import { useSnackbar } from 'notistack';

export class ConstantsInitializer {
    snackbar;
    history;
    apiRoute = `${process.env.REACT_APP_API_URL}/`;
    //Token expired time in milliseconds
    tokenExpired = 300000 //5min
    contentLoading;
    topBarLoading;
  
    constructor () {
      this.contentLoading = new BehaviorSubject(false);
      this.topBarLoading = new BehaviorSubject(false);
    }

    setSnackbar(snackbar){
        this.snackbar = snackbar;
    }

    getSnackbar(){
      return this.snackbar;
    }

    setHistory(history){
      this.history = history;
    }

    getHistory(){
    return this.history;
    }

    getApiRoute(){
      return this.apiRoute;
    }

    getTokenExpired(){
        return this.tokenExpired;
    }

    getcontentLoading(){
      return this.contentLoading;
    }
    getcontentLoadingValue(){
      return this.contentLoading.value;
    }
    setcontentLoading(contentLoading){
      this.contentLoading.next(contentLoading);
    }

    getTopBarLoading(){
      return this.topBarLoading;
    }
    getTopBarLoadingValue(){
      return this.topBarLoading.value;
    }
    setTopBarLoading(topBarLoading){
      this.topBarLoading.next(topBarLoading);
    }
}

export const Constants = new ConstantsInitializer();