//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Button, 
    Popper, 
    Typography, 
    IconButton, 
    Fade, 
    Paper,
    Drawer,
    AppBar,
    Toolbar 
} from '@material-ui/core';
//Styles

import { 
    makeStyles, 
    withStyles 
} from '@material-ui/styles';
import style from './style';
import theme from 'theme';
//Components

import { 
    AccountProfile, 
    ActiveLeader, 
    LeadersTable, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class EditUser extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(true);
        apiRequest.getUser(this);
        apiRequest.getLeader(this);      

        this.state = {
            user: null,
            leader: null,
            lastValidFromForAdd: null,
            lastValidFromForEdit: null,
            isModifable: false,
            approved:false,
            leaders: [],
            warning:false,
            baseLeaders: [],
            anchorEl:null,
            loading: {
                user: true,
                leader: true
            },
            addNew:false,
        }
    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const {loading} = this.state;

        if (prevState.loading != loading) {
            if (loading.user === false && loading.leader === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods

    handleEdit = (leader) => {
        const leaders = [...this.state.leaders];
        const index = leaders.indexOf(leader);
        leaders[index] = { ...leader };
        leaders[index].disabled = false;
        this.setState({ leaders });        
    }

    handleDelete = (leader) => {
        apiRequest.deleteLeader(this,leader);
    }

    handleBack = (leader) => {
        const leaders = [...this.state.baseLeaders];
        this.setState({ leaders });
    }

    handleDone = (leader) => {
        const leaders = [...this.state.leaders];
        const index = leaders.indexOf(leader);
        leaders[index] = { ...leader };
        leaders[index].disabled = true;
        this.setState({ leaders });
        apiRequest.saveLeader(this,leader);
    }

    handleValidFrom = (leader, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 

        const leaders = [...this.state.leaders];
        const index = leaders.indexOf(leader);
        leaders[index] = { ...leader };
        leaders[index].validFrom = newDate;
        this.setState({ leaders });
    }

    handleValidTo = (leader, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 

        const leaders = [...this.state.leaders];
        const index = leaders.indexOf(leader);
        leaders[index] = { ...leader };
        leaders[index].validTo = newDate;
        this.setState({ leaders });
    }

    handleOpenWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState=>({
            anchorEl: target,
            warning: !this.state.warning
        }));
    };

    handleCloseWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            anchorEl: null,
            warning: false
        }))
    };


    handleClose = () => {
        this.setState({
            addNew:false
        });
    }

    handleOpen = () => {
        this.setState({
            addNew: true
        });
    }

    handleSave = () => {
        Constants.setcontentLoading(true);
        this.setState(prevState=>({
            addNew: false,
            loading: {
                ...prevState.loading,
                user: true,
                leader: true
            },
        }));
        apiRequest.getUser(this);
        apiRequest.getLeader(this);
    }

    handleApproved = () => {
        this.setState({
            approved: true,
            anchorEl: null,
            warning: false
        })
    }

    render() {
        const {user, leader, warning, approved, anchorEl, isModifable, lastValidFromForAdd, lastValidFromForEdit, addNew, leaders, loading } = this.state;
        const {classes, snackbar, onEdit, onClose, refresh} = this.props;

        return ((loading.user === false && loading.leader === false ) &&
            <Drawer  anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <div style={{flexGrow:1}}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Popper open={warning} anchorEl={anchorEl} placement='bottom' transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.helperPaper}>
                                    <Typography className={classes.helperText}><b>Az adatok módosítása esetén, a felhasználók által bevitt adatok törlődnek!</b></Typography>
                                    <Typography className={classes.helperText}><b>Ha mégis módosítani szeretnél kérlek ennek tudatában tedd!</b></Typography>
                                    {approved == true && <Button variant="outlined" disabled={true} className={classes.approvedButton} size="small">Jóváhagyva</Button>}
                                    {approved == false && <Button disabled={true} variant="outlined" onClick={this.handleApproved} className={classes.approveButton} size="small">Jóváhagyás</Button>}
                                    <Button variant="outlined" onClick={this.handleCloseWarning} className={classes.backButton} size="small">Bezár</Button>
                                </Paper>
                            </Fade>
                        )}
                </Popper>
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                    <AccountProfile user={user} />
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                    {leader !== null && leader !== undefined && < ActiveLeader user={leader} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <LeadersTable
                            onOpenWarning={this.handleOpenWarning} 
                            approved={approved} 
                            isModifable={isModifable} 
                            lastValidFrom={lastValidFromForEdit} 
                            onDelete={this.handleDelete} 
                            onOpen={this.handleOpen} 
                            onValidFromChange={this.handleValidFrom}
                            onValidToChange={this.handleValidTo} 
                            onBack={this.handleBack} 
                            onEdit={this.handleEdit} 
                            onDone={this.handleDone} 
                            users={leaders} 
                        />
                    </Grid>
                </Grid>
                {addNew === true && 
                    <AddNewDialog
                        refresh={refresh} 
                        lastValidFrom={lastValidFromForAdd} 
                        onSave={this.handleSave} 
                        snackbar={snackbar} 
                        user={user} 
                        onClose={this.handleClose} 
                        open={addNew} 
                    />
                }
                </div> 
            </Drawer> 
        );
    }
};

export default withStyles(style)(EditUser);
