//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    NotificationBar,
    Progress
} from 'components';
//Actions

const Actions = {
    GetCompanies: 'probaido/auto-complete/companies',
    GetBios: 'probaido/auto-complete/bios',
    GetOrganizations: 'probaido/auto-complete/organizations',
    GetLeaders: 'probaido/auto-complete/leaders',
    GetLeader: 'probaido/auto-complete/leader',
    GetHrs: 'probaido/auto-complete/hrs',
    SaveUser: 'probaido/admin/user/add-new'
}

export const apiRequest = {
    populateCompanies,
    populateBios,
    populateOrganizations,
    populateLeaders,
    populateHrs,
    getLeader,
    saveUser
};

async function saveUser(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    const { hireDate, fullName, email, domainName, endDate, bio, leader, hr, organization, company } = parent.state;
    const { onClose, refreshTable } = parent.props;

    const model = {
        hireDate,
        fullName,
        endDate,
        bio,
        email,
        domainName,
        hr,
        leader,
        organization,
        company
    }

    await new Api().call.post(Actions.SaveUser, model)
        .then(handleResponse)
        .then(res => {
            refreshTable();
            onClose();
        }).catch(handleError);
}

async function populateBios(parent) {
    const { company } = parent.state;

    await new Api().call.get(`${Actions.GetBios}/${company.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                },
                bios: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                }
            }));
        });
}

async function populateOrganizations(parent) {
    const { company } = parent.state;

    await new Api().call.get(`${Actions.GetOrganizations}/${company.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });
}

async function populateCompanies(parent) {
    await new Api().call.get(Actions.GetCompanies)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    companies: false
                },
                companies: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    companies: false
                }
            }));
        });
}

async function populateLeaders(parent) {
    await new Api().call.get(Actions.GetLeaders)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    leaders: false
                },
                leaders: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    leaders: false
                }
            }));
        });
}

async function populateHrs(parent) {
    await new Api().call.get(Actions.GetHrs)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                },
                hrs: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                }
            }));
        });
}

async function getLeader(parent) {
    const { organization } = parent.state;

    await new Api().call.get(`${Actions.GetLeader}/${organization.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                leader: res
            }));
        }).catch(error => {
            handleError(error);
        });
}
