//React

import React from 'react';
//Components

import { MuiOutlinedInput } from 'components';

export default function TextField(props) {
    const { unit, value, label, readOnly, inputId } = props;

    return (
        <MuiOutlinedInput
            margin="dense"
            readOnly={readOnly}
            endInputAdornment={unit}
            label={label}
            inputId={inputId}
            value={value} 
        />
    )
}
