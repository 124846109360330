import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  loginRoot:{
    minHeight: '100vh',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      margin: '1rem'
    }
  },
  loginHeaderLogoContainer:{
    paddingTop:'0.5rem',
    paddingLeft: '1rem',

    [theme.breakpoints.up('md')]: {
      paddingTop: '1rem',
       paddingLeft: '1.5rem'
    }
  },
  loginHeaderLogoContainerImg:{
    maxHeight:'2.5rem',
    objectFit: 'cover', 

    [theme.breakpoints.up('md')]: {
      maxHeight: '3.5rem'
    }
  },
  loginAlertWrapper:{
    margin:'auto'
  },
  loginContainer:{
    display: 'grid',
    gridTemplateColumns:'repeat(1, minmax(0, 1fr))',
    placeItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '56rem',
    boxShadow:'rgba(0, 0, 0, 0.15) 0px 2px 8px !important',
    borderRadius: '0.25rem !important',

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
    }
  },
  backdropLoading:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:'100%',
    position:'absolute',
    width:'100%',
    backgroundColor:'transparent',
    backdropFilter: 'blur(2px)',
    zIndex:'100'
  },
  loginForm:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: '1rem',
    padding: '2rem',
    flexDirection: 'column',
    backgroundColor:'white',
    borderRadius: '0.25rem',

    [theme.breakpoints.up('md')]:{
        borderRadius: '0.25rem 0rem 0rem 0.25rem',
        padding: '0rem'
    }
  },
  loginFormInputs:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    paddingTop: '0.75rem',
    width: '100%',
    paddingLeft: '0rem',
    paddingRight: '0rem',

    [theme.breakpoints.up('md')]:{
      paddingLeft:'2rem',
      paddingRight: '2rem'
    } 
  },
  loginFormLogosContainer:{
    display:'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent:'center',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  loginFormLogosContainerImg:{
    maxHeight: '8rem',
    objectFit: 'cover'
  },
  loginFormCompany:{
    fontSize: '1.5rem',
    fontWeight: '600'
  },
  loginFormTitle:{
    fontWeight: '600',
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
  loginContainerImg:{
    height: '100%',
    maxWidth: '100%',
    display: 'none',
    objectFit: 'cover',

    [theme.breakpoints.up('md')]:{
      display: 'block',
      borderRadius: '0rem 0.25rem 0.25rem 0rem'
    } 
  },
  helpButtonsContainer:{
    padding:'0.75rem',
    display:'flex',
    flexDirection:'column',
    gap:'0.5rem',
    marginBottom:'0.5rem'
  },
  helperPaper: {
    padding: theme.spacing(2),
    zIndex:'1000',
    backgroundColor:'white'
  },
  helperText: {
    fontFamily: 'Nunito',
  },
  help_video_container:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    margin:'1rem'
  },
  help_video:{
    border:'1px solid '+theme.palette.appMain.main,
    width:'640px',
    [theme.breakpoints.down('md')]: {
      width:'320px',
    },
    height:'auto',
    borderRadius:'0.5rem'
  },
  help_video_title:{
    fontWeight:'600'
  },
  systemsContainer:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'row',
    alignItems:'center',
    gap:'0.5rem'
  }
  /*root:{
    [theme.breakpoints.down('sm')]: {
      paddingTop:'35px'
    },
    paddingTop:'130px'
  },
  help: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  helperPaper: {
    padding: theme.spacing(2),
    zIndex:'1000',
    backgroundColor:'white'
  },
  helperText: {
    fontFamily: 'Nunito',
  },
  logo_container:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  help_container:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    margin:'0.5rem'
  },
  help_video_container:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    margin:'1rem'
  },
  help_video:{
    border:'1px solid '+theme.palette.appMain.main,
    width:'640px',
    [theme.breakpoints.down('md')]: {
      width:'320px',
    },
    height:'auto',
    borderRadius:'0.5rem'
  },
  help_video_title:{
    fontWeight:'600'
  },
  help_container_button:{
    textTransform:'capitalize', 
    fontFamily:'Nunito', 
    borderRadius:'0px', 
    border:`2px solid white`,
    backgroundColor:theme.palette.appMain.main,
    color:'white',
    '&:hover': {
        backgroundColor:'white',
        color:theme.palette.appMain.main,
        border:`2px solid ${theme.palette.appMain.main}`,
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    '&:disabled':{
        border:`2px solid `+theme.palette.disabled.default,
        backgroundColor:'white',
        color:theme.palette.disabled.default,
    }
  },
  logo_bg:{
    [theme.breakpoints.down('sm')]: {
      padding:'16px 48px', 
      width:'196px',
    },

    padding:'15px 85px', 
    width:'320px',
    textAlign:'center',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    backgroundColor:theme.palette.appMain.main,
    borderRadius:'10px'
  },
  logo:{
    [theme.breakpoints.down('sm')]: {
      width:'75px',
    },
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    width:'150px'
  },
  body_container:{
    [theme.breakpoints.down('sm')]: {
      margin:'10px 55px ', 
    },
    margin:'10px 35px ', 
    borderRadius:'10px'
  },
  textField:{
    [theme.breakpoints.down('sm')]: {
      width:'200px'
    },
    width:'280px'
  },
  loginBox_container:{
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'center', 
    flexDirection:'row'
  },
  system_container:{
    display:'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection:'column',
    },
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    gap:'0.5rem',
    margin:'1rem'

  },
  ter_system:{
    background:'url(/images/ter_login_box_lg.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    alignItems:'center',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    cursor:'pointer',
    borderRadius:'10px',
    '&:hover': {
      background:'url(/images/ter_login_box_lg-hover.png)',
      backgroundPosition:'center',
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
    }
  },
  ter_system_disabled:{
    background:'url(/images/ter_login_box_lg-disabled.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    alignItems:'center',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    borderRadius:'10px',
  },
  probaido_sytem:{
    background:'url(/images/probaido_login_box_lg.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    cursor:'pointer',
    borderRadius:'10px',
    '&:hover': {
      background:'url(/images/probaido_login_box_lg-hover.png)',
      backgroundPosition:'center',
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
    }
  },
  probaido_sytem_disabled:{
    background:'url(/images/probaido_login_box_lg-disabled.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    borderRadius:'10px'
  },
  exit_system:{
    background:'url(/images/exit_login_box_lg.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    alignItems:'center', 
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    cursor:'pointer',
    borderRadius:'10px',
    '&:hover': {
      background:'url(/images/exit_login_box_lg-hover.png)',
      backgroundPosition:'center',
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
    }
  },
  exit_system_disabled:{
    background:'url(/images/exit_login_box_lg-disabled.png)',
    backgroundPosition:'center',
    [theme.breakpoints.down('md')]: {
      width:'15rem',
      height:'8rem',
    },
    height:'15rem',
    width:'10rem',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    alignItems:'center', 
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
    border:`1px solid ${theme.palette.appMain.main}`,
    borderRadius:'10px',
  },
  system_name_container:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:'100%',
  },
  system_name:{
    backgroundColor:theme.palette.appMain.main,
    padding:'0.5rem',
    fontWeight:'bold',
    fontSize:'1rem',
    color:'white',
    borderRadius:'5px',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)', 
  }
*/
}));
