//React

import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core

import {
    Box,
    Grid,
    CircularProgress,
    Typography,
    Divider,
    Badge,
    ButtonGroup,
    Button
} from '@material-ui/core';
//Components

import { 
    Pagination, 
    MuiOutlinedSelect 
} from 'components';
import { 
    GoalsGrid, 
    AddGoal, 
    EditGoal, 
    GoalsGridForCopy 
} from './components';
import $ from "jquery";
import { apiRequest } from './apiRequest';
import { UserStore } from 'stores';
//Icons

import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done'
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';

const StyledErrorBadge = withStyles((theme) => ({
    badge: {
        right: -15,
        padding: '0 4px',
        backgroundColor: theme.palette.error.light
    },
}))(Badge);

const StyledOkBadge = withStyles((theme) => ({
    badge: {
        right: -15,
        padding: '0 4px',
        backgroundColor: theme.palette.success.light
    },
}))(Badge);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


class TabPanels extends Component {

    constructor(props) {
        super(props);
        const { useCopy, usePreviousCopy } = this.props;
        this.state = {
            goals: (useCopy === false && usePreviousCopy === false) ? this.props.lazyData.goals : [],
            baseGoals: (useCopy === false && usePreviousCopy === false) ? this.props.lazyData.baseGoals : [],
            loading: (useCopy === false && usePreviousCopy === false) ? false : true,
            addNew: null,
            edit: null
        };

        this.initState = this.initState.bind(this);

    }


    //#Lifecycle methods
    componentDidMount() {
        const { selectedType, useCopy, usePreviousCopy } = this.props;
        if (useCopy) {
            apiRequest.populateGoalsForCopy(this, selectedType.id);
        } else if (usePreviousCopy) {
            apiRequest.populateGoalsForPreviousCopy(this, selectedType.id);
        } else {
            this.initState();
            apiRequest.populateGoals(this, 1, selectedType.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { goals } = this.state;
        const { setUserSelectable, onCopy, onPreviousCopy, selectedType } = this.props;
        if (this.props.userId !== prevProps.userId) {

            this.initState();
            apiRequest.populateGoals(this, 1, selectedType.id);
            onCopy(false);
            onPreviousCopy(false);
            setUserSelectable(true);
        }
        if (prevProps.selectedType.id != selectedType.id) {
            //Refresh table
            this.initState();
            apiRequest.populateGoals(this, 1, selectedType.id);

        }
    }
    //#EndLifecycle methods

    handleScaleChange = (prop, value) => {
        this.setState(prevState => ({
            [prop]: {
                ...prevState[[prop]],
                base: {
                    ...prevState[[prop]].base,
                    scale: value,
                    expectedValue: value.defExpected
                }
            }
        }));
    }

    handleDescriptionChange = (prop, event) => {
        const value = event.target.value;
        this.setState(prevState => ({
            [prop]: {
                ...prevState[[prop]],
                base: {
                    ...prevState[[prop]].base,
                    description: value
                }
            }
        }));
    }

    handleExpectedChange = (prop, value) => {
        this.setState(prevState => ({
            [prop]: {
                ...prevState[[prop]],
                base: {
                    ...prevState[[prop]].base,
                    expectedValue: value
                }
            }
        }));
    }

    handleRelevanceChange = (prop, value) => {
        this.setState(prevState => ({
            [prop]: {
                ...prevState[[prop]],
                base: {
                    ...prevState[[prop]].base,
                    relevance: value
                }
            }
        }));
    }

    //Open add new window
    handleClickAddNew = (value, goalType) => {
        const { goals } = this.state;
        if (value) {
            //Scroll up to modify window
            $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
            this.setState({
                edit: null,
                addNew: {
                    goalType: goalType,
                    base: {
                        scale: null,
                        description: null,
                        expectedValue: null,
                        relevance: 100 - goals.relevanceSum
                    }
                },
            });
            this.props.setUserSelectable(false);
        }
        else {
            this.setState({
                addNew: null,
                edit: null
            });
            this.props.setUserSelectable(true);
        }

    }
    //Open modify window
    handleClickModify = (row, goalType, currentPage) => {
        //Scroll up to modify window
        $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
        if (row != null) {
            this.setState({
                addNew: null,
                edit: {
                    goalType: goalType,
                    currentPage: currentPage,
                    base: row
                },
            });
            this.props.setUserSelectable(false);
        }
        else {
            this.setState({
                edit: null,
                addNew: null
            });
            this.props.setUserSelectable(true);
        }
    }

    //Save new element
    handleClickAddDone = (value) => {
        const { userId } = this.props;
        var model = {
            id: value.id,
            userId: userId,
            scaleId: value.base.scale.id,
            typeId: value.goalType.id,
            description: value.base.description,
            expected: value.base.expectedValue.toString(),
            relevance: value.base.relevance
        }
        apiRequest.handleSave(this, model, -1, value.goalType);
        this.props.setUserSelectable(true);
    }

    //Save modify changes
    handleClickModifyDone = (value) => {
        const { userId } = this.props;
        var model = {
            id: value.base.id,
            userId: userId,
            scaleId: value.base.scale.id,
            typeId: value.goalType.id,
            description: value.base.description,
            expected: value.base.expectedValue.toString(),
            relevance: value.base.relevance
        }
        apiRequest.handleSave(this, model, value.currentPage, value.goalType);
        this.props.setUserSelectable(true);
    }

    //Remove selected element
    handleClickRemove = (value, currentPage, goalType) => {
        const { userId } = this.props;
        var model = {
            id: value.id,
            userId: userId,
            typeId: value.typeId,
        }
        apiRequest.handleRemove(this, model, currentPage, goalType);
    }

    //When change page on the pagination element
    handlePageChanges = (currentPage, goalType) => {
        this.initState();
        apiRequest.populateGoals(this, currentPage, goalType.id);
        $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
    }

    async initState() {
        await this.setState(prevState => ({
            goals: null,
            baseGoals: null,
            loading: true,
            addNew: null,
            edit: null,
        }));
    }

    render() {
        const { userId, value, index, classes, goalTypes, snackbar, useCopy, usePreviousCopy, selectedType, selectedUsersForCopy, selectedProjectsForCopy, paginationEnabled } = this.props;
        const { onSelectAllProjectForCopy, onSelectProjectForCopy, onCopy, onPreviousCopy, onSelectChange, onSaveCopies, onSavePreviousCopies } = this.props;
        const { goals, loading, addNew, open, edit } = this.state;

        const loggedBy = UserStore.getLoggedByAdminValue();

        return (
            <TabPanel
                value={value}
                index={index}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        {addNew != null &&
                            <AddGoal
                                snackbar={snackbar}
                                value={value}
                                addNew={addNew}
                                goalTypeMatrix={goals.goalType}
                                onScaleChange={this.handleScaleChange}
                                onDescriptionChange={this.handleDescriptionChange}
                                onExpectedChange={this.handleExpectedChange}
                                onRelevanceChange={this.handleRelevanceChange}
                                onClickAddDone={this.handleClickAddDone}
                                onClickAddNew={this.handleClickAddNew} />}

                        {edit != null &&
                            <EditGoal
                                snackbar={snackbar}
                                edit={edit}
                                goalTypeMatrix={goals.goalType}
                                onScaleChange={this.handleScaleChange}
                                onDescriptionChange={this.handleDescriptionChange}
                                onRelevanceChange={this.handleRelevanceChange}
                                onExpectedChange={this.handleExpectedChange}
                                onClickModifyDone={this.handleClickModifyDone}
                                onClickModify={this.handleClickModify} />}
                    </Grid>
                </Grid>
                {addNew === null && edit === null && useCopy === false && usePreviousCopy === false &&
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={3}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <MuiOutlinedSelect
                                className={classes.select}
                                menuList={goalTypes}
                                margin="dense"
                                handleChange={onSelectChange}
                                value={selectedType !== null ? selectedType.id : 0}
                                label="Célkitűzések"
                            />
                        </Grid>
                    </Grid>}
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                {loading === false && addNew === null && edit === null && useCopy === false && usePreviousCopy === false &&
                                    <div style={{ textAlign: 'right' }}>
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => this.handleClickAddNew(true, goals.goalType)}
                                                className={classes.addNewButton}
                                                disabled={(goals.goalType.allowQuantity > goals.total ? false : true) || (loggedBy != null && loggedBy.period.disabled)}
                                                size="small"
                                                endIcon={<LibraryAddIcon />}
                                            >
                                                Új hozzáadása
                                            </Button>
                                            <Button
                                                onClick={() => onCopy(true)}
                                                className={classes.copyButton}
                                                disabled={(goals.goalsForTable.length > 0 ? false : true) || (loggedBy != null && loggedBy.period.disabled)}
                                                size="small"
                                                endIcon={<FileCopyIcon />}
                                            >
                                                Másolás
                                            </Button>
                                            <Button
                                                onClick={() => onPreviousCopy(true)}
                                                className={classes.copyButton}
                                                disabled={(loggedBy != null && loggedBy.period.disabled)}
                                                size="small"
                                                endIcon={<FileCopyIcon />}
                                            >
                                                Előző év
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                }
                                {loading === false && addNew === null && edit === null && useCopy === true &&
                                    <ButtonGroup style={{ padding: '1.0rem' }}>
                                        <Button
                                            onClick={onSaveCopies}
                                            className={classes.doneButton}
                                            disabled={selectedUsersForCopy.length < 1 || selectedProjectsForCopy.length < 1}
                                            size="small"
                                            endIcon={<DoneIcon />}
                                        >
                                            Mentés
                                        </Button>
                                        <Button
                                            onClick={() => onCopy(false)}
                                            className={classes.cancelButton}
                                            size="small"
                                            endIcon={<CloseIcon />}
                                        >
                                            Vissza
                                        </Button>
                                    </ButtonGroup>
                                }
                                {loading === false && addNew === null && edit === null && usePreviousCopy === true &&
                                    <ButtonGroup style={{ padding: '1.0rem' }}>
                                        <Button
                                            onClick={()=>onSavePreviousCopies(userId)}
                                            className={classes.doneButton}
                                            disabled={selectedProjectsForCopy.length < 1}
                                            size="small"
                                            endIcon={<DoneIcon />}
                                        >
                                            Mentés
                                        </Button>
                                        <Button
                                            onClick={() => onPreviousCopy(false)}
                                            className={classes.cancelButton}
                                            size="small"
                                            endIcon={<CloseIcon />}
                                        >
                                            Vissza
                                        </Button>
                                    </ButtonGroup>
                                }
                            </Grid>
                        </Grid>
                        {loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                            <React.Fragment>

                                {goals.goalType.isWeighted ? goals.relevanceSum != goals.goalType.weightSum ?
                                    <StyledErrorBadge badgeContent={`${goals.relevanceSum}%`} className={classes.sumBadge} color="primary">
                                        <Typography className={classes.subTitle}>{selectedType.label}</Typography>
                                    </StyledErrorBadge>
                                    : <StyledOkBadge badgeContent={`${goals.relevanceSum}%`} className={classes.sumBadge} color="primary">
                                        <Typography className={classes.subTitle}>{selectedType.label}</Typography>
                                    </StyledOkBadge>
                                    : <Typography className={classes.subTitle}>{selectedType.label}</Typography>
                                }

                                <Divider className={classes.divider} />
                                {(useCopy === false && usePreviousCopy === false) ?
                                    <GoalsGrid onClickRemove={this.handleClickRemove}
                                        goalType={selectedType}
                                        goalTypeMatrix={goals.goalType}
                                        edit={edit}
                                        addNew={addNew}
                                        title={selectedType.label}
                                        handleClickModify={this.handleClickModify}
                                        data={goals.goalsForTable}
                                        currentPage={goals.currentPage}
                                    /> :
                                    <GoalsGridForCopy
                                        onSelectAllProjectForCopy={onSelectAllProjectForCopy}
                                        onSelectProjectForCopy={onSelectProjectForCopy}
                                        selectedProjectsForCopy={selectedProjectsForCopy}
                                        goalType={selectedType}
                                        goalTypeMatrix={goals.goalType}
                                        data={goals.goalsForTable}
                                        usePreviousCopy={usePreviousCopy}
                                    />}

                                {goals.step < goals.total && useCopy === false && usePreviousCopy === false &&
                                    <Pagination
                                        disabled={!paginationEnabled}
                                        goalType={selectedType}
                                        onPageChanges={this.handlePageChanges}
                                        step={goals.step}
                                        currentPage={goals.currentPage}
                                        pages={goals.pages}
                                        total={goals.total}
                                    />
                                }
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </TabPanel>);
    }
}

export default withStyles(style)(TabPanels);
