//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    Grid,
    CardHeader,
    Divider,
    ButtonGroup,
    Paper,
    Button
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
//Components

import {
    WriteDescription,
    SelectScale,
    SetExpected,
    SetRelevance
} from './components';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';


const ContentWithWeight = (props) => {
    const { snackbar, edit } = props;
    const { onScaleChange, onDescriptionChange, onExpectedChange, onRelevanceChange } = props;
    return (<Grid
        container
        spacing={4}
    >
        <Grid
            item
            md={3}
            xs={12}
        >
            <SelectScale
                snackbar={snackbar}
                goalType={edit.goalType}
                value={edit.base.scale.id}
                handleChange={onScaleChange} />
        </Grid>
        <Grid
            item
            md={3}
            xs={12}
        >
            <WriteDescription
                value={edit.base.description}
                handleChange={onDescriptionChange} />
        </Grid>
        <Grid
            item
            md={3}
            xs={12}
        >
            <SetExpected
                value={edit.base.expectedValue}
                handleChange={onExpectedChange}
                selectedScale={edit.base.scale} />
        </Grid>
        <Grid
            item
            md={3}
            xs={12}
        >
            <SetRelevance
                value={edit.base.relevance}
                handleChange={onRelevanceChange}
            />
        </Grid>
    </Grid>)
}

const ContentWithoutWeight = (props) => {
    const { snackbar, edit } = props;
    const { onScaleChange, onDescriptionChange, onExpectedChange } = props;
    return (<Grid
        container
        spacing={4}
    >
        <Grid
            item
            md={3}
            xs={12}
        >
            <SelectScale
                snackbar={snackbar}
                goalType={edit.goalType}
                value={edit.base.scale.id}
                handleChange={onScaleChange} />
        </Grid>
        <Grid
            item
            md={6}
            xs={12}
        >
            <WriteDescription
                value={edit.base.description}
                handleChange={onDescriptionChange} />
        </Grid>
        <Grid
            item
            md={3}
            xs={12}
        >
            <SetExpected
                value={edit.base.expectedValue}
                handleChange={onExpectedChange}
                selectedScale={edit.base.scale} />
        </Grid>
    </Grid>)
}


class EditGoal extends Component {

    state = {
        isValid: false
    }
    componentDidMount() {
        if (document.getElementsByClassName("goal-new-edit-notvalid").length === 0) {
            this.setState({
                isValid: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (document.getElementsByClassName("goal-new-edit-notvalid").length === 0) {
            if (prevState.isValid !== true) {
                this.setState({
                    isValid: true
                });
            }
        } else {
            if (prevState.isValid !== false) {
                this.setState({
                    isValid: false
                });
            }
        }

    }

    render() {
        const { isValid } = this.state;
        const { edit, snackbar, classes, goalTypeMatrix } = this.props;
        const { onScaleChange, onDescriptionChange, onExpectedChange, onClickModify, onRelevanceChange, onClickModifyDone } = this.props;
        return (
            <div className={classes.root}>
                <CardHeader
                    action=
                    {
                        <ButtonGroup>
                            <Button
                                onClick={() => onClickModifyDone(edit)}
                                className={classes.doneButton}
                                disabled={!isValid}
                                size="small"
                                endIcon={<DoneIcon />}
                            >
                                Mentés
                            </Button>
                            <Button
                                onClick={() => onClickModify()}
                                className={classes.cancelButton}
                                size="small"
                                endIcon={<CloseIcon />}
                            >
                                Mégsem
                            </Button>
                        </ButtonGroup>
                    }

                    title={<Typography
                        className={classes.title}>
                        {`Módosítás - ${edit.goalType.label}`}
                    </Typography>}
                />
                <Divider className={classes.dividerBottom} />
                <Paper>
                    <div className={classes.gridRoot}>
                        {goalTypeMatrix.isWeighted ?
                            <ContentWithWeight
                                snackbar={snackbar}
                                edit={edit}
                                onScaleChange={onScaleChange}
                                onRelevanceChange={onRelevanceChange}
                                onExpectedChange={onExpectedChange}
                                onDescriptionChange={onDescriptionChange}
                            /> :
                            <ContentWithoutWeight
                                snackbar={snackbar}
                                edit={edit}
                                onScaleChange={onScaleChange}
                                onExpectedChange={onExpectedChange}
                                onDescriptionChange={onDescriptionChange}
                            />
                        }
                    </div>
                </Paper>
            </div>
        );
    }

}
export default withStyles(style)(EditGoal);
