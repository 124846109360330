import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '1.0rem',
            fontFamily: "Nunito",

        },
    },
    head: {
        '& > *': {
            fontSize: '1.1rem',
            fontFamily: "Nunito",
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)


const useStyles =  makeStyles((theme)=>({
    table: {
    },
    modifyIcon: {
        color: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.dark,
        },
    },
    deleteIcon: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
    },
    divider: {
        height: 40,
        margin: 2,
    },
    root: {
        display: 'flex',
        margin:'0 auto'
    },
    selectedRow: {
        backgroundColor: lighten(theme.palette.appMain.main,0.9)
    },
    sumTitle: {
        fontWeight:'bold'
    },
    sumValue: {
        fontWeight: 'bold',
    },
    okIcon: {
        color: theme.palette.success.main
    },
    notOkIcon: {
        color: theme.palette.error.main
    },
    nameContainer: {
    display: 'flex',
    alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1)
    },
    badge: {
        marginRight: theme.spacing(2)
    },
    overTheLimitIcon: {
        color: theme.palette.primary.main
    }
}));

export default {
    useStyles: useStyles,
    StyledTableRow: StyledTableRow
}
