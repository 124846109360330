//React

import React from 'react';
import clsx from 'clsx';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Pickers

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  InputAdornment,
  IconButton,
  CardActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';

const StyledTableRow = style.StyledTableRow;

const DateTimePicker = props => {
  const {value, disabled, id, name, error, label, minDate, maxDate, errorClassName, readOnly, onChange, helperText} = props;
  const classes = style.useStylesForPicker();

  return (
    <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
      <DatePicker
          InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton disabled={disabled}>
                          <TodayIcon />
                      </IconButton>
                  </InputAdornment>
              ),
          }}
          error={error}
          helperText={helperText}
          clearable={true}
          clearLabel="Törlés"
          emptyLabel="Nincs megadva"
          id={id}
          name={name}
          className={classes.picker}
          cancelLabel="Vissza"
          openTo="date"
          label={label}
          readOnly={readOnly}
          disabled={disabled}
          format="yyyy MMMM dd"
          minDate={minDate}
          minDateMessage="Nem megengedett érték"
          maxDate={maxDate}
          views={["year", "month", "date"]}
          autoOk
          value={(value !== null && value !== undefined) ? value : null}
          onChange={date => onChange(date)}
      />
    </MuiPickersUtilsProvider>
  )
}


const LeadersTable = props => {
  const {className, leaders, loading, edit, lastValidFrom, onEdit, onDone, onOpen, onCancel, onChange, onSave, onDelete, onSelect, ...rest} = props;

  const classes = style.useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Felhasználó</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-től</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-ig</TableCell>
                    <TableCell align="center" padding="none">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                  {leaders.map((leader,index) => (
                    <StyledTableRow
                      className={classes.tableRow}
                      hover
                      key={leader.id}
                    >
                      <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={(leader.avatar !== null && leader.avatar !== undefined) ? leader.avatar : ''}
                        />
                        <Typography variant="body1">
                          {leader.userName}
                        </Typography>
                      </div>
                    </TableCell>                                
                    <TableCell padding="none" align="center" >
                        <DateTimePicker
                        minDate={lastValidFrom}
                        disabled={(edit === null || edit.id !== leader.id || leader.isClosed===true)}
                        value={leader.validFrom}
                        onChange={(date)=>onChange(date,index,"validFrom")}
                        />
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      <DateTimePicker
                        minDate={(new Date(leader.validFrom) > new Date(lastValidFrom)) ? leader.validFrom : lastValidFrom}
                        disabled={(edit === null || edit.id !== leader.id)}
                        value={leader.validTo}
                        onChange={(date)=>onChange(date,index,"validTo")}
                        />
                    </TableCell>
                    <TableCell padding="none" align="center">
                        {(edit == null || (edit != null && edit.id != leader.id)) &&
                            <React.Fragment> 
                              <Tooltip title="Törlés">
                                <span>
                                  <IconButton 
                                    disabled={(edit !== null || leader.isClosed===true || leader.disabled===true) ? true : false}
                                    onClick={() => onDelete(index)} 
                                    className={classes.deleteButton} 
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>  
                              <Tooltip title="Módosítás">
                                <span>
                                  <IconButton 
                                    disabled={(edit !== null || leader.disabled===true) ? true : false} 
                                    onClick={() => onEdit(leader)} 
                                    className={classes.editButton} 
                                    aria-label="edit"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>  
                            </React.Fragment>
                        }
                        {(edit != null && edit.id == leader.id) &&
                          <React.Fragment> 
                            <Tooltip title="Mentés">
                              <span>
                                <IconButton 
                                  disabled={(leader.validFrom!==null  && leader.validFrom!==undefined && leader.validFrom!=='') ? false : true}  
                                  onClick={()=>onSave(index)} 
                                  className={classes.saveButton} 
                                  aria-label="save"
                                >
                                  <DoneIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Mégsem">
                            <IconButton 
                              onClick={()=>onCancel(index)} 
                              className={classes.cancelButton} 
                              aria-label="cancel"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip> 
                        </React.Fragment> 
                      }
                      </TableCell>
                    </StyledTableRow>))
                  }
                  {leaders.length < 1 &&
                    <StyledTableRow>
                      <TableCell align="center" colSpan="4">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                      </TableCell>
                    </StyledTableRow>
                  }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        </CardContent>
        <CardActions>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
                <Typography style={{ fontWeight: 'bold' }}>Új vezető beállítása: </Typography>
                <Tooltip title="Új hozzáadás">
                    <span>
                        <IconButton disabled={edit != null ? true : false} onClick={onOpen} className={classes.addButton} aria-label="add">
                            <AddIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        </CardActions>
    </Card>
  );
};

export default LeadersTable;
