import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core
import {
    Box,
    Grid,
    CircularProgress,
    Button,
    ButtonGroup
} from '@material-ui/core';
//Components
import { RatingCard, ResultAvg } from './components';
import {UserStore} from 'stores';
import {apiRequest} from './apiRequest';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import theme from 'theme';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class TabPanels extends Component {
    constructor(props) {
        super(props);
        const { lazyData } = this.props;
        this.state = {
            competences: lazyData.competences,
            isModified:false,
            baseCompetences: lazyData.baseCompetences,
            loading: {
                competences: false,
                avg: true,
            },
            avg: null,
            ratingPoints: lazyData.ratingPoints,
            autoSave: UserStore.getUserValue().autoSave,
        };

        this.handleCompetences = this.handleCompetences.bind(this);
        this.initState = this.initState.bind(this);
    }

    //#Lifecycle methods

   componentDidMount() {
        apiRequest.getAvgAsync(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isModified, autoSave } = this.state;
        if (this.props.userId !== prevProps.userId) {
                if (autoSave === true) {
                    if (isModified===true) {
                        apiRequest.handleSaveChanges(this,true);
                    }
                }
                this.initState().then();
                apiRequest.populateCompetences(this,this.props.userId);
        }
    }

    componentWillUnmount() {
        const { autoSave, isModified } = this.state;
        if (autoSave === true && isModified===true) {
            apiRequest.handleSaveChanges(this,true);
        }      
    }

    //#EndLifecycle methods

    handleCompetences = (compId, newValue, groupId) => {
        const competences = [...this.state.competences].map(comp => {
            comp.competenceSubGroup.map(group => {
                if (group.id === groupId) {
                    group.competences.map(competence => {
                        if (competence.id === compId) {
                            competence.value = newValue
                        }
                    });
                }
            })
            return comp;
        });
        this.setState({ competences });
        this.checkIsModified();
        this.props.setParentState("competences", JSON.parse(JSON.stringify(competences)))
        apiRequest.getAvgAsync(this)
    }

    handleCancel = () => {
        this.setState({
            avg:null,
            isModified: false,
            competences: JSON.parse(JSON.stringify(this.state.baseCompetences))
        }, ()=>apiRequest.getAvgAsync(this));
        this.props.setParentState("isModified", false);
    }

    async initState() {
        await this.setState({
            competences: [],
            isModified: false,
            baseCompetences: [],
            loading: {
                competences: true,
                avg: true
            },
            avg: null
        });
        this.props.setParentState("isModified", false);
    }

    checkIsModified = () => {
        const { competences, baseCompetences } = this.state;
        var hashCompetences = '';
        var hashBaseCompetences = '';
        var competencesObject = [];
        var baseCompetencesObject = [];

        competences.map(comp => comp.competenceSubGroup.map(group => {
            group.competences.map(competence => {
                competencesObject.push({ id: competence.id, value: competence.value })
            });
        }));

        baseCompetences.map(comp => comp.competenceSubGroup.map(group => {
            group.competences.map(competence => {
                baseCompetencesObject.push({ id: competence.id, value: competence.value })
            });
        }));

        competencesObject.sort(this.compare).map((competence) => {
            hashCompetences = hashCompetences + competence.id;
            hashCompetences = hashCompetences + competence.value;
        });

        baseCompetencesObject.sort(this.compare).map((competence) => {
            hashBaseCompetences = hashBaseCompetences + competence.id;
            hashBaseCompetences = hashBaseCompetences + competence.value;
        });

        if (hashCompetences != hashBaseCompetences) {
            this.setState({
                isModified: true
            });
            this.props.setParentState("isModified", true);
        }
        else {
            this.setState({
                isModified: false
            });
            this.props.setParentState("isModified", false);
        }
    }

    compare(a, b) {
        const idA = a.id;
        const idB = b.id;

        let comparison = 0;
        if (idA > idB) {
            comparison = 1;
        } else if (idA < idB) {
            comparison = -1;
        }
        return comparison;
    }



    render() {
        const { value, index,classes } = this.props;
        const { competences, loading, isModified, avg, ratingPoints } = this.state;
        return (loading.competences===true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
            <TabPanel
                value={value}
                index={index}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        md={false}
                        sm={false}
                        xs={false}
                    ></Grid>
                    <Grid
                        item
                        xl={6}
                        lg={8}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        {isModified === true &&
                        <div style={{textAlign:'right'}}>
                            <ButtonGroup>
                                <Button
                                    onClick={() => apiRequest.handleSaveChanges(this,false)}
                                    className={classes.doneButton}
                                    size="small"
                                    endIcon={<DoneIcon />}
                                >
                                    Mentés
                                </Button>
                                <Button
                                    onClick={this.handleCancel}
                                    className={classes.cancelButton}  
                                    size="small"
                                    endIcon={<CloseIcon />}
                                >
                                    Mégsem
                                </Button>
                            </ButtonGroup>
                        </div>
                        }
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        sm={false}
                        md={false}
                        xs={false}
                    ></Grid>
                </Grid>
                {competences.map((comp) => comp.competenceSubGroup.map((compGroup) => (
                    <Grid
                        container
                        spacing={2}
                        key={compGroup.id}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={6}
                            lg={8}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <RatingCard title={compGroup.label} groupId={compGroup.id} handleCompetences={this.handleCompetences} ratingPoints={ratingPoints} competences={compGroup.competences} />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>
                )))}
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        md={false}
                        sm={false}
                        xs={false}
                    ></Grid>
                    <Grid
                        item
                        xl={6}
                        lg={8}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        < ResultAvg loading={loading.avg} value={avg} />
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        sm={false}
                        md={false}
                        xs={false}
                    ></Grid>
                </Grid>

            </TabPanel>);
    }
   
}

export default withStyles(style)(TabPanels);
