import React from 'react';
import clsx from 'clsx';
//Core
import {
    InputAdornment,
    TextField
} from '@material-ui/core';
//Style
import style from './style';

export default function MuiOutlinedInput(props) {
    const { readOnly,
        id,
        disabled,
        endInputAdornment,
        startInputAdornment,
        multiline,
        rows,
        minValue,
        defaultValue,
        label,
        value,
        error,
        helperText,
        className,
        name,
        maxValue,
        margin,
        type,withBackground } = props;

    const { handleChange } = props;

    const classes = withBackground===true ? style.useStylesForResultForm() : style.useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <TextField
                variant="outlined"
                error={error}
                helperText={error === true && helperText}
                type={type}
                margin={margin}
                onChange={handleChange}
                disabled={disabled}
                value={value}
                multiline={multiline}
                rows={rows}
                InputProps={{
                    inputProps: { min: minValue, max: maxValue },
                    readOnly: readOnly,
                    endAdornment: <InputAdornment position="end" > {endInputAdornment}</InputAdornment>,
                    startAdornment: <InputAdornment position="start" > {startInputAdornment}</InputAdornment>
                }}
                onChange={handleChange}
                id={id}
                name={name}
                label={label}
            defaultValue={defaultValue} /></div>);
}
