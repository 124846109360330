import theme from 'theme';


const useStyles = ({
  root: {
    padding: theme.spacing(3)
  },
  tabRoot:{
      flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
  circularProgress: {
      padding: theme.spacing(4),
      textAlign: 'center'
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    padding: theme.spacing(2)
  }
});

export default useStyles;