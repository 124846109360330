
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetUsers: 'ratingForm/persons',
    GetSelector: 'ratingForm/leader-selector-list',
    GetSelectorMain:'ratingForm/rating-selector-list',
    GetCompetences: 'ratingForm/self/competences/user',
    GetResult: 'ratingForm/result/competences/user',
    GetPeriods: 'ratingForm/periods',
}

export const apiRequest = {
    populateUsers,
    populatePeriods,
    populateSelector,
    populateSelectorMain,
    populateCompetences,
    populateResult
};

async function populateUsers(parent) {
    
    const { selectedPeriod } = parent.state;
    const {location} = parent.props;

    const loggedBy = UserStore.getLoggedByAdminValue();
    var model = null;
    if(loggedBy!=null){
       model = {period:selectedPeriod,user:loggedBy.user.userId,path:location.pathname};
    }else{
        model = {period:selectedPeriod,path:location.pathname}
    }
        await new Api().call.post(Actions.GetUsers,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                users: res,
                loading: {
                    ...prevState.loading,
                    users: false
                },
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        result: false,
                        competences: false
                    }
                }));
            }
        }).catch(handleError);

}
async function populatePeriods(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetPeriods,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    periods: false,
                },
                periods: res,
                selectedPeriod: (res.length > 0 && res[0].disabled === false) ? res[0].id : null,
                selectedPeriodObject: (res.length > 0 && res[0].disabled === false) ? res[0] : null,

            }));
            if (((res.length > 0 && res[0].disabled === false) ? res[0].id : null) === null) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        selector: false,
                        users: false,
                        result: false,
                        competences: false,
                    },
                }));
            }
        }).catch(handleError);
  
}


async function populateSelector(parent, userId) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod, userId:userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
   
    await new Api().call.post(Actions.GetSelector,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            selector: res,
            loading: {
                ...prevState.loading,
                selector: false
            },
        }));
    }).catch(handleError);
}

async function populateSelectorMain(parent, userId) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod, userId:userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
   
    await new Api().call.post(Actions.GetSelectorMain,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            selector: res,
            loading: {
                ...prevState.loading,
                selector: false
            },
        }));
    }).catch(handleError);
   
}

//Populate datas
async function populateCompetences(parent,userId) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod,userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetCompetences,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    competences: res,
                },
                loading: {
                    ...prevState.loading,
                    competences: false
                }
            }));
        }).catch(handleError);
  
}

//Populate datas
async function populateResult(parent,userId) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod,userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetResult,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    result: res.competencesMainGroups,
                    avg: res.avg,
                },
                loading: {
                    ...prevState.loading,
                    result: false
                }
            }));
        }).catch(handleError);
}