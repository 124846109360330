import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import {
  Card,
  CardHeader,
  CardContent,
    Divider,
    CircularProgress
} from '@material-ui/core';
//Components
import { options } from './chart';
import { Bar, Line } from 'react-chartjs-2';
import { apiRequest } from './apiRequest';
//Style
import { withStyles } from '@material-ui/core/styles';
import style from './style';




class CompareResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            competences: [],
            self: [],
            secondary: [],
            loading: true,
            data: {
                labels: [],
                datasets: []
            }
        }
    }
     //#Lifecycle methods
    componentDidMount() {
            apiRequest.populateChart(this);
    }
     //#EndLifecycle methods
   
render(){
    const { className, classes, ...rest } = this.props;
    const { data,loading } = this.state;
    return (loading===true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> : 
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                title="Értékelői átlagok összehasonlítása"
            />
            <Divider />
            <CardContent>
                <div className={classes.chartContainer}>
                    <Line
                        data={data}
                        options={options}
                    />
                </div>
            </CardContent>
            <Divider />
        </Card>
    );
}
  
};

CompareResults.propTypes = {
  className: PropTypes.string
};

export default withStyles(style)(CompareResults);
