import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core
import {
    Box,
    Grid,
    CircularProgress,
    Typography,
    Divider,
} from '@material-ui/core';
//Components
import { NotificationBar, Pagination, MuiOutlinedSelect } from 'components';
import { GoalsGrid } from './components';
import $ from "jquery";
import {apiRequest} from './apiRequest';
//Style
import { withStyles } from '@material-ui/core/styles';
import style from './style';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class TabPanels extends Component {

    constructor(props) {
        super(props);
        const { goalTypes } = this.props;
        this.state = {
            goals: null,
            loading: true,
            selectedType: goalTypes !== null && goalTypes.length > 0 ? goalTypes[0] : null
        };  

        this.initState = this.initState.bind(this);

    }

     
     //#Lifecycle methods
    componentDidMount() {        
        const { selectedType } = this.state;
        apiRequest.populateGoals(this,1, selectedType.id, this.props.periodId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userId !== prevProps.userId) {
            const { selectedType } = this.state;
            this.initState();
            apiRequest.populateGoals(this,1, selectedType.id, this.props.periodId);

          
        }
    }
     //#EndLifecycle methods

    //When change page on the pagination element
    handlePageChanges = (currentPage, goalType)=>{
        this.initState();
        apiRequest.populateGoals(this,currentPage, goalType.id, this.props.periodId);
        
        $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
    }
    //Handle type change
    handleSelectChange = (event)=>{
        //Change selected state
        const { goalTypes } = this.props;
        const selectedType = goalTypes.find(type => type.id === event.target.value);
        this.setState({
            selectedType
        });
        //Refresh table
        this.initState();
        apiRequest.populateGoals(this,1, selectedType.id, this.props.periodId);
     
    }

    async initState() {
        await this.setState(prevState => ({
            goals: null,
            loading: true,
        }));
    }

    render() {
        const { value, index, classes, goalTypes, snackbar } = this.props;
        const { goals, loading, selectedType } = this.state;
        return (           
            <TabPanel
                value={value}
                index={index}>
                    <Grid
                        container
                        spacing={2}
                >
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={6}
                        sm={12}
                        xs={12}
                    >
                        <MuiOutlinedSelect
                            margin="dense" 
                            className={classes.select} 
                            menuList={goalTypes} 
                            handleChange={this.handleSelectChange} 
                            value={selectedType !== null ? selectedType.id : 0} 
                            label="Célkitűzések" 
                        />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                        {loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                            <React.Fragment><Typography className={classes.subTitle}>{selectedType.label}</Typography>
                                <Divider className={classes.divider} />
                                <GoalsGrid
                                    checkIsModified={this.checkIsModified}
                                    data={goals.goalsForTable}
                                    currentPage={goals.currentPage}
                                />
                                {goals.step < goals.total && <Pagination goalType={selectedType} onPageChanges={this.handlePageChanges} step={goals.step} currentPage={goals.currentPage} pages={goals.pages} total={goals.total} />}
                            </React.Fragment>} </Grid>
                    </Grid>
            </TabPanel>);
    }

   
}

export default withStyles(style)(TabPanels);
