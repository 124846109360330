
const userDeadLines = [
    {
        title: 'Önértékelés',
        subTitle: '10-nap múlva lejár',
        done: false,
        href: '/rating'
    },

    {
        title: 'Értékelések',
        subTitle: '10-nap múlva lejár',
        done: false,
        href: '/rating'
    },
    {
        title: 'Motiváció',
        subTitle: '10-nap múlva lejár',
        done: false,
        href: '/motivation'
    }
];



const deadLines = userDeadLines;

export default {
    deadLines
};
