import { makeStyles } from '@material-ui/styles';
import { palette } from '@material-ui/system';
import appPalette from '../../../../../../theme/palette'
//Core
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {

    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontFamily: 'Nunito',
        fontWeight: 'bold'
    },
    buttonActive: {
        color: appPalette.appMain.main,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        '& $icon': {
            color: appPalette.appMain.main
        }
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    menuIcon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1)
    },
    active: {
        color: appPalette.appMain.main,
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        '& $icon': {
            color: appPalette.appMain.main
        }
    },
    subMenu: {
        marginLeft: '1.2rem',
        '& .MuiMenuItem-root': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
        }
    },
    subMenuTitle: {
        fontFamily: 'nunito',
        fontWeight: 'bold',
    },
    subHeader: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
}));

export default useStyles;
