//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    Grid,
    CircularProgress
} from '@material-ui/core';
//Components

import { MuiOutlinedSelect  } from 'components';
import { 
    TabPanels, 
    Selector, 
    LeaderBox,
    PdfDocument 
} from './components';
import {Constants} from 'stores';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';
//Api

import {apiRequest} from './apiRequest';


class Own extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);

        this.state = {
            leader:null,
            loading: {
                selector: true,
                conversation: true,
                result: true,
                competences: true,
                leaderBox: true,
                periods:true
            },
            periods: [],
            selectedPeriod: null,
            periodIsActive:null,
            selector: [],
            value: 0,
            error: false,
            conversation: false,
            lazyData:null
        }

        this.initLoading = this.initLoading.bind(this);
    }

     //#Lifecycle methods
    componentDidMount() {
        apiRequest.populatePeriods(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, selectedPeriod } = this.state;
        if (prevState.selectedPeriod != selectedPeriod) {
            if (loading.periods === false) {
                if (selectedPeriod !== null) {
                    if (loading.selector !== false) {
                        apiRequest.populateSelector(this);
                    }
                    if (loading.leaderBox !== false) {
                        apiRequest.populateLeader(this);
                    }
                    apiRequest.populateCompetences(this);
                    apiRequest.populateResult(this);
                }
            }

        }
        if (prevState.loading != loading) {
            if (loading.periods===false && loading.selector === false && loading.leaderBox===false && loading.competences === false && loading.result === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
     //#EndLifecycle methods

    handleSelectChange = (event) => {
        const periodIsActive = this.state.periods.filter(item => item.id === event.target.value)[0].isActive;
        this.setState(prevState => ({
            selectedPeriod: event.target.value,
            selector: [],
            periodIsActive,
            value:0,
            loading: {
                ...prevState.loading,
                selector: true,
                leaderBox: true,
                competences:true,
                result: true
            }
        }));

    }

    handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
    };
    
    async initLoading() {
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                selector: true,
                conversation: true,
                result: true,
                competences: true,
                leaderBox: true
            }
        }));
    }
  
    render() {
        const {value, selector, periodIsActive, leader, periods, loading, selectedPeriod, lazyData} = this.state;
        const {snackbar, classes} = this.props;

        return ((loading.periods ===false) && 
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        <Typography className={classes.title}>Értékelőlap</Typography>
                        <Grid container spacing={2}>
                            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MuiOutlinedSelect 
                                        margin="dense" 
                                        disabledItemLabel="Még nem elérhető" 
                                        disabled={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} 
                                        error={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} 
                                        helperText={(periods !== null && periods !== undefined && periods.length > 0) ? '' : "Nem található előző periódus"} 
                                        className={classes.select} menuList={periods} 
                                        handleChange={this.handleSelectChange} 
                                        value={selectedPeriod !== null ? selectedPeriod : 0} 
                                        label="Periódusok" 
                                    />
                                    {(selectedPeriod !== null && periodIsActive===true)  && 
                                        <PdfDocument 
                                            titles={selector} 
                                            snackbar={snackbar} 
                                            period={selectedPeriod}
                                        />
                                    }
                                </div>               
                            </Grid>
                            <Grid item xl={6} lg={6} md={4} sm={false} xs={false}/>
                            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                                {(selectedPeriod !== null) && 
                                (loading.leaderBox === true ? 
                                <div className={classes.circularProgress}>
                                    <CircularProgress color="primary" />
                                </div> : (leader!==null && leader!==undefined && leader!=='') && 
                                <LeaderBox 
                                    periodIsActive={periodIsActive} 
                                    period={selectedPeriod} 
                                    leader={leader} 
                                />)}
                            </Grid>
                        </Grid>
                        {selectedPeriod !== null ?
                        (loading.competences === true || loading.result === true || loading.selector === true) ? 
                            <div className={classes.circularProgress}>
                                <CircularProgress color="primary" />
                            </div> : 
                            selector.length > 0 ?
                            <Selector data={selector} value={value} onChange={this.handleChange}>
                                <TabPanels 
                                    lazyData={lazyData} 
                                    snackbar={snackbar} 
                                    titles={selector} 
                                    period={selectedPeriod} 
                                    value={value} 
                                    index={value} 
                                />
                            </Selector> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nincs választható menüpont</b>
                            </div> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nincs kiválasztva periódus</b>
                            </div>
                        }
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
            </div>
        )
    }
}
export default withStyles(style)(Own);
