import axios from "axios";
import {UserStore} from '../stores';

export class Api{
  call;
  callAnonymus;

    constructor(props){
        this.callAnonymus = axios.create({
            baseURL:`${process.env.REACT_APP_API_URL}/`
        });
        if(UserStore.getUserValue()!=null && UserStore.getUserValue()!==undefined){
            this.call = axios.create({
                baseURL:`${process.env.REACT_APP_API_URL}/`,
                headers:{
                  'Authorization': `Bearer ${UserStore.getUserValue().token}`
                 }
            }); 
        }else{
            this.call = axios.create({
                baseURL:`${process.env.REACT_APP_API_URL}/`,
                headers:{
                  'Authorization': `Bearer ${null}`
                 }
            }); 
        }
    }
}