import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: 150
    },
    rootFull: {
        width: '100%'
    },
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1)
    }
}));

export default useStyles;