import React from 'react';
import clsx from 'clsx';
//Core
import {
    Typography,
    Select,
    FormControl,
    FormHelperText,
    MenuItem,
    InputLabel,
    Tooltip
} from '@material-ui/core';
//Style
import style from './style';


export default function MuiOutlinedSelect(props) {
    const { error,
        disabled,
        disabledItemLabel,
        id,
        label,
        value,
        menuList,
        open,
        onOpen,
        onClose,
        className,
        readOnly,
        margin,
        helperText } = props;
    const { handleChange } = props;

    const classes = style();
    return (


        <FormControl
            disabled={disabled}
            variant="outlined"
            margin={margin}
            className={clsx(classes.root,className)}
            error={error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                autoWidth={false}
                id={id}     
                value={value}
                onChange={handleChange}
                label={label}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                inputProps={{ readOnly: readOnly }}
            >
                <MenuItem value={0} disabled>
                    <Typography className={classes.menuItem}> Válaszd ki</Typography>
                </MenuItem>
                {menuList.map((item, index) => (
                    <MenuItem disabled={item.disabled} key={index} value={item.id}>                    
                            {item.disabled === true ? `${item.label} - ${disabledItemLabel}` : 
                            item.tooltip ? 
                                <Tooltip title={item.tooltip} arrow>
                                    <span>{ item.label }</span>
                                </Tooltip> : item.label                        
                            }               
                    </MenuItem>
                ))}
            </Select>
            {error === true && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
