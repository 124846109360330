import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    editButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color:theme.palette.warning.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.warning.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    removeButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.appMain.main
    },
    selectedRow: {
        backgroundColor: lighten(theme.palette.appMain.main, 0.9)
    }
}));
