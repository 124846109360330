import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: theme.spacing(2)
    },
    additionalDataSpace: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
        padding: theme.spacing(2)
    },
    additionalData: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    }
}));

export default {
    useStyles: useStyles
}
