//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetMatrixUsers:'admin/home/matrix-result/users',
    GetGoalUsers:'admin/home/goal-result/users', 
    GetResultUsers:'admin/home/result-result/users',
    GetSpeakUsers:'admin/home/speak-result/users',
    GetRatingUsers:'admin/home/rating-result/users',
}

export const apiRequest = {
    getMatrixUsers,
    getGoalUsers,
    getResultUsers,
    getSpeakUsers,
    getRatingUsers
};

async function getMatrixUsers(parent,id) {
    await new Api().call.get(`${Actions.GetMatrixUsers}/${id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users:res,
            loading:false
        }));
    }).catch(handleError);
}

async function getGoalUsers(parent,id) {
    await new Api().call.get(`${Actions.GetGoalUsers}/${id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users:res,
            loading:false
        }));
    }).catch(handleError);
}

async function getResultUsers(parent,id) {
    await new Api().call.get(`${Actions.GetResultUsers}/${id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users:res,
            loading:false
        }));
    }).catch(handleError);
}

async function getSpeakUsers(parent,id) {
    await new Api().call.get(`${Actions.GetSpeakUsers}/${id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users:res,
            loading:false
        }));
    }).catch(handleError);
}

async function getRatingUsers(parent,id) {
    await new Api().call.get(`${Actions.GetRatingUsers}/${id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users:res,
            loading:false
        }));
    }).catch(handleError);
}

