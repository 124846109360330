//React

import React, { Component } from 'react';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


const DateTimePicker = props => {
    const {value, disabled, id, name, error, label, minDate, maxDate, errorClassName, readOnly, onChange, helperText} = props;
    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                error={error}
                helperText={helperText}
                clearable={true}
                clearLabel="Törlés"
                emptyLabel="Nincs megadva"
                id={id}
                name={name}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                minDateMessage="Nem megengedett érték"
                maxDate={maxDate}
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider>
    )
  }


class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.getLeaders(this);

        this.state = {
            user: '',
            users: [],
            validFrom:'',
            valid:false,
            loading:{
                users:true
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {user,validFrom} = this.state;

        if (prevState.user != user || prevState.validFrom != validFrom) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });       
    }

    onSelectChange = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    handleSave = () => {
        const {role, validFrom, user} = this.state;
        const {organization} = this.props;

        const model = {organization:organization.id, user:user.userId,validFrom };
        apiRequest.saveChanges(this,model);
    }

    handleDateChange = (date) =>{

        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        this.setState({ 
            validFrom:newDate 
        });
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }

    render() {
        const {onClose, open, classes, lastValidFrom} = this.props;
        const {user, loading, valid, users, validFrom} = this.state;
        return (
            <Dialog  
                open={open} 
                onClose={onClose} 
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új vezető beállítása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                        <Grid spacing={2} container>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-user"
                                    name="user"
                                    value={user}
                                    onChange={(event, value) => this.onSelectChange("user", value)}
                                    options={users}
                                    getOptionLabel={(option) => option.fullName===undefined ? '' : option.fullName}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        error={!this.validateFields(user)}
                                        helperText={!this.validateFields(user) ? "Kötelező megadni" : ''}
                                        label="Vezetők"
                                        InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading.users ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <div style={{textAlign:'center'}}>
                                    <DateTimePicker
                                        minDate={lastValidFrom}
                                        error={!this.validateFields(validFrom)}
                                        helperText={!this.validateFields(validFrom) ? 'Kötelező megadni' : null}
                                        label="Érvényesség-től"
                                        value={validFrom==='' ? null : validFrom}
                                        onChange={(date)=>this.handleDateChange(date)}
                                    />
                                </div>
                            </Grid>                                                
                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                    <DoneIcon />
                                </IconButton>
                             </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
