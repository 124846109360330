//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Helpers

import { UserStore } from 'stores';

const Actions = {
    ModifyUser:'matrix/modify',
    GetMaxPercent: 'matrix/max-percent',
    IsOverTheLimit: 'matrix/is-over-the-limit'
}

export const apiRequest = {
    getMaxPercent,
    checkIsOverTheLimit,
    modify
};

async function modify(model,handleEvaluators) {

    await new Api().call.post(`${Actions.ModifyUser}`, model)
    .then(handleResponse)
    .then(res => {
        handleEvaluators(res.reviewers);
    }).catch(handleError);   
}

async function getMaxPercent(classId,validMatrix,selectedMatrix,setMaxPercent) {
    var model = {selectedMatrix,classId};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetMaxPercent,model)
    .then(handleResponse)
    .then(res => {
        setMaxPercent(res);
    }).catch(handleError);

}
async function checkIsOverTheLimit(user,setUser,setOverTheLimitUser,setOpen,setIsOverTheLimit){
    var model = {userId:user.userId};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.IsOverTheLimit,model)
    .then(handleResponse)
    .then(res => {
        setIsOverTheLimit(res);
        if (res === false) {
            setUser(user);
        } else {
            setOverTheLimitUser(user);
            setOpen(true);
        }
    }).catch(handleError);
}