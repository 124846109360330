//React

import React from 'react';
//Core

import {
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    Paper,
    Avatar,
    IconButton,
    Divider,
    Tooltip,
    TableFooter,
    CircularProgress,
    Badge
} from '@material-ui/core';
//Helpers

import { UserStore } from 'stores';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Icons

import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';


const StyledTableRow = style.StyledTableRow;

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: theme.palette.white
    },
}))(Avatar);

export default function CreatedTable(props) {
    const { handleRemoveUser, handleSetModifySelector, modifySelector, data, addNew } = props;
    const rows = data;
    const getSum = () => {
        let sum = 0;
        data.map(row => {
            sum = sum + row.percent
        });
        return sum;
    }
    const classes = style.useStyles();
    const loggedBy =  UserStore.getLoggedByAdminValue();

    return (
        <TableContainer component={Paper}>
            <PerfectScrollbar>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            {modifySelector != null &&
                                <TableCell padding="none" />
                            }
                            <TableCell align="left">Értékelő neve</TableCell>
                            <TableCell align="center">Értékelő minősítése</TableCell>
                            <TableCell align="center">%</TableCell>
                            <TableCell padding="none" align="center"/>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow className={modifySelector === row.userId ? classes.selectedRow : ''} key={row.userId}>
                                {modifySelector != null &&
                                    <TableCell padding="none" align="right">
                                        {modifySelector === row.userId && <NavigateNextIcon />}
                                    </TableCell>
                                }
                                <TableCell className={classes.nameContainer} align="center">
                                    {row.overTheLimit ?
                                        <Tooltip title="Definiált területről hozzáadott értékelő.">
                                            <Badge
                                                overlap="circle"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={
                                                    <SmallAvatar>
                                                        <SupervisedUserCircleIcon className={classes.overTheLimitIcon}/>
                                                    </SmallAvatar>
                                                }
                                                >
                                                <Avatar
                                                    className={classes.avatar}
                                                    src={(row.avatar !== null && row.avatar !== undefined) ? row.avatar : ''}
                                                />
                                            </Badge>
                                        </Tooltip> : 
                                        row.avatar!=='loading' ? 
                                        <Avatar
                                            className={classes.avatar}
                                            src={(row.avatar !== null && row.avatar !== undefined) ? row.avatar : ''}
                                            >                               
                                        </Avatar> : 
                                        <CircularProgress size="2rem" color="primary" />
                                    }
                                    {row.fullName}
                                </TableCell>
                            <TableCell align="center">{row.classification.label}</TableCell>
                            <TableCell align="center">{row.percent}</TableCell>
                            <TableCell padding="none" align="center">
                                {modifySelector===null && <div className={classes.root}>
                                    {row.classification.modifiable === true && addNew!==true ? 
                                    <Tooltip  title="Módosítás">
                                        <span>
                                            <IconButton 
                                                disabled={loggedBy!=null && loggedBy.period.disabled} 
                                                onClick={() => handleSetModifySelector(row.userId,row.classification.id)} 
                                                className={classes.modifyIcon}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip > :     
                                        <IconButton disabled onClick={() => handleSetModifySelector(row.userId,row.classification.id)} className={classes.modifyIcon}>
                                        <CreateIcon />
                                    </IconButton>}
                                <Divider className={classes.divider} orientation="vertical" />
                                    {row.classification.modifiable === true && addNew !== true ? 
                                        <Tooltip title="Eltávolítás">
                                            <span>
                                                <IconButton 
                                                    disabled={loggedBy!=null && loggedBy.period.disabled}  
                                                    onClick={() => handleRemoveUser(row.userId, row.classification.id)} 
                                                    className={classes.deleteIcon}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip >
                                        : <IconButton disabled onClick={() => handleRemoveUser(row.userId, row.classification.id)}  className={classes.deleteIcon}>
                                        <DeleteIcon />
                                    </IconButton>}
                                </div>
                                }
                            </TableCell>
                        </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow >
                            {modifySelector != null &&
                                <TableCell padding="none" />
                            }
                            <TableCell align="center" />
                            <TableCell className={classes.sumTitle} align="right">Összesítve:</TableCell>
                            <TableCell className={classes.sumValue} align="center">{getSum()}</TableCell>
                            <TableCell padding="none" align="left">{getSum() === 100 ? <CheckCircleOutlineIcon className={classes.okIcon} /> : < CancelIcon className={classes.notOkIcon} />}</TableCell>
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </PerfectScrollbar>
        </TableContainer>
    );
}
