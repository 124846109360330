import theme from 'theme';


const useStyles = () => ({
  secondaryButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appSecondary.main,
        '&:hover': {
            color:theme.palette.appSecondary.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appSecondary.light}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.appSecondary.main,
            color:theme.palette.secondary.dark
        },
    }
});

export default useStyles;