

import {Constants} from 'stores';

export default function NotificationBar (_message, _vertical, _horizontal, _autoHideDuration, _variant, _action) {
    const snackbar = Constants.getSnackbar();
    return (
        snackbar(_message, {
            anchorOrigin: {
                vertical: _vertical,
                horizontal: _horizontal,
            },
            variant: _variant,
            autoHideDuration: _autoHideDuration,
            action: _action,
        })
    );
}
