import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Core
import { Typography, Link } from '@material-ui/core';
//Style
import style from './style';


const Footer = props => {
  const { className, ...rest } = props;
  const date = new Date();
  const year = date.getFullYear();
  const classes = style();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://merkbau.hu/"
          target="_blank"
        >
          Merkbau Zrt
        </Link>
        . {year}
      </Typography>
      <Typography variant="caption">
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
