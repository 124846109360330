import React, { useState, Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

//Core
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Tooltip,
    withStyles
} from '@material-ui/core';
//Components
import axios from 'axios';
import Cookies from 'js-cookie';
import { authenticationService } from 'services';
//Icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

import  data  from './data';
import style from './style';
//Actions
const Actions = {
    IsActive: 'https://localhost:44353/gui/isActive',
}




class DeadLines extends Component {

    state = {
        isActive:false

    }

    async componentDidMount() {
        //await authenticationService.refReshToken();
       // await this.isActive();

    }

    async isActive() {
        const headers = { 'Authorization': `Bearer ${Cookies.get('token')}` };
        await axios.post(`${Actions.IsActive}`, null, { headers }).then(res => {
            this.setState({
                isActive: true
            })
        }).catch(error => {
            console.error(error);
            //Error message snack
            //NotificationBar(this.props.snackbar, 'Váratlan hiba történt!', 'top', 'right', 4000, 'error', null);
        });
    }

render(){
    const deadlines = data.deadLines;
    const { isActive } = this.state;
    const { classes, className } = this.props;
    return (
        isActive===true && <Card
        >
            <CardHeader
                className={classes.headerTitle}
                title="HATÁRIDŐK"
                action={
                    <IconButton size="small">
                        <AlarmOnIcon className={classes.headerIcon} />
                    </IconButton>
                }
            />
            <Divider />
            <CardContent className={classes.content}>
                <List>
                    {deadlines.map((deadline, i) => (
                        <ListItem
                            divider={i < deadline.length - 1}
                            key={i}
                        >

                            <ListItemAvatar>
                                <Tooltip title="Megnyitás">
                                    <RouterLink to={deadline.href}>
                                        <IconButton
                                            edge="end"
                                            size="small"
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </RouterLink>
                                </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                                primary={deadline.title}
                                secondary={deadline.subTitle}
                            />
                            {deadline.done ?
                                <Tooltip title="Nincs kitöltve">
                                    <ClearIcon className={classes.clearIcon} />
                                </Tooltip> :
                                <Tooltip title="Kitöltve">
                                    <DoneIcon className={classes.doneIcon} />
                                </Tooltip>}

                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <Divider />
        </Card>
    );

}
   
};

DeadLines.propTypes = {
    className: PropTypes.string
};

export default withStyles(style)(DeadLines);
