//React

import React, { Component } from 'react';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography,
    IconButton,
    Grid,
    TextField,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


const DateTimePicker = props => {
    const {value, disabled, id, name, error, label, minDate, maxDate, required, errorClassName, readOnly, onChange, helperText} = props;

    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                error={error}
                helperText={helperText}
                clearable={true}
                clearLabel="Törlés"
                id={id}
                inputVariant="outlined"
                margin="dense"
                name={name}
                required={required}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                minDateMessage="Nem megengedett érték"
                maxDate={maxDate}
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider >
    )
}


class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.getRoles(this);

        this.state = {
            role: '',
            roles: [],
            validFrom: '',
            valid: false,
            loading: {
                roles: true
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {role, validFrom} = this.state;

        if (prevState.role != role || prevState.validFrom != validFrom) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    onSelectChange = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    handleSave = () => {
        const {role, validFrom} = this.state;

        const { user } = this.props;
        const model = { user: user.id, role, validFrom };
        apiRequest.saveChanges(this, model);
    }

    handleDateChange = (date) => {

        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        }
        this.setState({
            validFrom: newDate
        });
    }

    validateFields = (value) => {
        if (value === null || value === undefined || value === '') {
            return false;
        }
        return true;
    }


    render() {
        const {onClose, open, classes} = this.props;
        const {loading, valid, roles, role, validFrom} = this.state;
        
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új jogosultság beállítása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent id="add-new-form">
                    <Grid spacing={2} container>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-role"
                                    name="role"
                                    value={role}
                                    onChange={(event, value) => this.onSelectChange("role", value)}
                                    options={roles}
                                    getOptionLabel={(option) => option.label === undefined ? '' : option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            error={!this.validateFields(role)}
                                            label="Jogosultságok"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.roles ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <DateTimePicker
                                    error={!this.validateFields(validFrom)}
                                    label="Érvényesség-től"
                                    required={true}
                                    value={validFrom === '' ? null : validFrom}
                                    onChange={(date) => this.handleDateChange(date)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton
                                    disabled={!valid}
                                    onClick={this.handleSave}
                                    className={classes.doneButton}
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton
                                    className={classes.backButton}
                                    onClick={onClose}
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
