import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: 150
    },
    rootFull: {
        width: '100%'
    },
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1)
    },
    endOfRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:"flex-end"
    },
    startOfRow: {
        marginRight:'auto'
    },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    blockButton: {
        color: theme.palette.error.main
    },
    doneButton: {
        color: theme.palette.success.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    unlockButton: {
        color: theme.palette.success.main
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default useStyles;