
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar 
} from 'components';
//Helpers

import { UserStore } from 'stores';

//Actions
const Actions = {
    SaveConversation: 'ratingForm/conversation/leader/save',
}

export const apiRequest = {
    handleSaveChanges
};

async function handleSaveChanges(userId, date,period,handleClose,refreshUsers) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    var model = {userId, date, period:period.id };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.SaveConversation, model)
    .then(handleResponse)
    .then(res => {
        handleClose();
        refreshUsers();
    }).catch(handleError);
}