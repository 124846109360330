import { makeStyles,withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  avg_title:{
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
  },
  avg_value:{
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
  },
}));

const StyledRating = withStyles((theme)=>({
    iconFilled: {
        color: theme.palette.appMain.main,
    },
    iconHover: {
        color: theme.palette.appMain.main,
    },
}))(Rating);

export default {
useStyles:useStyles,
StyledRating:StyledRating,

}
