import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { Button } from '@material-ui/core';
//Components
//Icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
//Styles
import style from './style';

const HelperPlan = props => {
  const { className,snackbar, ...rest } = props;

    const classes = style();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.media}>
        <img
          alt="Info"
          src="/images/Info.png"
        />
      </div>
      <div className={classes.actions}>
        <Button                    
          className={classes.buttons}
          startIcon={<ErrorOutlineIcon />}
          component={RouterLink}
          to="/helper/problem-report"
          variant="contained"
        >
              Hiba bejelentés
        </Button> 
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.buttons}
          to="/helper/user-guide"
          component={RouterLink}
          startIcon={<OndemandVideoIcon />}
          variant="contained"
        >
          Használati segédlet
        </Button>
      </div>
      <div className={classes.actions}>
        <Button
          to="/helper/questions"
          component={RouterLink}
          className={classes.buttons}
          startIcon={<HelpOutlineIcon />}
          variant="contained"
        >
          Gyakori kérdések
        </Button>
      </div>
    </div>
  );
};

HelperPlan.propTypes = {
  className: PropTypes.string
};

export default HelperPlan;
