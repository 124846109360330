//React

import React from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    ButtonGroup,
    DialogTitle,
    Divider,
    Typography
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style

import theme from 'theme';
import style from './style';

export default function ConfirmDialog(props) {
    const { open, onClose,onDelete } = props;

    const classes = style();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle 
                style={{ backgroundColor: theme.palette.warning.light }} 
                id="alert-dialog-title"
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Figyelmeztetés
                    </Typography>
                    <ErrorOutlineIcon style={{ color: theme.palette.white }} />
                </div>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Typography style={{ fontSize: '1.0rem' }}>Biztos törölni szeretnéd ?</Typography>
            </DialogContent>
            <DialogActions>
                <ButtonGroup>
                    <Button 
                        className={classes.saveButton}
                        size="small" 
                        onClick={onDelete} 
                        endIcon={<DoneIcon />}
                    >
                        Igen
                    </Button>
                    <Button 
                        className={classes.cancelButton}
                        size="small" 
                        onClick={onClose} 
                        endIcon={<ArrowBackIcon />}
                    >
                        Nem
                    </Button>
                </ButtonGroup>              
            </DialogActions>
        </Dialog>
    );
}
