//React

import React,{ Component } from "react";
//Core

import { 
    Typography, 
    Grid, 
    Divider, 
    Card,
    CardContent,
    Box,
    Collapse,
    IconButton
} from "@material-ui/core";
//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
//Assets

import matrix_1 from '../../../../assets/images/questions/Matrix_1.png'
import matrix_2 from '../../../../assets/images/questions/Matrix_2.png'
import beszelgetes_1 from '../../../../assets/images/questions/Beszelgetes_1.png'
import beszelgetes_2 from '../../../../assets/images/questions/Beszelgetes_2.png'

class Questions extends Component{

    state = {
        collapsed:[]
    }

    //Handle button click
    handleCollapse = (item) =>{
        var temp = this.state.collapsed;

        if(temp.includes(item)){
            var index = temp.indexOf(item);
            if (index !== -1) {
                temp.splice(index, 1);
            }
        }else{
            temp.push(item);
        }
      
        this.setState({
            collapsed:temp
        });
    }

    render(){
        const { classes } = this.props;

        return(<div className={classes.root}>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                >
                </Grid>
                <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                >
                    <Typography className={classes.title}>Gyakori kérdések</Typography>
                    <Divider />
                    <div className={classes.divider} /> 

                    <Typography className={classes.subTitle}>Határidő</Typography>    
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Van e lehetőség a határidők módosítására, a határidők kitolására?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(1)}>
                                    {this.state.collapsed.includes(1) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(1)}>
                                <Typography className={classes.text}>
                                    A határidők módosítására az értékelés időszakában nincs lehetőség, így kérünk mindenkit a megadott határidők pontos betartására! 
                                </Typography>
                            </Collapse>    
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography className={classes.subSubTitle}>Mit tegyek, ha nem végeztem el határidőre kijelölt feladatot, feladatokat?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(2)}>
                                    {this.state.collapsed.includes(2) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>                                       
                            <Collapse in={this.state.collapsed.includes(2)}>
                                <Typography className={classes.text}>
                                    A határidők pontos betartását kérjük, vedd komolyan. Amennyiben mégis lemaradtál a határidőről, kérjük mielőbb pótold az értékelést egy külön dokumentumban és küld meg a HR-es kollégák részére a dokumentumot. 
                                    Indokolt esetben utólagosan, az értékelési időszak lezárultával tudjuk rögzíteni az értékeléseket a rendszerben.<b>Kivételt ez alól az képez, amennyiben a mátrix nem készült el az erre kijelölt időszakban.</b> Ebben az esetben kérünk feltétlenül vedd fel a kapcsolatot a HR csoporttal.  Kérünk, minden esetben vedd figyelembe, hogy elmaradásod hátráltathatja a saját és kollégáid értékelésének gördülékeny menetét. A határidőkről a Merkmondó felületén keresztül tájékoztatunk Benneteket.
                                </Typography> 
                            </Collapse>
                        </CardContent>
                    </Card>

                    <Typography className={classes.subTitle}>Hibabejelentés</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Milyen címen tudok hibát bejelenteni?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(3)}>
                                    {this.state.collapsed.includes(3) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(3)}>
                                <Typography className={classes.text}>
                                    Hibabejelentést a hibabejelentő fül alatt tudsz tenni. Kérünk, ezt a módját válaszd a kapcsolatfelvételnek, ha elakadsz. Kollégáink rövid határidőn belül igyekeznek segítséget nyújtani.
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card>
                           
                    <Typography className={classes.subTitle}>A teljesítmény értékelésben résztvevők</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Mit jelentenek az értékelés során használt különböző fogalmak?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(4)}>
                                    {this.state.collapsed.includes(4) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(4)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    <b>Felettes:</b> A felettes az értékelt munkahelyi vezetője. Az értékelés során feladata a következő évi célkitűzések megfogalmazása. 
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    <b>Főértékelő:</b> A főértékelő az a személy, akinek a feladata az előző év értékelése. A Felettes és a Főértékelő személye alapesetben ugyanaz, ugyanakkor indokolt esetben eltérő is lehet. A Főértékelő értékelése számít legnagyobb aránnyal bele az értékelésbe.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    <b>Vezető értékelő:</b> A vezető értékelő olyan másik vezető beosztású kolléga, aki a munkahelyi közvetlen felettes mellett, jól rálát az értékelt személy munkájára. A főértékelő mellett a vezető értékelő értékelése számít legnagyobb súllyal az értékelésbe. 
                                </Typography>
                                <Typography className={classes.text}>
                                    <b>Mellérendelt értékelő:</b> Mellérendelt értékelő bárki lehet. Értékelésük súlya számuktól függően változik. <i>Egy-egy mellérendelt értékelő esetében a felettes feladata az értékelési arány beállítása. </i> 
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card>
                
                    <Typography className={classes.subTitle}>Értékelők</Typography> 
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Egy személy összesen hány főt értékelhet?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(5)}>
                                    {this.state.collapsed.includes(5) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(5)}>
                                <Typography className={classes.text}>
                                    Mellérendelt értékelőként egy személy maximum 15 főt értékelhet. Vezető értékelőnél nincs limit.
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card> 
                 
                    <Typography className={classes.subTitle}>Mátrix</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Milyen mátrix variációkat használhatunk?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(6)}>
                                    {this.state.collapsed.includes(6) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(6)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Általános esetben: 
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    A főértékelő mellett maximum 5 értékelőt jelölhetünk meg, melyből egy fő lehet vezetőértékelő. A főértékelő mellett minimum 2, mellérendelt értékelőt kell megjelölni.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    A vezető-, és főértékelő személyek százalékos arányai adottak, a mellérendelt értékelők számának növekedésével automatikusan módosulnak. Egy-egy mellérendelt értékelő esetében a felettes feladata az értékelési arány beállítása.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Az arányok a következőképpen alakulnak:
                                </Typography>
                                <Box textAlign="center">
                                    <img style={{margin:'auto', maxWidth:'40rem', width:'100%', height:'auto'}} src={matrix_1}/>
                                </Box>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    ÉPSZER esetében:
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    A főértékelő mellett maximum 9 értékelőt jelölhetünk meg, melyből 1 fő lehet vezetőértékelő. A további 8 mellérendelt közül 4 fő bárki lehet, akár vezető beosztású munkatárs is; további 2 fő értékelő hozzáadása pedig csak és kizárólag a társcégek szakági munkatársai lehetnek, azaz a CSMT és KMB munkatársai. 
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    A vezető-, és főértékelő személyek százalékos arányai itt is adottak, ezek az értékek a mellérendelt értékelők számának növekedésével automatikusan módosulnak. Egy-egy mellérendelt értékelő esetében a felettes feladata az értékelési arány beállítása.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Az arányok a következőképpen alakulnak:
                                </Typography>
                                <Box textAlign="center">
                                    <img style={{maxWidth:'40rem', width:'100%', height:'auto'}} src={matrix_2}/>
                                </Box>  
                            </Collapse>       
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Mi az oka, hogy a 2 szakági kolléga mellé nem tudok 4 nem szakági kollégát mellérendelt értékelőt beállítani?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(7)}>
                                    {this.state.collapsed.includes(7) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>              
                            <Collapse in={this.state.collapsed.includes(7)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Kiemelten figyelj a mátrix készítésnél a sorrendre, ha 6 darab mellérendelő értékelőt szeretnél beállítani.
                                </Typography>
                                <Typography className={classes.text}>
                                    Amennyiben az 1-4 mellérendeltnél már szerepel szakági kolléga, nem fogja engedni a rendszer, hogy az 5. és 6. helyen nem szakági kollégát adj hozzá értékelőként. Ez esetben a szakági kollégákat az 5. és 6. értékelőnek kell hozzáadni. Fontos, hogy az értékelés súlya, nem a hozzárendelés sorrendjétől függ.
                                </Typography>
                            </Collapse>   
                        </CardContent>
                    </Card>
                
                    <Typography className={classes.subTitle}>Anonimitás</Typography>
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>A Felettes értékelése valóban anonim?</Typography> 
                                <IconButton onClick={()=>this.handleCollapse(8)}>
                                    {this.state.collapsed.includes(8) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(8)}>
                                <Typography className={classes.text}>
                                    Felettesed értékelését külön menüpontban tudod megtenni. Az értékelés eredménye teljesen anonim.  A közvetlen felettes az értékeléséről csak egy összesített értéket kap. Tehát sem a szöveges visszajelzéshez, sem pedig a részletes értékeléshez nem fér hozzá. Ezeket az információkat csak a HR admin jogosultsággal rendelkező munkatársai látják és diszkrécióval kezelik.  
                                </Typography>
                            </Collapse>   
                        </CardContent>
                    </Card>
               
                    <Typography className={classes.subTitle}>Visszajelzés</Typography>   
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Kell visszajelzést adnom a beszélgetés teljesítéséről?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(9)}>
                                    {this.state.collapsed.includes(9) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(9)}>
                                <Typography className={classes.text}>
                                    Az értékelő beszélgetés visszaigazolása a felettesnek és a beosztottnak is feladata. 
                                </Typography>
                            </Collapse> 
                        </CardContent>
                    </Card>      

                    <Typography className={classes.subTitle}>Beszélgetések</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Beszélgetésekről általánosságban jó, ha tudod:</Typography>
                                <IconButton onClick={()=>this.handleCollapse(10)}>
                                    {this.state.collapsed.includes(10) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(10)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -	a beszélgetés időpontját a főértékelő jelöli ki.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -	a beszélgetés megtörténtjét a felettesnek és a beosztottnak is vissza kell igazolnia a felületen.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -	igazolás módja:
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    a, Felettesként
                                </Typography>
                                <Typography style={{marginLeft:'1rem',marginBottom:'0.5rem'}} className={classes.text}>
                                    1.lépés
                                </Typography>
                                <img style={{marginLeft:'1rem',maxWidth:'40rem', width:'100%', height:'auto'}} src={beszelgetes_1}/>
                                <Typography style={{marginLeft:'1rem',marginBottom:'0.5rem'}} className={classes.text}>
                                    2.lépés
                                </Typography>
                                <img style={{marginLeft:'1rem',maxWidth:'40rem', width:'100%', height:'auto'}} src={beszelgetes_2}/> 
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    b, Beosztottként:
                                </Typography>
                                <Typography className={classes.text}>
                                    Válaszd az Értékelőlap menüpont alatt a Saját almenüt. Itt elérhető az értéklőlapod. (Figyelj rá, hogy a periódusnál a 2023-as legyen kiválasztva!) Az értékelőlap felett megjelenik felettesed neve és egy kis ikon a jobb felső sarokban. A kis ikonra kattintva tudsz visszaigazolást adni, hogy megtörtént e az értékelő beszélgetés.
                                </Typography>
                            </Collapse> 
                        </CardContent>
                    </Card>                 

                    <Typography className={classes.subTitle}>Értékelhetőség, értékelés</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Kik értékelhetnek és kik értékelhetőek?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(11)}>
                                    {this.state.collapsed.includes(11) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(11)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -	Mindenki részt vesz a teljesítményértékelésben, aki előző év augusztus 31-ig állományba lépett. Ők minden esetben értékelhetnek és értékelhetőek.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -   Azok, aki szeptember 1 után léptek állományba az adott évre célkitűzést kapnak, melyeket a Próbaidő modulban kell a felettesnek megírnia. Kivételt ez alól az árukiadó kollégák képeznek.
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    -   A próbaidő modulban megfogalmazott célkitűzések a teljesítmény értékeléskor átkerülnek a TÉR rendszerébe. Azok esetében, akiknek a próbaidős beszélgetése és a teljesítményértékelés időszaka egy időszakra esik, nem kell a TÉR felületén célkitűzést írni! A TÉR felületén ezek a beosztott kollégák ugyanúgy meg fognak jelenni, mint a régiek, de részükre a próbaidő modulban kell megfogalmazni a célokat.
                                </Typography>
                            </Collapse>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Attól, hogy nem értékelhetek még van lehetőség az önértékelés és motivációs rész kitöltésére?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(12)}>
                                    {this.state.collapsed.includes(12) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(12)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Amennyiben szeptember 1 után kerültél állományba a teljesítményértékelésben nem veszel részt az idei évben. Ez azt jelenti, hogy nem kell kollégáid mellérendelt értékelőként értékelned és téged sem fognak értékelni. Ugyanakkor lehetőséged van a felületen az önértékelésed és a motivációs kérdőív kitöltésére. Fontos, hogy felettesed célkitűzéseket tűzhet ki számodra, melyeket részletesen ismertet veled.
                                </Typography>
                            </Collapse>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Ha mellérendeltként értékelek valakit az értékelt személy látja, hogy milyen értékelést adtam le róla?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(13)}>
                                    {this.state.collapsed.includes(13) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(13)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    Az értékelésben résztvevők nem látják, hogy egy-egy kolléga milyen értékeket adott a megjelölt kompetenciáikra. Az értékelésben átlagok jelennek meg. Az értékelt azt sem látja, hogy mely munkatársak értékelték, csupán a főértékelő, illetve, ha van vezető értékelő,  személye nyilvános az értékelés során.
                                </Typography>
                            </Collapse>                         
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Ha mellérendelt értékelőként nem látok rá az értékelt összes megadott kompetenciájára, akkor is le kell értékelnem az adott kompetenciát?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(14)}>
                                    {this.state.collapsed.includes(14) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(14)}>
                                <Typography className={classes.text}>
                                    Csak azok a kompetenciák kerüljenek értékelésre, melyről reális véleményt tudsz alkotni az értékelt kollégával kapcsolatban.
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card>  
                
                    <Typography className={classes.subTitle}>Eredmények</Typography>  
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Hogyan épül fel a mellérendelt és a főértékelő által adott pontokból a végleges pontszámom?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(15)}>
                                    {this.state.collapsed.includes(15) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(15)}>
                                <Typography className={classes.text}>
                                    Amikor felettesed kijelöli a téged értékelő személyeket, azt is megjelöli, hogy kinek az értékelése milyen súlyban számít bele a teljesítményértékelésedbe. A főértékelő, aki alap esetben a felettesed véleménye a legnagyobb súlyú, emellett, ha vezető értékelő is véleményt mondott a munkádról az ő értékelése is magasabb arányban járul hozzá pontjaidhoz. A fő- és vezető értékelő véleményének súlyozása automatikusan számolódik, viszont a hozzád rendelt mellérendelt értékelők véleményének arányát felettesed határozza meg.  
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card>                    

                    <Typography className={classes.subTitle}>Célok kitűzése</Typography>    
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Összesen hány cél fogalmazható meg?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(16)}>
                                    {this.state.collapsed.includes(16) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box> 
                            <Collapse in={this.state.collapsed.includes(16)}>
                                <Typography className={classes.text}>
                                    Maximum 10 célkitűzés adható meg, de fontos, hogy ezek megadásakor figyeljünk a súlyozásra. Összesen 100 %-ot kell kitennie a teljes összegnek. Különben a rendszer nem engedi menteni.
                                </Typography>
                            </Collapse>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography className={classes.subSubTitle}>Milyen mérőszámok alkalmazhatók a célkitűzések írásakor?</Typography>
                                <IconButton onClick={()=>this.handleCollapse(17)}>
                                    {this.state.collapsed.includes(17) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.collapsed.includes(17)}>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    a.	százalékos  
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    b.	darab      
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    c.	határidő      
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    d.	teljesülés     
                                </Typography>
                                <Typography style={{marginBottom:'0.5rem'}} className={classes.text}>
                                    e.	pénzösszeg  
                                </Typography>
                            </Collapse>
                        </CardContent>
                    </Card>                  
                </Grid>
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                >
                </Grid>
            </Grid>
        </div>)    
    }
}

export default withStyles(style)(Questions);