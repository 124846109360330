import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles
import style from './style';
//Core
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TextField,
  IconButton,
  CardActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ReorderIcon from '@material-ui/icons/Reorder';

const StyledTableRow = style.StyledTableRow;


const DataTable = props => {
    const { className,loading, emailGroups, edit, onEdit,onDone,onOpen,onEmails, onCancel,onValidChange, onChange,onSave, ...rest } = props;

  const classes = style.useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Azonosító</TableCell>
                    <TableCell align="center"  padding="none">Megnevezés</TableCell>   
                    <TableCell align="center" padding="none">Alapértelmezett</TableCell>          
                    <TableCell align="center" padding="none">Aktív</TableCell>
                    <TableCell align="center" padding="none">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                  {emailGroups.map((row,index) => (
                    <StyledTableRow
                      className={classes.tableRow}
                      hover
                      key={row.id}
                    >
                                      <TableCell padding="none" align="center" >
                                        {row.id}
                                      </TableCell>                                 
                                      <TableCell padding="none" align="center" >
                                         <TextField
                                          disabled={(edit == null || (edit != null && edit.id != row.id))}
                                          value={row.name}
                                          onChange={(event)=>onChange(event,index,"name")}
                                          fullWidth
                                         />
                                      </TableCell>  
                                      <TableCell padding="none" align="center">
                                        {row.isDefault ? 'Igen' : 'Nem'}
                                      </TableCell>
                                      <TableCell padding="none" align="center" >
                                       
                                        {row.isValid===true ?
                                        <Tooltip title="Tiltás">
                                        <IconButton  disabled={(edit != null ? true : false)}  onClick={()=>onValidChange(index,false)} className={classes.doneIcon}>
                                          <DoneIcon/>
                                        </IconButton></Tooltip>  : 
                                         <Tooltip title="Feloldás">
                                        <IconButton  disabled={(edit != null ? true : false)}  onClick={()=>onValidChange(index,true)} className={classes.blockIcon}>
                                          <BlockIcon/>
                                        </IconButton></Tooltip>
                                        }
                                         {row.isValid ? 'Igen' : 'Nem'}
                                      </TableCell>
                                      <TableCell padding="none" align="center">
                                          {(edit == null || (edit != null && edit.id != row.id)) &&
                                                <Tooltip title="Módosítás">
                                                  <span>
                                                    <IconButton 
                                                      disabled={(edit != null ? true : false)} 
                                                      onClick={() => onEdit(row)} 
                                                      className={classes.editButton} 
                                                      aria-label="edit"
                                                    >
                                                      <EditIcon />
                                                    </IconButton>
                                                  </span>
                                                </Tooltip>  
                                          }
                                          {(edit != null && edit.id == row.id) &&
                                            <React.Fragment> 
                                              <Tooltip title="Mentés">
                                                <span>
                                                  <IconButton 
                                                    disabled={(row.name!==null && row.name!==undefined && row.name!=='') ? false : true}  
                                                    onClick={()=>onSave(index)} 
                                                    className={classes.saveButton} 
                                                    aria-label="save"
                                                  >
                                                    <DoneIcon />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Mégsem">
                                              <IconButton 
                                                onClick={()=>onCancel(index)} 
                                                className={classes.cancelButton} 
                                                aria-label="cancel"
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </Tooltip> 
                                          </React.Fragment> 
                                        }
                                          <Tooltip title="Email címek">
                                              <IconButton 
                                               disabled={(edit != null ? true : false)} 
                                                onClick={()=>onEmails(row)} 
                                                className={classes.emailsButton} 
                                                aria-label="emails"
                                              >
                                                <ReorderIcon />
                                              </IconButton>
                                            </Tooltip> 
                                      </TableCell>
                      </StyledTableRow>
                      ))}
                              {emailGroups.length < 1 &&
                                  <StyledTableRow>
                                      <TableCell align="center" colSpan="6">
                                      {loading ? 'Betöltés...' : 'Nincs találat'}
                                  </TableCell>
                                  </StyledTableRow>}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
          </CardContent>
          <CardActions>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
                  <Typography style={{ fontWeight: 'bold' }}>Új csoport létrehozása: </Typography>
                  <Tooltip title="Új hozzáadás">
                      <span>
                          <IconButton disabled={edit != null ? true : false} onClick={onOpen} className={classes.addButton} aria-label="add">
                              <AddIcon />
                          </IconButton>
                      </span>
                  </Tooltip>
              </div>
          </CardActions>
    </Card>
  );
};

export default DataTable;
