//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetClaims:'admin/rating/claims',
    GetUserFilters:'admin/rating/claims/filter/user',
    SaveEdit:'admin/rating/claims/edit/save',
    Delete:'admin/user/user-roles/delete'
}

export const apiRequest = {
    populateClaims,
    populateUserFilters,
    saveEdit,
    deleteRole
};

async function populateClaims(parent,page) {
    const {selectedFilter} = parent.state;

    const model = {
        filteredUsers: selectedFilter.users,
        ertekelhet: (selectedFilter.ertekelhet!==null && selectedFilter.ertekelhet!==undefined)  ?  selectedFilter.ertekelhet.id : null,
        ertekelheto:  (selectedFilter.ertekelheto!==null && selectedFilter.ertekelheto!==undefined) ? selectedFilter.ertekelheto.id : null,
        page
    };
    
    await new Api().call.post(Actions.GetClaims,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                claims:false
            },
            claims: res.claimslist,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function populateUserFilters(parent) {

    await new Api().call.get(Actions.GetUserFilters)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                users:false
            },
            filter: {
                ...prevState.filter,
                users: res
            }
        }));
    }).catch(handleError);
}

async function saveEdit(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.SaveEdit,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}

async function deleteRole(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}

