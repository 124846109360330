//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress 
} from 'components';
//Actions

const Actions = {
    GetCompanies: 'probaido/auto-complete/companies',
    GetBios: 'probaido/auto-complete/bios',
    GetOrganizations: 'probaido/auto-complete/organizations',
    GetLeaders:'probaido/auto-complete/leaders',
    GetLeader:'probaido/auto-complete/leader',
    SaveUser:'admin/user/user/add-new'
}

export const apiRequest = {
    populateCompanies,
    populateBios,
    populateOrganizations,
    populateLeaders,
    getLeader,
    saveUser
};

async function saveUser(parent){
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const { fullName,email,domainName,bio,leader,organization,company} = parent.state;
    const {onClose,refresh} = parent.props;
    const model = { fullName,bio,email,domainName,leader,organization,company }
        await new Api().call.post(Actions.SaveUser, model)
        .then(handleResponse)
        .then(res => {
            refresh();         
            onClose();
        }).catch(handleError);
}

async function populateBios(parent) {
        const {company} = parent.state;
        await new Api().call.get(`${Actions.GetBios}/${company.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                },
                bios: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                }
            }));
        });
}

async function populateOrganizations(parent) {
        const {company} = parent.state;
        await new Api().call.get(`${Actions.GetOrganizations}/${company.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });

}
async function populateCompanies(parent) {
    await new Api().call.get(Actions.GetCompanies)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                companies: false
            },
            companies: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                companies: false
            }
        }));
    });

}

async function populateLeaders(parent) {
    await new Api().call.get(Actions.GetLeaders)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                leaders: false
            },
            leaders: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                leaders: false
            }
        }));
    });

}


async function getLeader(parent) {
    const {organization} = parent.state;
    await new Api().call.get(`${Actions.GetLeader}/${organization.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            leader: res
        }));
    }).catch(error => {
        handleError(error);
    });

}
