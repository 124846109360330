//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components

import { 
    Element, 
    MainHeader, 
    SubHeader 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


class Motivations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            motivationPoints: [],
            loading: true,
        }

        this.initState = this.initState.bind(this);
    }

    //#Lifecycle methods
    componentDidMount() {
            apiRequest.populateMotivationPoints(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.period != this.props.period) {
            this.setState({
                loading: true
            });
            apiRequest.populateMotivationPoints(this);
        }
    }
    //#EndLifecycle methods

    async initState() {
        await this.setState({
            motivationPoints: [],
            loading: true,
        });
    }

    render() {
        const {motivationPoints, loading} = this.state;
        const {classes} = this.props;
        
        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        {loading ? 
                            <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                            motivationPoints.length < 1 ? 
                            <div style={{ textAlign: 'center' }}>
                                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>
                                    Nem lett kitöltve a motivációs rész
                                </Typography>
                            </div> : 
                            motivationPoints.map((item, index) => (
                                <React.Fragment key={index}>
                                    <MainHeader title={item.title} additionalData={item.additionalData} />
                                    {item.subMotivations.map((subMotivation, index) => (
                                        <React.Fragment key={index}>
                                            <SubHeader title={subMotivation.title} />
                                            <Element data={subMotivation.elements} />
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }

  
}

export default withStyles(style)(Motivations);
