//React

import React from 'react';
//Material UI

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import {
    CardContent,
    Card,
    Box,
    CircularProgress
} from '@material-ui/core';

import { apiRequest } from './apiRequest';

function TimeLine(props) {
    const { } = props;

    const [model, setModel] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        apiRequest.populateSteps(setModel, setLoading);
    }, [])

    return (
        <Card>
            <CardContent>
                <Typography
                    style={{
                        fontWeight: 'bold',
                        fontFamily: 'Nunito',
                        fontSize: '0.9rem'
                    }}
                    color="inherit"
                    gutterBottom
                    variant="body2"
                >
                    IDŐVONAL:
                </Typography>
                {loading && <Box textAlign="center"><CircularProgress size="1.5rem"/></Box>}
                {Array.isArray(model?.steps) && model?.steps?.length > 0 ?
                    <>
                        <Timeline>
                            {model?.steps.map((step, index) =>
                                <TimelineItem>
                                    <TimelineOppositeContent>                                   
                                        <Typography style={{ fontWeight: step.isActive && "700" }}>{step.startDate}</Typography>
                                        <Typography style={{ fontWeight: step.isActive ? "700" : "600" }}>{step.label}</Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color={step.isActive ? "primary" : "secondary"} style={{backgroundColor: (step.isDone === true ? "#43a047" : (step.isDone === false ? "#e53935" : ""))}} />
                                        {model.steps.length - 1 > index && <TimelineConnector />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography style={{ fontWeight: step.isActive && "700" }} color="textSecondary">{step.endDate}</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            )}
                        </Timeline>
                        <Typography style={{float:"right",fontWeight:"600"}}>Aktuális dátum: {model?.date}</Typography>
                    </> :
                    loading ? <Typography align="center">Betöltés...</Typography> :
                    <Typography>
                        Jelenleg nem aktív az értékelési periódus...
                    </Typography>}
            </CardContent>
        </Card>
    )
}

export default TimeLine;