//React

import React, { Component } from 'react';
//Core

import { 
    Drawer, 
    AppBar, 
    IconButton, 
    Toolbar 
} from '@material-ui/core';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Components

import { 
    AccountProfile, 
    AccountDetails, 
    AccountSettings 
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class EditUser extends Component {

    constructor(props) {
        super(props);
        apiRequest.getUser(this);
        apiRequest.getSettings(this);

        this.state = {
            user: null,
            loading: {
                user: true,
                settings: true
            },
            time: new Date(),
            settings: {
                autoSave: false
            }
        };
    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        const { handleContentLoading } = this.props;
        if (prevState.loading != loading) {
            if (loading.user === false && loading.settings === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods

    handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: checked
            }
        }));
    }

    handleTextChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));
    }



    render() {
        const {user, time, loading, settings} = this.state;
        const {classes, snackbar, onEdit, onClose, refreshTable} = this.props;

        return (loading.user === false && loading.settings === false &&
            <Drawer anchor={'right'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.drawer}>
                    <AccountProfile
                        userId={this.props.user.id}
                        user={user}
                        time={time}
                        refreshProfile={() => apiRequest.getUser(this)}
                        refreshTable={refreshTable}
                    />
                    <AccountSettings
                        userId={this.props.user.id}
                        settings={settings}
                        refreshProfile={() => apiRequest.getUser(this)}
                        refreshTable={refreshTable}
                    />
                    <AccountDetails
                        userId={this.props.user.id}
                        refreshProfile={() => apiRequest.getUser(this)}
                        refreshTable={refreshTable}
                        snackbar={snackbar}
                        onSave={this.handleSave}
                        onTextChange={this.handleTextChange}
                        onChange={this.handleChange}
                        settings={settings}
                        user={user}
                    />
                </div>
            </Drawer>
        );
    }
};

export default withStyles(style)(EditUser);
