import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetUsers: 'goal/previous/persons',
    GetGoalTypes: 'goal/previous/types',
    GetPeriods: 'goal/periods'
}

export const apiRequest = {
    populateUsers,
    populateGoalTypes,
    populatePeriods
};

async function populateUsers(parent,periodId) {

    var model = {periodId:(periodId === null || periodId === undefined) ? -1 : periodId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetUsers,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false,
                },
                users: res
            }));
        }).catch(handleError);
       
}

async function populateGoalTypes(parent,periodId) {

        await new Api().call.get(`${Actions.GetGoalTypes}/${periodId}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    goalTypes: false,
                },
                goalTypes: res
            }));
        }).catch(handleError);
        
}

async function populatePeriods(parent) {

        await new Api().call.get(Actions.GetPeriods)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    periods: false,
                },
                periods: res
            }));
        }).catch(handleError);
      
}