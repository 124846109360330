//React

import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  CardActions,
  Typography,
  Select,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
//Icons

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


const StyledTableRow = style.StyledTableRow;

const DataTable = props => {
  const {className, loading, data, periods, period, onOpen, onDelete, ...rest} = props;

  const classes = style.useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Felhasználó</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-től</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-ig</TableCell>
                    <TableCell align="center" padding="none">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((row,index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={row.id}
                  >
                    <TableCell padding="none" align="center" >
                      {row.userName}
                    </TableCell>                                
                    <TableCell padding="none" align="center" >
                      <Select
                        disabled={true}
                        name="validFrom"
                        value={row.validFrom}
                      >
                        {periods.map((item,index)=>(
                          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      <Select
                        disabled={true}
                        name="validTo"
                        value={row.validTo}
                      >
                        {periods.map((item,index)=>(
                          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <Tooltip title="Törlés">
                        <span>
                          <IconButton
                            disabled={row.disabled || period.disabled} 
                            onClick={() => onDelete(index)} 
                            className={classes.deleteButton} 
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>   
                    </TableCell>
                  </StyledTableRow>
                   ))}
                  {(period==='' || period===undefined || period===null) ?
                    <StyledTableRow>
                        <TableCell align="center" colSpan="4">
                          Nincs kválasztva periódus
                        </TableCell>
                    </StyledTableRow>
                    :  data.length < 1 && 
                    <StyledTableRow>
                      <TableCell align="center" colSpan="4">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                      </TableCell>
                    </StyledTableRow>
                    }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
          <Typography style={{ fontWeight: 'bold' }}>
            Új felhasználó hozzáadása: 
          </Typography>
          <Tooltip title="Új hozzáadás">
              <span>
                  <IconButton 
                    disabled={period===null || period===undefined || period==='' || period.disabled} 
                    onClick={onOpen} 
                    className={classes.addButton} 
                    aria-label="add"
                  >
                      <AddIcon />
                  </IconButton>
              </span>
          </Tooltip>
        </div>        
      </CardActions>
    </Card>
  );
};

export default DataTable;
