import React, { useState, Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Styles
import theme from 'theme';
import { makeStyles } from '@material-ui/styles';
//Icons
import SaveIcon from '@material-ui/icons/Save';
//Core
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Switch,
  Button,
  FormControlLabel,
} from '@material-ui/core';
//Components
import {apiRequest} from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';


class AccountDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _settings: this.props.settings
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(prevState => ({
            _settings: {
                ...prevState._settings,
                [name]: checked
            }
        }));
    }

    handleSave = ()=> {
        apiRequest.saveSettings(this);
    }

render(){
    const { _settings } = this.state;
    const { classes } = this.props;
    return (
        <Card>
            <form
                autoComplete="off"
                noValidate
            >
                <CardHeader
                    subheader="Személyes beállítások"
                    title="Beállítások"
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >                
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={_settings.autoSave}
                                        onChange={this.handleChange}
                                        color="primary"
                                        name="autoSave"
                                    />
                                }
                                label="Automatikus mentés"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button
                        className={classes.saveButton}
                        variant="contained"
                        size="small"
                        onClick={this.handleSave}
                        endIcon={<SaveIcon />}
                    >
                        Mentés
                    </Button>
                </CardActions>
            </form>
        </Card>       
    );
}
};

export default withStyles(style)(AccountDetails);
