//React

import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Core

import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  TableContainer,
  Paper
} from '@material-ui/core';
//Components

import { UserSelector } from 'components';
import {
  TabPanels,
  AlertDialog
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import {
  Constants,
  enums
} from 'stores';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';

//Icons

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import GetAppIcon from '@material-ui/icons/GetApp';


const inputTypes = enums.inputTypesInString;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class GoalDialog extends Component {

  constructor(props) {
    super(props);

    Constants.setcontentLoading(true);

    this.state = {
      users: null,
      value: 0,
      tabValue: 0,
      loading: {
        users: true,
        goalTypes: true,
        notAllowedUsers: true,
        lazyData: true,
        points: true,
        download: false
      },
      notAllowedUsers: [],
      selectedType: null,
      goalTypes: null,
      error: false,
      userSelectable: true,
      lazyData: null,
      alert: {
        isWeighted: false,
        weightSum: null,
        total: null,
        open: false
      },
      points: null
    }
  }


  //#Lifecycle methods
  componentDidMount() {
    const { selected } = this.props;

    apiRequest.populateUsers(this);
    apiRequest.populateGoalTypes(this);
    apiRequest.populatePoints(this, selected.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, users, goalTypes, selectedType } = this.state;

    if (prevState.loading != loading) {
      if (loading.goalTypes === false && loading.users === false) {
        if (goalTypes.length > 0 && users.length > 0) {
          if (loading.lazyData === true) {
            apiRequest.populateGoals(this, 1, selectedType.id);
          }
        } else {
          Constants.setcontentLoading(false);
        }

      }
      if (loading.goalTypes === false && loading.users === false && loading.lazyData === false) {
        Constants.setcontentLoading(false);
      }
    }
  }
  //#EndLifecycle methods

  setParentState = (props, newValue) => {
    this.setState({
      [props]: newValue
    });
  }
  setParentObjectState = (object, props, newValue) => {
    this.setState(prevState => ({
      [object]: {
        ...prevState[object],
        [props]: newValue
      }

    }));
  }

  handleClose = () => {
    this.setState(prevState => ({
      alert: {
        ...prevState.alert,
        open: false
      }
    }));
  }

  setValue = (newValue) => {
    this.setState({
      value: newValue
    })
  }

  handleError = () => {
    this.setState({
      error: true
    });
  }

  setUserSelectable = (value) => {
    this.setState({ userSelectable: value })
  }

  //Handle type change
  handleSelectChange = (event) => {
    const { alert } = this.state;

    if (alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false) {
      //Change selected state
      const { goalTypes } = this.state;
      const selectedType = goalTypes.find(type => type.id === event.target.value);
      this.setState({
        selectedType
      });
    }
    else {
      this.setState(prevState => ({
        alert: {
          ...prevState.alert,
          open: true
        }
      }));
    }
  }

  //#Api calls

  //#EndApi calls

  handleBlockedNavigation = (nextLocation) => {
    this.setState(prevState => ({
      alert: {
        ...prevState.alert,
        open: true
      }
    }));
    return false
  }

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    });
  }

  handleText = (event) => {
    const { points } = this.state;

    const name = event.target.name;
    const value = event.target.value;
    var newData = { ...points };
    newData[name] = value;
    this.setState({
      points: newData
    });
  }

  handleChangeInArray = (event, checked, mainPoint, subPoint, point, valuesIndex, id) => {
    const { points } = this.state;

    const value = event.target.value;
    var newData = { ...points };
    const mainIndex = newData.mainPoints.indexOf(mainPoint);
    const subPointIndex = newData.mainPoints[mainIndex].subPoints.indexOf(subPoint);
    const pointIndex = newData.mainPoints[mainIndex].subPoints[subPointIndex].points.indexOf(point);
    if (checked) {
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values[valuesIndex].value = 'true';
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values.map((item, index) => {
        if (item.type === inputTypes.CHECKBOX && index !== valuesIndex) {
          item.value = 'false';
        }
      });
    } else {
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values[valuesIndex].value = 'false';
    }
    this.setState({
      points: newData
    });
  }

  handleChange = (event, checked) => {
    const { points } = this.state;

    const name = event.currentTarget.name;
    const value = checked;
    var newData = { ...points };
    if (name.includes('eLearning')) {
      if (name.includes("_true")) {
        if (value) {
          newData.eLearning = true
        } else {
          newData.eLearning = null;
        }
      } else if (name.includes("_false")) {
        if (value) {
          newData.eLearning = false
        } else {
          newData.eLearning = null;
        }
      }

    } else if (name.includes('workWithUser')) {
      if (name.includes("_true")) {
        if (value) {
          newData.workWithUser = true
        } else {
          newData.workWithUser = null;
        }
      } else if (name.includes("_false")) {
        if (value) {
          newData.workWithUser = false
        } else {
          newData.workWithUser = null;
        }
      }
    }
    this.setState({
      points: newData
    });
  }

  handleSave = () => {
    apiRequest.save(this);
  }

  handleDownload = () => {
    const { selected } = this.props;

    this.setState(prevState => ({
      loading: {
        ...prevState.loading,
        download: true
      }
    }));
    var save = apiRequest.save(this);
    save.then(res => {
      apiRequest.download(this, selected);
    }).catch(error => { });
  }

  handleGoalDownload = (typeId, userId) => {
    this.setState(prevState => ({
      loading: {
        ...prevState.loading,
        download: true
      }
    }));
    apiRequest.downloadGoals(this, typeId, userId);
  }

  render() {
    const { loading, users, alert, lazyData, userSelectable, value, tabValue, points, goalTypes, error, selectedType } = this.state;
    const { snackbar, onClose, open, readOnly, classes } = this.props;

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              2.5 hónapos elbeszélgetés
            </Typography>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Tabs
          value={tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Elbeszélgetés" {...this.a11yProps(0)} />
          <Tab label="Célkitűzések" {...this.a11yProps(1)} />
        </Tabs>
        <PerfectScrollbar>
          <DialogContent>
            <TabPanel value={tabValue} index={0} dir={theme.direction}>
              {loading.points === true &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress color="primary" />
                </div>
              }
              {loading.points === false &&
                <div>
                  {points.mainPoints.map((item, index) =>
                    <div key={item.label} >
                      <Typography className={classes.title}>
                        {item.label}
                      </Typography>
                      {item.subPoints.map(subPoint =>
                        <Paper>
                          <TableContainer>
                            <PerfectScrollbar>
                              <Table key={subPoint.label}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      <Typography className={classes.subTitle}>
                                        {subPoint.label}
                                      </Typography>
                                    </TableCell>
                                    {subPoint.values.map(row =>
                                      <TableCell
                                        key={row.label}
                                        align="center"
                                      >
                                        <Typography className={classes.value}>
                                          {row.label}
                                        </Typography>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {subPoint.points.map(point =>
                                    <TableRow key={point.label}>
                                      <TableCell align="left" padding="none">
                                        <Typography className={classes.point}>
                                          {point.label}
                                        </Typography>
                                      </TableCell>
                                      {subPoint.values.map((row, index) =>
                                        <TableCell
                                          key={row.id}
                                          width={row.type === inputTypes.CHECKBOX ? '15%' : '30%'}
                                          align="center"
                                          padding="none"
                                        >
                                          {row.type === inputTypes.CHECKBOX &&
                                            <Checkbox
                                              disabled={readOnly}
                                              checked={point.values[index].value === "true"}
                                              onChange={(event, checked) => this.handleChangeInArray(event, checked, item, subPoint, point, index, row.id)}
                                              name="checkedB"
                                              color="primary"
                                            />
                                          }
                                          {row.type === inputTypes.TEXTFIELD &&
                                            <TextField
                                              onChange={(event) => this.handleTextInArray(event, item, subPoint, index, point)}
                                              value={(point.values[index].value == null || point.values[index].value == undefined) ? '' : point.values[index].value}
                                              InputProps={{
                                                readOnly: readOnly,
                                              }}
                                              fullWidth
                                            />
                                          }
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </PerfectScrollbar>
                          </TableContainer>
                        </Paper>)
                      }
                    </div>
                  )}
                  <Typography className={classes.footer}>
                    A próbaidő értékelő beszélgetés megtörtént.
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.footer}>
                      A dolgozó további foglalkoztatását
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={points.workWithUser === true}
                          onChange={this.handleChange}
                          name="workWithUser_true"
                          color="primary"
                        />
                      }
                      label="javaslom"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={points.workWithUser === false}
                          onChange={this.handleChange}
                          name="workWithUser_false"
                          color="primary"
                        />
                      }
                      label="nem javaslom"
                    />
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.footer}>
                      Az e-learning rendszerben található képzésekre felhívtam a figyelmet
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={points.eLearning === true}
                          onChange={this.handleChange}
                          name="eLearning_true"
                          color="primary"
                        />
                      }
                      label="igen"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={points.eLearning === false}
                          onChange={this.handleChange}
                          name="eLearning_false"
                          color="primary"
                        />
                      }
                      label="nem"
                    />
                  </div>
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    onChange={this.handleText}
                    value={(points.note === null || points.note === null) ? '' : points.note}
                    variant="outlined"
                    name="note"
                    fullWidth
                    multiline
                    rows={3}
                    label="Megjegyzések"
                  />
                  <div className={classes.actions}>
                    <Button
                      endIcon={<GetAppIcon />}
                      disabled={readOnly || loading.download}
                      className={classes.mainButton}
                      size="small"
                      onClick={this.handleDownload}
                      variant="outlined"
                    >
                      Letöltés
                    </Button>
                    <Button
                      endIcon={<SaveIcon />}
                      disabled={readOnly || loading.download}
                      className={classes.greenButton}
                      size="small"
                      onClick={this.handleSave}
                      variant="outlined"
                    >
                      Mentés
                    </Button>
                  </div>
                </div>}
            </TabPanel>
            <TabPanel value={tabValue} index={1} dir={theme.direction}>
              {(loading.goalTypes === false && loading.users === false) &&
                <Grid container spacing={2}>
                  <Grid item xs={false} sm={false} md={1} />
                  <Grid item md={10} xs={12}>
                    {users.length > 0 ?
                      <UserSelector data={users} value={value}>
                        {lazyData !== null &&
                          <TabPanels
                            readOnly={readOnly}
                            setParentObjectState={this.setParentObjectState}
                            lazyData={lazyData}
                            paginationEnabled={userSelectable}
                            setUserSelectable={this.setUserSelectable}
                            onSelectChange={this.handleSelectChange}
                            selectedType={selectedType}
                            onError={this.handleError}
                            goalTypes={goalTypes}
                            snackbar={snackbar}
                            value={value}
                            index={value}
                            handleDownload={this.handleGoalDownload}
                            userId={users[value].id}
                          />
                        }
                      </UserSelector> :
                      <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                        <b>Nem tartozik hozzád a beosztott</b>
                      </div>}
                  </Grid>
                  <Grid item xs={false} sm={false} md={1} />
                </Grid>
              }
            </TabPanel>
            <Prompt
              when={!(alert.weightSum === 100 || alert.total === 0 || alert.isWeighted == false)}
              message={this.handleBlockedNavigation}
            />
            <AlertDialog open={alert.open} onClose={this.handleClose} />
          </DialogContent>
        </PerfectScrollbar>
      </Dialog>
    );
  }
}

export default withStyles(style)(GoalDialog)
