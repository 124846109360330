//React

import React from 'react';
//Api

import { 
    Api, 
    handleResponse, 
    handleError 
} from 'api';
//Components

import { 
    NotificationBar,
    Progress 
} from 'components';
//Actions

const Actions = {
    GetCompaniesForSelect: 'admin/user/user-positions/edit/companies-for-list',
    GetPositionsForSelect: 'admin/user/user-positions/edit/positions-for-list',
    GetOrganizationsForSelect: 'admin/user/user-positions/edit/organizations-for-list',
    SavePosition: 'admin/user/user-positions/edit/add-new-postion'
}

export const apiRequest = {
    populatePositions,
    populateOrganizations,
    populateCompanies,
    savePosition
};

async function populatePositions(parent, companyId) {
    await new Api().call.get(`${Actions.GetPositionsForSelect}/${companyId}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    positions: false
                },
                positions: res
            }));
        }).catch(handleError);
}

async function populateOrganizations(parent, companyId) {
    await new Api().call.get(`${Actions.GetOrganizationsForSelect}/${companyId}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(handleError);
}

async function populateCompanies(parent) {
    await new Api().call.get(Actions.GetCompaniesForSelect)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    companies: false
                },
                companies: res
            }));
        }).catch(handleError);
}

async function savePosition(parent) {
    const { onSave, user, refresh } = parent.props;
    const { validFrom, selected } = parent.state;
    const model = { positionId: selected.position.id, organizationId: selected.organization.id, userId: user.id, validFrom };

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.SavePosition, model)
        .then(handleResponse)
        .then(res => {
            onSave();
            refresh();
        }).catch(handleError);
}