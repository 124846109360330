import {Api,handleResponse,handleError} from 'api';
//Action
const Actions = {
    Send: 'user/avatar/update',
}


export const apiRequest = {
    upload
};

async function upload(setPicture,setRequest,data) {
    await new Api().call.post(`${Actions.Send}`, data).then(handleResponse)
        .then(res => {
                setPicture(null);
                setRequest(false);
                window.location.reload();
            }).catch(error => {
                setRequest(false);
                setPicture(null);
                handleError(error);
            });
}