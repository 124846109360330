import theme from 'theme';

const useStyles = () => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    progress: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(7)
        },
        marginBottom: theme.spacing(3)
    },
    typography: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
        lineHeight: '1.3'
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    }
});

export default useStyles;
