import { makeStyles, withStyles } from '@material-ui/styles';
import React from 'react';
//Core
import {
    TableRow
  } from '@material-ui/core';

const useStylesForPicker = makeStyles(theme => ({
    root: {
    },
    picker: {
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
}));

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
    },
    blockButton: {
        color: theme.palette.error.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    unlockButton: {
        color: theme.palette.success.main
    },
    editButton: {
        color: theme.palette.warning.main
    },
    doneButton: {
        color: theme.palette.success.main
    },
    backButton: {
        color: theme.palette.error.main
    },
    addButton: {
        color: theme.palette.success.main
    },
    deleteButton: {
        color: theme.palette.error.main
    },
    warningIcon: { 
        color: theme.palette.warning.main
    },
    approvedIcon: {
        color: theme.palette.warning.dark
    },
}));

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '0.8rem',

        },
    },
    head: {
        '& > *': {
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)

export default {
    useStylesForPicker,
    useStyles,
    StyledTableRow
}