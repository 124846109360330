//React

import React,{ Component } from 'react';
import clsx from 'clsx';
//Components

import {
  ConfirmDialog,
  NewDialog,
  FilterDrawer,
  ConstraintDrawer
} from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';
//Core

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  LinearProgress,
  InputLabel
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GroupIcon from '@material-ui/icons/Group';
import HowToRegIcon from '@material-ui/icons/HowToReg';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function Row(props) {
  const {main, classifications, edit, onEdit, onAddNew, onConstraint, onFilters, onSave, onCancel, onDelete, deleted, mainIndex, onChange, periods, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.matrixVariationMainGroup.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.matrixVariationMainGroup.id}
        </TableCell>
        <TableCell align="center">
        <TextField
            name="label"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"label")}
            disabled={(edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))}
            value={(main.matrixVariationMainGroup.label===null || main.matrixVariationMainGroup.label===undefined) ? '' : main.matrixVariationMainGroup.label}
          />       
        </TableCell>       
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validFrom")}
            value={main.matrixVariationMainGroup.validFrom}
          >
            {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validTo")}
            value={main.matrixVariationMainGroup.validTo}
          >
             {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        {(edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id)) && <React.Fragment>
        <Tooltip title="Megszorítás">
          <span>
            <IconButton
              onClick={()=>onConstraint(main.matrixVariationMainGroup)}   
              className={classes.howToRegButton}
              disabled={main.disabled || edit!==null || period===null}
            >
              <HowToRegIcon/>
            </IconButton>
         </span>
        </Tooltip>
        <Tooltip title="Módosítás">
          <span>
            <IconButton 
              onClick={()=>onEdit(main.matrixVariationMainGroup)} 
              className={classes.editIcon}
              disabled={main.matrixVariationMainGroup.disabled || edit!==null  || period===null || period.disabled===true}>
              <EditIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main.matrixVariationMainGroup)}   
              className={classes.deleteButton}
              disabled={main.disabled || period===null || edit!==null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
        </Tooltip></React.Fragment>
        }
        {(edit != null && edit.id == main.matrixVariationMainGroup.id) &&
          <React.Fragment> 
            <Tooltip title="Mentés">
              <span>
                <IconButton  
                  onClick={()=>onSave(mainIndex)} 
                  className={classes.saveButton} 
                  aria-label="save"
                >
                  <DoneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Mégsem">
              <IconButton 
                onClick={onCancel} 
                className={classes.cancelButton} 
                aria-label="cancel"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> 
          </React.Fragment> 
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Minősítés</TableCell>
                    <TableCell padding="none" align="center">Mennyiség</TableCell>
                    <TableCell padding="none" align="center">Alapértelmezett érték</TableCell>
                    <TableCell padding="none" align="center">Max érték</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.matrixVariationSubGroups.map((sub,subIndex) => (
                    <TableRow className={(deleted!==null && deleted.id==sub.id) ? classes.deleted : ''} key={sub.id}>
                      <TableCell padding="checkbox" align="left" component="th" scope="row">
                        {sub.id}
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                          {sub.parentId}
                      </TableCell>
                      <TableCell align="center">
                          <Select
                              disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))}
                              name="classificationId"
                              fullWidth
                              onChange={(event)=>onChange(event,mainIndex,subIndex,"classificationId")}
                              value={(sub.classificationId===null || sub.classificationId===undefined) ? '' : sub.classificationId}
                            >
                              {classifications.map((item,index)=>(
                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                              ))}
                           </Select>                        
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            type="number"
                            name="quantity"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"quantity",true)}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))}
                            value={(sub.quantity===null || sub.quantity===undefined) ? '' : sub.quantity}
                          />
                      </TableCell>   
                      <TableCell align="center">
                        <TextField
                            type="number"
                            name="defaultValue"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"defaultValue",true)}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))}
                            value={(sub.defaultValue===null || sub.defaultValue===undefined) ? '' : sub.defaultValue}
                          />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            type="number"
                            name="maxValue"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"maxValue",true)}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))}
                            value={(sub.maxValue===null || sub.maxValue===undefined) ? '' : sub.maxValue}
                          />
                      </TableCell>                                      
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validFrom"
                          fullWidth
                          value={sub.validFrom}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validTo"
                          fullWidth
                          value={sub.validTo}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                      <Tooltip title="Szűrők">
                          <span>
                            <IconButton
                              onClick={()=>onFilters(sub)}   
                              className={classes.groupButton}
                              disabled={main.disabled || edit!==null || period===null}
                            >
                              <GroupIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Törlés">
                          <span>
                            <IconButton
                              onClick={()=>onDelete(sub)}   
                              className={classes.deleteButton}
                              disabled={main.disabled || edit!==null || period===null || period.disabled===true}>
                              <DeleteIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell padding="none" colSpan={10}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új pont létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton
                                onClick={()=>onAddNew(1,main.matrixVariationMainGroup.id)}  
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != main.matrixVariationMainGroup.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


class VariationTree extends Component {

    constructor(props){
        super(props);
        this.state={
            variations:[],
            classifications:[],
            periods:[],
            period:'',
            edit:null,
            deleted:null,
            loading:null,
            newPoint:null,
            parentId:null,
            filter:null,
            constraint:null

        };
    }

    componentDidMount(){
        apiRequest.populatePeriods(this);
        apiRequest.populateClassifications(this);
    }

    componentDidUpdate(prevProps,prevState){
      const {period} = this.state;

      if(prevState.period!=period){
        this.setState({
          loading:true
        });
        apiRequest.populateVariations(this,period.id);
      }
    }

    handleEdit = (row) =>{
      this.setState({
        edit:{ ...row }
      });
    }

    handleCancel = () =>{
      const {period} = this.state;

      apiRequest.populateVariations(this,period.id);
      this.setState({
          edit:null
      });
    }

    handleNewPoint = (level,parentId) =>{
      this.setState({
        newPoint:level,
        parentId:parentId
      });
    }

    afterAddedNewPoint = () =>{
      const {period} = this.state;

      this.setState({
        newPoint:null,
        parentId:null,
        loading:true,
        edit:null
      });
      apiRequest.populateVariations(this,period.id)
    }

    handleChange = (event,mainIdex,subIndex,props,isNumber) => {
      let value = event.target.value;
      if(isNumber){
        value= value*1;
      }
      const variations = [...this.state.variations];
      if(subIndex===null){
        variations[mainIdex].matrixVariationMainGroup[props] = value;
      }else{
        variations[mainIdex].matrixVariationSubGroups[subIndex][props] = value;
      }
      
      this.setState({ variations });
    }

    handleSave = (mainIdex) =>{
        const {variations, period} = this.state;

        const model = {...variations[mainIdex],period:period.id};
        
        apiRequest.saveChanges(this,model,period.id);        
    }

    handlePeriodChange = (event) =>{
      const value = event.target.value;
      this.setState({
        period:value
      });
    }

    handleDelete = (row) =>{
      this.setState({
        deleted:row
      });
    }

    deleteRow = () =>{
      const {deleted, period} = this.state;

      const model = {id:deleted.id,period:period.id};
      apiRequest.deletePoint(this,model,period.id);
    }

    handleFilters = (row) =>{
      this.setState({
        filter:row
      });
    }

    handleConstraint = (row) =>{
      this.setState({
        constraint:row
      });
    }

    render(){
        const {variations, classifications, filter, edit, periods, constraint, period, deleted, parentId, newPoint, loading} = this.state;
        const {classes} = this.props;
        
        return (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="period-select-label">Periódusok</InputLabel>
                  <Select
                    labelId="period-select-label"
                    id="period-select"
                    value={period}
                    onChange={this.handlePeriodChange}
                  >
                    {periods.map(period=>
                    <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                  </Select>
              </FormControl>
              {period!=='' && <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                  <Typography style={{ fontWeight: 'bold' }}>Új variáció létrehozása: </Typography>
                  <Tooltip title="Új hozzáadás">
                      <span>
                          <IconButton
                            onClick={()=>this.handleNewPoint(0)} 
                            disabled={edit != null || period.disabled ? true : false}
                            className={classes.addButton} 
                            aria-label="add"
                          >
                              <AddIcon />
                          </IconButton>
                      </span>
                  </Tooltip>
              </div>}
              <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <PerfectScrollbar>
              <Table aria-label="collapsible table">
                  <TableHead>
                  <TableRow className={classes.head}>
                      <TableCell />
                      <TableCell padding="checkbox" align="left">Azonositó</TableCell>
                      <TableCell padding="none" align="center">Megnevezés</TableCell>
                      <TableCell padding="none" align="center">Érvényes-től</TableCell>
                      <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                      <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {variations.map((main,index) => (
                      <Row 
                        key={index}
                        mainIndex={index} 
                        main={main} 
                        classifications={classifications}
                        deleted={deleted} 
                        edit={edit}
                        period={(period==='' || period===undefined || period===null) ? null : period}
                        periods={periods}
                        onChange={this.handleChange} 
                        onDelete={this.handleDelete}
                        onFilters={this.handleFilters}
                        onConstraint={this.handleConstraint}
                        onAddNew={this.handleNewPoint}
                        onEdit={this.handleEdit}
                        onSave={this.handleSave}
                        onCancel={this.handleCancel}
                      />
                  ))}
                   {(period==='' || period===undefined || period===null) ?
                    <TableRow>
                        <TableCell align="center" colSpan="10">
                          Nincs kválasztva periódus
                        </TableCell>
                    </TableRow>
                    :  variations.length < 1 && 
                    <TableRow>
                      <TableCell align="center" colSpan="10">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                      </TableCell>
                    </TableRow>
                    }
                  </TableBody>
              </Table>
              </PerfectScrollbar>
              </TableContainer>
              
              <ConfirmDialog 
                onClose={()=>this.handleDelete(null)} 
                onDelete={this.deleteRow} 
                open={deleted!==null}
              />
               {newPoint!=null && <NewDialog
                parentId={parentId}
                refresh={this.afterAddedNewPoint}
                period={period}
                classifications={classifications}
                onClose={()=>this.handleNewPoint(null)} 
                level={newPoint}
                open={newPoint!=null}
              />}
              {filter!=null && <FilterDrawer
                open={filter!=null}
                period={period}
                periods={periods}
                variation={filter}
                onClose={()=>this.handleFilters(null)} 
              />}
              {constraint!=null && <ConstraintDrawer
                open={constraint!=null}
                period={period}
                periods={periods}
                variation={constraint}
                onClose={()=>this.handleConstraint(null)} 
              />}
            </div>
        );
    }
}

export default withStyles(style.useStyles)(VariationTree)