import React, { Component } from 'react';
//Core
import {
    Grid,
    Typography,
    Divider,
    Box,
} from '@material-ui/core';
//Components
import { RatingLine, SelectList } from './components';
import { enums } from 'stores';
//Style
import style from './style';
import TextBox from './components/TextBox/TextBox';

const InputTypes = enums.inputTypes;

export default function Element(props) {
    const { data, handleMotivationPoints } = props;
    const classes = style.useStyles();

    const isRequired = (array, item) => {
        const temp = array?.find(_item => _item?.id === item?.inputType?.requiredById);
        if (temp !== undefined && temp !== null && temp?.value !== null && temp?.value !== undefined) {
            if (item?.inputType?.requiredByValue?.includes(temp?.value?.toString())) {
                return true;
            }
        }
        return false;
    }

    return (<div className={classes.root}>
        {data.map((item, index) => (
            <React.Fragment key={index}>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        className={classes.center}
                        xl={7}
                        lg={7}
                        md={7}
                        sm={12}
                        xs={12}
                    >
                        <Typography className={classes.label}>{item.label}</Typography>
                    </Grid>
                    <Grid
                        item
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                    >
                        {/*RatingLine with subpoints */}
                        {item.subElements.length != 0 && item.subElements.map((subElement, index) => (
                            subElement.inputType.id === InputTypes.RATINGLINE &&
                            <React.Fragment key={index}>
                                <Grid container>
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Typography className={classes.label}>{subElement.label}</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                        <RatingLine data={subElement.inputType.inputValues} name={subElement.id} itemId={subElement.id} handleChanges={handleMotivationPoints} index={index} defValue={subElement.value} />
                                    </Grid>
                                </Grid>
                                {((item.subElements.length - 1) > index) && <Divider className={classes.divider} />}
                            </React.Fragment>
                        ))}
                        {/*Alone RatingLine without subpoints */}
                        {(item.inputType != null && item.inputType.id === InputTypes.RATINGLINE && item.subElements.length === 0) &&
                            <React.Fragment>
                                <Grid container>
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                    </Grid>
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                        <RatingLine data={item.inputType.inputValues} name={item.id} itemId={item.id} handleChanges={handleMotivationPoints} index={index} defValue={item.value} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {/*SelectList*/}
                        {(item.inputType != null && item.inputType.id === 3 && item.inputType.inputValues.length != 0) &&
                            <React.Fragment>
                                <Grid container>
                                    <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <SelectList handleChanges={handleMotivationPoints} itemId={item.id} data={item.inputType.inputValues} defValue={item.value} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {(item.inputType != null && item.inputType.id === InputTypes.TEXTFIELD) &&
                            <React.Fragment>
                                <Grid container>
                                    <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextBox
                                            handleChanges={handleMotivationPoints}
                                            itemId={item.id}
                                            defValue={item.value}
                                            isRequired={isRequired(data, item)}

                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>

                </Grid>
                <Divider className={classes.divider} />
            </React.Fragment>
        ))}

    </div>)
}
