
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetCompetences: 'rating/self/competences',
    Save: 'rating/self/competences/save'
}

export const apiRequest = {
    handleSaveChanges,
    populateCompetences
};

async function handleSaveChanges(parent,closed,competences) {
    //Info message snack
    parent.setState({
        isModified: false
    });
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    var model = null;
    const postObjectArray = [];
    competences.map(group => group.competenceSubGroup.map(item => {
        item.competences.map(competence => {
            postObjectArray.push({ id: competence.compToPosId, value: competence.value })
        });
    }));

    var model = {minimalizedCompetences: postObjectArray};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
        await new Api().call.post(Actions.Save,model)
        .then(handleResponse)
        .then(res => {
            if (!closed) {
                parent.setState({
                    isModified: false,
                    gridIsModified: false,
                    baseCompetences: res.baseData
                });
            }
        }).catch(error => {

            handleError(error);          
            parent.setState({
                isModified: true
            });
        });
     
}


//Populate datas
async function populateCompetences(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetCompetences,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            competences: res,
            baseCompetences: JSON.parse(JSON.stringify(res)),
            gridCompetences: JSON.parse(JSON.stringify(res)),
            isModified: false,
            loading: false,
            avg: null
        });
    }).catch(handleError);
       
}