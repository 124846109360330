import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize:'0.8rem'
        
    },
    subContent: {
        fontFamily: 'Nunito',

    },
    avatar: {
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32,
        color: theme.palette.appMain.main
    }
}));
