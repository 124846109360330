import React, { Component } from 'react';
//Core
import { Card, 
CardContent,
Grid, 
Typography, 
Avatar, 
Tooltip,
} from '@material-ui/core';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';



class LeaderBox extends Component {


    render() {
        const { classes, leader } = this.props;
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="inherit"
                                gutterBottom
                                variant="body2"
                            >
                                KÖZVETLEN FELETTESED :
                            </Typography>
                            <Typography
                                color="inherit"
                                className={classes.leaderName}
                            >
                                {leader?.fullName}
                            </Typography>
                            <Typography
                                color="inherit"
                                className={classes.leaderBio}
                            >
                                {leader?.bio}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title={leader?.fullName}>
                                    <Avatar
                                        className={classes.avatar}
                                        src={(leader?.avatar !== null && leader?.avatar !== undefined) ? leader?.avatar : ''}
                                    />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}
export default withStyles(style)(LeaderBox);
