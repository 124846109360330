import  theme  from 'theme';

const useStyles = () => ({
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    }
});

export default useStyles;
