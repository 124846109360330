//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    IconButton 
    } from '@material-ui/core';
//Components

import { 
    Pagination, 
    DataTable, 
    AddNewDialog 
} from './components';
import { apiRequest } from './apiRequest';
import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class FilterDrawer extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            filters: [],
            loading: {
              filters: true,
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit:null,
        }

    }
    //#Lifecycle methods
    componentDidMount() {
        const {period} = this.props;
        
        apiRequest.populateFilters(this,1,period);
    }
    //#EndLifecycle methods

    handleDelete = (index) => {
        const {filters} = this.state;
        const {period} = this.props;

        const model ={id:filters[index].id, period:period.id}
        apiRequest.deleteFilter(this, model);
    }

    handlePageChanges = (nextPage) => {
        const {period} = this.props;

        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                filters: true
            }  
        }));
        apiRequest.populateFilters(this,nextPage,period);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () =>{
        const {page} = this.state;
        const {period} = this.props;

        apiRequest.populateFilters(this,page,period);
    }

    handleEdit = (row) =>{
        this.setState({
          edit:{ ...row }
        });
    }

    handleCancel = () =>{
        const {page} = this.state;
        const {period} = this.props;

        apiRequest.populateFilters(this,page,period);
        this.setState({
            edit:null
        });
    }

    handleChange = (event,index,isNumber) => {
        let value = event.target.value;
        let name = event.target.name;
        if(isNumber){
          value= value*1;
        }
        const filters = [...this.state.filters];
        filters[index][name] = value;
        
        this.setState({ filters });
    }

    handleSave = (index) =>{
        const {filters} = this.state;
        const {period, variation} = this.props;

        const model = {variationFilters:{...filters[index]},period:period.id,variation:variation.id};
        
        apiRequest.saveChanges(this,model);        
    }

    render() {
        const {open, filters, currentPage, step, total, pages, loading, edit} = this.state;
        const {classes, snackbar, onClose, variation, period, periods} = this.props;

        return (
            <Drawer anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <Typography className={classes.subTitle}>Szűrők</Typography>
                        <div style={{flexGrow:1}}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.root}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                        <div className={classes.content}>
                            <DataTable
                                loading={loading.filters}
                                edit={edit}
                                period={period}
                                periods={periods}
                                onChange={this.handleChange} 
                                onOpen={this.handleOpen}
                                onSave={this.handleSave} 
                                onDelete={this.handleDelete}
                                onEdit={this.handleEdit}
                                onCancel={this.handleCancel}  
                                data={filters}
                            />
                        </div>
                        {(loading.filters === false) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                </Grid>
                {open == true && 
                    <AddNewDialog
                        period={period}
                        variation={variation} 
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </Drawer>
        );
    }
};

export default withStyles(style)(FilterDrawer);
