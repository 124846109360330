import { BehaviorSubject } from 'rxjs';
//Components
import { NotificationBar } from 'components';
import {Api,handleError} from 'api';
import {Constants,UserStore} from 'stores';

//Actions
const Actions = {
    SignIn: 'auth/login',
    Logout:'auth/revoke-token',
    RefreshToken:'auth/refresh-token'
}

const remainingTimeSubject = new BehaviorSubject(null);

export const authenticationService = {
    login,
    logout,
    logoutForced,
    refreshToken,
    remainingTimeSubject: remainingTimeSubject,
    remainingTime: remainingTimeSubject.asObservable(),
    get remainingTimeValue() { return remainingTimeSubject.value }
};

async function login(userName, password,system) {
    const history = Constants.getHistory();
    const model = { userName,password,system };
    await new Api().callAnonymus.post(Actions.SignIn, model, {withCredentials: true}).then(value => {
        UserStore.setUser(value.data);
        UserStore.setLoggedInStatus('LOGGED_IN');
        UserStore.setRequest(false);
        if(value.data.system==='EXIT'){
            history.push('/exit/users-list');
        }else if(value.data.system==='PROBAIDO'){
            history.push('/probaido/home');
        }else{
            history.push('/home');
        }
       
        NotificationBar('Sikeres bejelentkezés', 'top', 'right', 2000, 'success', null);
    }).catch(error => {
        handleError(error);
        UserStore.setRequest(false);
        throw error;
    });
}

async function logout() {
    const history = Constants.getHistory();
    await new Api().call.post(Actions.Logout,{},{withCredentials: true}).then(value => {
        UserStore.setLoggedInStatus('NOT_LOGGED_IN');
        NotificationBar('Sikeres kijelentkezés', 'top', 'right', 2000, 'success', null);
        UserStore.setRequest(false);
        UserStore.setUser(null);
        UserStore.clearLoggedByAdmin();
        history.push('/sign-in');
    }).catch(error => {
        console.error(error);
    });
}

async function logoutForced() {
    const history = Constants.getHistory();
        UserStore.setLoggedInStatus('SESSION_IS_OVER');
        NotificationBar('Lejárt a munkamenet, kérlek jelentkezz be újra!', 'top', 'right', 3000, 'warning', null);
        UserStore.setRequest(false);
        UserStore.setUser(null);
        UserStore.clearLoggedByAdmin();
        history.push('/sign-in');      
}


async function refreshToken() {
    const history = Constants.getHistory();
    await new Api().callAnonymus.post(Actions.RefreshToken,null,{withCredentials: true}).then(value => {
        UserStore.setUser(value.data);
        UserStore.setLoggedInStatus('LOGGED_IN');
        UserStore.setRequest(false);
    }).catch(error => {
        handleError(error);
        if(UserStore.getLoggedInStatusValue()==='LOGGED_IN' || UserStore.getLoggedInStatusValue()==='SESSION_IS_OVER'){
            UserStore.setLoggedInStatus('SESSION_IS_OVER');
        }else{
            UserStore.setLoggedInStatus('NOT_LOGGED_IN');
        }
        UserStore.setRequest(false);
        UserStore.setUser(null);

    });
}

