import React from 'react';
//Core
import {
    TableRow
  } from '@material-ui/core';

import { makeStyles,withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
    },
    doneIcon:{
        color: theme.palette.success.main
    },
    blockIcon:{
        color: theme.palette.error.main
    },
    saveButton: {
        color: theme.palette.success.main
    },
    cancelButton: {
        color: theme.palette.error.main
    },
    editButton: {
        color: theme.palette.warning.main
    },
    addButton: {
        color: theme.palette.success.main
    },
    deleteButton: {
          color:theme.palette.error.main
    }
  }));

  const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '0.8rem',

        },
    },
    head: {
        '& > *': {
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)

const useStylesForPicker = makeStyles(theme => ({
    root: {
    },
    picker: {
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
}));

  export default {
    useStylesForPicker,
    useStyles:useStyles,
    StyledTableRow:StyledTableRow

  };