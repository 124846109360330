//React

import React, { useEffect } from 'react';
//Core

import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
//Components

import { Constants } from 'stores';
//Icons

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style

import style from './style';


const NotFound = (props) => {
    useEffect(() => {
        Constants.setcontentLoading(false);
    }, []);

    const classes = style();
    const history = Constants.getHistory();
    
    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.content}>
                        <img
                            alt="404"
                            className={classes.image}
                            src="/images/404.png"
                        />
                        <Typography variant="h1" className={classes.contentTitle}>
                            404
                        </Typography>
                        <Typography variant="h1" className={classes.contentTitle}>
                            A keresett oldal nem található
                        </Typography>
                        <Typography className={classes.contentSubTitle} variant="subtitle2">
                        </Typography>
                        <Button
                            className={classes.buttons}
                            size="small"
                            startIcon={<ArrowBackIcon />}
                            color="primary"
                            onClick={() => history.goBack()}
                            variant="contained"
                        >
                            Vissza
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotFound;
