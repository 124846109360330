//React

import React, { useState } from 'react';
import clsx from 'clsx';
//Pickers

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Style

import style from './style';


export default function DateSelect(props) {
    const { readOnly, defValue, inputId } = props;

    var mappedValue = defValue;
    if (defValue != null && defValue != undefined && defValue != '') {
        const splittedValue = defValue?.split('-');
        if (splittedValue?.length > 1) {
            if (splittedValue[1].length === 1) {
                mappedValue = splittedValue[0] + "-0" + splittedValue[1]
            }
        }
    }

    const [selectedDate, setSelectedDate] = useState((defValue !== null && defValue != undefined) ? new Date(mappedValue) : null);
    const [invalidDate, setInvalidDate] = React.useState(false);

    const handleDateChange = (date) => {
        setSelectedDate((defValue !== null && defValue != undefined) ? new Date(defValue) : null);
    }

    const classes = style();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="dense"
                id={inputId}
                invalidDateMessage="Nem megfelelő formátum"
                error={(selectedDate === null || invalidDate) ? true : false}
                helperText={invalidDate ? "Nem megfelelő formátum" : selectedDate === null && "Kötelező választani"}
                inputVariant="outlined"
                className={(selectedDate === null || invalidDate) ? clsx(classes.picker, "goal-new-notvalid") : classes.picker}
                cancelLabel="Vissza"
                readOnly={readOnly}
                openTo="month"
                label="Elvárt"
                format="yyyy MMMM"
                views={["year", "month"]}
                autoOk
                InputAdornmentProps={{ position: "end" }}
                value={selectedDate}
                onChange={date => handleDateChange(selectedDate)}
            />
        </MuiPickersUtilsProvider>

    );
}

