import theme  from 'theme';


const useStyles = ({
    doneButton: {
        color: theme.palette.success.main
    },
    backButton: {
        color: theme.palette.error.main
    },
    formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
    }
});

export default useStyles;
