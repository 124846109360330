//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Drawer,
    AppBar,
    Toolbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton 
} from '@material-ui/core';
//Components

import { 
    Pagination, 
    DataTable, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class RoleDrawer extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            roles: [],
            period:'',
            periods:[],
            loading: {
              roles: null,
              positions:true,
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
        }

    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populatePeriods(this);       
    }

    componentDidUpdate(prevProps,prevState){
        const {period, page} = this.state;

        if(period!=prevState.period){
            apiRequest.populateRoles(this,1,period);
        }
    }
    //#EndLifecycle methods

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]:value
        });
    }

    handleDelete = (index) => {
        const {roles, period} = this.state;

        const model ={id:roles[index].id, period:period.id}
        apiRequest.deleteRoles(this, model);
    }

    handlePageChanges = (nextPage) => {
        const {period} = this.state;

        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                roles: true
            }
        }));
        apiRequest.populateRoles(this, nextPage,period);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () =>{
        const {page, period} = this.state;

        apiRequest.populateRoles(this, page,period);
    }
   
    render() {
        const {open, roles, currentPage, step, periods, total, pages, loading, period} = this.state;
        const {classes, snackbar, onClose, filter} = this.props;

        return (
            <Drawer anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="period-select-label">Periódusok</InputLabel>
                            <Select
                                labelId="period-select-label"
                                id="period-select"
                                value={period}
                                name="period"
                                onChange={this.handleChange}
                            >
                                {periods.map(period=>
                                <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <div style={{flexGrow:1}}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.root}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                        <div className={classes.content}>
                            <DataTable
                                loading={loading.roles}
                                period={period}
                                filter={filter}
                                periods={periods} 
                                onOpen={this.handleOpen} 
                                onDelete={this.handleDelete}    
                                data={roles}
                            />
                        </div>
                        {(loading.roles === false) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                </Grid>
                {open == true && 
                    <AddNewDialog
                        period={period}
                        filter={filter} 
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </Drawer>
        );
    }
};

export default withStyles(style)(RoleDrawer);
