import React, { Component } from 'react';
//Core
import { CircularProgress, Grid, Typography,Divider } from '@material-ui/core';
//Components
import { Pagination, DataTable, Toolbar, AddNewDialog,EmailsDialog } from './components';
import { apiRequest } from './apiRequest';
import { Constants } from 'stores';
//Styles
import { withStyles } from '@material-ui/styles';
import style from './style';




class EmailGroupsTable extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            emailGroups: [],
            loading: {
                emailGroups: true,
              f_emailGroups:true,
            },
            filter: {
                emailGroups: [],
            },
            selectedFilter:{
                emailGroups:[],
                active:null,
            },
            emails:null,
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit:null,
        }

    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateEmailGroups(this, this.state.page);
        apiRequest.populateEmailGroupsFilters(this);
    }
    //#EndLifecycle methods


    handleChange = (event,index,props) => {
        const value = event.target.value;
        const emailGroups = [...this.state.emailGroups];
        emailGroups[index][props] = value;
        this.setState({ emailGroups });
    }

    handleValidChange = (index,value) =>{
        const emailGroups = [...this.state.emailGroups];
        emailGroups[index]['isValid'] = value;
        this.setState({ emailGroups });
        apiRequest.saveEdit(this, emailGroups[index]);
    }

    handleEdit = (role) => {
        this.setState({
            edit: { ...role }
        });
    }

    handleSave = (index) => {
        const { emailGroups } = this.state;
        apiRequest.saveEdit(this, emailGroups[index]);
    }

    handleCancel = (index) => {
        const emailGroups = [...this.state.emailGroups];
        emailGroups[index].label = this.state.edit.label;
        emailGroups[index].description = this.state.edit.description;
        this.setState({
            emailGroups,
            edit:null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                emailGroups: true
            },
            selectedFilter: {
                ...prevState.selectedFilter,
                [props]: value
            }
        }), () => apiRequest.populateEmailGroups(this,1));
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                emailGroups: true
            }
        }));
        apiRequest.populateEmailGroups(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () =>{
        const {page} = this.state;
        apiRequest.populateEmailGroups(this, page);
        apiRequest.populateEmailGroupsFilters(this);
    }

    handleEmails=(value)=>{
        this.setState({
            emails:value
        });
    }
   
  
    render() {
        const {open,emailGroups, filter, currentPage,emails, step, total,selectedFilter, pages, edit, loading } = this.state;
        const { classes, snackbar } = this.props;
        return (
            <div>
                {emails!==null && <EmailsDialog selected={emails} open={true} onClose={()=>this.handleEmails(null)} />}
                <Grid container>
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        item
                    >
                         {loading.emailGroups === false && 
                            <Toolbar
                                onBlockSelected={this.handleBlockSelected} 
                                onUnBlockSelected = {this.handleUnBlockSelected}
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter}
                                selectedFilter={selectedFilter}  
                                loading={loading} 
                                selected={[]} 
                            />
                         }
                        <div className={classes.content}>
                                <DataTable
                                    onEmails={this.handleEmails}
                                    loading={loading.emailGroups}
                                    onValidChange={this.handleValidChange} 
                                    onOpen={this.handleOpen} 
                                    onCancel={this.handleCancel} 
                                    edit={edit} 
                                    onEdit={this.handleEdit} 
                                    onSave={this.handleSave} 
                                    onChange={this.handleChange} 
                                    emailGroups={emailGroups}
                                />
                        </div>
                        {(loading.emailGroups === false && edit==null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                </Grid>
                {open == true && 
                    <AddNewDialog 
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </div>
        );
    }

};

export default withStyles(style)(EmailGroupsTable);
