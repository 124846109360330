
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    getUsers: 'exit/auto-complete/users',
    getTypes:'exit/auto-complete/exit-types',
    getBio:'exit/bio',
    getLeader:'exit/leader',
    getOrganization:'exit/organization',
    saveUser:'exit/add-user'
}

export const apiRequest = {
    populateUsers,
    populateTypes,
    populateBio,
    saveUser,
    populateLeader,
    populateOrganization
};

async function populateUsers(parent) {

        await new Api().call.get(Actions.getUsers)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                users:res
            });
        }).catch(handleError);
       
}

async function saveUser(parent) {
    const {user,exitType,exitNotice,exitDate,exitTime} = parent.state;
    const newExitTime=`${new Date(exitTime).getHours()}:${new Date(exitTime).getMinutes()}`
    //Info message snack
     NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = {user,exitType,exitNotice,exitDate,exitTime:newExitTime};
    await new Api().call.post(Actions.saveUser,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            user:'',
            bio:'',
            organization:'',
            leader:'',
            exitType:'',
            exitNotice:new Date(),
            exitDate:new Date(),
            exitTime:new Date()
        })
    }).catch(handleError);
   
}

async function populateTypes(parent) {

    await new Api().call.get(Actions.getTypes)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            exitTypes:res
        });
    }).catch(handleError);
   
}

async function populateBio(parent) {
    const {user} = parent.state;
    await new Api().call.get(`${Actions.getBio}/${user.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            bio:res
        });
    }).catch(handleError);
   
}


async function populateLeader(parent) {
    const {user} = parent.state;
    await new Api().call.get(`${Actions.getLeader}/${user.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            leader:res
        });
    }).catch(handleError);
}

async function populateOrganization(parent) {
    const {user} = parent.state;
    await new Api().call.get(`${Actions.getOrganization}/${user.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            organization:res
        });
    }).catch(handleError);
   
}

