//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { 
    BiosToolbar, 
    BiosTable, 
    Pagination, 
    AddNewDialog,
    CompDrawer,
    RoleDrawer 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';


class Positions extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            bios: [],
            loading: {
                bios: true,
                filterBios: true,
                filterCompanies:true,
            },
            filter: {
                bios: [],
                companies:[],
                active: null
            },
            comp:null,
            role:null,
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            filterBios: [],
            filterCompanies: [],
            edit:null,
            selectedBios:[],
        }

    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateBios(this, this.state.page);
        apiRequest.populateFilterBios(this);
        apiRequest.populateFilterCompanies(this);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (bios) => {
        this.setState({
            selectedBios: bios
        });
    }

    handleChange = (event,index,props) => {
        const value = event.target.value;
        const bios = [...this.state.bios];
        bios[index][props] = value;
        this.setState({ bios });
    }

    handleEdit = (bio) => {
        this.setState({
            edit: { ...bio }
        });
    }

    handleSave = (index) => {
        const { bios } = this.state;
        apiRequest.saveChanges(this, bios[index]);
    }

    handleCancel = (index) => {
        const bios = [...this.state.bios];
        bios[index].name = this.state.edit.name;
        this.setState({
            bios,
            edit:null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                bios: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateBios(this,1));
    }

    handleDelete = (index) => {
        const bios = [...this.state.bios];
        bios[index].isValid = false;
        apiRequest.saveChanges(this, bios[index]);
    }

    handleDone = (index) => {
        const bios = [...this.state.bios];
        bios[index].isValid = true;
        apiRequest.saveChanges(this, bios[index]);
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                bios: true
            }
        }));
        apiRequest.populateBios(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    handleBlockSelected = () =>{
        const {selectedBios,page} = this.state;
        const model ={positions:selectedBios,valid:false};
        apiRequest.validatePositions(this,page,model);
        this.setState({
            selectedBios:[]
        });
    }

    handleUnBlockSelected = () =>{
        const {selectedBios,page} = this.state;
        const model ={positions:selectedBios,valid:true};
        apiRequest.validatePositions(this,page,model);
        this.setState({
            selectedBios:[]
        });
    }

    refresh = () =>{
        const {page} = this.state;
        apiRequest.populateBios(this, page);
        apiRequest.populateFilterBios(this);
    }

    handleComp = (comp) =>{
        this.setState({
            comp
        });
    }

    handleRole = (role) =>{
        this.setState({
            role
        });
    }

    render() {
        const {bios, selectedBios, open, filter, currentPage, role, step, comp, total, pages, filterBios, filterCompanies, edit, loading} = this.state;
        const {classes, snackbar} = this.props;
        
        return (
            <div className={classes.root}>
                <Typography className={classes.title}>Munkakörök</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={10} item>
                        {loading.bios === false && 
                            <BiosToolbar
                                onBlockSelected={this.handleBlockSelected}
                                onUnBlockSelected = {this.handleUnBlockSelected}  
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter} 
                                companies={filterCompanies} 
                                bios={filterBios} 
                                loading={loading} 
                                selected={selectedBios} 
                            />
                        }
                        <div className={classes.content}>
                            <BiosTable
                                loading={loading.bios}
                                onOpen={this.handleOpen} 
                                onDelete={this.handleDelete} 
                                onDone={this.handleDone}
                                onComp = {this.handleComp}
                                onRole= {this.handleRole} 
                                onCancel={this.handleCancel} 
                                edit={edit} 
                                onEdit={this.handleEdit} 
                                onSave={this.handleSave} 
                                onChange={this.handleChange} 
                                onSelect={this.handleSelectCompanies} 
                                bios={bios}
                                selectedBios={selectedBios} 
                            />
                        </div>
                        {(loading.bios === false && edit==null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                </Grid>
                {comp!=null && 
                    <CompDrawer
                        position={comp} 
                        onClose={()=>this.handleComp(null)}
                    />
                }
                {role!=null && 
                    <RoleDrawer
                        position={role} 
                        onClose={()=>this.handleRole(null)}
                    />
                }
                {open == true && 
                    <AddNewDialog
                        refresh={this.refresh} 
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </div>
        );
    }
};

export default withStyles(style)(Positions);
