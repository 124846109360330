//React

import React from 'react';
//Core

import {
  Grid,
  Typography,
} from '@material-ui/core';
//Style

import style from './style';


const Maintenance = props => {

  const classes = style();
  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}/>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <div className={classes.form}>
                <div className={classes.contentHeader}>
                  <img
                    alt="Logo"
                    src="/images/logos/logo--app.png"
                    height="125"
                    width="140"
                  />
                </div>
                <Typography
                  className={classes.title}
                  variant="h1"
                >
                  Merkbau<span className={classes.span}>TÉR</span>
                </Typography>
                <Typography
                  className={classes.description}
                  variant="h2"
                >
                  Jelenleg karbantartás miatt az oldal nem üzemel.
                </Typography>
                <Typography
                  className={classes.description}
                  variant="h2"
                >
                  Kérlek nézz vissza később!
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Maintenance;
