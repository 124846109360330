import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    headerDiv:{
        display:'flex',
        alignItems:'center'
    },
    flexGrow: {
        flexGrow: 3
    },
    lockIcon: {
        color: theme.palette.warning.main
    },
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            color:theme.palette.disabled.default,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        }
    }
});


export default useStyles;
