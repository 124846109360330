//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import huLocale from "date-fns/locale/hu";
//Core

import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Typography,
    Tooltip,
    IconButton,
    InputAdornment
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import TodayIcon from '@material-ui/icons/Today';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';


const StyledTableRow = style.StyledTableRow;

const DateTimePicker = props => {
    const {value, disabled, id, name, label, minDate, maxDate, error, readOnly, onChange, helperText} = props;

    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                clearable={true}
                clearLabel="Törlés"
                emptyLabel="Nincs megadva"
                id={id}
                name={name}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                error={error}
                helperText={helperText}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                maxDate={maxDate}
                minDateMessage="Nem megengedett érték"
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider >
    )
}

const LeadersTable = props => {
    const {className, onOpenWarning, onOpen, isModifable, users, approved, lastValidFrom, onValidFromChange, onDone, onDelete, onValidToChange, onEdit, onBack, onSelect, ...rest} = props;
    
    const classes = style.useStyles();
    const [editRow,setEditRow] = React.useState(null);

    const checkCanAddNew = () => {
        if (users.filter(item => item.disabled === false).length > 0) {
            return false;
        }
        if (users.filter(item => item.validTo === null || item.validTo === undefined || item.validTo === 'null').length > 0) {
            return false;
        }
        return true;
    }

    const checkCanSave = (user) => {
        if (user.validFrom == null || user.validFrom == undefined) {
            return false;
        }
        if (user.validTo !== null && user.validTo !== undefined && (new Date(user.validTo) >= new Date(user.validFrom))) {
            return true;
        }
        if ((user.validTo === null || user.validTo === undefined) && (user.validFrom !== null && user.validFrom !== undefined)) {
            return true;
        }
        return false;
    }

    function handleEdit(user){
        setEditRow(user);
    }
    
    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell>Teljes név</TableCell>
                                    <TableCell>Munkakör</TableCell>
                                    <TableCell>Szervezeti egység</TableCell>
                                    <TableCell>Érvényes -től</TableCell>
                                    <TableCell>Érvényes -ig</TableCell>
                                    <TableCell align="center">Műveletek</TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user,index) => (
                                    <StyledTableRow
                                        className={classes.tableRow}
                                        hover
                                        key={user.id}
                                    >
                                        <TableCell>
                                        <div className={classes.nameContainer}>
                                            <Avatar
                                                className={classes.avatar}
                                                src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                                            />
                                            <Typography variant="body1">
                                                {user.fullName}
                                            </Typography>
                                        </div>
                                        </TableCell>
                                        <TableCell>{user.bio}</TableCell>
                                        <TableCell>{user.organization}</TableCell>
                                        <TableCell>
                                            <DateTimePicker
                                                minDate={lastValidFrom}
                                                helperText={(user.validFrom !== undefined && user.validFrom !== null) ? '' : 'Kötelező kitölteni'}
                                                error={(user.validFrom !== undefined && user.validFrom !== null) ? false : true}
                                                onChange={(date) => onValidFromChange(user, date)}
                                                disabled={!(editRow==user) || user.isClosed===true}
                                                value={user.validFrom} />
                                        </TableCell>
                                        <TableCell>
                                            <DateTimePicker
                                                minDate={(new Date(user.validFrom) > new Date(lastValidFrom)) ? user.validFrom : lastValidFrom}
                                                error={(user.validTo !== null && user.validTo !== undefined && (new Date(user.validFrom) > new Date(user.validTo))) ? true : false}
                                                helperText={(user.validTo !== null && user.validTo !== undefined && (new Date(user.validFrom) > new Date(user.validTo))) ? 'Nem megengedett érték' : ''}
                                                onChange={(date) => onValidToChange(user, date)}
                                                disabled={!(editRow==user)}
                                                value={user.validTo} />                                                  
                                        </TableCell>
                                        <TableCell align="center" padding="checkbox">
                                            {(editRow==null || editRow.rowId!==user.rowId) &&
                                                <React.Fragment> 
                                                    <Tooltip title="Adatok módosítása">
                                                        <span>
                                                            <IconButton
                                                                disabled={(editRow !== null || user.disabled===true) ? true : false}   
                                                                onClick={() => handleEdit(user)} 
                                                                className={classes.editButton} 
                                                                aria-label="edit"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Felettes törlése">
                                                        <span>
                                                            <IconButton 
                                                                disabled={user.isClosed}  
                                                                onClick={() => onDelete(user)} 
                                                                className={classes.deleteButton} 
                                                                aria-label="delete"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                            {(editRow!==null && editRow.rowId==user.rowId) && 
                                                <React.Fragment>
                                                    <Tooltip title="Mentés">
                                                        <span>
                                                            <IconButton 
                                                                disabled={!checkCanSave(user)} 
                                                                className={classes.doneButton} 
                                                                onClick={() => onDone(user)} 
                                                                aria-label="done"
                                                            >
                                                                <DoneIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Mégsem">
                                                        <span>
                                                            <IconButton 
                                                                className={classes.backButton} 
                                                                onClick={() => handleEdit(null)} 
                                                                aria-label="back"
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }                                                                      
                                        </TableCell>
                                    </StyledTableRow>))
                                }
                                {users.length < 1 &&
                                    <StyledTableRow>
                                        <TableCell align="center" colSpan="9">
                                            Nincs találat
                                        </TableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions>
                {isModifable === false && 
                    <IconButton 
                        onClick={onOpenWarning} 
                        className={approved == true ? classes.approvedIcon : classes.warningIcon}
                    >
                        <WarningIcon />
                    </IconButton>
                }
                <div style={{ display: 'flex', alignItems:'center', marginLeft: 'auto', }}>
                    <Typography style={{fontWeight:'bold'}}>
                        Új felettes hozzáadása: 
                    </Typography>
                    <Tooltip title="Új hozzáadás">
                        <span>
                            <IconButton  
                                onClick={onOpen} 
                                className={classes.addButton} 
                                aria-label="add"
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </CardActions>
        </Card>
    );
};

LeadersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default LeadersTable;
