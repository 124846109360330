//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    NotificationBar,
    Progress
} from 'components';
//Actions

const Actions = {
    GetUsers: 'probaido/auto-complete/users',
    GetHrs: 'probaido/auto-complete/hrs',
    SaveUser: 'probaido/admin/exsits/add-new',
    GetUser: 'probaido/admin/exits/user'
}

export const apiRequest = {
    populateUsers,
    populateUser,
    populateHrs,
    saveUser
};

async function saveUser(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const { hireDate, endDate, hr, selected } = parent.state;
    const { onClose, refreshTable } = parent.props;
    const model = { hireDate, endDate, hr, user: selected }
    await new Api().call.post(Actions.SaveUser, model)
        .then(handleResponse)
        .then(res => {
            refreshTable();
            onClose();
        }).catch(handleError);
}

async function populateUsers(parent) {
    await new Api().call.get(Actions.GetUsers)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateUser(parent) {
    const { selected } = parent.state;
    await new Api().call.get(`${Actions.GetUser}/${selected.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    user: false
                },
                user: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        });
}

async function populateHrs(parent) {
    await new Api().call.get(Actions.GetHrs)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                },
                hrs: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    hrs: false
                }
            }));
        });
}
