import React, { Component } from 'react';
//Core
import {
    Typography,
    Avatar
} from '@material-ui/core';
//Style
import style from './style';



export default function MainHeader(props) {
    const { title, additionalData } = props;
    const classes = style.useStyles();
    return (<div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {additionalData != null && <React.Fragment>
            <Typography className={classes.additionalDataSpace} >-</Typography>
            <Avatar alt={additionalData.fullName} src={(additionalData.avatar !== null && additionalData.avatar !== undefined) ? additionalData.avatar : ''} />
            <Typography className={classes.additionalData}>{additionalData.fullName}</Typography></React.Fragment>}
    </div>)
}
