//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetInputPoints:'admin/masterData/rating-periods',
    GetRoles:'admin/masterData/rating-periods/roles',
    GetPeriods:'admin/masterData/periods',
    Delete:'admin/masterData/rating-periods/delete/steps',
    Save:'admin/masterData/rating-periods/edit/save',
    PauseStep:'admin/masterData/rating-periods/step/pause',
    PausePeriod:'admin/masterData/rating-periods/period/pause'
}

export const apiRequest = {
    populateInputPoints,
    populateRoles,
    populatePeriods,
    deletePoint,
    saveChanges,
    pauseStep,
    pausePeriod
};

async function populateInputPoints(parent,period) {
    await new Api().call.get(`${Actions.GetInputPoints}/${period}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            inputPoints:res,
            loading:false
        });
    }).catch(handleError);
}

async function populateRoles(parent) {
    await new Api().call.get(Actions.GetRoles)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            roles:res
        });
    }).catch(handleError);
}

async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            periods:res
        });
    }).catch(handleError);
}

async function deletePoint(parent,model,period)
{
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            deleted:null
        });
        populateInputPoints(parent,period);
    }).catch(handleError);
}

async function saveChanges(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        populateInputPoints(parent,period);
    }).catch(handleError);
}

async function pauseStep(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.PauseStep,model)
    .then(handleResponse)
    .then(res => {
        populateInputPoints(parent,period);
    }).catch(handleError);
}

async function pausePeriod(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.PausePeriod,model)
    .then(handleResponse)
    .then(res => {
        populateInputPoints(parent,period);
    }).catch(handleError);
}

