import React, { Component } from 'react';
//Components
import { MuiOutlinedSelect } from 'components';
import {apiRequest} from './apiRequest';

class SelectScale extends Component {
    state = {
        options:[]
    }

    componentDidMount() {
        apiRequest.populateOptions(this);
    }

    handleSelectChange = (event) => {
        const { options } = this.state;
        const { handleChange } = this.props;
        const selectedId = event.target.value;
        let selectedObject = options.filter(item => item.id === selectedId)[0];
        handleChange('addNew', selectedObject);
    }


    render() {
        const { value, disabled } = this.props;
        const { options } = this.state;
        return (
            <MuiOutlinedSelect
            margin="dense"
            className={(value === null || value === 0 || value === '0' || value === '' || options.length === 0 || options === null || options === undefined) && "goal-new-add-notvalid"}
            error={(value === null || value === 0 || value === '0' || value === '' || options.length === 0 || options === null || options === undefined) ? true : false }
            helperText="Kötelező választani"
            value={value === null ? 0 : value}
            disabled={disabled}
            handleChange={this.handleSelectChange}
            menuList={options}
            id="select-scale"
            label="Mérőszám" />
    );
    }
}
export default SelectScale;


