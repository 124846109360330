import React from 'react';
//Components
import { NotificationBar,Progress  } from 'components';
import {Api,handleResponse,handleError} from 'api';

//Actions
const Actions = {
    Save: 'exit/admin/email-groups/add-new/save'
}


export const apiRequest = {
    saveChanges,
};

//#Api calls

async function saveChanges(parent, model) {
    const { onClose,refresh } = parent.props;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            refresh();
            onClose();
        }).catch(handleError);
}

