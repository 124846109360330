//React

import React from 'react';
//Material UI

import {
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    IconButton,
    Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import MuiButton from '../../../../theme/overrides/MuiButton';
//Style

import {
    withStyles,
    makeStyles
} from '@material-ui/core/styles';
import style from './style';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(style)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    help_video: {
        width: '100%', /* 90% of viewport vidth */
        borderRadius: '0.5rem'
    }
});


const HelperVideo = props => {
    const { closeHandler, open } = props;

    const classes = useStyles();

    return (

        <Dialog TransitionComponent={Transition} maxWidth="lg" onClose={closeHandler} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
                Használati segédlet
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.help_video_container}>
                    <video className={classes.help_video} controls>
                        <source src="/videos/TÉR belépés videó.mp4" type="video/mp4" />
                    </video>
                </div>
            </DialogContent>
            <DialogActions>
                <MuiButton color="secondary" value="Vissza" onClick={closeHandler} />
            </DialogActions>
        </Dialog>
    )
}

HelperVideo.propTypes = {
    closeHandler: PropTypes.func,
    open: PropTypes.bool
};

export default HelperVideo;