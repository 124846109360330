//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    GetBios: 'admin/masterData/positions-list',
    SaveChanges: 'admin/masterData/positions-list/save',
    GetFilterBios: 'admin/masterData/filter/positions',
    GetFilterCompanies: 'admin/masterData/filter/companies',
    ValidatePositions :'admin/masterData/positions-list/validate'
}

export const apiRequest = {
    populateBios,
    saveChanges,
    populateFilterBios,
    populateFilterCompanies,
    validatePositions
};

//#Api calls
async function populateBios(parent,page) {
    const { filter } = parent.state;
    const model = {
        filteredBios: filter.bios,
        filteredCompanies: filter.companies,
        active: filter.active !== null ? filter.active.id : filter.active,
        page

    };

    await new Api().call.post(Actions.GetBios, model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                bios: false
            },
            bios: res.biosList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                bios: false
            }
        }));
    });
}

async function populateFilterBios(parent) {

    await new Api().call.get(Actions.GetFilterBios)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                filterBios: false
            },
            filterBios: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                filterBios: false
            }
        }));
    });
}

async function populateFilterCompanies(parent) {

    await new Api().call.get(Actions.GetFilterCompanies)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                filterCompanies: false
            },
            filterCompanies: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                filterCompanies: false
            }
        }));
    });
}


async function saveChanges(parent, model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.SaveChanges, model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            edit:null,
        }));
    }).catch(handleError);
}

async function validatePositions(parent,page, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.ValidatePositions, model)
    .then(handleResponse)
    .then(res => {
        populateBios(parent,page);
    }).catch(handleError);
}

