//React

import React from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Button,
    Typography
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//Style

import theme from 'theme';
import style from './style';

export default function AlertDialog(props) {
    const { open, onClose } = props;

    const classes = style();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">             
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Figyelmeztetés
                    </Typography>
                    <ErrorOutlineIcon style={{ color: theme.palette.white }} />
                </div>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Typography style={{ fontSize:'1.0rem' }}>
                    A létrehozott mátrix nem megengedett. Kérlek módosítsd az értékeket!
                </Typography>                       
            </DialogContent>
            <DialogActions>
                <Button 
                    size="small"
                    onClick={onClose}
                    className={classes.okButton}
                    endIcon={<DoneIcon />}
                >
                    Rendben
                </Button>
            </DialogActions>
        </Dialog>
    );
}
