import theme from 'theme';

const useStyles = ({
    root: {},
    chartContainer: {
      height: 400,
      position: 'relative'
    },
    circularProgress: {
          padding: theme.spacing(4),
          textAlign: 'center'
      },
  });

  export default useStyles;