
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    Progress,
    NotificationBar
} from 'components';
//Helpers

import { UserStore } from 'stores';
//Actions

const Actions = {
    GetUsers: 'matrix/persons',
    Save: 'matrix/save',
    SaveAdmin: 'matrix/admin/save',
    GetReviewers: 'matrix/reviewers'
}

export const apiRequest = {
    populateUsers,
    populateEvaluators,
    saveAction
};

async function populateUsers(parent) {
    var model = {};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                users: res,
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        }).catch(handleError);
}

async function populateEvaluators(parent) {
    const { users } = parent.state;

    const filteredUsers = Array.isArray(users) ? users.filter(item => item.disabled === false) : [];
    var model = { userId: filteredUsers.length > 0 ? filteredUsers[0].id : 0 };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetReviewers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    evaluators: res.reviewers,
                    baseEvaluators: JSON.parse(JSON.stringify(res.reviewers)),
                    selectedMatrix: res.variationMatrixId
                },
                loading: {
                    ...prevState.loading,
                    lazyData: false
                },
                selectedMatrix: res.variationMatrixId
            }));
        }).catch(handleError);
}

async function saveAction(parent, userId) {
    const { evaluators, selectedMatrix } = parent.state;

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var model = { evaluators, selectedMatrixId: selectedMatrix, userId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                isModified: false,
                evaluators: null,
                selectedMatrix: null
            });
        }).catch(handleError);
}