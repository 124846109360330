import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    divider:{
        marginTop:'1rem',
        marginBottom:'1rem'
    },
    help_container:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        margin:'0.5rem'
    },
    help_video_container:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        margin:'1rem'
    },
    help_video:{
        width: '75vw', /* 90% of viewport vidth */
        height: '42.1875vw', /* ratio = 9/16 * 90 = 50.625 */
        maxHeight: '75vh',
        maxWidth: '133vh', /* 16/9 * 90 = 160 */
        margin: 'auto',
        borderRadius:'0.5rem',
        border:'1px solid '+theme.palette.appMain.main,
    }
});


export default useStyles;
