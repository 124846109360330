import React, { Component } from 'react';
//Core
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Tooltip
} from '@material-ui/core';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';

class RangeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: [],
            genValue: [],
        }
        this.initGenValue = this.initGenValue.bind(this);
        this.initValue = this.initValue.bind(this);
    }

    //Lifecycle methods
    componentDidMount() {
        this.initGenValue();        
    }

    //Helper methods
    initValue(genValue) {
        var array = [];
        var sum = 0;
        for (var i = 0; i < genValue.length - 1; i++) {
            sum = sum + genValue[i].value;
            array.push(sum);
        }
        this.setState({
            value: array
        });
    }

    initGenValue() {
        var array = [];
        this.props.competences.map(item => {
            item.competences.map(competence => {
                array.push(competence);
            })
        })
        this.setState({
            genValue: array
        });
        this.initValue(array);
    }

    //Render method
    render() {
        const { value, genValue } = this.state;
        const { subTitle,classes } = this.props;

        return (<div className={classes.root}>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
            {/*<Slider
                disabled={true}
                className={classes.slider}
                value={value}
                aria-labelledby="range-slider"
                track={false}
            /> */}
            <div className={classes.cards}>
                {genValue.map((item, index) => (
                    <Card key={index} style={{ width: item.value + '%' }}>
                        <CardHeader title={<Tooltip title={item.label}><Typography className={classes.cardHeader}>{item.label}</Typography></Tooltip>}>
                        </CardHeader>
                        <CardContent style={{ backgroundColor: item.color, textAlign: 'center' }}>
                            <Typography className={classes.cardContent}>
                                {`${item.value} %`}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>);
    }
}
export default withStyles(style)(RangeSlider);
