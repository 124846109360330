
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetUsers: 'goal/result/persons',
    Save: 'goal/result/save-goal',
    GetGoalTypes: 'goal/result/types',
    GetGoals: 'goal/result/goals',
}

export const apiRequest = {
    populateUsers,
    populateGoalTypes,
    populateGoals,
    saveGoals
};


async function populateUsers(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetUsers,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false,
                },
                users: res
            }));
        }).catch(handleError);

}

async function populateGoalTypes(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoalTypes,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    goalTypes: false,
                },
                goalTypes: res
            }));
        }).catch(handleError);

    
}

async function populateGoals(parent,currentPage, _typeId) {
    const { users } = parent.state;
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = {userId:users[0].id,typeId,currentPage:pageNumber};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await  new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    goals: res,
                    baseGoals: {
                        ...res,
                        goalsForTable: [...res.goalsForTable]
                    },
                },
                loading: {
                    ...prevState.loading,
                    lazyData: false
                }
            }));
        }).catch(handleError);
       

}

async function saveGoals(parent,model) {

      //Info message snack
      NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

     var _model = {goals:model};
     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
         _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

  
        await new Api().call.post(Actions.Save,_model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                isModified: false,
                goals: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                isModified: true
            })
        });              
}

