
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    ForgotPwd: 'gui/forgot-pwd',
}

export const apiRequest = {
    forgotPwd
};

async function forgotPwd(model) {
    return await new Api().call.post(Actions.ForgotPwd, model)
        .then(handleResponse)
        .then(res => {
            return res;
        }).catch(handleError);
}

