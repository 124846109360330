import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
//Core
import { Card, 
CardContent, 
Grid, 
Typography,
Avatar, 
IconButton, 
Tooltip } from '@material-ui/core';
//Icons
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

import style from './style';



function StyledTutorial(props) {
    const classes = style();
    return (<Card
    >
        <CardContent>
            <Grid
                container
                justify="space-between"
            >
                <Grid item>
                    <Typography
                        className={classes.title}
                        color="inherit"
                        gutterBottom
                        variant="body2"
                    >
                        KITÖLTÉSI ÚTMUTATÓ
            </Typography>
                    <Typography
                        color="inherit"
                        className={classes.subContent}
                    >
                        Megtekintéshez kattints az ikonra.
            </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Megtekintés">
                    <RouterLink to="/tutorial">
                        <IconButton>
                            <OndemandVideoIcon className={classes.icon} />
                        </IconButton>
                        </RouterLink>  
                    </Tooltip>
                </Grid>
            </Grid>
        </CardContent>
    </Card>)
}

export default class Tutorial extends Component {

    render() {
        return (<div>
            <StyledTutorial handleOpen={this.props.handleOpen} />
        </div>)
    }
}
