import React, { Component } from 'react';
import clsx from 'clsx';
//Core
import {
    Tooltip,
    IconButton,
    FormControl,
    InputAdornment,
    Input,
    FormHelperText
} from '@material-ui/core';
//Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

//Components
import { MuiOutlinedInput } from 'components';
//Style
import style from './style';

export default function SetRating(props) {

    const { defValue, groupId, compId, isActive, datasAreNotValidToAdd, datasAreNotValidToRemove, handleChange } = props;

    const [value, setValue] = React.useState(defValue);
    const [error, setError] = React.useState(false);
    const [increaseError, setIncreaseError] = React.useState(false);
    const [decreaseError, setDecreaseError] = React.useState(false);

    const handleTextChange = (event) => {
        if ((event.target.value * 1) % 1 != 0) {
            datasAreNotValidToAdd(compId);
            setError(true);
            setValue(event.target.value);
        }
        else {
            if (event.target.value < 101 && event.target.value > -1 && event.target.value!='') {
                setDecreaseError(false);
                setIncreaseError(false);
                if (event.target.value >= 100) {
                    setIncreaseError(true);
                }
                if (event.target.value <= 0) {
                    setDecreaseError(true);
                }
                datasAreNotValidToRemove(compId);
                setError(false);
                setValue(event.target.value);
                handleChange(groupId, compId, event.target.value * 1);
            }
            else {
                setError(true);
                datasAreNotValidToAdd(compId);
                setValue(event.target.value);
            }
          
        }
    }

    const handleIncrease = () => {
        const newValue = (value * 1) + 1;
        if (newValue < 101) {
            setDecreaseError(false);
            setValue(newValue);
            handleChange(groupId, compId, newValue);
            if (newValue===100) {
                setIncreaseError(true);
            }
        }
    }

    const handleDecrease = () => {
        const newValue = (value * 1) - 1;
     
        if (!(newValue < 0)) {
            setIncreaseError(false);
            setValue(newValue);
            handleChange(groupId, compId, newValue);
            if (newValue === 0) {
                setDecreaseError(true);
            }
        }
    }


    const classes = style();
    return (
        <FormControl error={error} className={clsx(classes.margin, classes.formControl)}>
            <Input
                id={`tf-comp-value-${compId}`}
                type="text"
                disabled={!isActive}
                value={isActive ? value : defValue}
                onChange={handleTextChange}
                endAdornment={
                    <InputAdornment position="end">
                        {!isActive || error || increaseError ? <IconButton
                            disabled
                            aria-label="toggle password visibility"
                            onClick={handleIncrease}
                        >
                            <AddCircleOutlineIcon className={!isActive || error || increaseError ? classes.disabledPlusButton : classes.plusButton} />
                        </IconButton> :
                            <Tooltip title="Érték növelése">
                                <IconButton
                                    aria-label="increase"
                                    onClick={handleIncrease}
                                >
                                    <AddCircleOutlineIcon className={!isActive || error || increaseError ? classes.disabledPlusButton : classes.plusButton} />
                                </IconButton>
                            </Tooltip>}
                     

                        {!isActive || error || decreaseError ? <IconButton
                            disabled
                            aria-label="decrease"
                            onClick={handleDecrease}
                        >
                            <RemoveCircleOutlineIcon className={!isActive || error || decreaseError ? classes.disabledminusButton : classes.minusButton} />
                        </IconButton> : <Tooltip title="Érték csökkentése">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleDecrease}
                                >
                                    <RemoveCircleOutlineIcon className={!isActive || error || decreaseError ? classes.disabledminusButton : classes.minusButton} />
                                </IconButton>
                            </Tooltip>}
                       

                    </InputAdornment>
                }
            />
            {error && <FormHelperText>Nem megengedett</FormHelperText>}
        </FormControl>
    );
}

