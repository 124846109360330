//React

import React, { Component } from 'react';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Icons

import SaveIcon from '@material-ui/icons/Save';
import TodayIcon from '@material-ui/icons/Today';
//Core

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


const DateTimePicker = props => {
    const { value, disabled, id, name, error, label, minDate, maxDate, required, readOnly, onChange, helperText } = props;
    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                error={error}
                helperText={helperText}
                clearable={true}
                margin="dense"
                clearLabel="Törlés"
                inputVariant="outlined"
                emptyLabel="Nincs megadva"
                id={id}
                fullWidth
                name={name}
                required={required}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                minDateMessage="Nem megengedett érték"
                maxDate={maxDate}
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider>
    )
}

class HrDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hrs: [],
            loading: {
                hrs: true,
                hr: true
            },
            hr: null
        }
    }

    componentDidMount() {
        apiRequest.populateHrs(this);
        apiRequest.populateData(this);
    }

    handleSelectChange = (props, value) => {
        const { hr } = this.state;
        
        this.setState(prevState => ({
            hr: {
                ...hr,
                [props]: value
            }
        }));
    }

    handleSave = () => {
        apiRequest.saveSettings(this);
    }

    validateFields = (value) => {
        if (value === null || value === undefined || value === '') {
            return false;
        }
        return true;
    }

    handleDateChange = (date, props) => {

        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        }
        this.setState(prevState => ({
            hr: {
                ...prevState.hr,
                [props]: newDate
            }
        }));
    }


    render() {
        const { loading, hrs, hr } = this.state;
        const { classes } = this.props;

        return (
            <Card>
                <form autoComplete="off" noValidate>
                    <CardHeader title="HR adatok" />
                    <Divider />
                    {loading.hr === true &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress color="primary" size={35} />
                        </div>
                    }
                    {loading.hr === false &&
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <Autocomplete
                                        clearText="Alaphelyzet"
                                        noOptionsText="Nincs találat"
                                        closeText="Bezárás"
                                        id="select-hr"
                                        name="hr"
                                        value={(hr !== null && hr !== undefined) ? hr.person : ''}
                                        onChange={(event, value) => this.handleSelectChange("person", value)}
                                        options={hrs}
                                        getOptionLabel={(option) => option.fullName === undefined ? '' : option.fullName}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                variant="outlined"
                                                margin="dense"
                                                label="Hr-es"
                                                InputProps={{
                                                    error: !this.validateFields(hr.person),
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading.hrs ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <DateTimePicker
                                        error={!this.validateFields(hr.dateOfEntry)}
                                        label="Belépés dátuma"
                                        required={true}
                                        value={(hr !== null && hr !== undefined) ? hr.dateOfEntry : ''}
                                        onChange={(date) => this.handleDateChange(date, 'dateOfEntry')}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    }
                    <Divider />
                    {loading.hr === false &&
                        <CardActions>
                            <Button
                                className={classes.saveButton}
                                variant="contained"
                                size="small"
                                disabled={!(this.validateFields(hr.dateOfEntry) && this.validateFields(hr.person))}
                                onClick={this.handleSave}
                                endIcon={<SaveIcon />}
                            >
                                Mentés
                            </Button>
                        </CardActions>
                    }
                </form>
            </Card>
        );
    }
};

export default withStyles(style.useStyles)(HrDetails);
