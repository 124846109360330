//React

import React from 'react';
//Components

import {
    MuiOutlinedInput,
    MuiOutlinedSelect,
    MuiOutlinedDateSelect
} from 'components';
//Helpers

import { enums } from 'stores';

const InputTypes = enums.inputTypes;

function getField(value, selectedScale, handleNumberFieldChange, handleSelectListChange, handleDateChange, handleChange, validateNumberField) {
    if (selectedScale.defExpected != null) {
        switch (selectedScale.inputTypeId) {
            case InputTypes.NUMBERFIELD:
                return <MuiOutlinedInput
                    margin="dense"
                    disabled={true}
                    id="input-expected"
                    type="number"
                    className={(selectedScale.defExpected === null || selectedScale.defExpected < 0) && "goal-new-edit-notvalid"}
                    helperText={(selectedScale.defExpected === null || selectedScale.defExpected < 0) && 'Nem megengedett érték'}
                    error={(selectedScale.defExpected === null || selectedScale.defExpected < 0) ? true : false}
                    endInputAdornment={selectedScale.unit}
                    value={selectedScale.defExpected != null ? selectedScale.defExpected : 0}
                    label="Elvárt"
                    handleChange={handleNumberFieldChange} />;
            case InputTypes.SELECTLIST:
                return <MuiOutlinedSelect
                    margin="dense"
                    disabled={true}
                    className={selectedScale.defExpected === null && "goal-new-edit-notvalid"}
                    error={selectedScale.defExpected === null ? true : false}
                    helperText="Kötelező választani"
                    value={selectedScale.defExpected != null ? selectedScale.defExpected : 0}
                    handleChange={handleSelectListChange}
                    menuList={selectedScale.options}
                    id="select-expected"
                    label="Elvárt" />;
            case InputTypes.DATEFIELD:
                return <MuiOutlinedDateSelect
                    margin="dense"
                    disabled={true}
                    value={selectedScale.defExpected}
                    label="Elvárt"
                    errorClassName="goal-new-edit-notvalid"
                    /*min={new Date("2019-12-31")} 
                     * max={new Date("2019-12-31")}*/
                    handleChange={handleDateChange} />
        }
    }
    switch (selectedScale.inputTypeId) {
        case InputTypes.NUMBERFIELD:
            return <MuiOutlinedInput
                margin="dense"
                id="input-expected"
                type="number"
                className={(value === null || !validateNumberField()) && "goal-new-edit-notvalid"}
                helperText={(value === null || !validateNumberField()) && 'Nem megengedett érték'}
                error={(value === null || !validateNumberField()) ? true : false}
                endInputAdornment={selectedScale.unit}
                value={value != null ? value : ''}
                label="Elvárt"
                handleChange={handleNumberFieldChange} />;
        case InputTypes.SELECTLIST:
            return <MuiOutlinedSelect
                margin="dense"
                className={value === null && "goal-new-edit-notvalid"}
                error={value === null ? true : false}
                helperText="Kötelező választani"
                value={value != null ? value : 0}
                handleChange={handleSelectListChange}
                menuList={selectedScale.options}
                id="select-expected"
                label="Elvárt" />;
        case InputTypes.DATEFIELD:
            return <MuiOutlinedDateSelect
                margin="dense"
                value={value}
                label="Elvárt"
                errorClassName="goal-new-edit-notvalid"
                /*min={new Date("2019-12-31")} 
                 * max={new Date("2019-12-31")}*/
                handleChange={handleDateChange} />
    }
}

export default function SetExpected(props) {
    const { handleChange, value, selectedScale } = props;

    const handleNumberFieldChange = (event) => {
        const value = event.target.value;
        handleChange('edit', value);
    }

    const validateNumberField = () => {
        const parsedValue = parseInt(value);
        let valid = false;
        if (!isNaN(parsedValue)) {
            valid = true;
        }
        return valid;
    }

    const handleSelectListChange = (event) => {
        handleChange('edit', event.target.value);
    };

    const handleDateChange = (date) => {
        var month = date.getMonth() + 1; //months from 1-12
        var year = date.getFullYear();
        var newdate = year + "-" + month;
        handleChange('edit', newdate);
    }

    return (getField(value, selectedScale, handleNumberFieldChange, handleSelectListChange, handleDateChange, handleChange, validateNumberField));
}
