//React

import React from 'react';
//Core

import {
  Divider,
  IconButton,
  Typography, 
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Paper
} from '@material-ui/core';
//Style

import style from './style';
//Icons

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const {p_left, p_right, p_right_2, p_right_3, rightQuantity, right_2Quantity, right_3Quantity, setModel, period} = props;

  const classes = style();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(p_left);
  const [right, setRight] = React.useState(p_right);
  const [right_2, setRight_2] = React.useState(p_right_2);
  const [right_3, setRight_3] = React.useState(p_right_3);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const rightChecked_2 = intersection(checked, right_2);
  const rightChecked_3 = intersection(checked, right_3);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  React.useEffect(() => {
    const model = {general:right,key:right_2,leader:right_3};
    let isValid =false;
    if(!(rightQuantity!==right.length || right_2Quantity!==right_2.length || (right_3.length!==0 && right_3Quantity!==right_3.length))){
      isValid=true;
    }
    setModel(model,isValid);
  },[right,right_2,right_3]);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedRight_2 = () => {
    setRight_2(right_2.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedRight_3 = () => {
    setRight_3(right_3.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleCheckedLeft_2 = () => {
    setLeft(left.concat(rightChecked_2));
    setRight_2(not(right_2, rightChecked_2));
    setChecked(not(checked, rightChecked_2));
  };

  const handleCheckedLeft_3 = () => {
    setLeft(left.concat(rightChecked_3));
    setRight_3(not(right_3, rightChecked_3));
    setChecked(not(checked, rightChecked_3));
  };

  const customList = (items,label,disabled) => (
    <Paper className={classes.paper}>
        <span style={{padding:'0.5rem',fontWeight:'bold'}}>{label}</span>
        <Divider/>
        <List dense component="div" role="list">
          {items.map((item) => {
            const labelId = `transfer-list-item-${item.id}-label`;

            return (
              <ListItem disabled={disabled} key={item.id} role="listitem" button onClick={handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disabled={disabled}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.label} />
              </ListItem>
            );
          })
        }
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid style={{paddingTop:'3.0rem'}} item>
        {customList(left,"Választható",period.disabled)}
      </Grid>
      <Grid item>
        <Typography className={classes.header}>
          {`Megengedett:${rightQuantity}/${right.length}`}
        </Typography>
        <Grid container direction="row" alignItems="center">           
          <IconButton
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ArrowDropDownIcon/>
          </IconButton>
          <IconButton
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ArrowDropUpIcon/>
          </IconButton>
        </Grid>
        {customList(right,'Általános',period.disabled)}
      </Grid>
      <Grid item>
        <Typography className={classes.header}>
          {`Megengedett:${right_2Quantity}/${right_2.length}`}
        </Typography>
        <Grid container direction="row" alignItems="center">
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedRight_2}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ArrowDropDownIcon/>
          </IconButton>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft_2}
            disabled={rightChecked_2.length === 0}
            aria-label="move selected left"
          >
            <ArrowDropUpIcon/>
          </IconButton>
        </Grid>
        {customList(right_2,'Kulcs',period.disabled)}
      </Grid>
      <Grid item>
        <Typography className={classes.header}>
          {`Megengedett:${right_3Quantity}/${right_3.length}`}
        </Typography>
        <Grid container direction="row" alignItems="center">
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedRight_3}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ArrowDropDownIcon/>
          </IconButton>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft_3}
            disabled={rightChecked_3.length === 0}
            aria-label="move selected left"
          >
            <ArrowDropUpIcon/>
          </IconButton>
        </Grid>
        {customList(right_3,'Vezetői',period.disabled)}
      </Grid>
    </Grid>
  );
}