import  theme  from 'theme';

const useStyles = () => ({

  

    table: {
        marginTop: theme.spacing(2)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    divider: {
        marginBottom: theme.spacing(2),
    },  
    select: {
        paddingBottom: theme.spacing(2)
    }
});

export default useStyles;
