import React from 'react';
//Core
import {
    Button,
    Dialog,
    IconButton,
    Grid,
    Avatar,
    Tooltip
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';

const DialogContent = style.DialogContent;
const DialogActions = style.DialogActions;

const DialogTitle = withStyles(style.styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function CustomizedDialogs(props) {
    const { open, handleOpen, handleClose, data } = props;
    const classes = style.useStyles();

    return (
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography className={classes.title}>{data.fullName}</Typography>
                
        </DialogTitle>
                <DialogContent dividers>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item className={classes.userDatas}>
                        <Typography
                            color="inherit"
                            className={classes.userBio}
                        >
                          <b> Beosztás: </b> {data.bio}
                        </Typography>
                        <Typography
                            color="inherit"
                            className={classes.subContent}
                        >
                            <b> Szervezet: </b> {data.org}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={data.fullName}>
                            <IconButton>
                                <Avatar
                                    alt="Vezető"
                                    className={classes.avatar}
                                    src={(data.avatar !== null && data.avatar !== undefined) ? data.avatar : ''}
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Vissza
                    </Button>
             </DialogActions>
            </Dialog>
    );
}
