//React

import React,{ Component } from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    DialogTitle,
    Divider,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
    FormHelperText,
    Tooltip,
    IconButton
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/styles';


class ConfirmDialog extends Component {

    constructor(props){
        super(props);
        this.state={
            label:'',
            allowQuantity:'',
            input:'',
            inputMatrix:'',
            unit:'',
            canSkipResult:null,
            isWeighted:null,
            weightSum:'',
            valid:false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {label, allowQuantity, input, inputMatrix, unit, canSkipResult, isWeighted, weightSum} = this.state;

        if (prevState.label != label || prevState.allowQuantity!= allowQuantity || prevState.input!= input
            || prevState.inputMatrix!= inputMatrix || prevState.unit!= unit || prevState.canSkipResult!= canSkipResult
            || prevState.isWeighted!= isWeighted  || prevState.weightSum!= weightSum) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]:value
        });
    }

    handleSave = () =>{
        const {label, allowQuantity, input, inputMatrix, unit, canSkipResult, isWeighted, weightSum} = this.state;
        const {period, parentId} = this.props;

        const model = {
            parent:parentId,
            period:period.id,
            label,
            allowQuantity:allowQuantity ==='' ? null : allowQuantity * 1, 
            input:input === '' ? null : input,
            inputMatrix:inputMatrix === '' ? null : inputMatrix,
            unit:unit === '' ? null : unit,
            canSkipResult : canSkipResult === '' ? null : canSkipResult, 
            isWeighted : isWeighted === '' ? null : isWeighted,
            weightSum:weightSum === '' ? null : weightSum * 1,                            
        }
        apiRequest.saveChanges(this,model);
    }

    validateFields = (value) =>{
        if(value === null || value === undefined || value === ''){
            return false;
        }
        return true;
    }

    render(){
        const {open, onClose, classes, inputs, level, inputTypes} = this.props;
        const {label, input, allowQuantity, canSkipResult, isWeighted, weightSum, unit, valid, inputMatrix} = this.state;

        return(
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új pont hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider/>
                <DialogContent id="add-new-form">
                {level === 0 &&
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                required
                                error={!this.validateFields(label)}
                                helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                label="Megnevezés"
                                fullWidth
                                value={label}
                                name="label"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                required
                                error={!this.validateFields(allowQuantity)}
                                helperText={!this.validateFields(allowQuantity) && 'Kötelező megadni'}
                                label="Max darabszám"
                                type="number"
                                fullWidth
                                value={allowQuantity}
                                name="allowQuantity"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField
                                required
                                error={!this.validateFields(weightSum)}
                                helperText={!this.validateFields(weightSum) && 'Kötelező megadni'}
                                label="Súly összeg"
                                fullWidth
                                type="number"
                                value={weightSum}
                                name="weightSum"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                        <FormControl 
                                fullWidth 
                                required 
                                error={!this.validateFields(isWeighted)}  
                                className={classes.formControl}
                            >
                            <InputLabel id="isWeighted-select-label">
                                    Súlyozott
                            </InputLabel>
                                <Select
                                    name="isWeighted"
                                    labelId="isWeighted-select-label"
                                    id="isWeighted-select"
                                    value={isWeighted===null ? '' : isWeighted}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value={true}>Igen</MenuItem>
                                    <MenuItem value={false}>Nem</MenuItem>
                                </Select>
                                {!this.validateFields(isWeighted) && 
                                <FormHelperText>Kötelező megadni</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormControl 
                                fullWidth 
                                required 
                                error={!this.validateFields(canSkipResult)}  
                                className={classes.formControl}
                            >
                            <InputLabel id="skip-select-label">
                                Kihagyható
                            </InputLabel>
                                <Select
                                    name="canSkipResult"
                                    labelId="skip-select-label"
                                    id="skip-select"
                                    value={canSkipResult===null ? '' : canSkipResult}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value={true}>Igen</MenuItem>
                                    <MenuItem value={false}>Nem</MenuItem>
                                </Select>
                                {!this.validateFields(canSkipResult) && 
                                <FormHelperText>Kötelező megadni</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                {level > 0 && 
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                required
                                error={!this.validateFields(label)}
                                helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                label="Megnevezés"
                                fullWidth
                                value={label}
                                name="label"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item>
                            <FormControl 
                                fullWidth 
                                required 
                                error={!this.validateFields(input)}  
                                className={classes.formControl}
                            >
                            <InputLabel id="input-select-label">
                                Bemenet
                            </InputLabel>
                                <Select
                                    name="input"
                                    labelId="input-select-label"
                                    id="input-select"
                                    value={input}
                                    onChange={this.handleChange}
                                >
                                    {inputTypes.map((item,index)=>(
                                        <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                                {!this.validateFields(input) && 
                                <FormHelperText>Kötelező megadni</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item>
                            <FormControl 
                                fullWidth 
                                className={classes.formControl}
                            >
                            <InputLabel id="inputMatrix-select-label">
                                Bemeneti értékek
                            </InputLabel>
                                <Select
                                    name="inputMatrix"
                                    labelId="inputMatrix-select-label"
                                    id="inputMatrix-select"
                                    value={inputMatrix}
                                    onChange={this.handleChange}
                                >
                                    {inputs.map((item,index)=>(
                                        <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                label="Mértékegység"
                                fullWidth
                                value={unit}
                                name="unit"
                                onChange={this.handleChange}
                            />
                        </Grid>                          
                    </Grid>
                }           
                </DialogContent>
                <DialogActions>
                    <div>
                        <Tooltip title="Mentés">
                        <span>
                            <IconButton
                                disabled={!valid} 
                                onClick={this.handleSave} 
                                className={classes.doneButton} 
                                aria-label="done"
                            >
                                <DoneIcon />
                            </IconButton>
                        </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>         
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(style)(ConfirmDialog);
