import theme from 'theme';
import React from 'react';
//Core
import {
    TableRow
  } from '@material-ui/core';
//Styles
import { makeStyles,withStyles } from '@material-ui/styles';

const useStyles = ({
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            color:theme.palette.success.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.dark}`
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    },
    cancelButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
});

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '0.8rem',

        },
    },
    head: {
        '& > *': {
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)

export default {
    StyledTableRow,
    useStyles
};
