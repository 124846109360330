import theme from 'theme';

const useStyles = () => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
    },
    subTitle: {
        fontWeight: 'bold',
        fontSize: '1.0rem',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    lockIcon: {
        color: theme.palette.warning.main
    },
    disabledUsersIcon: {
        color: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.dark,
        }
    },
    menuHeader: {
        fontWeight: 'bold',
        fontSize: '1.0rem'
    }, 
    paper: {
        backgroundColor: theme.palette.secondary.main
    }
});

export default useStyles;
