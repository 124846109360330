//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Button
} from '@material-ui/core';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Icons

import MailOutlineIcon from '@material-ui/icons/MailOutline';

const StyledTableRow = style.StyledTableRow;

const UsersTable = props => {
  const { className, users, onEmail, ...rest } = props;
  const classes = style.useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        >
                        </Avatar>
                        <Typography variant="body1">{user.fullName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {user.bio}
                    </TableCell>
                    <TableCell>
                      {user.org}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant='contained'
                        endIcon={<MailOutlineIcon />}
                        onClick={()=>onEmail(user.id)}
                      >
                        Üzenet kiküldése
                      </Button>
                    </TableCell>
                  </StyledTableRow>))
                }
                {users.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="9">
                      Nincs találat
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
