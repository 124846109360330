import theme from 'theme';

const useStyles = ({

    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        },
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    actions: {
        padding: theme.spacing(1, 1),
        display: 'flex',
        justifyContent: 'center',
    },
    userPanel:{
        top:'auto',
        bottom: 0,
        backgroundColor:theme.palette.appMain.main
    },
    adminPanel:{
        top:'auto',
        bottom: 0,
        backgroundColor:theme.palette.warning.light
    },
    buttons: {
        fontSize: '0.6rem',
        width:'70%',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            backgroundColor: theme.palette.appMain.dark,
        },
    },
    usersIcon:{
        color:'white'
    }
});

export default useStyles;
