import React, { useState } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core
import {
    IconButton,
    InputAdornment,
} from '@material-ui/core';
//Icons
import TodayIcon from '@material-ui/icons/Today';
//Pickers
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
//Style
import style from './style';

export default function DateSelect(props) {
    const { goal, goalId, handleGoalsChange, readOnly, disabled, defValue } = props;

    var mappedValue = defValue;
    if (defValue != null && defValue != undefined && defValue != '') {
        const splittedValue = defValue?.split('-');
        if (splittedValue?.length > 1) {
            if (splittedValue[1].length === 1) {
                mappedValue = splittedValue[0] + "-0" + splittedValue[1]
            }
        }
    }

    const [selectedDate, setSelectedDate] = useState((defValue !== null && defValue != undefined && defValue !== 'nem teljesült') ? new Date(mappedValue) : null);
    const handleDateChange = (date) => {
        var newdate = null;
        var mappedNewDate = null;
        if (date !== null && date !== undefined) {
            var month = date.getMonth() + 1; //months from 1-12
            var year = date.getFullYear();
            newdate = year + "-" + month;
            mappedNewDate = year + "-" + (month < 10 ? ("0" + month) : month);
        }
        setSelectedDate(mappedNewDate);
        handleGoalsChange(goal, newdate);
    }
    const classes = style();
    const label = selectedDate === null ? "Nem teljesült" : 'Teljesítve';

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                //error={(selectedDate === null || selectedDate === undefined) ? true : false}
                //helperText={(selectedDate === null || selectedDate === undefined) && "Kötelező választani"}
                inputVariant="outlined"
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="year"
                margin="dense"
                clearable
                clearLabel="Törlés"
                label={label}
                format="yyyy MMMM"
                disabled={disabled}
                readOnly={readOnly}
                /* minDate={new Date("2019-01-01")}
                 maxDate={new Date("2020-12-31")}*/
                views={["year", "month"]}
                autoOk
                value={selectedDate}
                onChange={date => handleDateChange(date)}
            />
        </MuiPickersUtilsProvider>

    );
}

