import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    header: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            lineHeight: '1.6'
        },
        
    },
    typography: {
        fontFamily: 'Nunito',
        fontSize: '1.3rem',
        lineHeight:'1.6'
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        paddingBottom: theme.spacing(2)
    }
}));
