//React
import React from 'react';

//Core
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent, 
  Typography,
  IconButton,
  Divider,
  Paper
 } from '@material-ui/core';
  import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Styles
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';
//Icons
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { apiRequest } from './apiRequest';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor:theme.palette.appMain.main
  },
  title: {
    fontFamily:'nunito',
    color:'white',
    fontWeight:'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



function NoteDialog(props) {
  const {onClose,open,selected,readOnly} = props;
  const classes = style.useStyles();

  const [note,setNote] = React.useState('');
  const [createdBy,setCreatedBy] = React.useState('');
  const [modifiedBy,setModifiedBy] = React.useState('');

  React.useEffect(()=>{
    apiRequest.populateNote(selected.id,setNote,setCreatedBy,setModifiedBy);
  },[])

  function validate(){
    if(note==='' || note===undefined || note===null){
        return false;
    }
    return true;
  }

  function handleSave(){
    const model={note,rowId:selected.id};
    apiRequest.save(model,onClose);
  }

  return (
    <div>
      <Dialog 
        fullWidth={true}
        maxWidth={"lg"} 
        open={open} 
        onClose={onClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={onClose}>Megjegyzés</DialogTitle>
        <DialogContent>
         <Paper style={{padding:'0.5rem'}}>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Név:</Typography>
                <Typography>{selected.fullName} </Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Pozíció:</Typography>
                <Typography> {selected.bio}</Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Szervezeti egység:</Typography>
                <Typography> {selected.organization}</Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Közvetlen felettes:</Typography>
                <Typography>{(selected.leader!=null && selected.leader!=undefined) && selected.leader.fullName}</Typography>
            </div>
            </Paper>
            <Divider/>
            <Paper style={{padding:'0.5rem',marginTop:'0.5rem'}}>
                <Typography style={{fontSize:'1.2rem',textAlign:'center', color:theme.palette.primary.main,padding:'1.0rem'}}>MEGJEGYZÉS</Typography>
                <TextField value={note} onChange={(event)=>setNote(event.target.value)} multiline={true} rows={6} fullWidth variant="outlined"/>
              {(createdBy!==null && createdBy!=='' && createdBy!==undefined) &&  
                <div style={{display:'flex',gap:'0.5rem'}}>
                  <Typography style={{fontWeight:'bold'}}>Megjegyzést létrehozta:</Typography>
                  <Typography>{createdBy}</Typography>
                </div>}
                {(modifiedBy!==null && modifiedBy!=='' && modifiedBy!==undefined) &&    
                <div style={{display:'flex',gap:'0.5rem'}}>
                  <Typography style={{fontWeight:'bold'}}>Utolsó módosító:</Typography>
                  <Typography>{modifiedBy}</Typography>
                </div>}
            </Paper>
        </DialogContent>
        <DialogActions>
          <Button endIcon={<BackspaceIcon/>} className={classes.secondaryButton} size="small" onClick={onClose} variant="outlined" color="secondary">
            Vissza
          </Button>
          <Button disabled={!validate()} endIcon={<SaveIcon/>} className={classes.mainButton} size="small" onClick={handleSave} variant="outlined">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NoteDialog;