import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Core
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TextField
} from '@material-ui/core';
//Icons
import FilterListIcon from '@material-ui/icons/FilterList';
//Components
import Autocomplete from '@material-ui/lab/Autocomplete';
//Styles
import style from './style';



const UsersToolbar = props => {
    const { className,onValidation, users, onChange, filter, ...rest } = props;
    const classes = style();
    const [width, setWidth] = React.useState(classes.root);

    const handleExit = () => {
        setWidth(classes.root);
    }
    const handleEnter = () => {
        setWidth(classes.rootFull);
    }

  return (
    <div
      {...rest}
          className={clsx(className)}
      >         
          <div className={width}>
              <ExpansionPanel 
                TransitionProps={{ onExited: handleExit, onEnter: handleEnter }}>
                  <ExpansionPanelSummary
                      expandIcon={<FilterListIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                      <Typography className={classes.heading}>Szűrők</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                      <Grid container>
                          <Grid item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                          >
                              <Grid container spacing={1}>
                                  <Grid item
                                      xl={3}
                                      lg={3}
                                      md={3}
                                      sm={12}
                                      xs={12}
                                  >
                                      <Autocomplete
                                          multiple
                                          clearText="Alaphelyzet"
                                          noOptionsText="Nincs találat"
                                          closeText="Bezárás"
                                          id="filter-user"
                                          name="users"
                                          value={filter.users}
                                          onChange={(event, value) => onChange("users", value)}
                                          options={users}
                                          getOptionLabel={(option) => option.label}
                                          filterSelectedOptions
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  variant="outlined"
                                                  label="Felhasználó"
                                              />
                                          )}
                                      />

                                  </Grid>                                                                                                                                  
                             </Grid>
                          </Grid>
                      </Grid>
                  </ExpansionPanelDetails>
              </ExpansionPanel>
              </div>             
          </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
