//React

import React, { Component } from 'react';
//Core

import { Grid } from '@material-ui/core';
//Components

import { 
    ClaimsPagination, 
    ClaimsTable, 
    ClaimsToolbar 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';


class Claims extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            claims: [],
            loading: {
              claims: true,
              users:true
            },
            filter: {
                users: [],
                roles:[]
            },
            selectedFilter:{
                users:[],
                ertekelhet:null,
                ertekelheto:null
            },
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null
        }

    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateClaims(this, this.state.page);
        apiRequest.populateUserFilters(this);
    }
    //#EndLifecycle methods


    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                claims: true
            },
            selectedFilter: {
                ...prevState.selectedFilter,
                [props]: value
            }
        }), () => apiRequest.populateClaims(this,1));
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                claims: true
            }
        }));
        apiRequest.populateClaims(this, nextPage);
    }

    refresh = () =>{
        const {page} = this.state;

        apiRequest.populateClaims(this, page);
        apiRequest.populateUserFilters(this);
        
    }

    handleClick = (claimId,value,claim) =>{
        const model = {
            claimId,
            value,
            userId:claim.userId
        };
        apiRequest.saveEdit(this, model);
    }
   

    render() {
        const {claims, filter, currentPage, step, total, selectedFilter, pages, loading} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid xs={false} sm={false} md={false} lg={2} xl={2} item/>
                    <Grid xs={12} sm={12} md={12} lg={8} xl={8} item>
                         {(loading.claims === false) && <ClaimsToolbar
                            onChange={this.handleFilterChanges} 
                            filter={filter}
                            selectedFilter={selectedFilter}  
                            loading={loading} 
                        />}
                        <div className={classes.content}>
                                <ClaimsTable
                                    loading={loading.claims}
                                    onClick={this.handleClick} 
                                    claims={claims}
                                />
                        </div>
                        {(loading.claims === false) && 
                            <ClaimsPagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                    <Grid xs={false} sm={false} md={false} lg={2} xl={2} item/>
                </Grid>
            </div>
        );
    }
};

export default withStyles(style)(Claims);
