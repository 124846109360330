//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    Typography,
    Divider
} from '@material-ui/core';
//Components

import { enums } from 'stores';
//Style

import style from './style';


const InputTypes = enums.inputTypes;

export default function Element(props) {
    const { data } = props;

    const classes = style.useStyles();

    return (
        <div className={classes.root}>
            {data.map((item,index) => (
                (item.inputType != null && item.inputType.id !== InputTypes.TEXTFIELD) &&
                <React.Fragment key={index}>
                    <Grid container spacing={0}>
                        <Grid item className={classes.center} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <Typography  className={classes.label}>{item.label}</Typography>                     
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            {/*RatingLine with subpoints */}
                            {item.subElements.length != 0 && item.subElements.map((subElement, index) => (
                                subElement.inputType.id === InputTypes.RATINGLINE && 
                                <React.Fragment key={index}>
                                    <Grid container>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Typography className={classes.label}>{subElement.label}</Typography>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={classes.flex}>
                                                <Typography className={classes.label} style={{ fontWeight:'bold' }}>{subElement.allValues}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={classes.flex}>
                                                <Typography className={classes.label} style={{ fontWeight:'bold' }}>{subElement.value}</Typography>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>-</Typography>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>
                                                    {subElement.inputType.inputValues.filter(item => item.value == subElement.roundedValue).map(row => { return row.label })}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>                                                                                                 
                                    {((item.subElements.length - 1) > index) && <Divider className={classes.divider} />}
                                </React.Fragment>
                            ))}
                            {/*Alone RatingLine without subpoints */}
                            {(item.inputType != null && item.inputType.id === InputTypes.RATINGLINE && item.subElements.length === 0) && 
                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={classes.flex}>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>{item.allValues}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={classes.flex}>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>{item.value}</Typography>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>-</Typography>
                                                <Typography className={classes.label} style={{ fontWeight: 'bold' }}>
                                                    {item.inputType.inputValues.filter(filterItem => filterItem.value == item.roundedValue).map(row => { return row.label })}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                            {/*SelectList*/}
                            {(item.inputType != null && item.inputType.id === 3 && item.inputType.inputValues.length != 0) &&
                                <React.Fragment>
                                    <Grid container>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Typography className={classes.label} style={{ fontWeight: 'bold' }}>
                                                {item.inputType.inputValues.filter(filterItem => filterItem.value == item.value).map(row => { return row.label })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                            {/*TextField*/}
                            {(item.inputType != null && item.inputType.id === InputTypes.TEXTFIELD) && null}
                        </Grid>                  
                    </Grid>
                    <Divider className={classes.divider} />
                </React.Fragment>                
            ))}
        </div>
    )
}
