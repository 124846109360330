
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    Save: 'rating/leader-rating/save',
    SaveAdmin: 'rating/admin/leader-rating/save',
    GetLeaderRatingPoints: 'rating/leader-rating',
    GetLeader:  'rating/leader-rating/leader'
}

export const apiRequest = {
    populateLeaderRatingPoints,
    handleSaveChanges,
    populateLeader
};

async function populateLeaderRatingPoints(parent) {
    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
    await new Api().call.post(Actions.GetLeaderRatingPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            motivationPoints: res,
            baseMotivationPoints: JSON.parse(JSON.stringify(res)),
            loading: false,
        })
    }).catch(handleError);

}

async function handleSaveChanges(parent,closed) {

    parent.setState({
        isModified: false
    });

    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    const { motivationPoints } = parent.state;
    const postObjectArray = [];
    motivationPoints.map(main => {
        main.subLeaderRatings.map(sub => {
            sub.elements.map(element => {
                if (element.value != null) {
                    postObjectArray.push({ id: element.id, value:  String(element.value) })
                } else {
                    element.subElements.map(subElement => {
                        postObjectArray.push({ id: subElement.id, value: String(subElement.value) })
                    })
                }

            })
        })
    });

    var model = { minimalizedLeaderRatingPoints: postObjectArray }
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model, loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.Save,model)
        .then(handleResponse)
        .then(res => {
            if (!closed) {
                parent.setState({
                    baseMotivationPoints: res.baseData,
                    isModified: false
                });
            }
        }).catch(error => {
            handleError(error);
            parent.setState({
                isModified: true
            });
        });

}

async function populateLeader(parent) {

    const loggedBy = UserStore.getLoggedByAdminValue();
    var request = null;
    if(loggedBy!=null){
       let model = {period:loggedBy.period.id,user:loggedBy.user.userId};
       request =  new Api().call.post(Actions.GetLeader,model);
    }else{
        request = new Api().call.get(Actions.GetLeader);
    }
        await request
        .then(handleResponse)
        .then(res => {
                parent.setState(prevState => ({
                    leader: res
                }));
            }).catch(handleError);
}