
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar 
} from 'components';
//Helpers

import { UserStore } from 'stores';

//Actions
const Actions = {
    GetConversation: 'ratingForm/conversation/worker',
    SaveConversation: 'ratingForm/conversation/save',
}

export const apiRequest = {
    handleSaveChanges,
    populateConversation
};

async function populateConversation(parent) {
    const { period } = parent.props;
    await new Api().call.get(`${Actions.GetConversation}/${period}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: false,
            conversation: res
        }));
    }).catch(handleError);

}

async function handleSaveChanges(parent) {
    const { period } = parent.props;

    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var model = {isDone: true,period };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.SaveConversation, model)
    .then(handleResponse)
    .then(res => {

    }).catch(handleError);

}