//React

import React from 'react';
import { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { VariationTree } from './components';
//Style

import { withStyles } from '@material-ui/styles';
import style from './style';


class Variations extends Component{

    render(){
        const {classes} = this.props;

        return(
            <div className={classes.root}>
                <Typography className={classes.title}>Variációk</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VariationTree/>
                    </Grid>                    
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(Variations);