import React from 'react';
import {Api,handleResponse,handleError} from 'api';
//Components
import { NotificationBar,Progress  } from 'components';
//Actions
const Actions = {
    GetEmailGroups:'exit/admin/email-groups',
    GetEmailGroupsFilters:'exit/admin/filters/email-groups',
    SaveEdit:'exit/admin/email-groups/edit/save',
}


export const apiRequest = {
    populateEmailGroups,
    populateEmailGroupsFilters,
    saveEdit,
};

async function populateEmailGroups(parent,page) {

    const { selectedFilter } = parent.state;
    const model = {
        filteredEmailGroups: selectedFilter.emailGroups,
        active: (selectedFilter.active!==null && selectedFilter.active!==undefined) ? selectedFilter.active.id : null,
        page
    };
    await new Api().call.post(Actions.GetEmailGroups,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                emailGroups:false
            },
            emailGroups: res.emailGroupList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function populateEmailGroupsFilters(parent) {

    await new Api().call.get(Actions.GetEmailGroupsFilters)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                f_emailGroups:false
            },
            filter: {
                ...prevState.filter,
                emailGroups: res
            }
        }));
    }).catch(handleError);
}

async function saveEdit(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.SaveEdit,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        parent.refresh();
    }).catch(handleError);
}

