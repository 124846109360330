//React

import React, { Component } from 'react';
import { Prompt  } from 'react-router';
//Core

import {
    Grid,
    Typography,
    Divider,
    Button,
    Card,
    CardContent,
    CardHeader,
    Box,
    Icon,
    Tooltip
} from '@material-ui/core';
//Components

import {  SaveDialog } from 'components';
import { 
    Element, 
    LeaderBox
} from './components';
//Helpers

import {
    Constants,
    UserStore
} from 'stores';
//Api

import {apiRequest} from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


class LeaderRating extends Component {
  
    constructor(props) {
        super(props);

            Constants.setcontentLoading(true);

        this.state = {
            motivationPoints: [],
            baseMotivationPoints: [],
            isModified: false,
            loading: true,
            autoSave: UserStore.getUserValue().autoSave,
            open: false,
            nextLocation:null,
            leader:null,
        }
    }

     //#Lifecycle methods
     componentDidMount(){
        apiRequest.populateLeaderRatingPoints(this);
        apiRequest.populateLeader(this);
     }

    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;

        if (prevState.loading != loading) {
            if (loading === false) {
                Constants.setcontentLoading(false);
            }
        }
    }

    componentWillUnmount() {
        const { isModified, autoSave} = this.state;
        if (isModified === true && autoSave===true) {
            apiRequest.handleSaveChanges(this,true);
        } 
    }
     //#EndLifecycle methods

    handleClose = () => {
        this.setState({
            open: false,
            nextLocation:null
        });
    }

    handleMotivationPoints = (itemId,newValue) => {
        const { motivationPoints } = this.state;
        
        var tempMotivationPoints = []

        motivationPoints.map(main => {
            main.subLeaderRatings.map(sub => {
                sub.elements.map(element => {
                    if (element.id === itemId) {
                        element.value = newValue;
                    }
                    element.subElements.map(subElement => {
                        if (subElement.id === itemId) {
                            subElement.value = newValue;
                        }
                    })
                })
            })
            tempMotivationPoints.push(main);
        });

        this.setState({
            motivationPoints: tempMotivationPoints
        });
        this.checkIsModified(); 
    }

    checkIsModified = () => {
        const { motivationPoints, baseMotivationPoints } = this.state;

        var hashMotivationPoints = '';
        var hashBaseMotivationPoints = '';
        var motivationPointsObject = [];
        var baseMotivationPointsObject = [];

        motivationPoints.map(main => {
            main.subLeaderRatings.map(sub => {
                sub.elements.map(element => {
                    if (element.value!=null) {
                        motivationPointsObject.push({ id: element.id, value: element.value })
                    } else {
                        element.subElements.map(subElement => {
                            motivationPointsObject.push({ id: subElement.id, value: subElement.value })
                        })
                    }
                   
                })
            })
        });

        baseMotivationPoints.map(main => {
            main.subLeaderRatings.map(sub => {
                sub.elements.map(element => {
                    if (element.value != null) {
                        baseMotivationPointsObject.push({ id: element.id, value: element.value })
                    } else {
                        element.subElements.map(subElement => {
                            baseMotivationPointsObject.push({ id: subElement.id, value: subElement.value })
                        })
                    }

                })
            })
        });

        motivationPointsObject.sort(this.compare).map((motivationPoint) => {
            hashMotivationPoints = hashMotivationPoints + motivationPoint.id;
            hashMotivationPoints = hashMotivationPoints + motivationPoint.value;
        });

        baseMotivationPointsObject.sort(this.compare).map((motivationPoint) => {
            hashBaseMotivationPoints = hashBaseMotivationPoints + motivationPoint.id;
            hashBaseMotivationPoints = hashBaseMotivationPoints + motivationPoint.value;
        });

        if (hashMotivationPoints != hashBaseMotivationPoints) {
            this.setState({
                isModified: true
            });
        }
        else {
            this.setState({
                isModified: false
            });
        }
    }

    handleAlertSave = () => {
        const { history } = this.props

        this.setState({
            open: false,
            isModified:false,
        }, () => {
                const promise = apiRequest.handleSaveChanges(this,true);
                promise.then(()=>{
                    history.push(this.state.nextLocation.pathname);
                }).catch(error => { });
        });
    }

    handleMoveNext = () =>{
        const { history } = this.props
        const { nextLocation } = this.state;

        this.setState({
            open: false,
            isModified:false,
        }, () => {
            history.push(nextLocation.pathname);
        });    
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            open: true,
            nextLocation: nextLocation
        });
        return false
    }

    render() {
        const { motivationPoints, autoSave, isModified, loading, open, leader } = this.state;
        const { classes, realIsActive } = this.props;

        return (loading === false && 
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        <div className={classes.headerDiv}>
                            <Typography className={classes.title}>
                                Vezetői értékelés
                            </Typography>
                            {!realIsActive && 
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>                               
                                </Tooltip>
                            } 
                            <div className={classes.flexGrow} />                  
                        </div>  
                        {leader!==null && <LeaderBox leader={leader} />}              
                        <Card>
                            <CardContent>
                                <Typography className={classes.typography}>
                                    Ezekről a válaszokról a felettesed csak egy összesített eredményt kap, ami az összes hozzá tartozó közvetlen beosztott értékelése alapján kalkulálódik. 
                                    <br></br><br></br>
                                    A részletes eredményeket csak a HR admin jogosultsággal rendelkező kollégái láthatják, és teljes diszkrécióval kezelik.
                                </Typography>
                            </CardContent>
                            <Divider />
                        </Card>
                        <Box marginTop="2rem">
                            <Grid container spacing={2}>
                                <Grid item md={1} sm={false} xs={false}/>
                                <Grid item md={10} sm={12} xs={12}>
                                    {isModified === true &&
                                    <div style={{textAlign:'right'}}>
                                        <Button
                                            size="small"
                                            className={classes.saveButton}
                                            onClick={()=>apiRequest.handleSaveChanges(this,false)}
                                            endIcon={ <DoneIcon />}
                                        >
                                            Mentés
                                        </Button>
                                    </div>
                                    }
                                </Grid>
                                <Grid item  md={1} sm={false} xs={false}/>
                            </Grid>
                        </Box>                
                        <Grid className={classes.subRoot} container spacing={2}>
                            <Grid item xl={1} lg={1} md={false} sm={false} xs={false}/>
                            <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                                {motivationPoints.map((item, index) => (
                                    <Card key={index}>
                                        <CardHeader className={classes.headerTitle} title={item.title}/>
                                        <CardContent>
                                            {item.subLeaderRatings.map((subLeaderRating, index) => (
                                                <Element key={index} handleMotivationPoints={this.handleMotivationPoints} data={subLeaderRating.elements} />                                                        
                                            ))}
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                            <Grid item xl={1} lg={1} sm={false} md={false} xs={false}/>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
                <Prompt
                    when={autoSave === false && isModified===true}
                    message={this.handleBlockedNavigation}
                />
                <SaveDialog 
                    open={open} 
                    onSave={this.handleAlertSave} 
                    onMoveNext={this.handleMoveNext}
                    onClose={this.handleClose} 
                />
            </div>
        )
    }

}

export default withStyles(style)(LeaderRating);
