import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: theme.spacing(2)
    },
    additionalData: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
    helpIcon: {
        color: theme.palette.appMain.main,
        '&:hover': {
            color: theme.palette.appMain.dark,
        }
    },
    helperPaper: {
        padding: theme.spacing(2),
        display:'flex',
        alignItems:'center',
        borderTop:`1.0rem solid ${theme.palette.appMain.main}`
    },

}));

export default {
    useStyles: useStyles
}
