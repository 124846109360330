import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    tutorial: {
        paddingBottom:'1.0rem'
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    contentTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
    actions: {
        justifyContent: 'flex-end'
    },
    media: {
        height: 80,
    },
    headerDiv: {
        textAlign: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    select: {
        paddingBottom: theme.spacing(2),
        minWidth:'10.0rem'
    },
    avatar:{
        backgroundColor:'white'
    },
    avatar__icon:{
        color:theme.palette.warning.main
    },
});

export default useStyles;
