import React from 'react';
//Core
import {
    TableRow
  } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
      padding: 0
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    actions: {
      justifyContent: 'flex-end'
      },
      blockButton: {
          color: theme.palette.error.main
      },
      lockButton: {
          color: theme.palette.primary.main
      },
      saveButton: {
          color: theme.palette.success.main
      },
      cancelButton: {
          color: theme.palette.error.main
      },
      unlockButton: {
          color: theme.palette.success.main
      },
      editButton: {
          color: theme.palette.warning.main
      },
      doneButton: {
          color: theme.palette.success.main
      },
      addButton: {
          color: theme.palette.success.main
      },
      compButton:{
          color:theme.palette.appMain.main
      },
      roleButton:{
        color:theme.palette.appMain.light
    }
  }));
  
  const StyledTableRow = withStyles({
      root: {
          '& > *': {
              fontSize: '0.8rem',
  
          },
      },
      head: {
          '& > *': {
              fontSize: '0.9rem',
              fontWeight: 'bold'
          },
      },
  })((props) => <TableRow  {...props} />)

export default {
    useStyles,
    StyledTableRow
};