import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '90%',
    },
    menuItem: {
        fontFamily: 'Nunito'
    }
}));


export default {
    useStyles: useStyles
}
