//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar,Progress  } from 'components';
//Actions

const Actions = {
    GetFilters:'admin/matrix/variations/filters',
    Delete:'admin/matrix/variations/filters/delete',
    Save:'admin/matrix/variations/filters/edit/save'
}

export const apiRequest = {
    populateFilters,
    deleteFilter,
    saveChanges
};

async function populateFilters(parent,page,period) {
    const {variation} = parent.props;
    const model = {
        variation:variation.id,
        period:period.id,
        page,
    };
    await new Api().call.post(Actions.GetFilters,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                filters:false
            },
            page:1,
            filters: res.variationFiltersList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function deleteFilter(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}


async function saveChanges(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}

