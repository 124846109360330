import theme from 'theme';


const useStyles = ({
    appBar:{
        height:'60px',
        backgroundColor:theme.palette.warning.light,
    },
    closeIcon:{
        color:'white'
    },
    root: {
        padding: theme.spacing(4),
        backgroundImage: 'url(/images/main.png)'
    },
    title:{
        color:'white'
    }
});

export default useStyles;