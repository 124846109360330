import React from 'react';
//Icons
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { UserStore } from 'stores';
//Style
import style from './style';


export default function HoverRating(props) {
    const { name, handleCompetences, compId, value, groupId, ratingPoints } = props;
    const [hover, setHover] = React.useState(-1);
    const classes = style.useStyles();
    const StyledTooltip = style.StyledTooltip;
    const StyledRating = style.StyledRating;

    const handleChange = (event, newValue) => {
        handleCompetences(compId, newValue, groupId);
    }

    const loggedBy =  UserStore.getLoggedByAdminValue();



    return (
        <div className={classes.root}>
        {hover !== -1 ?  <StyledTooltip title={ratingPoints.find(item => item.value === hover).label}>
                <StyledRating
                    name={"rating-" + name}
                    value={value}
                    disabled={loggedBy!=null && loggedBy.period.disabled}
                    max={ratingPoints.length}
                    size="large"
                    icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                    emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
                    precision={1}
                    onChange={(event, newValue) => handleChange(event, newValue)}
                    onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
                />
            </StyledTooltip> : 
            <StyledRating
            name={"rating-" + name}
            value={value}
            disabled={loggedBy!=null && loggedBy.period.disabled}
            max={ratingPoints.length}
            size="large"
            icon={<RadioButtonCheckedIcon fontSize="inherit" />}
            emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
            precision={1}
            onChange={(event, newValue) => handleChange(event, newValue)}
            onChangeActive={(event, newHover) => {
            setHover(newHover);
        }}
        />}
        </div>
    );
}
