import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    doneButton: {
        color: theme.palette.success.main
    },
    backButton: {
        color: theme.palette.error.main
    },
    avatar: {
        marginLeft: 'auto',
        height: 100,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    deleteIcon:{
        color:theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark
        },
    },
    loading:{
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        backgroundColor: 'transparent ',
      }
  }));

export default useStyles;