//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Helpers

import { Constants } from 'stores';
//Actions

const Actions = {
    GetUser: 'admin/user/user-positions/edit',
    SavePosition: 'admin/user/user-positions/edit/save',
    DeletePosition: 'admin/user/user-positions/delete'
}

export const apiRequest = {
    getUser,
    deletePosition,
    savePosition
};

async function getUser(parent) {
    const { user } = parent.props;

        await new Api().call.get(`${Actions.GetUser}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                user: res.user,
                isModifable: res.isModifable,
                positions: res.editPositionTable,
                lastValidFrom: res.lastValidFrom,
                basePositions: [...res.editPositionTable],
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        }).catch(handleError);

}  

async function deletePosition(parent,position) {
    const { user } = parent.state;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { position, userId: user.id };

        await new Api().call.post(Actions.DeletePosition, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true
                },
            }));
            getUser(parent);
            parent.props.refresh();
        }).catch(handleError);

}
async function savePosition(parent,position) {
    const { user } = parent.state;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { position,userId:user.id };

        await new Api().call.post(Actions.SavePosition, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true
                },
            }));
            getUser(parent);
            parent.props.refresh();
        }).catch(handleError);

}