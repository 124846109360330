//React

import React, { Component } from 'react';
//Core

import {
    Card,
    CardContent,
    Divider,
    Typography
} from '@material-ui/core';
//Style

import style from './style';


export default function Welcome(props) {
    const { date } = props;

    const classes = style();

    return (
        <Card>
            <CardContent>
                <Typography className={classes.typography}>
                    A Merkbau Cégcsoportnál az új belépőkkel három alkalommal ülünk le beszélgetni:
                    <br />
                    <ul style={{ padding: '1.0rem' }}>
                        <li>1 hónap után a közvetlen felettes</li>
                        <li>2 hónap után a HR</li>
                        <li>2,5 hónap után ismét a közvetlen felettes, amikor az év hátralévő részére célkitűzéseket is megfogalmaztok</li>
                    </ul>
                    <br />
                    A beszélgetések formanyomtatványait itt találod a felületen, itt tudod őket kitölteni, lementeni, nyomtatni.
                    <br /> <br />
                    Reméljük, hogy az új felület neked is hatékonyabbá teszi a munkádat!!
                    <br /> <br />
                    HR csoport
                </Typography>
            </CardContent>
            <Divider />
        </Card>
    )
}
