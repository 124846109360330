import React, { Component } from 'react';
//Core
import {
    Card,
    CardContent,
    CardHeader,
    Slider,
    Typography,
    Tooltip
} from '@material-ui/core';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';


class RangeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: [],
            selectedIndex: null,
            genValue: [],
        }
        this.onChange = this.onChange.bind(this);
        this.handleGenValue = this.handleGenValue.bind(this);
        this.onChangeCommitted = this.onChangeCommitted.bind(this);
        this.initGenValue = this.initGenValue.bind(this);
        this.initValue = this.initValue.bind(this);
    }

    //Lifecycle methods
    componentDidMount() {
        this.initGenValue();        
    }

    componentDidUpdate(prevProps, prevState) {
        const { value } = this.state;
        var changedIndex = null;
        var difference = null;
        prevState.value.map((item, index) => {
            if (value[index] != item) {
                const changedIndex = index;
                const difference = item - value[index];
                this.handleGenValue(changedIndex, difference);
            }
        });
    }

    //Event handling
    onChange = (event, newValue) => {
        const { value, selectedIndex } = this.state;
        const index = event.target.getAttribute("data-index");
        const arrayLength = (value.length - 1);
        if (selectedIndex === null) {
            this.setState({
                selectedIndex: index * 1,
            })
        }
        this.setState({
            value: newValue
        })
    };

    onChangeCommitted(event, newValue) {
        this.setState({
            selectedIndex:null
        });
        this.props.checkIsModified();
    }

    //Helper methods
    initValue(genValue) {
        var array = [];
        var sum = 0;
        for (var i = 0; i < genValue.length - 1; i++) {
            sum = sum + genValue[i].value;
            array.push(sum);
        }
        this.setState({
            value: array
        });
    }

    initGenValue() {
        var array = [];
        this.props.competences.map(item => {
            item.competences.map(competence => {
                array.push(competence);
            })
        })
        this.setState({
            genValue: array
        });
        this.initValue(array);
    }

    handleGenValue(changedIndex, difference) {
        const { genValue } = this.state;
        var array = genValue;
        array[changedIndex].value = array[changedIndex].value - difference;
        array[changedIndex + 1].value = array[changedIndex + 1].value + difference;
        this.setState({
            genValue:array
        })
    //    this.props.setCompetences(array);
    }

    //Render method
    render() {
        const { value, genValue } = this.state;
        const { subTitle,classes, disabled } = this.props;
        return (<div className={classes.root}>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
            <Slider
                disabled={disabled}
                className={classes.slider}
                value={value}
                onChange={this.onChange}
                onChangeCommitted={this.onChangeCommitted}
                aria-labelledby="range-slider"
                track={false}
            />
            <div className={classes.cards}>
                {genValue.map((item, index) => (
                    <Card key={index} style={{ width: item.value + '%' }}>

                        <CardHeader title={
                            <Tooltip title={item.label}>
                                <Typography className={classes.cardHeader}>
                                    {item.label}
                                </Typography>
                            </Tooltip>}>
                        </CardHeader>
                        <CardContent style={{ backgroundColor: item.color, textAlign: 'center' }}>
                            <Typography className={classes.cardContent}>
                                {`${item.value} %`}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>)
    }

}
export default withStyles(style)(RangeSlider);
