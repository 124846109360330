//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    StepLabel,
    Step,
    Stepper,
    CardHeader,
    Divider,
    Paper,
    ButtonGroup,
    Button
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
//Components

import { WithWeight, WithoutWeight } from './components';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


const defaultSteps = [
    ['Válszd ki a mérőszámot', 'Add meg a célkitűzés leírását', 'Add meg az elvárt értéket'],
    ['Válszd ki a mérőszámot', 'Add meg a célkitűzés leírását', 'Add meg az elvárt értéket', 'Add meg a feladat súlyát']
]

class AddGoal extends Component {
    state = {
        activeStep: 0,
        nextIsValid: false,
        steps: this.props.goalTypeMatrix.isWeighted ? defaultSteps[1] : defaultSteps[0]
    }

    componentDidUpdate(prevProps, prevState) {
        if (document.getElementsByClassName("goal-new-add-notvalid").length === 0) {
            if (prevState.nextIsValid !== true) {
                this.setState({
                    nextIsValid: true
                });
            }
        } else {
            if (prevState.nextIsValid !== false) {
                this.setState({
                    nextIsValid: false
                });
            }
        }
    }

    handleNext = (index) => {
        const { activeStep, steps } = this.state;

        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1
        }));
        if ((activeStep + 1) === steps.length) {
        }
    };

    handleBack = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1
        }));
    };

    render() {
        const { activeStep, steps, nextIsValid } = this.state;
        const { addNew, snackbar, classes, goalTypeMatrix } = this.props;
        const { onClickAddNew, onScaleChange, onDescriptionChange, onExpectedChange, onRelevanceChange, onClickAddDone } = this.props;

        return (
            <div className={classes.root}>
                <CardHeader
                    action=
                    {
                        <ButtonGroup>
                            <Button
                                onClick={() => onClickAddDone(addNew)}
                                className={classes.doneButton}
                                disabled={!(nextIsValid && activeStep === steps.length - 1)}
                                size="small"
                                endIcon={<DoneIcon />}
                            >
                                Mentés
                            </Button>
                            <Button
                                onClick={() => onClickAddNew(false)}
                                className={classes.cancelButton}
                                size="small"
                                endIcon={<CloseIcon />}
                            >
                                Mégsem
                            </Button>
                        </ButtonGroup>
                    }

                    title={
                        <Typography className={classes.title}>
                            {`Új hozzáadása - ${addNew.goalType.label}`}
                        </Typography>
                    }
                />
                <Divider className={classes.dividerBottom} />
                <Paper>
                    <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>{goalTypeMatrix.isWeighted ?
                        <WithWeight
                            goalTypeMatrix={goalTypeMatrix}
                            snackbar={snackbar}
                            classes={classes}
                            stepIndex={activeStep}
                            addNew={addNew}
                            onScaleChange={onScaleChange}
                            onRelevanceChange={onRelevanceChange}
                            onDescriptionChange={onDescriptionChange}
                            onExpectedChange={onExpectedChange} />
                        : <WithoutWeight
                            goalTypeMatrix={goalTypeMatrix}
                            snackbar={snackbar}
                            classes={classes}
                            stepIndex={activeStep}
                            addNew={addNew}
                            onScaleChange={onScaleChange}
                            onDescriptionChange={onDescriptionChange}
                            onExpectedChange={onExpectedChange} />}
                    </div>
                </Paper>
                <ButtonGroup style={{ padding: '1.5rem' }}>
                    <Button
                        onClick={this.handleBack}
                        className={classes.backButton}
                        disabled={activeStep === 0}
                        size="small"
                        startIcon={<NavigateBeforeIcon />}
                    >
                        Előző
                    </Button>
                    <Button
                        onClick={this.handleNext}
                        disabled={!nextIsValid || activeStep === steps.length - 1}
                        className={classes.nextButton}
                        size="small"
                        endIcon={<NavigateNextIcon />}
                    >
                        Következő
                    </Button>
                </ButtonGroup>
            </div>
        )
    }
}

export default withStyles(style)(AddGoal);
