//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar } from 'components';
//Actions

const Actions = {
    GetSelfRatingResultExcel: 'admin/home/self-result-excel',
    GetMotivationsResultExcel: 'admin/home/motivation-result-excel',
    GetMatrixResultExcel: 'admin/home/matrix-result-excel',
    GetGoalResultExcel: 'admin/home/goal-result-excel',
    GetResultResultExcel: 'admin/home/result-result-excel',
    GetRatingResultExcel: 'admin/home/rating-result-excel',
    GetLeaderRatingResultExcel: 'admin/home/leader-rating-result-excel',
    GetSpeakResultExcel: 'admin/home/speak-result-excel',

    SendSelfRatingResultEmailByUser: 'admin/home/self-result-email',
    SendMotivationEmailByUser: 'admin/home/motivation-result-email',
    SendLeaderRatingResultEmailByUser: 'admin/home/leader-rating-result-email',
    SendRatingResultEmailByUser: 'admin/home/rating-result-email',
    SendMatrixResultEmailByUser: 'admin/home/matrix-result-email',
    SendGoalResultEmailByUser: 'admin/home/goal-result-email',
    SendResultResultEmailByUser: 'admin/home/result-result-email',
    SendSpeakResultEmailByUser: 'admin/home/speak-result-email',

    SendSelfRatingResultEmailAll: 'admin/home/self-result-email-all',
    SendMotivationEmailAll: 'admin/home/motivation-result-email-all',
    SendLeaderRatingResultEmailAll: 'admin/home/leader-rating-result-email-all',
    SendRatingResultEmailAll: 'admin/home/rating-result-email-all',
    SendMatrixResultEmailAll: 'admin/home/matrix-result-email-all',
    SendGoalResultEmailAll: 'admin/home/goal-result-email-all',
    SendResultResultEmailAll: 'admin/home/result-result-email-all',
    SendSpeakResultEmailAll: 'admin/home/speak-result-email-all'
}

export const apiRequest = {
    getSelfRatingResultExcel,
    getMotivationsResultExcel,
    getMatrixResultExcel,
    getGoalResultExcel,
    getResultResultExcel,
    getRatingResultExcel,
    getLeaderRatingResultExcel,
    getSpeakResultExcel,

    sendSelfRatingResultEmailByUser,
    sendMotivationEmailByUser,
    sendLeaderRatingResultEmailByUser,
    sendRatingResultEmailByUser,
    sendMatrixResultEmailByUser,
    sendGoalResultEmailByUser,
    sendResultResultEmailByUser,
    sendSpeakResultEmailByUser,

    sendSelfRatingResultEmailAll,
    sendMotivationEmailAll,
    sendLeaderRatingResultEmailAll,
    sendRatingResultEmailAll,
    sendMatrixResultEmailAll,
    sendGoalResultEmailAll,
    sendResultResultEmailAll,
    sendSpeakResultEmailAll
};


async function getSelfRatingResultExcel(parent) {
    await new Api().call.post(Actions.GetSelfRatingResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getMotivationsResultExcel(parent) {
    await new Api().call.post(Actions.GetMotivationsResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getMatrixResultExcel(parent) {
    await new Api().call.post(Actions.GetMatrixResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getGoalResultExcel(parent) {
    await new Api().call.post(Actions.GetGoalResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getResultResultExcel(parent) {
    await new Api().call.post(Actions.GetResultResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getRatingResultExcel(parent) {
    await new Api().call.post(Actions.GetRatingResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getLeaderRatingResultExcel(parent) {
    await new Api().call.post(Actions.GetLeaderRatingResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

async function getSpeakResultExcel(parent) {
    await new Api().call.post(Actions.GetSpeakResultExcel)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));

        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    download: false,
                },
            }));
        });
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

async function sendSelfRatingResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendSelfRatingResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendMotivationEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendMotivationEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendLeaderRatingResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendLeaderRatingResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendRatingResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendRatingResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendMatrixResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendMatrixResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendGoalResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendGoalResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendResultResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendResultResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendSpeakResultEmailByUser(parent, userId) {
    await new Api().call.post(`${Actions.SendSpeakResultEmailByUser}/${userId}`)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendSelfRatingResultEmailAll(parent) {
    await new Api().call.post(Actions.SendSelfRatingResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendMotivationEmailAll(parent) {
    await new Api().call.post(Actions.SendMotivationEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendLeaderRatingResultEmailAll(parent) {
    await new Api().call.post(Actions.SendLeaderRatingResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendRatingResultEmailAll(parent) {
    await new Api().call.post(Actions.SendRatingResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendMatrixResultEmailAll(parent) {
    await new Api().call.post(Actions.SendMatrixResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendGoalResultEmailAll(parent) {
    await new Api().call.post(Actions.SendGoalResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendResultResultEmailAll(parent) {
    await new Api().call.post(Actions.SendResultResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}

async function sendSpeakResultEmailAll(parent) {
    await new Api().call.post(Actions.SendSpeakResultEmailAll)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    email: false
                }
            }));
        }).catch(handleError);
}
