//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    Save: 'admin/masterData/organizations-list/add-new/save',
    GetLeaders:'admin/masterData/organizations-list/leaders'
}

export const apiRequest = {
    saveChanges,
    getLeaders
};

//#Api calls

async function getLeaders(parent) {

    await new Api().call.get(Actions.GetLeaders)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState=>({
            users: res,
            loading:{
                ...prevState.loading,
                users:false
            }
        }));
    }).catch(error => {
        handleError(error);
    });
}

async function saveChanges(parent, model) {
    const { onClose,refresh } = parent.props;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            refresh();
            onClose();
        }).catch(handleError);
}

