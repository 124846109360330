import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import style from './style';


const Error = () => {
    const classes = style();

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={2}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <div className={classes.content}>
                        <img
                            alt="Error"
                            className={classes.image}
                            src="/images/Error.png"
                        />
                        <Typography variant="h1" className={classes.contentTitle}>
                            Váratlan hiba történt
                        </Typography>
                        <Typography variant="h1" className={classes.contentTitle}>
                            Kérem próbálkozzon újra
                        </Typography>
                        <Typography className={classes.contentSubTitle} variant="subtitle2">
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Error;
