import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';

//Actions
const Actions = {
    GetUsers: 'exit/user-list',
    GetUsersForFilter: 'probaido/filter/users',
}

export const apiRequest = {
    populateUsers,
    populateUsersForFilter
};

  async function populateUsers(parent,page) {

    const { filter } = parent.state;
    const model = {
        filteredUsers: filter.users,
        page

    };

        await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res.userList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateUsersForFilter(parent) {
        await new Api().call.get(Actions.GetUsersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                },
                filterUsers: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                }
            }));
        });
}
