import React, { Component } from 'react';
//Core
import { Grid,Divider } from '@material-ui/core';
//Styles
import { withStyles } from '@material-ui/styles';
import style from './style';
//Components
import { AccountProfile, AccountDetails,AccountSettings, Goals } from './components';
import { apiRequest } from './apiRequest';
import {Constants} from 'stores';


class Account extends Component {

    constructor(props) {
        super(props);
        apiRequest.getUser(this);
        apiRequest.getSettings(this);
        Constants.setcontentLoading(true);
       

        this.state = {
            user: null,
            loading: {
                user: true,
                settings: true
            },
            time: new Date(),
            settings: {
                autoSave: false
            },
            interval: setInterval(this.tick, 1000)
        }
    }

         //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        if (prevState.loading != loading) {
            if (loading.user === false && loading.settings === false) {
                Constants.setcontentLoading(false);
            }
        }
    }

    componentWillUnmount() {
        const { interval } = this.state;
        clearInterval(interval);   
    }
    //#EndLifecycle methods

    tick = () => {
        this.setState({
            time: new Date()
        });
    };

    handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: checked
            }
        }));      
    }

    handleTextChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));
    }

    render() {
        const { user, time, loading, settings } = this.state;
        const { classes, snackbar } = this.props;
        return ((loading.user === false && loading.settings === false ) && 
            <div className={classes.root}>
                <Grid
                    container
                    spacing={2}
                >

                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                md={6}
                                sm={12}
                                xs={12}
                            >
                                <AccountProfile user={user} time={time} />
                                <AccountSettings settings={settings} refreshProfile={() => apiRequest.getUser(this)} />
                            </Grid>
                            <Grid
                                item
                            md={6}
                            sm={12}
                                xs={12}
                            >
                            <AccountDetails refreshProfile={() => apiRequest.getUser(this)} snackbar={snackbar} user={user} />
                            </Grid>

                        </Grid>

                </Grid>
                <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                >
                    <Goals snackbar={snackbar} />
                    </Grid>
                </Grid>
            </div>
        );
    }
 
};

export default withStyles(style)(Account);
