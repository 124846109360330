//React
import React from 'react';

//Core
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent, 
  Typography,
  IconButton,
  Paper,
  InputAdornment,
  Grid,
  Divider,
  CircularProgress
 } from '@material-ui/core';
  import MuiDialogTitle from '@material-ui/core/DialogTitle';
  import Autocomplete from '@material-ui/lab/Autocomplete';
//Styles
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Pickers
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker
  } from '@material-ui/pickers';
  import huLocale from "date-fns/locale/hu";
  import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Icons
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import GetAppIcon from '@material-ui/icons/GetApp';

import { apiRequest } from './apiRequest';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor:theme.palette.appMain.main
  },
  title: {
    fontFamily:'nunito',
    color:'white',
    fontWeight:'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DateTimePicker = props => {
    const { value, disabled, id, name, error, label, minDate,maxDate, errorClassName, readOnly, onChange, helperText } = props;
    const classes = style.useStylesForPicker();
    return < MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
        <DatePicker
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled={disabled}>
                            <TodayIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            error={error}
            helperText={helperText}
            clearable={true}
            clearLabel="Törlés"
            emptyLabel="Nincs megadva"
            id={id}
            name={name}
            fullWidth
            cancelLabel="Vissza"
            openTo="date"
            label={label}
            readOnly={readOnly}
            disabled={disabled}
            format="yyyy MMMM dd"
            minDate={minDate}
            minDateMessage="Nem megengedett érték"
            maxDate={maxDate}
            views={["year", "month", "date"]}
            autoOk
            value={(value !== null && value !== undefined) ? value : null}
            onChange={date => onChange(date)}
        />
    </MuiPickersUtilsProvider >;
  }


function ExitDialog(props) {
  const {onClose,open,selected,readOnly} = props;
  const classes = style.useStyles();

  const [form,setForm] = React.useState(null);
  const [hrs,setHrs] = React.useState([]);
  const [loading,setLoading] = React.useState(true);
  const [leaders,setLeaders] = React.useState([]);
  const [options,setOptions] = React.useState([]);
  const [download,setDownload] = React.useState(false);

  React.useEffect(()=>{
    apiRequest.fetchForm(selected.id,setForm,setLoading)
    apiRequest.populateHrs(setHrs);
    apiRequest.populateLeaders(setOptions);
  },[])

  
  function handleDateChange(date){
    
    var temp = {...form};
    temp.interViewTime = date;
    setForm(temp);
  }

  function handleHrChange(value){
    var temp = {...form};
    temp.hr = value;
    setForm(temp);
  }

  function validate(){
    if(form==null){
      return false;
    }
    if(form.hr==='' || form.hr===undefined || form.hr===null){
        return false;
    }
    if(form.interViewTime==='' || form.interViewTime===undefined || form.interViewTime===null){
        return false;
    }
    return true;
  }

  function handleSave(){
    const model={form,exitUserId:selected.id};
    apiRequest.save(model);
  }

  function handleSend(){
    const model={form,user:selected,leaders};
    const saveModel={form,exitUserId:selected.id};
    var save = apiRequest.save(saveModel);
    save.then(res => {    
      apiRequest.send(model,setLeaders);
    }).catch(error=>{});
 
  }

  function handleChange(event,index){
    const value = event.target.value;
    var temp = {...form};
    temp.mainList[index].value = value;
    setForm(temp);
  }

  function handleDownload(){
    setDownload(true)
    const model={form,exitUserId:selected.id};
    var save = apiRequest.save(model);
    save.then(res => {    
      apiRequest.download(selected,setDownload);
    }).catch(error=>{});
  }

  return (
    <div>
      <Dialog 
        fullWidth={true}
        maxWidth={"lg"} 
        open={open} 
        onClose={onClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={onClose}>EXIT interjú</DialogTitle>
        <PerfectScrollbar>
        <DialogContent>
        <Paper style={{padding:'0.5rem'}}>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Név:</Typography>
                <Typography>{selected.fullName} </Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Pozíció:</Typography>
                <Typography> {selected.bio}</Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Szervezeti egység:</Typography>
                <Typography> {selected.organization}</Typography>
            </div>
            <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Közvetlen felettes:</Typography>
                <Typography>{(selected.leader!=null && selected.leader!=undefined) && selected.leader.fullName}</Typography>
            </div>
            <Grid container style={{padding:'1rem'}} spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <Autocomplete
                        clearText="Alaphelyzet"
                        noOptionsText="Nincs találat"
                        closeText="Bezárás"
                        id="hr"
                        name="hr"
                        value={form!==null ? form.hr : ''}
                     
                        onChange={(event, value) => handleHrChange(value)}
                        options={hrs}
                        getOptionLabel={(option) => option.fullName}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{minWidth:'260px'}}
                                label="Beszélgetést végző HR-es"
                            />
                        )}
                    />
                </Grid>
                <Grid item  xs={12} sm={12} md={4}>
                    <DateTimePicker label="Beszélgetés időpontja" value={form!=null ? form.interViewTime : ''}  onChange={(date)=>handleDateChange(date)}/>
                </Grid>
            </Grid>
            </Paper>
            <Divider />
                 {loading===true && <div style={{textAlign:'center',padding:'1rem'}}><CircularProgress/></div>}
                 {form!==null && loading!==true && form.mainList.map((main,index)=><div key={main.id}>
                  <Typography style={{fontSize:'1.2rem',textAlign:'center', color:theme.palette.primary.main,padding:'1.0rem'}}>{main.label}</Typography>
                  <Paper style={{padding:'0.5rem'}}>
                    {main.subList.map(sub=>
                      <Typography key={sub.id} style={{padding:'0.2rem'}}>{sub.label}</Typography>
                    )}
                   <TextField value={main.value} onChange={(event)=>handleChange(event,index)} multiline={true} rows={6} fullWidth variant="outlined"/>
                 </Paper></div> 
                  
                 )}                       
                <Typography style={{fontSize:'1.2rem',textAlign:'center', color:theme.palette.primary.main,padding:'1.0rem'}}>KIKÜLDÉS</Typography>
                <Paper style={{padding:'0.5rem'}}>
                <Autocomplete
                    multiple
                    clearText="Alaphelyzet"
                    noOptionsText="Nincs találat"
                    closeText="Bezárás"
                    id="leaders"
                    name="leaders"
                    value={leaders}
                    onChange={(event, value) => setLeaders(value)}
                    options={options}
                    getOptionLabel={(option) => option.fullName}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Vezetők"
                        />
                    )}
                />            
                <Button style={{marginTop:'0.5rem'}} onClick={handleSend} disabled={!validate() || download} fullWidth color="primary" variant="contained">Kiküldés</Button>
                </Paper>

        </DialogContent>
        </PerfectScrollbar>
        <DialogActions>
        <Button disabled={!validate() || download || loading} endIcon={<GetAppIcon/>} className={classes.mainButton} size="small" onClick={handleDownload} variant="outlined" color="secondary">
            Letöltés
          </Button>
          <Button disabled={download || loading} endIcon={<BackspaceIcon/>} className={classes.secondaryButton} size="small" onClick={onClose} variant="outlined" color="secondary">
            Vissza
          </Button>
          <Button disabled={!validate() || download || loading} endIcon={<SaveIcon/>} className={classes.greenButton} size="small" onClick={handleSave} variant="outlined">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ExitDialog;