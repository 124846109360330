//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Helpers

import { Constants } from 'stores';
//Actions

const Actions = {
    GetUser: 'admin/user/leader-of-users/edit',
    GetLeader: 'admin/user/leader-of-users/active-leader',
    SaveLeader: 'admin/user/leader-of-users/edit/save',
    DeleteLeader: 'admin/user/leader-of-users/delete'
}

export const apiRequest = {
    getUser,
    getLeader,
    deleteLeader,
    saveLeader
};

 //#Api calls
 async function getUser(parent) {
    const { user } = parent.props;
        await new Api().call.get(`${Actions.GetUser}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                user: res.user,
                isModifable: res.isModifable,
                lastValidFromForAdd: res.lastValidFromForAdd,
                lastValidFromForEdit: res.lastValidFromForEdit,
                leaders: res.editLeadersTable,
                baseLeaders: [...res.editLeadersTable],
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        }).catch(handleError);

}

async function getLeader(parent) {
    const { user } = parent.props;

        await new Api().call.get(`${Actions.GetLeader}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                leader: res,
                loading: {
                    ...prevState.loading,
                    leader: false
                }
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    leader: false
                }
            }));          
        });
}

async function deleteLeader(parent,leader) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { leader };
        await new Api().call.post(Actions.DeleteLeader, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true,
                    leader: true
                },
            }));
            getUser(parent);
            getLeader(parent);
            parent.props.refresh();
        }).catch(handleError);

}
async function saveLeader(parent,leader) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { leader };
        await new Api().call.post(Actions.SaveLeader, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true,
                    leader: true
                },
            }));
            getUser(parent);
            getLeader(parent);
            parent.props.refresh();
        }).catch(handleError);

}