//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {Api,handleResponse,handleError} from 'api';

//Actions
const Actions = {
    GetPeriods: 'admin/masterData/periods',
    GetCompetences:'admin/masterData/competences',
    Save:'admin/masterData/competences/save'
}

export const apiRequest = {
    populatePeriods,
    populateCompetences,
    saveChanges,
};

//#Api calls
async function populatePeriods(parent) {
        await new Api().call.get(Actions.GetPeriods)
        .then(handleResponse)
        .then(res => {
            parent.setState({
               periods:res
            });
        }).catch(handleError);
}

//#Api calls
async function populateCompetences(parent,period,position) {
    await new Api().call.get(`${Actions.GetCompetences}/${period}/${position}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            left:res.all,
            right:res.general,
            right_2:res.key,
            right_3:res.leader,
            rightQuantity:res.generalQuantity,
            right_2Quantity:res.keyQuantity,
            right_3Quantity:res.leaderQuantity,
            loading:false
        });
    }).catch(handleError);
}

//#Api calls
async function saveChanges(parent,model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            request:false
        });
    }).catch(handleError);
}


