//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { 
    CompaniesToolbar, 
    CompaniesTable, 
    Pagination, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import {  withStyles } from '@material-ui/styles';
import style from './style';


class Companies extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            companies: [],
            loading: {
                companies: true,
                filterCompanies: true,
            },
            filter: {
                companies: [],
                active: null
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            filterCompanies: [],
            edit: null,
            selectedCompanies: [],
        }

    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateCompanies(this, this.state.page);
        apiRequest.populateFilterCompanies(this);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (companies) => {
        this.setState({
            selectedCompanies: companies
        });
    }

    handleChange = (event, index, props) => {
        const value = event.target.value;
        const companies = [...this.state.companies];
        companies[index][props] = value;
        this.setState({ companies });
    }

    handleEdit = (bio) => {
        this.setState({
            edit: { ...bio }
        });
    }

    handleSave = (index) => {
        const { companies } = this.state;
        apiRequest.saveChanges(this, companies[index]);
    }

    handleCancel = (index) => {
        const companies = [...this.state.companies];
        companies[index].name = this.state.edit.name;
        this.setState({
            companies,
            edit: null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                companies: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateCompanies(this, 1));
    }

    handleDelete = (index) => {
        const companies = [...this.state.companies];
        companies[index].isValid = false;
        apiRequest.saveChanges(this, companies[index]);
    }

    handleDone = (index) => {
        const companies = [...this.state.companies];
        companies[index].isValid = true;
        apiRequest.saveChanges(this, companies[index]);
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                companies: true
            }
        }));
        apiRequest.populateCompanies(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open:false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    handleBlockSelected = () =>{
        const {selectedCompanies,page} = this.state;
        
        const model ={companies:selectedCompanies,valid:false};
        apiRequest.validateCompanies(this,page,model);
        this.setState({
            selectedCompanies:[]
        });
    }

    handleUnBlockSelected = () =>{
        const {selectedCompanies,page} = this.state;

        const model ={companies:selectedCompanies,valid:true};
        apiRequest.validateCompanies(this,page,model);
        this.setState({
            selectedCompanies:[]
        });
    }

    refresh = () =>{
        const {page} = this.state;

        apiRequest.populateCompanies(this,page);
        apiRequest.populateFilterCompanies(this);
    }

    render() {
        const {companies, selectedCompanies, open, filter, currentPage, step, total, pages, filterCompanies, edit, loading} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>Cégcsoportok</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={10} item>
                        {loading.companies === false && 
                            <CompaniesToolbar
                                onBlockSelected={this.handleBlockSelected}
                                onUnBlockSelected = {this.handleUnBlockSelected} 
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter} 
                                companies={filterCompanies} 
                                loading={loading} 
                                selected={selectedCompanies} 
                            />
                        }
                        <div className={classes.content}>
                            <CompaniesTable
                                loading={loading.companies} 
                                onOpen={this.handleOpen} 
                                onDelete={this.handleDelete} 
                                onDone={this.handleDone} 
                                onCancel={this.handleCancel} 
                                edit={edit} 
                                onEdit={this.handleEdit} 
                                onSave={this.handleSave} 
                                onChange={this.handleChange} 
                                onSelect={this.handleSelectCompanies} 
                                companies={companies}
                                selectedCompanies={selectedCompanies}  
                            />
                        </div>
                        {(loading.companies === false && edit == null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total}                         
                                pages={pages} 
                            />
                        }
                    </Grid>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                </Grid>
                {open == true && 
                    <AddNewDialog
                        refresh={this.refresh} 
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </div>
        );
    }
};

export default withStyles(style)(Companies);
