import React from 'react';
//Core
import {
    Typography,
    Avatar,
    IconButton,
    Fade,
    Paper,
    Popper,
    ClickAwayListener
} from '@material-ui/core';
//Icons
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//Style
import style from './style';

export default function MainHeader(props) {
    const { title, additionalData } = props;
    const classes = style.useStyles();

    const [anchorEl,setAnchorEl] = React.useState(null);

    function handleOpen(event){
        setAnchorEl(event.currentTarget);
    }

    function handleClickAway(){
        setAnchorEl(null);
    }

    return (<div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {additionalData != null  &&      
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <IconButton onClick={handleOpen} className={classes.helpIcon}>
                    <HelpOutlineIcon/>
                    </IconButton>      
            </ClickAwayListener>
            <Popper open={anchorEl!==null} anchorEl={anchorEl} placement='bottom' transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.helperPaper}>
                        <Avatar src={(additionalData.avatar !== null && additionalData.avatar !== undefined) ? additionalData.avatar : ''} />
                            <Typography className={classes.additionalData}>{additionalData.fullName}</Typography>
                        </Paper>
                    </Fade>
                    )}
            </Popper>
        </div>}
    </div>)
}
