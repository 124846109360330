import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
    backgroundImage: 'url(/images/main.png)',
    backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    help: {
        textAlign: 'right',
        margin: theme.spacing(2),
    },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
      marginTop: theme.spacing(3),
      fontFamily: 'Nunito',
    },
  description:{
    marginTop: theme.spacing(3),
    fontFamily: 'Nunito',
    color:theme.palette.warning.main
  },
  span: {
        color: theme.palette.appMain.main
  },
  textField: {
      marginTop: theme.spacing(2),
      '& label': {
          fontFamily: 'Nunito'
      },
      '& label.Mui-focused': {
          color: theme.palette.appMain.main,
          },
          '& .MuiInput-underline:after': {
              borderBottomColor: 'green',
          },
          '& .MuiOutlinedInput-root': {           
              '&.Mui-focused fieldset': {
                  borderColor: theme.palette.appMain.main,
              },
              '&:hover fieldset': {
                  borderColor: theme.palette.appMain.light,
              },
      },
  }
}));
