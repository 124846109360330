import React from 'react';
//Core
import { TextField } from '@material-ui/core';
import { UserStore } from 'stores';


export default function TextBox(props) {
    const { defValue, itemId, handleChanges, isRequired } = props;
    const [value, setValue] = React.useState(defValue === null || defValue === undefined ? "" : defValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleChanges(itemId, event.target.value)
    };

    const loggedBy = UserStore.getLoggedByAdminValue();

    return (
        <TextField
            labelId="tf-mot-value-label"
            style={{padding:"0.5rem"}}
            fullWidth
            minRows={3}
            multiline
            id="tf-mot-value"
            placeholder="Megjegyzés írása..."
            value={value}
            disabled={loggedBy != null && loggedBy.period.disabled}
            onChange={handleChange}
            required={isRequired}
            error={(isRequired && (value === "" || value === undefined || value === null)) ? true : false}
            helperText={(isRequired && (value === "" || value === undefined || value === null)) ? "Kötelező kitölteni" : ""}
            variant='outlined'
        />
    );
}
