import React, { Component } from 'react';
//Core
import { Typography, CircularProgress } from '@material-ui/core';
//Components
import { GoalsGrid } from './components';
import { apiRequest } from './apiRequest';
//Style
import theme from 'theme';


class NextPeriod extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false
        }
    }


    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateGoals(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.period != this.props.period) {
            this.setState({
                loading: true
            });
            apiRequest.populateGoals(this);
        }
    }
    //#EndLifecycle methods
    render() {
        const { loading, data } = this.state;
        return (
            loading ? <div style={{padding: theme.spacing(4),textAlign: 'center'}}><CircularProgress color="primary" /></div> :
                data.map((item,index) => <React.Fragment key={index}>
                <Typography style={{ fontFamily: 'nunito', fontWeight: 'bold', fontSize: '1.0rem', paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}>{item.goalType.label}</Typography>
                <GoalsGrid key={item.goalType.id}
                    data={item.goalsForTable} goalType={item.goalType} goalTypeMatrix={item.goalType} />
                </React.Fragment>)
        )
    }

}
export default NextPeriod;
