import  theme  from 'theme';

const useStyles = () => ({

    addNewButton: {
        color: theme.palette.appMain.main,
        '&:hover': {
            color: theme.palette.appMain.dark,
        },
        verticalAlign: 'top'
    },
    table: {
        marginTop: theme.spacing(2)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    addNewButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    copyButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    doneButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    mainButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.light}`
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
        },
    },
    select: {
        paddingBottom: theme.spacing(2)
    }
});

export default useStyles;
