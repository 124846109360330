import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    typography: {
        padding: theme.spacing(2),
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    content: {
        padding: 0
    },
    newIcon:{
        color:theme.palette.primary.main
    },
    inProgressIcon:{
        color:theme.palette.warning.light
    },
    doneIcon:{
        color:theme.palette.success.light
    },
    button_container:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        gap:'0.5rem'
    },
    newButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            color:theme.palette.primary.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.primary.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    inProgressButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.warning.light,
        '&:hover': {
            color:theme.palette.warning.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.warning.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.light,
        '&:hover': {
            color:theme.palette.error.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
}));
export default useStyles;