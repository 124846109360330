import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 200,
      height: 230,
      overflow: 'auto'
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    header:{
      fontWeight:'bold'
    }
  }));
  export default useStyles;