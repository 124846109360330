//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetVariations:'admin/matrix/variations',
    GetPeriods:'admin/matrix/variations/periods',
    GetClassifications:'admin/matrix/variations/classifications',
    Delete:'admin/matrix/variations/delete',
    Save:'admin/matrix/variations/edit/save'
}

export const apiRequest = {
    populateVariations,
    populatePeriods,
    populateClassifications,
    deletePoint,
    saveChanges
};

async function populateVariations(parent,period) {
    await new Api().call.get(`${Actions.GetVariations}/${period}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            variations:res,
            loading:false
        });
    }).catch(handleError);
}

async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            periods:res
        });
    }).catch(handleError);
}


async function populateClassifications(parent) {
    await new Api().call.get(Actions.GetClassifications)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            classifications:res
        });
    }).catch(handleError);
}

async function deletePoint(parent,model,period)
{
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            deleted:null
        });
        populateVariations(parent,period);
    }).catch(handleError);
}

async function saveChanges(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        populateVariations(parent,period);
    }).catch(handleError);
}


