import {Api,handleResponse,handleError} from 'api';

//Actions
const Actions = {
    GetUser: 'user/account',
    GetSettings: 'user/account/settings'
}


export const apiRequest = {
    getUser,
    getSettings
};

//#Api calls
async function getUser(parent) {
    const { snackbar } = parent.props;
        await new Api().call.get(Actions.GetUser)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                user: res,
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        }).catch(handleError);
    
}

async function getSettings(parent) {
    const { snackbar } = parent.props;
        await new Api().call.get(Actions.GetSettings)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                settings: res,
                loading: {
                    ...prevState.loading,
                    settings: false
                }
            }));
        }).catch(handleError);

}
