import theme from 'theme';

const useStyles = ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        padding: theme.spacing(2)
    },
    header: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '1.6'
        },

    },
});
export default useStyles;
