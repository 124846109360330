//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetUser: 'admin/user/edit-user',
    GetSettings: 'admin/user/edit-user/settings'
}

export const apiRequest = {
    getUser,
    getSettings
};

async function getUser(parent) {
    const { user } = parent.props;

    await new Api().call.get(`${Actions.GetUser}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                user: res,
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        }).catch(handleError);
}

async function getSettings(parent) {
    const { user } = parent.props;

    await new Api().call.get(`${Actions.GetSettings}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                settings: res,
                loading: {
                    ...prevState.loading,
                    settings: false
                }
            }));
        }).catch(handleError);
}