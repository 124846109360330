import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
    Typography,
    Grid,
    Icon,
    Box,
    Tooltip
} from '@material-ui/core';
//Icons

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Components

import {
    UserSelector,
    NotificationBar,
    SaveDialog
} from 'components';
import { TabPanels } from './components';
import {
    Constants,
    UserStore
} from 'stores';
import { apiRequest } from './apiRequest';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';


class ActYearGoals extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);

        this.state = {
            users: [],
            value: 0,
            loading: {
                users: true,
                goalTypes: true,
                lazyData: true,
            },
            goalTypes: [],
            goals: null,
            error: false,
            lazyData: null,
            autoSave: UserStore.getUserValue().autoSave,
            open: false,
            nextLocation: null,
            nextUser: null,
            isModified: false
        }
    }


    //#Lifecycle methods

    componentDidMount() {
        apiRequest.populateUsers(this);
        apiRequest.populateGoalTypes(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { users, loading, goalTypes } = this.state;
        const { history } = this.props;

        if (prevState.users != users) {
            if (users.length < 1) {
                NotificationBar('Nincs kiértékelendő célkitűzés', 'top', 'right', 4000, 'info', null);
                history.push('/home');
            }
        }
        if (prevState.loading != loading) {
            if (loading.goalTypes === false && loading.users === false) {
                if (goalTypes.length > 0 && users.length > 0) {
                    if (loading.lazyData === true) {
                        apiRequest.populateGoals(this, 1, goalTypes[0].id);

                    }
                }
            }
            if (loading.goalTypes === false && loading.users === false && loading.lazyData === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods


    setParentState = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    handleChange = (event, newValue) => {
        const { isModified, autoSave } = this.state;

        const classList = event.target.parentElement.classList.value.toUpperCase();
        if (classList.includes('AVATAR')) {
            if (autoSave == false && isModified == true) {
                this.setState({
                    open: true,
                    nextUser: newValue
                })
            } else {
                this.setState({
                    value: newValue,
                    isModified: false,
                    competences: null,
                    nextUser: null,
                    open: false,
                    nextLocation: null
                })
            }
        }

    };

    handleError = () => {
        this.setState({
            error: true
        });
    }

    //#Api calls

    //#EndApi calls

    async handleSaveChanges(userId) {
        const { goals } = this.state;

        var model = [];
        goals.goalsForTable.map(goal => {
            let skip = (goal.isSkipped !== undefined && goal.isSkipped !== null) ? goal.isSkipped : null;
            let reason = (goal.reasonOfSkip !== undefined && goal.reasonOfSkip !== null && goal.reasonOfSkip !== '') ? goal.reasonOfSkip : null;
            if (goal.performedValue !== null && goal.performedValue !== undefined) {
                model.push({ id: goal.id, value: String(goal.performedValue), userId, typeId: goals.goalType.id, isSkipped: skip, reasonOfSkip: reason })
            }
            else {
                model.push({ id: goal.id, value: null, userId, typeId: goals.goalType.id, isSkipped: skip, reasonOfSkip: reason })
            }

        });
        await apiRequest.saveGoals(this, model);
    }

    handleClose = () => {
        this.setState({
            open: false,
            nextLocation: null,
            nextUser: null
        });
    }

    handleAlertSave = () => {
        const { history } = this.props;
        const { nextLocation, users, value, nextUser } = this.state;

        this.setState({
            open: false,
            isModified: false
        }, () => {
            const promise = this.handleSaveChanges(users[value].id);
            promise.then(() => {
                if (nextLocation != null) {
                    history.push(nextLocation.pathname);
                } else {
                    this.setState({
                        value: nextUser
                    });
                }

            }).catch(error => { });
        });
    }

    handleMoveNext = () => {
        const { history } = this.props;
        const { nextLocation, nextUser } = this.state;

        this.setState({
            open: false,
            isModified: false
        }, () => {
            if (nextLocation != null) {
                history.push(nextLocation.pathname);
            } else {
                this.setState({
                    value: nextUser
                });
            }
        });
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            open: true,
            nextLocation: nextLocation,
            nextUser: null
        });
        return false
    }

    render() {
        const { loading, users, autoSave, open, isModified, value, goalTypes, error, lazyData } = this.state;
        const { snackbar, classes, realIsActive } = this.props;

        return ((loading.goalTypes === false && loading.users === false && loading.lazyData === false) &&
            <div className={classes.root} >
                <Grid container spacing={4}>
                    <Grid item md={1} xs={12} />
                    <Grid item md={10} xs={12}>
                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                            <Typography className={classes.title}>Célkitűzések</Typography>
                            {!realIsActive &&
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>
                                </Tooltip>
                            }
                        </Box>
                        <Typography className={classes.title}>Eredmények kitöltése</Typography>
                        {(users !== null && users !== undefined && users.length > 0) &&
                            < UserSelector data={users} value={value} onChange={this.handleChange}>
                                {lazyData !== null && <TabPanels setParentState={this.setParentState} lazyData={lazyData} onError={this.handleError} goalTypes={goalTypes} snackbar={snackbar} value={value} index={value} userId={(users !== null && users !== undefined && users.length > 0) ? users[value].id : null} />}
                            </UserSelector>
                        }
                    </Grid>
                    <Grid item md={1} xs={12} />
                </Grid>
                <Prompt
                    when={autoSave === false && isModified === true}
                    message={this.handleBlockedNavigation}
                />
                <SaveDialog
                    open={open}
                    onSave={this.handleAlertSave}
                    onClose={this.handleClose}
                    onMoveNext={this.handleMoveNext}
                />
            </div>
        );
    }
}

export default withStyles(style)(ActYearGoals)
