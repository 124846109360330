import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {        
        display: 'flex',
        alignItems: 'center',
    },
    buttons:{
        paddingBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    plusButton: {
        color: theme.palette.success.main
    },
    minusButton: {
        color: theme.palette.error.main
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));
