//React

import React from 'react';
//Components

import { MuiOutlinedInput } from 'components';

export default function SetRelevance(props) {
    const { handleChange, value } = props;

    const handleNumberFieldChange = (event) => {
        const value = event.target.value;
        if (value !== null && value !== undefined && value !== '') {
            handleChange('edit', (value * 1));
        } else {
            handleChange('edit', value);
        }
    }

    return (
        <MuiOutlinedInput
            margin="dense"
            id="input-expected"
            type="number"
            className={(value === null || value < 1) && "goal-new-edit-notvalid"}
            helperText={(value === null || value < 1) && 'Nem megengedett érték'}
            error={(value === null || value < 1) ? true : false}
            endInputAdornment={"%"}
            value={value != null ? value : 0}
            label="Feladat súlya"
            minValue={0}
            handleChange={handleNumberFieldChange} />
    );
}
