//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


class NewUser extends Component {

  constructor(props){
    super(props);

    this.state={
      fullName:'',
      domainName:'',
      email:'',
      bio:'',
      leader:'',
      organization:'',
      company:'',
      companies:[],
      bios:[],
      leaders:[],
      organizations:[],
      loading:{
        companies:true,
        bios:false,
        leaders:false,
        organizations:false,
        hrs:false
      }
    }

  }

  componentDidMount(){
    apiRequest.populateCompanies(this);
  }

  componentDidUpdate(prevProps,prevState){
    const {company,organization,hireDate} = this.state;
    if(prevState.company!=company){
      if(company==null || company==undefined || company == ''){
          this.setState({
            bio:'',
            leader:'',
            organization:''
          })
      }else{
        this.setState(prevState=>({
          loading:{
            ...prevState.loading,
            bios:true,
            organizations:true
          }
        }))
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
      }
    }
    if(prevState.organization!=organization)
    {
        if(organization==null || organization==undefined || organization == ''){
          this.setState({
            leader:'',
          })
      }else{
        this.setState(prevState=>({
          loading:{
            ...prevState.loading,
            leaders:true,
          }
        }))
        apiRequest.populateLeaders(this);
        apiRequest.getLeader(this);
      }
    }
  }

   
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
        [name]: value
    });       
  }


  handleSelectChange = (props, value) => {
    this.setState({
        [props]: value
    });
  }

  validateFields=(value)=>{
    if(value===null || value===undefined || value===''){
        return false;
    }
    return true;
  }

  handleSave = () =>{
    apiRequest.saveUser(this);
  }


    render() {
        const { onClose, open, classes, } = this.props;
        const { fullName,domainName,email,companies,company,bio,bios,organization,organizations,leader,leaders,loading} = this.state;
        return (
            <Dialog  open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                        {<div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új felhasználó létrehozása
                        </Typography>
                        </div>}
                    </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                    <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <TextField
              error={!this.validateFields(fullName)} 
              required 
              fullWidth
              variant="outlined"
              margin="dense"
              name="fullName"
              onChange={this.handleChange} 
              value={fullName}
              label="Teljes név"
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              error={!this.validateFields(domainName)} 
              required 
              fullWidth
              variant="outlined"
              margin="dense"
              name="domainName"
              onChange={this.handleChange} 
              value={domainName}
              label="Domain név"
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              name="email"
              variant="outlined"
              margin="dense"
              onChange={this.handleChange} 
              value={email}
              label="Email cím"
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Autocomplete
             clearText="Alaphelyzet"
             noOptionsText="Nincs találat"
             closeText="Bezárás"
             id="select-company"
             name="company"
             value={company}
             onChange={(event, value) => this.handleSelectChange("company", value)}
             options={companies}
             getOptionLabel={(option) => option.label===undefined ? '' : option.label}
             filterSelectedOptions
             renderInput={(params) => (
              <TextField
              {...params}
               required
               variant="outlined"
               margin="dense"
               error={!this.validateFields(company)}
               label="Cégcsoport"
               InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                          <React.Fragment>
                            {loading.companies ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </React.Fragment>
                            ),
                          }}
              />
            )}
           />
            </Grid>
          <Grid xs={12} sm={6} item>
            <Autocomplete
             disabled={(company==='' || company===null || company ===undefined) ? true : false}
             clearText="Alaphelyzet"
             noOptionsText="Nincs találat"
             closeText="Bezárás"
             id="select-bio"
             name="bio"
             value={bio}
             onChange={(event, value) => this.handleSelectChange("bio", value)}
             options={bios}
             getOptionLabel={(option) => option.label===undefined ? '' : option.label}
             filterSelectedOptions
             renderInput={(params) => (
              <TextField
              {...params}
               required
               variant="outlined"
               margin="dense"
               error={!this.validateFields(bio)}
               label="Munkakör"
               InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                          <React.Fragment>
                            {loading.bios ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </React.Fragment>
                            ),
                          }}
              />
            )}
           />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Autocomplete
             disabled={(company==='' || company===null || company ===undefined) ? true : false}
             clearText="Alaphelyzet"
             noOptionsText="Nincs találat"
             closeText="Bezárás"
             id="select-organization"
             name="organization"
             value={organization}
             onChange={(event, value) => this.handleSelectChange("organization", value)}
             options={organizations}
             getOptionLabel={(option) => option.label===undefined ? '' : option.label}
             filterSelectedOptions
             renderInput={(params) => (
              <TextField
              {...params}
               required
               variant="outlined"
               margin="dense"
               error={!this.validateFields(organization)}
               label="Szervezeti egység"
               InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                          <React.Fragment>
                            {loading.organizations ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </React.Fragment>
                            ),
                          }}
              />
            )}
           />
          </Grid>
          <Grid xs={12}  sm={6} item>
            <Autocomplete
             disabled={(organization==='' || organization===null || organization ===undefined) ? true : false}
             clearText="Alaphelyzet"
             noOptionsText="Nincs találat"
             closeText="Bezárás"
             id="select-leader"
             name="leader"
             value={leader}
             onChange={(event, value) => this.handleSelectChange("leader", value)}
             options={leaders}
             getOptionLabel={(option) => option.fullName===undefined ? '' : option.fullName}
             filterSelectedOptions
             renderInput={(params) => (
              <TextField
              {...params}
               required
               variant="outlined"
               margin="dense"
               error={!this.validateFields(leader)}
               label="Felettes"
               InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                          <React.Fragment>
                            {loading.leaders ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                          </React.Fragment>
                            ),
                          }}
              />
            )}
           />
          </Grid>       
        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                            <Tooltip title="Mentés">
                            <span>
                                <IconButton onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                        <DoneIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Mégsem">
                                <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                        <CloseIcon />
                                    </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                    </DialogActions>
                </Dialog>);
    }
   
   
}
export default withStyles(style.useStyles)(NewUser);
