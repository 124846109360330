import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
   
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.appMain.main
    },
    root: {
    },
    selectedRow: {
        backgroundColor: lighten(theme.palette.appMain.main, 0.9)
    }
}));
