
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetMotivationPoints: 'ratingForm/motivation/user',
}


export const apiRequest = {
    populateMotivationPoints
};

async function populateMotivationPoints(parent,userId) {
    const { period } = parent.props;

    var model = {userId,period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }


    await new Api().call.post(Actions.GetMotivationPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            motivationPoints: res,
            loading: false,
        })
    }).catch(error => {
         handleError(error);
         parent.initState();
    });

}