import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    subTitle: {
        fontWeight: 'bold',
        fontSize: '1.0rem',
        padding: theme.spacing(2)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    textField: {
        width: '80%',
    },
    multilineTextField: {
        width: '100%',
    },
    sendButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
        }
    },
    addIconButton: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    removeIconButton: {
        color: theme.palette.white,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.error.dark,
        },
        marginLeft:theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});


export default useStyles;
