import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
    Typography,
    Grid,
    Card,
    CardContent,
    Divider,
    IconButton,
    Fade,
    Paper,
    Popper,
    ClickAwayListener,
    Tooltip,
    Icon
} from '@material-ui/core';
//Components

import { 
    UserSelector, 
    SaveDialog 
} from 'components';

import { TabPanels } from './components';
import {
    Constants,
    UserStore
} from 'stores';
import {apiRequest} from './apiRequest';
//Icons

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';



class SecondaryRating extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);

        this.state = {
            value: 0,
            users: [],
            loading: {
                users: true,
                competences: true,
                ratingPoints: true
            },
            competences:null,
            lazyData: null,
            autoSave: UserStore.getUserValue().autoSave,
            open: false,
            infoOpen:false,
            nextLocation: null,
            nextUser:null,
            anchorEl:null,
            isModified:false
        }

    }

    //#Lifecycle methods

    componentDidMount(){
        apiRequest.populateUsers(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading,users } = this.state;

        if (prevState.loading != loading) {
            if (loading.users === false) {
                if (users.length > 0) {
                    if (loading.competences === true) {
                        apiRequest.populateCompetences(this,users[0].id)
                    }
                    if (loading.ratingPoints === true) {
                        apiRequest.populateRatingPoints(this);
                    }
                }
                else {
                    Constants.setcontentLoading(false);
                }
            }
            if (loading.users === false && loading.competences === false && loading.ratingPoints === false) {
                Constants.setcontentLoading(false);
            }

        }
    }

    //#EndLifecycle methods

    setParentState = (props,value) => {
        this.setState({
            [props]: value
        });
    }

    handleChange = (event, newValue) => {
        const { isModified, autoSave } = this.state;
        
        const classList = event.target.parentElement.classList.value.toUpperCase();
        if (classList.includes('AVATAR')) {
            if (autoSave == false && isModified == true) {
                this.setState({
                    open: true,
                    nextUser: newValue
                })
            } else {
                this.setState({
                    value: newValue,
                    isModified: false,
                    competences: null,
                    nextUser: null,
                    open: false,
                    nextLocation: null
                })
            }
        }

    };

    handleAlertSave = () => {
        const { history } = this.props;
        const { nextLocation, nextUser } = this.state;

        this.setState({
            open: false,
            isModified: false
        }, () => {
            const promise = apiRequest.handleSaveChanges(this,true);
                promise.then(() => {
                    if (nextLocation != null) {
                        history.push(nextLocation.pathname);
                    } else {
                        this.setState({
                            value: nextUser
                        });
                    }
                
            }).catch(error => { });

        });
    }

    handleMoveNext = () =>{
        const { history } = this.props
        const { nextLocation, nextUser } = this.state;

        this.setState({
            open: false,
            isModified:false,
        }, () => {
            if (nextLocation != null) {
                history.push(nextLocation.pathname);
            } else {
                this.setState({
                    value: nextUser
                });
            }
        });    
    }

    handleClose = () => {
        this.setState({
            open: false,
            nextLocation: null,
            nextUser:null
        });
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            open: true,
            nextLocation: nextLocation,
            nextUser: null
        });
        return false
    }

    handleInfoClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget,
            infoOpen:!this.state.infoOpen
        })
    };

    handleClickAway = () => {
        this.setState({
            infoOpen:false
        })
    }

    render() {
        const { value, users, open, isModified, autoSave, loading, lazyData,anchorEl, infoOpen } = this.state;
        const { snackbar, classes, realIsActive } = this.props;

        return ((loading.competences === false && loading.ratingPoints === false && loading.users === false) &&  
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        <div className={classes.headerDiv}>
                            <Typography className={classes.title}>Mellérendelt értékelés</Typography> 
                            {!realIsActive && 
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>                               
                                </Tooltip>
                            } 
                            <div className={classes.flexGrow} />                   
                            {(lazyData!==null && lazyData!== undefined && lazyData.ratingPoints!==null && lazyData.ratingPoints!==undefined) && 
                                <div className={classes.help}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                    <IconButton onClick={this.handleInfoClick} className={classes.helpIcon}>
                                        <HelpOutlineIcon/>
                                        </IconButton>      
                                    </ClickAwayListener>
                                    <Popper open={infoOpen} anchorEl={anchorEl} placement='bottom' transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper className={classes.helperPaper}>
                                                <Typography className={classes.helperText}><b>Magyarázat a pontokhoz:</b></Typography>
                                                {lazyData.ratingPoints.map(point=>
                                                <Typography key={point.value} className={classes.helperText}><b>Érték: </b>{point.value} - {point.label}</Typography>)
                                                }
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            }
                        </div>
                        <Card>
                            <CardContent>
                                <Typography className={classes.typography}>
                                    Az általad értékelt munkatárs kompetenciáit kérünk, 6-os skálán helyezd el. A 7 db kompetencia közül 3 db kulcskompetencia, melyek fontosságát dupla súlyozás jelzi. A 4 db általános kompetencia szimpla súllyal kerül majd be a kompetencia átlagba.
                                    <br></br><br></br>
                                    Helyezd az egeret a skála értékre, hogy lásd, mit takar.
                                    <br></br><br></br>
                                    Helyezd az egeret a kompetenciára, hogy lásd a leírást, mely a szervezet által elvárt szintet jelöli!
                                </Typography>
                            </CardContent>
                            <Divider />
                        </Card>
                        {
                            users.length>0 ? 
                            <UserSelector data={users} value={value} onChange={this.handleChange}>
                                {lazyData !== null && <TabPanels setParentState={this.setParentState} lazyData={lazyData} snackbar={snackbar} value={value} index={value} userId={users[value].id} />}
                            </UserSelector> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}><b>Nincs értékelendő személy</b></div>
                        }
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
                <Prompt
                    when={autoSave === false && isModified === true}
                    message={this.handleBlockedNavigation}
                />
                <SaveDialog 
                    open={open} 
                    onSave={this.handleAlertSave} 
                    onMoveNext={this.handleMoveNext}
                    onClose={this.handleClose} 
                />
            </div>
        )
    }
}
export default withStyles(style)(SecondaryRating)
