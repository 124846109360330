import React, { useState } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core
import {
    IconButton,
    InputAdornment,
} from '@material-ui/core';
//Icons
import TodayIcon from '@material-ui/icons/Today';
//Pickers
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
//Style
import style from './style';

export default function DateSelect(props) {
    const { value, label, errorClassName, readOnly, disabled, name, id, withBackground, helperText, margin, skipError, skipHelperText } = props;
    const { handleChange } = props;
    const handleDateChange = (date) => {
        handleChange(date);
    }
    const classes = withBackground === true ? style.useStylesForResultForm() : style.useStyles();

    var mappedValue = value;
    if (value != null && value != undefined && value != '') {
        const splittedValue = value?.split('-');
        if (splittedValue?.length > 1) {
            if (splittedValue[1].length === 1) {
                mappedValue = splittedValue[0] + "-0" + splittedValue[1]
            }
        }
    }

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                id={id}
                name={name}
                error={!skipError && (value === null || value === undefined) ? true : false}
                helperText={!skipHelperText && (value === null || value === undefined) && helperText}
                inputVariant="outlined"
                className={(value === null || value === undefined) ? clsx(classes.picker, errorClassName) : classes.picker}
                cancelLabel="Vissza"
                openTo="month"
                margin={margin}
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM"
                /* minDate={new Date("2019-01-01")}
                 maxDate={new Date("2020-12-31")}*/
                views={["year", "month"]}
                autoOk
                value={(mappedValue !== null && mappedValue !== undefined) ? mappedValue : null}
                onChange={date => handleDateChange(date)}
            />
        </MuiPickersUtilsProvider>

    );
}

