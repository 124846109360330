import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
    root: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        width: '30px',
        fontSize:'1.2rem',
        textAlign: 'center',
        fontWeight:'bold'
    }
}));

export default {
useStyles:useStyles
}
