//React

import React from 'react';
//Core

import {
    Grid,
} from '@material-ui/core';
//Components

import { 
    WriteDescription, 
    SelectScale, 
    SetExpected, 
    SetRelevance 
} from '../../../components';


function WithWeight(props) {
    const { classes, stepIndex, addNew, snackbar } = props;
    const { onScaleChange, goalTypeMatrix, onDescriptionChange, onExpectedChange, onRelevanceChange } = props;

    switch (stepIndex) {
        case 0:
            return <div className={classes.gridRoot}> 
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <SelectScale 
                        snackbar={snackbar} 
                        goalType={addNew.goalType} 
                        value={addNew.base.scale !== null ? addNew.base.scale.id : null} 
                        handleChange={onScaleChange} 
                    />
                </Grid>
                <Grid item md={4} xs={12}/>
                <Grid item md={4} xs={12}/>
            </Grid>
            </div>;
        case 1:
            return <div className={classes.gridRoot}> 
            <Grid container spacing={4}>
                <Grid item md={3} xs={12}>
                    <SelectScale 
                        value={addNew.base.scale !== null ? addNew.base.scale.id : null} 
                        handleChange={onScaleChange} disabled={true} 
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <WriteDescription 
                        value={addNew.base.description === null ? '' : addNew.base.description}
                        handleChange={onDescriptionChange} 
                    />
                </Grid>
                <Grid item md={3} xs={12}/>
            </Grid>
            </div>;
        case 2:
            return <div className={classes.gridRoot}> 
            <Grid container spacing={4}>
                <Grid item md={3} xs={12}>
                    <SelectScale 
                        value={addNew.base.scale !== null ? addNew.base.scale.id : null} 
                        handleChange={onScaleChange} disabled={true} 
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <WriteDescription 
                        value={addNew.base.description} 
                        handleChange={onDescriptionChange} 
                        disabled={true} 
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <SetExpected 
                        value={addNew.base.expectedValue} 
                        handleChange={onExpectedChange} 
                        selectedScale={addNew.base.scale} 
                    />
                </Grid>
                <Grid item md={3} xs={12}/>
            </Grid>
            </div>;
        case 3:
            return <div className={classes.gridRoot}> 
            <Grid container spacing={4}>
                <Grid item md={3} xs={12}>
                    <SelectScale 
                        value={addNew.base.scale !== null ? addNew.base.scale.id : null} 
                        handleChange={onScaleChange} 
                        disabled={true} 
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <WriteDescription 
                        value={addNew.base.description} 
                        handleChange={onDescriptionChange} 
                        disabled={true} 
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <SetExpected 
                        value={addNew.base.expectedValue} 
                        handleChange={onExpectedChange} 
                        disabled={true} 
                        selectedScale={addNew.base.scale} 
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <SetRelevance 
                        weightSum={goalTypeMatrix.weightSum} 
                        value={addNew.base.relevance} 
                        handleChange={onRelevanceChange} 
                    />
                </Grid>
            </Grid>
            </div>;
        default:
            return 'Unknown stepIndex';
    }
}
export default WithWeight;
