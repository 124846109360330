//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    ButtonGroup,
    DialogTitle,
    Divider,
    Typography,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    LinearProgress
} from '@material-ui/core';
//Icons

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/styles';
//Api

import { apiRequest } from './apiRequest';

const StyledTableRow = style.StyledTableRow;

class UsersDialog extends Component {

    state = {
        users: [],
        loading: true
    }

    componentDidMount() {
        const { title, selected } = this.props;
        switch (title) {
            case 'Mátrixok':
                apiRequest.getMatrixUsers(this, selected);
                break;
            case 'Célkitűzések':
                apiRequest.getGoalUsers(this, selected);
                break;
            case 'Eredmények':
                apiRequest.getResultUsers(this, selected);
                break;
            case 'Beszélgetések':
                apiRequest.getSpeakUsers(this, selected);
                break;
            case 'Értékelések':
                apiRequest.getRatingUsers(this, selected);
                break;
        }
    }

    render() {
        const { open, onClose, classes } = this.props;
        const { users, loading } = this.state;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Hiányosságok
                        </Typography>
                    </div>
                </DialogTitle>
                {loading && <LinearProgress />}
                <Divider />
                <DialogContent>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>Teljes név</TableCell>
                                <TableCell>Munkakör</TableCell>
                                <TableCell>Szervezeti egység</TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(user => (
                                <StyledTableRow
                                    className={classes.tableRow}
                                    hover
                                    key={user.id}
                                >
                                    <TableCell>
                                        <div className={classes.nameContainer}>
                                            <Avatar
                                                className={classes.avatar}
                                                src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                                            />
                                            <Typography variant="body1">
                                                {user.fullName}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {user.bio}
                                    </TableCell>
                                    <TableCell>
                                        {user.org}
                                    </TableCell>
                                </StyledTableRow>))
                            }
                            {users.length < 1 &&
                                <StyledTableRow>
                                    <TableCell align="center" colSpan="9">
                                        Nincs találat
                                    </TableCell>
                                </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button
                            className={classes.cancelButton}
                            size="small"
                            onClick={onClose}
                            endIcon={<ArrowBackIcon />}
                        >
                            Vissza
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(style.useStyles)(UsersDialog);
