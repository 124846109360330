import theme from 'theme';


const useStyles = ({
    root: {
      padding: theme.spacing(3),
      backgroundImage: 'url(/images/main.png)'
    },
    content: {
      marginTop: theme.spacing(2)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    title: {
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: '1.4rem',
          padding: theme.spacing(2)
    },
    appBar:{
      height:'70px',
      backgroundColor:theme.palette.appMain.main,
    },
    closeIcon:{
      color:'white'
    }
  });

export default useStyles;