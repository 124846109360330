//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Tooltip,
  IconButton,
  LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';


const StyledTableRow = style.StyledTableRow;

const UsersTable = props => {
  const { className, users, onEdit, loading, onLock, selectedUsers, onValidation, onRole, onSelect, onCheckList, ...rest } = props;

  const classes = style.useStyles();

  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    onSelect(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    onSelect(newSelectedUsers);
  };
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers.length === users.length}
                      color="primary"
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < users.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Email cím</TableCell>
                  <TableCell>Domain név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>HR-es</TableCell>
                  <TableCell>Belépés dátuma</TableCell>
                  <TableCell>Aktív</TableCell>
                  <TableCell>Zárolva</TableCell>
                  <TableCell align="center" colSpan="4">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                    selected={selectedUsers.indexOf(user.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.indexOf(user.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, user.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        />
                        <Typography variant="body1">
                          {user.fullName}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    <TableCell>
                      {user.domainName}
                    </TableCell>
                    <TableCell>
                      {user.bio}
                    </TableCell>
                    <TableCell>
                      {user.organization}
                    </TableCell>
                    <TableCell>
                      {user.hrMember}
                    </TableCell>
                    <TableCell>
                      {user.dateOfEntry}
                    </TableCell>
                    <TableCell>
                      {user.isActive === true ? 'Igen' : 'Nem'}
                    </TableCell>
                    <TableCell>
                      {user.lockOutEnabled === true ? 'Igen' : 'Nem'}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Tooltip title="Próbaidő teendők megtekintése">
                        <IconButton onClick={() => onCheckList(user)} className={classes.checklistButton} aria-label="checkList">
                          <ListAltOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell padding="checkbox">
                      {user.isActive === true ?
                        <Tooltip title="Felhasználó letiltása">
                          <IconButton onClick={() => onValidation([user.id], false, false)} className={classes.doneButton} aria-label="delete">
                            <DoneIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Felhasználó feloldása">
                          <IconButton onClick={() => onValidation([user.id], true, false)} className={classes.blockButton} aria-label="add">
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      {user.lockOutEnabled === false ?
                        <Tooltip title="Felhasználó zárolása">
                          <IconButton onClick={() => onLock([user.id], true, false)} className={classes.unlockButton} aria-label="lock">
                            <LockOpenIcon />
                          </IconButton>
                        </Tooltip> :
                        <Tooltip title="Zárolás feloldása">
                          <IconButton onClick={() => onLock([user.id], false, false)} className={classes.lockButton} aria-label="unlock">
                            <LockIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Tooltip title="Adatok módosítása">
                        <IconButton onClick={() => onEdit(user)} className={classes.editButton} aria-label="delete">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
                {users.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="9">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
