
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetSystems: 'gui/check-login-types',
}

export const apiRequest = {
    getSystems
};

async function getSystems(userName, setSystems) {
    await new Api().call.get(`${Actions.GetSystems}/${userName}`)
        .then(handleResponse)
        .then(res => {
            setSystems(res);
        }).catch(handleError);
}

