import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const appMain ='#008AAB';
const appSecondary = '#8A8A8D';
const papper = "#f5f5f5";

export default {
  black,
    white,
    papper,
  appMain: {
      contrastText: white,
      dark: '#006680',
      main: appMain,
      light: '#00B8E6'
  },
  appSecondary: {
      contrastText: white,
      dark: '#7D7D80',
      main: appSecondary,
      light: '#E1E1E6'
  },
  primary: {
    contrastText: white,
    dark: '#006680',
    main: appMain,
    light: '#00B8E6'
  },
  secondary: {
    contrastText: white,
    dark: '#7D7D80',
    main: appSecondary,
    light: '#E1E1E6'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  disabled:{
    default:'#A9A9A9',
    hoover:'	#D3D3D3'
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
