
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoals: 'ratingForm/goals/result',
    GetSumOfResult: 'ratingForm/goals/sum/result'
}

export const apiRequest = {
    populateGoals,
    populateSumOfResult
};

async function populateGoals(parent) {
    const { period } = parent.props;

    var model = {period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: false,
                data: res
            }));
        }).catch(handleError);

}
async function populateSumOfResult(parent) {
    const { period } = parent.props;

    var model = {period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await  new Api().call.post(Actions.GetSumOfResult,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                sumOfResult: res
            }));
        }).catch(handleError);

}