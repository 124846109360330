import React from 'react';
//Components
import { MuiOutlinedInput } from 'components'; 

export default function WriteDescription(props) {
    const { handleChange, value, disabled} = props;

    return (
        <MuiOutlinedInput
            multiline={true}
            rows={3}
            margin="dense"
            disabled={disabled}
            readOnly={false}
            value={value}
            className={!(value != null && value != '') && "goal-new-edit-notvalid"}
            helperText={!(value != null && value != '') && 'Kötelező kitölteni'}
            error={(value!=null && value!='') ? false : true}
            handleChange={(event) => handleChange('edit',event)}
            id={`textfield-add-new-goal-description`}
            label="Célkitűzés" />
    );
}
