import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, Slide, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import MuiButton from '../../../../theme/overrides/MuiButton';
//Components
import { authenticationService } from 'services';
//Style
import { withStyles } from '@material-ui/core/styles';
import style from './style';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(style)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const LogoutDialog = props => {
    const { history, closeHandler, open,snackbar } = props;

    function logout() {
        authenticationService.logout(false);
    }

    return (

        <Dialog TransitionComponent={Transition} onClose={closeHandler} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
                Biztosan kijelentkezel?
                </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Kattints a  "Kijelentkezés" gombra ha végeztél az aktuális teendőiddel!
                    </Typography>
            </DialogContent>
            <DialogActions>
                <MuiButton color="secondary" value="Vissza" onClick={closeHandler} />
                <MuiButton color="main" value="Kijelentkezés" onClick={logout} />
            </DialogActions>
        </Dialog>
    )
}

LogoutDialog.propTypes = {
    history: PropTypes.object
};

export default withRouter(LogoutDialog);

