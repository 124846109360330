import React, { Component } from 'react';
//Core
import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
//Components
import { apiRequest } from './apiRequest';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

//Style
import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class EmailsDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            emails:[''],
            loading:true,
            label: '',
            valid:false
        }

    }

    componentDidMount(){
       apiRequest.getEmails(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { emails } = this.state;
        if (prevState.emails != emails) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }


    onChange = (value,index) => {
        const {emails} = this.state;
        var temp = [...emails];
        temp[index] = value;
        this.setState({
            emails: temp
        });       
    }

    handleSave = () => {
        const {selected} = this.props;
        const { emails } = this.state;
        const model = { emails,groupId:selected.id };
        apiRequest.saveChanges(this,model);
    }

    handleRemove = (_index) =>{
        const {emails} = this.state;
        var temp = [...emails].filter((email,index)=>index!==_index);
        this.setState({
            emails: temp
        });       
    }

    handleNew = () =>{
        const {emails} = this.state;
        var temp = [...emails];
        temp.push('');
        this.setState({
            emails: temp
        });   
    }
    


    render() {
        const { onClose, open, classes, } = this.props;
        const { label, valid,emails} = this.state;
        return (
            <Dialog  open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.appMain.main }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Email címek hozzárendelése
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            Új email cím hozzáadása
                            <Tooltip title="Törlés">
                                <span>
                                    <IconButton className={classes.addButton} onClick={this.handleNew}  aria-label="back">
                                        <AddIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <div id="add-new-form">
                    <Grid spacing={2} container>
                        {emails.map((email,index)=><React.Fragment>
                           <Grid
                            item
                            xl={10}
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            >
                                <TextField
                                    required
                                    error={!this.validateFields(email)}
                                    helperText={!this.validateFields(email) && 'Kötelező megadni'}
                                    onChange={(event)=>this.onChange(event.target.value,index)} 
                                    fullWidth
                                    name={"email_"+index}
                                    value={email}
                                    label="Email cím"
                                />
                        </Grid>   
                        <Grid  xs={1} sm={1} md={1} lg={1} xl={1} item>
                            <Tooltip title="Törlés">
                                <span>
                                    <IconButton className={classes.backButton} onClick={()=>this.handleRemove(index)}  aria-label="back">
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        </React.Fragment>                                      
                      )}
                    </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                            <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                        <DoneIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Mégsem">
                                <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                        <CloseIcon />
                                    </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                    </DialogActions>
                </Dialog>);
    }
   
   
}
export default withStyles(style.useStyles)(EmailsDialog);
