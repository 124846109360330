//React

import React, { Component } from 'react';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    InputAdornment,
    Grid,
    TextField,
    Typography,
    CircularProgress
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


const DateTimePicker = props => {
    const {value, disabled, id, name, required, label, minDate, maxDate, error, errorClassName, readOnly, onChange, helperText} = props;
    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                error={error}
                helperText={helperText}
                clearable={true}
                clearLabel="Törlés"
                id={id}
                inputVariant="outlined"
                margin="dense"
                required={required}
                name={name}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                maxDate={maxDate}
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined)  ? value : null}
                onChange={date => onChange(name,date)}
            />
        </MuiPickersUtilsProvider>
    )
}

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.populateOptions(this);

        this.state = {
            options: [],
            selected:null,
            validFrom: null,
            loading:true,
            valid:false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {selected, validFrom} = this.state;

        if (prevState.selected != selected || prevState.validFrom!= validFrom) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onDateChange = (props, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 

            this.setState({
                [props]: newDate
            });
    }

    onChange = (leader) => {
        this.setState({
            selected: leader
        });
    }

    handleSave = () => {
        apiRequest.saveUser(this);
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    } 

    render() {
        const {onClose, open, lastValidFrom, classes} = this.props;
        const {validFrom, valid, loading, options, selected} = this.state;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új felettes hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent id="add-new-form">
                    <Grid spacing={2} container>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-leader"
                                    name="leader"
                                    value={selected}
                                    onChange={(event, value) => this.onChange(value)}
                                    options={options}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            margin="dense"
                                            variant="outlined"
                                            error={!this.validateFields(selected)}
                                            label="Felettes"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <DateTimePicker
                                     minDate={lastValidFrom}
                                     error={!this.validateFields(validFrom)}
                                     required={true} 
                                     onChange={this.onDateChange} 
                                     name="validFrom" 
                                     value={validFrom} 
                                     label="Érvényes -től" 
                                />
                           </div>
                        </Grid>
                   </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!valid} 
                                    onClick={this.handleSave} 
                                    className={classes.doneButton} 
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
