//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    IconButton,
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    LinearProgress
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Components

import {
    LeadersTable,
    Pagination,
    RatingsTable,
    UsersDialog,
    UsersTable
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';


class ResultDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            total: this.props.users.length,
            currentPage: 1,
            step: 10,
            first: 0,
            last: 10,
            pages: Math.ceil(this.props.users.length / 10),
            selectedLeader: null,
            loading:{
                download:false
            }
        }
    }

    handlePageChanges = (page) => {
        const { step } = this.state;
        this.setState({
            currentPage: page,
            first: page === 1 ? page - 1 : ((page - 1) * step),
            last: page === 1 ? step : (page * step)
        });
    }

    handleClose = () => {
        this.setState({
            selectedLeader: null
        });
    }

    handleSelect = (id) => {
        this.setState({
            selectedLeader: id
        });
    }

    handleDownload = () => {
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: true,
            },
        }));
        switch (this.props.title) {
            case "Önértékelés":
                apiRequest.getSelfRatingResultExcel(this);
                break;
            case "Motiváció":
                apiRequest.getMotivationsResultExcel(this);
                break;
            case "Mátrixok":
                apiRequest.getMatrixResultExcel(this);
                break;
            case "Célkitűzések":
                apiRequest.getGoalResultExcel(this);
                break;
            case "Eredmények":
                apiRequest.getResultResultExcel(this);
                break;
            case "Értékelések":
                apiRequest.getRatingResultExcel(this);
                break;
            case "Vezetői értékelések":
                apiRequest.getLeaderRatingResultExcel(this);
                break;
            case "Beszélgetések":
                apiRequest.getSpeakResultExcel(this);
                break;
        }
    }

    
    handleSendMailByUser = (userId) => {
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                email: true,
            },
        }));
        switch (this.props.title) {
            case "Önértékelés":
                apiRequest.sendSelfRatingResultEmailByUser(this,userId);
                break;
            case "Motiváció":
                apiRequest.sendMotivationEmailByUser(this,userId);
                break;
            case "Mátrixok":
                apiRequest.sendMatrixResultEmailByUser(this,userId);
                break;
            case "Célkitűzések":
                apiRequest.sendGoalResultEmailByUser(this,userId);
                break;
            case "Eredmények":
                apiRequest.sendResultResultEmailByUser(this,userId);
                break;
            case "Értékelések":
                apiRequest.sendRatingResultEmailByUser(this,userId);
                break;
            case "Vezetői értékelések":
                apiRequest.sendLeaderRatingResultEmailByUser(this,userId);
                break;
            case "Beszélgetések":
                apiRequest.sendSpeakResultEmailByUser(this,userId);
                break;
        }
    }

    handleSendMailAll = () => {
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                email: true,
            },
        }));
        switch (this.props.title) {
            case "Önértékelés":
                apiRequest.sendSelfRatingResultEmailAll(this);
                break;
            case "Motiváció":
                apiRequest.sendMotivationEmailAll(this);
                break;
            case "Mátrixok":
                apiRequest.sendMatrixResultEmailAll(this);
                break;
            case "Célkitűzések":
                apiRequest.sendGoalResultEmailAll(this);
                break;
            case "Eredmények":
                apiRequest.sendResultResultEmailAll(this);
                break;
            case "Értékelések":
                apiRequest.sendRatingResultEmailAll(this);
                break;
            case "Vezetői értékelések":
                apiRequest.sendLeaderRatingResultEmailAll(this);
                break;
            case "Beszélgetések":
                apiRequest.sendSpeakResultEmailAll(this);
                break;
        }
    }

    render() {
        const { total, currentPage, step, selectedLeader, pages, first, last, loading } = this.state;
        const { classes, users, onClose, title } = this.props;

        return (
            <Drawer
                anchor={'bottom'}
                open={true}
                onClose={onClose}
            >
                <PerfectScrollbar>
                    <AppBar
                        className={classes.appBar}
                        position="static"
                    >
                        <Toolbar>
                            <Typography className={classes.title}>{title} - hiányosságok</Typography>
                            <div style={{ flexGrow: 1 }} />
                            <IconButton
                                className={classes.closeIcon}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {(loading.download || loading.email) && <LinearProgress />}
                    <div className={classes.root}>
                        <Box display="flex" alignItems="center" marginBottom="0.5rem">
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                endIcon={<MailOutlineIcon />}
                                style={{ marginRight: "0.5rem" }}
                                disabled={loading.download || loading.email}
                                onClick={this.handleSendMailAll}
                            >
                                Üzenet kikülése
                            </Button>
                            <Button
                                onClick={this.handleDownload}
                                color="primary"
                                size="small"
                                variant="contained"
                                endIcon={<GetAppIcon />}
                                disabled={loading.download || loading.email}
                            >
                                Exportálás
                            </Button>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                {(title === 'Önértékelés' || title === 'Motiváció' || title === 'Vezetői értékelések') &&
                                    <UsersTable
                                        users={users.slice(first, last)}
                                        onEmail={this.handleSendMailByUser}
                                    />
                                }
                                {/*title === 'Értékelések' &&
                                    <RatingsTable
                                        users={users.slice(first, last)}
                                        onEmail={this.handleSendMailByUser}
                                    />
                                */}
                                {(title === 'Mátrixok' || title === "Célkitűzések" || title === "Eredmények" || title === "Beszélgetések" || title === 'Értékelések') &&
                                    <LeadersTable
                                        onSelect={this.handleSelect}
                                        users={users.slice(first, last)}
                                        onEmail={this.handleSendMailByUser}
                                    />
                                }
                                <Pagination
                                    onPageChanges={this.handlePageChanges}
                                    currentPage={currentPage}
                                    step={step}
                                    total={total}
                                    pages={pages}
                                />
                            </Grid>
                        </Grid>
                        {selectedLeader !== null &&
                            <UsersDialog
                                selected={selectedLeader}
                                title={title}
                                open={true}
                                onClose={this.handleClose}
                            />
                        }
                    </div>
                </PerfectScrollbar>
            </Drawer>
        );
    }
};

export default withStyles(style)(ResultDialog);
