import theme from 'theme';

const useStyles = ({
    backdrop:{
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    period: {
        padding: theme.spacing(2),
        fontWeight: 'bold',
        fontSize: '1.6rem'
    }
});

export default useStyles;