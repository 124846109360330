import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            color:theme.palette.success.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.dark}`
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    },
    cancelButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    moveNextButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    }
}));
