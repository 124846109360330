import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Core
import {
  useMediaQuery,
} from '@material-ui/core';
//Components
import {
  Sidebar,
  Topbar,
  ScrollToTop,
  LogoutDialog,
} from './components';
import { PeriodIsNotActive } from 'components';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { apiRequest } from './apiRequest';
import { Constants, UserStore } from 'stores';
//Styles 
import { useTheme } from '@material-ui/styles';
import style from './style';


const TER = props => {
  const { children, snackbar } = props;
  const pathName = children.props.location.pathname;

  const classes = style();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openTopScroll, setOpenTopScroll] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [pages, setPages] = useState([]);
  const [loginBy, setLoginBy] = useState(UserStore.getLoggedByAdminValue());
  const [pagesLoading, setPagesLoading] = useState(true);
  const [stepIsActive, setStepIsActive] = useState(false);
  const [stepIsActiveLoading, setStepIsActiveLoading] = useState(true);
  const [profile, setProfile] = useState(pathName.includes('/admin/') ? 'admin' : 'user');

  useEffect(() => {
    setPagesLoading(true);
    Constants.setcontentLoading(true);
    UserStore.getProfile().subscribe(res => setProfile(res));
    UserStore.getLoggedByAdmin().subscribe(res => setLoginBy(res));
    if (pathName.includes('/admin/')) {
      UserStore.setProfile('admin');
    } else {
      UserStore.setProfile('user');
    }
  }, [])

  useEffect(() => {
    apiRequest.isActive(setStepIsActive, setStepIsActiveLoading, pathName);
  }, [children.props.location.pathname]);

  useEffect(() => {
    if (stepIsActiveLoading === false && pagesLoading === false) {
      Constants.setcontentLoading(false);
    }
    if (profile === "admin" && pagesLoading === false) {
      Constants.setcontentLoading(false);
    }
  }, [pagesLoading, stepIsActiveLoading, profile]);

  useEffect(() => {
    if (profile === "admin") {
      setPagesLoading(true);
      Constants.setcontentLoading(true);
      apiRequest.populateAdminMenuPoints(setPages, setPagesLoading)
    } else {
      setPagesLoading(true);
      Constants.setcontentLoading(true);
      apiRequest.populateMenuPoints(setPages, setPagesLoading);
      apiRequest.isActive(setStepIsActive, setStepIsActiveLoading, pathName);
    }
  }, [profile]);

  useEffect(() => {
    const history = Constants.getHistory();
    if (loginBy !== null) {
      setPagesLoading(true);
      Constants.setcontentLoading(true);
      apiRequest.populateLoginByMenupoints(setPages, setPagesLoading);
      history.push('/home');
    }
    else {
      if (profile === "admin") {
        setPagesLoading(true);
        Constants.setcontentLoading(true);
        apiRequest.populateAdminMenuPoints(setPages, setPagesLoading);
        history.push('/admin/home');
      } else {
        setPagesLoading(true);
        Constants.setcontentLoading(true);
        apiRequest.populateMenuPoints(setPages, setPagesLoading);
        apiRequest.isActive(setStepIsActive, setStepIsActiveLoading, pathName);
        history.push('/home');
      }
    }
  }, [loginBy]);


  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleOpenTopScroll = () => {
    setOpenTopScroll(true);
  };

  const handleCloseTopScroll = (container) => {
    if (container.scrollTop == 0) {
      setOpenTopScroll(false);
    }
  };

  const handleLogoutDialogOpen = () => {
    setOpenLogoutDialog(true);
  }

  const handleLogoutDialogClose = () => {
    setOpenLogoutDialog(false);
  }
  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  return (pagesLoading === false &&
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar
        profile={profile}
        openSidebar={openSidebar}
        onSidebarOpen={handleSidebarOpen}
        onLogoutDialogOpen={handleLogoutDialogOpen}
      />
      {pages !== null && pages !== undefined && pages.length > 0 &&
        <Sidebar
          pages={pages}
          snackbar={snackbar}
          pathName={pathName}
          onClose={handleSidebarClose}
          onLogoutDialogOpen={handleLogoutDialogOpen}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
      }
      <main className={classes.content}>
        <PerfectScrollbar
          onYReachEnd={container => handleCloseTopScroll(container)}
          onScrollY={handleOpenTopScroll}
          onYReachStart={handleCloseTopScroll}
        >
          <div id="to-up"></div>
          {stepIsActiveLoading === false &&
            (stepIsActive?.isActive ?
              React.cloneElement(children, { realIsActive: stepIsActive?.realIsActive }) :
              <PeriodIsNotActive />)
          }
        </PerfectScrollbar>
      </main>
      <ScrollToTop open={openTopScroll} />
      <LogoutDialog
        snackbar={snackbar}
        open={openLogoutDialog}
        closeHandler={handleLogoutDialogClose}
      />
    </div>
  );
};

TER.propTypes = {
  children: PropTypes.node
};

export default TER;
