//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';


const Actions = {
    GetUsersForSelect: 'admin/user/main-evaluators-of-users/edit/evaluators-for-list',
    SaveUser: 'admin/user/main-evaluators-of-users/edit/add-new-evaluator'
}

export const apiRequest = {
    populateOptions,
    saveUser
};

 async function populateOptions(parent) {

    await new Api().call.get(Actions.GetUsersForSelect)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: false,
            options: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: false
        }));
    });
}

async function saveUser(parent) {
    const {onSave, user, refresh} = parent.props;
    const {validFrom, validTo, selected} = parent.state;

    const model = { evaluatorId: selected.id, userId: user.id, validFrom, validTo };

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
   
    await new Api().call.post(Actions.SaveUser, model)
    .then(handleResponse)
    .then(res => {
        onSave();
        refresh();
    }).catch(handleError);
}


