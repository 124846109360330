
import {Api,handleResponse,handleError} from 'api';
import theme from 'theme';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetChartData: 'ratingForm/compare',
}

export const apiRequest = {
    populateChart
};

async function populateChart(parent) {

    var model = {period:parent.props.period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetChartData,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            data: {
                ...prevState.data,
                labels: res.competences.map(item => { return item.label }),
                datasets: [
                    {
                        label: 'Önértékelés',
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        lineTension:0,
                        data: res.resultsForSelf.map(item => { return item.value}),
                        fill: false,
                    },
                    {
                        label: 'Értékelés átlaga',
                        backgroundColor: theme.palette.neutral,
                        borderColor: theme.palette.neutral,
                        lineTension: 0,
                        data: res.resultsForSecondary.map(item => { return item.value }),
                        fill: false,
                    }
                ]
            },
            loading:false
        }));
    }).catch(handleError);
}