//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar,Progress  } from 'components';
//Actions

const Actions = {
    GetRatingPoints:'admin/rating/rating-points',
    GetGroups:'admin/rating/rating-points/groups',
    GetInputs:'admin/rating/rating-points/inputs',
    GetPeriods:'admin/rating/rating-points/periods',
    Delete:'admin/rating/rating-points/delete',
    Save:'admin/rating/rating-points/edit/save'
}

export const apiRequest = {
    populateRatingPoints,
    populateCompGroups,
    populateInputs,
    populatePeriods,
    deletePoint,
    saveChanges
};

async function populateRatingPoints(parent,period) {
    await new Api().call.get(`${Actions.GetRatingPoints}/${period}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            ratingPoints:res,
            loading:false
        });
    }).catch(handleError);
}

async function populateCompGroups(parent) {
    await new Api().call.get(Actions.GetGroups)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            compGroups:res
        });
    }).catch(handleError);
}

async function populateInputs(parent) {
    await new Api().call.get(Actions.GetInputs)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            inputs:res
        });
    }).catch(handleError);
}

async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            periods:res
        });
    }).catch(handleError);
}

async function deletePoint(parent,model,period)
{
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            deleted:null
        });
        populateRatingPoints(parent,period);
    }).catch(handleError);
}

async function saveChanges(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        populateRatingPoints(parent,period);
    }).catch(handleError);
}


