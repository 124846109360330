
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetSelector: 'ratingForm/own-selector-list',
    GetCompetences: 'ratingForm/self/competences',
    GetResult: 'ratingForm/result/competences',
    GetLeader: 'ratingForm/leader',
    GetPeriods: 'ratingForm/periods',
}

export const apiRequest = {
    populateSelector,
    populateLeader,
    populatePeriods,
    populateResult,
    populateCompetences
};

async function populateSelector(parent) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetSelector,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                selector: false,
            },
            selector: res
        }));
    }).catch(handleError);

}

async function populateLeader(parent) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetLeader,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                    leader: res,
                    loading: {
                        ...prevState.loading,
                        leaderBox: false
                    }
                }));
            }).catch(handleError);

}

async function populatePeriods(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetPeriods,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    periods: false,
                },
                periods: res,
                selectedPeriod: (res.length > 0 && res[0].disabled === false) ? res[0].id : null,
                periodIsActive: res.length > 0 ? res[0].isActive : null,

            }));
            if (((res.length > 0 && res[0].disabled === false) ? res[0].id : null) === null) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        selector: false,
                        conversation: false,
                        result: false,
                        competences: false,
                        leaderBox: false,
                    },
                }));
            }
        }).catch(handleError);
  
}

//Populate datas
async function populateCompetences(parent) {
    const { selectedPeriod } = parent.state;
    
    var model = {period:selectedPeriod};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetCompetences,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    competences: res,
                },
                loading: {
                    ...prevState.loading,
                    competences: false
                }
            }));
   }).catch(handleError);

}

//Populate datas
async function populateResult(parent) {
    const { selectedPeriod } = parent.state;

    var model = {period:selectedPeriod};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetResult,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    result: res.competencesMainGroups,
                    avg: res.avg
                },
                loading: {
                    ...prevState.loading,
                    result: false
                }
            }));
   }).catch(handleError);


}