import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
//Components
import clsx from 'clsx';
import PropTypes from 'prop-types';
import data from './data';
//Core
import { List, ListItem, Button, Menu, MenuItem } from '@material-ui/core';
//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//Style
import style from './style';



const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const { pages, pathName, className, ...rest } = props;
    const classes = style();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [anchorIndex, setAnchorIndex] = React.useState(null);

    const handleClick = (event, index,pageId) => {
        setAnchorEl(event.currentTarget);
        setAnchorIndex(index);
        

    };

    const handleClose = () => {
        setAnchorIndex(null);
        setAnchorEl(null);
    };
    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            {pages.map((page, index) => (
                <ListItem
                    className={classes.item}
                    disableGutters
                    key={page.id}
                >
                    {(page.submenus != null && page.submenus.length>0) ?
                        <Button
                            disabled={page.disabled}
                                className={pathName.includes(page.href) ? classes.buttonActive : classes.button}
                                onClick={event => handleClick(event, index,page.id)}
                        >
                            <div className={classes.icon}>{data.icons.find(item => page.href === item.href) != null
                                && data.icons.find(item => page.href === item.href) !== undefined
                                && data.icons.find(item => page.href === item.href).icon}</div>
                            {page.label}
                            <div className={classes.menuIcon}>{anchorIndex==index ? <NavigateNextIcon /> : <ExpandMoreIcon />}</div>
                            </Button>

                        : <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            disabled={page.disabled}
                            component={CustomRouterLink}
                            to={page.href}
                            name={page.id}
                            id={page.href}
                        >
                            <div className={classes.icon}>{data.icons.find(item => page.href === item.href) != null
                                && data.icons.find(item => page.href === item.href) !== undefined
                                && data.icons.find(item => page.href === item.href).icon}</div>
                            {page.label}
                        </Button>}

                </ListItem>
            ))}
            {anchorIndex!=null &&
                <Menu
                    className={classes.subMenu}
                    id='submenu'
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
            >
                {pages[anchorIndex].submenus.map(submenu => (
                    <MenuItem key={submenu.id} disabled={submenu.disabled} component={CustomRouterLink}
                            to={submenu.href} onClick={handleClose} activeClassName={classes.active}>{submenu.label}</MenuItem>
                    ))}
                </Menu>
            }
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
