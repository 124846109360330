//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            label: '',
            description: '',
            color:'#008AAB',
            valid:false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {label, description, color} = this.state;

        if (prevState.label != label || prevState.description != description || prevState.color!= color) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    validateFields = (value) =>{
        if(value === null || value === undefined || value === ''){
            return false;
        }
        return true;
    }


    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });       
    }

    handleSave = () => {
        const {label, description, color} = this.state;

        const model = { label, description,color };
        apiRequest.saveChanges(this,model);
    }
    
    render() {
        const {onClose, open, classes} = this.props;
        const {color, label, valid, description} = this.state;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Új kompetencia létrehozása
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                        <Grid spacing={2} container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    required
                                    error={!this.validateFields(label)}
                                    helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                    onChange={this.onChange} 
                                    fullWidth
                                    name="label"
                                    value={label}
                                    label="Megnevezés"
                                />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    required
                                    error={!this.validateFields(description)}
                                    helperText={!this.validateFields(description) && 'Kötelező megadni'}
                                    label="Leírás"
                                    name="description"
                                    value={description}
                                    onChange={this.onChange} 
                                    fullWidth
                                    multiline
                                />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <b>Szín kiválasztása:</b>
                                <input                            
                                    onChange={this.onChange} 
                                    style={{margin:'0.5rem'}} 
                                    type="color" 
                                    id="color" 
                                    name="color"
                                    value={color}>                            
                                </input>
                            </Grid>                                                
                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
