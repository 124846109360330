import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.appMain.main
    },
    empty: {
        textAlign:'center'
    }
}));
