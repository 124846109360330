import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.appMain.main
    },
    root:{},
    skipButton: {
        color:'white',
        fontSize: '0.8125rem',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color:theme.palette.warning.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.warning.dark}`
        },
        '&:disabled': {
        backgroundColor: theme.palette.secondary.light,
        color:theme.palette.secondary.dark
        }
    },
    typography: {
        padding: theme.spacing(2),
    },
}));
