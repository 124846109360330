//React

import React from 'react';
//Components

import { Component } from 'react';
import {CompTransfer} from '../index';
//Core

import {  
    IconButton,
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    Button,
    FormControl,
    Select,
    InputLabel,
    MenuItem, 
    LinearProgress 
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
//Helpers

import { enums } from 'stores';


const CompetenceGroups = enums.competenceGroups;

class CompDrawer extends Component {

    constructor(props){
        super(props);
        this.state={
            period:'',
            periods:[],
            left:[],
            right:[],
            rightQuantity:null,
            right_2Quantity:null,
            right_3Quantity:null,
            right_2:[],
            right_3:[],
            loading:true,
            model:null,
            isValid:false,
            request:false
        };
    }

    componentDidMount(){
        apiRequest.populatePeriods(this);
    }

    componentDidUpdate(prevProps,prevState){
        const {period} = this.state;
        const {position} = this.props;

        if(prevState.period!=period){
            this.setState({
                loading:true
            });
            apiRequest.populateCompetences(this,period.id,position.id);
        }
    }

    handleChange = (event) =>{
        const value = event.target.value;
        this.setState({
            period:value
        });
    }

    setModel = (model,isValid) =>{
        this.setState({
            model,
            isValid
        });
    }

    handleClick = () =>{
        const {model,period} = this.state;     
        const {position} = this.props;

        let items = [];
        model.general.map(item=>items.push({id:item.id,compGroup:CompetenceGroups.ALTALANOS}));
        model.key.map(item=>items.push({id:item.id,compGroup:CompetenceGroups.KULCS}));
        model.leader.map(item=>items.push({id:item.id,compGroup:CompetenceGroups.VEZETOI}));
        const extendedModel={
            items,
            period:period.id,
            position:position.id
        }
        this.setState({
            request:true
        });
        apiRequest.saveChanges(this,extendedModel);
    }

    render(){
        const {classes,onClose} = this.props;
        const {period,periods,left,right,right_2,right_3,loading,rightQuantity,right_2Quantity,right_3Quantity,isValid} = this.state;

        return (
            <Drawer  anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">                    
                    <Toolbar>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="period-select-label">Periódusok</InputLabel>
                        <Select
                            labelId="period-select-label"
                            id="period-select"
                            value={period}
                            onChange={this.handleChange}
                        >
                            {periods.map(period=>
                            <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                        <div style={{flexGrow:1}}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {period!=='' && loading===true && <LinearProgress/>}                
                <div className={classes.root}>  
                    {period=='' && <Typography className={classes.info}>Nincs kiválasztva periódus</Typography>}                  
                    {period!=='' && loading===false && 
                    <CompTransfer
                        period={period}
                        p_left={left} 
                        p_right={right} 
                        p_right_2={right_2} 
                        p_right_3={right_3} 
                        rightQuantity={rightQuantity}
                        right_2Quantity={right_2Quantity}
                        right_3Quantity={right_3Quantity}
                        setModel={this.setModel}
                    />
                    }
                    {(period!==''  && period.disabled!==true) && <div className={classes.footer}>
                        <Button
                         endIcon={<SaveIcon/>}
                         onClick={this.handleClick}
                         className={classes.saveButton}
                         size="small"
                         disabled={!isValid}
                        >
                            Mentés
                        </Button>
                    </div>}
                </div>
            </Drawer>
        );
    }
}

export default withStyles(style)(CompDrawer);