//React

import React from 'react';
//Core

import {
    Tooltip,
    IconButton,
    Divider
} from '@material-ui/core';
//Icons

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//Components

import { MuiOutlinedInput } from 'components';
//Style

import style from './style';


export default function SetPercent(props) {
    const { handleChange, value, maxPercent, selectedClass } = props;

    const handleIncrease = () => {
        const newValue = (value * 1) + 1;
        if (newValue <= maxPercent) {
            handleChange(newValue);  
        }            
    }

    const handleDecrease = () => {
        const newValue = (value*1) - 1;
        if (!(newValue < 0)) {
            handleChange(newValue);
        }
    }
    const validate = () =>{
        let valid = false;
        if (value > 0 && value <= maxPercent && value % 1 === 0) {
            valid = true;
        }
        return valid;
    }
    const classes = style();
    
    return (
        <div className={classes.root}>
            <MuiOutlinedInput 
                margin="dense" 
                minValue={0} 
                disabled={selectedClass.defaultValue != null} 
                value={value} 
                handleChange={(event, newValue) => handleChange(event.target.value)} 
                helperText="Nem megengedett érték" 
                inpuId="tf-percent" 
                maxValue={maxPercent}
                inputLabel="Százalék" 
                type="number" 
                error={validate() === false ? true : false} 
            />
            <div className={classes.buttons}>
                <Tooltip title="Érték növelése">
                    <span>
                        <IconButton 
                            onClick={handleIncrease} 
                            disabled={selectedClass.defaultValue != null} 
                            className={classes.plusButton} 
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </span>
                </Tooltip >
                <Divider  className={classes.divider} orientation="vertical" />
                <Tooltip title="Érték csökkentése">
                    <span>
                        <IconButton 
                            onClick={handleDecrease} 
                            disabled={selectedClass.defaultValue != null} 
                            className={classes.minusButton}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </span>                 
                </Tooltip >
            </div>
        </div>
    );
}
