import theme from 'theme';


const useStyles = ({
  root: {
    padding: theme.spacing(3)
  },
  tabRoot:{
      flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
  circularProgress: {
      padding: theme.spacing(4),
      textAlign: 'center'
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    padding: theme.spacing(2)
  },
  greenButton:{
    color:'white',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.success.light,
    border:`1px solid ${theme.palette.success.light}`,
    '&:hover': {
        color:theme.palette.success.main,
        backgroundColor: 'white',
    },
    '&:disabled': {
        backgroundColor: theme.palette.secondary.light,
        color:theme.palette.secondary.dark
    },
  },
  primaryButton:{
    color:'white',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.primary.main,
    border:`1px solid ${theme.palette.primary.main}`,
    '&:hover': {
        color:theme.palette.primary.main,
        backgroundColor: 'white',  
    },
    '&:disabled': {
        backgroundColor: theme.palette.secondary.light,
        color:theme.palette.secondary.dark
    },
  }
});

export default useStyles;