//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetSteps: 'gui/time-line-steps'
}

export const apiRequest = {
    populateSteps,
};


async function populateSteps(setSteps,setLoading) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetSteps, model)
        .then(handleResponse)
        .then(res => {
            setSteps(res);
            setLoading(false);
        }).catch(handleError);
}

