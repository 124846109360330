import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
//Components
import {AddPicture} from '../';
import {apiRequest} from './apiRequest';
//Core
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  Divider,
  withStyles
} from '@material-ui/core';
//Styles
import style from './style';

class AccountProfile extends Component {

    constructor(props){
        super(props);
        this.state={
            open:false,
            request:false
        }
    }

    handleUpdate = () =>{
        this.setState({
            open:true
        });
    }

    handleClose = () =>{
        this.setState({
            open:false
        });
    }

    handleRemove = () =>{
        const {user} = this.props;
        this.setState({
            request:true
        });
        apiRequest.remove(this);
    }
    
    render() {
        const { className,classes,user,time, ...rest } = this.props;
        const {open,request} = this.state;
        return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            {open && <AddPicture user={user} open={open} onClose = {this.handleClose}/>}
            <CardContent>
                <div className={classes.details}>
                    <div>
                        <Typography
                            gutterBottom
                            variant="h2"
                        >
                                {user.fullName}
                        </Typography>
                        <Typography
                            className={classes.locationText}
                            color="textSecondary"
                            variant="body1"
                        >
                            {user.org}, {user.bio}
                        </Typography>
                        <Typography
                            className={classes.dateText}
                            color="textSecondary"
                            variant="body1"
                            >
                                {time.toLocaleTimeString()}
                        </Typography>
                    </div>
                    <Avatar
                        className={classes.avatar}
                        src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                    />
                </div>
            </CardContent>
            <Divider />
            <CardActions>
                <div style={{display:'flex',alignItems:'center'}}>
                    <Typography style={{fontWeight:'bold'}}>Profilkép módosítás:</Typography>
                    <IconButton disabled={request} onClick={this.handleUpdate} className={classes.editIcon}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={request} onClick={this.handleRemove} className={classes.deleteIcon}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </CardActions>
        </Card>
  );
    }
    
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default withStyles(style)(AccountProfile);
