//React

import React,{ Component } from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    DialogTitle,
    Divider,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
    Tooltip,
    IconButton
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Api

import { apiRequest } from './apiRequest';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/styles';


class ConfirmDialog extends Component {

    constructor(props){
        super(props);
        this.state={
            additional:'0',
            label:'',
            input:'0',
            valid:false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {additional, label, input} = this.state;

        if (prevState.additional != additional || prevState.label != label || prevState.input!= input) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
      
        this.setState({
            [name]:value
        });
    }

    handleSave = () =>{
        const {additional, label, input} = this.state;
        
        const {period, parentId} = this.props;

        const model = {parent:parentId, period:period.id,label, additional:additional === '0' ? null : additional.id, input:input === '0' ? null : input.id}
        apiRequest.saveChanges(this,model);
    }

    validateFields = (value) =>{
        if(value === null || value === undefined || value === ''){
            return false;
        }
        return true;
    }

    render(){
        const {open, onClose, additionals, classes, inputs, level} = this.props;
        const {additional, label, input, valid} = this.state;

        return(
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Új pont hozzáadása
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider/>
                <DialogContent id="add-new-form">
                {level === 0 &&
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                required
                                error={!this.validateFields(label)}
                                helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                label="Pont megnevezés"
                                fullWidth
                                value={label}
                                name="label"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} item>
                            <FormControl fullWidth  className={classes.formControl}>
                            <InputLabel id="additional-select-label">
                                    Kiegészítések
                            </InputLabel>
                                <Select
                                    name="additional"
                                    labelId="additional-select-label"
                                    id="additional-select"
                                    value={additional}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value='0'>
                                        Nincs kiválasztva
                                    </MenuItem>
                                    {additionals.map(item=>
                                    <MenuItem key={item.id} value={item}>
                                        {item.label}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                {level > 0 && <Grid container spacing={2}>
                        <Grid xs={12} sm={12} item>
                            <TextField
                                required
                                error={!this.validateFields(label)}
                                helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                label="Pont megnevezés"
                                fullWidth
                                value={label}
                                name="label"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={12} item>
                            <FormControl fullWidth  className={classes.formControl}>
                            <InputLabel id="input-select-label">
                                    Értékek
                            </InputLabel>
                                <Select
                                    name="input"
                                    labelId="input-select-label"
                                    id="input-select"
                                    value={input}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value='0'>
                                        Nincs kiválasztva
                                    </MenuItem>
                                    {inputs.map(item=>
                                    <MenuItem key={item.id} value={item}>
                                        {item.label}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }           
                </DialogContent>
                <DialogActions>
                    <div>
                        <Tooltip title="Mentés">
                        <span>
                            <IconButton 
                                disabled={!valid} 
                                onClick={this.handleSave} 
                                className={classes.doneButton} 
                                aria-label="done"
                            >
                                <DoneIcon />
                            </IconButton>
                        </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>                       
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(style)(ConfirmDialog);
