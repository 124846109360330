//React

import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core

import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  TextField,
  CardActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PollIcon from '@material-ui/icons/Poll';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';


const StyledTableRow=style.StyledTableRow;

const BiosTable = props => {
    const {className, bios, edit, selectedBios, loading, onEdit, onDone, onOpen, onComp, onRole, onCancel, onChange, onSave, onDelete, onSelect, ...rest} = props;

  const classes = style.useStyles();

  const handleSelectAll = event => {

      let selectedBios;

    if (event.target.checked) {
        selectedBios = bios.map(bio => bio.id);
    } else {
        selectedBios = [];
    }
      onSelect(selectedBios);
  };

  const handleSelectOne = (event, id) => {
      const selectedIndex = selectedBios.indexOf(id);
    let newSelectedBios = [];

    if (selectedIndex === -1) {
        newSelectedBios = newSelectedBios.concat(selectedBios, id);
    } else if (selectedIndex === 0) {
        newSelectedBios = newSelectedBios.concat(selectedBios.slice(1));
    } else if (selectedIndex === selectedBios.length - 1) {
        newSelectedBios = newSelectedBios.concat(selectedBios.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelectedBios = newSelectedBios.concat(
            selectedBios.slice(0, selectedIndex),
            selectedBios.slice(selectedIndex + 1)
      );
    }

      onSelect(newSelectedBios);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedBios.length === bios.length}
                      color="primary"
                      indeterminate={
                          selectedBios.length > 0 &&
                          selectedBios.length < bios.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell align="center"  padding="none">Azonosító</TableCell>
                  <TableCell align="center"  padding="none">Megnevezés</TableCell>
                  <TableCell align="center" padding="none">Leírás</TableCell>
                  <TableCell align="center" padding="none">Cégcsoport</TableCell>
                  <TableCell align="center" padding="none">Aktív</TableCell>
                  <TableCell padding="none" align="center">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {bios.map((bio,index) => (
                <StyledTableRow
                  className={classes.tableRow}
                  hover
                  key={bio.id}
                  selected={selectedBios.indexOf(bio.id) !== -1}
                >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedBios.indexOf(bio.id) !== -1}
                    color="primary"
                    onChange={event => handleSelectOne(event, bio.id)}
                    value="true"
                  />
                </TableCell>
                <TableCell padding="none" align="center" >
                      {bio.id}
                  </TableCell>
                  <TableCell padding="none" align="center" >
                    {(edit != null && edit.id == bio.id) && 
                      <TextField 
                          helperText={!(bio.name != null && bio.name != undefined && bio.name != '') ? 'Nem megengedett érték' : ''} 
                          error={!(bio.name != null && bio.name != undefined && bio.name != '') ? true : false} 
                          onChange={(event) => onChange(event, index,'name')} 
                          value={bio.name} 
                      />
                      }
                    {(edit == null || (edit != null && edit.id != bio.id)) && bio.name}
                  </TableCell>
                  <TableCell padding="none" align="center" >
                    {(edit != null && edit.id == bio.id) && 
                    <TextField 
                      onChange={(event) => onChange(event, index,'description')} 
                      value={(bio.description===undefined || bio.description===null) ? '' : bio.description} 
                    />
                    }
                    {(edit == null || (edit != null && edit.id != bio.id)) && bio.description}
                  </TableCell>
                  <TableCell padding="none" align="center" >
                      {bio.company}
                  </TableCell>
                  <TableCell padding="none" align="center" >
                      {bio.isValid==true ? 'Igen' : 'Nem'}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {(edit == null || (edit != null && edit.id != bio.id)) &&
                      <React.Fragment> 
                        {bio.isValid == true ? 
                          <Tooltip title="Munkakör tiltása">
                            <IconButton
                                disabled={(edit != null ? true : false)}  
                                onClick={() => onDelete(index)} 
                                className={classes.doneButton} 
                                aria-label="done"
                            >
                              <DoneIcon />
                            </IconButton>
                          </Tooltip> : 
                          <Tooltip title="Munkakör feloldása">
                              <IconButton
                                disabled={(edit != null ? true : false)}  
                                onClick={() => onDone(index)} 
                                className={classes.blockButton} 
                                aria-label="block"
                              >
                                  <BlockIcon /> 
                              </IconButton>
                          </Tooltip>
                        }
                        <Tooltip title="Módosítás">
                          <span>
                              <IconButton 
                                disabled={(edit != null ? true : false)} 
                                onClick={() => onEdit(bio)} 
                                className={classes.editButton} 
                                aria-label="edit"
                              >
                                <EditIcon />
                              </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Kompetenciák">
                          <span>
                              <IconButton 
                                disabled={(edit != null ? true : false)} 
                                onClick={() => onComp(bio)} 
                                className={classes.compButton} 
                                aria-label="competences"
                              >
                                <PollIcon />
                              </IconButton>
                          </span>
                        </Tooltip>  
                        <Tooltip title="Jogosultságok">
                          <span>
                              <IconButton 
                                disabled={(edit != null ? true : false)} 
                                onClick={() => onRole(bio)} 
                                className={classes.roleButton} 
                                aria-label="roles"
                              >
                                <AccountTreeIcon />
                              </IconButton>
                          </span>
                        </Tooltip>   
                      </React.Fragment>
                    }
                    {(edit != null && edit.id == bio.id) &&
                      <React.Fragment> 
                        <Tooltip title="Mentés">
                          <span>
                            <IconButton 
                              disabled={!(bio.name != null && bio.name != undefined && bio.name != '') ? true : false} 
                              onClick={()=>onSave(index)} 
                              className={classes.saveButton} 
                              aria-label="save"
                            >
                              <DoneIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                          <IconButton 
                            onClick={()=>onCancel(index)} 
                            className={classes.cancelButton} 
                            aria-label="cancel"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip> 
                      </React.Fragment> 
                    }
                  </TableCell>
                </StyledTableRow>))
                }
                {bios.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="7">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        </CardContent>
        <CardActions>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
            <Typography style={{ fontWeight: 'bold' }}>Új munkakör létrehozása: </Typography>
            <Tooltip title="Új hozzáadás">
                <span>
                  <IconButton 
                    disabled={edit!=null ? true : false} 
                    onClick={onOpen} 
                    className={classes.addButton} 
                    aria-label="add"
                  >
                    <AddIcon />
                  </IconButton>
                </span>
            </Tooltip>
          </div>
        </CardActions>
    </Card>
  );
};

BiosTable.propTypes = {
  className: PropTypes.string,
  bios: PropTypes.array.isRequired
};

export default BiosTable;
