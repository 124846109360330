//React

import React, { Component } from 'react';
//Core

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import { GoalsGrid } from './components';
import BackspaceIcon from '@material-ui/icons/Backspace';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


class GoalDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open != this.props.open) {
      if (this.props.open) {

        this.setState({
          loading: true,
          data: []
        });
        apiRequest.populateGoals(this);

      } else {
        this.setState(prevState => ({
          ...prevState,
          loading: false
        }));
      }

    }
  }

  render() {
    const { onClose, open, classes } = this.props;
    const { loading, data } = this.state;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>
            Célkitűzések
          </DialogTitle>
          <PerfectScrollbar>
            <DialogContent>
              {loading === true &&
                <div style={{ textAlign: 'center', padding: '1.0rem' }}>
                  <CircularProgress color="primary" />
                </div>
              }
              {loading === false && data.map((item, index) =>
                <React.Fragment key={index}>
                  <Typography style={{ fontFamily: 'nunito', fontWeight: 'bold', fontSize: '1.0rem', paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}>
                    {item.goalType.label}
                  </Typography>
                  <GoalsGrid
                    key={item.goalType.id}
                    data={item.goalsForTable}
                    goalType={item.goalType}
                    goalTypeMatrix={item.goalType}
                  />
                </React.Fragment>
              )}
            </DialogContent>
          </PerfectScrollbar>
          <DialogActions>
            <Button
              endIcon={<BackspaceIcon />}
              className={classes.secondaryButton}
              size="small"
              onClick={onClose}
              variant="outlined"
              color="secondary"
            >
              Vissza
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(style)(GoalDialog);