//React

import React, { Component } from 'react';
//Core

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  TableCell,
  Table,
  TableHead,
  TableBody,
  LinearProgress
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Api

import { apiRequest } from './apiRequest';
//Styles

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import BackspaceIcon from '@material-ui/icons/Backspace';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
//Helpers

import { enums } from 'stores'
//Components

import {
  FormDialog,
  GoalDialog
} from 'views/INTERVIEW/HR/components';


const StyledTableRow = style.StyledTableRow;

const InterViewsTypes = enums.interViewsTypes;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CheckListDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      selected: null,
      type: null,
      readOnly: true,
      goalDialog: null
    }

  }

  componentDidMount() {
    apiRequest.populateList(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected, type } = this.state;

    if (prevState.type !== type) {
      if (type === "mounth-1") {
        apiRequest.downloadFirst(this, selected);
      } else if (type === "mounth-3") {
        apiRequest.downloadThird(this, selected);
      } else if (type === "downloadAll") {
        apiRequest.downloadAll(this, selected);
      }
    }

  }

  isDisabled = (type) => {
    if (type === InterViewsTypes.NOTAVAILABLE) {
      return true;
    }
    return false;
  }

  isReadOnly = (type) => {
    return false;
  }

  setClass = (type) => {
    const { classes } = this.props;

    if (type === InterViewsTypes.AVAILABLE) {
      return classes.mainButton;
    } else if (type === InterViewsTypes.WARNING) {
      return classes.yellowButton;
    } else if (type === InterViewsTypes.LATE) {
      return classes.redButton;
    } else if (type === InterViewsTypes.DONE) {
      return classes.greenButton;
    }
    return classes.mainButton
  }


  handleSelect = (value, type, readOnly) => {
    this.setState({
      selected: value,
      type,
      readOnly
    });
  }

  handleGoals = (selected) => {
    this.setState(prevState => ({
      ...prevState,
      goalDialog: selected
    }));
  }

  render() {
    const { onClose, open, classes } = this.props;
    const { loading, data, type, selected, goalDialog } = this.state;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>Próbaidő teendők</DialogTitle>
          {loading && <LinearProgress />}
          <DialogContent>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>Felettes</TableCell>
                  <TableCell>Belépés dátuma</TableCell>
                  <TableCell>Próbaidő vége</TableCell>
                  <TableCell align="center" width="30%">Műveletek</TableCell>
                  <TableCell align="center">Ellenőrzés</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data?.map(user => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user?.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user?.fullName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user?.bio}</TableCell>
                    <TableCell>
                      {user?.organization}
                    </TableCell>
                    <TableCell>
                      {(user?.leader != null && user?.leader != undefined) && user?.leader.fullName}
                    </TableCell>
                    <TableCell>
                      {user?.hireDate}
                    </TableCell>
                    <TableCell>
                      {user?.endDate}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: 'flex', alignItems: 'center', gap: "0.5rem", justifyContent: 'center' }}>
                        <Button
                          disabled={this.isDisabled(user?.firstInterView?.type) || type === "mounth-1" || type === "mounth-3"}
                          className={this.setClass(user?.firstInterView?.type)}
                          onClick={() => this.handleSelect(user, 'mounth-1', this.isReadOnly(user?.firstInterView?.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<GetAppIcon />}
                          color="primary"
                        >
                          1. hónap
                        </Button>
                        <Button
                          disabled={this.isDisabled(user?.secondInterView?.type) || type === "mounth-1" || type === "mounth-3"}
                          className={this.setClass(user?.secondInterView?.type)}
                          onClick={() => this.handleSelect(user, 'mounth-2', this.isReadOnly(user?.secondInterView?.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<ListAltIcon fontSize='small' />}
                          color="primary"
                        >
                          2. hónap
                        </Button>
                        <Button
                          disabled={this.isDisabled(user?.thirdInterView?.type) || type === "mounth-1" || type === "mounth-3"}
                          className={this.setClass(user?.thirdInterView?.type)}
                          onClick={() => this.handleSelect(user, 'mounth-3', this.isReadOnly(user?.thirdInterView?.type))}
                          size="small"
                          variant="outlined"
                          endIcon={<GetAppIcon fontSize='small' />}
                          color="primary"
                        >
                          2.5. hónap
                        </Button>
                        {user?.allIsDone === true &&
                          <Button
                            disabled={type === "mounth-1" || type === "mounth-3"}
                            className={classes.greenButton}
                            onClick={() => this.onSelect(user, 'downloadAll', this.isReadOnly(user?.thirdInterView?.type))}
                            size="small"
                            variant="outlined"
                            endIcon={<GetAppIcon fontSize='small' />}
                            color="primary"
                          >
                            Letöltés
                          </Button>
                        }
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        disabled={type === "mounth-1" || type === "mounth-3"}
                        className={!user?.haveGoals ? classes.redButton : classes.greenButton}
                        onClick={user?.haveGoals ? () => this.handleGoals(user) : null}
                        size="small"
                        variant="outlined"
                        endIcon={<FormatListBulletedIcon fontSize='small' />}
                        color="primary"
                      >
                        Célkitűzések
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
                {data.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="9">
                      {loading ? 'Betöltés...' : 'Nincs elérhető adat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
            {(selected != null && type === 'mounth-2') &&
              <FormDialog
                readOnly={this.readOnly}
                selected={selected}
                open={selected != null && type === 'mounth-2' ? true : false}
                onClose={() => this.handleSelect(null, null)}
              />
            }
            <GoalDialog
              selected={goalDialog}
              open={goalDialog != null}
              onClose={() => this.setState({ goalDialog: null })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              endIcon={<BackspaceIcon />}
              className={classes.secondaryButton}
              size="small"
              onClick={onClose}
              variant="outlined"
              color="secondary"
            >
              Vissza
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style.useStyles)(CheckListDialog);