import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    skipIcon:{
        color:theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
        }
    },
    unSkipIcon:{
        color:theme.palette.success.main,
        '&:hover': {
            color:theme.palette.success.dark,
        }
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.appMain.main
    }
}));
