import React, { Component } from 'react';
//Core
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    Typography,
    Zoom
} from '@material-ui/core';
//Style
import style from './style';





export default function RatingCard(props) {
    const { title, competences } = props;

    const classes = style.useStyles();
  const StyledTooltip = style.StyledTooltip;
    return (<Card>
        <CardHeader  className={classes.headerTitle} title={title}>
        </CardHeader>
        <Divider />
        <CardContent > 
            {competences.map(competence => (
                <Grid
                    container
                    key={competence.id}
                    spacing={1}
                >
                    <Grid
                        item
                        md={10}
                        sm={10}
                        xs={12}
                    >
                        <StyledTooltip TransitionComponent={Zoom} title={competence.description}>
                            <Typography className={classes.compName}>{competence.label}</Typography>
                        </StyledTooltip>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        sm={2}
                        xs={12}
                    >
                        {/* <RatingLineHeader /> */}
                        <Typography style={{fontWeight:'bold',fontSize:'1.0rem'}}>{competence.value}</Typography>
                    </Grid>
                </Grid>
            ))}
        </CardContent>
        <Divider />
    </Card>)
}
