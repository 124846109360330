//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    Save: 'admin/masterData/organizations-list/add-new',
    GetCompanies: 'admin/masterData/filter/companies'
}

export const apiRequest = {
    saveChanges,
    getCompanies
};

//#Api calls
async function getCompanies(parent) {

    await new Api().call.get(Actions.GetCompanies)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            loading: false,
            companies: res
        });
    }).catch(error => {
        handleError(error);
        parent.setState({
            loading: false
        });
    });
}

async function saveChanges(parent, model) {
    const { onClose,refresh } = parent.props;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            refresh();
            onClose();
        }).catch(handleError);
}

