//React

import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
//Core

import { 
    Grid, 
    Typography, 
    Button 
} from '@material-ui/core';
//Components

import { Constants } from 'stores';
//Icons

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Style

import style from './style';


const PermissionDenied = (props) => {
    const classes = style();


    useEffect(() => {
        Constants.setcontentLoading(false);
    }, []);

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.content}>
                        <img
                            alt="403"
                            className={classes.image}
                            src="/images/403.png"
                        />
                        <Typography variant="h1" className={classes.contentTitle}>
                            403
                        </Typography>
                        <Typography variant="h1" className={classes.contentTitle}>
                            Nincs jogosultságod a megtekintéshez
                        </Typography>
                        <Typography className={classes.contentSubTitle} variant="subtitle2">
                        </Typography>
                        <Button
                            className={classes.buttons}
                            startIcon={<ArrowBackIcon />}
                            color="primary"
                            component={RouterLink}
                            to="/"
                            variant="contained"
                        >
                            Kezdőlap
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PermissionDenied;
