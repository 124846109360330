import React from 'react';
import { Component } from 'react';
//Core
import { CircularProgress, Grid, Typography,Divider } from '@material-ui/core';
//Components
import {EmailGroupsTable} from './components';
import { apiRequest } from './apiRequest';
//Style
import { withStyles } from '@material-ui/styles';
import style from './style';

class Admin extends Component{

    constructor(props){
        super(props)

        this.state={
            compGroups:[]
        };
    }

    render(){
        const {classes} = this.props;
        const  {compGroups} = this.state;
        return(<div className={classes.root}>
            <Typography className={classes.title}>Email beállítások</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container spacing={2}>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <Typography className={classes.subTitle}>Csoportok</Typography>
                        <EmailGroupsTable/>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                      
                    </Grid>                
                </Grid>
            </div>)
    }
}

export default withStyles(style)(Admin);