import theme from 'theme';

const useStyles = () => ({
    root: {
        width: '100%',
    },
    gridRoot: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    doneButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        }
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
    dividerBottom: {
        marginBottom: theme.spacing(2)
    }
});

export default useStyles;
