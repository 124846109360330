import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    head: {
      '& > *': {
          fontSize: '0.9rem',
          fontWeight: 'bold'
      }
  },
  editIcon:{
    color:theme.palette.warning.main
  },
  saveButton:{
    color: theme.palette.success.main
  },
  cancelButton: {
    color: theme.palette.error.main
  },
  addButton:{
    color:theme.palette.success.main
  },
  deleteButton:{
    color:theme.palette.error.main
  },
  deleted: {
    backgroundColor:'#ffffba'
  },
  playIcon:{
    color:theme.palette.success.main
  },
  pauseIcon:{
    color:theme.palette.warning.main
  }
  });
  
  const useStyles = ({
    head: {
      '& > *': {
          fontSize: '0.9rem',
          fontWeight: 'bold'
      }
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    addButton:{
      color:theme.palette.success.main
    }
  });

  const useStylesForPicker = makeStyles(theme => ({
    root: {
    },
    picker: {
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
}));

  export default {
    useRowStyles,
    useStyles,
    useStylesForPicker
  }