import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { Divider, Drawer,Typography,Select,Tooltip, IconButton } from '@material-ui/core';
//Components
import { Profile, SidebarNav, HelperPlan,LoginByDialog } from './components';
import Footer from '../Footer';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { authenticationService } from 'services';
import { UserStore,Constants } from 'stores';
//Style
import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons
import PeopleIcon from '@material-ui/icons/People';

var subs = null;


class Sidebar extends Component {

    state = {
        remainingTime: null,
        profile:UserStore.getProfileValue(),
        currentUser:UserStore.getUserValue(),
        loginBy:false,
        loginByAdmin:UserStore.getLoggedByAdminValue(),
    }

    componentDidMount() {
        subs = authenticationService.remainingTime.subscribe(res => this.setState({
            remainingTime: res,
        }));
        UserStore.getProfile().subscribe(res => this.setState({
            profile: res,
        }));
          //Subscribe user value 
        UserStore.getUser().subscribe(res => this.setState({
            currentUser: res,
        }));

        UserStore.getLoggedByAdmin().subscribe(res => this.setState({
            loginByAdmin: res,
        }));

    }

    componentDidUpdate(prevProps,prevState){
        const {profile} = this.state;
        if(profile!=prevState.profile){
            if(profile==="admin"){
                const history = Constants.getHistory();
                history.push('/admin/home');
            }else{
                const history = Constants.getHistory();
                history.push('/home');
                UserStore.clearLoggedByAdmin();
            }
        }
    }

    componentWillUnmount() {
        subs.unsubscribe();
    }

    handleChange = (event) =>{
        const value = event.target.value;
        UserStore.setProfile(value);
    }

    handleLoginBy = () =>{
        const {loginBy} = this.state;
        this.setState({
            loginBy:!loginBy
        })
    }



render(){

    const { profile,currentUser,loginByAdmin,loginBy } = this.state;
    const { snackbar, classes, open, pages, pathName, variant, onClose, onLogoutDialogOpen, className, ...rest } = this.props;
    const roles =['Admin'];
    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <PerfectScrollbar>
                <div
                    {...rest}
                    className={clsx(classes.root, className)}
                >
                    <Profile profile={profile} onLogoutDialogOpen={onLogoutDialogOpen} />
                    <Divider className={classes.divider} />
                        <SidebarNav
                        pathName={pathName}
                        className={classes.nav}
                        pages={pages}
                        />
                    <Divider className={classes.divider} />
                    {profile!=='admin' && <HelperPlan snackbar={snackbar} />}
                    <Footer />
                </div>
            </PerfectScrollbar>
                {(currentUser!==null && currentUser!==undefined) && roles.some(r => currentUser.roles.indexOf(r) >= 0) &&  
                <div className={profile==='admin' ? classes.adminPanel : classes.userPanel}>
                    <div style={{padding:'0.2rem',display:'flex',alignItems:'center', justifyContent:'center'}}>
                        <Select
                            variant='outlined'
                            margin='dense' 
                            onChange={this.handleChange}
                            disabled={loginByAdmin!==null} 
                            style={{color:'white'}} 
                            native value={profile} 
                            id="grouped-native-select"
                        >
                            <option style={{color:'black'}} value="user">
                                Felhasználói
                            </option>
                            <option style={{color:'black'}} value="admin">
                                Rendszergazdai
                            </option>
                        </Select>
                        {profile==='admin' &&  
                            <Tooltip title="Bejelentkezés mint">
                                <IconButton onClick={this.handleLoginBy} className={classes.usersIcon}>
                                    <PeopleIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                        
                    </div>
                </div>
                }
                {loginBy && 
                    <LoginByDialog 
                        loginBy={loginByAdmin}
                        onClose={this.handleLoginBy} 
                        open={loginBy}
                    />
                }
        </Drawer>
    );
}
   
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default withStyles(style)(Sidebar);
