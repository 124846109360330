import React, { Component } from "react";
//Core
import { Typography, Grid, Divider, TextField, IconButton,Button,Backdrop,CircularProgress } from "@material-ui/core";
//Components
import { RoundedButton } from 'components';
import { apiRequest } from './apiRequest';
import {Constants} from 'stores';
//Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
//Style
import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/styles';



class ReportProblem extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);

        this.state = {
            menuPoint: '',
            description: '',
            fileName: '',
            file: null,
            request:false
        }
    }

    handleselectedFile = (event) => {
        if (event.target.files.length < 1) {
            this.setState({
                file:null,
                fileName:''
            });
        } else {
            const fileName = event.target.files[0].name;
            const file = event.target.files[0];
            this.setState({
                file,
                fileName
            });

        }
       
    }

    handleDelete = () => {
        this.setState({
            file: null,
            fileName: ''
        })
    }

    handleChange = (event) => {    
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    handleSend = () => {
        const { menuPoint, description, file } = this.state     
        const model = { menuPoint, description, file}
        this.setState({
            request:true
        });
        apiRequest.send(this,model);
        
    }

    render() {
        const { classes } = this.props;
        const { menuPoint, description, fileName,file,request } = this.state;
        return (<div className={classes.root}>
            <Backdrop className={classes.backdrop} open={request}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                ></Grid>
                <Grid
                    item
                    md={10}
                    sm={12}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={1}
                    sm={false}
                    xs={false}
                ></Grid>
            </Grid>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={12}
                >
                    <Typography className={classes.title}>Hiba bejelentés</Typography>
                    <Divider />
                    <Typography className={classes.subTitle}> Melyik menüpontban történt a hiba ? </Typography>
                    <TextField
                        className={classes.textField}
                        required
                        onChange={this.handleChange}
                        value={menuPoint}
                        name="menuPoint"
                        label="Leírás"
                        variant="outlined">                      
                    </TextField>
                    <Typography className={classes.subTitle}> Hibajelenség leírása:</Typography>
                    <TextField
                        className={classes.multilineTextField}
                        multiline
                        onChange={this.handleChange}
                        value={description}
                        name="description"
                        label="Leírás"
                        rows={6}
                        required
                        variant="outlined">
                    </TextField>
                    <Typography className={classes.subTitle}> Képernyőkép csatolása:</Typography>
                    {/*label htmlFor="fileUploader">
                        <IconButton size="small" className={classes.iconButton}><AddIcon/></IconButton>
                    </label>*/}
                    {file === null
                        && <div style={{ display: 'flex', alignItems: 'center', paddingLeft: theme.spacing(4), }}>
                            <IconButton
                                className={classes.addIconButton}
                                size="small"
                                variant="contained"
                                component="label"
                            >
                                <AddIcon />
                                <input
                                    onChange={this.handleselectedFile}
                                    accept=".jpg,.png"
                                    type="file"
                                    name="file"
                                    style={{ display: "none" }}
                                />
                            </IconButton>
                            <Typography style={{ paddingLeft: theme.spacing(1) }}>{fileName === '' ? 'Nincs file kiválasztva' : fileName}</Typography>
                         </div>
                    }
                    {file !== null && <div style={{ display: 'flex', alignItems: 'center', paddingLeft: theme.spacing(4), }}>
                        <IconButton
                            className={classes.addIconButton}
                            size="small"
                            variant="contained"
                            component="label"
                        >
                            <AddIcon />
                            <input
                                onChange={this.handleselectedFile}
                                accept=".jpg,.png"
                                type="file"
                                style={{ display: "none" }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={this.handleDelete}
                            className={classes.removeIconButton}
                            size="small"
                            variant="contained"
                            component="label"
                        >
                            <DeleteIcon />
                        </IconButton>
                        <Typography style={{ paddingLeft: theme.spacing(1) }}>{fileName === '' ? 'Nincs file kiválasztva' : fileName}</Typography>
                    </div>}
                    <div style={{textAlign:'right'}}>
                        <Button
                            className={classes.sendButton} 
                            onClick={this.handleSend}
                            endIcon={<SendIcon />}
                            size="small"
                            disabled={(menuPoint==='' || description==='') ? true : false }
                        >
                            Elküld
                        </Button>
                    </div>

                </Grid>
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
            </Grid>
        </div>);
    }
}
export default withStyles(style)(ReportProblem);
