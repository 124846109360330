import React from 'react';
//Core
import {
    Grid,
    Typography
} from '@material-ui/core';
import { UserStore } from 'stores';
//Icons
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
//Style
import style from './style';





export default function ResultAvg(props) {
  const { value,loading } = props;
  const classes = style.useStyles();
  const StyledRating = style.StyledRating;

  const loggedBy =  UserStore.getLoggedByAdminValue();

  return (
<div>
           <Grid
            container
            spacing={2}
            >
                <Grid
                    item
                    md={8}
                    sm={8}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                >
                </Grid>
            </Grid>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        md={8}
                        sm={8}
                        xs={12}
              >
                  <Typography className={classes.avg_title}><b>Átlag: </b>{value}</Typography>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={4}
                        xs={12}
                    >
                       
                    <StyledRating 
                    name="avg_rating" 
                    value={value}
                    max={6}
                    disabled={loggedBy!=null && loggedBy.period.disabled}
                    precision={0.1} 
                    readOnly
                    size="large"
                    icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                    emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
                    />
          
                    </Grid>
             </Grid>
</div>
  );
}
