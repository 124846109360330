
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Helpers

import { UserStore } from 'stores';
//Actions

const Actions = {
    GetCompetences: 'ratingForm/self/competences/user',
    GetResult: 'ratingForm/result/competences/user',
    GetNextGoals: 'ratingForm/goals/new/user',
    GetActGoals: 'ratingForm/goals/result/user',
    GetMotivationPoints: 'ratingForm/motivation/user',
    GetLeaderRatings: 'ratingForm/leader-rating/user',
    GetPeriod: 'ratingForm/periods/printable',
    GetSumOfResult: 'ratingForm/goals/sum/result/user'
}

export const apiRequest = {
    populatePeriod,
    populateCompetences,
    populateSumOfResult,
    populateResult,
    populateGoals,
    populateActGoals,
    populateMotivationPoints,
    populateLeaderRatings
};

async function populatePeriod(parent) {
    const {period} = parent.props;

    await new Api().call.get(`${Actions.GetPeriod}/${period.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            periodLabel: res,
            loading: {
                ...prevState.loading,
                periodLabel: false
            }
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                periodLabel: false
            }
        }));
    });
}

async function populateCompetences(parent) {
    const {period,userid} = parent.props;

    var model = {period:period.id,userId:userid};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetCompetences,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            competences: res,
            loading: {
                ...prevState.loading,
                competences: false
            }
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                competences: false
            }
        }));
    });
}

async function populateSumOfResult(parent) {
    const {period,userid} = parent.props;

    var model = {period:period.id,userId:userid};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetSumOfResult,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                sumOfResult: false
            },
            sumOfResult: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                sumOfResult: false
            }
        }));
    });
}

//Populate datas
async function populateResult(parent) {
    const {period,userid} = parent.props;

    var model = {period:period.id,userId:userid};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetResult,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            result: res.competencesMainGroups,
            avg: res.avg,
            loading: {
                ...prevState.loading,
                result: false
            }
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                result: false
            }
        }));
    }); 
}

async function populateGoals(parent) {
    const {period,userid} = parent.props;

    var model = {userId:userid,period:period.id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetNextGoals,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                nextGoals: false
            },
            nextGoals: res
        }));
    }).catch(error => {
        handleError(error);
        //Error message snack
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                nextGoals: false
            }
        }));
    });
}

async function populateActGoals(parent) {
    const {period,userid} = parent.props;

    var model = {period:period.id,userId:userid};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetActGoals,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                actGoals: false
            },
            actGoals: res
        }));
    }).catch(error => {
        handleError(error);
        //Error message snack
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                actGoals: false
            }
        }));
    });
}

async function populateMotivationPoints(parent) {
    const {period,userid} = parent.props;

    var model = {userId:userid,period:period.id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetMotivationPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                motivationPoints: false
            },
            motivationPoints: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                motivationPoints: false
            }
        }));
    });
}

async function populateLeaderRatings(parent) {
    const {period,userid} = parent.props;

    var model = {userId:userid,period:period.id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetLeaderRatings,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                leaderRatings: false
            },
            leaderRatings: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                leaderRatings: false
            }
        }));
    });
}