import React,{Component} from 'react';
import PropTypes from 'prop-types';
//Core
import {
    Box,
    Divider,
    CardHeader,
    CircularProgress,
    Button,
    ButtonGroup
} from '@material-ui/core'; 
//Icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Components
import { AddUser, CreatedTable, ModifyUser } from './components';
import {UserStore} from 'stores';
import {apiRequest} from './apiRequest';
//Style
import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class TabPanels extends Component{

    constructor(props) {
    super(props);
        this.state = {
            evaluators: this.props.lazyData.evaluators,
            baseEvaluators: this.props.lazyData.baseEvaluators,
            loading: false,
            addNew:false,
            modifySelector:null,
            classifications:null,
            optionalEvaluators: null,
            selectedClass: null,
            selectedUser: null,
            percent: 0,
            isModified: false,
            validMatrix: null,
            selectedMatrix: this.props.lazyData.selectedMatrix,
            couldAddNew: false,
            autoSave: UserStore.getUserValue().autoSave,
        };

        this.initState = this.initState.bind(this);
    }

    //#Lifecycle methods
    componentDidMount() {
             apiRequest.checkIsAddNew(this);
             apiRequest.checkIsValid(this);      
    }

    componentWillUnmount() {
        const { validMatrix, isModified } = this.state;
        if (this.state.autoSave === true) {
            if (validMatrix !== null && validMatrix !== undefined && validMatrix !== '') {
                if (isModified === true) {
                    apiRequest.saveAction(this,this.props.userId);
                }
            } else {

            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { validMatrix } = this.state;
        if (this.props.userId !== prevProps.userId) {
            if (this.state.autoSave === true) {
                if (this.state.isModified) {
                    if (validMatrix !== null && validMatrix !== undefined && validMatrix !== '') {
                        apiRequest.saveAction(this,prevProps.userId).then(() => {
                            this.initState().then(() => {
                                apiRequest.populateEvaluators(this);
                            });
                        });
                    }
                } else {
                    this.initState().then(() => {
                        apiRequest.populateEvaluators(this);
                    });
                }
            } else {
                this.initState().then(() => {
                    apiRequest.populateEvaluators(this);
                });
            }
        }

        if (this.state.loading==false && this.state.evaluators != prevState.evaluators) {
            this.isModified();
                apiRequest.checkIsValid(this);
                apiRequest.checkIsAddNew(this);
        }
    }
    //#EndLifecycle methods

    handleSetSelectedMatrix = (value) =>{
        this.setState({
            selectedMatrix: value
        });
        this.props.setParentSate("selectedMatrix", value);
    }

    handleEvaluators = (value) => {
        this.setState({
            evaluators: value
        });
    }

    handleSetAddNew = (value) => {
        this.setState({
            addNew: value
        });
        if (value) {
            this.props.setUserSelectable(false);
        }
        if (value === false) {
            this.setState({
                activeStep: 0,
                selectedClass: null,
                selectedUser: null,
                percent: 0
            });
            this.props.setUserSelectable(true);
        }

    }

    handleRemoveUser = (userId,classId) =>{
        const { evaluators } = this.state;
        let tempEvaluators = evaluators.filter(item => item.userId != userId);
        const model = { evaluators: tempEvaluators, removedClassId: classId }
        apiRequest.removeUser(this,model);
       
    }

    handleSetModifySelector = (value, classId) => {
        const { evaluators } = this.state;
        this.setState({
            modifySelector: value,
            modifySelectorClass: classId
        });

        if (value != null) {
            this.setState({
                addNew: false,
            });
            const tempUser = evaluators.filter(evaluator => evaluator.userId === value);
            const createdUser = { userId: tempUser[0].userId, fullName: tempUser[0].fullName, userFilterId: tempUser[0].userFilterId}
            this.setState({
            selectedUser:createdUser,
            percent:tempUser[0].percent,
            selectedClass:tempUser[0].classification
            });
            this.props.setUserSelectable(false);
        }
        else {
            this.setState({
                selectedUser:null,
                selectedClass:null,
                percent:0
            });
            this.props.setUserSelectable(true);
        }
    }

    handleCancel = () => {
        this.setState({
            isModified: false,
            evaluators: JSON.parse(JSON.stringify([...this.state.baseEvaluators]))
        });
        this.props.setParentSate("isModified", false);
    }


    async initState() {
       await this.setState({
            evaluators: [],
            baseEvaluators: [],
            loading: true,
            addNew: false,
            modifySelector: null,
            classifications: null,
            optionalEvaluators: null,
            selectedClass: null,
            selectedUser: null,
            percent: 0,
            isModified: false,
            validMatrix: null,
            selectedMatrix: null,
            couldAddNew: false
       });
        this.props.setParentSate("isModified", false);
        this.props.setParentSate("isValid", false);
        this.props.setParentSate("selectedMatrix", null);
        this.props.setParentSate("evaluators", null);
    }

    /*handleSave = (userId) => {
           this.saveAction(userId);     
    }*/


    isModified = () => {
        const { evaluators, baseEvaluators } = this.state;
        if (evaluators.length != baseEvaluators.length) {
            this.setState({
                isModified: true
            });
            this.props.setParentSate("isModified", true);
        }
        else {
            var hashEvaluators = '';
            var hashBaseEvaluators = '';
            evaluators.map((evaluator) => {
                hashEvaluators = hashEvaluators + evaluator.userId;
                hashEvaluators = hashEvaluators + evaluator.fullName.toUpperCase().trim();
                hashEvaluators = hashEvaluators + evaluator.classification.id;
                hashEvaluators = hashEvaluators + evaluator.percent;
            });
            baseEvaluators.map((evaluator) => {
                hashBaseEvaluators = hashBaseEvaluators + evaluator.userId;
                hashBaseEvaluators = hashBaseEvaluators + evaluator.fullName.toUpperCase().trim();
                hashBaseEvaluators = hashBaseEvaluators + evaluator.classification.id;
                hashBaseEvaluators = hashBaseEvaluators + evaluator.percent;
            });
            if (hashEvaluators != hashBaseEvaluators) {
                this.setState({
                    isModified: true
                });
                this.props.setParentSate("isModified", true);
            }
            else {
                this.setState({
                    isModified: false
                });
                this.props.setParentSate("isModified", false);
            }
        }
    }

    compare(a, b) {
        const idA = a.userId;
        const idB = b.userId;

        let comparison = 0;
        if (idA > idB) {
            comparison = 1;
        } else if (idA < idB) {
            comparison = -1;
        }
        return comparison;
    }

    sort_array_by(array, sort) {
        array.sort(function (a, b) {
            if (a[sort] < b[sort]) return -1;
            if (a[sort] > b[sort]) return 1;
            return 0;
        });
        this.setState({
            evaluators: array,
            baseEvaluators: JSON.parse(JSON.stringify(array)),
            loading: false
        });
    }
   
    render() {
        const { evaluators,
            loading,
            modifySelector,
            addNew,
            selectedClass,
            selectedUser,
            percent,
            classifications,
            optionalEvaluators,
            isModified,
            validMatrix,
            selectedMatrix,
            couldAddNew
        } = this.state;

        const {value, index, snackbar, classes} = this.props;
        const loggedBy =  UserStore.getLoggedByAdminValue();

        return (loading === true ? 
            <div className={classes.circularProgress}>
                <CircularProgress color="primary" />
            </div> :
            <TabPanel
                value={value}
                index={index}>
                <div>                 
                    {modifySelector === null && addNew === false &&
                        <CardHeader
                            action=
                            {
                                <Button
                                    disabled={!couldAddNew ||  (loggedBy!=null && loggedBy.period.disabled)}
                                    onClick={() => this.handleSetAddNew(true)}
                                    className={classes.addNewButton}
                                    endIcon={<PersonAddIcon />}
                                >
                                    Új értékelő hozzáadása
                                </Button>
                            }
                        />}
                    {modifySelector != null &&
                        <ModifyUser
                            validMatrix={validMatrix}
                            selectedMatrix={selectedMatrix}
                            selectedTabUser={this.props.userId}
                            evaluators={evaluators}
                            handleEvaluators={this.handleEvaluators}
                            selectedClass={selectedClass}
                            selectedUser={selectedUser}
                            selectedPercent={percent}
                            optionalEvaluators={optionalEvaluators}
                            classifications={classifications}
                            handleSetModifySelector={this.handleSetModifySelector}
                            snackbar={snackbar}
                        />}
                    {addNew === true &&
                        <AddUser
                            selectedMatrix={selectedMatrix}
                            handleSetSelectedMatrix={this.handleSetSelectedMatrix}
                            selectedTabUser={this.props.userId}
                            value={value}
                            evaluators={evaluators}
                            handleEvaluators={this.handleEvaluators}
                            optionalEvaluators={optionalEvaluators}
                            classifications={classifications}
                            handleSetAddNew={this.handleSetAddNew}
                            selectedClass={selectedClass}
                            selectedUser={selectedUser}
                            selectedPercent={percent}
                            snackbar={snackbar}
                        />}
                    <Divider />
                </div>
                <div className={classes.table} >
                    <CreatedTable 
                        addNew={addNew} 
                        handleRemoveUser={this.handleRemoveUser} 
                        data={evaluators} 
                        modifySelector={modifySelector} 
                        handleSetModifySelector={this.handleSetModifySelector} 
                    />
                </div>
                <div>
                {isModified &&
                    <div style={{textAlign:'right',paddingTop:'0.5rem'}}>
                        <ButtonGroup>
                            <Button
                                onClick={() => apiRequest.saveAction(this,this.props.userId)}
                                className={classes.doneButton}
                                disabled={!(validMatrix !== null && validMatrix !== undefined && validMatrix!=='')} 
                                size="small"
                                endIcon={<DoneIcon/>}
                            >
                                Mentés
                            </Button>
                            <Button
                                onClick={this.handleCancel}
                                className={classes.cancelButton}  
                                size="small"
                                endIcon={<CloseIcon/>}
                            >
                                Mégsem
                            </Button>
                        </ButtonGroup>
                    </div>
                }
                </div>
            </TabPanel>
        )
    }
}

export default withStyles(style)(TabPanels);
