import { makeStyles } from '@material-ui/styles';

export default  makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '75%',
        width: 560
    },
    buttons:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        }
    },
    contentTitle: {
        fontFamily:'Nunito'
    },
    contentSubTitle: {
        fontFamily: 'Nunito'
    },
}));
