//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetActivePeriod: 'admin/home/active-period',
    GetSelfRatingResult: 'admin/home/self-result',
    GetMotivationsResult: 'admin/home/motivation-result',
    GetMatrixResult: 'admin/home/matrix-result',
    GetGoalResult: 'admin/home/goal-result',
    GetResultResult: 'admin/home/result-result',
    GetRatingResult: 'admin/home/rating-result',
    GetLeaderRatingResult: 'admin/home/leader-rating-result',
    GetSpeakResult: 'admin/home/speak-result'
}

export const apiRequest = {
    getActivePeriod,
    getSelfRatingResult,
    getMotivationsResult,
    getMatrixResult,
    getGoalResult,
    getResultResult,
    getRatingResult,
    getLeaderRatingResult,
    getSpeakResult
};

async function getActivePeriod(parent) {
    await new Api().call.get(Actions.GetActivePeriod)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                period: res,
                loading: {
                    ...prevState.loading,
                    period: false
                }
            }));
        }).catch(handleError);
}

async function getSelfRatingResult(parent) {
    await new Api().call.get(Actions.GetSelfRatingResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                self: res,
                loading: {
                    ...prevState.loading,
                    self: false
                }
            }));
        }).catch(handleError);
}

async function getMotivationsResult(parent) {
    await new Api().call.get(Actions.GetMotivationsResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                motivation: res,
                loading: {
                    ...prevState.loading,
                    motivation: false
                }
            }));
        }).catch(handleError);
}

async function getMatrixResult(parent) {
    await new Api().call.get(Actions.GetMatrixResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                matrix: res,
                loading: {
                    ...prevState.loading,
                    matrix: false
                }
            }));
        }).catch(handleError);
}

async function getGoalResult(parent) {
    await new Api().call.get(Actions.GetGoalResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                goals: res,
                loading: {
                    ...prevState.loading,
                    goals: false
                }
            }));
        }).catch(handleError);
}

async function getResultResult(parent) {
    await new Api().call.get(Actions.GetResultResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                results: res,
                loading: {
                    ...prevState.loading,
                    results: false
                }
            }));
        }).catch(handleError);
}

async function getRatingResult(parent) {
    await new Api().call.get(Actions.GetRatingResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                ratings: res,
                loading: {
                    ...prevState.loading,
                    ratings: false
                }
            }));
        }).catch(handleError);
}

async function getLeaderRatingResult(parent) {
    await new Api().call.get(Actions.GetLeaderRatingResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                leaderRatings: res,
                loading: {
                    ...prevState.loading,
                    leaderRatings: false
                }
            }));
        }).catch(handleError);
}

async function getSpeakResult(parent) {
    await new Api().call.get(Actions.GetSpeakResult)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                speak: res,
                loading: {
                    ...prevState.loading,
                    speak: false
                }
            }));
        }).catch(handleError);
}