import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 120,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    padding: theme.spacing(1, 2)
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center',
  },
  buttons:{
    color:'white',
    fontSize: '0.6rem',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.appMain.main,
    '&:hover': {
        color:theme.palette.appMain.dark,
        backgroundColor: 'white',
        border:`1px solid ${theme.palette.appMain.dark}`
    },
    '&:disabled': {
      backgroundColor: theme.palette.secondary.light,
      color:theme.palette.secondary.dark
    }
  }
    /*buttons: {
        fontSize: '0.6rem',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            backgroundColor: theme.palette.appMain.dark,
        },
    }*/
}));

export default useStyles;
