//React

import React, { useState } from 'react';
//Core

import {
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    ButtonGroup,
    Button
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done'
//Components

import { 
    DatePicker, 
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import huLocale from "date-fns/locale/hu";
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';


const DialogTitle = withStyles(style.styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function ConversationDialog(props) {
    const {open, handleClose, period, data, refreshUsers} = props;
    const [date, changeDate] = useState(new Date());

    function handleClickSend() {
        apiRequest.handleSaveChanges(data.id, date,period,handleClose,refreshUsers)
    }

    const classes = style.useStyles();

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle onClose={handleClose} id="customized-dialog-title"><Typography className={classes.title}>Beszélgetés visszaigazolása - {data.fullName}</Typography></DialogTitle>
            <Divider />
            <DialogContent>
                <Typography className={classes.description}>Kérlek, válaszd ki melyik napon történt meg a beszélgetés!</Typography>
                <MuiPickersUtilsProvider locale={huLocale} utils={DateFnsUtils}>
                    <div style={{ textAlign: 'center'}}>
                <DatePicker
                    autoOk
                    orientation="landscape"
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={changeDate}
                        />
                    </div>
                    </MuiPickersUtilsProvider>

            </DialogContent>
            <Divider />
            <DialogActions>
                <ButtonGroup>
                    <Button
                        onClick={handleClickSend}
                        className={classes.doneButton}
                        size="small"
                        endIcon={<DoneIcon/>}
                    >
                        Mentés
                    </Button>
                    <Button
                        onClick={handleClose}
                        className={classes.cancelButton}  
                        size="small"
                        endIcon={<CloseIcon/>}
                    >
                        Mégsem
                    </Button>
                </ButtonGroup>
              </DialogActions>
        </Dialog>
    );
}
