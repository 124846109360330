//React

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Styles

import style from './style';
//Core

import {
    Paper,
    Grid,
    IconButton,
    Tooltip,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    TextField
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';


const validOptions = [{ id: 1, label: 'Igen' }, { id: 2, label: 'Nem' }];

const CompaniesToolbar = props => {
    const {selected, onDelete, organizations, onDone, edit, companies, filter, loading, onChange, onBlockSelected, onUnBlockSelected, className, ...rest} = props;
    
    const classes = style();

    const [width, setWidth] = React.useState(classes.root);

    const handleExit = () => {
        setWidth(classes.root);
    }
    const handleEnter = () => {
        setWidth(classes.rootFull);
    }

    return (
        <div {...rest} className={clsx(className)}>
            {selected.length > 0 && 
                <div className={classes.actionPanel}>
                    <Paper>
                        <Tooltip title="Cégcsoport letiltása">
                            <IconButton 
                                onClick={onBlockSelected} 
                                className={classes.blockButton} 
                                aria-label="delete"
                            >
                                <BlockIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cégcsoport feloldása">
                            <IconButton  
                                onClick={onUnBlockSelected} 
                                className={classes.doneButton} 
                                aria-label="done"
                            >
                                <DoneIcon />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                </div>
            }
            <div className={width}>
                <ExpansionPanel TransitionProps={{ onExited: handleExit, onEnter: handleEnter }}>
                    <ExpansionPanelSummary
                        expandIcon={<FilterListIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Szűrők</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                        <Autocomplete
                                            multiple
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-companies"
                                            name="companies"
                                            disabled={edit == null ? false : true}
                                            value={filter.companies}
                                            onChange={(event, value) => onChange("companies", value)}
                                            options={companies}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Cégcsoport"
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                                        <Autocomplete
                                            clearText="Alaphelyzet"
                                            noOptionsText="Nincs találat"
                                            closeText="Bezárás"
                                            id="filter-active"
                                            name="active"
                                            disabled={edit == null ? false : true}
                                            value={filter.active}
                                            onChange={(event, value) => onChange("active", value)}
                                            options={validOptions}
                                            getOptionLabel={(option) => option.label}
                                            filterSelectedOptions
                                            renderInput={(params) => (
                                                <TextField
                                                    margin='dense'
                                                    {...params}
                                                    variant="outlined"
                                                    label="Aktív"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
};

CompaniesToolbar.propTypes = {
    className: PropTypes.string
};

export default CompaniesToolbar;
