//React

import React, { Component } from 'react';
//Pickers

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Styles

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import TodayIcon from '@material-ui/icons/Today';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DateTimePicker = props => {
  const { value, disabled, id, name, error, label, minDate, maxDate, required, readOnly, onChange, helperText } = props;
  const classes = style.useStylesForPicker();

  return (
    <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils}>
      <DatePicker
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={disabled}>
                <TodayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
        helperText={helperText}
        clearable={true}
        clearLabel="Törlés"
        emptyLabel="Nincs megadva"
        id={id}
        inputVariant="outlined"
        margin="dense"
        fullWidth
        name={name}
        required={required}
        className={classes.picker}
        cancelLabel="Vissza"
        openTo="date"
        label={label}
        readOnly={readOnly}
        disabled={disabled}
        format="yyyy MMMM dd"
        minDate={minDate}
        minDateMessage="Nem megengedett érték"
        maxDate={maxDate}
        views={["year", "month", "date"]}
        autoOk
        value={(value !== null && value !== undefined) ? value : null}
        onChange={date => onChange(date)}
      />
    </MuiPickersUtilsProvider>
  )
}


class ExistsDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hireDate: '',
      endDate: '',
      hr: '',
      user: null,
      selected: '',
      users: [],
      hrs: [],
      loading: {
        users: true,
        user: false,
        hrs: false
      }
    }

  }

  componentDidMount() {
    apiRequest.populateHrs(this);
    apiRequest.populateUsers(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { hireDate, selected } = this.state;

    if (prevState.selected !== selected) {
      apiRequest.populateUser(this);
    }
    if (prevState.hireDate !== hireDate) {
      var newDate = new Date(new Date(hireDate).setMonth(new Date(hireDate).getMonth() + 3));
      const endDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
      this.setState({
        endDate
      });
    }
  }

  handleDateChange = (date, props) => {

    let newDate = null;
    if (date !== null && date !== undefined) {
      newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
    }
    this.setState({
      [props]: newDate
    });
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }


  handleSelectChange = (props, value) => {
    this.setState({
      [props]: value
    });
  }

  validateFields = (value) => {
    if (value === null || value === undefined || value === '') {
      return false;
    }
    return true;
  }

  handleSave = () => {
    apiRequest.saveUser(this);
  }

  render() {
    const { onClose, open, classes } = this.props;
    const { hireDate, user, users, endDate, loading, hr, hrs, selected } = this.state;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>
            Létező belépő
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12} sm={12} item>
                <Autocomplete
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-user"
                  name="userName"
                  value={selected}
                  onChange={(event, value) => this.handleSelectChange("selected", value)}
                  options={users}
                  getOptionLabel={(option) => option.fullName === undefined ? '' : option.fullName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      margin="dense"
                      error={!this.validateFields(selected)}
                      label="Teljes név"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.companies ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={12} item>
                <Autocomplete
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-hr"
                  name="hr"
                  value={hr}
                  onChange={(event, value) => this.handleSelectChange("hr", value)}
                  options={hrs}
                  getOptionLabel={(option) => option.fullName === undefined ? '' : option.fullName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!this.validateFields(hr)}
                      label="Hr-es"
                      variant="outlined"
                      margin="dense"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.hrs ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <DateTimePicker
                  error={!this.validateFields(hireDate)}
                  label="Belépés dátuma"
                  required={true}
                  value={hireDate === '' ? null : hireDate}
                  onChange={(date) => this.handleDateChange(date, 'hireDate')}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <DateTimePicker
                  error={!this.validateFields(endDate)}
                  label="Próbaidő vége"
                  required={true}
                  value={endDate === '' ? null : endDate}
                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                  name="company"
                  onChange={this.handleChange}
                  value={user != null ? user.company : ''}
                  label="Cégcsoport"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                  name="position"
                  onChange={this.handleChange}
                  value={user != null ? user.position : ''}
                  label="Munkakör"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                  name="organization"
                  onChange={this.handleChange}
                  defaultChecked={user != null ? user.organization : ''}
                  label="Szervezeti egység"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                  name="leader"
                  onChange={this.handleChange}
                  value={user != null ? user.leader : ''}
                  label="Felettes"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              endIcon={<BackspaceIcon />}
              className={classes.secondaryButton}
              size="small"
              onClick={onClose}
              variant="outlined"
              color="secondary"
            >
              Vissza
            </Button>
            <Button
              disabled={!this.validateFields(hr) || !this.validateFields(selected) || !this.validateFields(hireDate) || !this.validateFields(endDate)}
              endIcon={<SaveIcon />}
              className={classes.mainButton}
              size="small"
              onClick={this.handleSave}
              variant="outlined"
            >
              Mentés
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style.useStyles)(ExistsDialog);