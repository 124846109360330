//React

import React from 'react';
//Core

import {
    Typography,
    Button,
    StepLabel,
    Step,
    Stepper,
    Grid,
    CardHeader,
    Divider,
    ButtonGroup
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
//Components

import { 
    SelectUser, 
    SelectClass, 
    SetPercent, 
    AlertDialog 
} from './components';
//Api

import {apiRequest} from './apiRequest';
//Style

import style from './style';


function getStepContent(snackbar, getDisabledClasses, disabledUsers, optionalEvaluators, classifications, stepIndex, classes, selectedUser, selectedClass, percent, handleUserChange, handleClassChange, handlePercentChange, handleSetSelectedMatrix, usedClasses, maxPercent, selectedTabUser) {
    switch (stepIndex) {
        case 0:
            return (
                <div className={classes.gridRoot}> 
                <Grid container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <SelectClass 
                            selectedTabUser={selectedTabUser} 
                            snackbar={snackbar} 
                            usedClasses={usedClasses} 
                            disabledClasses={getDisabledClasses()} 
                            classifications={classifications} 
                            handleChange={handleClassChange} 
                            value={selectedClass} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}/>
                    <Grid item md={4} xs={12}/>
                    </Grid>
                </div>
            )
        case 1:
            return (
                <div className={classes.gridRoot}> 
                <Grid container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <SelectClass 
                            selectedTabUser={selectedTabUser} 
                            snackbar={snackbar} 
                            usedClasses={usedClasses}
                            disabledClasses={getDisabledClasses()} 
                            classifications={classifications} 
                            disabled={true} 
                            handleChange={handleClassChange} 
                            value={selectedClass} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SelectUser 
                            handleSetSelectedMatrix={handleSetSelectedMatrix} 
                            selectedClass={selectedClass} 
                            usedClasses={usedClasses} 
                            snackbar={snackbar} 
                            disabledUsers={disabledUsers} 
                            handleChange={handleUserChange} 
                            users={optionalEvaluators}  
                            value={selectedUser} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}/>
                </Grid>
                </div>
            )
        case 2:
            return (
            <div className={classes.gridRoot}> 
                <Grid container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <SelectClass 
                            selectedTabUser={selectedTabUser} 
                            snackbar={snackbar} 
                            usedClasses={usedClasses} 
                            disabledClasses={getDisabledClasses()} 
                            handleChange={handleClassChange} 
                            classifications={classifications} 
                            disabled={true} 
                            value={selectedClass} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SelectUser 
                            handleSetSelectedMatrix={handleSetSelectedMatrix} 
                            selectedClass={selectedClass} 
                            usedClasses={usedClasses}  
                            snackbar={snackbar} 
                            disabledUsers={disabledUsers} 
                            handleChange={handleUserChange} 
                            users={optionalEvaluators} 
                            disabled={true} 
                            value={selectedUser} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SetPercent 
                            maxPercent={maxPercent} 
                            selectedClass={selectedClass} 
                            handleChange={handlePercentChange} 
                            value={percent} 
                        />
                    </Grid>
                </Grid>
            </div>
            )
        default:
            return 'Unknown stepIndex';
    }
}

function getSteps() {
    return ['Add meg milyen minősítésben értékeljen','Válszd ki az értékelőt', 'Add meg hány százalékban számítson az értékelése'];
}

export default function AddUser(props) {
    const { 
        value,
        evaluators,
        handleEvaluators,
        optionalEvaluators,
        classifications,
        handleSetAddNew,
        selectedClass,
        selectedUser,
        selectedPercent,
        snackbar,
        selectedTabUser,
        handleSetSelectedMatrix,
        selectedMatrix 
    } = props;

    var disabledUsers = evaluators.map(item => item.userId);
    const usedClasses = evaluators.map(item => item.classification.id);
    disabledUsers.push(selectedTabUser);

    const [classification, setClassification] = React.useState(selectedClass);
    const [user, setUser] = React.useState(selectedUser);
    const [percent, setPercent] = React.useState(selectedPercent);
    const [maxPercent, setMaxPercent] = React.useState(100);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isOverTheLimit, setIsOverTheLimit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [overTheLimitUser, setOverTheLimitUser] = React.useState(null);
    const steps = getSteps();

    const handleClassChange = (value) => {

        if (value === null || value === 'null') {
            handlePercentChange(0);
            setActiveStep(0);
        }
        else {
            if (value.defaultValue != null) {
                handlePercentChange(value.defaultValue);
            }
            else {
                handlePercentChange(0);
            }
            setActiveStep(1);
        }

        setClassification(value);
        setUser(null);
    }

    const handlePercentChange = (value) => {
        if(value!==null && value!==undefined && value!==''){
            setPercent(value*1);
        }else{
            setPercent(value);
        }       
    }

    async function handleUserChange(value){  
        if (value !== null && value !== 'null') {
            apiRequest.checkIsOverTheLimit(value,setIsOverTheLimit,setActiveStep,setUser,setOverTheLimitUser,setOpen,classification,selectedMatrix,setMaxPercent);                  
        }
        else {
            setActiveStep(1);
            if (classification.defaultValue === null || classification.defaultValue === 'null' || classification.defaultValue === undefined) {
                setPercent(0);
            }
            setUser(value); 
        }     
    }

    const handleNext = (index) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        if ((activeStep + 1) === steps.length) {

            let model = {
                evaluators: evaluators,
                userId: user.userId,
                avatar: 'loading',
                fullName: user.fullName,
                classification: classification,
                percent: percent,
                userFilterId: user.userFilterId,
                selectedMatrixId: selectedMatrix
            }

            apiRequest.addNew(model,handleEvaluators);
            handleSetAddNew(false);
        }      
    };

    const getDisabledClasses = () => {
        let classIds = [];
        let classWithQuantity = [];
        let disabledIds = [];
        evaluators.map(item => {
            if (!classIds.includes(item.classification.id)) {
                classIds.push(item.classification.id);
            }           
        });
        classIds.map(classId => {
            let counter = 0;
            let allowed = 0;
            evaluators.map(item => {
                if (item.classification.id == classId) {
                    counter++;
                    allowed = item.classification.maxQuantity;
                }
              
            });
            classWithQuantity.push({ id: classId, quantity: counter, maxQuantity: allowed})
        });
        classWithQuantity.map(item => {
            if (item.quantity >= item.maxQuantity) {
                disabledIds.push(item.id);
            }
        });
        return disabledIds;
    }

    const setNextButton= () => {
        let disabled = true;
        if (activeStep === 0 && classification != null) {
            disabled = false;
        }
        if (activeStep === 1 && user != null) {
            disabled = false;
        }
        if (activeStep === 2 && (percent > 0 && percent <= maxPercent && percent % 1 === 0)) {
            disabled = false;
        }
        return disabled;
    }

    const classes = style();

    return (
        <div className={classes.root}>
            <CardHeader
                action=
                {
                    <ButtonGroup>
                        <Button
                            onClick={() => handleNext(value)}
                            className={classes.doneButton}
                            disabled={setNextButton()} 
                            size="small"
                            endIcon={<DoneIcon/>}
                        >
                            Hozzáadás
                        </Button>
                        <Button
                            onClick={() => handleSetAddNew(false)}
                            className={classes.cancelButton}  
                            size="small"
                            endIcon={<CloseIcon/>}
                        >
                            Mégsem
                        </Button>
                    </ButtonGroup>
                }
                title={
                    <Typography className={classes.title}>
                        Új értékelő hozzáadása
                    </Typography>
                }
            />
            <Divider className={classes.dividerBottom} />
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <div className={classes.bgWhite}>
                    {getStepContent(snackbar, getDisabledClasses, disabledUsers, optionalEvaluators, classifications, activeStep, classes, user, classification, percent, handleUserChange, handleClassChange, handlePercentChange, handleSetSelectedMatrix, usedClasses, maxPercent, selectedTabUser)}
                </div>            
            </div>
            {overTheLimitUser !== null && 
                <AlertDialog 
                    user={overTheLimitUser} 
                    open={open} 
                    onClose={() => setOpen(false)} 
                />
            }
        </div>
    );
}
