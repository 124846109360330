import { makeStyles } from '@material-ui/core';
import theme from 'theme';


const useStyles = makeStyles({
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color:theme.palette.appMain.main,
    paddingBottom:'1rem',
    paddingTop:'1rem',
    fontSize: '1.0rem',
  },
  subTitle:{
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color:theme.palette.appMain.main,
    fontSize: '0.9rem',
    paddingBottom:'0.5rem',
    paddingTop:'0.5rem',
  },
  point:{
    fontFamily: 'Nunito',
    fontSize: '0.8rem',
  },
  value:{
    fontFamily: 'Nunito',
    color:theme.palette.appMain.dark,
    fontSize: '0.9rem',
  },
  footer:{
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    padding:'0.5rem',
  },
  mainButton:{
    color:'white',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.appMain.main,
    '&:hover': {
        color:theme.palette.appMain.main,
        backgroundColor: 'white',
        border:`1px solid ${theme.palette.appMain.light}`
    },
    '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        color:theme.palette.secondary.dark
    },
  },
  secondaryButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appSecondary.main,
        '&:hover': {
            color:theme.palette.appSecondary.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appSecondary.light}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.appSecondary.main,
            color:theme.palette.secondary.dark
        },
    },
    greenButton:{
      color:'white',
      fontFamily:'Nunito',
      textTransform:'none',
      fontWeight:'bold',
      backgroundColor: theme.palette.success.light,
      '&:hover': {
          color:theme.palette.success.main,
          backgroundColor: 'white',
          border:`1px solid ${theme.palette.success.main}`
      },
      '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
      },
    }
});

export default useStyles;