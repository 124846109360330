import palette from '../palette';

export default {
    root: {
        color: palette.white,
        backgroundColor: palette.appSecondary.main,
        fontFamily: 'Nunito',
        fontSize:'1.0rem'
    },
};
