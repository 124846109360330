//React

import React, { Component } from 'react';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    InputAdornment,
    Grid,
    TextField,
    CircularProgress,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';


const DateTimePicker = props => {
    const {value, disabled, id, name, required, label, minDate, maxDate, error, errorClassName, readOnly, onChange, helperText} = props;

    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                error={error}
                helperText={error===true && helperText}
                clearable={true}
                clearLabel="Törlés"
                id={id}
                required={required}
                name={name}
                inputVariant="outlined"
                margin="dense"
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                maxDate={maxDate}
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(name,date)}
            />
        </MuiPickersUtilsProvider >
    )
}

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.populateCompanies(this);

        this.state = {
            positions: [],
            organizations: [],
            companies: [],
            selected: {
                position: null,
                organization: null,
                company:null
            },
            validFrom: null,
            loading: {
                positions: null,
                organizations: null,
                companies:true
            },
            valid:false
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {selected, validFrom} = this.state;

        if (selected.company != prevState.selected.company) {
            if (selected.company != null && selected.company != undefined) {
                this.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        organizations: true,
                        positions:true
                    },
                    selected: {
                        ...prevState.selected,
                        position: null,
                        organization: null
                    },
                }));
                apiRequest.populateOrganizations(this,selected.company.id);
                apiRequest.populatePositions(this,selected.company.id);
            } else {
                this.setState({
                    selected: {
                        company:null,
                        position: null,
                        organization: null
                    },
                });
            }
        }

        if (prevState.selected.position != selected.position || prevState.selected.organization!= selected.organization || 
            prevState.selected.company!= selected.company || prevState.validFrom!=validFrom) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }     
    }

    onDateChange = (props, date) => {      
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 

        this.setState({
            [props]: newDate
        });
    }
    onChange = (props,value) => {
        this.setState(prevState => ({
            selected: {
                ...prevState.selected,
                [props]: value
            }
        }));
    }

    handleSave = () => {
        apiRequest.savePosition(this);
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }
   


    render() {
        const {onClose, open, classes, lastValidFrom} = this.props;
        const {validFrom, loading, companies, organizations, positions, selected, valid} = this.state;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Új munkakör hozzáadása
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider />
                <DialogContent id="add-new-form">
                    <Grid spacing={2} container>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-company"
                                    name="company"
                                    value={selected.company}
                                    onChange={(event, value) => this.onChange("company", value)}
                                    options={companies}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            error={!this.validateFields(selected.company)}
                                            label="Cégcsoportok"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.companies ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    className={classes.autocomplete_2}
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-organization"
                                    disabled={(selected.company == null || selected.company == undefined) ? true : false}
                                    name="organization"
                                    value={selected.organization}
                                    onChange={(event, value) => this.onChange("organization",value)}
                                    options={organizations}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            error={!this.validateFields(selected.organization)}
                                            label="Szervezeti egységek"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.organizations ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    className={classes.autocomplete_3}
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-position"
                                    disabled={(selected.company == null || selected.company == undefined) ? true : false}
                                    name="position"
                                    value={selected.position}
                                    onChange={(event, value) => this.onChange("position",value)}
                                    options={positions}
                                    getOptionLabel={(option) => option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            error={!this.validateFields(selected.position)}
                                            label="Munkakörök"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.positions ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <div style={{ textAlign: 'center' }}>
                                <DateTimePicker 
                                    minDate={lastValidFrom} 
                                    error={!this.validateFields(validFrom)} 
                                    required={true} 
                                    onChange={this.onDateChange} 
                                    name="validFrom" 
                                    value={validFrom} 
                                    label="Érvényes -től" 
                                />
                           </div>
                        </Grid>
                   </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!valid} 
                                    onClick={this.handleSave} 
                                    className={classes.doneButton} 
                                    aria-label="done"
                                >
                                        <DoneIcon />
                                    </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
        </Dialog>);
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
