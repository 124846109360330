//React

import React from 'react';
import clsx from 'clsx';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  CardActions,
  Typography,
  Select,
  MenuItem,
  TextField,
  LinearProgress
} from '@material-ui/core';
//Icons

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';


const StyledTableRow = style.StyledTableRow;

const DataTable = props => {
  const {className, data, periods, period, loading, onOpen, onSave, onChange, onCancel, edit, onEdit, onDelete, ...rest} = props;

  const classes = style.useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell align="center"  padding="none">Szűrő</TableCell>
                    <TableCell align="center"  padding="none">Darabszámtól</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-től</TableCell>
                    <TableCell align="center" padding="none">Érvényesség-ig</TableCell>
                    <TableCell align="center" padding="none">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((row,index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={row.id}
                  >
                    <TableCell padding="none" align="center" >
                      {row.label}
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      <TextField
                        value={row.fromQuantities}
                        type="number"
                        name="fromQuantities"
                        disabled={(row.disabled || edit == null || (edit != null && edit.id != row.id))}
                        onChange={(event)=>onChange(event,index,true)}
                      />
                    </TableCell>                                  
                    <TableCell padding="none" align="center" >
                      <Select
                        disabled={true}
                        name="validFrom"
                        value={row.validFrom}
                      >
                        {periods.map((item,index)=>(
                          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell padding="none" align="center" >
                      <Select
                        disabled={true}
                        name="validTo"
                        value={row.validTo}
                      >
                        {periods.map((item,index)=>(
                          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell padding="none" align="center">
                    {(edit == null || (edit != null && edit.id != row.id)) && <React.Fragment>
                    <Tooltip title="Módosítás">
                      <span>
                        <IconButton 
                          onClick={()=>onEdit(row)} 
                          className={classes.editIcon}
                          disabled={row.disabled || period===null || period.disabled===true}>
                          <EditIcon/>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Törlés">
                      <span>
                        <IconButton
                          onClick={()=>onDelete(index)}   
                          className={classes.deleteButton}
                          disabled={row.disabled || period===null || period.disabled===true}>
                          <DeleteIcon/>
                        </IconButton>
                      </span>
                    </Tooltip></React.Fragment>
                    }
                    {(edit != null && edit.id == row.id) &&
                      <React.Fragment> 
                        <Tooltip title="Mentés">
                          <span>
                            <IconButton  
                              onClick={()=>onSave(index)} 
                              className={classes.saveButton} 
                              aria-label="save"
                            >
                              <DoneIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                          <IconButton 
                            onClick={onCancel} 
                            className={classes.cancelButton} 
                            aria-label="cancel"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip> 
                      </React.Fragment> 
                    }            
                    </TableCell>
                  </StyledTableRow>
                      ))}
                      {data.length < 1 &&
                      <StyledTableRow>
                        <TableCell align="center" colSpan="5">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                        </TableCell>
                  </StyledTableRow>}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
          <Typography style={{ fontWeight: 'bold' }}>
            Új szűrő hozzáadása: 
          </Typography>
          <Tooltip title="Új hozzáadás">
              <span>
                  <IconButton 
                    disabled={edit != null || period===null || period===undefined || period==='' || period.disabled} 
                    onClick={onOpen} 
                    className={classes.addButton} 
                    aria-label="add"
                  >
                      <AddIcon />
                  </IconButton>
              </span>
          </Tooltip>
        </div>        
      </CardActions>
    </Card>
  );
};

export default DataTable;
