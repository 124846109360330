//React

import React, { Component } from 'react';
//Pickers

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Styles

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import TodayIcon from '@material-ui/icons/Today';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DateTimePicker = props => {
  const { value, disabled, id, name, error, label, minDate, maxDate, required, readOnly, onChange, helperText } = props;
  const classes = style.useStylesForPicker();

  return (
    <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
      <DatePicker
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={disabled}>
                <TodayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
        helperText={helperText}
        clearable={true}
        clearLabel="Törlés"
        emptyLabel="Nincs megadva"
        id={id}
        inputVariant="outlined"
        margin="dense"
        fullWidth
        name={name}
        required={required}
        className={classes.picker}
        cancelLabel="Vissza"
        openTo="date"
        label={label}
        readOnly={readOnly}
        disabled={disabled}
        format="yyyy MMMM dd"
        minDate={minDate}
        minDateMessage="Nem megengedett érték"
        maxDate={maxDate}
        views={["year", "month", "date"]}
        autoOk
        value={(value !== null && value !== undefined) ? value : null}
        onChange={date => onChange(date)}
      />
    </MuiPickersUtilsProvider >
  )
}


class NewDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hireDate: '',
      fullName: '',
      domainName: '',
      email: '',
      endDate: '',
      bio: '',
      leader: '',
      hr: '',
      organization: '',
      company: '',
      companies: [],
      bios: [],
      leaders: [],
      hrs: [],
      organizations: [],
      loading: {
        companies: true,
        bios: false,
        leaders: false,
        organizations: false,
        hrs: false
      }
    }

  }

  componentDidMount() {
    apiRequest.populateCompanies(this);
    apiRequest.populateHrs(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { company, organization, hireDate } = this.state;

    if (prevState.company != company) {
      if (company == null || company == undefined || company == '') {
        this.setState({
          bio: '',
          leader: '',
          organization: ''
        })
      } else {
        this.setState(prevState => ({
          loading: {
            ...prevState.loading,
            bios: true,
            organizations: true
          }
        }))
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
      }
    }
    if (prevState.organization != organization) {
      if (organization == null || organization == undefined || organization == '') {
        this.setState({
          leader: '',
        })
      } else {
        this.setState(prevState => ({
          loading: {
            ...prevState.loading,
            leaders: true,
          }
        }))
        apiRequest.populateLeaders(this);
        apiRequest.getLeader(this);
      }
    }
    if (prevState.hireDate != hireDate) {
      var newDate = new Date(new Date(hireDate).setMonth(new Date(hireDate).getMonth() + 3));
      const endDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
      this.setState({
        endDate
      });
    }
  }

  handleDateChange = (date, props) => {

    let newDate = null;
    if (date !== null && date !== undefined) {
      newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
    }
    this.setState({
      [props]: newDate
    });
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }


  handleSelectChange = (props, value) => {
    this.setState({
      [props]: value
    });
  }

  validateFields = (value) => {
    if (value === null || value === undefined || value === '') {
      return false;
    }
    return true;
  }

  handleSave = () => {
    apiRequest.saveUser(this);
  }

  render() {
    const { onClose, open, classes } = this.props;
    const { hireDate, fullName, domainName, email, endDate, bio, loading, bios, organizations, organization, leaders, leader, company, companies, hr, hrs } = this.state;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={onClose}>Új belépő</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12} sm={6} item>
                <TextField
                  error={!this.validateFields(fullName)}
                  required
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="fullName"
                  onChange={this.handleChange}
                  value={fullName}
                  label="Teljes név"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <DateTimePicker
                  error={!this.validateFields(hireDate)}
                  label="Belépés dátuma"
                  required={true}
                  value={hireDate === '' ? null : hireDate}
                  onChange={(date) => this.handleDateChange(date, 'hireDate')}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <DateTimePicker
                  error={!this.validateFields(endDate)}
                  label="Próbaidő vége"
                  required={true}
                  value={endDate === '' ? null : endDate}
                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  error={!this.validateFields(domainName)}
                  required
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="domainName"
                  onChange={this.handleChange}
                  value={domainName}
                  label="Domain név"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="email"
                  onChange={this.handleChange}
                  value={email}
                  label="Email cím"
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Autocomplete
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-company"
                  name="company"
                  value={company}
                  onChange={(event, value) => this.handleSelectChange("company", value)}
                  options={companies}
                  getOptionLabel={(option) => option.label === undefined ? '' : option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      margin="dense"
                      error={!this.validateFields(company)}
                      label="Cégcsoport"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.companies ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Autocomplete
                  disabled={(company === '' || company === null || company === undefined) ? true : false}
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-bio"
                  name="bio"
                  value={bio}
                  onChange={(event, value) => this.handleSelectChange("bio", value)}
                  options={bios}
                  getOptionLabel={(option) => option.label === undefined ? '' : option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      margin="dense"
                      error={!this.validateFields(bio)}
                      label="Munkakör"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.bios ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Autocomplete
                  disabled={(company === '' || company === null || company === undefined) ? true : false}
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-organization"
                  name="organization"
                  value={organization}
                  onChange={(event, value) => this.handleSelectChange("organization", value)}
                  options={organizations}
                  getOptionLabel={(option) => option.label === undefined ? '' : option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      margin="dense"
                      error={!this.validateFields(organization)}
                      label="Szervezeti egység"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.organizations ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Autocomplete
                  disabled={(organization === '' || organization === null || organization === undefined) ? true : false}
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-leader"
                  name="leader"
                  value={leader}
                  onChange={(event, value) => this.handleSelectChange("leader", value)}
                  options={leaders}
                  getOptionLabel={(option) => option.fullName === undefined ? '' : option.fullName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!this.validateFields(leader)}
                      label="Felettes"
                      variant="outlined"
                      margin="dense"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.leaders ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <Autocomplete
                  clearText="Alaphelyzet"
                  noOptionsText="Nincs találat"
                  closeText="Bezárás"
                  id="select-hr"
                  name="hr"
                  value={hr}
                  onChange={(event, value) => this.handleSelectChange("hr", value)}
                  options={hrs}
                  getOptionLabel={(option) => option.fullName === undefined ? '' : option.fullName}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!this.validateFields(hr)}
                      label="Hr-es"
                      variant="outlined"
                      margin="dense"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading.hrs ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              endIcon={<BackspaceIcon />}
              className={classes.secondaryButton}
              size="small"
              onClick={onClose}
              variant="outlined"
              color="secondary"
            >
              Vissza
            </Button>
            <Button
              disabled={!this.validateFields(hr) || !this.validateFields(leader) || !this.validateFields(organization) || !this.validateFields(bio) || !this.validateFields(company) || !this.validateFields(domainName) || !this.validateFields(fullName) || !this.validateFields(hireDate) || !this.validateFields(endDate)}
              endIcon={<SaveIcon />}
              className={classes.mainButton}
              size="small"
              onClick={this.handleSave}
              variant="outlined"
            >
              Mentés
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(style.useStyles)(NewDialog);