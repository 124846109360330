import React, { Component } from 'react';
//Core
import {
    Typography,
    Grid,
    CircularProgress,
    Backdrop
} from '@material-ui/core';
//Components
import { UserSelector, Error, NotificationBar, MuiOutlinedSelect } from 'components';
import { TabPanels } from './components';
import { Constants } from 'stores';
import {apiRequest} from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';



class PreviousYearsGoals extends Component {

    constructor(props) {
        super(props);


        Constants.setcontentLoading(true);
        apiRequest.populatePeriods(this);

        this.state = {
            users: [],
            goalTypes: [],
            periods: [],
            value: 0,
            loading: {
                users: true,
                goalTypes: true,
                periods: true,
            },
            error: false,
            selected: {
                period: null
            }
        }
        
    }


    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { selected, periods, loading } = this.state;
        if (prevState.selected.period != selected.period) {
            if (this.state.selected.period !== null && selected.period !== undefined) {
                apiRequest.populateUsers(this,selected.period);
                apiRequest.populateGoalTypes(this,selected.period);
                this.setState({
                    value:0
                });
            }
        }
        if (prevState.periods != periods) {
            if (periods.length < 1) {
                NotificationBar(this.props.snackbar, 'Nincsenek kiválasztható előző periódusok', 'top', 'right', 4000, 'info', null);
                this.props.history.push('/home');
            }
        }
        if (prevState.loading.periods != loading.periods) {
            if (loading.periods === false && periods.length >0) {
                Constants.setcontentLoading(false);
            }
        }
    }

    //#EndLifecycle methods

    handleChange = (event, newValue) => {
        const classList = event.target.parentElement.classList.value.toUpperCase();
        if (classList.includes('AVATAR')) {
            this.setState({
                value: newValue
            })
        }
    };

    handleError = () => {
        this.setState({
            error: true
        });
    }

    handleSelectChange = (event) => {
        const { selected } = this.state;
        this.setState(prevState => ({
            selected: {
                ...prevState.select,
                period: event.target.value
            },
            loading: {
                ...prevState.loading,
                users: true,
                goalTypes:true
            },
            users: [],
            goalTypes:[]
        }));
   
    }

    render() {
        const { loading, users, value, goalTypes, periods, selected, error } = this.state;
        const { snackbar, classes } = this.props;
        return (loading.periods === false && <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={10}
                    xs={12}
                >
                   
                    <Typography className={classes.title}>Célkitűzések</Typography>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={3}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <MuiOutlinedSelect margin="dense" disabled={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} error={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} helperText={(periods !== null && periods !== undefined && periods.length > 0) ? '' : "Nem található előző periódus"} className={classes.select} menuList={periods} handleChange={this.handleSelectChange} value={selected.period !== null ? selected.period : 0} label="Előző periódusok" />
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex' }}>
                       
                    </div>
                    {selected.period != null && (loading.users || loading.goalTypes ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> : < UserSelector data={users} value={value} onChange={this.handleChange}>
                            <TabPanels periodId={selected.period} onError={this.handleError} goalTypes={goalTypes} snackbar={snackbar} value={value} index={value} userId={users[value].id} />
                        </UserSelector>)}
                </Grid>
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
            </Grid>
        </div>);
    }
}

export default withStyles(style)(PreviousYearsGoals)
