import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    help: {
        margin: theme.spacing(2),
    },
    helpIcon: {
        color: theme.palette.appMain.main,
        '&:hover': {
            color: theme.palette.appMain.dark,
        }
    },
    helperPaper: {
        padding: theme.spacing(2),
    },
    helperText: {
        fontFamily: 'Nunito',
    }
}));

export default useStyles;

