import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { Button } from '@material-ui/core';
//Components
//Icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
//Styles
import style from './style';

const HelperPlan = props => {
  const { className,onLogoutDialogOpen,snackbar, ...rest } = props;

    const classes = style();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
          <div className={classes.actions}>
                  <Button                    
                      className={classes.buttons}
                      startIcon={<PeopleIcon />}
                      component={RouterLink}
                      to="/home"
                      variant="contained"
                  >
                      TÉR RENDSZER
                  </Button> 
            </div>
    </div>
  );
};

HelperPlan.propTypes = {
  className: PropTypes.string
};

export default HelperPlan;
