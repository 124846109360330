
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    saveNotes:'exit/save-note',
    getNotes:'exit/get-note'
}

export const apiRequest = {
    save,
    populateNote,
};

async function populateNote(rowId,setNote,setCreatedBy,setModifiedBy) {
    await new Api().call.get(`${Actions.getNotes}/${rowId}`)
    .then(handleResponse)
    .then(res => {
        setNote(res.note);
        setCreatedBy(res.createdBy);
        setModifiedBy(res.modifiedBy);
    }).catch(handleError);      
}

async function save(model,onClose) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.saveNotes,model)
    .then(handleResponse)
    .then(res => {
        onClose();
    }).catch(handleError);      
}

