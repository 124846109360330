import theme from 'theme';

const useStyles = ({
    headerTitle: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '0.2',       
        },
        backgroundColor: theme.palette.papper
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    }
});

export default useStyles;
