//React

import React,{ Component } from 'react';
import clsx from 'clsx';
//Components

import {
  ConfirmDialog,
  NewDialog
} from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';
//Core

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  LinearProgress,
  InputLabel
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function Row(props) {
  const {main, compGroups, inputs, edit, onEdit, onAddNew, onSave, onCancel, onDelete, deleted, mainIndex, onChange, periods, period} = props;
  const [open, setOpen] = React.useState(false);
  
  const classes = style.useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.ratingPointsMainGroup.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.ratingPointsMainGroup.id}
        </TableCell>
        <TableCell align="center">
        <TextField
            name="label"
            onChange={(event)=>onChange(event,mainIndex,null,"label")}
            disabled={(edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
            value={(main.ratingPointsMainGroup.label===null || main.ratingPointsMainGroup.label===undefined) ? '' : main.ratingPointsMainGroup.label}
          />
         
        </TableCell>
        <TableCell align="center">
          <TextField
            disabled={(edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
            type="number"
            name="selfRatingSum"
            onChange={(event)=>onChange(event,mainIndex,null,"selfRatingSum",true)}
            value={(main.ratingPointsMainGroup.selfRatingSum===null || main.ratingPointsMainGroup.selfRatingSum===undefined) ? '' : main.ratingPointsMainGroup.selfRatingSum}
          />
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validFrom")}
            value={main.ratingPointsMainGroup.validFrom}
          >
            {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validTo")}
            value={main.ratingPointsMainGroup.validTo}
          >
             {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        {(edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id)) && <React.Fragment>
        <Tooltip title="Módosítás">
          <span>
            <IconButton 
              onClick={()=>onEdit(main.ratingPointsMainGroup)} 
              className={classes.editIcon}
              disabled={main.ratingPointsMainGroup.disabled || edit!=null || period===null || period.disabled===true}>
              <EditIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main.ratingPointsMainGroup)}   
              className={classes.deleteButton}
              disabled={main.disabled || period===null || edit!=null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
        </Tooltip></React.Fragment>
        }
        {(edit != null && edit.id == main.ratingPointsMainGroup.id) &&
          <React.Fragment> 
            <Tooltip title="Mentés">
              <span>
                <IconButton  
                  onClick={()=>onSave(mainIndex)} 
                  className={classes.saveButton} 
                  aria-label="save"
                >
                  <DoneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Mégsem">
              <IconButton 
                onClick={onCancel} 
                className={classes.cancelButton} 
                aria-label="cancel"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> 
          </React.Fragment> 
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Megnvezés</TableCell>
                    <TableCell padding="none" align="center">Súly</TableCell>
                    <TableCell padding="none" align="center">Darabszám</TableCell>
                    <TableCell padding="none" align="center">Értékek</TableCell>
                    <TableCell padding="none" align="center">Csoport</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.ratingPointsSubGroups.map((sub,subIndex) => (
                    <TableRow className={(deleted!==null && deleted.id==sub.id) ? classes.deleted : ''} key={sub.id}>
                      <TableCell padding="checkbox" align="left" component="th" scope="row">
                        {sub.id}
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                          {sub.parentId}
                      </TableCell>
                      <TableCell align="right">
                          <TextField
                            name="label"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"label")}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
                            value={(sub.label===null || sub.label===undefined) ? '' : sub.label}
                          />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"weight",true)}
                            name="weight"
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
                            type="number"
                            value={(sub.weight===null || sub.weight===undefined) ? '' : sub.weight}
                        />
                      </TableCell>
                      <TableCell align="right">
                          <TextField
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"defQuantity",true)}
                            name="defQuantity"
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
                            type="number"
                            value={(sub.defQuantity===null || sub.defQuantity===undefined) ? '' : sub.defQuantity}
                          />
                      </TableCell>
                      <TableCell align="right">
                          <Select
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"inputPointMatrixId")}
                            name="inputPointMatrixId"
                            fullWidth
                            value={(sub.inputPointMatrixId===null || sub.inputPointMatrixId===undefined) ? '' : sub.inputPointMatrixId}
                          >
                           {inputs.map((item,index)=>(
                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                              ))}
                          </Select>
                      </TableCell>
                      <TableCell align="right">
                          <Select
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"competenceGroupId")}
                            name="competenceGroupId"
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))}
                            fullWidth
                            value={(sub.competenceGroupId===null || sub.competenceGroupId===undefined) ? '' : sub.competenceGroupId}
                          >
                              {compGroups.map((item,index)=>(
                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                              ))}
                          </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validFrom"
                          fullWidth
                          onChange={(event)=>onChange(event,mainIndex,subIndex,"validFrom")}
                          value={sub.validFrom}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validTo"
                          fullWidth
                          onChange={(event)=>onChange(event,mainIndex,subIndex,"validTo")}
                          value={sub.validTo}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Törlés">
                          <span>
                            <IconButton
                              onClick={()=>onDelete(sub)}   
                              className={classes.deleteButton}
                              disabled={main.disabled || period===null || edit!=null || period.disabled===true}>
                              <DeleteIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell padding="none" colSpan={10}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új pont létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton
                                onClick={()=>onAddNew(1,main.ratingPointsMainGroup.id)}  
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != main.ratingPointsMainGroup.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


class CompGroupTree extends Component {

    constructor(props){
        super(props);
        this.state={
            ratingPoints:[],
            compGroups:[],
            inputs:[],
            periods:[],
            period:'',
            edit:null,
            deleted:null,
            loading:null,
            newPoint:null,
            parentId:null,
        };
    }

    componentDidMount(){
        apiRequest.populateCompGroups(this);
        apiRequest.populateInputs(this);
        apiRequest.populatePeriods(this);
    }

    componentDidUpdate(prevProps,prevState){
      const {period} = this.state;
      if(prevState.period!=period){
        this.setState({
          loading:true
        });
        apiRequest.populateRatingPoints(this,period.id);
      }
    }

    handleEdit = (row) =>{
      this.setState({
        edit:{ ...row }
      });
    }

    handleCancel = () =>{
      const {period} = this.state;
      this.setState({
        loading:true,
        edit:null
      });
      apiRequest.populateRatingPoints(this,period.id);
    }

    handleNewPoint = (level,parentId) =>{
      this.setState({
        newPoint:level,
        parentId:parentId
      });
    }

    afterAddedNewPoint = () =>{
      const {period} = this.state;
      this.setState({
        newPoint:null,
        parentId:null,
        loading:true,
        edit:null
      });
      apiRequest.populateRatingPoints(this,period.id)
    }

    handleChange = (event,mainIdex,subIndex,props,isNumber) => {
      let value = event.target.value;
      const ratingPoints = [...this.state.ratingPoints];
      if(isNumber){
        value=value*1;
      }
      if(subIndex===null){
        ratingPoints[mainIdex].ratingPointsMainGroup[props] = value;
      }else{
        ratingPoints[mainIdex].ratingPointsSubGroups[subIndex][props] = value;
      }
      
      this.setState({ ratingPoints });
    }

    handleSave = (mainIdex) =>{
        const {ratingPoints,period} = this.state;
        const model = {...ratingPoints[mainIdex],period:period.id};
        
        apiRequest.saveChanges(this,model,period.id);        
    }

    handlePeriodChange = (event) =>{
      const value = event.target.value;
      this.setState({
        period:value
      });
    }

    handleDelete = (row) =>{
      this.setState({
        deleted:row
      });
    }

    deleteRow = () =>{
      const {deleted,period} = this.state;
      const model = {id:deleted.id,period:period.id};
      apiRequest.deletePoint(this,model,period.id);
    }

    render(){
        const {ratingPoints,compGroups,inputs,edit,periods,period,deleted,parentId,newPoint,loading} = this.state;
        const {classes} = this.props;
        return (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="period-select-label">Periódusok</InputLabel>
                  <Select
                    labelId="period-select-label"
                    id="period-select"
                    value={period}
                    onChange={this.handlePeriodChange}
                  >
                    {periods.map(period=>
                    <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                  </Select>
              </FormControl>
              {period!=='' && <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                  <Typography style={{ fontWeight: 'bold' }}>Új csoport létrehozása: </Typography>
                  <Tooltip title="Új hozzáadás">
                      <span>
                          <IconButton
                            onClick={()=>this.handleNewPoint(0)} 
                            disabled={edit != null || period.disabled ? true : false}
                            className={classes.addButton} 
                            aria-label="add"
                          >
                              <AddIcon />
                          </IconButton>
                      </span>
                  </Tooltip>
              </div>}
              <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <PerfectScrollbar>
              <Table aria-label="collapsible table">
                  <TableHead>
                  <TableRow className={classes.head}>
                      <TableCell />
                      <TableCell padding="checkbox" align="left">Azonositó</TableCell>
                      <TableCell padding="none" align="center">Megnevezés</TableCell>
                      <TableCell padding="none" align="center">Összeg</TableCell>
                      <TableCell padding="none" align="center">Érvényes-től</TableCell>
                      <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                      <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {ratingPoints.map((main,index) => (
                      <Row 
                        key={index}
                        mainIndex={index} 
                        main={main} 
                        compGroups={compGroups} 
                        inputs={inputs}
                        deleted={deleted} 
                        edit={edit}
                        period={(period==='' || period===undefined || period===null) ? null : period}
                        periods={periods}
                        onChange={this.handleChange} 
                        onDelete={this.handleDelete}
                        onAddNew={this.handleNewPoint}
                        onEdit={this.handleEdit}
                        onSave={this.handleSave}
                        onCancel={this.handleCancel}
                      />
                  ))}
                  {(period==='' || period===undefined || period===null) ?
                    <TableRow>
                        <TableCell align="center" colSpan="7">
                          Nincs kválasztva periódus
                        </TableCell>
                    </TableRow>
                    :  ratingPoints.length < 1 && 
                    <TableRow>
                      <TableCell align="center" colSpan="7">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                      </TableCell>
                    </TableRow>
                  }
                  </TableBody>
              </Table>
              </PerfectScrollbar>
              </TableContainer>           
              <ConfirmDialog 
                onClose={()=>this.handleDelete(null)} 
                onDelete={this.deleteRow} 
                open={deleted!==null}
              />
              {newPoint!=null && <NewDialog
                parentId={parentId}
                refresh={this.afterAddedNewPoint}
                period={period}
                compGroups={compGroups}
                inputs={inputs}
                onClose={()=>this.handleNewPoint(null)} 
                level={newPoint}
                open={newPoint!=null}
              />}
            </div>
        );
    }
}
export default withStyles(style.useStyles)(CompGroupTree)