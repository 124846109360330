import theme from 'theme';

const useStyle = ({
    root: {
        flexGrow: 1,
        width: '100%',
        '& .MuiCardHeader-root':{
            backgroundColor:'#f5f5f5'
        }
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize: '0.9rem'

    },
    leaderTitle: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize: '1.0rem'
    },
    leaderSubTitle: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize: '0.8rem'
    },
    tabs: {
        '& .MuiTab- textColorPrimary': {
            color: theme.palette.white
        },
        margin: 'auto'
    },
    doneIcon:{
        color:theme.palette.success.main,
        '&:hover':{
            color:theme.palette.success.dark,
        }
    },
    notDoneIcon:{
        color:theme.palette.error.main,
        '&:hover':{
            color:theme.palette.error.dark,
        }
    },
    avatar: {
        height: 80,
        width: 80
    },
    icon: {
        height: 32,
        width: 32,
        color: theme.palette.appMain.main
    }
});
export default useStyle
