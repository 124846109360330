import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles
import style from './style';
//Core
import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Button,
  IconButton,
  LinearProgress
} from '@material-ui/core';
import { enums } from 'stores'
//Icons
import NoteIcon from '@material-ui/icons/Note';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import EmailIcon from '@material-ui/icons/Email';
//Components
import {ExitDialog,NoteDialog,EmailDialog} from  '../../components';


const StyledTableRow = style.StyledTableRow;

const  InterViewsTypes = enums.interViewsTypes;

const UsersTable = props => {
    const { className, onRefresh,users,loading, onSelect, ...rest } = props;
  const classes = style.useStyles();

  const [note,setNote] = React.useState(null);
  const [exit,setExit] = React.useState(null);
  const [email,setEmail] = React.useState(null);

  React.useEffect(()=>{
    if(email===null){
      onRefresh();
    }
  },[email])


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Teljes név</TableCell>
                  <TableCell>Munkakör</TableCell>
                  <TableCell>Szervezeti egység</TableCell>
                  <TableCell>Felettes</TableCell>
                  <TableCell>Kiépés dátuma</TableCell>
                  <TableCell>Felmondás módja</TableCell>
                  <TableCell>Kiléptetés pontos ideje</TableCell>
                  <TableCell align="center">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                    <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                                    src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        >
                        </Avatar>
                        <Typography variant="body1">{user.fullName}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.bio}</TableCell>
                    <TableCell>
                      {user.organization}
                    </TableCell>     
                    <TableCell>
                      {(user.leader!=null && user.leader!=undefined) && user.leader.fullName}
                    </TableCell>   
                    <TableCell>
                      {user.exitTime}
                    </TableCell>   
                    <TableCell>
                      {user.exitType}
                    </TableCell>  
                    <TableCell>
                      {user.leaveOutDateTime}
                    </TableCell>  
                    <TableCell align="center">
                      <div style={{display:'flex',alignItems:'center',gap:"0.5rem", justifyContent:'center'}}>
                        <IconButton onClick={()=>setNote(user)}  size="small" variant="contained"  color="primary"><NoteIcon/></IconButton>
                        <IconButton onClick={()=>setExit(user)}  size="small" variant="contained" color="primary"><RecordVoiceOverIcon/></IconButton>
                        <IconButton onClick={()=>setEmail(user)}  size="small" variant="contained" color="primary">{user.notificationIsSent ? <EmailIcon className={classes.sentButton}/> : <EmailIcon/>}</IconButton>
                      </div>
                    </TableCell>                  
                  </StyledTableRow>
                ))}
                              {users.length < 1 &&
                                  <StyledTableRow>
                                      <TableCell align="center" colSpan="9">
                                      {loading ? 'Betöltés...' : 'Nincs találat'}
                                  </TableCell>
                                  </StyledTableRow>}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
     {exit!==null && <ExitDialog open={true} selected={exit} onClose={()=>setExit(null)} />}
     {note!==null && <NoteDialog open={true} selected={note} onClose={()=>setNote(null)} />}
     {email!==null && <EmailDialog onRefresh={onRefresh} open={true} selected={email} onClose={()=>setEmail(null)} />}
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
