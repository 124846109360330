//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar, Progress } from 'components';
//Actions

const Actions = {
    SaveAccount: 'admin/user/account/update',
}

export const apiRequest = {
    saveAccount,
};

//#Api calls
async function saveAccount(parent) {
    const { refreshProfile, userId } = parent.props;

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const { _user } = parent.state;
    const model = {
        userId,
        fullName: _user.fullName,
        userName: _user.userName,
        telephone: _user.telephone,
        email: _user.email
    }

    await new Api().call.post(Actions.SaveAccount, model)
        .then(handleResponse)
        .then(res => {
            refreshProfile();
        }).catch(handleError);
}

