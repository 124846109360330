import theme from 'theme';

const useStyles = ({
    appBar:{
        height:'70px',
        backgroundColor:theme.palette.warning.light,
    },
    closeIcon:{
        color:'white'
    },
    root: {
        padding: theme.spacing(4),
        textAlign:'center',
        backgroundImage: 'url(/images/main.png)'
    },
    info:{
        fontWeight:'bold'
    },
    footer:{
        margin:'1.0rem',
        textAlign:'right'
    },
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            color:theme.palette.success.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.dark}`
        },
        '&:disabled': {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.light,
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInputLabel-formControl':{
            color:'white'
        },
        '& .MuiSelect-select':{
            color:'white'
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
  });

  export default useStyles;