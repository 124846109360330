import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoalTypes: 'goal/previous/types',
    GetPeriods: 'goal/own/periods',
    GetGoals: 'goal/previous/own/goals'
}


export const apiRequest = {
    populateGoals,
    populatePeriods,
    populateGoalTypes
};

//#Api calls
async function populateGoals(parent,currentPage, _typeId, periodId) {
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = {periodId:(periodId === null || periodId === undefined) ? -1 : periodId,typeId,currentPage:pageNumber};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                goals: res,
                loading: {
                    ...prevState.loading,
                    goals: false
                },
            }));
        }).catch(handleError);

}

async function populatePeriods(parent) {
    
    /*/var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }*/

        await new Api().call.get(Actions.GetPeriods)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    periods: false,
                },
                periods: res
            }));
        }).catch(handleError);

}

async function populateGoalTypes(parent,periodId) {
        await new Api().call.get(`${Actions.GetGoalTypes}/${periodId}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    goalTypes: false,
                },
                goalTypes: res,
                selectedType: null,
            }));

        }).catch(handleError);

}

