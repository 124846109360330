import React from 'react';
//Components
import { MuiOutlinedInput } from 'components'; 

export default function WriteDescription(props) {
    const { handleChange, value, disabled} = props;

    return (
        <MuiOutlinedInput
            className={!(value !== null && value !== '') && "goal-new-add-notvalid"}
            multiline={true}
            rows={3}
            margin="dense"
            disabled={disabled}
            readOnly={false}
            value={value}
            helperText={!(value !== null && value !== '') && 'Kötelező kitölteni'}
            error={(value!==null && value!=='') ? false : true}
            handleChange={(event) => handleChange('addNew',event)}
            id={`textfield-add-new-goal-description`}
            label="Célkitűzés" />
    );
}
