import palette from '../../../../theme/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: palette.appMain.main,
    },
    adminRoot:{
        backgroundColor: palette.warning.light,
    },
    flexGrow: {
        flexGrow: 1
    },
    signOutButton: {
        marginLeft: theme.spacing(1)
    },
    brandName: {
        marginLeft: theme.spacing(1),
        color: palette.white,
        fontSize: '1.2rem',
        fontWeight: 'bold',
        fontFamily: 'Nunito'
    }
}));

export default useStyles;
