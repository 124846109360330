import theme from 'theme';

const useStyles = ({
    appBar:{
        height:'60px',
        backgroundColor:theme.palette.warning.light,
    },
    closeIcon:{
        color:'white'
    },
    root: {
        padding: theme.spacing(4),
        backgroundImage: 'url(/images/main.png)'
    },
    backButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        }
    },
    toolbar: {
        display:'flex',
        margin: theme.spacing(2),
    },
    warningIcon: { 
        marginLeft: 'auto',
        color: theme.palette.warning.main
    },
    approvedIcon: {
        marginLeft: 'auto',
        color: theme.palette.warning.dark
    },
    helperPaper: {
        padding: theme.spacing(2),
    },
    approveButton: {
        color: 'white',
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    approvedButton: {
        color: 'white',
        backgroundColor: theme.palette.warning.dark,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
        '&:disabled': {
            color: 'white',
            backgroundColor: theme.palette.warning.dark,
        }
    }
});

export default useStyles;
