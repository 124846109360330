import { makeStyles,withStyles } from '@material-ui/core/styles';
//Core
import {
    Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '1.6',       
        },
        backgroundColor: theme.palette.papper
    },
    compName: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
    },
}));

const StyledTooltip = withStyles({
    tooltip: {
        fontSize:'1.0rem'
    }
})(Tooltip);

export default {
useStyles:useStyles,
StyledTooltip:StyledTooltip
}
