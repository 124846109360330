//React

import React from 'react';
//Api

import {Api,handleResponse,handleError} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Helpers

import { Constants } from 'stores';
//Actions

const Actions = {
    GetUser: 'admin/user/main-evaluators-of-users/edit',
    GetEvaluator: 'admin/user/main-evaluators-of-users/active-evaluator',
    SaveEvaluator: 'admin/user/main-evaluators-of-users/edit/save',
    DeleteEvaluator: 'admin/user/main-evaluators-of-users/delete'
}

export const apiRequest = {
    getUser,
    getEvaluator,
    deleteEvaluator,
    saveEvaluator
};

async function getUser(parent) {
    const { user } = parent.props;
        await new Api().call.get(`${Actions.GetUser}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                user: res.user,
                isModifable: res.isModifable,
                evaluators: res.editEvaluatorsTable,
                lastValidFromForAdd: res.lastValidFromForAdd,
                lastValidFromForEdit: res.lastValidFromForEdit,
                baseEvaluators: [...res.editEvaluatorsTable],
                loading: {
                    ...prevState.loading,
                    user: false
                }
            }));
        }).catch(handleError);

}

async function getEvaluator(parent) {
    const { user } = parent.props;
        await new Api().call.get(`${Actions.GetEvaluator}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                evaluator: res,
                loading: {
                    ...prevState.loading,
                    evaluator: false
                }
            }));
        }).catch(handleError);

}

async function deleteEvaluator(parent,evaluator) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { evaluator };
        await new Api().call.post(Actions.DeleteEvaluator, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true,
                    evaluator: true
                },
            }));
            getUser(parent);
            getEvaluator(parent);
            parent.props.refresh();
        }).catch(handleError);

}
async function saveEvaluator(parent,evaluator) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { evaluator };
        await new Api().call.post(Actions.SaveEvaluator, model)
        .then(handleResponse)
        .then(res => {
            Constants.setcontentLoading(true);
            parent.setState(prevState => ({
                addNew: false,
                loading: {
                    ...prevState.loading,
                    user: true,
                    evaluator: true
                },
            }));
            getUser(parent);
            getEvaluator(parent);
            parent.props.refresh();
        }).catch(handleError);

}