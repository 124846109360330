//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    ExportMotivationDatas: 'admin/ExportDatas/export-motivations',
    ExportSelfRatings: 'admin/ExportDatas/export-selfRatings',
    ExportLeaderRatings: 'admin/ExportDatas/export-leaderRatings',
    ExportGeneralCompetences: 'admin/ExportDatas/export-general-competences-result',
    ExportLeaderCompetences: 'admin/ExportDatas/export-leader-competences-result',
    ExportSumCompetences: 'admin/ExportDatas/export-sum-competences-result',
    ExportNewGoalResult: 'admin/ExportDatas/export-new-goal-result',
    ExportGoalResult:'admin/ExportDatas/export-goal-result',
    GetPeriods:'admin/ExportDatas/periods'
}

export const apiRequest = {
    exportMotivationDatas,
    exportSelfRatings,
    exportLeaderRatings,
    exportGeneralCompetences,
    exportLeaderCompetences,
    exportSumCompetences,
    exportNewGoalResult,
    exportGoalResult,
    populatePeriods
};

//#Api calls
async function exportMotivationDatas(parent) {
    const model = {period:parent.state.period.id};
    await new Api().call.post(Actions.ExportMotivationDatas,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));

    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportSelfRatings(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportSelfRatings,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportLeaderRatings(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportLeaderRatings,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportGeneralCompetences(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportGeneralCompetences,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportLeaderCompetences(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportLeaderCompetences,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportSumCompetences(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportSumCompetences,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function exportNewGoalResult(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportNewGoalResult,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}



async function exportGoalResult(parent) {
    const model = {period:parent.state.period.id};

    await new Api().call.post(Actions.ExportGoalResult,model)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                download: false,
            },
        }));
    });
}

async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                periods: false,
            },
            periods: res
        }));
    }).catch(handleError);
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }
