import theme from 'theme';

const useStyles = ({
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.dark}`
        }
    }
});


export default useStyles;
