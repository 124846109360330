//React

import React, { Component } from 'react';
//Core

import { Card, 
    Typography, 
    CardHeader,
    Avatar, 
    IconButton
} from '@material-ui/core';
//Helpers

import { UserStore } from 'stores';
//Icons

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
//Api

import {apiRequest} from './apiRequest';


class LeaderBox extends Component {


     handleClick = (event, index) => {
        apiRequest.handleSaveChanges(this);      
    };

    handleCloseClick = () => {
        const { anchorEl } = this.state;
        if (anchorEl !== null) {
            this.setState({
                anchorEl: null
            });
        }
    };

    setConversation = () => {
        this.setState({
            anchorEl: null
        });
        apiRequest.handleSaveChanges(this);    
    } 

    render() {
        const {classes, periodIsActive, leader} = this.props;
        const loggedBy =  UserStore.getLoggedByAdminValue();

        return (
            <div className={classes.root}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar
                                className={classes.avatar}
                                src={(leader.avatar !== null && leader.avatar !== undefined) ? leader.avatar : ''}
                            />
                        }
                        action={(periodIsActive===true && (loggedBy===null || !loggedBy.period.disabled)) &&
                            (leader.conversationIsDone ? 
                            <span>
                            <IconButton 
                                onClick={this.setConversation} 
                                disabled={leader.conversationIsDone} 
                                aria-label="settings"
                            >
                                <RecordVoiceOverIcon  className={classes.doneIcon} />
                            </IconButton>
                            </span> :
                            <span>
                            <IconButton 
                                onClick={this.setConversation} 
                                disabled={leader.conversationIsDone} 
                                aria-label="settings"
                            >
                                <RecordVoiceOverIcon  className={classes.notDoneIcon} />
                            </IconButton>
                            </span>
                            )}
                        title={<Typography className={classes.leaderTitle}>{leader.fullName}</Typography>}
                        subheader={<Typography className={classes.leaderSubTitle}>Főértékelő</Typography>}
                    />
                </Card>
            </div>
        )
    }
}
export default withStyles(style)(LeaderBox);
