//React

import React from 'react';
import PropTypes from 'prop-types';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    IconButton,
    Typography,
    Grid,
    TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons

import CloseIcon from '@material-ui/icons/Close';
//Components

import { NotificationBar } from 'components';
//Api

import { apiRequest } from './apiRequest';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';
import MuiButton from '../../../../theme/overrides/MuiButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(style)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const ForgotPwd = props => {
    const { closeHandler, open } = props;

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');


    function send() {
        apiRequest.forgotPwd({ name, email }).then(() => {
            NotificationBar('Sikeres beküldés', 'top', 'right', 2000, 'success', null);
            closeHandler();
        }).catch(() => { });
    }

    function validate() {
        if (name === null || name === undefined || name === '') {
            return false
        }
        if (email === null || email === undefined || email === '') {
            return false
        }

        return true;
    }

    return (
        <Dialog TransitionComponent={Transition} onClose={closeHandler} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
                Elfelejtetted a jelszavad?
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Töltsd ki a következő mezőket, majd kattints a küldés gombra! Ezután kollégáink jelentkeznek majd a jelszóval kapcsolatban.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            size='small'
                            label="Igénylő neve"
                            error={name === null || name === undefined || name === ''}
                            helperText={(name === null || name === undefined || name === '') && "Kötelező megadni"}
                            onChange={(event) => setName(event.target.value)}
                            value={name}
                            required
                            name="name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            size='small'
                            label="Igénylő email címe"
                            error={email === null || email === undefined || email === ''}
                            helperText={(email === null || email === undefined || email === '') && "Kötelező megadni"}
                            onChange={(event) => setEmail(event.target.value)}
                            value={email}
                            required
                            name="email"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MuiButton color="secondary" value="Vissza" onClick={closeHandler} />
                <MuiButton color="main" value="Küldés" disabled={!validate()} onClick={send} />
            </DialogActions>
        </Dialog>
    )
}

ForgotPwd.propTypes = {
    history: PropTypes.object
};

export default ForgotPwd;

