import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core

import {
    Box,
    Grid,
    CircularProgress,
    Typography,
    Divider,
    ButtonGroup,
    Button
} from '@material-ui/core';
//Components

import { 
    Pagination, 
    MuiOutlinedSelect, 
    SaveDialog 
} from 'components';
import { GoalsGrid } from './components';
import $ from "jquery";
import {UserStore} from 'stores';
import {apiRequest} from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';
import theme from 'theme';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class TabPanels extends Component {

    constructor(props) {
        super(props);
        const { goalTypes, lazyData } = this.props;
        this.state = {
            goals: lazyData.goals,
            baseGoals: lazyData.baseGoals,
            loading: false,
            isModified: false,
            isValid:false,
            selectedType: goalTypes !== null && goalTypes.length > 0 ? goalTypes[0] : null,
            autoSave: UserStore.getUserValue().autoSave,
            open: false,
            nextType: null,
            nextPage: null,
            nextGoalType:null
            
        };  

        this.initState = this.initState.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
    }

     
     //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { isModified } = this.state;

        if (this.props.userId !== prevProps.userId) {           
            const { selectedType } = this.state;
            if (isModified === true) {
                if (this.state.autoSave === true) {
                    this.handleSaveChanges(prevProps.userId).then(
                        this.initState().then(apiRequest.populateGoals(this,1, selectedType.id)));
                } else {
                        this.initState().then(apiRequest.populateGoals(this,1, selectedType.id));
                }
              
            } else {
                this.initState().then(apiRequest.populateGoals(this,1, selectedType.id))
            }
        }
        if (document.getElementsByClassName("goal-result-notvalid").length === 0) {
            if (prevState.isValid !== true) {
                this.setState({
                    isValid: true
                });
            }
        } else {
            if (prevState.isValid !== false) {
                this.setState({
                    isValid: false
                });
            }
        }
    }
    //#EndLifecycle methods

    //When change page on the pagination element
    handlePageChanges = (currentPage, goalType) => {
        const { isModified } = this.state;

        if (isModified === true) {
            if (this.state.autoSave === true) {
                this.handleSaveChanges(this.props.userId).then(
                    this.initState().then(apiRequest.populateGoals(this,currentPage, goalType.id)));
            } else {
                this.setState({
                    open: true,
                    nextPage: currentPage,
                    nextGoalType: goalType
                });
            }
            
        } else {
            this.initState().then(apiRequest.populateGoals(this,currentPage, goalType.id))
        }         
        $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
    }
    //Handle type change
    handleSelectChange = (event) => {
        //Change selected state
        const { goalTypes } = this.props;
        const { isModified } = this.state;

        const selectedType = goalTypes.find(type => type.id === event.target.value);
        if (isModified === true) {
            if (this.state.autoSave === true) {
                this.setState({
                    selectedType
                });
                this.handleSaveChanges(this.props.userId).then(
                    this.initState().then(apiRequest.populateGoals(this,1, selectedType.id)));
            } else {
                this.setState({
                    open: true,
                    nextType: selectedType
                });
            }
            
        } else {
            this.setState({
                selectedType
            });
            this.initState().then(apiRequest.populateGoals(this,1, selectedType.id))
        }
    }

    checkIsModified = () => {  
        const { goals, baseGoals } = this.state;

        var hashGoals = '';
        var hashbaseGoals = '';
        var goalsObject = [];
        var baseGoalsObject = [];


        goals.goalsForTable.map(goal => {
            goalsObject.push({ id: goal.id, value: goal.performedValue, skip: goal.isSkipped, reason: goal.reasonOfSkip })
        });

        baseGoals.goalsForTable.map(goal => {
            baseGoalsObject.push({ id: goal.id, value: goal.performedValue, skip: goal.isSkipped, reason: goal.reasonOfSkip  })
        });

        goalsObject.sort(this.compare).map((goal) => {
            hashGoals = hashGoals + goal.id;
            hashGoals = hashGoals + goal.value;
            hashGoals = hashGoals + goal.skip;
            hashGoals = hashGoals + goal.reasonOfSkip;
        });

        baseGoalsObject.sort(this.compare).map((goal) => {
            hashbaseGoals = hashbaseGoals + goal.id;
            hashbaseGoals = hashbaseGoals + goal.value;
            hashbaseGoals = hashbaseGoals + goal.skip;
            hashbaseGoals = hashbaseGoals + goal.reasonOfSkip;
        });

        if (hashGoals != hashbaseGoals) {
            this.setState({
                isModified: true
            })
            this.props.setParentState("isModified", true);
            this.props.setParentState("goals", JSON.parse(JSON.stringify(goals)))
        }
        else {
            this.setState({
                isModified: false
            })
            this.props.setParentState("isModified", false);
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            nextType: null,
            nextPage: null,
            nextGoalType:null
        });
    }

    handleBackChanges = () => {
        this.setState({
            isModified: false,
            goals: null,
            loading:true
        },
            ()=>this.setState({
                goals: JSON.parse(JSON.stringify(this.state.baseGoals)),
                loading:false
            }));
        this.props.setParentState("isModified", false);
    }

    async handleSaveChanges(userId) {
        const { goals } = this.state;

        var model = [];
        goals.goalsForTable.map(goal => {
            let skip = (goal.isSkipped !== undefined && goal.isSkipped !== null) ? goal.isSkipped : null;
            let reason = (goal.reasonOfSkip !== undefined && goal.reasonOfSkip !== null && goal.reasonOfSkip !== '') ? goal.reasonOfSkip : null;
            if (skip === false) {
                goal.reasonOfSkip = null;
            }
            if (goal.performedValue !== null && goal.performedValue !== undefined) {
                model.push({ id: goal.id, value: String(goal.performedValue), userId, typeId: goals.goalType.id, isSkipped: skip, reasonOfSkip: reason })
            }
            else {
                model.push({ id: goal.id, value: null, userId, typeId: goals.goalType.id, isSkipped: skip, reasonOfSkip: reason })
            }

        });
        await apiRequest.saveGoals(this,model);
    }

    handleAlertSave = () => {
        const { nextType, isModified, nextPage, nextGoalType } = this.state;
        const promise = this.handleSaveChanges(this.props.userId);
        
        promise.then(() => {
            if (nextType !== null) {
                this.setState({
                    selectedType: nextType,
                });
                this.initState().then(apiRequest.populateGoals(this,1, nextType.id))
            } else if (nextPage !== null) {
                this.initState().then(apiRequest.populateGoals(this,nextPage, nextGoalType.id));
            }

        }).catch(error => { });
    }

    handleMoveNext = () =>{
        const { nextType, nextPage, nextGoalType } = this.state;

        if (nextType !== null) {
            this.setState({
                selectedType: nextType,
            });
            this.initState().then(apiRequest.populateGoals(this,1, nextType.id))
        } else if (nextPage !== null) {
            this.initState().then(apiRequest.populateGoals(this,nextPage, nextGoalType.id));
        }
    }

    async initState() {
        await this.setState(prevState => ({
            loading: true,
            isModified: false,
            goals: null,
            baseGoals: null,
        }));
        this.props.setParentState("isModified", false);
    }


    //Helper for sort
    compare(a, b) {
        const idA = a.id;
        const idB = b.id;

        let comparison = 0;
        if (idA > idB) {
            comparison = 1;
        } else if (idA < idB) {
            comparison = -1;
        }
        return comparison;
    }



    render() {
        const { value, index, classes, goalTypes, snackbar } = this.props;
        const { goals, loading, open, selectedType, isModified, isValid } = this.state;

        return (           
            <TabPanel
                value={value}
                index={index}
            >
                <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <MuiOutlinedSelect
                        margin="dense" 
                        className={classes.select} 
                        menuList={goalTypes} 
                        handleChange={this.handleSelectChange} 
                        value={selectedType !== null ? selectedType.id : 0} 
                        label="Célkitűzések" 
                    />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {isModified === true && 
                            <div style={{textAlign:'right'}}>
                                <ButtonGroup >
                                    <Button
                                        onClick={() => this.handleSaveChanges(this.props.userId)}
                                        className={classes.doneButton}
                                        disabled={!isValid} 
                                        size="small"
                                        endIcon={ <DoneIcon/>}
                                    >
                                    Mentés
                                    </Button>
                                    <Button
                                        onClick={this.handleBackChanges}
                                        className={classes.cancelButton}  
                                        size="small"
                                        endIcon={ <CloseIcon/>}
                                    >
                                    Mégsem
                                    </Button>
                                </ButtonGroup>
                            </div>
                        }
                        {loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> :
                            <React.Fragment><Typography className={classes.subTitle}>{selectedType.label}</Typography>
                                <Divider className={classes.divider} />
                                <GoalsGrid
                                    selectedType={selectedType}
                                    checkIsModified={this.checkIsModified}
                                    data={goals.goalsForTable}
                                    currentPage={goals.currentPage}
                                />
                                {goals.step < goals.total && 
                                    <Pagination 
                                        goalType={selectedType} 
                                        onPageChanges={this.handlePageChanges} 
                                        step={goals.step} 
                                        currentPage={goals.currentPage} 
                                        pages={goals.pages} 
                                        total={goals.total} 
                                    />
                                }
                            </React.Fragment>
                        } 
                    </Grid>
                </Grid>
                <SaveDialog 
                    open={open} 
                    onSave={this.handleAlertSave} 
                    onMoveNext={this.handleMoveNext}
                    onClose={this.handleClose} 
                />
            </TabPanel>
        );
    }
   
}

export default withStyles(style)(TabPanels);
