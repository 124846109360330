import theme from 'theme';

const useStyles = () => ({

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    loginByRoot: {
        display: 'flex',
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1),
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    subName: {
        marginTop: theme.spacing(1),
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    bio: {
        fontFamily: 'Nunito',
        fontSize: '0.9rem',
        textAlign: 'center'
    },
    signOutButton: {
        color: theme.palette.appMain.main
    }

});
export default useStyles;
