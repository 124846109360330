
import React, { Component } from 'react';
import {Constants} from 'stores';
//Pickers
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker
  } from '@material-ui/pickers';
  import huLocale from "date-fns/locale/hu";
  import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core
import {
    Grid,
    Typography,
    TextField,
    Paper,
    IconButton,
    InputAdornment,
    Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Icons
import TodayIcon from '@material-ui/icons/Today';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';
import { apiRequest } from './apiRequest';

const DateTimePicker = props => {
    const { value, disabled, id, name, error, label, minDate,maxDate, errorClassName, readOnly, onChange, helperText } = props;
    const classes = style.useStylesForPicker();
    return < MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
        <DatePicker
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled={disabled}>
                            <TodayIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            margin="dense"
            error={error}
            helperText={helperText}
            clearable={true}
            clearLabel="Törlés"
            emptyLabel="Nincs megadva"
            id={id}
            inputVariant="outlined"
            name={name}
            fullWidth
            cancelLabel="Vissza"
            openTo="date"
            label={label}
            readOnly={readOnly}
            disabled={disabled}
            format="yyyy MMMM dd"
            minDate={minDate}
            minDateMessage="Nem megengedett érték"
            maxDate={maxDate}
            views={["year", "month", "date"]}
            autoOk
            value={(value !== null && value !== undefined) ? value : null}
            onChange={date => onChange(date)}
        />
    </MuiPickersUtilsProvider >;
  }

const DateTimeTimePicker = props => {
    const { value, disabled, id, name, error, label, minDate,maxDate, errorClassName, readOnly, onChange, helperText } = props;
    const classes = style.useStylesForPicker();
    return < MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
        <TimePicker
          margin="dense"
          clearable
          ampm={false}
          label={label}
          value={(value !== null && value !== undefined) ? value : null}
          clearLabel="Törlés"
          invalidDateMessage="Nem megfelelő formátum"
          cancelLabel="Vissza"
          inputVariant="outlined"
          emptyLabel="Nincs megadva"
          onChange={date => onChange(date)}
        />
    </MuiPickersUtilsProvider >;
  }

class AddUser extends Component{

    constructor(props) {
        super(props);
        this.state={
            user:'',
            bio:'',
            organization:'',
            leader:'',
            users:[],
            exitTypes:[],
            exitType:'',
            exitNotice:new Date(),
            exitDate:new Date(),
            exitTime:new Date()
        }
    }

    componentDidMount(){
        Constants.setcontentLoading(false);
        apiRequest.populateUsers(this);
        apiRequest.populateTypes(this);
    }

    componentDidUpdate(prevProps,prevState){
        const {user} = this.state;
        if(user!=prevState.user){
            if(user!==null && user!=='' && user!==undefined){
                apiRequest.populateBio(this);
                apiRequest.populateLeader(this);
                apiRequest.populateOrganization(this);
            }
        }

    }

    onChange = (prop,value) =>{
        this.setState({ 
            [prop]:value 
        });
    }

    handleDateChange = (props,date) =>{
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        this.setState({ 
            [props]:newDate 
        });
    }

    handleTimeChange = (time) =>{
        this.setState({ 
            exitTime:time 
        });
    }

    handleSave = () =>{
        apiRequest.saveUser(this);
    }

    validate = () =>{
        const {user,exitType,exitDate,exitTime,exitNotice} = this.state;
        if(user===null || user==='' || user ===undefined){
            return false
        }
        if(exitType===null || exitType==='' || exitType ===undefined){
            return false
        }
        if(exitDate===null || exitDate==='' || exitDate ===undefined){
            return false
        }
        if(exitTime===null || exitTime==='' || exitTime ===undefined){
            return false
        }
        if(exitNotice===null || exitNotice==='' || exitNotice ===undefined){
            return false
        }
        return true;
    }

    render(){
        const { classes } = this.props;
        const {user,users,bio,organization,leader,exitType,exitTypes,exitDate,exitTime,exitNotice} = this.state;
        return(  <div className={classes.root}><Grid
            container
            spacing={4}
        >
            <Grid
                item
                md={1}
                xs={false}
            >
            </Grid>
            <Grid
                item
                md={10}
                xs={12}
            >
                <Typography className={classes.title}>Új kilépő felvitele</Typography>
                <Grid container spacing={2} component={Paper}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            clearText="Alaphelyzet"
                            noOptionsText="Nincs találat"
                            closeText="Bezárás"
                            id="user"
                            name="user"
                            value={user}
                            onChange={(event, value) => this.onChange("user", value)}
                            options={users}
                            getOptionLabel={(option) => option.fullName}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    margin="dense"
                                    {...params}
                                    variant="outlined"
                                    label="Munkavállalók"
                            />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            margin="dense"
                            fullWidth
                            disabled
                            InputProps={{readOnly:true}}
                            variant="outlined"
                            label="Szervezeti egység"
                            value={organization}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <TextField
                            margin="dense"
                            fullWidth
                            disabled
                            InputProps={{readOnly:true}}
                            variant="outlined"
                            label="Munkakör"
                            value={bio}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            margin="dense"
                            fullWidth
                            disabled
                            InputProps={{readOnly:true}}
                            variant="outlined"
                            label="Vezető"
                            value={leader}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Autocomplete
                            clearText="Alaphelyzet"
                            noOptionsText="Nincs találat"
                            closeText="Bezárás"
                            id="exitType"
                            name="exitType"
                            value={exitType}
                            onChange={(event, value) => this.onChange("exitType", value)}
                            options={exitTypes}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    margin="dense"
                                    {...params}
                                    variant="outlined"
                                    label="Felmondás módja"
                            />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <DateTimePicker value={exitNotice}  onChange={(date)=>this.handleDateChange("exitNotice",date)} label="Felmondás dátuma"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <DateTimePicker value={exitDate}  onChange={(date)=>this.handleDateChange("exitDate",date)} label="Kiléptetés pontos napja"/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <DateTimeTimePicker onChange={this.handleTimeChange} value={exitTime} label="Kiléptetés pontos ideje"/>
                    </Grid>
                    <Grid style={{textAlign:'right'}} item xs={12}>
                        <Button className={classes.mainButton} onClick={this.handleSave} disabled={!this.validate()} color="primary" size="small" variant="contained">Mentés</Button>
                    </Grid>
                </Grid>
            </Grid>
          
            <Grid
                item
                md={1}
                xs={false}
            >
            </Grid>
        </Grid></div>)
    }
}



export default withStyles(style.useStyles)(AddUser);