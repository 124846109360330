import theme from 'theme';


const useStyles = ({
    root: {
      padding: theme.spacing(3),
      backgroundImage: 'url(/images/main.png)'
    },
    content: {
      marginTop: theme.spacing(2)
    },
    circularProgress: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
    title: {
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: '1.4rem',
          padding: theme.spacing(2)
    },
    appBar:{
        height:'70px',
        backgroundColor:theme.palette.warning.light,
    },
    closeIcon:{
        color:'white'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      '& .MuiInputLabel-formControl':{
          color:'white'
      },
      '& .MuiSelect-select':{
          color:'white'
      }
    }
  });

export default useStyles;