//React
import React from 'react';

//Core
import {
  Button,
  TextField,
  Dialog,
  Paper,
  DialogActions,
  DialogContent, 
  Typography,
  IconButton
 } from '@material-ui/core';
  import MuiDialogTitle from '@material-ui/core/DialogTitle';
  import Autocomplete from '@material-ui/lab/Autocomplete';
  import {enums} from 'stores';
//Styles
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Icons
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { apiRequest } from './apiRequest';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor:theme.palette.appMain.main
  },
  title: {
    fontFamily:'nunito',
    color:'white',
    fontWeight:'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top:0,
    color:'white'
  },
});

const emailGroups = enums.emailGroups;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function EmailDialog(props) {
  const {onClose,open,selected,readOnly} = props;
  const classes = style.useStyles();

  const [options,setOptions] = React.useState([]);
  const [note,setNote] = React.useState('');
  const [sendTo,setSendTo] = React.useState([]);
  const [leader,setLeader] = React.useState(null);
  const [order,setOrder] = React.useState([]);

  React.useEffect(()=>{
    apiRequest.populateGroups(setOptions);
    apiRequest.populateNote(selected.id,setNote);
    apiRequest.populateLeader(selected.userId,setLeader);
    apiRequest.populateOrder(setOrder,sendTo);
  },[])

  React.useEffect(()=>{
    apiRequest.populateOrder(setOrder,sendTo);
  },[sendTo])

  
  function validate(){
    if(sendTo===null ||  sendTo===undefined || sendTo.length<1){
      return false;
    }
    return true;
  }

  function handleSend(){
    const model = {emailGroups:sendTo,exitUser:selected,note}
    apiRequest.send(model,onClose);
  }



  return (
    <div>
      <Dialog 
        fullWidth={true}
        maxWidth={"lg"}
        open={open} 
        onClose={onClose} 
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={onClose}>Tájékoztató kiküldése</DialogTitle>
        <PerfectScrollbar>
          <DialogContent>
          <Paper style={{padding:'0.5rem'}}>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Név:</Typography>
                  <Typography>{selected.fullName} </Typography>
              </div>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Pozíció:</Typography>
                  <Typography> {selected.bio}</Typography>
              </div>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Szervezeti egység:</Typography>
                  <Typography> {selected.organization}</Typography>
              </div>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Közvetlen felettes:</Typography>
                  <Typography>{(selected.leader!=null && selected.leader!=undefined) && selected.leader.fullName}</Typography>
              </div>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Kilépés dátuma:</Typography>
                  <Typography>{selected.exitTime}</Typography>
              </div>
              <div style={{display:'flex',alignItems:'center',padding:'0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>Felmondás módja:</Typography>
                  <Typography>{selected.exitType}</Typography>
              </div>
              <Autocomplete
                      multiple
                      clearText="Alaphelyzet"
                      noOptionsText="Nincs találat"
                      closeText="Bezárás"
                      id="leaders"
                      name="sendTo"
                      value={sendTo}
                      onChange={(event, value) => setSendTo(value)}
                      options={options}
                      getOptionLabel={(option) => option.label}
                      filterSelectedOptions
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Címzettek"
                          />
                      )}
                  />
              </Paper>
              <Paper style={{padding:'0.5rem',marginTop:'0.5rem'}}>
              <div style={{display:'flex',alignItems:'center',padding:'2.0rem 0.3rem 0.3rem 0.3rem'}}>
                  <Typography style={{fontWeight:'bold',paddingRight:'0.5rem'}}>A kiléptetésének ideje:</Typography>
                  <Typography>{selected.leaveOutDateTime}</Typography>
              </div>

              <Typography style={{padding:'0.3rem'}}>Aznap a kolléga az alábbi sorrendben keres fel titeket:</Typography>
              <div style={{padding:'0.3rem'}}>
              {order.map((row,index)=>
                <Typography key={index}>
                  {row}
                </Typography>
              )}
              </div>
              <Typography style={{padding:'0.3rem'}}>Kérjük, hogy gondoskodjatok arról, hogy a kiléptetés napján fogadni tudjátok a kollégát! </Typography>
              {leader!=null && <Typography style={{padding:'0.3rem'}}>{leader} kérlek jelezd a HR és a pénzügy felé, hogyha a dolgozónak bármilyen tartozása van. Továbbá, ha a dolgozót valamilyen formában (pl. megbízási szerződéssel) tovább foglalkoztatjuk, akkor jelezz a HR, a Munkaügy, valamint szükség esetén az IT felé.  </Typography>}
              <Typography style={{padding:'0.3rem'}}>A HR a kiléptetés kapcsán az alábbi megjegyzést jegyezte fel: </Typography>
              <TextField inputProps={{readOnly:true}} value={note} fullWidth variant="outlined" multiline rows={5}></TextField>
              <Typography style={{padding:'0.3rem'}}>Köszönjük előre is az együttműködéseteket! </Typography>
              <Typography style={{padding:'0.3rem'}}>Kérdés esetén kérjük, hogy a HR csoport munkatársait keressétek! </Typography>
              <Typography style={{padding:'2.0rem 0.3rem 0.3rem 0.3rem'}}>Üdvözlettel:</Typography>
              <Typography style={{padding:'0.3rem'}}>HR csoport</Typography>
              </Paper>
          </DialogContent>
        </PerfectScrollbar>
        <DialogActions>
          <Button endIcon={<BackspaceIcon/>} className={classes.secondaryButton} size="small" onClick={onClose} variant="outlined" color="secondary">
            Vissza
          </Button>
          <Button disabled={!validate()} endIcon={<SaveIcon/>} className={classes.mainButton} size="small" onClick={handleSend} variant="outlined">
            Kiküldés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EmailDialog;