//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    GetData: 'admin/matrix/filters',
    SaveChanges: 'admin/matrix/filters/save',
    GetFilterLabels: 'admin/matrix/filters/labels',
    ValidateFilters :'admin/matrix/filters/validate'
}

export const apiRequest = {
    populateData,
    saveChanges,
    populateFilterLabels,
    validateFilters
};

//#Api calls
async function populateData(parent, page) {
    const { filter } = parent.state;

    const model = {
        filteredFilters: filter.labels,
        active: filter.active !== null ? filter.active.id : filter.active,
        page
    };
    await new Api().call.post(Actions.GetData, model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                data: false
            },
            data: res.matrixFiltersList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                companies: false
            }
        }));
    });
}

async function populateFilterLabels(parent) {
    await new Api().call.get(Actions.GetFilterLabels)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                labels: false
            },
            filterLabels: res
        }));
    }).catch(error => {
        handleError(error);
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                labels: false
            }
        }));
    });
}

async function saveChanges(parent, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.SaveChanges, model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            edit: null,
        }));
    }).catch(handleError);
}

async function validateFilters(parent,page, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.ValidateFilters, model)
    .then(handleResponse)
    .then(res => {
        populateData(parent,page);
    }).catch(handleError);
}

