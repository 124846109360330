
//Api

import {Api,handleResponse,handleError} from 'api';
//Helpers

import { UserStore } from 'stores';

const Actions = {
    GetUsersForSelect: 'matrix/users-for-list/edit'
}

export const apiRequest = {
    getUsers
};

async function getUsers(active,model,setOptions) {
     var _model = {...model};
     
     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
        _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

    await new Api().call.post(Actions.GetUsersForSelect,_model)
    .then(handleResponse)
    .then(res => {
        if (active) {
            setOptions(res);
        } else {
            setOptions([]);
        }
    }).catch(handleError);
}