import React from 'react';
//Core
import {
    Avatar,
    AppBar,
    Tabs,
    Tab,
    Tooltip,
    Menu,
    MenuItem,
    Badge
} from '@material-ui/core';
//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
//Components
import { SelectedUser } from './components';
//Style
import style from './style';
import theme from 'theme';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function UserSelector(props) {
    const { data, actions, onChange, value, children, onSetAnchorIndex, disabled } = props;
    const [anchorIndex, setAnchorIndex] = React.useState(null)
    const classes = style();
    

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs               
                    value={value}
                    onChange={onChange}
                    scrollButtons="on"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                   
                    {data.map((item, index) => (
                        <Tab disabled={disabled}
                            label={item.fullName}
                            key={index} {...a11yProps(index)}
                            className={classes.tabs}
                            icon={<Tooltip placement="top" title={item.fullName}>
                            <Badge style={{ backgroundColor: 'none' }} badgeContent={<div>{item.doneBadge !== null && item.doneBadge !== undefined && <DoneIcon style={{ color: theme.palette.success.main }} />}{item.warningBadge !== null && item.warningBadge !== undefined && <PriorityHighIcon style={{ color: theme.palette.warning.light }} />}</div>}>
                                    <Avatar                                    
                                    className={classes.avatar}
                                    src={(item.avatar !== null && item.avatar !== undefined) ? item.avatar : ''}
                                />
                            </Badge></Tooltip>} {...a11yProps(index)} />
                    ))} 
                </Tabs>
            </AppBar>
            {children}
            
        </div>
    );
}
export default UserSelector;
