import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    subTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: theme.spacing(2)
    },
    subSubTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.025rem',
        padding: theme.spacing(2)
    },
    text:{
        paddingRight:theme.spacing(2),
        paddingLeft:theme.spacing(2),
        fontSize: '1.0rem',
    },
    divider:{
        marginTop:'1rem',
        marginBottom:'1rem'
    }
});


export default useStyles;
