//React

import React, { Component } from 'react';
//Core

import { Button } from '@material-ui/core';
//Components

import {
    UsersToolbar,
    UsersTable,
    EditUser,
    Pagination,
    UserRoles,
    NewDialog,
    ExistsDialog,
    CheckListDialog
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons

import AddIcon from '@material-ui/icons/Add';


class Admin extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            loading: {
                bios: true,
                organizations: true,
                users: true,
                filterUsers: true
            },
            users: [],
            page: 1,
            bios: [],
            organizations: [],
            users: [],
            filterUsers: [],
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit: null,
            checkList: null,
            role: null,
            filter: {
                bios: [],
                organizations: [],
                users: [],
                active: null,
                locked: null
            },
            selectedUsers: [],
            addNew: false,
            exists: false
        }
    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateUsers(this, this.state.page);
        apiRequest.populateBios(this);
        apiRequest.populateOrganizations(this);
        apiRequest.populateUsersForFilter(this);
    }
    //#EndLifecycle methods

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this, nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this, 1));
    }

    handleSelectUsers = (users) => {
        this.setState({
            selectedUsers: users
        });
    }

    handleValidation = (users, valid, multiple) => {
        const { selectedUsers } = this.state;

        if (multiple) {
            apiRequest.setValidationUser(this, selectedUsers, valid);
            this.setState({
                selectedUsers: []
            });
        } else {
            apiRequest.setValidationUser(this, users, valid);
        }
    }

    handleLock = (users, locked, multiple) => {
        const { selectedUsers } = this.state;

        if (multiple) {
            apiRequest.setLockUser(this, selectedUsers, locked);
            this.setState({
                selectedUsers: []
            });
        } else {
            apiRequest.setLockUser(this, users, locked);
        }

    }

    handleCheckList = (user) => {
        this.setState({
            checkList: user
        });
    }

    handleEdit = (user) => {
        this.setState({
            edit: user
        });
        if (user == null) {
            apiRequest.populateUsers(this, this.state.page);
        }
    }

    onClose = () => {
        this.setState({
            edit: null
        });
    }

    handleRole = (role) => {
        this.setState({
            role
        });
    }

    handleAddNew = (value) => {
        this.setState({
            addNew: value
        });
    }

    handleExists = (value) => {
        this.setState({
            exists: value
        });
    }

    render() {
        const { users, edit, addNew, checkList, loading, role, exists, selectedUsers, filter, bios, filterUsers, organizations, currentPage, pages, step, total, page } = this.state;
        const { classes, snackbar } = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <React.Fragment>
                        {loading.users === false &&
                            <UsersToolbar
                                users={filterUsers}
                                loading={loading}
                                bios={bios}
                                onLock={this.handleLock}
                                onValidation={this.handleValidation}
                                organizations={organizations}
                                selectedUsers={selectedUsers}
                                filter={filter}
                                onChange={this.handleFilterChanges}
                            />
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                            <Button
                                onClick={() => this.handleExists(true)}
                                className={classes.primaryButton}
                                size="small"
                                endIcon={<AddIcon />}
                            >
                                Létező belépő
                            </Button>
                            <Button
                                onClick={() => this.handleAddNew(true)}
                                className={classes.greenButton}
                                size="small"
                                endIcon={<AddIcon />}
                            >
                                Új belépő
                            </Button>
                            {addNew &&
                                <NewDialog
                                    refreshTable={() => apiRequest.populateUsers(this, page)}
                                    onClose={() => this.handleAddNew(false)}
                                    open={addNew}
                                />
                            }
                            {exists &&
                                <ExistsDialog
                                    refreshTable={() => apiRequest.populateUsers(this, page)}
                                    onClose={() => this.handleExists(false)}
                                    open={exists}
                                />
                            }
                            {checkList &&
                                <CheckListDialog
                                    onClose={() => this.handleCheckList(null)}
                                    open={Boolean(checkList)}
                                    user={checkList}
                                />
                            }
                        </div>
                        <div className={classes.content}>
                            <UsersTable
                                loading={loading.users}
                                onLock={this.handleLock}
                                onValidation={this.handleValidation}
                                onEdit={this.handleEdit}
                                onSelect={this.handleSelectUsers}
                                onRole={this.handleRole}
                                onCheckList={this.handleCheckList}
                                selectedUsers={selectedUsers}
                                users={users}
                            />
                        </div>
                        {loading.users === false &&
                            <Pagination
                                onPageChanges={this.handlePageChanges}
                                currentPage={currentPage}
                                step={step}
                                total={total}
                                pages={pages}
                            />
                        }
                    </React.Fragment>
                    {edit !== null &&
                        <EditUser
                            refreshTable={() => apiRequest.populateUsers(this, page)}
                            onClose={this.onClose}
                            snackbar={snackbar}
                            onEdit={this.handleEdit}
                            user={edit}
                        />
                    }
                    {role != null &&
                        <UserRoles
                            user={role}
                            onClose={() => this.handleRole(null)}
                        />
                    }
                </div>
            </div>
        );
    }
};

export default withStyles(style)(Admin);