import React, { Component } from 'react';
//Core
import {
    Grid,
    Card,
    CardHeader,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Competences
import { GeneralSlider, RatingCard, CompareResults } from './components';
import { apiRequest } from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';




class Competences extends Component {

    constructor(props) {
        super(props);
        const { lazyData } = this.props;

        if (lazyData === null) {
            apiRequest.populateCompetences(this);
            apiRequest.populateResult(this);
        }
        
        this.state = {
            competences: lazyData !== null  ?  lazyData.competences : [],
            result: lazyData !== null ? (lazyData.result!==undefined ? lazyData.result : []) : [],
            avg: lazyData !== null ? lazyData.avg!==undefined ? lazyData.avg : null : null,
            loading: {
                competences: lazyData !== null ? false : true,
                result: lazyData !== null ? false : true
            }
        }
    }

      //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.period != this.props.period) {
            this.setState(prevState=>({
                loading: {
                    ...prevState.loading,
                    competences: true,
                    result: true
                }
            }));
            apiRequest.populateCompetences(this);
            apiRequest.populateResult(this);
        }
    }
     //#EndLifecycle methods



    render() {
        const { competences, loading,result,avg } = this.state;
        const { classes, period } = this.props;
        return ((loading.competences === true || loading.result === true) ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> : <div>
            {(competences!=null && competences!=undefined && competences.length < 1) ? <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nem lett kitöltve az önértékelés</Typography></div> : competences.map(item =>
                <GeneralSlider key={item.id} subTitle={item.label} competences={item.competenceSubGroup} />)}
            <div style={{ paddingTop: theme.spacing(2) }}>
                {(result!=null && result!=undefined && result.length < 1) && <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nem lett értékelve mások által</Typography></div>}
                {(result!=null && result!=undefined && result.length > 0) && result.map((comp) => comp.competenceSubGroup.map((compGroup) => (
                    <Grid
                        container
                        spacing={0}
                        key={compGroup.id}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={6}
                            lg={8}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <RatingCard title={compGroup.label} groupId={compGroup.id} handleCompetences={this.handleCompetences} competences={compGroup.competences} />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>
                )))}
                {(result!=null && result!=undefined && result.length > 0) &&
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={6}
                            lg={8}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <CardHeader className={classes.headerTitle} title={`Átlag: ${avg}`} >
                                </CardHeader>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>}
                {((result!=null && result!=undefined && result.length > 0) || (competences!=null && competences!=undefined && competences.length> 0)) && <Grid
                    style={{ paddingTop: theme.spacing(2) }}
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        xl={1}
                        lg={1}
                        md={false}
                        sm={false}
                        xs={false}
                    ></Grid>
                    <Grid
                        item
                        xl={10}
                        lg={10}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <CompareResults period={period} snackbar={this.props.snackbar} />
                    </Grid>
                    <Grid
                        item
                        xl={1}
                        lg={1}
                        sm={false}
                        md={false}
                        xs={false}
                    ></Grid>
                </Grid>}
            </div>
           
        </div>)
    }
}
export default withStyles(style)(Competences);
