//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    Grid,
    CircularProgress
} from '@material-ui/core';
//Components

import { 
    UserSelector, 
    MuiOutlinedSelect  
} from 'components';
import { 
    TabPanels, 
    PdfDocument 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants, UserStore } from 'stores';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/core/styles';


class Ratings extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);
        apiRequest.populatePeriods(this);

        this.state = {
            value: 0,
            users: [],
            selector: [],
            dialog: {
                notes: false,
                conversation:false
            },
            loading: {
                users: true,
                selector: true,
                result: true,
                competences: true,
                periods:true
            },
            printable: {
                competences: null,
                result:null,
                motivations:null
            },
            periods: [],
            selectedPeriod: null,
            selectedPeriodObject:null,
            anchorIndex: null,
            lazyData: null,
            printerView:false,
            currentUser:null,
            loggedByUser:null
        }

    }

    componentDidMount(){
        //Subscribe user value 
        UserStore.getUser().subscribe(res => this.setState({
            currentUser: res,
        }));
        UserStore.getLoggedByAdmin().subscribe(res => this.setState({
            loggedByUser: res,
        }));
    }


    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const {loading, users, selectedPeriod, value} = this.state;
        const {location, selected} = this.props;

        if(prevState.selectedPeriod != selectedPeriod) {
            if (loading.periods === false) {
                if (selectedPeriod !== null) {
                    if (loading.users !== false) {
                        apiRequest.populateUsers(this);
                    }
                }
            }
        }

        if (selectedPeriod !== null) {
            if (loading.users === false && loading.selector === true) {
                if (users.length > 0) {
                    if (loading.selector !== false) {
                        if(location.pathname === "/form-rating/workers"){
                            apiRequest.populateSelector(this, users[value].id);
                        }else if(location.pathname === "/form-rating/main"){
                            apiRequest.populateSelectorMain(this, users[value].id);
                        }else if(location.pathname === "/form-rating/leader"){
                            apiRequest.populateSelectorMain(this, users[value].id);
                        } 
                    }
                    if (loading.competences === true) {
                        apiRequest.populateCompetences(this,users[0].id);
                    }
                    if (loading.result === true) {
                        apiRequest.populateResult(this,users[0].id);
                    }
                }else{
                    this.setState(prevState => ({
                        loading: {
                            ...prevState.loading,
                            selector: false
                        }
                    }));
                }
            }
        }

        if(prevState.value !== value && Array.isArray(users) && users.length > 0){
            if(location.pathname === "/form-rating/workers"){
                apiRequest.populateSelector(this, users[value].id);
            }else if(location.pathname === "/form-rating/main"){
                apiRequest.populateSelectorMain(this, users[value].id);
            }else if(location.pathname === "/form-rating/leader"){
                apiRequest.populateSelectorMain(this, users[value].id);
            } 
        }
          
        if (prevState.loading.periods != loading.periods) {
            if (loading.periods === false) {
                Constants.setcontentLoading(false);
            }
        }       
    }
    //#EndLifecycle methods

    handlePrinterView = () => {
        this.setState(prevState => ({
            printerView: !prevState.printerView
        }));     
    }

    handleChange = (event, newValue) => {
        const classList = event.target.parentElement.classList.value.toUpperCase();
        if (classList.includes('AVATAR')) {
            this.setState({
                value: newValue
            })
        }
    };

    handleSelectChange = (event) => {
        const selectedPeriodObject = this.state.periods.filter(item => item.id === event.target.value)[0];
        this.setState(prevState => ({
            selectedPeriod: event.target.value,
            selectedPeriodObject,
            selector: [],
            users:[],
            value: 0,
            loading: {
                ...prevState.loading,
                selector: true,
                users: true,
                result: true,
                competences: true,
            }
        }));

    }

    setAnchorIndex = (value) => {
        this.setState({
            anchorIndex: value
        })
    }

 
    render() {
        const {value, users, loading, selector, periods, selectedPeriod, selectedPeriodObject, lazyData, currentUser, loggedByUser} = this.state;
        const {snackbar, classes, location} = this.props;
        return ((loading.periods === false) && 
            <div className={classes.root}>    
                <Grid container spacing={4}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        {(location.pathname === "/form-rating/workers") && 
                            <Typography className={classes.title}>Értékelőlap</Typography>
                        }
                        <Grid container spacing={2}>
                            <Grid item xl={3} lg={3} md={4} sm={8} xs={8}>                        
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MuiOutlinedSelect
                                        margin="dense" 
                                        disabledItemLabel="Még nem elérhető" 
                                        disabled={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} 
                                        error={(periods !== null && periods !== undefined && periods.length > 0) ? false : true} 
                                        helperText={(periods !== null && periods !== undefined && periods.length > 0) ? '' : "Nem található előző periódus"} 
                                        className={classes.select} menuList={periods} 
                                        handleChange={this.handleSelectChange} 
                                        value={selectedPeriod !== null ? selectedPeriod : 0} 
                                        label="Periódusok" 
                                    />
                                    {(selectedPeriod !== null && 
                                    (users !== null && users !== undefined && users.length > 0)) && 
                                        <PdfDocument
                                            refreshUsers ={()=>apiRequest.populateUsers(this)}
                                            onWorkersPage={location.pathname === "/form-rating/workers"} 
                                            location={location} 
                                            titles={selector} 
                                            userFullname={users[value].fullName} 
                                            userid={users[value].id}
                                            user={users[value]} 
                                            snackbar={snackbar} 
                                            period={selectedPeriodObject}
                                        />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        {selectedPeriod !== null ?
                            (loading.users === true || loading.selector === true || loading.competences === true || loading.result === true) ? 
                            <div className={classes.circularProgress}>
                                <CircularProgress color="primary" />
                            </div> : 
                            (users !== null && users !== undefined && users.length > 0) ? 
                            selector.length > 0 ? 
                                <UserSelector
                                    onSetAnchorIndex={this.setAnchorIndex} 
                                    data={users} 
                                    value={value} 
                                    onChange={this.handleChange}
                                >
                                {lazyData !== null && 
                                    <TabPanels 
                                        location={location} 
                                        period={selectedPeriod} 
                                        lazyData={lazyData} 
                                        selector={selector} 
                                        snackbar={snackbar} 
                                        value={value} 
                                        index={value}
                                        user={users[value]} 
                                        userid={users[value].id} 
                                        currentUser={currentUser}
                                        loggedByUser={loggedByUser}
                                    />
                                }
                            </UserSelector> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nincs választható menüpont</b>
                            </div> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nem található értékelőlap</b>
                            </div> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nincs kiválasztva periódus</b>
                            </div>
                            }                  
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
            </div>
        )
    }
}
export default withStyles(style)(Ratings)
