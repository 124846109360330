
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoals: 'ratingForm/goals/new/user'
}

export const apiRequest = {
    populateGoals
};

async function populateGoals(parent,userId) {
    const { period } = parent.props;

    var model = {userId,period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetGoals,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: false,
            data: res
        }));
    }).catch(handleError);

}