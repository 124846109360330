//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    Save:'admin/rating/leader-rating-points/new/save'
}

export const apiRequest = {
    saveChanges
};

async function saveChanges(parent,model) {
    const {refresh} = parent.props;
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        refresh();
    }).catch(handleError);
}


