import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import style from './style';
import {Constants} from 'stores';


const PeriodIsNotActive = (props) => {

    useEffect(() => {
        Constants.setcontentLoading(false);
    }, []);


    const classes = style();

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={2}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                >
                    <div className={classes.content}>
                        <img
                            alt="Error"
                            className={classes.image}
                            src="/images/Error.png"
                        />
                        <Typography variant="h1" className={classes.contentTitle}>
                            Jelenleg nem elérhető
                        </Typography>
                        <Typography variant="h1" className={classes.contentTitle}>
                            Az értékelési periódus jelenleg nem aktív
                        </Typography>
                        <Typography className={classes.contentSubTitle} variant="subtitle2">
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PeriodIsNotActive;
