//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetUsers: 'admin/user/leader-of-users',
    GetLeadersForFilter: 'admin/user/filter/leaders',
    GetOrganizations: 'admin/user/filter/organizations',
    GetRoles: 'admin/user/filter/roles',
    GetBios: 'admin/user/filter/bios',
    GetUsersForFilter: 'admin/user/filter/users',
}

export const apiRequest = {
    populateUsers,
    populateBios,
    populateOrganizations,
    populateRoles,
    populateUsersForFilter,
    populateLeadersForFilter
};

//#Api calls
async function populateUsers(parent, page) {
    const { filter } = parent.state;
    const model = {
        filteredOrganizations: filter.organizations,
        filteredRoles: filter.roles,
        filteredUsers: filter.users,
        filteredBios: filter.bios,
        filteredLeaders: filter.leaders,
        withModifiedLeader: filter.leaderIsModified !== null ?
            (filter.leaderIsModified.id === 1 ? true : false) : filter.leaderIsModified,
        page

    };

    await new Api().call.post(Actions.GetUsers, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                },
                users: res.userList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
        });
}

async function populateBios(parent) {
    await new Api().call.get(Actions.GetBios)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                },
                bios: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    bios: false
                }
            }));
        });
}

async function populateOrganizations(parent) {
    await new Api().call.get(Actions.GetOrganizations)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });

}
async function populateRoles(parent) {
    await new Api().call.get(Actions.GetRoles)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    roles: false
                },
                roles: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    roles: false
                }
            }));
        });

}
async function populateUsersForFilter(parent) {
    await new Api().call.get(Actions.GetUsersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                },
                filterUsers: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterUsers: false
                }
            }));
        });
}
async function populateLeadersForFilter(parent) {
    await new Api().call.get(Actions.GetLeadersForFilter)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    leaders: false
                },
                leaders: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    leaders: false
                }
            }));
        });
}