
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Helpers

import { UserStore } from 'stores';

const Actions = {
    GetClassificationsForSelect: 'matrix/classifications-for-list/add-new'
}
export const apiRequest = {
    getClasses
};

async function getClasses(active,model,setOptions) {
     var _model = {...model};

     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
        _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

    await new Api().call.post(Actions.GetClassificationsForSelect,model)
    .then(handleResponse)
    .then(res => {
        if (active) {
            setOptions(res);
        } else{
            setOptions([]);
        }
    }).catch(handleError);
}