//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  TextField,
  CardActions,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';

const StyledTableRow = style.StyledTableRow;


const OrganizationsTable = props => {
  const {className, organizations, edit, loading, selectedOrganizations, onLeader, onEdit, onDone, onOpen, onCancel, onChange, onSave, onDelete, onSelect, ...rest} = props;

  const classes = style.useStyles();

  const handleSelectAll = event => {

    let selectedOrganizations;

    if (event.target.checked) {
        selectedOrganizations = organizations.map(org => org.id);
    } else {
        selectedOrganizations = [];
    }

    onSelect(selectedOrganizations);
  };

  const handleSelectOne = (event, id) => {
      const selectedIndex = selectedOrganizations.indexOf(id);
      let newSelectedOrganizations = [];

    if (selectedIndex === -1) {
        newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations, id);
    } else if (selectedIndex === 0) {
        newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations.slice(1));
    } else if (selectedIndex === selectedOrganizations.length - 1) {
        newSelectedOrganizations = newSelectedOrganizations.concat(selectedOrganizations.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelectedOrganizations = newSelectedOrganizations.concat(
            selectedOrganizations.slice(0, selectedIndex),
            selectedOrganizations.slice(selectedIndex + 1)
      );
    }

      onSelect(newSelectedOrganizations);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                 <StyledTableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedOrganizations.length === organizations.length}
                        color="primary"
                        indeterminate={
                            selectedOrganizations.length > 0 &&
                            selectedOrganizations.length < organizations.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="center"  padding="none">Azonosító</TableCell>
                    <TableCell align="center"  padding="none">Megnevezés</TableCell>
                    <TableCell align="center" padding="none">Cégcsoport</TableCell>
                    <TableCell align="center" padding="none">Aktív</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
              {organizations.map((org,index) => (
                <StyledTableRow
                className={classes.tableRow}
                hover
                key={org.id}
                selected={selectedOrganizations.indexOf(org.id) !== -1}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedOrganizations.indexOf(org.id) !== -1}
                    color="primary"
                    onChange={event => handleSelectOne(event, org.id)}
                    value="true"
                  />
                </TableCell>
                <TableCell padding="none" align="center" >
                    {org.id}
                </TableCell>
                <TableCell padding="none" align="center" >
                    {(edit != null && edit.id == org.id) && 
                      <TextField 
                        helperText={!(org.name != null && org.name != undefined && org.name != '') ? 'Nem megengedett érték' : ''} 
                        error={!(org.name != null && org.name != undefined && org.name != '') ? true : false} 
                        onChange={(event) => onChange(event, index, 'name')} 
                        value={org.name} 
                      />
                    }
                    {(edit == null || (edit != null && edit.id != org.id)) && org.name}
                </TableCell>
                <TableCell padding="none" align="center" >
                    {org.company}
                </TableCell>
                <TableCell padding="none" align="center" >
                    {org.isValid==true ? 'Igen' : 'Nem'}
                </TableCell>
                <TableCell padding="none" align="center">
                  {(edit == null || (edit != null && edit.id != org.id)) &&
                    <React.Fragment> 
                      {org.isValid == true ? 
                      <Tooltip title="Munkakör tiltása">
                        <IconButton
                          disabled={(edit != null ? true : false)}  
                          onClick={() => onDelete(index)} 
                          className={classes.doneButton} 
                          aria-label="done"
                        >
                            <DoneIcon /> 
                        </IconButton>
                      </Tooltip> : 
                      <Tooltip title="Munkakör feloldása">
                        <IconButton
                            disabled={(edit != null ? true : false)}  
                          onClick={() => onDone(index)} 
                          className={classes.blockButton} 
                          aria-label="block"
                        >
                            <BlockIcon />
                        </IconButton>
                      </Tooltip>
                      }
                      <Tooltip title="Módosítás">
                        <span>
                          <IconButton 
                            disabled={(edit != null ? true : false)} 
                            onClick={() => onEdit(org)} 
                            className={classes.editButton} 
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Vezető">
                        <span>
                          <IconButton 
                            disabled={(edit != null ? true : false)} 
                            onClick={() => onLeader(org)} 
                            className={classes.leaderButton} 
                            aria-label="leader"
                          >
                            <PersonIcon />
                          </IconButton>
                        </span>
                      </Tooltip>   
                    </React.Fragment>
                  }
                {(edit != null && edit.id == org.id) &&
                <React.Fragment> 
                  <Tooltip title="Mentés">
                    <span>
                      <IconButton 
                        disabled={!(org.name != null && org.name != undefined && org.name != '') ? true : false} 
                        onClick={()=>onSave(index)} 
                        className={classes.saveButton} 
                        aria-label="save"
                      >
                        <DoneIcon />
                      </IconButton>
                    </span>
                    </Tooltip>
                    <Tooltip title="Mégsem">
                      <IconButton 
                        onClick={()=>onCancel(index)} 
                        className={classes.cancelButton} 
                        aria-label="cancel"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip> 
                    </React.Fragment> 
                }
                </TableCell>
              </StyledTableRow>))
              }
              {organizations.length < 1 &&
                <StyledTableRow>
                  <TableCell align="center" colSpan="6">
                    {loading ? 'Betöltés...' : 'Nincs találat'}
                  </TableCell>
                </StyledTableRow>
              }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        </CardContent>
        <CardActions>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
            <Typography style={{ fontWeight: 'bold' }}>Új szervezeti egység létrehozása: </Typography>
            <Tooltip title="Új hozzáadás">
                <span>
                    <IconButton disabled={edit != null ? true : false} onClick={onOpen} className={classes.addButton} aria-label="add">
                        <AddIcon />
                    </IconButton>
                </span>
            </Tooltip>
          </div>
        </CardActions>
    </Card>
  );
};

OrganizationsTable.propTypes = {
  className: PropTypes.string,
  organizations: PropTypes.array.isRequired
};

export default OrganizationsTable;
