//React

import React from 'react';
//Core

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import { UserSelector } from 'components';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import GetAppIcon from '@material-ui/icons/GetApp';
//Api

import { apiRequest } from './apiRequest';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function FormDialog(props) {
  const { onClose, open, selected, readOnly } = props;
  const classes = style();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    apiRequest.populateDatas(setData, setLoading, selected.id);
  }, [])

  function handleText(event) {
    const name = event.target.name;
    const value = event.target.value;
    var newData = { ...data };
    newData[name] = value;
    setData(newData);
  }

  function handleTextInArray(event, mainPoint, point, valueIndex) {
    const value = event.target.value;
    var newData = { ...data };
    const mainIndex = newData.mainPoints.indexOf(mainPoint);
    const pointIndex = newData.mainPoints[mainIndex].points.indexOf(point);
    newData.mainPoints[mainIndex].points[pointIndex].values[valueIndex].value = value;

    setData(newData);
  }

  function handleSave() {
    apiRequest.save(data, selected);
  }

  function handleDownload() {
    var save = apiRequest.save(data, selected);
    save.then(res => {
      apiRequest.download(selected);
    }).catch(error => { });

  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" onClose={onClose}>Próbaidő értékelő beszélgetés - 2. hónap</DialogTitle>
        <PerfectScrollbar>
          <DialogContent>
            <UserSelector data={[selected]} value={0}>
            </UserSelector>
            {loading === true &&
              <div style={{ textAlign: 'center', padding: '1.0rem' }}>
                <CircularProgress color="primary" />
              </div>
            }
            {loading === false &&
              <div>
                {data.mainPoints.map(mainPoint =>
                  <div key={mainPoint.label}>
                    <Typography className={classes.title}>
                      {mainPoint.label}
                    </Typography>
                    {mainPoint.points.map(point => <div key={point.label}>
                      <Typography className={classes.subTitle}>
                        {point.label}
                      </Typography>
                      {point.values.map((item, index) =>
                        <TextField
                          InputProps={{
                            readOnly: readOnly,
                          }}
                          onChange={(event) => handleTextInArray(event, mainPoint, point, index)}
                          value={(item.value === null || item.value === undefined) ? '' : item.value}
                          multiline
                          rows={4}
                          fullWidth
                          variant="outlined"
                        />)
                      }
                    </div>)}
                  </div>)
                }
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Typography className={classes.footer}>
                    A dolgozó az e-learning felületen található bevezető kurzust
                  </Typography>
                  <TextField
                    InputProps={{
                      readOnly:
                        readOnly,
                    }}
                    name="eLearning"
                    onChange={handleText}
                    value={(data.eLearning === null || data.eLearning === undefined || data.eLearning === '') ? 0 : data.eLearning}
                    type="number"
                  />
                  <Typography className={classes.footer}>
                    százalékban tekintette meg.
                  </Typography>
                </div>
                <Typography className={classes.subTitle}>
                  Megjegyzések
                </Typography>
                <TextField
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  name="note"
                  onChange={handleText}
                  value={(data.note === null || data.note === undefined) ? '' : data.note}
                  multiline
                  fullWidth
                  rows={3}
                  variant="outlined"
                />
              </div>
            }
          </DialogContent>
        </PerfectScrollbar>
        <DialogActions>
          <Button
            endIcon={<GetAppIcon />}
            disabled={readOnly}
            className={classes.mainButton}
            size="small"
            onClick={handleDownload}
            variant="outlined"
          >
            Letöltés
          </Button>
          <Button
            endIcon={<BackspaceIcon />}
            className={classes.secondaryButton}
            size="small"
            onClick={onClose}
            variant="outlined"
            color="secondary"
          >
            Vissza
          </Button>
          <Button
            endIcon={<SaveIcon />}
            className={classes.greenButton}
            size="small"
            onClick={handleSave}
            variant="outlined"
          >
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;