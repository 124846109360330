import React, { Component } from 'react';
//Core
import { Typography, CircularProgress, Grid } from '@material-ui/core';
//Components
import { MuiOutlinedInput } from 'components';
import { GoalsGrid } from './components';
import { apiRequest } from './apiRequest';
//Style
import theme from 'theme';



class ActPeriod extends Component {

    

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            sumOfResult:'',
            error: false
        }
    }

    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateGoals(this);
        apiRequest.populateSumOfResult(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.period != this.props.period) {
            this.setState({
                loading: true,
                sumOfResult:''
            });
            apiRequest.populateGoals(this);
            apiRequest.populateSumOfResult(this);
        }
    }
     //#EndLifecycle methods
    render() {
        const { loading, data, sumOfResult } = this.state;
        return (
            loading ? <div style={{ padding: theme.spacing(4), textAlign: 'center' }}><CircularProgress color="primary" /></div> :<div>
                {
                    data.map((item, index) => <React.Fragment key={index}>
                        <Typography style={{ fontFamily: 'nunito', fontWeight: 'bold', fontSize: '1.0rem', paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}>{item.goalType.label}</Typography>
                        <GoalsGrid key={item.goalType.id}
                            data={item.goalsForTable} goalType={item.goalType} goalTypeMatrix={item.goalType} />
                    </React.Fragment>)
                }
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                    />
                    <Grid
                        item
                        xl={3}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                    />
                    <Grid
                        item
                        xl={2}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                    >
                        <Typography style={{ fontFamily: 'Nunito', fontWeight: 'bold', fontSize: '1.0rem' }}>Összesítve:</Typography>
                    </Grid>
                    <Grid
                        item
                        xl={2}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12} >
                        <MuiOutlinedInput
                            margin="dense"
                            withBackground={true}
                            readOnly={true}
                            inputId='textfield-performed-sum'
                            endInputAdornment={'%'}
                            label="Teljesített"
                            value={sumOfResult} />
                    </Grid>
                </Grid>
            </div >
        )
    }

}
export default ActPeriod;
