import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4),
    
    },
    backButton: {
        margin: theme.spacing(1),
        color:'white',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            backgroundColor: theme.palette.appMain.dark,
        },
    },
    drawer:{
        width:'350px',
    },
    appBar:{
        height:'60px',
        backgroundColor:theme.palette.warning.light,
    },
    closeIcon:{
        color:'white'
    }
});

export default useStyles;
