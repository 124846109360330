import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Core
import {
    useMediaQuery,
} from '@material-ui/core';
//Components
import {
    Sidebar,
    Topbar,
    ScrollToTop,    
    LogoutDialog,
} from './components';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {UserStore} from 'stores';
//Styles 
import { useTheme } from '@material-ui/styles';
import style from './style';


const PROBAIDO = props => {
    const { children, snackbar } = props;
    const pathName = children.props.location.pathname;

    const classes = style();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
      defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);
    const [openTopScroll, setOpenTopScroll] = useState(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const [profile,setProfile] = useState(pathName.includes('/admin/') ? 'admin' : 'user');

    useEffect(() => {
      UserStore.getProfile().subscribe(res => setProfile(res));
    }, [])
    

  const handleSidebarOpen = () => {
      setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleOpenTopScroll = () => {
      setOpenTopScroll(true);
  };

  const handleCloseTopScroll = (container) => {
      if (container.scrollTop == 0) {
            setOpenTopScroll(false);
      }       
  };

  const handleLogoutDialogOpen = () => {
      setOpenLogoutDialog(true);
  }

  const handleLogoutDialogClose = () => {
        setOpenLogoutDialog(false);
  }
    const shouldOpenSidebar = isDesktop ? true : openSidebar;
    return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
        >
            <Topbar 
              profile={profile} 
              openSidebar={openSidebar} 
              onSidebarOpen={handleSidebarOpen} 
              onLogoutDialogOpen={handleLogoutDialogOpen} 
            />
              <Sidebar
                snackbar={snackbar}
                pathName={pathName}
                onClose={handleSidebarClose}
                onLogoutDialogOpen={handleLogoutDialogOpen}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
            />
            <main className={classes.content}>
                <PerfectScrollbar 
                  onYReachEnd={container => handleCloseTopScroll(container)} 
                  onScrollY={handleOpenTopScroll} 
                  onYReachStart={handleCloseTopScroll}
                >
                    <div id="to-up"></div>
                        {children}
                </PerfectScrollbar>
            </main>
            <ScrollToTop open={openTopScroll} />
            <LogoutDialog 
              snackbar={snackbar} 
              open={openLogoutDialog} 
              closeHandler={handleLogoutDialogClose} 
            />
    </div>
    );
};

PROBAIDO.propTypes = {
  children: PropTypes.node
};

export default PROBAIDO;
