//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetProblems:'admin/helper/reported-problems',
    SetNew:'admin/helper/reported-problems/new',
    SetDone:'admin/helper/reported-problems/done',
    SetCancel:'admin/helper/reported-problems/cancel',
}

export const apiRequest = {
    getProblems,
    setNew,
    setDone,
    setCancel
};

async function getProblems() {
    return await new Api().call.get(Actions.GetProblems)
    .then(handleResponse)
    .then(res => {
        return res
    }).catch(handleError);
}

async function setNew(id) {
    return await new Api().call.post(`${Actions.SetNew}/${id}`)
    .then(handleResponse)
    .then(res => {
        return res
    }).catch(handleError);
}

async function setDone(model) {
    return await new Api().call.post(Actions.SetDone,model)
    .then(handleResponse)
    .then(res => {
        return res
    }).catch(handleError);
}


async function setCancel(id) {
    return await new Api().call.post(`${Actions.SetCancel}/${id}`)
    .then(handleResponse)
    .then(res => {
        return res
    }).catch(handleError);
}

