import React from 'react';
//Core
import {
    TableRow
  } from '@material-ui/core';
//Styles
import { makeStyles,withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
    },
    blockButton: {
        color: theme.palette.error.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    sentButton: {
        color: theme.palette.success.main
    },
    editButton: {
        color: theme.palette.warning.main
    },
    doneButton: {
        color: theme.palette.success.main
    },
    roleButton:{
      color: theme.palette.appMain.light
    },
    mainButton:{
      color:'white',
      fontFamily:'Nunito',
      textTransform:'none',
      fontWeight:'bold',
      backgroundColor: theme.palette.appMain.main,
      '&:hover': {
          color:theme.palette.appMain.main,
          backgroundColor: 'white',
          border:`1px solid ${theme.palette.appMain.main}`
      },
      '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
      },
    },
    redButton:{
      color:'white',
      fontFamily:'Nunito',
      textTransform:'none',
      fontWeight:'bold',
      backgroundColor: theme.palette.error.light,
      '&:hover': {
          color:theme.palette.error.main,
          backgroundColor: 'white',
          border:`1px solid ${theme.palette.error.main}`
      },
      '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
      },
    },
    yellowButton:{
      color:'white',
      fontFamily:'Nunito',
      textTransform:'none',
      fontWeight:'bold',
      backgroundColor: theme.palette.warning.light,
      '&:hover': {
          color:theme.palette.warning.main,
          backgroundColor: 'white',
          border:`1px solid ${theme.palette.warning.main}`
      },
      '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
      },
    },
    greenButton:{
      color:'white',
      fontFamily:'Nunito',
      textTransform:'none',
      fontWeight:'bold',
      backgroundColor: theme.palette.success.light,
      '&:hover': {
          color:theme.palette.success.main,
          backgroundColor: 'white',
          border:`1px solid ${theme.palette.success.main}`
      },
      '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
      },
    },
}));

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '0.8rem',

        },
    },
    head: {
        '& > *': {
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)

  export default {
    useStyles,
    StyledTableRow
  };