import { makeStyles,withStyles } from '@material-ui/core/styles';
//Lab
import Rating from '@material-ui/lab/Rating';
//Core
import { 
        Tooltip, 
} from '@material-ui/core';

const StyledRating = withStyles((theme)=>({
    iconFilled: {
        color: theme.palette.appMain.main,
    },
    iconHover: {
        color: theme.palette.appMain.main,
    },
}))(Rating);

const useStyles = makeStyles({
    root: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
});

const StyledTooltip = withStyles({
    tooltip: {
        fontSize: '1.0rem'
    }
})(Tooltip);

export default {
StyledRating:StyledRating,
useStyles:useStyles,
StyledTooltip:StyledTooltip
}
