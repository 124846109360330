import React from 'react';
//Core
import {
    Grid,
    Divider,
    Badge,
    Typography,
    IconButton,
    Tooltip,
    MenuItem,
    Menu
} from '@material-ui/core';
//Components
import { MuiOutlinedInput, MuiOutlinedDateSelect } from 'components';
import { DateSelect, NumberField, SelectList, SkipDialog } from './components';
import { UserStore } from 'stores';
import {enums} from 'stores';
//Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
//Style
import style from './style';

const InputTypes = enums.inputTypes;


function getPerformed(goal,goalId, typeId, unit, options, defValue, handleGoalsChange) {
    const loggedBy =  UserStore.getLoggedByAdminValue();
    switch (typeId) {
        case InputTypes.NUMBERFIELD:
            return <NumberField readOnly={loggedBy!=null && loggedBy.period.disabled} goal={goal} goalId={goalId} unit={unit} handleGoalsChange={handleGoalsChange} defValue={defValue} />
        case InputTypes.SELECTLIST:
            return <SelectList readOnly={loggedBy!=null && loggedBy.period.disabled} goal={goal} goalId={goalId} data={options} handleGoalsChange={handleGoalsChange} defValue={defValue} />
        case InputTypes.DATEFIELD:
            return <DateSelect readOnly={loggedBy!=null && loggedBy.period.disabled} goal={goal} goalId={goalId} handleGoalsChange={handleGoalsChange} defValue={defValue} />
    }
}

function getExpected(item,isWeighted){
    var result = null;
    switch(item.scale.inputTypeId){
        case InputTypes.DATEFIELD:
            result = <MuiOutlinedDateSelect
            readOnly={true}
            margin="dense"
            label="Elvárt"
            value={item.expected}/>
            break;
        default:
            result = <MuiOutlinedInput
            readOnly={true}
            margin="dense"
            inputId={`textfield-expected-${item.id}`}
            label="Elvárt"
            endInputAdornment={item.scale.unit}
            defaultValue={item.expected} />
            break;
    }
    if(isWeighted){
        return <Badge badgeContent={(item.relevance !== null && item.relevance !== undefined) ? `${item.relevance}%` : "0%"} color="primary">
            {result}
        </Badge>;
    }else{
        return result;
    }
}


export default function GoalsGrid(props) {
    const { data, checkIsModified, currentPage, selectedType } = props;
    const [goals, setGoals] = React.useState(data);
    const [rowForSkip, setRowForSkip] = React.useState(null);
    const [skipDialog, setSkipDialog] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const loggedBy =  UserStore.getLoggedByAdminValue();

    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = ()=>{
        setAnchorEl(null);
    }

    const handleSkip = (event, item) =>{
        setRowForSkip(item);
        setSkipDialog(true);
    }

    const handleGoalsChange = (row, newValue) => {
        var newGoals = goals;
        const index = goals.indexOf(row);
        newGoals[index] = { ...row }
        newGoals[index].performedValue = newValue;
        setGoals(newGoals);
        checkIsModified();
    }

    const handleUnSkip = (event, item) =>{
        var newGoals = goals;
        const index = goals.indexOf(item);
        newGoals[index] = { ...item }
        newGoals[index].isSkipped = false;
        newGoals[index].reasonOfSkip = '';
        setGoals(newGoals);
        checkIsModified();

        setRowForSkip(null);
        setSkipDialog(false);
    }

    const openDialog = (item) => {
        setRowForSkip(item);
        setSkipDialog(true);
    }

    const handleDoneSkip = (reason) => {
        var newGoals = goals;
        const index = goals.indexOf(rowForSkip);
        newGoals[index] = { ...reason }
        newGoals[index].isSkipped = true;
        setGoals(newGoals);
        checkIsModified();

        setRowForSkip(null);
        setSkipDialog(false);
    }

    const handleClose = () => {
        setRowForSkip(null);
        setSkipDialog(false);
    }
    const classes = style();
    return (
        goals !== null && 
        goals.length > 0 ?

        <React.Fragment>
            {rowForSkip !== null && <SkipDialog open={skipDialog} data={rowForSkip} onDone={handleDoneSkip} handleClose={handleClose} />}
            {goals.map((item, index) => (
                <div key={index} className={classes.root}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Grid
                                container
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xl={5}
                                    lg={5}
                                    md={5}
                                    sm={12}
                                    xs={12}
                                >
                                    <MuiOutlinedInput
                                        margin="dense"
                                        multiline={true}
                                        rows={3}
                                        readOnly={true}
                                        inputId={`textfield-goal-${index}`}
                                        label="Feladat"
                                        defaultValue={item.description} />
                                </Grid>
                                <Grid
                                    item
                                    lg={2}
                                    md={2}
                                    sm={12}
                                    xs={12}
                                >
                                    <MuiOutlinedInput
                                        margin="dense"
                                        disabled={true}
                                        inputId={`textfield-scale-${index}`}
                                        label="Mérőszám"
                                        //startInputAdornment={Meroszamok.getIcon(item.scale.scalesForGoalsId)}
                                        defaultValue={item.scale.label} />
                                </Grid>
                                <Grid
                                    item
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={12}
                                    xs={12}
                                >
                                    {getExpected(item,selectedType.isWeighted)}                                  
                                </Grid>
                                <Grid
                                    item
                                    xl={selectedType.canSkipResult === true ? 2 : 1}
                                    lg={selectedType.canSkipResult === true ? 2 : 1}
                                    md={selectedType.canSkipResult === true ? 2 : 1}
                                    sm={12}
                                    xs={12}
                                >
                                    {getPerformed(item, item.id, item.scale.inputTypeId, item.scale.unit, item.scale.options, item.performedValue, handleGoalsChange)}
                                </Grid>
                                {selectedType.canSkipResult === true && 
                                <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={1}
                                    sm={12}
                                    xs={12}
                                >
                                    {item.isSkipped ?
                                    <Tooltip title="Kihagyás visszavonása">
                                        <IconButton disabled={loggedBy!=null && loggedBy.period.disabled} className={classes.skipIcon} onClick={(event) =>handleUnSkip(event, item)}>
                                            <BlockIcon/>
                                        </IconButton>
                                    </Tooltip> 
                                    : <Tooltip title="Kihagyás">
                                        <IconButton disabled={loggedBy!=null && loggedBy.period.disabled} className={classes.unSkipIcon} onClick={(event) => handleSkip(event, item)}>
                                            <CheckIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    }
                                    {item.isSkipped && 
                                        <React.Fragment>
                                            <IconButton 
                                                aria-controls="others-menu"
                                                aria-haspopup="true" 
                                                onClick={handleOpenMenu}
                                            >
                                                <MoreHorizIcon/>
                                            </IconButton>
                                            <Menu
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                id={`${item.id}-others-menu`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseMenu}
                                            >
                                                <MenuItem onClick={() => openDialog(item)}>Indoklás</MenuItem>
                                            </Menu>
                                        </React.Fragment>
                                    }
                                </Grid>
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                </div>

            ))} </React.Fragment>
        : <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nincs létrehozva</Typography></div>)
}
