//React

import React from 'react';
import clsx from 'clsx';
//Core

import {
    Grid,
    Divider,
    Badge,
    Typography,
    ButtonGroup,
    Button
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
//Components

import { MuiOutlinedInput } from 'components';
import {
    DateSelect,
    TextField
} from './components';
//Helpers

import {
    UserStore,
    enums
} from 'stores';
//Style

import style from './style';


const InputTypes = enums.inputTypes;

class Field {
    index;
    goalType;
    goal;
    goalId;
    typeId;
    unit;
    defValue;
    constructor(index, goalType, goal, scale) {
        this.index = index;
        this.goalType = goalType;
        this.goal = goal;
        this.goalId = goal.id;
        this.typeId = scale.typeId;
        this.unit = scale.unit;
        this.defValue = goal.expected;
    }
}

class Relevance {
    isWeighted;
    value;
    constructor(isWeighted, value) {
        this.isWeighted = isWeighted;
        this.value = value;
    }
}



function getExpected(field, relevance) {
    var result = null;
    switch (field.goal.scale.inputTypeId) {
        case InputTypes.DATEFIELD:
            result = <DateSelect
                inputId={`textfield-expected-${field.index}-${field.goalType.id}`}
                goal={field.goal}
                readOnly={true}
                goalId={field.goalId}
                defValue={field.defValue} />
            break;
        default:
            result = <TextField
                readOnly={true}
                unit={field.unit}
                label="Elvárt"
                inputId={`textfield-expected-${field.index}-${field.goalType.id}`}
                value={field.defValue} />
            break;
    }

    if (relevance.isWeighted) {
        return <Badge badgeContent={(relevance.value !== null && relevance.value !== undefined) ? `${relevance.value}%` : '0%'} color="primary">
            {result}
        </Badge>
    } else {
        return result;
    }
}

export default function GoalsGrid(props) {
    const { data, goalType, currentPage, edit, goalTypeMatrix, readOnly } = props;
    const { handleClickModify, onClickRemove } = props;

    const [goals, setGoals] = React.useState(data);

    const editIndex = edit != null ? goals.findIndex(x => x.id == edit.base.id) : -1;
    const classes = style();
    const loggedBy = UserStore.getLoggedByAdminValue();

    return (goals !== null && goals.length > 0 ?
        goals.map((item, index) => (
            <div key={index} className={index !== editIndex ? classes.root : clsx(classes.root, classes.selectedRow)}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                <MuiOutlinedInput
                                    margin="dense"
                                    multiline={true}
                                    rows={3}
                                    disabled={true}
                                    id={`textfield-goal-${index}-${goalType.id}`}
                                    label="Feladat"
                                    defaultValue={item.description}
                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                <MuiOutlinedInput
                                    margin="dense"
                                    disabled={true}
                                    id={`textfield-scale-${index}-${goalType.id}`}
                                    label="Mérőszám"
                                    defaultValue={item.scale.label}
                                />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                {getExpected(new Field(index, goalType, item, item.scale), new Relevance(goalTypeMatrix.isWeighted, item.relevance))}
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                <div style={{ textAlign: 'right' }}>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => handleClickModify(item, goalType, currentPage)}
                                            className={classes.editButton}
                                            disabled={index === editIndex ? true : false || (loggedBy != null && loggedBy.period.disabled) || readOnly}
                                            size="small"
                                            endIcon={<EditIcon />}
                                        >
                                            Módosítás
                                        </Button>
                                        <Button
                                            onClick={() => onClickRemove(item, currentPage, goalType)}
                                            className={classes.removeButton}
                                            disabled={index === editIndex ? true : false || (loggedBy != null && loggedBy.period.disabled) || readOnly}
                                            size="small"
                                            endIcon={<DeleteIcon />}
                                        >
                                            Eltávolítás
                                        </Button>
                                    </ButtonGroup>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </div>
        ))
        : <div style={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>
                Nincs létrehozva
            </Typography>
        </div>
    )
}
