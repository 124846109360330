import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    login_box_root:{
        [theme.breakpoints.down('sm')]: {
            margin:'10px 20px',
        },
        margin:'60px 30px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    login_box_header__ter:{
        [theme.breakpoints.down('sm')]: {
            width:'200px',
            height:'45px', 
        },
        background:'url(/images/login_ter-lg.png)',
        backgroundPosition:'center', 
        width:'260px',
        height:'65px', 
        border:`2px solid ${theme.palette.appMain.main}`
    },
    login_box_header__exit:{
        [theme.breakpoints.down('sm')]: {
            width:'200px',
            height:'45px', 
        },
        background:'url(/images/login_exit-lg.png)',
        backgroundPosition:'center', 
        width:'260px',
        height:'65px', 
        border:`2px solid ${theme.palette.appMain.main}`
    },
    login_box_header__probaido:{
        [theme.breakpoints.down('sm')]: {
            width:'200px',
            height:'45px', 
        },
        background:'url(/images/login_probaido-lg.png)',
        backgroundPosition:'center', 
        width:'260px',
        height:'65px', 
        border:`2px solid ${theme.palette.appMain.main}`
    },
    login_box_body:{
        [theme.breakpoints.down('sm')]: {
            width:'170px',
            height:'45px', 
        },
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'white',
        width:'230px',
        height:'60px',
        borderBottom:'2px solid #008AAB', 
        borderLeft:'2px solid #008AAB', 
        borderRight:'2px solid #008AAB',
        borderRadius:'0px 0px 10px 10px'
    },
    login_box_title:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'16px',
        },
        fontSize:'24px',
        fontWeight:'bold',
        color:theme.palette.appMain.main
    },
    login_box_button:{
        textTransform:'capitalize', 
        fontFamily:'Nunito', 
        borderRadius:'0px', 
        border:`2px solid white`,
        backgroundColor:theme.palette.appMain.main,
        color:'white',
        '&:hover': {
            backgroundColor:'white',
            color:theme.palette.appMain.main,
            border:`2px solid ${theme.palette.appMain.main}`,
            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
        },
        '&:disabled':{
            border:`2px solid `+theme.palette.disabled.default,
            backgroundColor:'white',
            color:theme.palette.disabled.default,
        }
    },
    button_wrapper:{
        position: 'relative'
    },
    button_progress:{
        color: theme.palette.appMain.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));
