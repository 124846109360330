//React

import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Styles

import style from './style';
//Core

import {
    Card,
    CardContent,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Tooltip,
    IconButton,
    TextField,
    CardActions,
    Typography,
    LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ContactsIcon from '@material-ui/icons/Contacts';


const StyledTableRow = style.StyledTableRow;

const DataTable = props => {
    const {className, loading, data, period, edit, onEdit, onUserDrawer, onDone, selectedLabels, onRoleDrawer, onOpen, onCancel, onChange, onSave, onDelete, onSelect, ...rest} = props;

    const classes = style.useStyles();

    const handleSelectAll = event => {

        let selectedLabels;

        if (event.target.checked) {
            selectedLabels = data.map(org => org.id);
        } else {
            selectedLabels = [];
        }

        onSelect(selectedLabels);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedLabels.indexOf(id);
        let newSelectedLabels = [];

        if (selectedIndex === -1) {
            newSelectedLabels = newSelectedLabels.concat(selectedLabels, id);
        } else if (selectedIndex === 0) {
            newSelectedLabels = newSelectedLabels.concat(selectedLabels.slice(1));
        } else if (selectedIndex === selectedLabels.length - 1) {
            newSelectedLabels = newSelectedLabels.concat(selectedLabels.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedLabels = newSelectedLabels.concat(
                selectedLabels.slice(0, selectedIndex),
                selectedLabels.slice(selectedIndex + 1)
            );
        }

        onSelect(newSelectedLabels);
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent className={classes.content}>
            {loading && <LinearProgress />}
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedLabels.length === data.length}
                                            color="primary"
                                            indeterminate={
                                                selectedLabels.length > 0 &&
                                                selectedLabels.length < data.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell align="center" padding="none">Azonosító</TableCell>
                                    <TableCell align="center" padding="none">Megnevezés</TableCell>
                                    <TableCell align="center" padding="none">Aktív</TableCell>
                                    <TableCell padding="none" align="center">Műveletek</TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <StyledTableRow
                                        className={classes.tableRow}
                                        hover
                                        key={row.id}
                                        selected={selectedLabels.indexOf(row.id) !== -1}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedLabels.indexOf(row.id) !== -1}
                                                color="primary"
                                                onChange={event => handleSelectOne(event, row.id)}
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell padding="none" align="center" >
                                            {row.id}
                                        </TableCell>
                                        <TableCell padding="none" align="center" >
                                            {(edit != null && edit.id == row.id) && 
                                                <TextField 
                                                    helperText={!(row.name != null && row.name != undefined && row.name != '') ? 'Nem megengedett érték' : ''} 
                                                    error={!(row.name != null && row.name != undefined && row.name != '') ? true : false} 
                                                    onChange={(event) => onChange(event, index, 'name')} 
                                                    value={row.name}
                                                    />
                                            }
                                            {(edit == null || (edit != null && edit.id != row.id)) && row.name}
                                        </TableCell>
                                        <TableCell padding="none" align="center" >
                                            {row.isValid == true ? 'Igen' : 'Nem'}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {(edit == null || (edit != null && edit.id != row.id)) &&
                                                <React.Fragment> 
                                                {row.isValid == true ? 
                                                <Tooltip title="Munkakör letiltása">
                                                    <IconButton
                                                        disabled={(edit != null ? true : false)}  
                                                        onClick={() => onDelete(index)} 
                                                        className={classes.doneButton} 
                                                        aria-label="done"
                                                    >
                                                        <DoneIcon />
                                                    </IconButton>
                                                </Tooltip> : 
                                                <Tooltip title="Munkakör feloldása">
                                                    <IconButton
                                                        disabled={(edit != null ? true : false)}  
                                                        onClick={() => onDone(index)} 
                                                        className={classes.blockButton} 
                                                        aria-label="block"
                                                    >
                                                        <BlockIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                                <Tooltip title="Módosítás">
                                                    <span>
                                                        <IconButton 
                                                            disabled={(edit != null ? true : false)} 
                                                            onClick={() => onEdit(row)} 
                                                            className={classes.editButton} 
                                                            aria-label="edit"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>  
                                                <Tooltip title="Személyek">
                                                    <span>
                                                        <IconButton 
                                                            disabled={(edit != null ? true : false)} 
                                                            onClick={() => onUserDrawer(row)} 
                                                            className={classes.peopleIcon} 
                                                            aria-label="persons"
                                                        >
                                                            <GroupAddIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Jogosultságok">
                                                    <span>
                                                        <IconButton 
                                                            disabled={(edit != null ? true : false)} 
                                                            onClick={() => onRoleDrawer(row)} 
                                                            className={classes.roleIcon} 
                                                            aria-label="roles"
                                                        >
                                                            <ContactsIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                </React.Fragment>
                                            }
                                            {(edit != null && edit.id == row.id) &&
                                                <React.Fragment> 
                                                    <Tooltip title="Mentés">
                                                        <span>
                                                            <IconButton 
                                                                disabled={!(row.name != null && row.name != undefined && row.name != '') ? true : false} 
                                                                onClick={() => onSave(index)} 
                                                                className={classes.saveButton} 
                                                                aria-label="save"
                                                            >
                                                                <DoneIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Mégsem">
                                                        <IconButton 
                                                            onClick={() => onCancel(index)} 
                                                            className={classes.cancelButton} 
                                                            aria-label="cancel"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip> 
                                                </React.Fragment>
                                        }
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {data.length < 1 &&
                                    <StyledTableRow>
                                        <TableCell align="center" colSpan="5">
                                        {loading ? 'Betöltés...' : 'Nincs találat'}
                                  </TableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
            <CardActions>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', }}>
                    <Typography style={{ fontWeight: 'bold' }}>Új szűrő létrehozása: </Typography>
                    <Tooltip title="Új hozzáadás">
                        <span>
                            <IconButton 
                                disabled={edit != null ? true : false} 
                                onClick={onOpen} 
                                className={classes.addButton} 
                                aria-label="add"
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>           
            </CardActions>
        </Card>
    );
};

export default DataTable;
