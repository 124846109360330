
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetCompetences: 'ratingForm/extended',
}

export const apiRequest = {
    populateCompetences
};

async function populateCompetences(parent) {
    const {userid,period} = parent.props;

    var model = {period,userId:userid};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetCompetences,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                competences: res.competences,
                users: res.usersForExtendedTable,
                results: res.resultsForExtendedTable,
                resultAvg: res.resultAvg,
                loading: false,
            }));
        }).catch(handleError);
    
}