
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoals: 'goal/previous/goals'
}

export const apiRequest = {
    populateGoals
};

async function populateGoals(parent,currentPage, typeId, periodId) {
    const {userId} = parent.props;
    const p_pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const p_typeId = isNaN(typeId) ? -1 : typeId;
    const p_periodId = (periodId === null || periodId === undefined) ? -1 : periodId;

    var model = {userId,periodId:p_periodId,typeId:p_typeId,currentPage:p_pageNumber};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                loading: false
            });
        }).catch(handleError);

}