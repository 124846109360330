import React from 'react';
import {Api,handleResponse,handleError} from 'api';
//Components
import { NotificationBar,Progress  } from 'components';
//Action
const Actions = {
    Remove: 'user/avatar/remove',
}


export const apiRequest = {
    remove
};

async function remove(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Remove).then(handleResponse)
        .then(res => {
                window.location.reload();
        }).catch(error => {
            parent.setState({
                request:false
            });
            handleError(error);
        });
}