import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    head: {
      '& > *': {
          fontSize: '0.9rem',
          fontWeight: 'bold'
      }
  },
    editIcon:{
      color:theme.palette.warning.main
    },
    saveButton:{
      color: theme.palette.success.main
    },
    cancelButton: {
      color: theme.palette.error.main
    },
    addButton:{
      color:theme.palette.success.main
    },
    deleteButton:{
      color:theme.palette.error.main
    },
    deleted: {
      backgroundColor:'#ffffba'
    }
  });
  
  const useStyles = ({
    head: {
      '& > *': {
          fontSize: '0.9rem',
          fontWeight: 'bold'
      }
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    addButton:{
      color:theme.palette.success.main
    }
  });

  export default {
    useRowStyles,
    useStyles
  }