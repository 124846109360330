//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { NotificationBar,Progress  } from 'components';
//Actions

const Actions = {
    Save:'admin/masterData/rating-periods/steps/new/save',
    SavePeriod:'admin/masterData/rating-periods/period/new/save',
    MinDate:'admin/masterData/rating-periods/min-date',
}

export const apiRequest = {
    saveChanges,
    savePeriodChanges,
    getMinDate
};

async function saveChanges(parent,model) {
    const {refresh} = parent.props;
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        refresh();
    }).catch(handleError);
}

async function savePeriodChanges(parent,model) {
    const {refresh} = parent.props;
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.SavePeriod,model)
    .then(handleResponse)
    .then(res => {
        refresh();
    }).catch(handleError);
}


async function getMinDate(parent,model) {
    await new Api().call.get(Actions.MinDate)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            minDate:res
        });
        //refresh();
    }).catch(handleError);
}


