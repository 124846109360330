import React, { Component } from 'react';
//Core
import {
    Grid,
    Typography
} from '@material-ui/core';
//Style
import style from './style';



export default function SubHeader(props) {
    const { title } = props;

    const classes = style.useStyles();
    return (<div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
    </div>)
}
