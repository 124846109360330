import React from 'react';

//Components
import { MuiOutlinedInput } from 'components'; 

export default function NumberField(props) {
    const { goal, goalId, unit,disabled, defValue,readOnly, handleGoalsChange } = props;
    const [value, setValue] = React.useState(defValue !== null ? defValue : '');

    const handleChange = (event) => {
        const value = event.target.value;
        setValue(value);
        handleGoalsChange(goal, value);
    }
    const validate = () => {
        const parsedValue = parseInt(value);
        let valid = false;
        if (!isNaN(parsedValue)) {
            valid = true;
        }
        return valid;
    }

    return (
        <MuiOutlinedInput
            disabled={disabled}
            readOnly={readOnly}
            margin="dense"
            className={validate() === false && "goal-result-notvalid"}
            endInputAdornment={unit}
            helperText="Nem megengedett érték"
            error={validate() === false ? true : false}
            label="Teljesítve"
            inputId="textfield-performed"
            value={value}
            type="number"
            handleChange={handleChange} />
  )
}
