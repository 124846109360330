//Styles
import { makeStyles } from '@material-ui/styles';

const useStylesForPicker = makeStyles(theme => ({
    root: {
        margin:'1.0rem'
    },
    picker: {
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.0rem',
            fontWeight: 'normal'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.0rem'
        }
    },
}));

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },
  inner: {
    padding: '1.0rem'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
    },
    blockButton: {
        color: theme.palette.error.main
    },
    lockButton: {
        color: theme.palette.primary.main
    },
    unlockButton: {
        color: theme.palette.success.main
    },
    editButton: {
        color: theme.palette.warning.main,
        float:'right'
    },
    doneButton: {
        color: theme.palette.success.main,
        float:'right'
    },
    backButton: {
        color: theme.palette.error.main,
        float:'right'
    },
    addButton: {
        color: theme.palette.success.main
    },
    deleteButton: {
        color: theme.palette.error.main,
        float:'right'
    }
}));

export default {
    useStyles,
    useStylesForPicker
}