//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    GetGroups: 'admin/rating/competenc-groups',
}

export const apiRequest = {
    populateGroups,
};

async function populateGroups(parent) {

    await new Api().call.get(Actions.GetGroups)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                compGroups:false
            },
            compGroups:res
        }));
    }).catch(handleError);
}
