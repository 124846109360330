import { makeStyles,withStyles } from '@material-ui/core/styles';
//Core
import {
    Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '1.6',       
        },
        backgroundColor: theme.palette.papper
    },
    compName: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
    },
    editButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            color:theme.palette.warning.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.warning.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    doneButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    buttonDiv: {
        padding: theme.spacing(2),    
    },
   


    chipOk: {
        float: 'right',
        color: theme.palette.white,
        backgroundColor: theme.palette.success.light
    },
    chipNotOk: {
        float: 'right',
        color: theme.palette.white,
        backgroundColor: theme.palette.error.light
    }
}));

const StyledTooltip = withStyles({
    tooltip: {
        fontSize:'1.0rem'
    }
})(Tooltip);

export default {
useStyles:useStyles,
StyledTooltip:StyledTooltip
}
