import React from 'react';
//Core
import {
    Typography,
    Select,
    FormControl,
    FormHelperText,
    MenuItem
} from '@material-ui/core';
import { UserStore } from 'stores';
//Style
import style from './style';


export default function SelectList(props) {
    const { data, defValue, itemId, handleChanges } = props;
    const [value, setValue] = React.useState(defValue === null || defValue === undefined ? '0' : defValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        handleChanges(itemId, event.target.value*1)
    };

    const loggedBy =  UserStore.getLoggedByAdminValue();

    const classes = style.useStyles();
    return (
       
        <FormControl margin="dense" className={classes.formControl} error={value!=0 ? false : true}>
                <Select
                    labelId="select-mot-value-label"
                    id="select-mot-value"
                    value={value}
                    displayEmpty
                    disabled={loggedBy!=null && loggedBy.period.disabled}
                    className={classes.select}
                    onChange={handleChange}
                >
                    <MenuItem value="0" disabled>
                        <Typography className={classes.menuItem}> 
                            Válaszd ki
                        </Typography>            
                    </MenuItem>
                    {data.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            <Typography className={classes.menuItem}>
                                {item.label}
                            </Typography>
                        </MenuItem>
                     ))}
            </Select>
            {!(value != 0) && <FormHelperText>Kötelező választani</FormHelperText>}
             
                </FormControl>   
            
    );
}
