import React, { Component } from 'react';
//Core
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    Typography,
    Tooltip,
    Zoom
} from '@material-ui/core';
//Components
import { RatingLine,RatingLineHeader } from './components';
//Style
import style from './style';





export default function RatingCard(props) {
    const { title, competences, handleCompetences, groupId, ratingPoints } = props;
    const classes = style.useStyles();
  const StyledTooltip = style.StyledTooltip;
    return (<Card>
        <CardHeader  className={classes.headerTitle} title={title}>
        </CardHeader>
        <Divider />
        <CardContent > 
            {/*<Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={8}
                    sm={8}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={4}
                    xs={12}
                >
                    <RatingLineHeader />
                </Grid>
            </Grid>*/}
            {competences.map(competence => (
                <Grid
                    container
                    key={competence.id}
                    spacing={2}
                >
                    <Grid
                        item
                        md={8}
                        sm={8}
                        xs={12}
                    >
                        <StyledTooltip TransitionComponent={Zoom} title={competence.description}>
                            <Typography className={classes.compName}>{competence.label}</Typography>
                        </StyledTooltip>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={4}
                        xs={12}
                    >
                        {/* <RatingLineHeader /> */}
                        <RatingLine ratingPoints={ratingPoints} groupId={groupId} compId={competence.id} handleCompetences={handleCompetences} name={competence.id} value={competence.value} />
                    </Grid>
                </Grid>
            ))}
        </CardContent>
        <Divider />
    </Card>)
}
