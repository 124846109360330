//React

import React from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
    Button
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
//Style

import theme from 'theme';
import style from './style';


export default function AlertDialog(props) {
    const { open, onClose, user } = props;

    const classes = style();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle 
                style={{ backgroundColor: theme.palette.warning.light }} 
                id="alert-dialog-title"
            >               
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Figyelmeztetés
                    </Typography>
                    <ErrorOutlineIcon style={{ color: theme.palette.white }} />
                </div>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Typography style={{ fontSize: '1.0rem' }}>
                    Az adott felhasználó: "{user.fullName}" nem rendelhető több értékelendő személyhez!
                </Typography>                       
            </DialogContent>
            <DialogActions>
                <Button 
                    endIcon={<DoneIcon />}
                    onClick={onClose}
                    className={classes.okButton} 
                >
                    Rendben
                </Button>
            </DialogActions>
        </Dialog>
    );
}
