//React

import React,{ Component } from 'react';
import clsx from 'clsx';
//Components

import {
  ConfirmDialog,
  NewDialog
} from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';
//Pickers

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  InputAdornment,
  LinearProgress,
  InputLabel
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TodayIcon from '@material-ui/icons/Today';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


const DateTimePicker = props => {
  const {value, disabled, id, name, error, label, minDate, maxDate, errorClassName, readOnly, onChange, helperText} = props;
  const classes = style.useStylesForPicker();

  return (
    <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
      <DatePicker
          InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton disabled={disabled}>
                          <TodayIcon />
                      </IconButton>
                  </InputAdornment>
              ),
          }}
          error={error}
          clearable={true}
          clearLabel="Törlés"
          emptyLabel="Nincs megadva"
          id={id}
          name={name}
          className={classes.picker}
          cancelLabel="Vissza"
          openTo="date"
          label={label}
          readOnly={readOnly}
          disabled={disabled}
          format="yyyy MMMM dd"
          minDate={minDate}
          minDateMessage="Nem megengedett érték"
          maxDate={maxDate}
          maxDateMessage="Nem megengedett érték"
          views={["year", "month", "date"]}
          autoOk
          value={(value !== null && value !== undefined) ? value : null}
          onChange={date => onChange(date)}
      />
    </MuiPickersUtilsProvider >
  )
}


function Row(props) {
  const {main, edit, onEdit, onAddNew, onSave, onCancel, onDelete, deleted, mainIndex, onChange, onDateChange, onPausePeriod, onPauseStep, periods, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.id}
        </TableCell>
        <TableCell align="center">
          <TextField
              name="name"
              onChange={(event)=>onChange(event)}
              disabled={(edit == null)}
              value={(main.name===null || main.name===undefined) ? '' : main.name}
          />        
        </TableCell>
        <TableCell align="center">
          <TextField
              name="label"
              fullWidth
              onChange={(event)=>onChange(event)}
              disabled={(edit == null)}
              value={(main.label===null || main.label===undefined) ? '' : main.label}
          />  
        </TableCell>
        <TableCell align="center">
        {(main.isPaused === null || main.isPaused === undefined || main.isPaused===false) ?
          <Tooltip title="Lépés szüneteltetése">
            <span>
              <IconButton onClick={()=>onPausePeriod(main.id,true)} disabled={edit!==null || period.disabled===true} className={classes.playIcon} >
                <PlayArrowIcon />
              </IconButton>
            </span>
          </Tooltip>                     
          :
          <Tooltip title="Lépés folytatása"> 
            <span>
              <IconButton onClick={()=>onPausePeriod(main.id,false)} disabled={edit!==null || period.disabled===true} className={classes.pauseIcon}>
                <PauseIcon />
              </IconButton>
            </span>
          </Tooltip> 
        }
        </TableCell>
        <TableCell align="center">
          <DateTimePicker
            minDate={main.minDate}
            disabled={(edit == null)}
            value={main.openedTime}
            onChange={(date)=>onDateChange(date,"openedTime")}
          />
        </TableCell>
        <TableCell align="center">
          <DateTimePicker
            minDate={main.openedTime}
            disabled={(edit == null)}
            value={main.closedTime}
            onChange={(date)=>onDateChange(date,"closedTime")}
          />
        </TableCell>
        <TableCell align="center">
        {(edit == null) && <React.Fragment>
        <Tooltip title="Módosítás">
          <span>
            <IconButton 
              onClick={()=>onEdit(main)} 
              className={classes.editIcon}
              disabled={main.disabled || period===null || period.disabled===true}>
              <EditIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main)}   
              className={classes.deleteButton}
              disabled={main.disabled || period===null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
        </Tooltip></React.Fragment>
        }
        {(edit != null) &&
          <React.Fragment> 
            <Tooltip title="Mentés">
              <span>
                <IconButton  
                  onClick={()=>onSave(mainIndex)} 
                  className={classes.saveButton} 
                  aria-label="save"
                >
                  <DoneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Mégsem">
              <IconButton 
                onClick={onCancel} 
                className={classes.cancelButton} 
                aria-label="cancel"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> 
          </React.Fragment> 
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell />
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="none" align="center">Lépés</TableCell>
                    <TableCell padding="none" align="center">Állapot</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.ratingSteps.map((sub,subIndex) => (
                    <SubRow 
                      onPauseStep={onPauseStep} 
                      onAddNew={onAddNew} 
                      onDateChange={onDateChange} 
                      deleted={deleted} 
                      onDelete={onDelete} 
                      key={sub.stepId} 
                      firstIndex={subIndex} 
                      period={period} 
                      parent={main} 
                      main={sub} 
                      edit={edit} 
                      periods={periods}
                    />))
                  }                                 
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SubRow(props) {
  const {main, edit, parent, firstIndex, onDateChange, onAddNew, deleted, periods, onPauseStep, onDelete, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton 
            aria-label="expand row" 
            size="small" 
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.stepId}
        </TableCell>
        <TableCell scope="row" align="center">
          {main.name}
        </TableCell>
        <TableCell align="center">         
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell align="center">Jogosultság</TableCell>
                    <TableCell padding="none" align="center">Állapot</TableCell>
                    <TableCell padding="none" align="center">Kezdet dátum</TableCell>
                    <TableCell padding="none" align="center">Vég dátum</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {main.rolesToRatingSteps.map((sub,subIndex) => (
                    <TableRow className={(deleted!==null && deleted.id==sub.id) ? classes.deleted : ''} key={sub.id}>
                      <TableCell padding="checkbox" align="left" component="th" scope="row">
                        {sub.id}
                      </TableCell>
                      <TableCell align="center">
                        {sub.name}
                      </TableCell>
                      <TableCell align="center">
                        {(sub.isPaused === null || sub.isPaused === undefined || sub.isPaused===false) ?
                        <Tooltip title="Lépés szüneteltetése">
                          <span>
                            <IconButton onClick={()=>onPauseStep(sub.id,true)} disabled={edit!==null || period.disabled===true} className={classes.playIcon} >
                              <PlayArrowIcon />
                            </IconButton>
                          </span>
                        </Tooltip>                     
                         :
                         <Tooltip title="Lépés folytatása"> 
                           <span>
                            <IconButton onClick={()=>onPauseStep(sub.id,false)} disabled={edit!==null || period.disabled===true} className={classes.pauseIcon}>
                              <PauseIcon />
                            </IconButton>
                          </span>
                        </Tooltip> 
                        }
                      </TableCell>                                        
                      <TableCell align="center">
                        <DateTimePicker
                          minDate={parent.openedTime}
                          maxDate={parent.closedTime}
                          disabled={(edit == null)}
                          value={sub.openedTime}
                          onChange={(date)=>onDateChange(date,"openedTime",firstIndex,subIndex)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <DateTimePicker
                          minDate={sub.openedTime}
                          maxDate={parent.closedTime}
                          disabled={(edit == null)}
                          value={sub.closedTime}
                          onChange={(date)=>onDateChange(date,"closedTime",firstIndex,subIndex)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Törlés">
                          <span>
                            <IconButton
                              onClick={()=>onDelete(sub)}   
                              className={classes.deleteButton}
                              disabled={main.disabled || period===null || edit!==null || period.disabled===true}>
                              <DeleteIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                          ))}
                  <TableRow>
                    <TableCell padding="none" colSpan={8}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új időszak létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton 
                                onClick={()=>onAddNew(1,main.stepId)}
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != parent.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>       
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


class PeriodsTree extends Component {

    constructor(props){
        super(props);
        this.state={
            inputPoints:[],
            roles:[],
            periods:[],
            period:'',
            edit:null,
            deleted:null,
            loading:null,
            newPoint:null,
            step:null,
        };
    }

    componentDidMount(){
        apiRequest.populateRoles(this);
        apiRequest.populatePeriods(this);
    }

    componentDidUpdate(prevProps,prevState){
      const {period} = this.state;
      if(prevState.period!=period){
        this.setState({
          loading:true,
          edit:null
        });
        apiRequest.populateInputPoints(this,period.id);
      }
    }

    handleEdit = (row) =>{
      this.setState({
        edit:{ ...row }
      });
    }

    handleCancel = () =>{
      const {period} = this.state;
      apiRequest.populateInputPoints(this,period.id);
      this.setState({
          edit:null
      });
    }

    handleNewPoint = (level,step) =>{
      this.setState({
        newPoint:level,
        step
      });
    }

    afterAddedNewPoint = () =>{
      const {period} = this.state;
      this.setState({
        newPoint:null,
        step:null
      });
      apiRequest.populatePeriods(this);
      apiRequest.populateInputPoints(this,period.id);
    }

    handleChange = (event,date) => {
      let value = event.target.value;
      let name = event.target.name;
      const inputPoints = {...this.state.inputPoints};
      inputPoints[name] = value;   
      this.setState({ inputPoints });
    }

    handleDateChange = (date,name,index,subIndex) =>{
      const inputPoints = {...this.state.inputPoints};
      let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 

      if(index!=null && subIndex!==null){      
        inputPoints.ratingSteps[index].rolesToRatingSteps[subIndex][name] = newDate;
      }else{
        inputPoints[name] = newDate
      }
      
      this.setState({inputPoints});
    }

    handleSave = (mainIdex) =>{
        const {inputPoints,period} = this.state;
        
        apiRequest.saveChanges(this,inputPoints,period.id);        
    }

    handlePeriodChange = (event) =>{
      const value = event.target.value;
      this.setState({
        period:value
      });
    }

    handleDelete = (row) =>{
      this.setState({
        deleted:row
      });
    }

    deleteRow = () =>{
      const {deleted,period} = this.state;

      const model = {id:deleted.id,period:period.id};
      apiRequest.deletePoint(this,model,period.id);
    }

    handlePausePeriod = (id,isPaused) =>{
      const {period} = this.state;

      const model ={id,isPaused}
      apiRequest.pausePeriod(this,model,period.id);
    }

    handlePauseStep = (id,isPaused) =>{
      const {period} = this.state;

      const model ={id,isPaused}
      apiRequest.pauseStep(this,model,period.id);
    }

    render(){
        const {inputPoints, roles, edit, periods, period, deleted, step, newPoint, loading} = this.state;
        const {classes} = this.props;

        return (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="period-select-label">Periódusok</InputLabel>
                  <Select
                    labelId="period-select-label"
                    id="period-select"
                    value={period}
                    onChange={this.handlePeriodChange}
                  >
                    {periods.map(period=>
                    <MenuItem key={period.id} value={period}>{period.label}</MenuItem>)}
                  </Select>
              </FormControl>
              <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                  <Typography style={{ fontWeight: 'bold' }}>Új periódus létrehozása: </Typography>
                  <Tooltip title="Új hozzáadás">
                      <span>
                          <IconButton
                            onClick={()=>this.handleNewPoint(0)} 
                            disabled={edit != null}
                            className={classes.addButton} 
                            aria-label="add"
                          >
                              <AddIcon />
                          </IconButton>
                      </span>
                  </Tooltip>
              </div>
              <TableContainer component={Paper}>
              {loading && <LinearProgress />}
              <PerfectScrollbar>
              <Table aria-label="collapsible table">
                  <TableHead>
                  <TableRow className={classes.head}>
                      <TableCell />
                      <TableCell padding="checkbox" align="left">Azonositó</TableCell>
                      <TableCell padding="none" align="center">Megnevevezés</TableCell>
                      <TableCell padding="none" align="center">Cím</TableCell>
                      <TableCell padding="none" align="center">Állapot</TableCell>
                      <TableCell padding="none" align="center">Kezdet dátum</TableCell>
                      <TableCell padding="none" align="center">Vég dátum</TableCell>
                      <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {loading===false &&  
                      <Row
                        loading={loading} 
                        mainIndex={0} 
                        main={inputPoints} 
                        roles={roles}
                        deleted={deleted} 
                        edit={edit}
                        period={(period==='' || period===undefined || period===null) ? null : period}
                        periods={periods}
                        onChange={this.handleChange}
                        onPausePeriod = {this.handlePausePeriod}
                        onPauseStep = {this.handlePauseStep}
                        onDateChange={this.handleDateChange} 
                        onDelete={this.handleDelete}
                        onAddNew={this.handleNewPoint}
                        onEdit={this.handleEdit}
                        onSave={this.handleSave}
                        onCancel={this.handleCancel}
                      />
                  }
                   {(period==='' || period===undefined || period===null) ?
                    <TableRow>
                        <TableCell align="center" colSpan="8">
                          Nincs kválasztva periódus
                        </TableCell>
                    </TableRow>
                    :  inputPoints.length < 1 && 
                    <TableRow>
                      <TableCell align="center" colSpan="8">
                        {loading ? 'Betöltés...' : 'Nincs találat'}
                      </TableCell>
                    </TableRow>
                    }
                  </TableBody>
              </Table>
              </PerfectScrollbar>
              </TableContainer>
              <ConfirmDialog 
                onClose={()=>this.handleDelete(null)} 
                onDelete={this.deleteRow} 
                open={deleted!==null}
              />
              {newPoint!=null && 
                <NewDialog
                  step={step}
                  refresh={this.afterAddedNewPoint}
                  period={period}
                  roles={roles}
                  onClose={()=>this.handleNewPoint(null)} 
                  level={newPoint}
                  open={newPoint!=null}
                />
              }
            </div>
        );
    }
}
export default withStyles(style.useStyles)(PeriodsTree)