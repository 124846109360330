import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
//Components
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { List, ListItem, Button } from '@material-ui/core';
//Icons
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from '@material-ui/icons/People';
//Style
import style from './style';



const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const { pages, pathName, className, ...rest } = props;
    const classes = style();

    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            <ListItem
                    className={classes.item}
                    disableGutters                  
                >
                   <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/exit/add-user"
                    name="Kezdőlap"
                    id="exit/add-user"
                >
                    <div className={classes.icon}><PersonAddIcon/></div>
                        Új kilépő felvitele
                </Button> 
            </ListItem>
            <ListItem
                    className={classes.item}
                    disableGutters                  
                >
                   <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/exit/users-list"
                    name="Kezdőlap"
                    id="exit/users-list"
                >
                    <div className={classes.icon}><PeopleIcon/></div>
                        Kilépők listája
                </Button> 
            </ListItem>
            <ListItem
                    className={classes.item}
                    disableGutters                  
                >
                   <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/exit/admin"
                    name="Kezdőlap"
                    id="exit/admin"
                >
                    <div className={classes.icon}><EditIcon/></div>
                        Adminisztráció
                </Button> 
            </ListItem>
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
