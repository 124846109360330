//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    Typography,
    TextField
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            company: '',
            valid:false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.company != this.state.company) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });       
    }

    handleSave = () => {
        const {company} = this.state;

        const model = { company };
        apiRequest.saveChanges(this, model);
    }

    validation = () => {
        var doc = document.getElementById("add-new-form");     
        if (doc !== null && doc !== undefined) {
            if (!(doc.querySelectorAll(".Mui-error").length>1)) {
                return true;
            }
        }
        return false;
    }
    
    render() {
        const {onClose, open, classes} = this.props;
        const {company, valid} = this.state;

        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új cégcsoport hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid spacing={2} container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div id="add-new-form">
                                <TextField
                                    required
                                    style={{ width: '100%' }}
                                    label="Megnevezés"
                                    name="company"
                                    error={(company == null || company == undefined || company == '') ? true : false}
                                    helperText={(company == null || company == undefined || company == '') ? 'Kötelező kitölteni' : ''}
                                    onChange={this.onChange}
                                    value={company}
                                />
                            </div>
                        </Grid>                        
                   </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                    <DoneIcon />
                                 </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(style.useStyles)(AddNewDialog);
