import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import {
    AppBar,
    Toolbar,
    Badge,
    Hidden,
    IconButton,
    Typography,
    Tooltip
} from '@material-ui/core';
//Icons
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
//Style
import style from './style';


const Topbar = props => {
    const { pathName, className, onSidebarOpen, openSidebar,profile, onLogoutDialogOpen, ...rest } = props;
    const classes = style();

    const [notifications] = useState([]);

    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    };

  return (
    <AppBar
      {...rest}
          className={clsx( profile==='admin' ? classes.adminRoot : classes.root, className)}
    >
          <Toolbar>

              {profile!=='admin' ? 
                <Tooltip title="Kezdőlap">
                  <RouterLink to="/home">
                        <img
                        alt="Logo"
                        style={{filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'}}
                        height="45"
                        width="50"
                        src="/images/logos/logo--app--white.png"
                    />
                    </RouterLink> 
                  </Tooltip> : 
                  <img
                      alt="Logo"
                      height="45"
                      width="50"
                      style={{filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'}}
                      src="/images/logos/logo--app--white.png"
                  />
                }
             
              <Hidden mdDown>
                  <Typography className={classes.brandName}>Merkbau TÉR - EXIT interjú modul</Typography>
              </Hidden>
              <Hidden lgUp>
                  <Typography className={classes.brandName}>TÉR - EXIT</Typography>
              </Hidden>
              <div className={classes.flexGrow} />
              <Hidden mdDown>
                  {/*<Tooltip title="Értesítés">
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          </Tooltip>*/}
          <Tooltip title="Kijelentkezés">
          <IconButton
               className={classes.signOutButton}
               color="inherit"
               onClick={onLogoutDialogOpen}
          >
          <InputIcon />
          </IconButton>
          </Tooltip>
        </Hidden>
              <Hidden lgUp>
                  
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
                  >
                      {!openSidebar ? <MenuIcon /> : <MenuOpenIcon />}
            
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
