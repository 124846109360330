import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
    Typography,
    Grid,
    Card,
    CardContent,
    Divider,
    Button,
    ButtonGroup,
    Tooltip,
    Icon,
    Box
} from '@material-ui/core';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Components

import { SaveDialog } from 'components';
import { 
    GeneralSlider, 
    GeneralRatingGrid 
} from './components';
import { 
    Constants, 
    UserStore 
} from 'stores';
import { apiRequest } from './apiRequest';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/styles';


class SelfRating extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);

        this.state = {
            competences: [],
            baseCompetences: [],
            gridCompetences:[],
            gridIsModified:false,
            isModified: false,
            ratingIsActive:false,
            showRating: true,
            showSlider: true,
            loggedBy:UserStore.getLoggedByAdminValue(),
            autoSave: UserStore.getUserValue().autoSave,
            loading: true,
            open: false,
            nextLocation: null
        }

    }

    //#Lifecycle methods

    componentDidMount(){
        apiRequest.populateCompetences(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;

        if (prevState.loading != loading) {
            if (loading === false) {
                Constants.setcontentLoading(false);
            }
        }
    }

    componentWillUnmount() {
        const { competences, gridCompetences, gridIsModified, isModified } = this.state;
        if (this.state.autoSave === true) {
                if (gridIsModified === true) {
                    apiRequest.handleSaveChanges(this,true, gridCompetences);
                } else if (isModified === true) {
                    apiRequest.handleSaveChanges(this,true, competences);
                }
        }  
    }

    //#EndLifecycle methods

    setParentSate = (props,value) => {
        this.setState({
            [props]: value
        });
    }

    setGridIsModified = (value) => {
        this.setState({
            gridIsModified: value
        });
    }

    //Setter methods
    setRatingIsActive = (value) => {
        if (value) {    
            this.handleRefreshRatingGrid();
        }
        this.setState({
            ratingIsActive:value
        });
    }

    //Helper methods
    handleRefreshRatingGrid = () => {
        this.setState({
            showRating: false
        }, () => {
                this.setState({
                    showRating: true
                })
        });
    }
    handleRefreshSlider = () => {
        this.setState({
            showSlider: false
        }, () => {
            this.setState({
                showSlider: true
            })
        });
    }
    //Deprecated
    setCompetences = (newArray) => {
        const { competences } = this.state;
        var array = []
        competences.map(group => {
            group.competenceSubGroup.map(item => {
                item.competences.map(competence => {
                    const tempArray = newArray.filter(newItem => newItem.id === competence.id);
                    if (tempArray.length > 0) {
                        competence.value = tempArray[0].value;
                    }
                })
                array.push(item);
            });

        });
        this.setState({
            competences: array,
            gridCompetences: JSON.parse(JSON.stringify(array))
        });
    }

    checkIsModified = () => {
        const { competences, baseCompetences } = this.state;

        var hashCompetences = '';
        var hashBaseCompetences = '';
        var competencesObject = [];
        var baseCompetencesObject = [];

        competences.map(group => group.competenceSubGroup.map(item => {
            item.competences.map(competence => {
                competencesObject.push({ id: competence.id, value: competence.value })
            });
        }));

        baseCompetences.map(group => group.competenceSubGroup.map(item => {
            item.competences.map(competence => {
                baseCompetencesObject.push({ id: competence.id, value: competence.value })
            });
        }));

        competencesObject.sort(this.compare).map((competence) => {
            hashCompetences = hashCompetences + competence.id;
            hashCompetences = hashCompetences + competence.value;
        });

        baseCompetencesObject.sort(this.compare).map((competence) => {
            hashBaseCompetences = hashBaseCompetences + competence.id;
            hashBaseCompetences = hashBaseCompetences + competence.value;
        });

        if (hashCompetences != hashBaseCompetences) {
            this.setState({
                isModified: true
            });
        }
        else {
            this.setState({
                isModified: false
            });
        }
    }

    //Event handling
    handleDoneModify = (array) => {
        const { competences } = this.state;

        this.setState({
            competences: array
        }, () => {
                this.handleRefreshRatingGrid();
                this.handleRefreshSlider();
                this.setRatingIsActive(false);
                apiRequest.handleSaveChanges(this,false, array);
        });
      
    }

    saveModifiedSlider = () => {
        const { competences } = this.state;

        apiRequest.handleSaveChanges(this,false, competences);
    }

    handleCancel = () => {
        this.setState({
            isModified: false,
            showSlider: false,
            showRating: false,
            competences: JSON.parse(JSON.stringify([...this.state.baseCompetences]))
        }, () => {
            this.setState({
                showSlider: true,
                showRating: true
            });
        });
    }


    handleAlertSave = (gridIsModified) => {
        const { history } = this.props;
        const { competences, gridCompetences } = this.state;
        
        let data = [];
        this.setState({
            open: false,
            isModified: false,
            gridIsModified: false,
        }, () => {

            const promise = apiRequest.handleSaveChanges(this,true, gridIsModified === false ? competences : gridCompetences);
            promise.then(() => {
                history.push(this.state.nextLocation.pathname);
            }).catch(error => { });

        });
    }

    handleMoveNext = () =>{
        const { history } = this.props
        const { nextLocation } = this.state;

        this.setState({
            open: false,
            isModified:false,
            gridIsModified: false,
        }, () => {
            history.push(nextLocation.pathname);
        });    
    }

    handleClose = () => {
        this.setState({
            open: false,
            nextLocation: null
        });
    }


    handleBlockedNavigation = (nextLocation) => {
        this.setState({
            open: true,
            nextLocation: nextLocation
        });
        return false
    }

    //Sort array
    compare(a, b) {
        const idA = a.id;
        const idB = b.id;

        let comparison = 0;
        if (idA > idB) {
            comparison = 1;
        } else if (idA < idB) {
            comparison = -1;
        }
        return comparison;
    }

    //Render method
    render() {
        const { competences, gridIsModified,loggedBy, autoSave, open, showSlider, loading, ratingIsActive, showRating, isModified } = this.state;
        const { classes, realIsActive } = this.props;

        return (loading === false && 
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={1} xs={false}/>
                    <Grid item md={10} xs={12}>
                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                            <Typography className={classes.title}>Önértékelés</Typography>
                            {!realIsActive && 
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>                               
                                </Tooltip>
                            }
                        </Box>                     
                        <Card>
                            <CardContent>
                                <Typography className={classes.typography}>
                                    Önértékelés esetében arra kérünk, hogy az alábbi, munkakörödre jellemző kompetenciákról gondolkodj el. Az általános kompetenciák adják az alapot, a kulcskompetenciák azok, melyek kiemelkedő fontossággal bírnak a munkavégzés módját illetően.
                                    <br></br>
                                    <br></br>
                                    <b>Kompetencia:</b> Egyéni képességek, készségek és munkához való hozzáállás összessége.
                                    <br></br>
                                    <br></br>
                                    Az alapján értékelj, hogy az adott kompetencia milyen mértékben jellemző Rád.Két módon tudod elvégezni az önértékelést:
                                    <br></br>
                                    <br></br>
                                    1. A kompetenciák melletti százalékszámokon módosítasz (csak egész szám megadása lehetséges).<br></br>
                                    2. A téglalapok méretét módosítva építed fel saját kompetencia kosaradat.
                                </Typography>
                            </CardContent>
                            <Divider />
                        </Card>
                        <React.Fragment>
                            {showSlider && competences.map(item => 
                                <GeneralSlider 
                                    key={item.id} 
                                    checkIsModified={this.checkIsModified} 
                                    disabled={ratingIsActive || loggedBy!=null && loggedBy.period.disabled} 
                                    setCompetences={this.setCompetences} 
                                    subTitle={item.label} 
                                    competences={item.competenceSubGroup} 
                                />                        
                            )}
                            {isModified && !ratingIsActive &&
                                <div style={{textAlign:'right',paddingTop:'0.5rem'}}>
                                    <ButtonGroup>
                                        <Button
                                            onClick={this.saveModifiedSlider}
                                            className={classes.doneButton}
                                            size="small"
                                            endIcon={<DoneIcon />}
                                        >
                                            Mentés
                                        </Button>
                                        <Button
                                            onClick={this.handleCancel}
                                            className={classes.cancelButton}  
                                            size="small"
                                            endIcon={<CloseIcon />}
                                        >
                                            Mégsem
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            }
                            <div className={classes.ratingGrid}>
                                <Grid container >
                                    <Grid item md={2} sm={2} xs={false}/>
                                    <Grid item md={8} sm={8} xs={12}>
                                        {showRating && 
                                            <GeneralRatingGrid
                                                disabled={loggedBy!=null && loggedBy.period.disabled} 
                                                setParentSate={this.setParentSate} 
                                                isModified={isModified} 
                                                setGridIsModified={this.setGridIsModified} 
                                                handleDoneModify={this.handleDoneModify} 
                                                showRating={showRating} 
                                                setRatingIsActive={this.setRatingIsActive} 
                                                isActive={ratingIsActive} 
                                                data={competences} 
                                            />
                                        }
                                    </Grid>
                                    <Grid item md={2} sm={2} xs={false}/>
                                </Grid>
                            </div>
                        </React.Fragment>
                    </Grid>
                    <Grid item md={1} xs={false}/>
                </Grid>
                <Prompt
                    when={autoSave === false && (isModified === true || gridIsModified===true)}
                    message={this.handleBlockedNavigation}
                />
                <SaveDialog 
                    open={open} 
                    onSave={()=>this.handleAlertSave(gridIsModified)} 
                    onMoveNext={this.handleMoveNext}
                    onClose={this.handleClose}              
                />
            </div>
        )
    }

}

export default withStyles(style)(SelfRating);


