//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetLeaders:'admin/masterData/organizations-list/leader',
    SaveEdit:'admin/masterData/organizations-list/leader/edit/save',
    Delete:'admin/masterData/organizations-list/leader/delete'
}

export const apiRequest = {
    populateLeaders,
    saveEdit,
    deleteRole
};

async function populateLeaders(parent,page) {

    const { organization } = parent.props;
    const model = {
        organization:organization.id,
        page
    };
    await new Api().call.post(Actions.GetLeaders,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                leaders:false
            },
            leaders: res.orgLeadersList,
            lastValidFromForAdd:res.lastValidFromForAdd,
            lastValidFromForEdit:res.lastValidFromForEdit,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function saveEdit(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.SaveEdit,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        parent.refresh();
    }).catch(handleError);
}

async function deleteRole(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}

