//React

import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core

import {
    Box,
    Typography,
} from '@material-ui/core';
//Components

import { 
    ActPeriod, 
    Competences, 
    Motivations, 
    NextPeriod,
    RatingsAsLeader 
} from './components';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && 
                <Box p={3}>
                    {children}
                </Box>
            }
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class TabPanels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lazyData: this.props.lazyData
        }
    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value != this.props.value) {
            this.setState({
                lazyData: null
            });
        }
    }
    //#EndLifecycle methods

    render() {
        const {lazyData} = this.state;
        const {value, titles, period, classes, snackbar} = this.props;

        return (
            <React.Fragment>
                {titles?.find(item=>item.key === "Competences") && 
                    <TabPanel value={value} index={titles?.find(item=>item.key ==="Competences")?.index}>
                        <Competences period={period} lazyData={lazyData} snackbar={snackbar} />
                    </TabPanel>
                }
                {titles?.find(item=>item.key ==="RatingsAsLeader") && 
                    <TabPanel value={value} index={titles?.find(item=>item.key ==="RatingsAsLeader")?.index}>
                        <Typography className={classes.subTitle}>{titles?.find(item=>item.key ==="RatingsAsLeader")?.title}</Typography>
                        <RatingsAsLeader period={period} snackbar={snackbar} />
                    </TabPanel>
                }
                {titles?.find(item=>item.key ==="PrevGoals") && 
                    <TabPanel value={value} index={titles?.find(item=>item.key ==="PrevGoals")?.index}>
                        <Typography className={classes.subTitle}>{titles?.find(item=>item.key ==="PrevGoals")?.title}</Typography>
                        <ActPeriod period={period} snackbar={snackbar} />
                    </TabPanel>
                }
                {titles?.find(item=>item.key ==="NewGoals") && 
                    <TabPanel value={value} index={titles?.find(item=>item.key ==="NewGoals")?.index}>
                        <Typography className={classes.subTitle}>{titles?.find(item=>item.key ==="NewGoals")?.title}</Typography>
                        <NextPeriod period={period} snackbar={snackbar} />
                    </TabPanel> 
                }  
                {titles?.find(item=>item.key ==="Motivations") && 
                    <TabPanel value={value} index={titles?.find(item=>item.key ==="Motivations")?.index}>
                        <Typography className={classes.subTitle}>{titles?.find(item=>item.key ==="Motivations")?.title}</Typography>
                        <Motivations period={period} snackbar={snackbar} />
                    </TabPanel> 
                }  
            </React.Fragment>
        )       
    }
}

export default withStyles(style)(TabPanels);
