import {Api,handleResponse,handleError} from '../../api';
import {Constants,UserStore} from '../../stores';

const Actions = {
    GetMenuPoints:'gui/menu-points',
    GetAdminMenuPoints:'gui/admin/menu-points',
    GetLoginByMenuPoints:'gui/admin/login-by/menu-points',
    IsActive: 'gui/isActive',
}

export const apiRequest = {
    populateMenuPoints,
    populateAdminMenuPoints,
    populateLoginByMenupoints,
    isActive
};

//#Api calls
async function populateMenuPoints(setPages,setPagesLoading) {
        await new Api().call.get(Actions.GetMenuPoints)
        .then(handleResponse)
        .then(res => {
            setPagesLoading(false);
            setPages(res);
        }).catch(handleError);
  
}


async function populateAdminMenuPoints(setPages,setPagesLoading) {
    await new Api().call.get(Actions.GetAdminMenuPoints)
    .then(handleResponse)
    .then(res => {
        setPagesLoading(false);
        setPages(res);
    }).catch(handleError);

}

async function populateLoginByMenupoints(setPages,setPagesLoading) {
    const loggedBy = UserStore.getLoggedByAdminValue();
    await new Api().call.get(`${Actions.GetLoginByMenuPoints}/${loggedBy.period.id}/${loggedBy.user.userId}`)
    .then(handleResponse)
    .then(res => {
        setPagesLoading(false);
        setPages(res);
    }).catch(handleError);

}

async function isActive(setStepIsActive,setStepIsActiveLoading,pathname) {
    const loggedBy = UserStore.getLoggedByAdminValue();

    var model = {location: pathname };
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}}
    }
        await new Api().call.post(`${Actions.IsActive}`, model)
        .then(handleResponse)
        .then(res => {
            setStepIsActiveLoading(false);
            setStepIsActive(res);
        }).catch(handleError);

}
