//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetPeriods:'admin/matrix/filters/periods',
    GetRoles:'admin/matrix/filters/roles',
    Delete:'admin/matrix/filters/roles/delete'
}

export const apiRequest = {
    populatePeriods,
    populateRoles,
    deleteRoles
};

async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
           periods:res
        }));
    }).catch(handleError);
}

async function populateRoles(parent,page,period) {
    const {filter} = parent.props;
    const model = {
        filter:filter.id,
        period:period.id,
        page,
    };
    parent.setState(prevState => ({
        loading: {
            ...prevState.loading,
            roles:true
        }
    }));
    await new Api().call.post(Actions.GetRoles,model)
    .then(handleResponse)
    .then(res => {
        parent.setState(prevState => ({
            loading: {
                ...prevState.loading,
                roles:false
            },
            page:1,
            roles: res.filterRolesList,
            total: res.total,
            currentPage: res.currentPage,
            step: res.step,
            pages: res.pages
        }));
    }).catch(handleError);
}

async function deleteRoles(parent,model) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.refresh();
    }).catch(handleError);
}

