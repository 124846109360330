
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    getGroups: 'exit/auto-complete/email-groups',
    getNotes:'exit/get-note',
    getLeader:'exit/leader',
    sendNotification:'exit/send-notification',
    getOrder:'exit/email-groups/order'
}

export const apiRequest = {
    populateGroups,
    populateOrder,
    populateNote,
    populateLeader,
    send
};

async function populateOrder(setOrder,options) {
    await new Api().call.post(Actions.getOrder,options)
    .then(handleResponse)
    .then(res => {
        setOrder(res);
    }).catch(handleError);      
}

async function populateNote(rowId,setNote) {
    await new Api().call.get(`${Actions.getNotes}/${rowId}`)
    .then(handleResponse)
    .then(res => {
        setNote(res.note);
    }).catch(handleError);      
}

async function populateLeader(userId,setLeader) {
    await new Api().call.get(`${Actions.getLeader}/${userId}`)
    .then(handleResponse)
    .then(res => {
        setLeader(res);
    }).catch(handleError);      
}

async function populateGroups(setOptions) {

        await new Api().call.get(Actions.getGroups)
        .then(handleResponse)
        .then(res => {
            setOptions(res);
        }).catch(handleError);      
}


async function send(model,onClose) {

    //Info message snack
    NotificationBar('Értesítés kiküldése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.sendNotification,model)
    .then(handleResponse)
    .then(res => {
        onClose();
    }).catch(handleError);      
}

