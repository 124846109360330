import theme from 'theme';

const useStyle = ({
    title: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize: '0.9rem'

    },
    subContent: {
        fontFamily: 'Nunito',
    },
    leaderBio: {
        fontFamily: 'Nunito',
        paddingBottom: '0.4rem',
        fontSize: '0.9rem'
    },
    leaderName: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize:'1.0rem'

    },
    avatar: {
        height: 56,
        width: 56
    }
});
export default useStyle
