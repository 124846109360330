import React, { Component } from 'react';
//Core
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    Typography,
    Zoom,
    Chip,
    ButtonGroup,
    Button
} from '@material-ui/core';
//Components
import { SetRating } from './components';
//Icons
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style
import style from './style';

function Ratings(props) {
    const { item, classes, datasAreNotValidToRemove, datasAreNotValidToAdd, isActive, handleChange } = props;
    const StyledTooltip = style.StyledTooltip;
    return (<Card className={classes.card}>
        <CardHeader className={classes.headerTitle} title={item.label}>
        </CardHeader>
        <Divider />
        <CardContent >
            {item.competences.map(competence => (
                <Grid
                    container
                    key={competence.id}
                    spacing={2}
                >
                    <Grid
                        item
                        md={8}
                        sm={8}
                        xs={12}
                    >
                        <StyledTooltip TransitionComponent={Zoom} title={competence.description}>
                            <Typography 
                                style={{ color: competence.color }} 
                                className={classes.compName}
                            >
                                {competence.label}
                            </Typography>
                        </StyledTooltip>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        sm={4}
                        xs={12}
                    >
                        <SetRating 
                            datasAreNotValidToRemove={datasAreNotValidToRemove} 
                            datasAreNotValidToAdd={datasAreNotValidToAdd} 
                            isActive={isActive} 
                            groupId={item.id} 
                            handleChange={handleChange} 
                            defValue={competence.value} 
                            compId={competence.id} 
                        />
                    </Grid>
                </Grid>
            ))}
        </CardContent>
        <Divider />
    </Card>)
}



export default function GeneralRatingGrid(props) {
    const { data, handleDoneModify, isActive,disabled, setRatingIsActive, isModified, setParentSate } = props;
    const classes = style.useStyles();
    const [datasAreNotValid, setDatasAreNotValid] = React.useState([]);
    const [sum, setSum] = React.useState(data.map(item => { return item.sumFromValues }));
    const [backUpData, setBackUpData] = React.useState(JSON.parse(JSON.stringify(data)));
    const [baseData, setBaseData] = React.useState(data);
    const [valid, setValid] = React.useState(true);


    const datasAreNotValidToAdd = (value) => {
        var array = datasAreNotValid;
        if (!array.includes(value)) {
            array.push(value);
        }
            setValid(false)
        setDatasAreNotValid(array);
    }
    const datasAreNotValidToRemove = (value) => {
        var array = datasAreNotValid;
        var removeIndex = null;
        array.map((item,index) => {
            if (item === value) {
                removeIndex = index;
            }
        })
        if (removeIndex != null) {
            array.splice(removeIndex, 1);
        }
        if (array.length < 1) {
            setValid(true)
        }
      
        setDatasAreNotValid(array);
    }

    const handleChange = (groupId, compId, newValue) => {
        var sumArray = [];
        var isValid = true;
        backUpData.map((group, index) => {
            var sum = 0;
            group.competenceSubGroup.map(item =>
                item.competences.map(competence => {
                    if (competence.id === compId) {
                        competence.value = newValue
                    }
                    sum = sum + competence.value;
                    //setSum(sum[index] + competence.value);
                })
            )
            if (sum != group.selfRatingSum) {
                isValid = false;
            }
            sumArray.push(sum);
        });

        setSum(sumArray);
        if (isValid) {
            setParentSate("gridCompetences", JSON.parse(JSON.stringify(backUpData)));
            setParentSate("gridIsModified", true);
            setValid(true);
        }
        else {
            setParentSate("gridIsModified", false);
            setValid(false);
        }
    }

    const handleDoneModifyClick = () => {
        setRatingIsActive(true);
    }

    const handleBack = () => {
        setBaseData(data);
        setParentSate("gridIsModified", false);
        setBackUpData(JSON.parse(JSON.stringify(data)));
        setRatingIsActive(false);
        setSum(data.map(item => { return item.sumFromValues }));

    }
    return (
        <React.Fragment>
            <div className={classes.buttonDiv}>
                <div style={{textAlign:'right'}}>
                {!isActive && !isModified && !disabled &&
                    <Button
                    onClick={handleDoneModifyClick}
                    className={classes.editButton}
                    size="small"
                    endIcon={<EditIcon />}
                    >
                        Módosítás
                    </Button>
                }
                {isActive &&               
                        <ButtonGroup>
                            <Button
                                onClick={() => handleDoneModify(backUpData)}
                                className={classes.doneButton}
                                disabled={!valid}
                                size="small"
                                endIcon={<DoneIcon />}
                            >
                                Mentés
                            </Button>
                            <Button
                                onClick={handleBack}
                                className={classes.cancelButton}  
                                size="small"
                                endIcon={<CloseIcon />}
                            >
                                Mégsem
                            </Button>
                        </ButtonGroup>               
                }
                </div>
           </div>     
            <Grid container spacing={2}>
                {baseData.length < 2 &&
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={false}
                        sm={false}
                        xs={false} />}
                {!isActive ? baseData.map((item, index) =>
                    <React.Fragment key={item.id}>
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            {item.competenceSubGroup.map((item, index) => (
                                <Ratings 
                                    key={item.id} 
                                    item={item} 
                                    classes={classes} 
                                    datasAreNotValidToRemove={datasAreNotValidToRemove} 
                                    datasAreNotValidToAdd={datasAreNotValidToAdd} 
                                    isActive={isActive} 
                                    handleChange={handleChange} 
                                />))
                            }
                            <Card className={classes.card}>
                                <CardHeader 
                                    className={classes.headerTitle} 
                                    title={
                                            <div>Összesítve:
                                                <Chip 
                                                    className={sum[index] !== item.selfRatingSum ? classes.chipNotOk : classes.chipOk} 
                                                    label={`${sum[index]}%`} />
                                            </div>
                                           } 
                                    action={""}
                                />
                                <Divider />
                            </Card>
                        </Grid>
                    </React.Fragment>
                ) :
                    backUpData.map((item, index) =>
                        <React.Fragment key={item.id}>
                           
                        <Grid
                            key={item.id}
                            item
                            xl={6}
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            {item.competenceSubGroup.map((item, index) => (
                                <Ratings 
                                    key={item.id} 
                                    item={item} 
                                    classes={classes} 
                                    datasAreNotValidToRemove={datasAreNotValidToRemove} 
                                    datasAreNotValidToAdd={datasAreNotValidToAdd} 
                                    isActive={isActive} 
                                    handleChange={handleChange} 
                                />))
                            }
                            <Card className={classes.card}>
                                <CardHeader 
                                    className={classes.headerTitle} 
                                    title={
                                            <div>Összesítve:
                                                <Chip 
                                                    className={sum[index] !== item.selfRatingSum ? classes.chipNotOk : classes.chipOk} 
                                                    label={`${sum[index]}%`} />
                                             </div>
                                           } 
                                    action={""}>
                                </CardHeader>
                                <Divider />
                            </Card>
                            </Grid> 
                        </React.Fragment>)}
                {baseData.length < 2 &&
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        md={false}
                        sm={false}
                        xs={false} />}
            </Grid>
            </React.Fragment>)
       
}
