//React

import React,{ Component } from 'react';
import clsx from 'clsx';
//Components

import {
  ConfirmDialog,
  NewDialog
} from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Api

import { apiRequest } from './apiRequest';
//Core

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  LinearProgress,
  InputLabel
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function Row(props) {
  const {main, inputs, edit, onEdit, onAddNew, onSave, onCancel, onDelete, deleted, inputTypes, mainIndex, onChange, periods, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.goalPointsMainGroup.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.goalPointsMainGroup.id}
        </TableCell>
        <TableCell align="center">
        <TextField
            name="label"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"label")}
            disabled={(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
            value={(main.goalPointsMainGroup.label===null || main.goalPointsMainGroup.label===undefined) ? '' : main.goalPointsMainGroup.label}
          />       
        </TableCell>
      
        <TableCell align="center">
          <TextField
            name="allowQuantity"
            type="number"
            onChange={(event)=>onChange(event,mainIndex,null,"allowQuantity",true)}
            disabled={(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
            value={(main.goalPointsMainGroup.allowQuantity===null || main.goalPointsMainGroup.allowQuantity===undefined) ? '' : main.goalPointsMainGroup.allowQuantity}
          />
        </TableCell>
        <TableCell align="center">
         <Select
            disabled={(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
            name="isWeighted"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"isWeighted")}
            value={(main.goalPointsMainGroup.isWeighted===null || main.goalPointsMainGroup.isWeighted===undefined) ? '' : main.goalPointsMainGroup.isWeighted}
          >
              <MenuItem  value={true}>Igen</MenuItem>
              <MenuItem  value={false}>Nem</MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          <TextField
            name="weightSum"
            type="number"
            onChange={(event)=>onChange(event,mainIndex,null,"weightSum",true)}
            disabled={(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
            value={(main.goalPointsMainGroup.weightSum===null || main.goalPointsMainGroup.weightSum===undefined) ? '' : main.goalPointsMainGroup.weightSum}
          />
        </TableCell>
        <TableCell align="center">
          <Select
              disabled={(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
              name="canSkipResult"
              fullWidth
              onChange={(event)=>onChange(event,mainIndex,null,"canSkipResult")}
              value={(main.goalPointsMainGroup.canSkipResult===null || main.goalPointsMainGroup.canSkipResult===undefined) ? '' : main.goalPointsMainGroup.canSkipResult}
            >
              <MenuItem  value={true}>Igen</MenuItem>
              <MenuItem  value={false}>Nem</MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validFrom")}
            value={main.goalPointsMainGroup.validFrom}
          >
            {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,mainIndex,null,"validTo")}
            value={main.goalPointsMainGroup.validTo}
          >
             {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        {(edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id)) && <React.Fragment>
        <Tooltip title="Módosítás">
          <span>
            <IconButton 
              onClick={()=>onEdit(main.goalPointsMainGroup)} 
              className={classes.editIcon}
              disabled={main.goalPointsMainGroup.disabled || edit!==null || period===null || period.disabled===true}>
              <EditIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main.goalPointsMainGroup)}   
              className={classes.deleteButton}
              disabled={main.disabled || edit!==null || period===null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
        </Tooltip></React.Fragment>
        }
        {(edit != null && edit.id == main.goalPointsMainGroup.id) &&
          <React.Fragment> 
            <Tooltip title="Mentés">
              <span>
                <IconButton  
                  onClick={()=>onSave(mainIndex)} 
                  className={classes.saveButton} 
                  aria-label="save"
                >
                  <DoneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Mégsem">
              <IconButton 
                onClick={onCancel} 
                className={classes.cancelButton} 
                aria-label="cancel"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> 
          </React.Fragment> 
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Megnevezés</TableCell>
                    <TableCell padding="none" align="center">Bemenet</TableCell>
                    <TableCell padding="none" align="center">Bemeneti értékek</TableCell>
                    <TableCell padding="none" align="center">Mértékegység</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.goalPointsSubGroups.map((sub,subIndex) => (
                    <TableRow className={(deleted!==null && deleted.id==sub.id) ? classes.deleted : ''} key={sub.id}>
                      <TableCell padding="checkbox" align="left" component="th" scope="row">
                        {sub.id}
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                          {sub.parentId}
                      </TableCell>
                      <TableCell align="center">
                          <TextField
                            fullWidth
                            name="label"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"label")}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
                            value={(sub.label===null || sub.label===undefined) ? '' : sub.label}
                          />
                      </TableCell>
                      <TableCell align="center">
                          <Select
                              disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
                              name="input"
                              fullWidth
                              onChange={(event)=>onChange(event,mainIndex,subIndex,"input")}
                              value={(sub.input===null || sub.input===undefined) ? '' : sub.input}
                            >
                              {inputTypes.map((item,index)=>(
                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                              ))}
                           </Select>
                      </TableCell>   
                      <TableCell align="center">
                          <Select
                              disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
                              name="inputMatrix"
                              fullWidth
                              onChange={(event)=>onChange(event,mainIndex,subIndex,"inputMatrix")}
                              value={(sub.inputMatrix===null || sub.inputMatrix===undefined) ? '' : sub.inputMatrix}
                            >
                              {inputs.map((item,index)=>(
                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                              ))}
                          </Select>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                            fullWidth
                            name="unit"
                            onChange={(event)=>onChange(event,mainIndex,subIndex,"unit")}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))}
                            value={(sub.unit===null || sub.unit===undefined) ? '' : sub.unit}
                          />
                      </TableCell>                                      
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validFrom"
                          fullWidth
                          onChange={(event)=>onChange(event,mainIndex,subIndex,"validFrom")}
                          value={sub.validFrom}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validTo"
                          fullWidth
                          onChange={(event)=>onChange(event,mainIndex,subIndex,"validTo")}
                          value={sub.validTo}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Törlés">
                          <span>
                            <IconButton
                              onClick={()=>onDelete(sub)}   
                              className={classes.deleteButton}
                              disabled={main.disabled || edit!==null || period===null || period.disabled===true}>
                              <DeleteIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell padding="none" colSpan={10}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új pont létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton
                                onClick={()=>onAddNew(1,main.goalPointsMainGroup.id)}  
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != main.goalPointsMainGroup.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


class InputPointsTree extends Component {

    constructor(props){
        super(props);
        this.state={
            inputPoints:[],
            inputs:[],
            inputTypes:[],
            periods:[],
            period:'',
            edit:null,
            deleted:null,
            loading:null,
            newPoint:null,
            parentId:null,
        };
    }

    componentDidMount(){
        apiRequest.populateInputs(this);
        apiRequest.populateInputTypes(this);
        apiRequest.populatePeriods(this);
    }

    componentDidUpdate(prevProps,prevState){
      const {period} = this.state;

      if(prevState.period!=period){
        this.setState({
          loading:true
        });

        apiRequest.populateInputPoints(this,period.id);
      }
    }

    handleEdit = (row) =>{
      this.setState({
        edit:{ ...row }
      });
    }

    handleCancel = () =>{
      const {period} = this.state;

      apiRequest.populateInputPoints(this,period.id);

      this.setState({
          edit:null,
          loading:true,
      });
    }

    handleNewPoint = (level,parentId) =>{
      this.setState({
        newPoint:level,
        parentId:parentId
      });
    }

    afterAddedNewPoint = () =>{
      const {period} = this.state;

      this.setState({
        newPoint:null,
        parentId:null,
        loading:true,
        edit:null
      });

      apiRequest.populateInputPoints(this,period.id)
    }

    handleChange = (event,mainIdex,subIndex,props,isNumber) => {
      let value = event.target.value;
      const inputPoints = [...this.state.inputPoints];
      if(isNumber){
        value=value*1;
      }
      if(subIndex===null){
        inputPoints[mainIdex].goalPointsMainGroup[props] = value;
      }else{
        inputPoints[mainIdex].goalPointsSubGroups[subIndex][props] = value;
      }
      
      this.setState({ inputPoints });
    }

    handleSave = (mainIdex) =>{
        const {inputPoints,period} = this.state;

        const model = {...inputPoints[mainIdex],period:period.id};
        
        apiRequest.saveChanges(this,model,period.id);        
    }

    handlePeriodChange = (event) =>{
      const value = event.target.value;
      this.setState({
        period:value
      });
    }

    handleDelete = (row) =>{
      this.setState({
        deleted:row
      });
    }

    deleteRow = () =>{
      const {deleted,period} = this.state;

      const model = {id:deleted.id,period:period.id};
      apiRequest.deletePoint(this,model,period.id);
    }

    render(){
        const {inputPoints, inputs, inputTypes, edit, periods, period, deleted, parentId, newPoint, loading} = this.state;
        const {classes} = this.props;
        return (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="period-select-label">Periódusok</InputLabel>
                  <Select
                    labelId="period-select-label"
                    id="period-select"
                    value={period}
                    onChange={this.handlePeriodChange}
                  >
                    {periods.map(period=>
                      <MenuItem key={period.id} value={period}>
                        {period.label}
                      </MenuItem>)
                    }
                  </Select>
              </FormControl>
              {period!=='' && 
                <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                    <Typography style={{ fontWeight: 'bold' }}>Új csoport létrehozása: </Typography>
                    <Tooltip title="Új hozzáadás">
                        <span>
                            <IconButton
                              onClick={()=>this.handleNewPoint(0)} 
                              disabled={edit != null || period.disabled ? true : false}
                              className={classes.addButton} 
                              aria-label="add"
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
              }
              <TableContainer component={Paper}>
                {loading && <LinearProgress />}
                <PerfectScrollbar>
                  <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow className={classes.head}>
                            <TableCell />
                            <TableCell padding="checkbox" align="left">Azonositó</TableCell>
                            <TableCell padding="none" align="center">Megnevezés</TableCell>
                            <TableCell padding="none" align="center">Max darabszám</TableCell>
                            <TableCell padding="none" align="center">Súlyozott</TableCell>
                            <TableCell padding="none" align="center">Súly összeg</TableCell>
                            <TableCell padding="none" align="center">Kihagyható</TableCell>
                            <TableCell padding="none" align="center">Érvényes-től</TableCell>
                            <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                            <TableCell padding="none" align="center">Műveletek</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inputPoints.map((main,index) => (
                          <Row 
                            key={index}
                            mainIndex={index} 
                            main={main} 
                            inputs={inputs}
                            inputTypes={inputTypes}
                            deleted={deleted} 
                            edit={edit}
                            period={(period==='' || period===undefined || period===null) ? null : period}
                            periods={periods}
                            onChange={this.handleChange} 
                            onDelete={this.handleDelete}
                            onAddNew={this.handleNewPoint}
                            onEdit={this.handleEdit}
                            onSave={this.handleSave}
                            onCancel={this.handleCancel}
                          />))
                        }
                        {(period==='' || period===undefined || period===null) ?
                          <TableRow>
                              <TableCell align="center" colSpan="10">
                                Nincs kválasztva periódus
                              </TableCell>
                          </TableRow>
                          :  inputPoints.length < 1 && 
                          <TableRow>
                            <TableCell align="center" colSpan="10">
                              {loading ? 'Betöltés...' : 'Nincs találat'}
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                  </Table>
                </PerfectScrollbar>
              </TableContainer>             
              <ConfirmDialog 
                onClose={()=>this.handleDelete(null)} 
                onDelete={this.deleteRow} 
                open={deleted!==null}
              />
              {newPoint!=null && 
                <NewDialog
                  parentId={parentId}
                  refresh={this.afterAddedNewPoint}
                  period={period}
                  inputTypes={inputTypes}
                  inputs={inputs}
                  onClose={()=>this.handleNewPoint(null)} 
                  level={newPoint}
                  open={newPoint!=null}
                />
              }
            </div>
        );
    }
}
export default withStyles(style.useStyles)(InputPointsTree)