import theme from 'theme';

const useStyles = ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    conversationIsDone:{
        color:theme.palette.success.main
    },
    conversationIsNotDone:{
        color:theme.palette.error.main
    },
    icons:{
        color:theme.palette.primary.main
    },
    period: {
        padding: theme.spacing(2),
        fontWeight: 'bold',
        fontSize: '1.6rem'
    }
});

export default useStyles;