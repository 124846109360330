import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    formControl: {
        '& .MuiIconButton-root': {
            padding:'2px'
        }
    },
    disabledPlusButton: {
        color: theme.palette.gray
    },
    plusButton: {
        color: theme.palette.success.main
    },
    disabledminusButton: {
        color: theme.palette.gray
    },
    minusButton: {
        color: theme.palette.error.main
    }
}));
