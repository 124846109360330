//React

import React,{ Component } from 'react';
//Pickers
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    DialogTitle,
    Divider,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
    FormHelperText,
    Tooltip,
    IconButton,
    InputAdornment
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/styles';


const DateTimePicker = props => {
    const {value, disabled, id, name, error, required, label, minDate, maxDate, errorClassName, readOnly, onChange, helperText} = props;
    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider className={classes.root} locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                error={error}
                helperText={helperText}
                clearable={true}
                clearLabel="Törlés"
                emptyLabel="Nincs megadva"
                id={id}
                fullWidth
                name={name}
                required={required}
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                minDateMessage="Nem megengedett érték"
                maxDate={maxDate}
                maxDateMessage="Nem megengedett érték"
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider >
    )
  }


class ConfirmDialog extends Component {

    constructor(props){

        super(props);
        this.state={
            validFrom:'',
            validTo:'',
            role:'',
            label:'',
            name:'',
            minDate:null,
            valid: false
        };

        apiRequest.getMinDate(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {role,validFrom,validTo,name,label} = this.state;

        if (prevState.role != role || prevState.validFrom!= validFrom || prevState.validTo!= validTo
            || prevState.name!= name || prevState.label!= label) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
      
        this.setState({
            [name]:value
        });
    }

    handleDateChange = (date,name) =>{

        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        this.setState({ 
            [name]:newDate 
        });
    }

    handleSave = () =>{
        const {validFrom,validTo,role,label,name} = this.state;
        const {period,step,level} = this.props;

        if(level>0){
            const model ={step,
                period:period.id,
                role:role.id,
                openedTime:validFrom ==='' ? null : validFrom,
                closedTime:validTo==='' ? null : validTo
            }
                apiRequest.saveChanges(this,model);
        }else if(level==0){
            const model ={step,
                label,
                name,
                openedTime:validFrom ==='' ? null : validFrom,
                closedTime:validTo==='' ? null : validTo
            }
                apiRequest.savePeriodChanges(this,model);
        }

    }
    
    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }

    render(){
        const {open, onClose, classes, roles, level} = this.props;
        const {role, valid, validFrom, validTo, name, label, minDate} = this.state;

        return(
            <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
    >
        <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                {level===0 ? 'Új periódus hozzáadása' : 'Új időszak létrehozása'}
                </Typography>
            </div>
        </DialogTitle>
        <Divider/>
        <DialogContent id="add-new-form">
        {level > 0 &&
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} item>
                    <FormControl 
                        fullWidth 
                        required 
                        error={!this.validateFields(role)}  
                        className={classes.formControl}
                    >
                    <InputLabel id="input-select-label">
                           Jogosultságok
                    </InputLabel>
                        <Select
                            name="role"
                            labelId="role-select-label"
                            id="role-select"
                            value={role}
                            onChange={this.handleChange}
                        >
                            {roles.map(item=>
                            <MenuItem key={item.id} value={item}>
                                {item.label}
                            </MenuItem>)}
                        </Select>
                        {!this.validateFields(role) && 
                        <FormHelperText>Kötelező megadni</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={6}  item>
                    <DateTimePicker
                        label="Kezdet dátum"
                        required={true}
                        value={validFrom==='' ? null : validFrom}
                        error={!this.validateFields(validFrom) || new Date(validFrom)>new Date(validTo)}
                        helperText={(!this.validateFields(validFrom) && 'Kötelező megadni') || ((this.validateFields(validFrom) &&  this.validateFields(validTo) && new Date(validFrom)>new Date(validTo)) && 'Nem megengedett érték')}  
                        onChange={(date)=>this.handleDateChange(date,'validFrom')}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6}  item>
                    <DateTimePicker
                        minDate={validFrom}
                        error={!this.validateFields(validTo)  || new Date(validTo)<new Date(validFrom)}
                        helperText={(!this.validateFields(validTo) && 'Kötelező megadni') || ((this.validateFields(validFrom) &&  this.validateFields(validTo) && new Date(validTo)<new Date(validFrom)) && 'Nem megengedett érték')} 
                        label="Vég dátum"
                        required={true}
                        value={validTo==='' ? null : validTo}
                        onChange={(date)=>this.handleDateChange(date,'validTo')}
                    />
                </Grid>
            </Grid>
        }
        {level === 0 && 
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} item>
                    <TextField
                        required
                        error={!this.validateFields(name)}
                        helperText={!this.validateFields(name) && 'Kötelező megadni'}
                        label="Megnevezés"
                        fullWidth
                        value={name}
                        name="name"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} item>
                <TextField
                        required
                        error={!this.validateFields(label)}
                        helperText={!this.validateFields(label) && 'Kötelező megadni'}
                        label="Cím"
                        fullWidth
                        value={label}
                        name="label"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} item>
                    <DateTimePicker
                        label="Kezdet dátum"
                        required={true}
                        minDate={minDate}
                        value={validFrom==='' ? null : validFrom}
                        error={!this.validateFields(validFrom) || new Date(validFrom) > new Date(validTo)}
                        helperText={(!this.validateFields(validFrom) && 'Kötelező megadni') || ((this.validateFields(validFrom) &&  this.validateFields(validTo) && new Date(validFrom)>new Date(validTo)) && 'Nem megengedett érték')}  
                        onChange={(date)=>this.handleDateChange(date,'validFrom')}
                    />
                </Grid>  
                <Grid xs={12} sm={12} md={6} item>
                    <DateTimePicker
                        minDate={validFrom}
                        error={!this.validateFields(validTo)  || new Date(validTo)<new Date(validFrom)}
                        helperText={(!this.validateFields(validTo) && 'Kötelező megadni') || ((this.validateFields(validFrom) &&  this.validateFields(validTo) && new Date(validTo)<new Date(validFrom)) && 'Nem megengedett érték')}  
                        label="Vég dátum"
                        required={true}
                        value={validTo==='' ? null : validTo}
                        onChange={(date)=>this.handleDateChange(date,'validTo')}
                    />
                </Grid>                           
            </Grid>
        }           
        </DialogContent>
        <DialogActions>
            <div>
                <Tooltip title="Mentés">
                <span>
                    <IconButton 
                        disabled={!valid} 
                        onClick={this.handleSave} 
                        className={classes.doneButton} 
                        aria-label="done"
                    >
                        <DoneIcon />
                    </IconButton>
                </span>
                </Tooltip>
                <Tooltip title="Mégsem">
                    <span>
                        <IconButton 
                            className={classes.backButton} 
                            onClick={onClose}  
                            aria-label="back"
                        >
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>                  
            </DialogActions>
        </Dialog>
        );
    }
}
export default withStyles(style.useStyles)(ConfirmDialog);
