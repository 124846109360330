import { makeStyles } from '@material-ui/styles';

export default  makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '75%',
        width: 560
    },
    contentTitle: {
        fontFamily:'Nunito'
    },
    contentSubTitle: {
        fontFamily: 'Nunito'
    },
}));
