//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    NotificationBar,
    Progress
} from 'components';
//Actions

const Actions = {
    GetUserRoles: 'admin/user/user-roles',
    SaveEdit: 'admin/user/user-roles/edit/save',
    Delete: 'admin/user/user-roles/delete'
}

export const apiRequest = {
    populateUserRoles,
    saveEdit,
    deleteRole
};

async function populateUserRoles(parent, page) {
    const { user } = parent.props;

    const model = {
        user: user.id,
        page
    };

    await new Api().call.post(Actions.GetUserRoles, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    userRoles: false
                },
                userRoles: res.userRolesList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(handleError);
}

async function saveEdit(parent, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.SaveEdit, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                edit: null
            });
            parent.refresh();
        }).catch(handleError);
}

async function deleteRole(parent, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.Delete, model)
        .then(handleResponse)
        .then(res => {
            parent.refresh();
        }).catch(handleError);
}

