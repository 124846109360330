//React

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Pickers

import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import huLocale from "date-fns/locale/hu";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//Core

import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import TodayIcon from '@material-ui/icons/Today';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
//Style

import style from './style';


const DateTimePicker = props => {
    const {value, disabled, id, name, label, minDate, maxDate, errorClassName, error, readOnly, onChange, helperText} = props;

    const classes = style.useStylesForPicker();

    return (
        <MuiPickersUtilsProvider  locale={huLocale} utils={DateFnsUtils} >
            <DatePicker
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <TodayIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                clearable={true}
                clearLabel="Törlés"
                emptyLabel="Nincs megadva"
                id={id}
                name={name}
                inputVariant="outlined"
                className={classes.picker}
                cancelLabel="Vissza"
                openTo="date"
                label={label}
                margin="dense"
                error={error}
                helperText={helperText}
                readOnly={readOnly}
                disabled={disabled}
                format="yyyy MMMM dd"
                minDate={minDate}
                maxDate={maxDate}
                minDateMessage="Nem megengedett érték"
                views={["year", "month", "date"]}
                autoOk
                value={(value !== null && value !== undefined) ? value : null}
                onChange={date => onChange(date)}
            />
        </MuiPickersUtilsProvider >
    )
}



const PositionsTable = props => {
    const {className, onOpen, positions, isModifable, approved, lastValidFrom, onValidFromChange, onDone, onDelete, onEdit, onBack, onSelect, ...rest} = props;

    const classes = style.useStyles();

    const [editRow,setEditRow] = React.useState(null);

    //Depricated
    const checkCanAddNew = () => {
        if (positions.filter(item => item.disabled === false).length > 0) {
            return false;
        }
        if (positions.filter(item => item.validTo === null || item.validTo === undefined || item.validTo === 'null').length > 0) {
            return false;
        }
        return true;
    }

    const checkCanSave = (position) => {
        if (position.validFrom == null || position.validFrom == undefined) {
            return false;
        }
        if (position.validTo !== null && position.validTo !== undefined && (new Date(position.validTo) >= new Date(position.validFrom))) {
            return true;
        }
        if ((position.validTo === null || position.validTo === undefined) && (position.validFrom !== null && position.validFrom !== undefined)) {
            return true;
        }
        return false;
    }

    function handleEdit(user){
        setEditRow(user);
    }


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
      <div className={classes.inner}>
          {positions.map((position,index) => (
            <div key={index}>
            <Grid  container spacing={2}>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                    <TextField
                        margin="dense"                      
                        label="Munkakör"
                        variant="outlined"
                        readOnly
                        value={position.bio}
                    />
                  
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                    <TextField
                        readOnly
                        margin="dense"
                        label="Szervezeti egység"
                        variant="outlined"
                        value={position.organization}
                    />                 
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                     <DateTimePicker
                        label="Érvényes -től"
                        minDate={lastValidFrom}
                        helperText={(position.validFrom !== undefined && position.validFrom !== null) ? '' : 'Kötelező kitölteni'}
                        error={(position.validFrom !== undefined && position.validFrom !== null) ? false : true}
                        onChange={(date) => onValidFromChange(position, date)}
                        disabled={!(editRow==position) || position.isClosed===true}
                        value={position.validFrom} 
                    />                                 
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                   <DateTimePicker
                        label="Érvényes -ig"
                        disabled={true}
                        minDate={(new Date(position.validFrom) > new Date(lastValidFrom)) ? position.validFrom : lastValidFrom}
                        value={position.validTo}
                    />            
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                    {(editRow==null || editRow.rowId!==position.rowId) && 
                    <React.Fragment>
                       
                        <Tooltip title="Munkakör törlése">
                            <span>
                                <IconButton 
                                    disabled={position.isClosed || position.disabled}   
                                    onClick={() => onDelete(position)} 
                                    className={classes.deleteButton} 
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Adatok módosítása">
                            <span>
                                <IconButton
                                    disabled={position.disabled || position.isClosed}   
                                    onClick={() => handleEdit(position)}  
                                    className={classes.editButton}
                                    aria-label="edit"
                                >
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </React.Fragment>}
                    {(editRow!==null && editRow.rowId==position.rowId) &&  <React.Fragment>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!checkCanSave(position)} 
                                    className={classes.doneButton} 
                                    onClick={() => onDone(position)} 
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={() => handleEdit(null)} 
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </React.Fragment>}
                </Grid>                           
            </Grid>
            <Divider/>
            </div>))}
        </div>
          </CardContent>
          <CardActions>
              <div style={{ display: 'flex', alignItems:'center', marginLeft: 'auto', }}>
              <Typography style={{fontWeight:'bold'}}>Új munkakör hozzáadása: </Typography>
              <Tooltip title="Új hozzáadás">
                      <span>
                          <IconButton 
                            onClick={onOpen} 
                            className={classes.addButton} 
                            aria-label="add"
                        >
                          <AddIcon />
                      </IconButton>
                  </span>
              </Tooltip>
              </div>
          </CardActions>
    </Card>
  );
};

PositionsTable.propTypes = {
  className: PropTypes.string,
  positions: PropTypes.array.isRequired
};

export default PositionsTable;
