//React

import React from 'react';
//Components

import { 
    NotificationBar,
    Progress 
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';

//Actions
const Actions = {
    GetOrganizations: 'admin/masterData/organizations-list',
    SaveChanges: 'admin/masterData/organizations-list/save',
    GetFilterOrganizations: 'admin/masterData/filter/organizations',
    GetFilterCompanies: 'admin/masterData/filter/companies',
    ValidateOrganizations :'admin/masterData/organizations-list/validate'
}

export const apiRequest = {
    populateOrganizations,
    saveChanges,
    populateFilterOrganizations,
    populateFilterCompanies,
    validateOrganizations
};

//#Api calls
async function populateOrganizations(parent,page) {
    const { filter } = parent.state;
    const model = {
        filteredOrganizations: filter.organizations,
        filteredCompanies: filter.companies,
        active: filter.active !== null ? filter.active.id : filter.active,
        page
    };
        await new Api().call.post(Actions.GetOrganizations, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                },
                organizations: res.organizationsList,
                total: res.total,
                currentPage: res.currentPage,
                step: res.step,
                pages: res.pages
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    organizations: false
                }
            }));
        });
}

async function populateFilterOrganizations(parent) {

        await new Api().call.get(Actions.GetFilterOrganizations)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterOrganizations: false
                },
                filterOrganizations: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterOrganizations: false
                }
            }));
        })
}

async function populateFilterCompanies(parent) {

        await new Api().call.get(Actions.GetFilterCompanies)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterCompanies: false
                },
                filterCompanies: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: {
                    ...prevState.loading,
                    filterCompanies: false
                }
            }));
        });
}


async function saveChanges(parent, model) {

    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.SaveChanges, model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                edit:null,
            }));
        }).catch(handleError);
}

async function validateOrganizations(parent,page, model) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.ValidateOrganizations, model)
        .then(handleResponse)
        .then(res => {
            populateOrganizations(parent,page);
        }).catch(handleError);
}

