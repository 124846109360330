
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    getHrs: 'exit/auto-complete/hrs',
    getData:'exit/exit-form',
    getLeaders:'exit/auto-complete/leaders',
    saveNotes:'exit/save-exit-form',
    sendNotes:'exit/send-exit-form',
    download:'exit/exit-form/download'
}

export const apiRequest = {
    populateHrs,
    populateLeaders,
    save,
    send,
    fetchForm,
    download
};

async function fetchForm(id,setForm,setLoading) {

    await new Api().call.get(`${Actions.getData}/${id}`)
    .then(handleResponse)
    .then(res => {
        setForm(res);
        setLoading(false);
    }).catch(handleError);      
}

async function populateHrs(setHrs) {

        await new Api().call.get(Actions.getHrs)
        .then(handleResponse)
        .then(res => {
            setHrs(res);
        }).catch(handleError);      
}

async function populateLeaders(setOptions) {

    await new Api().call.get(Actions.getLeaders)
    .then(handleResponse)
    .then(res => {
        setOptions(res);
    }).catch(handleError);      
}

async function save(model) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.saveNotes,model)
    .then(handleResponse)
    .then(res => {
    }).catch(handleError);      
}

async function send(model,setLeaders) {

    //Info message snack
    NotificationBar('Interjú kiküldése', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.sendNotes,model)
    .then(handleResponse)
    .then(res => {
        
        setLeaders([]);
    }).catch(handleError);      
}

async function download(selected,setDownload) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.download,selected)
    .then(handleResponse)
    .then(res => {
        var sampleArr = base64ToArrayBuffer(res.model.file);
        var blob = new Blob([sampleArr], {type: "application/octet-stream"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = res.model.fileName;
        link.download = fileName+res.model.fileType;
        link.click();
        setDownload(false);
    }).catch(handleError);   
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

