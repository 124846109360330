//React

import React from 'react';
//Core

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  Typography,
  FormControlLabel,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  IconButton,
  CircularProgress,
  Table
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Helpers

import { enums } from 'stores';
//Styles

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import GetAppIcon from '@material-ui/icons/GetApp';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
import { UserSelector } from 'components';
//Api

import { apiRequest } from './apiRequest';


const inputTypes = enums.inputTypesInString;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.appMain.main
  },
  title: {
    fontFamily: 'nunito',
    color: 'white',
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    color: 'white'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function FormDialog(props) {
  const { onClose, open, selected, readOnly } = props;
  const classes = style();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [download, setDownload] = React.useState(false);

  React.useEffect(() => {
    apiRequest.populateDatas(setData, setLoading, selected.id);
  }, [])


  function handleChangeInArray(event, checked, mainPoint, subPoint, point, valuesIndex, id) {
    const value = event.target.value;
    var newData = { ...data };
    const mainIndex = newData.mainPoints.indexOf(mainPoint);
    const subPointIndex = newData.mainPoints[mainIndex].subPoints.indexOf(subPoint);
    const pointIndex = newData.mainPoints[mainIndex].subPoints[subPointIndex].points.indexOf(point);
    if (checked) {
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values[valuesIndex].value = 'true';
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values.map((item, index) => {
        if (item.type === inputTypes.CHECKBOX && index !== valuesIndex) {
          item.value = 'false';
        }
      });
    } else {
      newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values[valuesIndex].value = 'false';
    }

    setData(newData);
  }


  function handleChange(event, checked) {
    const name = event.currentTarget.name;
    const value = checked;
    var newData = { ...data };
    if (name.includes('eLearning')) {
      if (name.includes("_true")) {
        if (value) {
          newData.eLearning = true
        } else {
          newData.eLearning = null;
        }
      } else if (name.includes("_false")) {
        if (value) {
          newData.eLearning = false
        } else {
          newData.eLearning = null;
        }
      }

    } else if (name.includes('workWithUser')) {
      if (name.includes("_true")) {
        if (value) {
          newData.workWithUser = true
        } else {
          newData.workWithUser = null;
        }
      } else if (name.includes("_false")) {
        if (value) {
          newData.workWithUser = false
        } else {
          newData.workWithUser = null;
        }
      }
    }
    setData(newData);
  }

  function handleText(event) {
    const name = event.target.name;
    const value = event.target.value;
    var newData = { ...data };
    newData[name] = value;
    setData(newData);
  }

  function handleTextInArray(event, mainPoint, subPoint, valuesIndex, point) {
    const value = event.target.value;
    var newData = { ...data };
    const mainIndex = newData.mainPoints.indexOf(mainPoint);
    const subPointIndex = newData.mainPoints[mainIndex].subPoints.indexOf(subPoint);
    const pointIndex = newData.mainPoints[mainIndex].subPoints[subPointIndex].points.indexOf(point);

    newData.mainPoints[mainIndex].subPoints[subPointIndex].points[pointIndex].values[valuesIndex].value = value;

    setData(newData);
  }

  function handleSave() {
    apiRequest.save(data, selected);
  }

  function handleDownload() {
    setDownload(true);
    var save = apiRequest.save(data, selected);
    save.then(res => {
      apiRequest.download(selected, setDownload);
    }).catch(error => { });
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title" onClose={onClose}>Próbaidő értékelő beszélgetés - 1. hónap</DialogTitle>
        <PerfectScrollbar>
          <DialogContent>
            <UserSelector data={[selected]} value={0}>
            </UserSelector>
            {loading === true && <div style={{ textAlign: 'center', padding: '1.0rem' }}><CircularProgress color="primary" /></div>}
            {loading === false && <div>
              {data.mainPoints.map((item, index) =>
                <div key={item.label} >
                  <Typography className={classes.title}>{item.label}</Typography>
                  {item.subPoints.map(subPoint =>
                    <Paper>
                      <TableContainer>
                        <PerfectScrollbar>
                          <Table key={subPoint.label}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography className={classes.subTitle}>{subPoint.label}</Typography>
                                </TableCell>
                                {subPoint.values.map(row =>
                                  <TableCell key={row.label} align="center">
                                    <Typography className={classes.value}>{row.label}</Typography>
                                  </TableCell>)}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {subPoint.points.map(point => <TableRow key={point.label}>
                                <TableCell align="left" padding="none">
                                  <Typography className={classes.point}>{point.label}</Typography>
                                </TableCell>
                                {subPoint.values.map((row, index) =>
                                  <TableCell key={row.id} width={row.type === inputTypes.CHECKBOX ? '15%' : '30%'} align="center" padding="none">
                                    {row.type === inputTypes.CHECKBOX &&
                                      <Checkbox
                                        disabled={readOnly}
                                        checked={point.values[index].value === "true"}
                                        onChange={(event, checked) => handleChangeInArray(event, checked, item, subPoint, point, index, row.id)}
                                        name="checkedB"
                                        color="primary"
                                      />
                                    }
                                    {row.type === inputTypes.TEXTFIELD &&
                                      <TextField
                                        onChange={(event) => handleTextInArray(event, item, subPoint, index, point)}
                                        value={(point.values[index].value == null || point.values[index].value == undefined) ? '' : point.values[index].value}
                                        InputProps={{
                                          readOnly: readOnly,
                                        }}
                                        fullWidth
                                      />
                                    }
                                  </TableCell>)}
                              </TableRow>)}
                            </TableBody>
                          </Table> </PerfectScrollbar>  </TableContainer> </Paper>
                  )}
                </div>
              )}
              <Typography className={classes.footer}>A próbaidő értékelő beszélgetés megtörtént.</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.footer}>A dolgozó további foglalkoztatását </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={readOnly}
                      checked={data.workWithUser === true}
                      onChange={handleChange}
                      name="workWithUser_true"
                      color="primary"
                    />
                  }
                  label="javaslom"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={readOnly}
                      checked={data.workWithUser === false}
                      onChange={handleChange}
                      name="workWithUser_false"
                      color="primary"
                    />
                  }
                  label="nem javaslom"
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.footer}>Az e-learning rendszerben található képzésekre felhívtam a figyelmet</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={readOnly}
                      checked={data.eLearning === true}
                      onChange={handleChange}
                      name="eLearning_true"
                      color="primary"
                    />
                  }
                  label="igen"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={readOnly}
                      checked={data.eLearning === false}
                      onChange={handleChange}
                      name="eLearning_false"
                      color="primary"
                    />
                  }
                  label="nem"
                />
              </div>
              <TextField
                InputProps={{
                  readOnly: readOnly,
                }}
                variant="outlined"
                fullWidth
                onChange={handleText}
                name="note"
                value={(data.note === null || data.note === null) ? '' : data.note}
                multiline
                rows={3}
                label="Megjegyzések"
              />
            </div>}
          </DialogContent>
        </PerfectScrollbar>
        <DialogActions>
          <Button disabled={loading || download} endIcon={<GetAppIcon />} className={classes.mainButton} size="small" onClick={handleDownload} variant="outlined">
            Letöltés
          </Button>
          <Button disabled={loading || download} endIcon={<BackspaceIcon />} className={classes.secondaryButton} size="small" onClick={onClose} variant="outlined" color="secondary">
            Vissza
          </Button>
          <Button disabled={readOnly || loading || download} endIcon={<SaveIcon />} className={classes.greenButton} size="small" onClick={handleSave} variant="outlined">
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;