import React, { Component } from 'react';
//Core
import {AppBar,Typography} from '@material-ui/core';
//Components
import Content from './Content';
import { SnackbarProvider } from 'notistack';
//Styles
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "video-react/dist/video-react.css";

class App extends Component {

    render() {
      return (           
          <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Content />               
              </SnackbarProvider>
          </ThemeProvider>
    );
  }
}
export default App;
