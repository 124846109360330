
//Api

import {Api,handleResponse,handleError} from 'api';
//Helpers

import { UserStore } from 'stores';

const Actions = {
    AddNewUser: 'matrix/add-new',
    GetMaxPercent: 'matrix/max-percent',
    IsOverTheLimit: 'matrix/is-over-the-limit'
}

export const apiRequest = {
    getMaxPercent,
    addNew,
    checkIsOverTheLimit
};

async function getMaxPercent(classId,matrixId,setMaxPercent) {
    var model = {matrixId,classId};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetMaxPercent,model)
    .then(handleResponse)
    .then(res => {
        setMaxPercent(res);
    }).catch(handleError);          
}

async function addNew(model,handleEvaluators) {
     var _model = {...model};

     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
        _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

    await new Api().call.post(Actions.AddNewUser,_model)
    .then(handleResponse)
    .then(res => {
        handleEvaluators(res.reviewers);
    }).catch(handleError);   
}

async function checkIsOverTheLimit(user,setIsOverTheLimit,setActiveStep,setUser,setOverTheLimitUser,setOpen,classification,selectedMatrix,setMaxPercent){
    var model = {userId:user.userId};

    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
       model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.IsOverTheLimit,model)
    .then(handleResponse)
    .then(res => {
        setIsOverTheLimit(res);
        if (res===false) {
            if (classification != null) {
                setActiveStep(2);
                getMaxPercent(classification.id,selectedMatrix,setMaxPercent);
            }
            else {
                setActiveStep(1);
            }
            setUser(user); 
        } else {
            setOverTheLimitUser(user);
            setOpen(true);
        }
    }).catch(handleError);
}