import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    avatar: {
        width: '100px',
        height: '100px',
        pointerEvents: 'auto'
    }
}));
export default useStyles;
