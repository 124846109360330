
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetUsers: 'rating/persons/secondary',
    GetCompetences: 'rating/competences/secondary',
    GetRatingPoints: 'rating/secondary/rating-points',
    Save:'rating/competences/save',
    SaveAdmin:'rating/competences/admin/save',
}

export const apiRequest = {
    populateUsers,
    populateCompetences,
    populateRatingPoints,
    handleSaveChanges,
};

async function populateUsers(parent) {


    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
        await new Api().call.post(Actions.GetUsers,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                users: res,
                loading: {
                    ...prevState.loading,
                    users: false
                }
            }));
            if (res.length < 1) {
                parent.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        competences: false,
                        ratingPoints: false
                    }
                }));
            }
        }).catch(handleError);

}

async function populateCompetences(parent,userId) {

    var model = {userId};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetCompetences,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    competences: res,
                    baseCompetences: JSON.parse(JSON.stringify(res)),
                },
                loading: {
                    ...prevState.loading,
                    competences: false
                }
            }));
        }).catch(handleError);

}

async function populateRatingPoints(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetRatingPoints,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                lazyData: {
                    ...prevState.lazyData,
                    ratingPoints: res
                },
                loading: {
                    ...prevState.loading,
                    ratingPoints: false
                }
            }));
        }).catch(handleError);

}

async function handleSaveChanges(parent,closed) {
    
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    const { competences, users, value } = parent.state;
    var model = null;
    const postObjectArray = [];
    competences.map(comp => comp.competenceSubGroup.map(group => {
        group.competences.map(competence => {
            postObjectArray.push({ id: competence.id, value: competence.value, matrixId: comp.matrixId, compToPosId: competence.compToPosId })
        });
    }));

    var model = {minimalizedCompetences: postObjectArray,userId: users[value].id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.Save,model)
        .then(handleResponse)
        .then(res => {
            if (!closed) {
                parent.setState({
                    isModified: false,
                    competences: null
                });
            }
        }).catch(error => {
             handleError(error);
             parent.setState({
                isModified: true
             });
        });
    
}
