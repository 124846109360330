//React

import React from 'react';
//Components

import {
    NotificationBar,
    Progress
} from 'components';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Actions

const Actions = {
    Save: 'admin/user/user-roles/add-new/save',
    GetRoles: 'admin/user/user-roles/add-new/roles'
}

export const apiRequest = {
    saveChanges,
    getRoles
};

//#Api calls

async function getRoles(parent) {

    await new Api().call.get(Actions.GetRoles)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                roles: res,
                loading: {
                    ...prevState.loading,
                    roles: false
                }
            }));
        }).catch(error => {
            handleError(error);
        });
}

async function saveChanges(parent, model) {
    const { onClose, refresh } = parent.props;
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            refresh();
            onClose();
        }).catch(handleError);
}

