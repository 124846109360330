
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Helpers

import { UserStore } from 'stores';

//Actions
const Actions = {
    GetLeaderRatingPoints: 'ratingForm/leader-rating/user',
    GetExtendedLeaderRatingPoints: 'ratingForm/leader-rating/extended'
}

export const apiRequest = {
    populateLeaderRatingPoints,
    populateExtendedLeaderRatingPoints
};

async function populateLeaderRatingPoints(parent,userId) {
    const { period } = parent.props;

    var model = {userId, period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetLeaderRatingPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            leaderRatingPoints: res,
            loading: false,
        })
    }).catch(handleError);
}

async function populateExtendedLeaderRatingPoints(parent,userId) {
    const { period } = parent.props;

    var model = {userId, period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetExtendedLeaderRatingPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            leaderRatingPoints: res,
            loading: false,
        })
    }).catch(handleError);
}