//React

import React, { Component } from 'react';
//Core

import {
    Grid,
    Drawer,
    AppBar,
    Toolbar,
    IconButton
} from '@material-ui/core';
//Components

import { 
    UserRolesPagination, 
    UserRolesTable, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class UserRoles extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            userRoles: [],
            loading: {
                userRoles: true,
                users: true,
                roles: true
            },
            filter: {
                users: [],
                roles: []
            },
            selectedFilter: {
                users: [],
                roles: []
            },
            open: false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit: null,
        }

    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateUserRoles(this, this.state.page);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (organizations) => {
        this.setState({
            selectedOrganizations: organizations
        });
    }

    handleChange = (date, index, props) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        }
        const userRoles = [...this.state.userRoles];
        userRoles[index][props] = newDate;
        this.setState({ userRoles });
    }

    handleEdit = (role) => {
        this.setState({
            edit: { ...role }
        });
    }

    handleSave = (index) => {
        const { userRoles } = this.state;

        apiRequest.saveEdit(this, userRoles[index]);
    }

    handleDelete = (index) => {
        const { userRoles } = this.state;

        apiRequest.deleteRole(this, userRoles[index]);
    }

    handleCancel = (index) => {
        const userRoles = [...this.state.userRoles];
        userRoles[index].validFrom = this.state.edit.validFrom;
        userRoles[index].validTo = this.state.edit.validTo;
        this.setState({
            userRoles,
            edit: null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                userRoles: true
            },
            selectedFilter: {
                ...prevState.selectedFilter,
                [props]: value
            }
        }), () => apiRequest.populateUserRoles(this, 1));
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                userRoles: true
            }
        }));
        apiRequest.populateUserRoles(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () => {
        const { page } = this.state;
        
        apiRequest.populateUserRoles(this, page);
    }

    render() {
        const { open, userRoles, currentPage, step, total, pages, edit, loading } = this.state;
        const { classes, snackbar, onClose, user } = this.props;
        return (
            <Drawer anchor={'bottom'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container className={classes.root}>
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        item
                    >
                        <div className={classes.content}>
                            <UserRolesTable
                                loading={loading.userRoles}
                                onOpen={this.handleOpen}
                                onDelete={this.handleDelete}
                                onCancel={this.handleCancel}
                                edit={edit}
                                onEdit={this.handleEdit}
                                onSave={this.handleSave}
                                onChange={this.handleChange}
                                onSelect={this.handleSelectCompanies}
                                userRoles={userRoles}
                            />
                        </div>
                        {(loading.userRoles === false && edit == null) &&
                            <UserRolesPagination
                                onPageChanges={this.handlePageChanges}
                                currentPage={currentPage}
                                step={step}
                                total={total}
                                pages={pages}
                            />
                        }
                    </Grid>
                </Grid>
                {open == true &&
                    <AddNewDialog
                        user={user}
                        refresh={this.refresh}
                        snackbar={snackbar}
                        onClose={this.handleClose}
                        open={open}
                    />
                }
            </Drawer>
        );
    }

};

export default withStyles(style)(UserRoles);
