import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        width:'100%',
        '& .MuiInputBase-root': {
            fontSize: '1.0rem',  
        },
        '& .MuiSelect-select': {
            '&:focus': {
                backgroundColor: theme.palette.white,
            },
        },
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            borderColor: theme.palette.appMain.main,
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
    menuItem: {
        fontFamily: 'Nunito'
    }
}));
