import theme from 'theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    tutorial: {
        paddingBottom:'1.0rem'
    },
    mainButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            color:theme.palette.appMain.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.appMain.light}`
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary.light,
          color:theme.palette.secondary.dark
       },
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
});

const useStylesForPicker = makeStyles(theme => ({
    root: {
    },
    picker: {
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.8rem'
        }
    }
    
}));

export default {
    useStyles: useStyles,
    useStylesForPicker: useStylesForPicker
};

