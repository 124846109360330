//React

import React, { Component } from 'react';
//Core

import {
    Typography,
    Grid
} from '@material-ui/core';
//Helpers

import {Constants} from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/core/styles';
//Components

import {MuiOutlinedSelect} from 'components';
import {Ratings} from '../../index';


class RatingPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            loading:{
                types:true
            },
            selected:{
                type:null
            },
            types:[]
        }
        Constants.setcontentLoading(true);

    }

    getRatings(){
        const {selected} = this.state;
        const pathname = selected.type;
        if(pathname!==null && pathname!==undefined){
            return <Ratings location={{pathname}}/>
        }    
    }


    //*Lifecycle methods*//
    componentDidMount() {
        apiRequest.populateTypes(this);
    }

    componentDidUpdate(prevProps,prevState){
        const {loading,selected} = this.state;
        if(loading!=prevState.loading){
            if(loading.types===false){
                Constants.setcontentLoading(false);
            }
        }
        if(selected.type != prevState.selected.type){

        }
    }
    //*Lifecycle methods*//

    handleTypeChange = (event) =>{
        const value = event.target.value;
        this.setState(prevState=>({
            selected:{
                ...prevState.selected,
                type:value
            }
        }));
    }

    render() {
        const {types, loading, selected} = this.state;
        const {classes} = this.props;

        return (loading.types === false && 
            <div className={classes.root} >
                <Grid container spacing={4}>
                    <Grid item md={1} xs={12}/>
                    <Grid item md={10} xs={12}>
                        <Typography className={classes.title}>Értékelőlap</Typography>
                        <Grid container spacing={2}>
                            <Grid item xl={3} lg={3} md={4} sm={8} xs={8}>
                                <MuiOutlinedSelect
                                    margin="dense" 
                                    disabledItemLabel="Még nem elérhető" 
                                    disabled={(types !== null && types !== undefined && types.length > 0) ? false : true} 
                                    error={(types !== null && types !== undefined && types.length > 0) ? false : true} 
                                    helperText={(types !== null && types !== undefined && types.length > 0) ? '' : "Nem található értékelőlap típus"} 
                                    className={classes.select} 
                                    menuList={types} 
                                    handleChange={this.handleTypeChange} 
                                    value={selected.type !== null ? selected.type : 0} 
                                    label="Típusok" 
                                />                                          
                            </Grid>                      
                            <Grid item xl={3} lg={3} md={4} sm={8} xs={8}/>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={12}/>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                        {selected.type=="/form-rating/leader" && <Ratings selected={selected} location={{pathname:selected.type}}/>}
                        {selected.type=="/form-rating/main" && <Ratings selected={selected} location={{pathname:selected.type}}/>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(style)(RatingPage);
