import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

const useStyles = ({
    headerTitle: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.0rem',
            lineHeight: '1.6'
        },
    },
    content: {
        padding: 0,
        '& .MuiTypography-root': {
            fontFamily: 'Nunito',
        },
        '& .MuiListItemText-primary': {
            fontSize: '1.0rem',
            fontWeight:'bold'
        },
        '& .MuiListItemText-secondary': {
            fontSize: '0.8rem',
        }
    },
    clearIcon: {
        color:theme.palette.error.light
    },
    doneIcon: {
        color: theme.palette.success.light
    },
    headerIcon: {
        color: theme.palette.appMain.main
    },
    actions: {
        justifyContent: 'flex-end'
    }
});
export default useStyles;
