import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    typography: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
        lineHeight: '1.3'
    },
    slider: {
        display: 'flex'
    },
    ratingGrid: {
        paddingTop: theme.spacing(2),
        '& .MuiCardHeader-root': {
            padding: '4px'

        }
    },
    doneButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    },
    lockIcon: {
        color: theme.palette.warning.main
    },
    cancelButton: {
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            color:theme.palette.error.dark,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.error.dark}`
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary.light,
            color:theme.palette.secondary.dark
        }
    }
});
export default useStyles;
