//React

import React, { Component } from 'react';
//Core

import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    CircularProgress,
    Typography
} from '@material-ui/core';
//Components

import Autocomplete from '@material-ui/lab/Autocomplete';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.getUsers(this);

        this.state = {
            user: [],
            users: [],
            valid:false,
            loading:{
                users:true
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {user} = this.state;

        if (prevState.user != user) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onSelectChange = (props, value) => {
        this.setState({
            [props]: value
        });
    }

    handleSave = () => {
        const {user } = this.state;
        const {filter,period} = this.props;
        const model = { users:user.map(item=>{return item.userId}),filter:filter.id,period:period.id};
        apiRequest.saveChanges(this,model);
    }

    validation = () => {
        var doc = document.getElementById("add-new-form");     
        if (doc !== null && doc !== undefined) {
            if (!(doc.querySelectorAll(".Mui-error").length>1)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const {onClose, open, classes} = this.props;
        const {user, users, loading, valid} = this.state;
        return (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                            Új felhasználó hozzáadása
                        </Typography>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                        <Grid spacing={2} container>                      
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-user"
                                    multiple
                                    name="user"
                                    value={user}
                                    onChange={(event, value) => this.onSelectChange("user", value)}
                                    options={users}
                                    getOptionLabel={(option) => option.fullName}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            error={(user === null || user==='' || user.length<1) ? true : false}
                                            helperText={(user === null || user==='' || user.length<1) ? "Kötelező választani" : ''}
                                            label="Felhasználók"
                                            InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading.users ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                                }}
                                            />
                                        )}
                                    />
                            </Grid>                                                
                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!valid} 
                                    onClick={this.handleSave} 
                                    className={classes.doneButton} 
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(style.useStyles)(AddNewDialog);
