
import {Api,handleResponse,handleError} from 'api';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetCompetences: 'ratingForm/self/competences',
    GetResult: 'ratingForm/result/competences'
}

export const apiRequest = {
    populateCompetences,
    populateResult
};

    //Populate datas
    async function populateCompetences(parent) {
        const { period } = parent.props;
        
        var model = {period};
        const loggedBy = UserStore.getLoggedByAdminValue();
        if(loggedBy!=null){
            model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
        }

        await new Api().call.post(Actions.GetCompetences,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                competences: res,
                loading: {
                    ...prevState.loading,
                    competences: false
                }
            }));
        }).catch(handleError);
    }

    //Populate datas
    async function populateResult(parent) {
    const { period } = parent.props;
    
    var model = {period};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetResult,model)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                result: res.competencesMainGroups,
                avg:res.avg,
                loading: {
                    ...prevState.loading,
                    result: false
                }
            }));
        }).catch(handleError);

    }