//React

import React, { Component } from 'react';
import { Prompt } from 'react-router';
//Core

import {
    Grid,
    Typography,
    MenuItem,
    IconButton,
    Tooltip,
    Avatar,
    ListItemAvatar,
    Divider,
    Menu,
    Box,
    Icon
} from '@material-ui/core';
//Icons

import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//Components

import { 
    UserSelector, 
    SaveDialog
} from 'components';
import { 
    TabPanels, 
    AlertDialog 
} from './components';
//Api

import {apiRequest} from './apiRequest';
//Helpers

import {
    UserStore,
    Constants
} from 'stores';
//Style

import style from './style';
import theme from 'theme';
import { withStyles } from '@material-ui/core/styles';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

class Matrix extends Component {

    constructor(props) {
        super(props);

        Constants.setcontentLoading(true);
        this.state = {
            value: 0,
            users: [],
            loading: {
                users: true,
                lazyData:true
            },
            dialog: {
                skip: false,
                alert: false,
                save:false
            },
            autoSave: UserStore.getUserValue().autoSave,
            anchorIndex: null,
            userSelectable: true,
            open: false,
            anchorEl: null,
            lazyData: null,
            isValid: false,
            isModified: false,
            nextLocation: null,
            nextUser: null,
            evaluators: null,
            selectedMatrix:null

        }
    }

    componentDidMount(){
        apiRequest.populateUsers(this);
    }

     //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const {loading,users } = this.state;
        
        if (prevState.loading != loading) {
            if (loading.lazyData === false && loading.users === false) {
                Constants.setcontentLoading(false);
            }
        }
        if (prevState.loading.users != loading.users) {
            if (users.length > 0) {
                apiRequest.populateEvaluators(this);
            }
            else {
                this.setState(prevState => ({
                    loading: {
                        ...prevState.loading,
                        lazyData: false
                    }
                }));
            }
        }
    }
    //#EndLifecycle methods

    setParentSate = (props, value) => {
        this.setState({
            [props]:value
        });
    }

    setAnchorIndex = (value) => {
        this.setState({
            anchorIndex:value
        })
    }

    handleChange = (event, newValue) => {
        const {isModified, autoSave, isValid} = this.state;
        const classList = event.target.parentElement.classList.value.toUpperCase();

        if (classList.includes('AVATAR')) {
            if (autoSave == true) {
                if (isModified == true && isValid == false) {
                    this.setState(prevState => ({
                        dialog: {
                            ...prevState.dialog,
                            alert: true
                        },
                        nextUser: newValue
                    }));
                } else {
                        this.setState({
                            value: newValue,
                            isModified: false,
                            nextUser: null,
                            nextLocation: null
                        })
                    }
            } else {
                if (isModified == true) {
                    if (isValid == false) {
                        this.setState(prevState => ({
                            dialog: {
                                ...prevState.dialog,
                                alert: true
                            },
                            nextUser: newValue
                        }));
                    } else {
                        this.setState(prevState => ({
                            dialog: {
                                ...prevState.dialog,
                                save: true
                            },
                            nextUser: newValue
                        }));
                    }

                } else {
                        this.setState({
                            value: newValue,
                            isModified: false,
                            nextUser: null,
                            nextLocation: null
                        })                   
                }
            }
        }
    };

    handleClick = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            open: !prevState.open,
            anchorEl: target
        }));
    };

    //Handle close dialogs
    handleOpen = (prop) => {
        this.setState(prevState=>({
            dialog: {
                ...prevState.dialog,
                [prop]: true
            }
        }));

    }
    //Handle open dialogs
    handleClose = (prop) => {
        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                [prop]: false
            }
        }));

    }

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        });
    };

    setUserSelectable = (value) => {
        this.setState({ userSelectable: value })
    }

    handleAlertSave = () => {
        const {history} = this.props;
        const {nextLocation, users, value, nextUser} = this.state;

        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                save: false
            },
            isModified: false
        }), () => {
                const promise = apiRequest.saveAction(this,users[value].id);
            promise.then(() => {
                if (nextLocation != null) {
                    history.push(nextLocation.pathname);
                } else {
                    this.setState({
                        value: nextUser
                    });
                }

            }).catch(error => { });
        });
    }

    handleMoveNext = () =>{
        const {history} = this.props;
        const {nextLocation, nextUser} = this.state;

        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                save: false
            },
            isModified: false
        }), () => {
            if (nextLocation != null) {
                history.push(nextLocation.pathname);
            } else {
                this.setState({
                    value: nextUser
                });
            }
        });   
    }

    handleBlockedNavigation = (nextLocation) => {
        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                alert: true
            }
        }));
        return false
    }
    handleBlockedNavigationSave = (nextLocation) => {
        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                save: true
            },
            nextLocation: nextLocation,
            nextUser: null
        }));
        return false
    }

    render() {
        const { snackbar, classes, realIsActive } = this.props;
        const { value, autoSave,isModified, isValid, loading, users, dialog, lazyData, userSelectable, anchorEl } = this.state;
        //If you add a new element to the array increment the key prop
        const actions = [() => this.handleOpen('skip')];
        return (
            (loading.lazyData === false && loading.users === false) && 
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box display="flex" gridGap="0.5rem" alignItems="center">
                            <Typography className={classes.title}>
                                Értékelőmátrix kitöltése
                            </Typography>   
                            {!realIsActive && 
                                <Tooltip title="Az időszak nem aktív, csak az adminisztrátoroknak elérhető">
                                    <Icon className={classes.lockIcon}>
                                        <LockOutlinedIcon />
                                    </Icon>                               
                                </Tooltip>
                            } 
                        </Box>                                      
                    </Grid>
                    <Grid item xl={1} lg={1} md={false} sm={false} xs={false}/>
                    <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                    {users.length > 0 ? <React.Fragment>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography className={classes.subTitle}>
                                Értékelendő személyek
                            </Typography>
                            {users.filter(item => item.disabled === true).length > 0 && 
                                <Tooltip title="Nem értékelendő személyek">
                                    <IconButton onClick={this.handleClick} className={classes.disabledUsersIcon}>
                                        <PersonAddDisabledIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        <UserSelector 
                            disabled={!userSelectable} 
                            onSetAnchorIndex={this.setAnchorIndex} 
                            actions={actions} 
                            data={users.filter(item => item.disabled === false)} 
                            value={value} 
                            onChange={this.handleChange}
                        >
                        {lazyData !== null && 
                            <TabPanels 
                                setParentSate={this.setParentSate} 
                                setUserSelectable={this.setUserSelectable} 
                                snackbar={snackbar} 
                                value={value} 
                                lazyData={lazyData} 
                                index={value} 
                                userId={users.filter(item=>item.disabled===false)[value].id} 
                            />
                        }
                        </UserSelector>
                        </React.Fragment> : 
                            <div style={{ textAlign: 'center', padding: theme.spacing(4) }}>
                                <b>Nem tartozik hozzád beosztott</b>
                            </div>
                        }
                    </Grid>
                    <Grid item xl={1} lg={1} md={false} sm={false} xs={false}/>
                </Grid>              
                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                >               
                    <MenuItem> 
                        <Typography className={classes.menuHeader} variant="inherit">
                            Nem értékelendő személyek
                        </Typography>
                    </MenuItem>
                    <Divider/>
                    {users.filter(item => item.disabled === true).map((item, index) => (
                        <MenuItem key={item.id}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="Person"
                                    className={classes.avatar}
                                    src={(item.avatar !== null && item.avatar !== undefined) ? item.avatar : ''}
                                />
                            </ListItemAvatar>
                            <Typography variant="inherit">{item.fullName}</Typography>
                        </MenuItem>
                    ))}                       
                </StyledMenu>
                <Prompt
                    when={(isModified === true && isValid===false)}
                    message={this.handleBlockedNavigation}
                />
                <Prompt
                    when={(autoSave===false && isModified === true && isValid === true)}
                    message={this.handleBlockedNavigationSave}
                />
                <AlertDialog open={dialog.alert} onClose={() => this.handleClose("alert")} />
                <SaveDialog 
                    open={dialog.save} 
                    onSave={this.handleAlertSave} 
                    onMoveNext={this.handleMoveNext}
                    onClose={() => this.handleClose("save")} 
                />
            </div>
        )
    }
}

export default withStyles(style)(Matrix)
