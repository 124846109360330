//React

import React, { Component } from 'react';
//Core

import {
    Grid, 
    Button, 
    IconButton, 
    Fade, 
    Paper, 
    Typography, 
    Popper,
    Drawer,
    AppBar,
    Toolbar 
} from '@material-ui/core';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';
//Components

import { 
    AccountProfile, 
    ActiveEvaluator, 
    EvaluatorsTable, 
    AddNewDialog 
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Icons
import CloseIcon from '@material-ui/icons/Close';


class EditUser extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(true);
        apiRequest.getUser(this);
        apiRequest.getEvaluator(this);      

        this.state = {
            user: null,
            evaluator: null,
            evaluators: [],
            isModifable: false,
            approved: false,
            anchorEl: null,
            warning:false,
            lastValidFromForAdd: null,
            lastValidFromForEdit: null,
            baseEvaluators:[],
            loading: {
                user: true,
                evaluator: true
            },
            addNew:false,
        }
    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        if (prevState.loading != loading) {
            if (loading.user === false && loading.evaluator === false) {
                Constants.setcontentLoading(false);
            }
        }
    }
    //#EndLifecycle methods

    handleOpenWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            anchorEl: target,
            warning: !prevState.warning
        }))
    };

    handleCloseWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            anchorEl: null,
            warning: false
        }))
    };


    handleEdit = (evaluator) => {
        const evaluators = [...this.state.evaluators];
        const index = evaluators.indexOf(evaluator);
        evaluators[index] = { ...evaluator };
        evaluators[index].disabled = false;
        this.setState({ evaluators });        
    }

    handleDelete = (evaluator) => {
        apiRequest.deleteEvaluator(this,evaluator);
    }

    handleBack = (evaluator) => {
        const evaluators = [...this.state.baseEvaluators];
        this.setState({ evaluators });
    }

    handleDone = (evaluator) => {
        const evaluators = [...this.state.evaluators];
        const index = evaluators.indexOf(evaluator);
        evaluators[index] = { ...evaluator };
        evaluators[index].disabled = true;
        this.setState({ evaluators });   
        apiRequest.saveEvaluator(this,evaluator);
    }

    handleValidFrom = (evaluator, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        const evaluators = [...this.state.evaluators];
        const index = evaluators.indexOf(evaluator);
        evaluators[index] = { ...evaluator };
        evaluators[index].validFrom = newDate;
        this.setState({ evaluators });
    }

    handleValidTo = (evaluator, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        } 
        const evaluators = [...this.state.evaluators];
        const index = evaluators.indexOf(evaluator);
        evaluators[index] = { ...evaluator };
        evaluators[index].validTo = newDate;
        this.setState({ evaluators });
    }

    handleClose = () => {
        this.setState({
            addNew:false
        });
    }

    handleOpen = () => {
        this.setState({
            addNew: true
        });
    }

    handleSave = () => {
        Constants.setcontentLoading(true);
        this.setState(prevState=>({
            addNew: false,
            loading: {
                ...prevState.loading,
                user: true,
                evaluator: true
            },
        }));
        apiRequest.getUser(this);
        apiRequest.getEvaluator(this);
    }

    handleApproved = () => {
        this.setState({
            approved: true,
            anchorEl: null,
            warning: false
        })
    }

    render() {
        const {user, evaluator, addNew, anchorEl, warning, approved, isModifable, lastValidFromForEdit, lastValidFromForAdd, evaluators, loading} = this.state;
        const {classes, snackbar, onEdit, onClose, refresh} = this.props;

        return ((loading.user === false && loading.evaluator === false ) &&
            <Drawer  
                anchor={'bottom'} 
                open={true} 
                onClose={onClose}
            >
            <AppBar 
                className={classes.appBar} 
                position="static"
            >
                <Toolbar>
                    <div style={{flexGrow:1}}></div>
                    <IconButton 
                        className={classes.closeIcon} 
                        onClick={onClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Popper 
                open={warning} 
                anchorEl={anchorEl} 
                placement='bottom' 
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.helperPaper}>
                            <Typography className={classes.helperText}><b>Az adatok módosítása esetén, a felhasználók által bevitt adatok törlődnek!</b></Typography>
                            <Typography className={classes.helperText}><b>Ha mégis módosítani szeretnél kérlek ennek tudatában tedd!</b></Typography>
                            {approved == true && <Button variant="outlined" disabled={true} className={classes.approvedButton} size="small">Jóváhagyva</Button>}
                            {approved == false && <Button disabled={true} variant="outlined" onClick={this.handleApproved} className={classes.approveButton} size="small">Jóváhagyás</Button>}
                            <Button variant="outlined" onClick={this.handleCloseWarning} className={classes.backButton} size="small">Bezár</Button>
                        </Paper>
                    </Fade>
                )}
            </Popper> 
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xl={3} lg={3} md={5} sm={6} xs={12}>
                                <AccountProfile user={user} />
                            </Grid>
                            <Grid item xl={3} lg={3} md={5} sm={6} xs={12}>
                                <ActiveEvaluator user={evaluator} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <EvaluatorsTable
                            onOpenWarning={this.handleOpenWarning} 
                            approved={approved} 
                            isModifable={isModifable} 
                            lastValidFrom={lastValidFromForEdit} 
                            onDelete={this.handleDelete} 
                            onOpen={this.handleOpen} 
                            onValidFromChange={this.handleValidFrom} 
                            onValidToChange={this.handleValidTo} 
                            onBack={this.handleBack} 
                            onEdit={this.handleEdit} 
                            onDone={this.handleDone} 
                            users={evaluators} 
                        />
                    </Grid>
                </Grid>
                {addNew === true && 
                    <AddNewDialog
                        refresh={refresh}
                        lastValidFrom={lastValidFromForAdd} 
                        user={user} 
                        onSave={this.handleSave} 
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={addNew} 
                    />
                }
                </div>
            </Drawer>
        );
    }
};

export default withStyles(style)(EditUser);
