
import React from 'react';
//Core
import {
    Typography,
    IconButton,
    Fade,
    Paper,
    Popper,
    ClickAwayListener
} from '@material-ui/core';
//Icons
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//Style
import style from './style';


function helperPopper(props){
    const {parent,infoOpen,anchorEl,data} = props;
    const classes= style();


    return <div className={classes.help}>
                <ClickAwayListener onClickAway={parent.handleClickAway}>
                    <IconButton onClick={parent.handleInfoClick} className={classes.helpIcon}>
                        <HelpOutlineIcon/>
                        </IconButton>      
                </ClickAwayListener>
                <Popper open={infoOpen} anchorEl={anchorEl} placement='bottom' transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.helperPaper}>
                                <Typography className={classes.helperText}><b>Magyarázat a pontokhoz:</b></Typography>
                                {data.map(point=>
                                <Typography 
                                    key={point.value} 
                                    className={classes.helperText}
                                >
                                    <b>Érték: </b>{point.value} - {point.label}
                                </Typography>)
                            }
                            </Paper>
                        </Fade>
                        )}
                </Popper>
        </div>
    }

    export default helperPopper;