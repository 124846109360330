import theme from 'theme';

const useStyle = ({

    root: {
    },
    media: {
        paddingTop: theme.spacing(2),
        height: 120,
        textAlign: 'center',
        '& > img': {
            height: '100%',
            width: 'auto'
        }
    },
    content: {
        padding: theme.spacing(1, 2)
    },
    actions: {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center',
    },
    buttons: {
        fontSize: '0.6rem',
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    }
});
export default useStyle;
   
