import React from 'react';
//Components
import { NotificationBar,Progress  } from 'components';
import {Api,handleResponse,handleError} from 'api';

//Actions
const Actions = {
    Save: 'exit/admin/emails/save',
    GetEmails:'exit/admin/emails'
}


export const apiRequest = {
    saveChanges,
    getEmails
};

//#Api calls

async function getEmails(parent) {
    const { selected } = parent.props;
        await new Api().call.get(`${Actions.GetEmails}/${selected.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                emails:res,
                loading:false
            });
        }).catch(handleError);
}


async function saveChanges(parent, model) {
    const { onClose } = parent.props;
    NotificationBar( 'Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
        await new Api().call.post(Actions.Save, model)
        .then(handleResponse)
        .then(res => {
            onClose();
        }).catch(handleError);
}

