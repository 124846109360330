//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar,
    Progress  
} from 'components';
//Actions

const Actions = {
    GetLeaderRatingPoints:'admin/rating/leader-rating-points',
    GetInputMatrices:'admin/rating/leader-rating-points/inputs',
    GetPeriods:'admin/rating/rating-points/periods',
    Delete:'admin/rating/leader-rating-points/delete',
    Save:'admin/rating/leader-rating-points/edit/save'
}

export const apiRequest = {
    populatetLeaderRatingPoints,
    populateInputMatrices,
    populatePeriods,
    deletePoint,
    saveChanges
};

async function populatetLeaderRatingPoints(parent,period) {
    await new Api().call.get(`${Actions.GetLeaderRatingPoints}/${period}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            leaderRatingPoints:res,
            loading:false
        });
    }).catch(handleError);
}

async function populateInputMatrices(parent) {
    await new Api().call.get(Actions.GetInputMatrices)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            inputs:res
        });
    }).catch(handleError);
}


async function populatePeriods(parent) {
    await new Api().call.get(Actions.GetPeriods)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            periods:res
        });
    }).catch(handleError);
}

async function deletePoint(parent,model,period)
{
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Delete,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            deleted:null
        });
        populatetLeaderRatingPoints(parent,period);
    }).catch(handleError);
}

async function saveChanges(parent,model,period) {

    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, < Progress />);
    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            edit:null
        });
        populatetLeaderRatingPoints(parent,period);
    }).catch(handleError);
}


