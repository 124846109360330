import React from 'react';
import {Api,handleResponse,handleError} from 'api';
//Components
import { NotificationBar,Progress  } from 'components';
//Action
const Actions = {
    Remove: 'admin/user/avatar/remove',
}


export const apiRequest = {
    remove
};

async function remove(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const {userId,refreshProfile,refreshTable} = parent.props;
    const model = {userId};
    await new Api().call.post(Actions.Remove,model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                request:false
            });
            refreshProfile();
            refreshTable();
        }).catch(error => {
            parent.setState({
                request:false
            });
            handleError(error);
        });
}