//React

import React,{ Component } from 'react';
//Core

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Tooltip,
    IconButton,
    DialogTitle,
    Divider,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
    FormHelperText
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Icons

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Style

import theme from 'theme';
import style from './style';
import { withStyles } from '@material-ui/styles';


class ConfirmDialog extends Component {

    constructor(props){
        super(props);
        this.state={
            label:'',
            classificationId:'',
            quantity:'',
            defaultValue:'',
            maxValue:'',
            valid:false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {label, classificationId, quantity, defaultValue, maxValue} = this.state;

        if (prevState.label != label || prevState.classificationId!= classificationId || prevState.quantity!= quantity
            || prevState.defaultValue!= defaultValue || prevState.maxValue!= maxValue) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
      
        this.setState({
            [name]:value
        });
    }

    handleSave = () =>{
        const {label, classificationId, quantity, defaultValue, maxValue} = this.state;
        const {period, parentId} = this.props;

        const model = {
            parent:parentId,
            period:period.id,
            label,
            classificationId:classificationId === '' ? null : classificationId,
            quantity:quantity === '' ? null : quantity * 1,  
            defaultValue:defaultValue === '' ? null : defaultValue * 1,
            maxValue:maxValue === '' ? null : maxValue * 1,              
        }

        apiRequest.saveChanges(this,model);
    }

    validateFields = (value) =>{
        if(value === null || value === undefined || value === ''){
            return false;
        }
        return true;
    }

    render(){
        const {open, onClose, classes, level, classifications, maxValue} = this.props;
        const {label, classificationId, defaultValue, quantity, valid} = this.state;

        return(
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Új pont hozzáadása
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider/>
                <DialogContent id="add-new-form">
                    {level===0 &&
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} item>
                                <TextField
                                    required
                                    error={!this.validateFields(label)}
                                    helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                    label="Megnevezés"
                                    fullWidth
                                    value={label}
                                    name="label"
                                    onChange={this.handleChange}
                                />
                            </Grid>             
                        </Grid>
                    }
                    {level>0 && <Grid container spacing={2}>
                            <Grid xs={12} sm={6} md={6} item>
                                <FormControl 
                                    fullWidth 
                                    required 
                                    error={!this.validateFields(classificationId)}  
                                    className={classes.formControl}
                                >
                                <InputLabel id="classification-select-label">
                                    Minősítés
                                </InputLabel>
                                    <Select
                                        name="classificationId"
                                        labelId="classification-select-label"
                                        id="classification-select"
                                        value={classificationId}
                                        onChange={this.handleChange}
                                    >
                                        {classifications.map((item,index)=>(
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                    {!this.validateFields(classificationId) && 
                                    <FormHelperText>Kötelező megadni</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6} md={6} item>
                                <TextField
                                    required
                                    error={!this.validateFields(quantity)}
                                    helperText={!this.validateFields(quantity) && 'Kötelező megadni'}
                                    label="Mennyiség"
                                    fullWidth
                                    type="number"            
                                    value={quantity}
                                    name="quantity"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={6} item>
                                <TextField
                                    label="Alapértelmezett érték"
                                    fullWidth
                                    type="number"            
                                    value={defaultValue}
                                    name="defaultValue"
                                    onChange={this.handleChange}
                                />                 
                            </Grid>
                            <Grid xs={12} sm={6} md={6} item>
                                <TextField
                                    label="Max érték"
                                    fullWidth
                                    type="number"            
                                    value={maxValue}
                                    name="maxValue"
                                    onChange={this.handleChange}
                                />                 
                            </Grid>                            
                        </Grid>
                    }           
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Tooltip title="Mentés">
                                <span>
                                    <IconButton
                                        disabled={!valid} 
                                        onClick={this.handleSave} 
                                        className={classes.doneButton} 
                                        aria-label="done"
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Mégsem">
                                <span>
                                    <IconButton 
                                        className={classes.backButton} 
                                        onClick={onClose}  
                                        aria-label="back"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </span>
                        </Tooltip>
                    </div>                       
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(style)(ConfirmDialog);
