import React from 'react';
//Icons
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
//Style
import style from './style';
import { UserStore } from 'stores';


export default function HoverRating(props) {
    const { name, handleChanges, index, defValue, itemId, data } = props;
    const [hover, setHover] = React.useState(-1);
    const [value, setValue] = React.useState(defValue === null || defValue === undefined ? 1 : defValue);
    const [title, setTitle] = React.useState(data.filter(item => item.value === defValue).length > 0 ? data.filter(item => item.value === defValue)[0].label : '');
    
    const loggedBy =  UserStore.getLoggedByAdminValue();

    const classes = style.useStyles();
    const StyledTooltip = style.StyledTooltip;
    const StyledRating = style.StyledRating;

    const handleChange = (event, newValue) => {
        if (newValue != null) {
            setValue(newValue);
            handleChanges(itemId, newValue);
        }
       
    }

    const handleHover = (newHover) => {
        setHover(newHover);
        if (newHover > 0) {
            var temp = data.filter(item => item.value === newHover);
            if (temp.length > 0) {
                setTitle(temp[0].label);
            }
        }      
    }
    return (
        <div className={classes.root}>
            <StyledTooltip title={title}>
                <StyledRating
                    name={"rating-" + name}
                    value={value}
                    max={data.length}
                    size="large"
                    disabled={loggedBy!=null && loggedBy.period.disabled}
                    icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                    emptyIcon={<RadioButtonUncheckedIcon fontSize="inherit" />}
                    precision={1}
                    onChange={(event, newValue) => handleChange(event, newValue)}
                    onChangeActive={(event, newHover) => {
                        handleHover(newHover);                 
                }}
                />
            </StyledTooltip>
        </div>
    );
}
