import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    subRoot: {
        marginTop:theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    typography: {
        fontFamily: 'Nunito',
        fontSize: '1.1rem',
        lineHeight: '1.3'
    },
    headerDiv:{
        display:'flex',
        alignItems:'center'
    },
    flexGrow: {
        flexGrow: 3
    },
    lockIcon: {
        color: theme.palette.warning.main
    },
    saveButton:{
        color:'white',
        fontFamily:'Nunito',
        textTransform:'none',
        fontWeight:'bold',
        backgroundColor: theme.palette.success.light,
        '&:hover': {
            color:theme.palette.success.main,
            backgroundColor: 'white',
            border:`1px solid ${theme.palette.success.main}`
        }
    },
    headerTitle: {
        '& .MuiCardHeader-title': {
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '1.6',       
        },
        backgroundColor: theme.palette.papper
    },
});


export default useStyles;
