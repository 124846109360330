//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    NotificationBar, 
    Progress 
} from 'components';
//Actions

const Actions = {
    GetList: 'probaido/admin/user/check-list',
    DownloadFirst: 'probaido/leader/forms/first/download',
    DownloadSecond: 'probaido/hr/forms/second/download',
    DownloadThird: 'probaido/leader/forms/third/download',
    DownloadAll: 'probaido/hr/forms/download-all'
}

export const apiRequest = {
    populateList,
    downloadFirst,
    downloadThird,
    downloadAll
};

async function populateList(parent) {
    const { user } = parent.props;

    await new Api().call.get(`${Actions.GetList}/${user.id}`)
        .then(handleResponse)
        .then(res => {
            parent.setState(prevState => ({
                loading: false,
                data: res
            }));
        }).catch(error => {
            handleError(error);
            parent.setState(prevState => ({
                loading: false
            }));
        });
}

async function downloadFirst(parent, selected) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.DownloadFirst, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

async function downloadThird(parent, selected) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.DownloadThird, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

async function downloadAll(parent, selected) {
    NotificationBar('File-ok letöltése...', 'top', 'right', 2000, null, <Progress />);

    await new Api().call.post(Actions.DownloadFirst, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });

    await new Api().call.post(Actions.DownloadSecond, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
        }).catch(handleError);

    await new Api().call.post(Actions.DownloadThird, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            parent.setState({
                selected: null,
                type: null
            })
        }).catch(error => {
            handleError(error);
            parent.setState({
                selected: null,
                type: null
            })
        });
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
