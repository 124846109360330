//React

import React, { Component } from 'react';
//Icons

import SaveIcon from '@material-ui/icons/Save';
//Core

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
} from '@material-ui/core';
//Api

import { apiRequest } from './apiRequest';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';


class AccountDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _user: this.props.user
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(prevState => ({
            _settings: {
                ...prevState._settings,
                [name]: checked
            }
        }));
    }

    handleTextChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            _user: {
                ...prevState._user,
                [name]: value
            }
        }));
    }

    handleSave = () => {
        apiRequest.saveAccount(this);
    }

    render() {
        const { _user } = this.state;
        const { classes } = this.props;

        return (
            <Card>
                <form autoComplete="off" noValidate>
                    <CardHeader title="Profil" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Teljes név"
                                    margin="dense"
                                    name="fullName"
                                    onChange={this.handleTextChange}
                                    required
                                    value={_user.fullName}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Felhasználónév"
                                    margin="dense"
                                    name="userName"
                                    onChange={this.handleTextChange}
                                    value={_user.userName}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="E-mail cím"
                                    margin="dense"
                                    name="email"
                                    onChange={this.handleTextChange}
                                    value={_user.email}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Telefonszám"
                                    margin="dense"
                                    name="telephone"
                                    onChange={this.handleTextChange}
                                    value={_user.telephone}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Domain név"
                                    margin="dense"
                                    name="domainName"
                                    disabled
                                    value={_user.domainName}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Button
                            disabled={(_user.fullName !== '' && _user.fullName !== null) ? false : true}
                            variant="contained"
                            className={classes.saveButton}
                            size="small"
                            onClick={this.handleSave}
                            endIcon={<SaveIcon />}
                        >
                            Mentés
                        </Button>
                    </CardActions>
                </form>
            </Card>
        );
    }
};

export default withStyles(style)(AccountDetails);
