import React, { Component } from 'react';
//Core
import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    Typography,
    CircularProgress
} from '@material-ui/core';
//Components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { apiRequest } from './apiRequest';
import { UserStore } from 'stores';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

//Style
import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';





class LoginByDialog extends Component {

    constructor(props) {
        super(props);

        apiRequest.populateUsers(this);
        apiRequest.populatePeriods(this);

        this.state = {
            users: [],
            periods:[],
            user:this.props.loginBy!=null ? this.props.loginBy.user : '',
            period: this.props.loginBy!=null ? this.props.loginBy.period : '',
            loading:{
                users:true,
                periods:true
            },
            valid:false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { user,period } = this.state;
        if (prevState.user != user || prevState.period!= period) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    onChange = (value,name) => {
        this.setState({
            [name]: value
        });
    }

    handleSave = () => {
        const { onClose } = this.props;
        const {user,period} = this.state;
        UserStore.setLoggedByAdmin(user,period);
        onClose();
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }
    
    render() {
        const { onClose, open, classes } = this.props;
        const { period, valid, loading, users,periods, user } = this.state;
        return (
            <Dialog 
                open={open} 
                onClose={onClose} 
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle style={{ backgroundColor: theme.palette.warning.light }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                       Bejelentkezés mint
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider />
                <DialogContent id="add-new-form">
                    <Grid spacing={2} container>                     
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-period"
                                    name="period"
                                    value={period}
                                    onChange={(event, value) => this.onChange(value,"period")}
                                    options={periods}
                                    getOptionLabel={(option) => option.label===null || option.label===undefined ? "" : option.label}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            label="Periódusok"
                                            error={!this.validateFields(period)}
                                            helperText={!this.validateFields(period) ? "Kötelező megadni" : ''}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.periods ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Autocomplete
                                    clearText="Alaphelyzet"
                                    noOptionsText="Nincs találat"
                                    closeText="Bezárás"
                                    id="select-user"
                                    name="user"
                                    value={user}
                                    onChange={(event, value) => this.onChange(value,"user")}
                                    options={users}
                                    getOptionLabel={(option) => option.fullName===null || option.fullName===undefined ? "" : option.fullName}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            label="Felhasználók"
                                            error={!this.validateFields(user)}
                                            helperText={!this.validateFields(user) ? "Kötelező megadni" : ''}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading.users ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                   </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                        <Tooltip title="Mentés">
                            <span>
                                <IconButton 
                                    disabled={!valid} 
                                    onClick={this.handleSave} 
                                    className={classes.doneButton} 
                                    aria-label="done"
                                >
                                    <DoneIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Mégsem">
                            <span>
                                <IconButton 
                                    className={classes.backButton} 
                                    onClick={onClose}  
                                    aria-label="back"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                </DialogActions>
        </Dialog>);
    }
   
   
}
export default withStyles(style.useStyles)(LoginByDialog);
