import { makeStyles } from '@material-ui/core';
import theme from 'theme';


const useStyles = ({
  title: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color:theme.palette.appMain.main,
    paddingBottom:'1rem',
    paddingTop:'1rem',
    fontSize: '1.0rem',
  },
  subTitle:{
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color:theme.palette.appMain.main,
    fontSize: '0.9rem',
    paddingBottom:'0.5rem',
  },
  point:{
    fontFamily: 'Nunito',
    fontSize: '0.8rem',
  },
  value:{
    fontFamily: 'Nunito',
    color:theme.palette.appMain.dark,
    fontSize: '0.9rem',
  },
  footer:{
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    padding:'0.5rem',
  },
  mainButton:{
    color:'white',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.appMain.main,
    border:`1px solid ${theme.palette.appMain.main}`,
    '&:hover': {
        color:theme.palette.appMain.main,
        backgroundColor: 'white'  
    },
    '&:disabled': {
        backgroundColor: theme.palette.secondary.light,
        color:theme.palette.secondary.dark
    },
  },
  secondaryButton:{
    color:'white',
    fontFamily:'Nunito',
    textTransform:'none',
    fontWeight:'bold',
    backgroundColor: theme.palette.appSecondary.main,
    border:`1px solid ${theme.palette.appSecondary.main}`,
      '&:hover': {
        color:theme.palette.appSecondary.main,
        backgroundColor: 'white',        
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.light,
        color:theme.palette.secondary.dark
      },
    }
});

const useStylesForPicker = makeStyles(theme => ({
  root: {
  },
  picker: {
    '& label': {
      fontFamily: 'Nunito'
    },
    '& label.Mui-focused': {
      color: theme.palette.appMain.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.appMain.main,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.white,
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.appMain.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.appMain.light,
      },
    },
    '& .MuiOutlinedInput-notchedOutline > legend > span': {
      paddingRight: '1.2rem'
    }
  }
}));

export default {
  useStyles: useStyles,
  useStylesForPicker: useStylesForPicker
};