//React

import React from 'react';
import clsx from 'clsx';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
//Helpers

import {enums} from 'stores';
//Styles

import style from './style';
//Core

import {
  Card,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress
} from '@material-ui/core';
//Icons

import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';


const StyledTableRow = style.StyledTableRow;

const Claims = enums.claims;

const ClaimsTable = props => {
  const {className, loading, onClick, claims, ...rest} = props;

  const classes = style.useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
      {loading && <LinearProgress />}
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                  <StyledTableRow>
                    <TableCell >Felhasználó</TableCell>
                    <TableCell align="center" padding="checkbox"></TableCell>
                    <TableCell align="center" padding="none">Értékelhet</TableCell>
                    <TableCell align="center" padding="checkbox"></TableCell>
                    <TableCell align="center" padding="none">Értékelhető</TableCell>
                  </StyledTableRow>
              </TableHead>
              <TableBody>
                {claims.map((claim,index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={claim.userId}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={(claim.avatar !== null && claim.avatar !== undefined) ? claim.avatar : ''}
                        />
                        <Typography variant="body1">
                          {claim.userName}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell padding="none" align="center" >
                    {claim.ertekelhet===true &&
                      <Tooltip title="Nem értékelhet">
                        <IconButton onClick={()=>onClick(Claims.ERTEKELHET,false,claim) } className={classes.doneIcon}>
                          <DoneIcon/>
                        </IconButton>
                      </Tooltip>}
                        {claim.ertekelhet===false &&
                          <Tooltip title="Értékelhet">
                          <IconButton onClick={()=>onClick(Claims.ERTEKELHET,true,claim) } className={classes.blockIcon}>
                            <BlockIcon/>
                          </IconButton>
                          </Tooltip> 
                        }                                                                          
                    </TableCell> 
                    <TableCell padding="none" align="center" >
                      {claim.ertekelhet===true ? 'Igen' : 'Nem'}
                    </TableCell> 
                    <TableCell padding="none" align="center" >
                    {claim.ertekelheto===true ?
                      <Tooltip title="Nem értékelhető">
                      <IconButton onClick={()=>onClick(Claims.ERTEKELHETO,false,claim) } className={classes.doneIcon}>
                        <DoneIcon/>
                      </IconButton></Tooltip>  : 
                      <Tooltip title="Értékelhető">
                      <IconButton onClick={()=>onClick(Claims.ERTEKELHETO,true,claim) } className={classes.blockIcon}>
                        <BlockIcon/>
                      </IconButton></Tooltip>
                    }
                    </TableCell>                                 
                    <TableCell padding="none" align="center" >
                      {claim.ertekelheto===true ? 'Igen' : 'Nem'}
                    </TableCell>                                    
                  </StyledTableRow>))
                }
                {claims.length < 1 &&
                  <StyledTableRow>
                    <TableCell align="center" colSpan="5">
                      {loading ? 'Betöltés...' : 'Nincs találat'}
                    </TableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default ClaimsTable;
