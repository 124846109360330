//React

import React, { Component } from 'react';
//Core

import { Grid } from '@material-ui/core';
//Components

import { 
    TableToolbar, 
    DataTable, 
    Pagination, 
    AddNewDialog,
    UserDrawer,
    RoleDrawer 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import {  withStyles } from '@material-ui/styles';
import style from './style';


class FiltersTable extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            data: [],
            loading: {
                labels: true,
                data: true,
            },
            filter: {
                labels: [],
                active: null
            },
            user:null,
            role:null,
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            filterLabels: [],
            edit: null,
            selectedLabels: [],
        }

    }
    //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateData(this, this.state.page);
        apiRequest.populateFilterLabels(this);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (companies) => {
        this.setState({
            selectedLabels: companies
        });
    }

    handleChange = (event, index, props) => {
        const value = event.target.value;
        const data = [...this.state.data];
        data[index][props] = value;
        this.setState({ data });
    }

    handleEdit = (bio) => {
        this.setState({
            edit: { ...bio }
        });
    }

    handleSave = (index) => {
        const { data } = this.state;
        
        apiRequest.saveChanges(this, data[index]);
    }

    handleCancel = (index) => {
        const data = [...this.state.data];
        data[index].name = this.state.edit.name;
        this.setState({
            data,
            edit: null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                data: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateData(this, 1));
    }

    handleDelete = (index) => {
        const data = [...this.state.data];
        data[index].isValid = false;
        apiRequest.saveChanges(this, data[index]);
    }

    handleDone = (index) => {
        const data = [...this.state.data];
        data[index].isValid = true;
        apiRequest.saveChanges(this, data[index]);
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                data: true
            }
        }));
        apiRequest.populateData(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open:false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    handleBlockSelected = () =>{
        const {selectedLabels,page} = this.state;
        const model ={filters:selectedLabels,valid:false};
        apiRequest.validateFilters(this,page,model);
        this.setState({
            selectedLabels:[]
        });
    }

    handleUnBlockSelected = () =>{
        const {selectedLabels,page} = this.state;
        const model ={filters:selectedLabels,valid:true};
        apiRequest.validateFilters(this,page,model);
        this.setState({
            selectedLabels:[]
        });
    }

    refresh = () =>{
        const {page} = this.state;
        apiRequest.populateData(this,page);
        apiRequest.populateFilterLabels(this);
    }

    handleUser = (filter) =>{
        this.setState({
            user:filter
        });
    }

    handleRole = (filter) =>{
        this.setState({
            role:filter
        });
    }

    render() {
        const {data, selectedLabels, open, filter, role, currentPage, step, total, pages, filterLabels, edit, user, loading} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <React.Fragment>
                <Grid container>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={10} item>
                        {loading.data === false && 
                            <TableToolbar
                                onBlockSelected={this.handleBlockSelected}
                                onUnBlockSelected = {this.handleUnBlockSelected} 
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter} 
                                labels={filterLabels} 
                                loading={loading} 
                                selected={selectedLabels} 
                            />
                        }
                        <div className={classes.content}>
                            <DataTable
                                loading={loading.data}
                                onUserDrawer={this.handleUser}
                                onRoleDrawer={this.handleRole} 
                                onOpen={this.handleOpen} 
                                onDelete={this.handleDelete} 
                                onDone={this.handleDone} 
                                onCancel={this.handleCancel} 
                                edit={edit} 
                                onEdit={this.handleEdit} 
                                onSave={this.handleSave} 
                                onChange={this.handleChange} 
                                onSelect={this.handleSelectCompanies} 
                                data={data}
                                selectedLabels={selectedLabels}  
                            />
                        </div>
                        {(loading.data === false && edit == null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total}                         
                                pages={pages} 
                            />
                        }
                    </Grid>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                </Grid>
                {open == true && 
                    <AddNewDialog
                        refresh={this.refresh} 
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
                {user!=null && 
                    <UserDrawer
                        filter={user} 
                        onClose={()=>this.handleUser(null)}
                    />
                }
                {role!=null && 
                    <RoleDrawer
                        filter={role} 
                        onClose={()=>this.handleRole(null)}
                    />
                }
            </React.Fragment>
        );
    }
};

export default withStyles(style)(FiltersTable);
