import React, { Component } from 'react';
//Core
import {
    Grid,
    Card,
    CardHeader,
    CircularProgress,
    Button,
    Typography
} from '@material-ui/core';
//Competences
import { GeneralSlider, RatingCard, ExtendedRatingCard, CompareResults } from './components';
import { apiRequest } from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';


class Competences extends Component {

    constructor(props) {
        super(props);
        const { lazyData, userid } = this.props;

        if (lazyData === null) {
            apiRequest.populateCompetences(this,userid);
            apiRequest.populateResult(this);
        }

        this.state = {
            competences: lazyData !== null && lazyData.competences!==undefined ? lazyData.competences : [],
            result: lazyData !== null ? lazyData.result !== undefined ? lazyData.result : [] : [],
            avg: lazyData !== null ? lazyData.avg !== undefined ? lazyData.avg : null : null,
            loading: {
                competences: lazyData !== null ? false : true,
                result: lazyData !== null ? false : true
            },
            extended: false

        }
    }
           
     //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        if (this.props.userid !== prevProps.userid) {
            this.setState({
                competences: [],
                result: [],
                avg: null,
                loading: {
                    competences: true,
                    result: true
                },
                extended:false
            });
            apiRequest.populateCompetences(this,this.props.userid);
            apiRequest.populateResult(this);
           
        }
    }
     //#EndLifecycle methods

    handleExtended = (value) => {
        this.setState({
            extended:value
        });
    }

    render() {
        const { competences,loading, result, avg, extended } = this.state;
        const { classes, period,location } = this.props;
        return ((loading.competences === true || loading.result === true) ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div> : <div>
            
            {competences.length < 1 ? <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nem lett kitöltve az önértékelés</Typography></div> : competences.map(item =>
                <GeneralSlider key={item.id} subTitle={item.label} competences={item.competenceSubGroup} />)}
            <div style={{ paddingTop: theme.spacing(2) }}>
                {result.length < 1 && <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nem lett értékelve mások által</Typography></div>}
                {result.length >0 && extended===false && result.map((comp) => comp.competenceSubGroup.map((compGroup) => (
                    <Grid
                        container
                        spacing={0}
                        key={compGroup.id}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={6}
                            lg={8}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <RatingCard title={compGroup.label} groupId={compGroup.id} handleCompetences={this.handleCompetences} competences={compGroup.competences} />
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>
                )))}
                {result.length > 0 && extended === false &&
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={6}
                            lg={8}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Card>
                                <CardHeader className={classes.headerTitle} title={`Átlag: ${avg}`} >
                                </CardHeader>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xl={3}
                            lg={2}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>}
                {result.length > 0 && extended === true &&
                    <Grid
                        container
                        spacing={0}>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                        <ExtendedRatingCard period={period} userid={this.props.userid} snackbar={this.props.snackbar} />
                        </Grid>
                    </Grid>}
                {result.length > 0 && extended === false ? <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        md={false}
                        sm={false}
                        xs={false}
                    ></Grid>
                    <Grid
                        item
                        align="right"
                        xl={6}
                        lg={8}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                     {(location.pathname==="/form-rating/main" || location.pathname==="/form-rating/workers")  &&  <Button 
                            style={{ margin: theme.spacing(2) }}
                            variant="contained" 
                            onClick={() => this.handleExtended(true)} 
                            size="small" 
                            color="primary"
                        >
                            Részletes lebontás
                        </Button>}
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={2}
                        sm={false}
                        md={false}
                        xs={false}
                    ></Grid>
                </Grid> : result.length > 0 && 
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            align="right"
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                        {(location.pathname==="/form-rating/main" || location.pathname==="/form-rating/workers") && <Button 
                                style={{ margin: theme.spacing(2) }} 
                                variant="contained" 
                                onClick={() => this.handleExtended(false)} 
                                size="small" 
                                color="primary"
                            >
                                Összegzett nézet
                            </Button>}
                        </Grid>
                    </Grid>}
                {(result.length > 0 || competences.length) > 0 &&
                    <Grid
                        style={{ paddingTop: theme.spacing(2) }}
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xl={1}
                            lg={1}
                            md={false}
                            sm={false}
                            xs={false}
                        ></Grid>
                        <Grid
                            item
                            xl={10}
                            lg={10}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                        <CompareResults period={period} userid={this.props.userid} />
                        </Grid>
                        <Grid
                            item
                            xl={1}
                            lg={1}
                            sm={false}
                            md={false}
                            xs={false}
                        ></Grid>
                    </Grid>}
            </div>
           
        </div>)
    }
}
export default withStyles(style)(Competences);
