import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import style from './style';

export default function Progress() {

    const classes = style();
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}
