
//React

import React from "react";
//Core

import {
    Typography,
    Divider,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableHead,
    Card,
    CardContent,
    LinearProgress,
    Button,
    IconButton,
    Tooltip,
    Popover
} from '@material-ui/core';
//Icon

import FiberNewIcon from '@material-ui/icons/FiberNew';
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NoteIcon from '@material-ui/icons/Note';
//Components

import PerfectScrollbar from 'react-perfect-scrollbar';
import {DoneDialog} from './components';
//Style

import style from './style';
import { withStyles } from '@material-ui/styles';
//Api

import { apiRequest } from './apiRequest';

const StyledTableRow = withStyles({
    root: {
        '& > *': {
            fontSize: '0.8rem',
        },
    },
    head: {
        '& > *': {
            fontSize: '0.9rem',
            fontWeight: 'bold'
        },
    },
})((props) => <TableRow  {...props} />)

function ReportedProblems(props){

    const [problems,setProblems] = React.useState([]);
    const [loading,setLoading] = React.useState(true);
    const [doneDialog,setDoneDialog] = React.useState(null);
    const [anchorEl,setAnchorEl] = React.useState({element:null,text:''});

    const classes = style();

    React.useEffect(()=>{
        apiRequest.getProblems().then((res)=>{
            setProblems(res);
        }).finally(()=>{
            setLoading(false);
        })
    },[])

    function handleNew(id){
        apiRequest.setNew(id).then(()=>{
            setLoading(true);
            apiRequest.getProblems().then((res)=>{
                setProblems(res);
            }).finally(()=>{
                setLoading(false);
            })
        });
    }

    function handleInProgress(id,description){
        const model={id,description}
        apiRequest.setDone(model).then(()=>{
            setDoneDialog(null);
            setLoading(true);
            apiRequest.getProblems().then((res)=>{
                setProblems(res);
            }).finally(()=>{
                setLoading(false);
            })
        });
    }

    function handleCancel(id){
        apiRequest.setCancel(id).then(()=>{
            setLoading(true);
            apiRequest.getProblems().then((res)=>{
                setProblems(res);
            }).finally(()=>{
                setLoading(false);
            })
        });
    }

    const problemState = (item) =>{
        if(item.state=="ÚJ"){
            return <Button onClick={()=>handleNew(item.id)} className={classes.newButton} variant="contained" size="small">
                ÚJ
            </Button>
        }else if(item.state==="FOLYAMATBAN"){
            return <div className={classes.button_container}>
                <Button onClick={()=>setDoneDialog(item.id)} className={classes.inProgressButton} disabled={!item.isOwn} variant="contained" size="small" >
                    FOLYAMATBAN
                </Button>
                {item.isOwn && <Button onClick={()=>handleCancel(item.id)} className={classes.cancelButton} disabled={!item.isOwn} variant="contained" size="small" >
                    MÉGSEM
                </Button>}
            </div>
            
        }else{
            return <Button disabled={true} variant="contained" size="small">
                {item.state}
            </Button>
        }
    }

    const problemStateIcon = (state) =>{
        if(state==="ÚJ"){
            return <FiberNewIcon className={classes.newIcon} fontSize="small"/>
        }else if(state==="FOLYAMATBAN"){
            return <CachedIcon className={classes.inProgressIcon}  fontSize="small"/>
        }else if(state==="MEGOLDVA"){
            return <CheckCircleIcon className={classes.doneIcon}  fontSize="small"/>
        }
        return null;
    }

    return(
        <div className={classes.root} >
            <Typography className={classes.title}>
                Bejelentett hibák
            </Typography>
            <Divider/>
            <Card>
                <CardContent className={classes.content}>
                    {loading && <LinearProgress />}
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell padding="checkbox"/>  
                                        <TableCell align="center" padding="none">Azonosító</TableCell>
                                        <TableCell align="center" padding="none">Bejelentő</TableCell>
                                        <TableCell align="center" padding="none">Probléma leírás</TableCell>  
                                        <TableCell align="center" padding="none">Bejelentve</TableCell>          
                                        <TableCell padding="none" align="center">Állapot</TableCell>                      
                                        <TableCell padding="none" align="center">Foglalkozik vele</TableCell>
                                        <TableCell padding="none" align="center">Utolsó interakció</TableCell>
                                        <TableCell padding="checkbox"/> 
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {problems.map((problem, index) => (
                                        <StyledTableRow
                                            hover
                                            key={problem.id}
                                        >
                                            <TableCell padding="checkbox">
                                                {problemStateIcon(problem.state)}
                                            </TableCell>  
                                            <TableCell align="center" >
                                                #{problem.id}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {problem.reporter} - {problem.email}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {problem.description}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {problem.created}
                                            </TableCell>
                                            <TableCell align="center">
                                                {problemState(problem)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {problem.owner}
                                            </TableCell>
                                            <TableCell align="center">
                                                {problem.lastAction}
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                {(problem.solveDescription!==null && problem.solveDescription!==undefined && problem.solveDescription!=='') &&
                                                <Tooltip title="Megoldás leírása">
                                                    <IconButton onClick={(event)=>setAnchorEl({element:event.currentTarget,text:problem.solveDescription})} color="primary" size="small">
                                                        <NoteIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>                                                
                                                }
                                            </TableCell>  
                                        </StyledTableRow>
                                    ))}
                                    {problems.length < 1 &&
                                    <StyledTableRow>
                                        <TableCell align="center" colSpan="9">
                                            {loading ? 'Betöltés...' : 'Nincs találat'}
                                        </TableCell>
                                    </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </CardContent>
            </Card>
            {doneDialog!==null && 
                <DoneDialog 
                    rowId={doneDialog} 
                    open={true} 
                    closeHandler={()=>setDoneDialog(null)} 
                    onDone={handleInProgress}
                />
            }
            {anchorEl.element!==null && 
                <Popover
                    open={anchorEl.element!==null}
                    anchorEl={anchorEl.element}
                    onClose={()=>setAnchorEl({element:null,text:''})}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <Typography className={classes.typography}>
                        {anchorEl.text}
                    </Typography>
                </Popover>
            }
        </div>   
    )
}

export default ReportedProblems;