//React

import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Core

import {
    Box
} from '@material-ui/core';
//Components

import { 
    Selector, 
    ContentTabPanels 
} from '../../components'
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class TabPanels extends Component {

    state = {
        contentValue: 0,
        error: false,
    }

    componentDidUpdate(prevProps,prevState){
        const { userid } = this.props;
        if(prevProps.userid != userid){
            this.setState({
                contentValue:0
            });
        }
    }

    handleChange = (event, newValue) => {
        this.setState({
            contentValue: newValue
        })
    };

    render() {
        const {value, index, period, lazyData, snackbar, selector, userid, location, currentUser, loggedByUser} = this.props;
        const {contentValue} = this.state;
        
        return (
            <TabPanel
                value={value}
                index={index}>
                <Selector 
                    data={selector} 
                    value={contentValue} 
                    onChange={this.handleChange}
                >
                    <ContentTabPanels 
                        location={location} 
                        period={period} 
                        lazyData={lazyData} 
                        userid={userid} 
                        snackbar={snackbar} 
                        titles={selector} 
                        value={contentValue} 
                        index={contentValue} 
                        currentUser={currentUser}
                        loggedByUser={loggedByUser}
                    />
                </Selector>
            </TabPanel>
        );
    }
}

export default withStyles(style)(TabPanels);
