import theme from 'theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = ({
    doneButton: {
        color: theme.palette.success.main
    },
    backButton: {
        color: theme.palette.error.main
    },
    autocomplete: {
        '& label': {
            fontFamily: 'Nunito'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.8rem'
        }
    }
});

const useStylesForPicker = makeStyles(theme => ({
    root: {},
    picker: {
        '& label': {
            fontFamily: 'Nunito'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '2.0rem'
        }
    }
    
}));

export default {
    useStyles: useStyles,
    useStylesForPicker: useStylesForPicker
};
