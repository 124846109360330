import { makeStyles } from '@material-ui/styles';
import teal from '@material-ui/core/colors/teal';

const useStyles = makeStyles(theme => ({
    root: {
    },
    picker: {
        width: '100%',
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.white,
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
      '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
}));
const useStylesForResultForm = makeStyles(theme => ({
    root: {
    },
    picker: {      
        width: '100%',
        '& label': {
            fontFamily: 'Nunito',
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: theme.palette.appMain.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: teal[50],
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.appMain.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.appMain.light,
            },
        },
        '& .MuiOutlinedInput-notchedOutline > legend > span': {
            paddingRight: '1.2rem'
        }
    },
}));
export default {
    useStyles: useStyles,
    useStylesForResultForm: useStylesForResultForm
}
