//React

import React from 'react';
//Core

import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    Slide, 
    IconButton, 
    Typography,
    TextField
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import MuiButton from '../../../../../theme/overrides/MuiButton';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(style)((props) => {
    const {children, classes, onClose, ...other} = props;
    
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DoneDialog = props => {
    const {closeHandler, open, onDone, rowId} = props;

    const[description,setDescription] = React.useState('');

    return (
        <Dialog TransitionComponent={Transition} onClose={closeHandler} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
                Bejelentett hiba megoldva
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Add meg a probléma megoldásának a menetét, leírását ha szeretnéd. (Nem kötelező)
                </Typography>
                <TextField
                    name='description'
                    value={description}
                    onChange={(event)=>setDescription(event.target.value)}
                    fullWidth
                    multiline
                    rows={6}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <MuiButton size="small" color="secondary" value="Vissza" onClick={closeHandler} />
                <MuiButton size="small" color="main" value="Megoldva" onClick={()=>onDone(rowId,description)} />
            </DialogActions>
        </Dialog>
    )
}

DoneDialog.propTypes = {
    history: PropTypes.object
};

export default DoneDialog;

