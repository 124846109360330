//React

import React from 'react';
//Core

import { TextField, CircularProgress } from '@material-ui/core';
//Lab

import Autocomplete from '@material-ui/lab/Autocomplete';
//Components

import {apiRequest} from './apiRequest';
//Style

import style from './style';


export default function SelectUser(props) {
    const { handleChange, value, disabledUsers, usedClasses, selectedClass, originalUser, selectedMatrix } = props;

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const model = { usedUsers: disabledUsers, selectedClass: selectedClass.id, usedClasses, selectedMatrixId: selectedMatrix, userFilterId: originalUser.userFilterId}

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            apiRequest.getUsers(active,model,setOptions);
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    const classes = style();
    return (
        <Autocomplete
            loadingText="Betöltés..."
            noOptionsText="Nincs találat"
            className={classes.root}
            id="ac-select-user"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disabled={false}
            value={value}
            onChange={(event, newValue) => handleChange(newValue)}
            getOptionLabel={(option) => option.fullName}
            getOptionDisabled={(option) => disabledUsers.includes(option.userId)}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="dense"
                    placeholder="Kereséshez kezdj el gépelni"
                    error={value === null ? true : false}
                    helperText={value === null && "Kötelező választani"}
                    label="Értékelő" 
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
