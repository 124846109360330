import React from 'react';
import clsx from 'clsx';
//Core
import {
    Grid,
    Divider,
    Badge,
    Typography,
    Tooltip,
    Button,
    Popover
} from '@material-ui/core';
//Components
import { MuiOutlinedInput, MuiOutlinedDateSelect } from 'components'; 
import BlockIcon from '@material-ui/icons/Block';
import {enums} from 'stores';
//Style
import style from './style';

const InputTypes = enums.inputTypes;

function getPerformed(item,index,setAnchorEl){
    const classes = style();

    if(item.isSkipped){
        return <div style={{display:'flex', alignItems:'center',justifyContent:'center',height:'100%'}}>
                <Tooltip title="Ok megtekintése">
                    <Button onClick={(event)=>setAnchorEl({element:event.currentTarget,index:index})} className={classes.skipButton} startIcon={<BlockIcon/>} color="warning"  variant="contained" size="small">
                    Kihagyva
                    </Button>
                </Tooltip>
            </div>
    }else if(item.scale.inputTypeId === InputTypes.DATEFIELD){
        return   <MuiOutlinedDateSelect
        margin="dense"
        withBackground={true}
        readOnly={true}
        skipError={true}
        label={item.performed==="nem teljesült" ||  item.performed===null || item.performed===undefined ? "Nem teljesült" : "Teljesített"}
        value={item.performed==="nem teljesült" ||  item.performed===null || item.performed===undefined ? null : item.performed} />
    }else{
        return  <MuiOutlinedInput
        margin="dense"
        withBackground={true}
        readOnly={true}
        inputId={`textfield-performed-${index}`}
        label="Teljesített"
        error={(item.performed === null || item.performed === undefined) ? true : false}
        helperText="Nincs kitöltve"
        endInputAdornment={item.scale.unit}
        defaultValue={item.performed} />
    }
}

export default function GoalsGrid(props) {
    const { data, goalType, edit, goalTypeMatrix } = props;
    const [goals, setGoals] = React.useState(data);
    const [anchorEl, setAnchorEl] = React.useState({element:null,index:null});
    const editIndex = edit != null ? goals.indexOf(edit.base) : -1;
    const classes = style();
    return (goals !== null && goals.length > 0 ?
        <React.Fragment> {goals.map((item, index) => (
            <div key={index} className={index !== editIndex ? classes.root : clsx(classes.root, classes.selectedRow)}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                md={5}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    margin="dense"
                                    multiline={true}
                                    rows={3}
                                    readOnly={true}
                                    id={`textfield-goal-${index}-${goalType.id}`}
                                    label="Feladat"
                                    defaultValue={item.description} />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    margin="dense"
                                    readOnly={true}
                                    id={`textfield-scale-${index}-${goalType.id}`}
                                    label="Mérőszám"
                                    //startInputAdornment={Meroszamok.getIcon(item.scale.scalesForGoalsId)}
                                    defaultValue={item.scale.label} />
                            </Grid>
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                                {goalTypeMatrix.isWeighted ?
                                    <Badge badgeContent={(item.relevance !== null && item.relevance !== undefined) ? `${item.relevance}%` : '0%'} color="primary">
                                        {item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                            <MuiOutlinedDateSelect
                                                margin="dense"
                                                readOnly={true}
                                                label="Elvárt"
                                                value={item.expected} />
                                            : <MuiOutlinedInput
                                                margin="dense"
                                                readOnly={true}
                                                inputId={`textfield-expected-${index}`}
                                                label="Elvárt"
                                                endInputAdornment={item.scale.unit}
                                                defaultValue={item.expected} />}
                                    </Badge>
                                    :
                                    item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                        <MuiOutlinedDateSelect
                                            margin="dense"
                                            readOnly={true}
                                            label="Elvárt"
                                            value={item.expected} />
                                        : <MuiOutlinedInput
                                            margin="dense"
                                            readOnly={true}
                                            inputId={`textfield-expected-${index}`}
                                            label="Elvárt"
                                            endInputAdornment={item.scale.unit}
                                            defaultValue={item.expected} />}

                            </Grid>

                            <Grid
                                item
                                xl={2}
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                              {getPerformed(item,index,setAnchorEl)}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </div>
        ))}
            {anchorEl.element!==null && <Popover
                    id={'reason-popover'}
                    open={Boolean(anchorEl.element)}
                    anchorEl={anchorEl.element}
                    onClose={()=>setAnchorEl({element:null,index:null})}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <Typography className={classes.typography}>{goals[anchorEl.index].reasonOfSkip}</Typography>
                </Popover>}
        </React.Fragment> : <div style={{ textAlign: 'center' }}><Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Nincs létrehozva</Typography></div>)
}
