//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Action

const Actions = {
    Send: 'admin/user/avatar/update',
}

export const apiRequest = {
    upload
};

async function upload(props, setPicture, setRequest, data) {
    const { refreshProfile, refreshTable, onClose } = props;
    await new Api().call.post(`${Actions.Send}`, data).then(handleResponse)
        .then(res => {
            setPicture(null);
            setRequest(false);
            refreshProfile();
            refreshTable();
            onClose();
        }).catch(error => {
            setRequest(false);
            setPicture(null);
            handleError(error);
        });
}