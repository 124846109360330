
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    Save: 'motivation/save',
    SaveAdmin: 'motivation/admin/save',
    GetMotivationPoints: 'motivation/points',
    GetMotivationPointsWithDescription: 'motivation/points-description'
}

export const apiRequest = {
    populateMotivationPoints,
    populateMotivationPointsWithDescription,
    handleSaveChanges
};

async function populateMotivationPoints(parent) {
    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }
    await new Api().call.post(Actions.GetMotivationPoints,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            motivationPoints: res,
            baseMotivationPoints: JSON.parse(JSON.stringify(res)),
            loading: false,
        })
    }).catch(handleError);

}

async function populateMotivationPointsWithDescription(parent) {

    var model = {};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.GetMotivationPointsWithDescription,model)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            motivationPointsWithDescription: res,
        })
    }).catch(handleError);
        

}

async function handleSaveChanges(parent,closed) {

    parent.setState({
        isModified: false
    });

    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    const { motivationPoints } = parent.state;
    const postObjectArray = [];
    motivationPoints.map(main => {
        main.subMotivations.map(sub => {
            sub.elements.map(element => {
                if (element.value != null && element.value != undefined) {
                    postObjectArray.push({ id: element.id, value: String(element.value) })
                } else {
                    element.subElements.map(subElement => {
                        postObjectArray.push({ id: subElement.id, value: (subElement.value !=null && subElement.value != undefined) ? String(subElement.value) : null })
                    })
                }

            })
        })
    });

    var model = { minimalizedMotivationPoints: postObjectArray }
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model, loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

    await new Api().call.post(Actions.Save,model)
    .then(handleResponse)
    .then(res => {
        if (!closed) {
            parent.setState({
                baseMotivationPoints: res.baseData,
                isModified: false
            });
        }
    }).catch(error => {
        NotificationBar('Nincsenek kitöltve a szükséges adatok', 'top', 'right', 2000, "error");
        handleError(error);
        parent.setState({
            isModified: true
        });
    });

}