
import React, { Component } from 'react';
import {Constants} from 'stores';
//Core
import {
    Grid,
    Typography
} from '@material-ui/core';
//Components
import { UsersTable, Pagination,UsersToolbar } from './components';
import { apiRequest } from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';

class UsersList extends Component{

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            page:1,
            filterUsers: [],
            filter: {
                users: [],
            },
            loading: {
                users: true,
                filterUsers: true
            },
            total: null,
            currentPage:null,
            step: null,
            pages: null,
            selected:null,
            type:null,
            readOnly:true,
        }
    }

    componentDidMount(){
        const {page} = this.state;
        apiRequest.populateUsers(this,page);
        apiRequest.populateUsersForFilter(this);
        Constants.setcontentLoading(false);
    }

    handleSelect = (value,type,readOnly) =>{
        this.setState({
            selected:value,
            type,
            readOnly
        });
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                users: true
            }
        }));
        apiRequest.populateUsers(this,nextPage);
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                users: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateUsers(this,1));
    }

    refreshTable = () =>{
        const {page} = this.state;
        this.setState({
            loading: {
                users: true,
                filterUsers: true
            }
        });
        apiRequest.populateUsers(this,page);
        apiRequest.populateUsersForFilter(this);
    }

    render(){
        const { classes } = this.props;
        const {users,loading,filterUsers,readOnly,filter,total,currentPage,selected,step,pages,type} = this.state;
        return( 
        <div className={classes.root}>
            <Typography className={classes.title}>Kilépők listája</Typography>
            <Grid container spacing={2}>
             <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                {loading.users === false && 
                    <UsersToolbar 
                        users={filterUsers}  
                        filter={filter} 
                        onChange={this.handleFilterChanges} 
                    />
                }
                <div className={classes.content}>
                    <UsersTable
                        onRefresh={this.refreshTable}
                        loading={loading.users} 
                        users={users} 
                        onSelect={this.handleSelect}
                    />
                </div>
                <Pagination 
                    onPageChanges={this.handlePageChanges} 
                    currentPage={currentPage} 
                    step={step} 
                    total={total} 
                    pages={pages} 
                />
             </Grid>
            </Grid>
           
        </div>)
    }
}



export default withStyles(style)(UsersList);