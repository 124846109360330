//React

import React from 'react';
//Core

import {
    Grid,
    CardHeader,
    Typography,
    Divider,
    Button,
    ButtonGroup
} from '@material-ui/core';
//Icons

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
//Components

import { 
    SelectUser, 
    SetPercent, 
    AlertDialog 
} from './components';
//Api

import {apiRequest} from './apiRequest';
//Style

import style from './style';


export default function ModifyUser(props) {
    const {
        evaluators,
        handleEvaluators,
        selectedClass,
        selectedUser,
        selectedPercent,
        optionalEvaluators,
        classifications,
        handleSetModifySelector,
        snackbar,
        validMatrix,
        selectedTabUser,
        selectedMatrix
    } = props;

    var disabledUsers = evaluators.filter(item => item.userId != selectedUser.userId).map(item => item.userId);
    const usedClasses = evaluators.map(item => item.classification.id);
    disabledUsers.push(selectedTabUser);

    const [classification, setClassification] = React.useState(selectedClass);
    const [user, setUser] = React.useState(selectedUser);
    const [percent, setPercent] = React.useState(selectedPercent);
    const [maxPercent, setMaxPercent] = React.useState(100);
    const [originalUser] = React.useState(selectedUser);
    const [isOverTheLimit, setIsOverTheLimit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [overTheLimitUser, setOverTheLimitUser] = React.useState(null);

    const handleClassChange = (value) => {
        if (value != null) {
            if (value.defaultValue != null) {
                handlePercentChange(value.defaultValue);
            }
            else {
                handlePercentChange(selectedPercent);
            }
        }
        setClassification(value);
    }

    React.useEffect(() => {
        /*console.log(validMatrix);
        console.log(selectedMatrix);
        if (validMatrix !== null && validMatrix !== undefined && validMatrix!=='' ) {*/
            apiRequest.getMaxPercent(classification.id,validMatrix,selectedMatrix,setMaxPercent);
        //}
    }, []);

    const handlePercentChange = (value) => {
        if(value!==null && value!==undefined && value!==''){
            setPercent(value*1);
        }else{
            setPercent(value);
        }
    }

    const handleUserChange = (value) => {
        if (value !== null && value !== 'null') {
            apiRequest.checkIsOverTheLimit(value,setUser,setOverTheLimitUser,setOpen,setIsOverTheLimit);
        } else {
            setUser(value);
        }      
    }

    const handleDoneModify=()=> {
        let model = {
            evaluators: evaluators,
            userId: user.userId,
            originalUserId: originalUser.userId,
            avatar: 'loading',
            userFilterId: user.userFilterId,
            fullName: user.fullName,
            classification: classification,
            percent: percent
        }

        apiRequest.modify(model,handleEvaluators);
        handleSetModifySelector(null);
    }
    
    const getDisabledClasses = () => {
        let classIds = [];
        let classWithQuantity = [];
        let disabledIds = [];
        evaluators.map(item => {
            if (!classIds.includes(item.classification.id)) {
                classIds.push(item.classification.id);
            }           
        });
        classIds.map(classId => {
            let counter = 0;
            let allowed = 0;
            evaluators.map(item => {
                if (item.classification.id == classId) {
                    counter++;
                    allowed = item.classification.maxQuantity;
                }
              
            });
            classWithQuantity.push({ id: classId, quantity: counter, maxQuantity: allowed})
        });
        classWithQuantity.map(item => {
            if (item.id === selectedClass){
               if ((item.quantity-1) >= item.maxQuantity) {
                disabledIds.push(item.id);
                }
            }else{
                if (item.quantity >= item.maxQuantity) {
                disabledIds.push(item.id);
                }
            }

        });
        return disabledIds;
    }

    const validateModify = () =>{
        let result = false;
        if (user != null && classification != null && (percent > 0 && percent <= maxPercent && percent % 1 === 0)) {
            result = true;
        }
        return result;
    }

    const classes = style();

    return (
        <div>
            <CardHeader
                action=
                {               
                    <ButtonGroup>
                        <Button
                            onClick={handleDoneModify}
                            className={classes.doneButton}
                            disabled={!validateModify()} 
                            size="small"
                            endIcon={<DoneIcon/>}
                        >
                            Módosítás
                        </Button>
                        <Button
                            onClick={() => handleSetModifySelector(null)}
                            className={classes.cancelButton}  
                            size="small"
                            endIcon={<CloseIcon/>}
                        >
                            Mégsem
                        </Button>
                    </ButtonGroup>
                }
                title={
                    <Typography className={classes.title}>
                        Értékelő módosítása
                    </Typography>
                }
            />
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Divider className={classes.dividerBottom} />
                </Grid>
            </Grid>
            <div className={classes.gridRoot}>      
                <Grid container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <SelectUser 
                            originalUser={originalUser} 
                            selectedMatrix={selectedMatrix} 
                            selectedClass={selectedClass} 
                            usedClasses={usedClasses} 
                            snackbar={snackbar} 
                            disabledUsers={disabledUsers} 
                            value={user} 
                            users={optionalEvaluators} 
                            handleChange={handleUserChange} 
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SetPercent 
                            maxPercent={maxPercent} 
                            classification={classification} 
                            value={percent} 
                            handleChange={handlePercentChange} 
                        />
                    </Grid>
                </Grid>        
            </div>
            {overTheLimitUser !== null && 
                <AlertDialog 
                    user={overTheLimitUser} 
                    open={open} 
                    onClose={() => setOpen(false)} 
                />
            }
        </div>
    )
}
