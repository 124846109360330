import theme from 'theme';

const useStyles = ({
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        marginLeft: 'auto',
        height: 100,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    editIcon:{
        color:theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.dark
        },
    },
    deleteIcon:{
        color:theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark
        },
    }
});

export default useStyles;
