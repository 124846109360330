//React

import React from 'react';
import { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import {
    CompGroupTree,
    CompetencesTable
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Style

import { withStyles } from '@material-ui/styles';
import style from './style';

class Competences extends Component{

    constructor(props){
        super(props)

        this.state={
            compGroups:[]
        };
    }

    componentDidMount(){
        apiRequest.populateGroups(this);
    }

    render(){
        const {classes} = this.props;

        return(
            <div className={classes.root}>
                <Typography className={classes.title}>Kompetenciák</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.subTitle}>Csoport összeállítások</Typography>
                        <CompGroupTree/>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.subTitle}>Kompetenciák</Typography>
                        <CompetencesTable/>
                    </Grid>                
                </Grid>
            </div>
        )
    }
}

export default withStyles(style)(Competences);