import React, { Component } from 'react';
//Router
import { Router } from 'react-router-dom';
import Routes from './Routes';
//Charts
import { Chart } from 'react-chartjs-2';
import { chartjs } from './helpers';
//Validator
import validate from 'validate.js';
import validators from './common/validators';

import { createBrowserHistory } from 'history';
import { authenticationService } from 'services';

import { withSnackbar } from 'notistack';
import {Constants,UserStore} from 'stores';

import { Backdrop, CircularProgress } from '@material-ui/core';
//Styles
import theme from 'theme';


const browserHistory = createBrowserHistory();
//var checkTokenInterval = null;
var warningShowed = false;

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

validate.validators = {
    ...validate.validators,
    ...validators
};

class Content extends Component {

    constructor(props){
        super(props);
        this.state={
          currentUser:null,
          loggedInStatus:null,
          notificationBar:true,
          request:UserStore.getRequestValue(),
          contentLoading:false,
        }
    
        this.refreshToken = this.refreshToken.bind(this);
        Constants.setSnackbar(this.props.enqueueSnackbar);
      }

      
    componentDidMount() {
      
       //Subscribe request value ('true','false')
        UserStore.getRequest().subscribe(res => this.setState({
          request: res,
        }));
        //Set browserhistory in to the constants 
        Constants.setHistory(browserHistory);
        //Subscribe user value 
        UserStore.getUser().subscribe(res => this.setState({
          currentUser: res,
        }));
        //Subscribe logged in status value ('LOGGED_IN, NOT_LOGGED_IN')
        UserStore.getLoggedInStatus().subscribe(res => this.setState({
          loggedInStatus: res,
        }));
        //Subscribe logged in status value ('LOGGED_IN, NOT_LOGGED_IN')
        Constants.getcontentLoading().subscribe(res => this.setState({
          contentLoading: res,
        }));

    }

    componentDidUpdate(prevProps,prevState){
        const{currentUser} = this.state;
          //User state changes then reset the interval(when token is changed)
          if(prevState.currentUser!=currentUser){
              if(currentUser!==null && currentUser!==undefined){
                //Refresh token in every ..... milliseconds
                this.interval = setTimeout(this.refreshToken, Constants.getTokenExpired());
              }
        }
    }
      

        
    componentWillUnmount () {
        //Clear the interval 
        clearTimeout(this.interval);
        //Clear the subscribes
        this.state={
            currentUser:null,
            loggedInStatus:null
        }
    }

    //Handle refresh token api calls
    refreshToken(){
        authenticationService.refreshToken();
    }

    render()
    {
      const {contentLoading,request} = this.state;
        return (<React.Fragment>
           <Backdrop
                style={{
                    zIndex: theme.zIndex.drawer + 1,
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent ',

                }}
                open={contentLoading || request}>
                <CircularProgress color="inherit" />
            </Backdrop> 
           <Router history={browserHistory}>
                <Routes />
            </Router>
            </React.Fragment>
        );
    }
}
export default withSnackbar(Content);
