import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { Button } from '@material-ui/core';
//Icons
import UpdateIcon from '@material-ui/icons/Update';
//Style
import { withStyles } from '@material-ui/styles';
import style from './style';

class SessionTab extends Component {

    render() {
        const { remainingTime, onClick, snackbar, className, classes, ...rest } = this.props;
        return (
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                    <div style={{ textAlign: 'center', fontSize: '0.9rem', fontWeight: 'bold' }}>Munkamenet vége:</div>
                    <div style={{ textAlign: 'center', fontSize: '0.9rem' }}>{remainingTime}</div>
                    <div className={classes.actions}>
                        <Button
                            size="small"
                            className={classes.buttons}
                            startIcon={<UpdateIcon />}
                            color="primary"
                            component="a"
                            variant="contained"
                            onClick={onClick}
                        >
                            Meghosszabítás
        </Button>
                    </div>


            </div>
        );
    }
};

SessionTab.propTypes = {
    className: PropTypes.string
};

export default withStyles(style)(SessionTab);
