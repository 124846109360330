import React from 'react';
//Core
import {
    Avatar,
    AppBar,
    Tabs,
    Tab,
    Tooltip,
    Menu,
    MenuItem,
    Checkbox
} from '@material-ui/core';
//Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//Components
import { SelectedUser } from './components';
//Style
import style from './style';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


function UserSelectorWithCheck(props) {
    const { data, onChange, value, loading, children, notAllowedUsers, selectedProjectsForCopy, selectedUsersForCopy } = props;
    const { onSelectUserForCopy } = props;
    const classes = style();
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    scrollButtons="on"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                   
                    {data.map((item, index) => (
                        <Tab
                            label={<div>{item.fullName}<Checkbox disabled={loading || selectedProjectsForCopy.length < 1 || notAllowedUsers.includes(item.id.toString()) || value === index} checked={selectedUsersForCopy.includes(item.id)} onChange={(event) => onSelectUserForCopy(event, item.id)} color="primary" className={classes.menuIcon} /></div>} key={index} {...a11yProps(index)} className={classes.tabs} icon={<Tooltip placement="top" title={item.fullName}>
                            <Avatar                        
                            className={classes.avatar}
                                src={(item.avatar !== null && item.avatar !== undefined) ? item.avatar : ''}
                                /></Tooltip>} {...a11yProps(index)} />
                    ))} 
                </Tabs>
            </AppBar>
            {children}
            
        </div>
    );
}
export default UserSelectorWithCheck;
