//React

import React from 'react';
import clsx from 'clsx';
//Core

import {
    Grid,
    Divider,
    Badge,
    Typography
} from '@material-ui/core';
//Components

import {
    MuiOutlinedInput,
    MuiOutlinedDateSelect
} from 'components';
//Helpers

import { enums } from 'stores';
//Style

import style from './style';


const InputTypes = enums.inputTypes;

export default function GoalsGrid(props) {
    const { data, goalType, edit, goalTypeMatrix } = props;

    const [goals, setGoals] = React.useState(data);
    const editIndex = edit != null ? goals.indexOf(edit.base) : -1;
    const classes = style();

    return (
        goals !== null && goals.length > 0 ?
            goals.map((item, index) => (
                <div key={index} className={index !== editIndex ? classes.root : clsx(classes.root, classes.selectedRow)}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xl={1} lg={1} md={1} sm={false} xs={false} />
                                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <MuiOutlinedInput
                                        margin="dense"
                                        multiline={true}
                                        rows={3}
                                        readOnly={true}
                                        id={`textfield-goal-${index}-${goalType.id}`}
                                        label="Feladat"
                                        defaultValue={item.description}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <MuiOutlinedInput
                                        margin="dense"
                                        readOnly={true}
                                        id={`textfield-scale-${index}-${goalType.id}`}
                                        label="Mérőszám"
                                        defaultValue={item.scale.label}
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                    {goalTypeMatrix.isWeighted ?
                                        <Badge badgeContent={(item.relevance !== null && item.relevance !== undefined) ? `${item.relevance}%` : '0%'} color="primary">
                                            {item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                                <MuiOutlinedDateSelect
                                                    margin="dense"
                                                    withBackground={true}
                                                    readOnly={true}
                                                    label="Elvárt"
                                                    value={item.expected}
                                                /> :
                                                <MuiOutlinedInput
                                                    margin="dense"
                                                    withBackground={true}
                                                    readOnly={true}
                                                    inputId={`textfield-expected-${index}`}
                                                    label="Elvárt"
                                                    endInputAdornment={item.scale.unit}
                                                    defaultValue={item.expected}
                                                />
                                            }
                                        </Badge> :
                                        item.scale.inputTypeId === InputTypes.DATEFIELD ?
                                            <MuiOutlinedDateSelect
                                                margin="dense"
                                                withBackground={true}
                                                readOnly={true}
                                                label="Elvárt"
                                                value={item.expected}
                                            /> :
                                            <MuiOutlinedInput
                                                margin="dense"
                                                withBackground={true}
                                                readOnly={true}
                                                inputId={`textfield-expected-${index}`}
                                                label="Elvárt"
                                                endInputAdornment={item.scale.unit}
                                                defaultValue={item.expected}
                                            />
                                    }
                                </Grid>
                                <Grid item xl={1} lg={1} md={1} sm={false} xs={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                </div>
            )) :
            <div style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>
                    Nincs létrehozva
                </Typography>
            </div>
    )
}
