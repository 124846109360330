
//React

import React from 'react';
//Api

import { 
    Api, 
    handleResponse, 
    handleError 
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar 
} from 'components';
//Actions

const Actions = {
    getDatas: 'probaido/leader/forms/first',
    save: 'probaido/leader/forms/first/save',
    download: 'probaido/leader/forms/first/download'
}

export const apiRequest = {
    populateDatas,
    save,
    download
};

async function populateDatas(setData, setLoading, user) {

    await new Api().call.get(`${Actions.getDatas}/${user}`)
        .then(handleResponse)
        .then(res => {
            setData(res);
            setLoading(false);
        }).catch(handleError);

}

async function download(selected, setLoading) {
    NotificationBar('File letöltése...', 'top', 'right', 2000, null, <Progress />);
    await new Api().call.post(Actions.download, selected)
        .then(handleResponse)
        .then(res => {
            var sampleArr = base64ToArrayBuffer(res.model.file);
            var blob = new Blob([sampleArr], { type: "application/octet-stream" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = res.model.fileName;
            link.download = fileName + res.model.fileType;
            link.click();
            setLoading(false);
        }).catch(handleError);

}

async function save(data, selected) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { data, userId: selected.id };
    await new Api().call.post(Actions.save, model)
        .then(handleResponse)
        .then(res => {
        }).catch(handleError);

}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
