//React

import React, { Component } from 'react';
//Core

import {
    IconButton,
    Drawer,
    AppBar,
    Toolbar
} from '@material-ui/core';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style'
//Components

import {
    AccountProfile,
    PositionsTable,
    AddNewDialog
} from './components';
//Helpers

import { Constants } from 'stores';
//Api

import { apiRequest } from './apiRequest';
//Icons

import CloseIcon from '@material-ui/icons/Close';


class EditUser extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(true);
        apiRequest.getUser(this);

        this.state = {
            user: null,
            lastValidFrom: null,
            isModifable: false,
            approved: false,
            anchorEl: null,
            warning: false,
            positions: [],
            basePositions: [],
            loading: {
                user: true
            },
            addNew: false,
        }

    }

    //#Lifecycle methods
    componentDidUpdate(prevProps, prevState) {
        const { loading } = this.state;
        const { handleContentLoading } = this.props;
        if (prevState.loading != loading) {
            if (loading.user === false) {
                Constants.setcontentLoading(false);
            }
        }
    }

    //#EndLifecycle methods

    handleOpenWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            anchorEl: target,
            warning: !prevState.warning
        }))
    };

    handleCloseWarning = (event) => {
        const target = event.currentTarget;
        this.setState(prevState => ({
            anchorEl: null,
            warning: false
        }))
    };


    handleEdit = (position) => {
        const positions = [...this.state.positions];
        const index = positions.indexOf(position);
        positions[index] = { ...position };
        positions[index].disabled = false;
        this.setState({ positions });
    }

    handleDelete = (position) => {
        apiRequest.deletePosition(this, position);
    }

    handleBack = (position) => {
        const positions = [...this.state.basePositions];
        this.setState({ positions });
    }

    handleDone = (position) => {
        const positions = [...this.state.positions];
        const index = positions.indexOf(position);
        positions[index] = { ...position };
        positions[index].disabled = true;
        this.setState({ positions });
        apiRequest.savePosition(this, position);
    }

    handleValidFrom = (position, date) => {
        let newDate = null;
        if (date !== null && date !== undefined) {
            newDate = `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;
        }
        const positions = [...this.state.positions];
        const index = positions.indexOf(position);
        positions[index] = { ...position };
        positions[index].validFrom = newDate;
        this.setState({ positions });
    }

    handleClose = () => {
        this.setState({
            addNew: false
        });
    }

    handleOpen = () => {
        this.setState({
            addNew: true
        });
    }

    handleSave = () => {
        Constants.setcontentLoading(true);
        this.setState(prevState => ({
            addNew: false,
            loading: {
                ...prevState.loading,
                user: true
            },
        }));
        apiRequest.getUser(this);
    }

    handleApproved = () => {
        this.setState({
            approved: true,
            anchorEl: null,
            warning: false
        })
    }

    render() {
        const {user, addNew, positions, approved, isModifable, lastValidFrom, loading} = this.state;
        const {classes, onEdit, onClose, refresh} = this.props;

        return (loading.user === false &&
            <Drawer anchor={'right'} open={true} onClose={onClose}>
                <AppBar className={classes.appBar} position="static">
                    <Toolbar>
                        <div style={{ flexGrow: 1 }}></div>
                        <IconButton className={classes.closeIcon} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <div className={classes.drawer}>
                        <AccountProfile user={user} />
                        <PositionsTable
                            approved={approved}
                            isModifable={isModifable}
                            lastValidFrom={lastValidFrom}
                            onDelete={this.handleDelete}
                            onOpen={this.handleOpen}
                            onValidFromChange={this.handleValidFrom}
                            onBack={this.handleBack}
                            onEdit={this.handleEdit}
                            onDone={this.handleDone}
                            positions={positions}
                        />
                    </div>
                </AppBar>
                {addNew === true &&
                    <AddNewDialog
                        refresh={refresh}
                        lastValidFrom={lastValidFrom}
                        onSave={this.handleSave}
                        user={user}
                        onClose={this.handleClose}
                        open={addNew}
                    />
                }
            </Drawer>
        );
    }
};

export default withStyles(style)(EditUser);
