import React, { Component } from 'react';
//Core
import {
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    Paper,
    Avatar,
    Tooltip,
    TableFooter,
    Badge,
    Chip,
    CircularProgress
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
//Components
import { apiRequest } from './apiRequest';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';
import theme from 'theme';


const StyledTableRow = style.StyledTableRow;


class ExtendedRatingCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            competences: [],
            users: [],
            results: [],
            resultAvg: null,
            loading: true
        }
    }


     //#Lifecycle methods
    componentDidMount() {
        apiRequest.populateCompetences(this);
    }
    //#EndLifecycle methods

    render() {
        const { classes } = this.props;
        const { competences, users, results, resultAvg, loading } = this.state;
        return (loading === true ? <div className={classes.circularProgress}><CircularProgress color="primary" /></div>: 
        <TableContainer component={Paper}>
            <PerfectScrollbar>
                    <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell  />
                                <TableCell />
                                {users.map(user =>
                                    <TableCell key={user.id} align="center" style={{ fontSize: '1.0rem' }}>
                                        <Tooltip title={user.fullName}><Badge badgeContent={`${user.percent}%`} color="primary">
                                            <Avatar
                                                className={classes.avatar}
                                                src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''} 
                                            />
                                        </Badge></Tooltip>
                                    </TableCell>
                                    )}                           
                            <TableCell />
                        </StyledTableRow>
                    </TableHead>
                        <TableBody>
                            {competences.map(group => <React.Fragment key={group.id}><StyledTableRow>
                                <TableCell padding="none" align="center" style={{ fontSize: '1.2rem', fontWeight: 'bold' }} rowSpan={group.competences.length+1}>{group.label}</TableCell>
                                {/*<TableCell align="center"><Chip style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white }} label="4.7" /></TableCell>*/}
                            </StyledTableRow>
                                {group.competences.map((item,index) =>
                                    <StyledTableRow key={item.id}>
                                        <TableCell padding="none" align="center">{item.label}</TableCell>
                                        {users.map(user =>
                                            <TableCell padding="none" key={user.id} align="center">{                                               
                                                results.filter(result => result.competenceId === item.id && result.userId === user.id).length>0 && 
                                                results.filter(result => result.competenceId === item.id && result.userId === user.id)[0].value}</TableCell>
                                            )}
                                        <TableCell padding="none" align="center"><Chip style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white }} label={item.avg} /></TableCell>
                                    </StyledTableRow>
                                    )}                    
                            </React.Fragment>)}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow >
                            <TableCell />
                                <TableCell />
                                {users.map(user =>
                                    <TableCell key={user.id} align="center"><Chip style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white }} label={user.avg} /></TableCell>
                                    )}
                                <TableCell align="center"><Chip style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white }} label={resultAvg} /></TableCell>
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </PerfectScrollbar>
        </TableContainer>
    );
    }

}
export default withStyles(style.useStyles)(ExtendedRatingCard);
