import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
//Core
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Tooltip,
    IconButton,
    Divider
} from '@material-ui/core';
import { Player } from 'video-react';
//Icons
import CloseIcon from '@material-ui/icons/Close';
//Style
import style from './style';
import { withStyles } from '@material-ui/styles';


class Tutorial extends Component {
   
    render() {
        const { classes } = this.props;
        return (<div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={1}
                    xs={12}
                />
                <Grid
                    item
                    md={10}
                    xs={12}
                >
                    <Card>
                        <CardHeader
                            className={classes.header}
                            title="Kitöltési útmutató:"
                            action={
                                <Tooltip title="Vissza">
                                    <RouterLink to="/home">
                                        <IconButton size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    </RouterLink>
                                </Tooltip>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Player
                                playsInline
                                poster="/images/video_poster.png"
                                src=""
                            />
                        </CardContent>
                        <Divider />
                    </Card>
                </Grid>
                <Grid
                    item
                    md={1}
                    xs={12}
                >
                </Grid>
            </Grid>
        </div>)
    }
}
export default withStyles(style)(Tutorial)
