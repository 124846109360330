
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetScalesForSelect:'goal/new/scales'
}


export const apiRequest = {
    populateOptions
};

async function populateOptions(parent) {
    const { goalType } = parent.props;

    var model = {goalTypeId:goalType.id};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetScalesForSelect,model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                options: res
            })
        }).catch(handleError);
  
}