//React

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Link as RouterLink,
  withRouter
} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
//Components

import { authenticationService } from 'services';
import {
  ForgotPwd,
  HelperVideo
} from './components';
//Core

import {
  TextField,
  CircularProgress,
  Button,
  Typography,
  Fade,
  Paper,
  Popper,
  ClickAwayListener
} from '@material-ui/core';
//Icons

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//Style

import style from './style';
//Api

import { apiRequest } from './apiRequest';
//Logos

import projectLogo from '../../assets/images/stock-logos/logo-primary.png';
import ter_img from 'assets/images/ter_login_box_lg.png';
import probaido_img from 'assets/images/probaido_login_box_lg.png';
import exit_img from 'assets/images/exit_login_box_lg.png';
import login_img from 'assets/images/login.png';


const schema = {
  userName: {
    presence: { allowEmpty: false, message: 'kötelező' },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'kötelező' },
    length: {
      maximum: 128
    }
  }
};

const SignIn = props => {
  const { history, snackbar } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: { userName: '', password: '' },
    touched: {},
    errors: {}
  });

  const [loginInProgress, setLoginInProgress] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [helperVideo, setHelperVideo] = React.useState(false);
  const [systems, setSystems] = React.useState(['TER']);
  const [activeSystem, setActiveSystem] = React.useState('TER');
  const [loading, setLoading] = React.useState(false);
  const [pwdDialog, setPwdDialog] = React.useState(false);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: { userName: document.getElementById("userName").value, password: '' },
    }));
  }, []);

  useEffect(() => {

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    if (formState.values.userName !== '' && formState.values.userName !== undefined && formState.values.userName !== null) {
      apiRequest.getSystems(formState.values.userName, setSystems, setLoading);
    }

  }, [formState.values.userName])

  useEffect(() => {
    setActiveSystem('TER');
  }, [systems])

  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  }

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };



  async function handleSignIn(event) {
    event.preventDefault();
    setLoginInProgress(activeSystem);
    try {
      const response = await authenticationService.login(formState.values.userName, formState.values.password, activeSystem);
    }
    catch (error) {
      setLoginInProgress(null);
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const classes = style();

  const loginImg = () => {
    if (activeSystem === 'TER') {
      return ter_img;
    } else if (activeSystem === 'PROBAIDO') {
      return probaido_img;
    } else if (activeSystem === 'EXIT') {
      return exit_img;
    } else {
      return login_img;
    }
  }

  return (
    <div className={classes.loginRoot}>
      {
        /*<div className={classes.loginHeaderLogoContainer}>
            <img className={classes.loginHeaderLogoContainerImg} alt="Logo" src={companyLogo}/>
        </div>*/
      }
      <div className={classes.loginAlertWrapper}>
        <Paper className={classes.loginContainer}>
          {loading &&
            <div className={classes.backdropLoading}>
              <CircularProgress color='white' />
            </div>
          }
          <form onSubmit={handleSignIn} className={classes.loginForm}>
            <div className={classes.loginFormLogosContainer}>
              <img className={classes.loginFormLogosContainerImg} alt="Logo" src={projectLogo} />
            </div>
            <div className={classes.systemsContainer}>
              {systems.includes('PROBAIDO') &&
                <Button
                  style={{ minWidth: '6rem' }}
                  size='small'
                  variant='contained'
                  color={activeSystem === 'PROBAIDO' ? 'primary' : 'secondary'}
                  onClick={() => setActiveSystem('PROBAIDO')}
                >
                  PRÓBAIDŐ
                </Button>
              }
              {systems.includes('TER') &&
                <Button
                  style={{ minWidth: '6rem' }}
                  size='small'
                  variant='contained'
                  color={activeSystem === 'TER' ? 'primary' : 'secondary'}
                  onClick={() => setActiveSystem('TER')}
                >
                  TÉR
                </Button>
              }
              {systems.includes('EXIT') &&
                <Button
                  style={{ minWidth: '6rem' }}
                  size='small'
                  variant='contained'
                  color={activeSystem === 'EXIT' ? 'primary' : 'secondary'}
                  onClick={() => setActiveSystem('EXIT')}
                >
                  EXIT
                </Button>
              }
            </div>
            <p className={classes.loginFormTitle}>
              Bejelentkezés
            </p>
            <div className={classes.loginFormInputs}>
              <TextField
                variant="outlined"
                margin="dense"
                className={classes.textField}
                error={hasError('userName')}
                helperText={hasError('userName') ? 'Felhasználónév megadása kötelező' : null}
                label="Felhasználónév"
                name="userName"
                id="userName"
                onChange={handleChange}
                type="text"
                fullWidth
                value={formState.values.userName || ''}
              />
              <TextField
                variant="outlined"
                margin="dense"
                className={classes.textField}
                error={hasError('password')}
                helperText={hasError('password') ? 'Jelszó megadása kötelező' : null}
                label="Jelszó"
                name="password"
                onChange={handleChange}
                type="password"
                fullWidth
                value={formState.values.password || ''}
              />
              <Button
                fullWidth
                aria-label="Bejelentkezés"
                variant="contained"
                disabled={loginInProgress || !formState.isValid}
                type="submit"
                color='primary'
                className='end-iconed login-button'
                endIcon={!loginInProgress && <ExitToAppIcon fontSize='small' />}
              >
                <span className='loading-state end-iconed-text'>
                  Bejelentkezés
                  {loginInProgress && <CircularProgress size="1rem" />}
                </span>
              </Button>
              <div className={classes.helpButtonsContainer}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Button
                    onClick={handleClick}
                    variant='contained'
                    size='small'
                    color="primary"
                  >
                    Segítség a bejelentkezéshez
                  </Button>
                </ClickAwayListener>
                <Popper open={open} anchorEl={anchorEl} placement='bottom' style={{ zIndex: 100 }} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className={classes.helperPaper}>
                        <Typography className={classes.helperText}><b>Felhasználónév:</b></Typography>
                        <Typography className={classes.helperText}>A cégés email-cím pl.: teszt.felhasznalo@merkbau.hu</Typography>
                        <Typography className={classes.helperText}>Domain név (e-mail cím '@' előtti része) pl.: teszt.felhasznalo</Typography>
                        <Typography className={classes.helperText}>Felhasználó által TÉR-ben megadott felhasználónév</Typography>
                        <Typography className={classes.helperText}><b>Jelszó:</b></Typography>
                        <Typography className={classes.helperText}>A cégés email-címhez tartozó jelszó.</Typography>
                        <Typography className={classes.helperText}>A cégés számítógépbe történő bejelentkezéshez használt.</Typography>
                        <div style={{ margin: '1rem', textAlign: 'center' }}>
                          <Button onClick={() => setHelperVideo(true)} size='small' color='primary' variant='contained'>
                            Videó megtekintése
                          </Button>
                        </div>

                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <HelperVideo open={helperVideo} closeHandler={() => setHelperVideo(false)} />
                <Button
                  onClick={() => setPwdDialog(true)}
                  variant='contained'
                  size='small'
                  color="primary"
                >
                  Elfelejtett jelszó...
                </Button>
              </div>
            </div>
          </form>
          <img
            alt="login_bg"
            className={classes.loginContainerImg}
            src={loginImg()}
          />
          {pwdDialog &&
            <ForgotPwd
              snackbar={snackbar}
              open={pwdDialog}
              closeHandler={() => setPwdDialog(false)}
            />
          }
        </Paper>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);