import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0),       
        fontFamily: 'Nunito'
    },
    main: {
        backgroundColor: theme.palette.appMain.main,
        '&:hover': {
            backgroundColor: theme.palette.appMain.dark,
        },
    },
    secondary: {
        backgroundColor: theme.palette.appSecondary.main,
        '&:hover': {
            backgroundColor: theme.palette.appSecondary.dark,
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    info: {
        backgroundColor: theme.palette.info.main,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    }
}));

export default function MuiButton(props) {
    const classes = useStyles();
    const color = classes[props.color];
    return (
        <Button
            className={classes.root, color}
            color="primary"
            type="submit"
            variant="contained"
            size={props.size}
            disabled={props.disabled}
            onClick={props.onClick}
        >   {props.value}
                </Button>);
}
