import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
//Core
import { Divider, Drawer,Typography,Select,Tooltip, IconButton } from '@material-ui/core';
//Components
import { Profile, SidebarNav, HelperPlan,LoginByDialog } from './components';
import Footer from '../Footer';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { authenticationService } from 'services';
import { UserStore,Constants } from 'stores';
//Style
import { withStyles } from '@material-ui/styles';
import style from './style';
//Icons
import PeopleIcon from '@material-ui/icons/People';

var subs = null;


class Sidebar extends Component {

    state = {
        remainingTime: null,
        profile:UserStore.getProfileValue(),
        currentUser:UserStore.getUserValue(),
    }

    componentDidMount() {
        subs = authenticationService.remainingTime.subscribe(res => this.setState({
            remainingTime: res,
        }));
        UserStore.getProfile().subscribe(res => this.setState({
            profile: res,
        }));
          //Subscribe user value 
        UserStore.getUser().subscribe(res => this.setState({
            currentUser: res,
        }));
    }

    componentWillUnmount() {
        subs.unsubscribe();
    }



render(){

    const { profile,currentUser } = this.state;
    const { snackbar, classes, open, pathName, variant, onClose, onLogoutDialogOpen, className, ...rest } = this.props;
    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <PerfectScrollbar>
                <div
                    {...rest}
                    className={clsx(classes.root, className)}
                >
                    <Profile profile={profile} onLogoutDialogOpen={onLogoutDialogOpen} />
                    <Divider className={classes.divider} />
                    <SidebarNav
                        roles={currentUser.roles}
                        pathName={pathName}
                        className={classes.nav}
                    />
                     <Divider className={classes.divider} />
                    <HelperPlan onLogoutDialogOpen={onLogoutDialogOpen} snackbar={snackbar} />
                    <Footer />
                </div>
            </PerfectScrollbar>
        </Drawer>
    );
}
   
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default withStyles(style)(Sidebar);
