import React, { Component } from 'react';
//Core
import {
    Dialog,
    Divider,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
//Components
import { apiRequest } from './apiRequest';
//Icons
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

//Style
import style from './style';
import { withStyles } from '@material-ui/styles';
import theme from 'theme';

class AddNewDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            label: '',
            valid:false
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { label } = this.state;
        if (prevState.label != label) {
            var doc = document.getElementById("add-new-form");
            if (doc !== null && doc !== undefined) {
                if (doc.querySelectorAll(".Mui-error").length > 0) {
                    this.setState({
                        valid: false
                    });
                } else {
                    this.setState({
                        valid: true
                    });
                }
            } else {
                this.setState({
                    valid: false
                });
            }
        }
    }

    validateFields = (value) =>{
        if(value===null || value===undefined || value===''){
            return false;
        }
        return true;
    }


    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });       
    }

    handleSave = () => {
        const { label } = this.state;
        const model = { name:label,isValid:true };
        apiRequest.saveChanges(this,model);
    }
    


    render() {
        const { onClose, open, classes, } = this.props;
        const { label, valid} = this.state;
        return (
            <Dialog  open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ backgroundColor: theme.palette.appMain.main }} id="alert-dialog-title">
                    {<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: theme.palette.white, fontWeight: 'bold', fontSize: '1.0rem', paddingRight: theme.spacing(1) }}>
                        Új email csoport létrehozása
                    </Typography>
                    </div>}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div id="add-new-form">
                    <Grid spacing={2} container>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}>
                              <TextField
                                required
                                error={!this.validateFields(label)}
                                helperText={!this.validateFields(label) && 'Kötelező megadni'}
                                onChange={this.onChange} 
                                fullWidth
                                name="label"
                                value={label}
                                label="Megnevezés"
                              />
                        </Grid>                                             
                    </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                   <div>
                            <Tooltip title="Mentés">
                            <span>
                                <IconButton disabled={!valid} onClick={this.handleSave} className={classes.doneButton} aria-label="done">
                                        <DoneIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title="Mégsem">
                                <span>
                                <IconButton className={classes.backButton} onClick={onClose}  aria-label="back">
                                        <CloseIcon />
                                    </IconButton>
                            </span>
                        </Tooltip>
                   </div>
                    </DialogActions>
                </Dialog>);
    }
   
   
}
export default withStyles(style.useStyles)(AddNewDialog);
