import React from 'react';
//Core
import {
    Grid,
    Divider,
    Badge,
    Button,
    Tooltip,
    Popover,
    Typography
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
//Components
import { MuiOutlinedInput, MuiOutlinedDateSelect } from 'components';
import { UserStore } from 'stores';
import {enums} from 'stores';
//Style
import style from './style';

const InputTypes = enums.inputTypes;

function getExpected(item){
    
    const loggedBy =  UserStore.getLoggedByAdminValue();
    var result = null;
    switch(item.scale.inputTypeId){
        case InputTypes.DATEFIELD:
            result = <MuiOutlinedDateSelect
            margin="dense"
            disabled={loggedBy!=null && loggedBy.period.disabled}
            readOnly={true}
            label="Elvárt"
            value={item.expected}/>
            break;
        default:
            result = <MuiOutlinedInput
            margin="dense"
            disabled={loggedBy!=null && loggedBy.period.disabled}
            inputId={`textfield-expected-${item.id}`}
            label="Elvárt"
            endInputAdornment={item.scale.unit}
            defaultValue={item.expected} />
            break;
    }

    if(item.relevance !== null && item.relevance !== undefined){
        return <Badge badgeContent={`${item.relevance}%`} color="primary">
            {result}
        </Badge>;
    }else{
        return result;
    }
}

function getPerformed(item,index,setAnchorEl){
    const classes = style();
    const loggedBy =  UserStore.getLoggedByAdminValue();
    
    if(item.isSkipped){
        return <div style={{display:'flex', alignItems:'center',justifyContent:'center',height:'100%'}}>
                <Tooltip title="Ok megtekintése">
                    <Button onClick={(event)=>setAnchorEl({element:event.currentTarget,index:index})} className={classes.skipButton} startIcon={<BlockIcon/>} color="warning"  variant="contained" size="small">
                        Kihagyva
                    </Button>
                </Tooltip>            
            </div>

    }else{
        switch(item.scale.inputTypeId){
            case InputTypes.DATEFIELD:
                return  <MuiOutlinedDateSelect
                margin="dense"
                readOnly={true}
                disabled={loggedBy!=null && loggedBy.period.disabled}
                label={( item.performed !== null &&  item.performed != undefined &&  item.performed !== 'nem teljesült') ? 'Teljesítve' : 'Nem teljesült'}
                skipError={item.performed === 'nem teljesült' ? true : false}
                skipHelperText={item.performed === 'nem teljesült' ? true : false}
                helperText="Nincs kitöltve"
                value={( item.performed !== null &&  item.performed != undefined &&  item.performed !== 'nem teljesült') ? item.performed : null} />
            default:
                return <MuiOutlinedInput
                margin="dense"
                readOnly={true}
                disabled={loggedBy!=null && loggedBy.period.disabled}
                inputId={`textfield-scale-${item.id}`}
                label="Teljesítve"
                error={(item.performed === null || item.performed === undefined) ? true : false}
                helperText="Nincs kitöltve"
                defaultValue={item.performed} />
        }
    }

   
}

export default function GoalsGrid(props) {
    const { data } = props;
    const [goals, setGoals] = React.useState(data);
    const [anchorEl, setAnchorEl] = React.useState({element:null,index:null});
    const classes = style();

    return (goals !== null && goals.length > 0 ? <React.Fragment>
        {goals.map((item, index) => (
            <div key={index} className={classes.root}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                md={5}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    margin="dense"
                                    multiline={true}
                                    rows={3}
                                    readOnly={true}
                                    inputId={`textfield-goal-${index}`}
                                    label="Feladat"
                                    defaultValue={item.description} />
                            </Grid>
                            <Grid
                                item
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                                <MuiOutlinedInput
                                    margin="dense"
                                    disabled={true}
                                    inputId={`textfield-scale-${index}`}
                                    label="Mérőszám"
                                    //startInputAdornment={Meroszamok.getIcon(item.scale.id)}
                                    defaultValue={item.scale.label} />
                            </Grid>
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                            >
                                {getExpected(item)}
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                {getPerformed(item,index,setAnchorEl)}                              
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </div>
        ))}
        {anchorEl.element!==null && <Popover
                    id={'reason-popover'}
                    open={Boolean(anchorEl.element)}
                    anchorEl={anchorEl.element}
                    onClose={()=>setAnchorEl({element:null,index:null})}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <Typography className={classes.typography}>{goals[anchorEl.index].reasonOfSkip}</Typography>
                </Popover>}
        </React.Fragment> 
        : <div className={classes.empty}>Nincs létrehozva</div>)
}
