
//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import { 
    Progress, 
    NotificationBar 
} from 'components';
//Actions

const Actions = {
    SaveNotes: 'ratingForm/notes/save',
    GetNotes: 'ratingForm/notes/get',
}

export const apiRequest = {
    populateNotes,
    handleSaveChanges
};

async function handleSaveChanges(parent,userId, notes) {
    const { handleClose, period } = parent.props;
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const model = { userId, notes, period:period.id }
    await new Api().call.post(Actions.SaveNotes, model)
    .then(handleResponse)
    .then(res => {
        handleClose();
    }).catch(handleError);
}

async function populateNotes(parent) {
    const { data, period } = parent.props;
    await new Api().call.get(`${Actions.GetNotes}/${data.id}/${period.id}`)
    .then(handleResponse)
    .then(res => {
        parent.setState({
            notes: res.notes,
            loading: false
        });
    }).catch(handleError);
}