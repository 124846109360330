import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Topbar } from './components';
import PerfectScrollbar from 'react-perfect-scrollbar'
import style from './style';
import {UserStore,Constants} from 'stores';
//Core
import {
  LinearProgress
} from '@material-ui/core';



const Minimal = props => {
    const { children } = props;
    const [loginBy, setLoginBy] = useState(UserStore.getLoggedByAdminValue());
    const [loading,setLoading] = useState(Constants.getTopBarLoadingValue());
    useEffect(() => {
      UserStore.getLoggedByAdmin().subscribe(res => setLoginBy(res));
      UserStore.clearLoggedByAdmin();
      Constants.getTopBarLoading().subscribe(res => setLoading(res));
    }, [])


    const classes = style();

  return (
    <div className={classes.root}>
       <Topbar />
       {loading===true  &&  <div style={{textAlign:'center'}}><LinearProgress color="primary" /></div>}
          <PerfectScrollbar>
              <main className={classes.content}>{children}</main>
          </PerfectScrollbar>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
