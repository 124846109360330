//React

import React, { Component } from 'react';
//Core

import { Grid } from '@material-ui/core';
//Components

import { 
    Pagination, 
    DataTable, 
    Toolbar, 
    AddNewDialog 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';


class CompetencesTable extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            competences: [],
            loading: {
              competences: true,
              f_competences:true,
            },
            filter: {
                competences: [],
            },
            selectedFilter:{
                competences:[],
                active:null,
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            edit:null,
        }

    }

    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateCompetences(this, this.state.page);
        apiRequest.populateCompetencesFilters(this);
    }
    //#EndLifecycle methods

    handleChange = (event,index,props) => {
        const value = event.target.value;
        const competences = [...this.state.competences];
        competences[index][props] = value;
        this.setState({ competences });
    }

    handleValidChange = (index,value) =>{
        const competences = [...this.state.competences];
        competences[index]['isValid'] = value;
        this.setState({ competences });
        apiRequest.saveEdit(this, competences[index]);
    }

    handleEdit = (role) => {
        this.setState({
            edit: { ...role }
        });
    }

    handleSave = (index) => {
        const {competences} = this.state;

        apiRequest.saveEdit(this, competences[index]);
    }

    handleCancel = (index) => {
        const competences = [...this.state.competences];
        competences[index].label = this.state.edit.label;
        competences[index].description = this.state.edit.description;
        this.setState({
            competences,
            edit:null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                competences: true
            },
            selectedFilter: {
                ...prevState.selectedFilter,
                [props]: value
            }
        }), () => apiRequest.populateCompetences(this,1));
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                competences: true
            }
        }));
        apiRequest.populateCompetences(this, nextPage);
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    refresh = () =>{
        const {page} = this.state;

        apiRequest.populateCompetences(this, page);
        apiRequest.populateCompetencesFilters(this);
    }
   
    render() {
        const {open, competences, filter, currentPage, step, total, selectedFilter, pages, edit, loading} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <div>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                         {loading.competences === false && 
                            <Toolbar
                                onBlockSelected={this.handleBlockSelected} 
                                onUnBlockSelected = {this.handleUnBlockSelected}
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter}
                                selectedFilter={selectedFilter}  
                                loading={loading} 
                                selected={[]} 
                            />
                         }
                        <div className={classes.content}>
                                <DataTable
                                    loading={loading.competences}
                                    onValidChange={this.handleValidChange} 
                                    onOpen={this.handleOpen} 
                                    onCancel={this.handleCancel} 
                                    edit={edit} 
                                    onEdit={this.handleEdit} 
                                    onSave={this.handleSave} 
                                    onChange={this.handleChange} 
                                    competences={competences}
                                />
                        </div>
                        {(loading.competences === false && edit==null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                </Grid>
                {open == true && 
                    <AddNewDialog 
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
            </div>
        );
    }
};

export default withStyles(style)(CompetencesTable);
