
import React from 'react';
import {Api,handleResponse,handleError} from 'api';
import { Progress, NotificationBar } from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoals: 'goal/result/goals',
    Save: 'goal/result/save-goal'
}

export const apiRequest = {
    saveGoals,
    populateGoals
};

async function saveGoals(parent,model) {
    const { goals } = parent.state;
    const {setParentState} = parent.props;
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

     var _model = {goals:model};
     const loggedBy = UserStore.getLoggedByAdminValue();
     if(loggedBy!=null){
         _model = {..._model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
     }

        await new Api().call.post(Actions.Save,_model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                isModified: false,
                open: false,
                baseGoals: {
                    ...goals,
                    goalsForTable: [...goals.goalsForTable]
                },
            })
            setParentState("isModified", false);
        }).catch(handleError);


}

async function populateGoals(parent,currentPage, _typeId) {
    const {userId} = parent.props;

    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = {userId,typeId,currentPage:pageNumber};
    const loggedBy = UserStore.getLoggedByAdminValue();
    if(loggedBy!=null){
        model = {...model,loggedByUser:{period:loggedBy.period.id,user:loggedBy.user.userId}};
    }

        await new Api().call.post(Actions.GetGoals,model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                baseGoals: {
                    ...res,
                    goalsForTable: [...res.goalsForTable]
                },
                nextType: null,
                nextPage: null,
                nextGoalType: null,
                loading: false
            });
        }).catch(handleError);

}