import theme from 'theme';

const useStyles = ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        padding: theme.spacing(2)
    },
    contentTitle: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1.0rem',
    },
    actions: {
        justifyContent: 'flex-end'
    },
    media: {
        height: 80,
    },
    headerDiv: {
        textAlign: 'center'
    },
    card__content:{
        padding:'unset',
        textAlign:'center',
        padding:'0.5rem'
    },
    playIcon:{
        color:theme.palette.success.main
    },
    pauseIcon:{
        color:theme.palette.warning.main
    },
    avatar:{
        backgroundColor:'white'
    },
    avatar__icon:{
        color:theme.palette.warning.main
    },
    card__result:{
        textAlign:'center',
        padding:'1rem 0 0 0'
    },
    card__result__text:{
        backgroundColor: 'white',
        padding:'.5rem .5rem .5rem .5rem',
        borderRadius: '.25rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, .15)'
    }
});
export default useStyles;