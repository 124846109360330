import { makeStyles,withStyles } from '@material-ui/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const useStyles =  makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        fontSize: '1.3rem'

    },
    subContent: {
        fontFamily: 'Nunito',
        paddingBottom: '0.4rem',
        fontSize: '1.1rem'
    },
    userBio: {
        fontFamily: 'Nunito',
        paddingBottom: '0.4rem',
        fontSize: '1.1rem'
    },
    avatar: {
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        height: 90,
        width: 90,
    },
    icon: {
        height: 32,
        width: 32,
        color: theme.palette.appMain.main
    },
    actions: {
        justifyContent: 'flex-end',
        '& .MuiButton-textPrimary': {
            color: theme.palette.appMain.main
        },
    },
    userDatas: {
        margin:'auto'
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default {
styles:styles,
useStyles:useStyles,
DialogContent:DialogContent,
DialogActions:DialogActions

}
