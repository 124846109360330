//React

import React, { Component } from 'react';
//Core

import { 
    Grid, 
    Typography,
    Divider 
} from '@material-ui/core';
//Components

import { 
    OrganizationsToolbar, 
    OrganizationsTable, 
    Pagination, 
    AddNewDialog,
    Leaders 
} from './components';
//Api

import { apiRequest } from './apiRequest';
//Helpers

import { Constants } from 'stores';
//Styles

import { withStyles } from '@material-ui/styles';
import style from './style';


class Organizations extends Component {

    constructor(props) {
        super(props);
        Constants.setcontentLoading(false);
        this.state = {
            organizations: [],
            loading: {
                organizations: true,
                filterOrganizations: true,
                filterCompanies:true,
            },
            filter: {
                organizations: [],
                companies:[],
                active: null
            },
            open:false,
            page: 1,
            total: null,
            currentPage: null,
            step: null,
            pages: null,
            filterOrganizations: [],
            filterCompanies: [],
            edit:null,
            selectedOrganizations:[],
            leader:null
        }

    }
    //#Lifecycle methods
    componentDidMount() {        
        apiRequest.populateOrganizations(this, this.state.page);
        apiRequest.populateFilterOrganizations(this);
        apiRequest.populateFilterCompanies(this);
    }
    //#EndLifecycle methods

    handleSelectCompanies = (organizations) => {
        this.setState({
            selectedOrganizations: organizations
        });
    }

    handleChange = (event,index,props) => {
        const value = event.target.value;
        const organizations = [...this.state.organizations];
        organizations[index][props] = value;
        this.setState({ organizations });
    }

    handleEdit = (bio) => {
        this.setState({
            edit: { ...bio }
        });
    }

    handleSave = (index) => {
        const {organizations} = this.state;

        apiRequest.saveChanges(this, organizations[index]);
    }

    handleCancel = (index) => {
        const organizations = [...this.state.organizations];
        organizations[index].name = this.state.edit.name;
        this.setState({
            organizations,
            edit:null
        });
    }

    handleFilterChanges = (props, value) => {
        this.setState(prevState => ({
            page: 1,
            loading: {
                ...prevState.loading,
                organizations: true
            },
            filter: {
                ...prevState.filter,
                [props]: value
            }
        }), () => apiRequest.populateOrganizations(this,1));
    }

    handleDelete = (index) => {
        const organizations = [...this.state.organizations];
        organizations[index].isValid = false;
        apiRequest.saveChanges(this, organizations[index]);
    }

    handleDone = (index) => {
        const organizations = [...this.state.organizations];
        organizations[index].isValid = true;
        apiRequest.saveChanges(this, organizations[index]);
    }

    handlePageChanges = (nextPage) => {
        this.setState(prevState => ({
            page: nextPage,
            loading: {
                ...prevState.loading,
                organizations: true
            }
        }));
        apiRequest.populateOrganizations(this, nextPage);
    }
    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    }

    handleBlockSelected = () =>{
        const {selectedOrganizations,page} = this.state;

        const model ={organizations:selectedOrganizations,valid:false};
        apiRequest.validateOrganizations(this,page,model);
        this.setState({
            selectedOrganizations:[]
        });
    }

    handleUnBlockSelected = () =>{
        const {selectedOrganizations,page} = this.state;

        const model ={organizations:selectedOrganizations,valid:true};
        apiRequest.validateOrganizations(this,page,model);
        this.setState({
            selectedOrganizations:[]
        });
    }

    refresh = () =>{
        const {page} = this.state;

        apiRequest.populateOrganizations(this, page);
        apiRequest.populateFilterOrganizations(this);
    }

    handleLeader = (leader) =>{
        this.setState({
            leader
        });
    } 

    render() {
        const {organizations,leader, open, selectedOrganizations, filter, currentPage, step, total, pages, filterOrganizations, filterCompanies, edit, loading} = this.state;
        const {classes, snackbar} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>Szervezeti egységek</Typography>
                <Divider style={{marginBottom:'0.5rem'}}/>
                <Grid container>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={10} item>
                        {loading.organizations === false && 
                            <OrganizationsToolbar
                                onBlockSelected={this.handleBlockSelected} 
                                onUnBlockSelected = {this.handleUnBlockSelected}
                                edit={edit} 
                                onChange={this.handleFilterChanges} 
                                filter={filter} 
                                companies={filterCompanies} 
                                organizations={filterOrganizations} 
                                loading={loading} 
                                selected={selectedOrganizations} 
                            />
                        }
                        <div className={classes.content}>
                            <OrganizationsTable
                                loading={loading.organizations} 
                                onOpen={this.handleOpen} 
                                onDelete={this.handleDelete} 
                                onDone={this.handleDone} 
                                onCancel={this.handleCancel} 
                                onLeader={this.handleLeader}
                                edit={edit} 
                                onEdit={this.handleEdit} 
                                onSave={this.handleSave} 
                                onChange={this.handleChange} 
                                onSelect={this.handleSelectCompanies} 
                                organizations={organizations}
                                selectedOrganizations={selectedOrganizations}  
                            />
                        </div>
                        {(loading.organizations === false && edit==null) && 
                            <Pagination 
                                onPageChanges={this.handlePageChanges} 
                                currentPage={currentPage} 
                                step={step} 
                                total={total} 
                                pages={pages} 
                            />
                        }
                    </Grid>
                    <Grid xs={false} sm={false} md={false} lg={1} xl={1} item/>
                </Grid>
                {open == true && 
                    <AddNewDialog 
                        refresh={this.refresh}
                        snackbar={snackbar} 
                        onClose={this.handleClose} 
                        open={open} 
                    />
                }
                {leader !== null &&
                    <Leaders
                        organization={leader}
                        onClose={()=>this.handleLeader(null)}
                    />
                }
            </div>
        );
    }
};

export default withStyles(style)(Organizations);
