import React from 'react';
import { Fab, Zoom } from '@material-ui/core';
//Icons
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//Components
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import $ from "jquery";
//Style
import style from './style';


function ScrollTop(props) {
    const { children, open } = props;
    const classes = style();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    const handleClick = (event) => {
      $(".scrollbar-container").animate({ scrollTop: -50 }, "slow");
    };

    return (
        <Zoom in={open}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

export default function ScrollToTop(props) {
    const classes = style();
    return (
        <ScrollTop {...props}>
            <Fab color="primary" className={classes.iconButton} size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    );
}
