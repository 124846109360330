//React

import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Core

import {
    Card,
    CardContent,
    Avatar,
    Typography,
    Divider,
    withStyles
} from '@material-ui/core';
//Styles

import style from './style';
import theme from 'theme';


class ActiveLeader extends Component {

    render() {
        const {className, classes, user, time, ...rest} = this.props;

        return (
            <Card {...rest} className={clsx(classes.root, className)}>
                <CardContent>
                    <div className={classes.details}>
                        <div>
                            <Typography
                                gutterBottom
                                variant="h4"
                            >
                                Aktív felettes
                            </Typography>
                            <Typography
                                gutterBottom
                                variant="h2"
                            >
                                {user.fullName}
                            </Typography>
                            <Typography
                                className={classes.locationText}
                                color="textSecondary"
                                variant="body1"
                            >
                                {user.org}, {user.bio}
                            </Typography>
                        </div>
                        <Avatar
                            className={classes.avatar}
                            src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        />
                    </div>
                </CardContent>
                <Divider />
            </Card>
        );
    }

};

ActiveLeader.propTypes = {
    className: PropTypes.string
};

export default withStyles(style)(ActiveLeader);
