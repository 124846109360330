//React

import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//Components

import { AddPicture } from '../';
//Api

import { apiRequest } from './apiRequest';
//Core

import {
    Card,
    CardContent,
    Avatar,
    Typography,
    Divider,
    withStyles
} from '@material-ui/core';
//Styles

import style from './style';


class AccountProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            request: false
        }
    }

    handleUpdate = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleRemove = () => {
        this.setState({
            request: true
        });
        apiRequest.remove(this);
    }

    render() {
        const { className, classes, user, time, userId, refreshProfile, refreshTable, ...rest } = this.props;
        const { open } = this.state;

        return (
            <Card {...rest} className={clsx(classes.root, className)}>
                {open &&
                    <AddPicture
                        userId={userId}
                        refreshProfile={refreshProfile}
                        refreshTable={refreshTable}
                        user={user}
                        open={open}
                        onClose={this.handleClose}
                    />
                }
                <CardContent>
                    <div className={classes.details}>
                        <div>
                            <Typography
                                gutterBottom
                                variant="h2"
                            >
                                {user.fullName}
                            </Typography>
                            <Typography
                                className={classes.locationText}
                                color="textSecondary"
                                variant="body1"
                            >
                                {user.org},
                            </Typography>
                            <Typography
                                className={classes.locationText}
                                color="textSecondary"
                                variant="body1"
                            >
                                {user.bio}
                            </Typography>
                        </div>
                        <Avatar
                            className={classes.avatar}
                            src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar : ''}
                        />
                    </div>
                </CardContent>
                <Divider />
            </Card>
        );
    }
};

AccountProfile.propTypes = {
    className: PropTypes.string
};

export default withStyles(style)(AccountProfile);
