//React

import React,{ Component } from 'react';
//Core

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  Box,
  LinearProgress,
  Collapse
} from '@material-ui/core';
//Icons

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
//Components

import {ConfirmDialog,NewDialog} from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { apiRequest } from './apiRequest';
//Style

import { withStyles } from '@material-ui/core/styles';
import style from './style';


function Row(props) {
  const {main, inputs, edit, onEdit, onSave, onDelete, onAddNew, deleted, onCancel, mainIndex, onChange, periods, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton 
            aria-label="expand row" 
            size="small" 
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.id}
        </TableCell>
        <TableCell align="center">
          <TextField
            fullWidth
            name="title"
            onChange={(event)=>onChange(event,"title",1,mainIndex)}
            disabled={(edit == null || (edit != null && edit.id != main.id))}
            value={(main.title===null || main.title===undefined) ? '' : main.title}
          />        
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,"validFrom",1,mainIndex)}
            value={main.validFrom}
          >
            {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,"validTo",1,mainIndex)}
            value={main.validTo}
          >
             {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        {(edit == null || (edit != null && edit.id != main.id)) &&
        <React.Fragment>         
          <Tooltip title="Módosítás">
            <span>
              <IconButton 
                onClick={()=>onEdit(main)} 
                className={classes.editIcon}
                disabled={main.disabled || edit!==null  || period===null || period.disabled===true}>
                <EditIcon/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Törlés">
          <span>
            <IconButton 
              onClick={()=>onDelete(main)} 
              className={classes.deleteButton}
              disabled={main.disabled || edit!==null  || period===null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
          </Tooltip>
        </React.Fragment>  
        }
        {(edit != null && edit.id == main.id) &&
          <React.Fragment> 
            <Tooltip title="Mentés">
              <span>
                <IconButton  
                  onClick={()=>onSave(mainIndex)} 
                  className={classes.saveButton} 
                  aria-label="save"
                >
                  <DoneIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Mégsem">
              <IconButton 
                onClick={onCancel} 
                className={classes.cancelButton} 
                aria-label="cancel"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip> 
          </React.Fragment> 
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell />
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Megnvezés</TableCell>
                    <TableCell padding="none" align="center">Értékek</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.subLeaderRatings.map((sub,subIndex) => (
                    <SubRow 
                      onAddNew={onAddNew} 
                      deleted={deleted} 
                      onDelete={onDelete} 
                      key={sub.id} 
                      firstIndex={mainIndex} 
                      secondIndex={subIndex} 
                      onChange={onChange} 
                      inputs={inputs} 
                      period={period} 
                      parent={main} 
                      main={sub} 
                      edit={edit} 
                      periods={periods}
                    />))
                  }
                  <TableRow>
                    <TableCell padding="none" colSpan={8}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új pont létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton 
                                onClick={()=>onAddNew(1,main.id)}
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != main.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>                  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SubRow(props) {
  const {main, inputs ,edit, parent, firstIndex, secondIndex, onChange, onAddNew, deleted, periods, onDelete, period} = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>
          <IconButton 
            aria-label="expand row" 
            size="small" 
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.id}
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.parentId}
        </TableCell>
        <TableCell align="center">
        <TextField
            name="title"
            multiline
            fullWidth
            onChange={(event)=>onChange(event,"title",2,firstIndex,secondIndex)}
            disabled={(edit == null || (edit != null && edit.id != parent.id))}
            value={(main.title===null || main.title===undefined) ? '' : main.title}
          />         
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={(main.disabled || edit == null || (edit != null && edit.id != parent.id))}
            name="inputId"
            fullWidth
            onChange={(event)=>onChange(event,"inputId",2,firstIndex,secondIndex)}
            value={main.inputId ===null || main.inputId===undefined ? '' : main.inputId}
          >
            {inputs.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,"validFrom",2,firstIndex,secondIndex)}
            value={main.validFrom}
          >
            {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,"validTo",2,firstIndex,secondIndex)}
            value={main.validTo}
          >
             {periods.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main)}   
              className={classes.deleteButton}
              disabled={main.disabled || edit!==null  || period===null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                  <TableCell/>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Megnvezés</TableCell>
                    <TableCell padding="none" align="center">Értékek</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.elements.map((element,elementIndex) => (
                    <Element 
                      onAddNew={onAddNew} 
                      deleted={deleted} 
                      onDelete={onDelete} 
                      firstIndex={firstIndex} 
                      onChange={onChange} 
                      secondIndex={secondIndex} 
                      thirdIndex={elementIndex} 
                      parent={parent} 
                      key={element.id} 
                      inputs={inputs} 
                      period={period}
                      main={element} 
                      edit={edit} 
                      periods={periods}
                    />))
                  }
                  <TableRow>
                    <TableCell padding="none" colSpan={8}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>Új pont létrehozása: </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton 
                                onClick={()=>onAddNew(2,main.id)}
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != parent.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>       
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Element(props) {
  const { main, parent, inputs, edit, firstIndex, secondIndex, thirdIndex, onAddNew, onChange, onDelete, deleted, periods, period } = props;

  const [open, setOpen] = React.useState(false);
  const classes = style.useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={(deleted!==null && deleted.id==main.id) ? clsx(classes.deleted,classes.root) : classes.root}>
        <TableCell>   
          <IconButton 
            aria-label="expand row" 
            size="small" 
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.id}
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox" align="left">
          {main.parentId}
        </TableCell>
        <TableCell align="center">
          <TextField
            name="label"
            multiline
            fullWidth
            onChange={(event)=>onChange(event,"label",3,firstIndex,secondIndex,thirdIndex)}
            disabled={(edit == null || (edit != null && edit.id != parent.id))}
            value={(main.label===null || main.label===undefined) ? '' : main.label}
          />         
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={(main.disabled || edit == null || (edit != null && edit.id != parent.id))}
            name="inputId"
            fullWidth
            onChange={(event)=>onChange(event,"inputId",3,firstIndex,secondIndex,thirdIndex)}
            value={main.inputId ===null || main.inputId===undefined ? '' : main.inputId}
          >
            {inputs.map((item,index)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>))
            }
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validFrom"
            fullWidth
            onChange={(event)=>onChange(event,"validFrom",3,firstIndex,secondIndex,thirdIndex)}
            value={main.validFrom}
          >
            {periods.map((item)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>))
            }
          </Select>
        </TableCell>
        <TableCell align="center">
          <Select
            disabled={true}
            name="validTo"
            fullWidth
            onChange={(event)=>onChange(event,"validTo",3,firstIndex,secondIndex,thirdIndex)}
            value={main.validTo}
          >
             {periods.map((item)=>(
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
        <Tooltip title="Törlés">
          <span>
            <IconButton
              onClick={()=>onDelete(main)}   
              className={classes.deleteButton}
              disabled={main.disabled || edit!==null  || period===null || period.disabled===true}>
              <DeleteIcon/>
            </IconButton>
          </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="sub">
                <TableHead>
                  <TableRow className={classes.head}>
                    <TableCell padding="checkbox" align="left">Azonosító</TableCell>
                    <TableCell padding="checkbox" align="left">Szülő</TableCell>
                    <TableCell padding="none" align="center">Megnvezés</TableCell>
                    <TableCell padding="none" align="center">Értékek</TableCell>
                    <TableCell padding="none" align="center">Érvényes-től</TableCell>
                    <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                    <TableCell padding="none" align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {main.subElements.map((sub,fourthIndex) => (
                    <TableRow className={(deleted!==null && deleted.id==sub.id) ? classes.deleted : ''} key={sub.id}>
                      <TableCell padding="checkbox" align="left" component="th" scope="row">
                        {sub.id}
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                          {sub.parentId}
                      </TableCell>
                      <TableCell align="center">
                          <TextField
                            multiline
                            fullWidth
                            name="label"
                            onChange={(event)=>onChange(event,"label",4,firstIndex,secondIndex,thirdIndex,fourthIndex)}
                            disabled={(sub.disabled || edit == null || (edit != null && edit.id != parent.id))}
                            value={(sub.label===null || sub.label===undefined) ? '' : sub.label}
                          />
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          disabled={(sub.disabled || edit == null || (edit != null && edit.id != parent.id))}
                          name="inputId"
                          fullWidth
                          onChange={(event)=>onChange(event,"inputId",4,firstIndex,secondIndex,thirdIndex,fourthIndex)}
                          value={sub.inputId ===null || sub.inputId===undefined ? '' : sub.inputId}
                        >
                          {inputs.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validFrom"
                          fullWidth
                          onChange={(event)=>onChange(event,"validFrom",4,firstIndex,secondIndex,thirdIndex,fourthIndex)}
                          value={sub.validFrom}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          disabled={true}
                          name="validTo"
                          fullWidth
                          onChange={(event)=>onChange(event,"validTo",4,firstIndex,secondIndex,thirdIndex,fourthIndex)}
                          value={sub.validTo}
                        >
                          {periods.map((item,index)=>(
                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Törlés">
                          <span>
                            <IconButton
                              onClick={()=>onDelete(sub)}   
                              className={classes.deleteButton}
                              disabled={main.disabled || edit!==null  || period===null || period.disabled===true}>
                              <DeleteIcon/>
                            </IconButton>
                          </span>
                        </Tooltip>
                    </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell padding="none" colSpan={7}>
                      <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Új pont létrehozása: 
                        </Typography>
                          <Tooltip title="Új hozzáadás">
                            <span>
                              <IconButton
                                onClick={()=>onAddNew(3,main.id)} 
                                disabled={(main.disabled || edit == null || (edit != null && edit.id != parent.id))} 
                                className={classes.addButton} 
                                aria-label="add"
                              >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow> 
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


class PointsTree extends Component {

    constructor(props){
        super(props);
        this.state={
            leaderRatingPoints:[],
            inputs:[],
            periods:[],
            period:'',
            edit:null,
            loading:null,
            deleted:null,
            newPoint:null,
            parentId:null
        };
    }

    componentDidMount(){
        apiRequest.populatePeriods(this);
        apiRequest.populateInputMatrices(this);
    }

    componentDidUpdate(prevProps,prevState){
      const {period} = this.state;

      if(prevState.period!=period){
        this.setState({
          loading:true
        });

        apiRequest.populatetLeaderRatingPoints(this,period.id);
      }
    }

    handleEdit = (row) =>{
      const edit = {...row};

      this.setState({
        edit
      });
    }

    handleCancel = () =>{
      const {period} = this.state;

      this.setState({
        loading:true,
        edit:null
      });
      
      apiRequest.populatetLeaderRatingPoints(this,period.id);
    }

    handleNewPoint = (level,parentId) =>{
      this.setState({
        newPoint:level,
        parentId:parentId
      });
    }

    afterAddedNewPoint = () =>{
      const {period} = this.state;

      this.setState({
        newPoint:null,
        parentId:null,
        loading:true,
        edit:null
      });

      apiRequest.populatetLeaderRatingPoints(this,period.id)
    }

    handleDelete = (row) =>{
        this.setState({
          deleted:row
        });
    }

    deleteRow = () =>{
      const {deleted, period} = this.state;

      const model = {id:deleted.id,period:period.id};
      apiRequest.deletePoint(this,model,period.id);
    }

    handleChange = (event,props,level,index,index2,index3,index4,isNumber) => {
      let value = event.target.value;
      const leaderRatingPoints = [...this.state.leaderRatingPoints];

      if(isNumber){
        value=value*1;
      }

      switch(level){
        case 1:
          leaderRatingPoints[index][props] = value;
        break;
        case 2:
          leaderRatingPoints[index].subLeaderRatings[index2][props] = value;
        break;
        case 3:
          leaderRatingPoints[index].subLeaderRatings[index2].elements[index3][props] = value;
        break;
        case 4:
          leaderRatingPoints[index].subLeaderRatings[index2].elements[index3].subElements[index4][props] = value;
        break;
      }
      
      this.setState({ leaderRatingPoints });
    }

    handleSave = (index) =>{
        const {leaderRatingPoints,period} = this.state;
        const model = {model:leaderRatingPoints[index],period:period.id};
        
        apiRequest.saveChanges(this,model,period.id);        
    }

    handlePeriodChange = (event) =>{
      const value = event.target.value;
      this.setState({
        period:value
      });
    }

    render(){
        const {leaderRatingPoints, compGroups, inputs, edit, periods, period, deleted, newPoint, parentId, loading} = this.state;
        const {classes} = this.props;

        return (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="period-select-label">Periódusok</InputLabel>
                  <Select
                    labelId="period-select-label"
                    id="period-select"
                    value={period}
                    onChange={this.handlePeriodChange}
                  >
                    {periods.map(period=>
                      <MenuItem key={period.id} value={period}>
                        {period.label}
                      </MenuItem>)
                    }
                  </Select>
              </FormControl>
              {period!=='' && 
                <div style={{ display: 'flex', alignItems: 'center', float: 'right', }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Új pont létrehozása: 
                    </Typography>
                    <Tooltip title="Új hozzáadás">
                        <span>
                            <IconButton
                              onClick={()=>this.handleNewPoint(0)} 
                              disabled={edit != null || period.disabled ? true : false} 
                              className={classes.addButton} 
                              aria-label="add"
                            >
                                <AddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
              }
              <TableContainer component={Paper}>
                {loading && <LinearProgress />}
                <PerfectScrollbar>
                  <Table aria-label="collapsible table">
                      <TableHead>
                      <TableRow className={classes.head}>
                          <TableCell />
                          <TableCell padding="checkbox" align="left">Azonositó</TableCell>
                          <TableCell padding="none" align="center">Pont megnevezés</TableCell>
                          <TableCell padding="none" align="center">Érvényes-től</TableCell>
                          <TableCell padding="none" align="center">Érvényes-ig</TableCell>
                          <TableCell padding="none" align="center">Műveletek</TableCell>
                      </TableRow>
                      </TableHead>
                      <TableBody>
                      {leaderRatingPoints.map((main,index) => (
                          <Row 
                            key={index}
                            mainIndex={index} 
                            main={main} 
                            compGroups={compGroups} 
                            inputs={inputs} 
                            edit={edit}
                            deleted={deleted}
                            period={(period==='' || period===undefined || period===null) ? null : period}
                            periods={periods}
                            onChange={this.handleChange} 
                            onEdit={this.handleEdit}
                            onDelete={this.handleDelete}
                            onSave={this.handleSave}
                            onAddNew={this.handleNewPoint}
                            onCancel={this.handleCancel}
                          />
                      ))}
                      {(period==='' || period===undefined || period===null) ?
                        <TableRow>
                            <TableCell align="center" colSpan="7">
                              Nincs kválasztva periódus
                            </TableCell>
                        </TableRow>
                        :  leaderRatingPoints.length < 1 && 
                        <TableRow>
                          <TableCell align="center" colSpan="7">
                            {loading ? 'Betöltés...' : 'Nincs találat'}
                          </TableCell>
                        </TableRow>
                      }
                      </TableBody>
                  </Table>
                </PerfectScrollbar>
              </TableContainer>            
              <ConfirmDialog 
                onClose={()=>this.handleDelete(null)} 
                onDelete={this.deleteRow} 
                open={deleted!==null}
              />
              {newPoint!=null && 
                <NewDialog
                  parentId={parentId}
                  refresh={this.afterAddedNewPoint}
                  period={period}
                  inputs={inputs}
                  onClose={()=>this.handleNewPoint(null)} 
                  level={newPoint}
                  open={newPoint!=null}
                />
              }
            </div>
        );
    }
}
export default withStyles(style.useStyles)(PointsTree)