import theme  from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = ({
    doneButton: {
        color: theme.palette.success.main
    },
    backButton: {
        color: theme.palette.error.main
    },
    formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
});

const useStylesForPicker = makeStyles(theme => ({
  root: {
  },
  picker: {
      '& label': {
          fontFamily: 'Nunito',
          fontSize: '1.2rem',
          fontWeight: 'bold'
      },
      '& label.Mui-focused': {
          color: theme.palette.appMain.main,
      },
      '& .MuiInput-underline:after': {
          borderBottomColor: theme.palette.appMain.main,
      },
      '& .MuiOutlinedInput-root': {
          backgroundColor: theme.palette.white,
          '&.Mui-focused fieldset': {
              borderColor: theme.palette.appMain.main,
          },
          '&:hover fieldset': {
              borderColor: theme.palette.appMain.light,
          },
      },
      '& .MuiOutlinedInput-notchedOutline > legend > span': {
          paddingRight: '1.2rem'
      }
  },
}));

export default {
  useStyles,
  useStylesForPicker
}
