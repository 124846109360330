import React from 'react';
//Api
import {Api,handleResponse,handleError} from 'api';
import {authenticationService} from 'services';
//Components
import { NotificationBar,Progress  } from 'components';

//Actions
const Actions = {
    SaveSettings: 'admin/user/settings/update',
}


export const apiRequest = {
    saveSettings,
};

//#Api calls
async function saveSettings(parent) {
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);
    const { _settings } = parent.state;
    const {userId,refreshProfile} = parent.props;
    const model = { userId, autoSave: _settings.autoSave }
        await new Api().call.post(Actions.SaveSettings, model).then(handleResponse).then(res => {
           
            refreshProfile();
        }).catch(handleError);
}

