import theme from 'theme';

const useStyles = ({
    root: {
        paddingTop: theme.spacing(2),
        width: '100%',
        '& .MuiSlider-root': {
            padding: 0
        },
        '& .MuiSlider-rail': {
            width: '0%',
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed',
            borderCollapse: 'initial',
        },
    },
    slider: {
    },
    cards: {
        display: 'flex',
        '& .MuiCardHeader-root': {
            padding: '0px',
            textAlign: 'center',
            backgroundColor: theme.palette.papper
        },
        '& .MuiCardContent-root': {
            padding: '2px',
        }
    },
    cardHeader: {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        whiteSpace:'nowrap'
    },
    cardContent: {
        whiteSpace: 'nowrap',
        color: theme.palette.white
    },
    subTitle: {
        padding: theme.spacing(0),
        fontWeight: 'bold',
        fontSize:'1.2rem'
    }
});

export default useStyles;
