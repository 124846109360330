//React

import React from 'react';
//Api

import {
    Api,
    handleResponse,
    handleError
} from 'api';
//Components

import {
    Progress,
    NotificationBar
} from 'components';
import { UserStore } from 'stores';

//Actions
const Actions = {
    GetGoals: 'goal/new/goals',
    GetGoalsForCopy: 'goal/new/goals/all',
    GetGoalsForPreviousCopy: 'goal/new/goals/allLastYear',
    Save: 'goal/new/save-goal',
    Remove: 'goal/new/remove-goal',
    RemoveAdmin: 'goal/new/admin/remove-goal',
}

export const apiRequest = {
    handleSave,
    handleRemove,
    populateGoals,
    populateGoalsForCopy,
    populateGoalsForPreviousCopy
};

async function handleSave(parent, model, currentPage, goalType) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var _model = { ...model };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        _model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.Save, _model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                addNew: null,
                edit: null,
            }, function () {
                parent.initState();
                populateGoals(parent, currentPage, goalType.id);
            });
        }).catch(handleError);
}

async function handleRemove(parent, model, currentPage, goalType) {
    //Info message snack
    NotificationBar('Változtatások mentése', 'top', 'right', 2000, null, <Progress />);

    var _model = { ...model };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        _model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }


    await new Api().call.post(Actions.Remove, _model)
        .then(handleResponse)
        .then(res => {
            parent.initState();
            populateGoals(parent, currentPage, goalType.id);
        }).catch(handleError);
}

async function populateGoals(parent, currentPage, _typeId, periodId) {
    const { setParentObjectState, userId } = parent.props;
    const pageNumber = isNaN(currentPage) ? 1 : currentPage;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = { currentPage: pageNumber, typeId, userId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetGoals, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                baseGoals: JSON.parse(JSON.stringify(res)),
                loading: false
            });
            setParentObjectState("alert", "weightSum", res.relevanceSum);
            setParentObjectState("alert", "total", res.total);
            setParentObjectState("alert", "isWeighted", res.goalType.isWeighted);
        }).catch(handleError);
}

async function populateGoalsForCopy(parent, _typeId) {
    const { userId } = parent.props;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = { userId, typeId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetGoalsForCopy, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                baseGoals: JSON.parse(JSON.stringify(res)),
                loading: false
            });
        }).catch(handleError);

}

async function populateGoalsForPreviousCopy(parent, _typeId) {
    const { userId } = parent.props;
    const typeId = isNaN(_typeId) ? -1 : _typeId;

    var model = { userId, typeId };
    const loggedBy = UserStore.getLoggedByAdminValue();
    if (loggedBy != null) {
        model = { ...model, loggedByUser: { period: loggedBy.period.id, user: loggedBy.user.userId } };
    }

    await new Api().call.post(Actions.GetGoalsForPreviousCopy, model)
        .then(handleResponse)
        .then(res => {
            parent.setState({
                goals: res,
                baseGoals: JSON.parse(JSON.stringify(res)),
                loading: false
            });
        }).catch(handleError);

}