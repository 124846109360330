import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@material-ui/core';
import style from './style';



const Topbar = props => {
  const { className, ...rest } = props;

    const classes = style();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
          <Toolbar>
                    <img
                      alt="Logo"
                      style={{filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'}}
                      src="/images/logos/logo--app--white.png"
                      height="45"
                      width="50"
                    />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
