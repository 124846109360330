//React

import React from 'react';
//Core

import {
    TextField,
    CircularProgress
} from '@material-ui/core';
//Lab

import Autocomplete from '@material-ui/lab/Autocomplete';
//Components

import {apiRequest} from './apiRequest';
//Style

import style from './style';


export default function SelectClass(props) {
    const {value, handleChange, usedClasses, selectedTabUser} = props;

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    let model = { usedClasses, selectedClass: null, selectedUser: selectedTabUser };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {   
            apiRequest.getClasses(active,model,setOptions);             
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const classes = style();

    return (
        <Autocomplete
            className={classes.root}
            loadingText="Betöltés..."
            noOptionsText="Nincs találat"
            id="selectClass"
            value={value}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disabled={false}
            options={options}
            loading={loading}
            getOptionDisabled={(option) => option.disabled}
            onChange={(event, newValue) => handleChange(newValue)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) =>
                <TextField
                    {...params}
                    placeholder="Kereséshez kezdj el gépelni"
                    error={value === null ? true : false}
                    helperText={value === null && "Kötelező választani"}               
                    label="Minősítés"
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                }}/>
            }
        />
    );
}
