import React, { Component } from 'react';
//Core
import {
    Typography,
    IconButton
} from '@material-ui/core';
//Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
//Style
import style from './style';
import { withStyles } from '@material-ui/core/styles';

class Pagination extends Component {

    handleNextClick = () => {
        this.props.onPageChanges(this.props.currentPage + 1);
    }
    handlePreviousClick = () => {
        this.props.onPageChanges(this.props.currentPage - 1);
    }
    handleFirstPageClick = () => {
        this.props.onPageChanges(1);
    }
    handleLastPageClick = () => {
        this.props.onPageChanges(this.props.pages);
    }

    render() {
        const { classes, total, step, pages, currentPage, disabled } = this.props;
        return (<div className={classes.pagination}>
            <Typography className={classes.paginationText}>{currentPage === 1 ? `1-${(currentPage * step) < total ? (currentPage * step) : total}, Összesen: ${total}` : `${((currentPage - 1) * step) + 1}-${(currentPage * step) < total ? (currentPage * step) : total }, Összesen: ${total}`}</Typography>
            <IconButton size="small" className={classes.previousButton} disabled={(disabled || currentPage === 1) ? true : false} onClick={this.handleFirstPageClick}>
                <FirstPageIcon />
            </IconButton>
            <IconButton size="small" className={classes.previousButton} disabled={(disabled || currentPage === 1) ? true : false} onClick={this.handlePreviousClick}>
                <ChevronLeftIcon />
            </IconButton>
            <IconButton size="small" className={classes.nextButton} disabled={(disabled || currentPage === pages || pages === 0) ? true : false} onClick={this.handleNextClick}>
                <ChevronRightIcon />
            </IconButton>
            <IconButton size="small" className={classes.nextButton} disabled={(disabled || currentPage === pages || pages === 0) ? true : false} onClick={this.handleLastPageClick}>
                <LastPageIcon />
            </IconButton>
        </div>)
    }
}
export default withStyles(style)(Pagination);
