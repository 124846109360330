import {Api,handleResponse,handleError} from 'api';
import { NotificationBar } from 'components';
//Actions
const Actions = {
    Send: 'helper/send-problem',
}

export const apiRequest = {
    send
};

//#Api calls
async function send(parent, model) {
    var data = new FormData();
    data.append("file", model.file);
    data.append("menuPoint", model.menuPoint);
    data.append("description", model.description);
        await new Api().call.post(`${Actions.Send}`, data)
        .then(handleResponse)
        .then(res => {
            NotificationBar(res.message, 'top', 'right', 4000, 'success', null);
            parent.setState({
                menuPoint: '',
                description: '',
                fileName: '',
                file: null,
                request:false
            })
        }).catch(handleError);

}
